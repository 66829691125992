import { Account, AuthUser, UserType } from '@api';
import { createReducer } from '@reduxjs/toolkit';

import logoUrl from '../../images/logo-full.png';

import {
    authenticateSession, logout, updateAccounts, updateClientTheme
} from './';


export interface AuthenticationState {
    initNeeded: boolean;
    user: AuthUser | undefined;
    accounts: Account[] | undefined;
}

const initialState: AuthenticationState = {
    initNeeded: true,
    user: undefined,
    accounts: undefined
};

function prepareAccounts(user: AuthUser) {
    const accounts: Account[] = [
        ...(user.clientAccounts?.map((clientAccount) => ({
            ...clientAccount,
            accountUserType: UserType.CLIENT
        })) || []),
        ...(user.customerAccounts?.map((clientAccount) => ({
            ...clientAccount,
            accountUserType: UserType.CUSTOMER
        })) || [])
    ];

    accounts.sort((accountA, accountB) => (
        accountA.name === accountB.name
            ? accountA.customerName
                ? accountA.customerName.localeCompare(accountB.customerName || '')
                : 0
            : accountA.name.localeCompare(accountB.name)
    ));

    return [
        ...(user.isInternal ? [
            {
                id: 'internal',
                name: 'Premicorr',
                logoUrl,
                primaryColor: '#7092BF',
                secondaryColor: '#70BABF',
                accountUserType: UserType.INTERNAL
            }
        ] : []),
        ...accounts
    ];
}

export const reducer = createReducer(initialState, (builder) => builder
    .addCase(authenticateSession.rejected, (state) => {
        state.initNeeded = false;
    })
    .addCase(authenticateSession.fulfilled, (state, action) => {
        const { user } = action.payload;

        return {
            initNeeded: false,
            accounts: prepareAccounts(user),
            user
        };
    })
    .addCase(logout.fulfilled, (state) => {
        state.user = undefined;
    })
    .addCase(updateAccounts, (state, action) => {
        state.accounts = prepareAccounts(action.payload);
    })
    .addCase(updateClientTheme, (state, action) => {
        const accountsToBeUpdated = state.accounts?.filter(
            account => account.id === action.payload.id
        );

        accountsToBeUpdated?.forEach(
            account => {
                const accountIndex = state.accounts?.findIndex(
                    a => a.customerId === account.customerId && a.id === account.id
                );

                state.accounts![accountIndex!] = {
                    ...account,
                    ...action.payload
                };
            }
        );
    }));
