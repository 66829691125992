import { ActiveInactiveEnum, DueDiligenceStepType } from '@api';

import { apiUtils } from '../api-utils';


export function getDueDiligenceConfigs(clientID: string): Promise<DueDiligenceConfig[]> {
    return apiUtils.get(`/client/${clientID}/due-diligence-config`);
}

export function createDueDiligenceConfig(clientID: string, config: Omit<DueDiligenceConfig, 'id'>): Promise<DueDiligenceConfig> {
    return apiUtils.post(`/client/${clientID}/due-diligence-config`, config);
}

export function updateDueDiligenceConfig(clientID: string, config: DueDiligenceConfig): Promise<DueDiligenceConfig> {
    return apiUtils.put(`/client/${clientID}/due-diligence-config/${config.id}`, config);
}

export function moveStep(clientID: string, dueDiligenceConfigID: string, destinationIndex: number): Promise<void> {
    return apiUtils.post(`/client/${clientID}/due-diligence-config/${dueDiligenceConfigID}/move`, {
        destinationIndex
    }, { emptyResponse: true });
}

export interface DueDiligenceConfig {
    id: string;
    name: string;
    description: string;
    type: DueDiligenceStepType;
    isRequired: boolean;
    isDocRequired: boolean;
    displayOrder?: number;
    status: ActiveInactiveEnum;
}
