import { PermissionType } from '@api';
import {
    Paper, Typography
} from '@mui/material';
import { Button } from '@tsp-ui/core/components';
import { useHasPermission } from '@utils/hooks';
import { ReactNode } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import styles from './DetailPageViewCard.module.scss';


interface DetailPageViewCardProps {
    label: ReactNode;
    to: LinkProps['to'];
    permission?: PermissionType; // TODO make this required when all perms are implemented
    noPermissionContent?: ReactNode;
}

export default function DetailPageViewCard({
    label, to, permission, noPermissionContent
}: DetailPageViewCardProps) {
    const [ hasPermission = true ] = useHasPermission(permission ? [ permission ] : []);

    return (
        <Paper
            className={styles.root}
            variant="outlined"
        >
            <Typography variant="h6">
                {label}
            </Typography>

            <Button
                component={Link}
                to={to}
                disabled={!hasPermission}
                tooltip={hasPermission ? '' : noPermissionContent}
            >
                View
            </Button>
        </Paper>
    );
}
