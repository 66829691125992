import { InvestorRelationship } from '@api';
import { PhoneField, TextField, isEmail } from '@tsp-ui/core';
import { FormProvider, useForm } from 'react-hook-form';

import styles from './InvestorRelationshipForm.module.scss';


interface InvestorRelationshipFormProps {
    defaultValues?: InvestorRelationship;
    handleSubmit(investorRelationship: InvestorRelationship): void;
}

export function InvestorRelationshipForm(
    { defaultValues, handleSubmit: handleSubmitProp }: InvestorRelationshipFormProps
) {
    const formMethods = useForm<InvestorRelationship>({ defaultValues });
    const handleSubmit = formMethods.handleSubmit(handleSubmitProp);

    return (
        <form
            noValidate
            id={`investor-relationship-${defaultValues?.id || 'new'}`}
            onSubmit={handleSubmit}
            className={styles.formContent}
        >
            <FormProvider {...formMethods}>
                <TextField<InvestorRelationship>
                    name="companyName"
                    label="Company name"
                    size="small"
                    variant="outlined"
                    required
                />

                <TextField<InvestorRelationship>
                    name="contact"
                    label="Contact name"
                    size="small"
                    variant="outlined"
                    required
                />

                <PhoneField<InvestorRelationship>
                    name="phone"
                    label="Phone #"
                    size="small"
                    variant="outlined"
                    required
                />

                <TextField<InvestorRelationship>
                    name="emailAddress"
                    label="Email address"
                    size="small"
                    variant="outlined"
                    required
                    rules={isEmail}
                />
            </FormProvider>
        </form>
    );
}
