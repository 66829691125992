import api, {
    LoanComment,
    LoanDetail,
    PermissionType
} from '@api';
import {
    Comment, OpenInNew
} from '@mui/icons-material';
import { Badge } from '@mui/material';
import {
    IconButton,
    RoutedDialogManager
} from '@tsp-ui/core/components';
import {
    useAsyncEffect, usePageMessage, useParams
} from '@tsp-ui/core/utils';
import { useGetCurrentAccount, useHasPermission, withAuth } from '@utils';
import LoanDataDialog from '@views/loans/components/LoanDataDialog';
import {
    Dispatch, SetStateAction, createContext, useCallback, useMemo, useState
} from 'react';
import { Link } from 'react-router-dom';

import Page from '../components/Page';

import styles from './LoanDetailPage.module.scss';
import { LoanCommentsDialog } from './components/LoanCommentsDialog';


export interface PendingLoanDetailContextValue {
    loanDetail: LoanDetail | undefined;
    comments: LoanComment[];
    setComments: Dispatch<SetStateAction<LoanComment[]>>;
    refreshComments: () => Promise<void>;

}

export const PendingLoanDetailContext = createContext<PendingLoanDetailContextValue>({
    loanDetail: undefined,
    comments: [],
    setComments: () => {},
    refreshComments: async () => {}
});

export default function PendingLoanDetailPage() {
    const [ loanDetail, setLoanDetail ] = useState<LoanDetail>();
    const [ comments, setComments ] = useState<LoanComment[]>([]);
    const [ loading, setLoading ] = useState(false);

    const { id: clientID, customerId } = useGetCurrentAccount();

    const { loanID } = useParams<{ loanID: string }>();
    const pageMessage = usePageMessage();

    const canViewLoanComments = useHasPermission([ PermissionType.VIEW_LOAN_COMMENTS ]);

    const refreshComments = useCallback(async () => {
        try {
            setComments(await api.loans.getLoanComments(clientID, loanID, customerId));
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching loan comments', error);
        }
    }, [
        clientID, loanID, customerId, pageMessage
    ]);

    useAsyncEffect(useCallback(async () => {
        setLoading(true);

        try {
            const [ loanDetail, comments ] = await Promise.all([
                api.loans.getProspectiveLoanDetail(clientID, loanID, customerId),
                api.loans.getLoanComments(clientID, loanID, customerId)
            ]);
            setLoanDetail(loanDetail);
            setComments(comments);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching loan details', error);
        }

        setLoading(false);
    }, [
        clientID, loanID, customerId, pageMessage
    ]));

    const contextValue = useMemo(() => ({
        loanDetail,
        comments,
        setComments,
        refreshComments
    }), [
        comments, loanDetail, refreshComments
    ]);

    return (
        <PendingLoanDetailContext.Provider value={contextValue}>
            <Page
                header={(loanDetail ? (
                    <div className={styles.header}>
                        Loan #{loanDetail.loanNumber || loanDetail.customerLoanNumber}

                        <div>
                            <IconButton
                                title="View loan data"
                                component={Link}
                                to="loan-data"
                                state={{ isPendingLoan: true }}
                            >
                                <OpenInNew color="secondary" />
                            </IconButton>

                            <IconButton
                                tooltip="View loan comments"
                                disabled={!canViewLoanComments}
                                component={Link}
                                to="loan-comments"
                            >
                                <Badge
                                    badgeContent={comments.length}
                                    color="primary"
                                    invisible={comments.length === 0}
                                >
                                    <Comment color="secondary" />
                                </Badge>
                            </IconButton>
                        </div>
                    </div>
                ) : null)}
                loading={!loanDetail || loading}
                breadcrumbs={[
                    'Loans',
                    (loanDetail?.loanNumber && `#${loanDetail?.loanNumber}`) || (loanDetail?.customerLoanNumber && `#${loanDetail?.customerLoanNumber}`)
                ]}
            >

                <RoutedDialogManager routes={dialogRoutes} />
            </Page>
        </PendingLoanDetailContext.Provider>
    );
}

const dialogRoutes = {
    'loan-data': withAuth(LoanDataDialog, [ PermissionType.VIEW_LOANS ], true),
    'loan-comments': withAuth(LoanCommentsDialog, [ PermissionType.VIEW_LOAN_COMMENTS ], true)
};
