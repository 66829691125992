import { EmailOutlined, PersonOutlined } from '@mui/icons-material';
import {
    Avatar, Link as MuiLink, Popover, Typography
} from '@mui/material';
import { PhoneTypography } from '@tsp-ui/core';
import { UserLinkContext } from '@views/AuthenticatedRouteSwitch';
import { useContext, useState } from 'react';

import styles from './UserLink.module.scss';


interface UserLinkProps {
    userId: string;
    showAvatar?: boolean;
}

export default function UserLink({ userId, showAvatar }: UserLinkProps) {
    const { users } = useContext(UserLinkContext);

    const [ anchorEl, setAnchorEl ] = useState<HTMLElement>();

    const user = users.find(user => user.id === userId);

    return !user ? (
        <Typography
            variant="caption"
            className={styles.userNotFound} // extra space before user is necessary
        > user not found
        </Typography>
    ) : (
        <div>
            <Popover
                open={!!anchorEl}
                onClose={() => setAnchorEl(undefined)}
                anchorEl={anchorEl}
                anchorOrigin={{
                    horizontal: 'left',
                    vertical: 'bottom'
                }}
                transformOrigin={{
                    horizontal: 'left',
                    vertical: 'top'
                }}
            >
                <div className={styles.contactInfo}>
                    {showAvatar && (
                        <Typography variant="body2">
                            <PersonOutlined
                                color="primary"
                                fontSize="small"
                            />

                            {`${user.firstName} ${user.lastName}`}
                        </Typography>
                    )}

                    {user.phone && (
                        <PhoneTypography
                            showIcon
                            variant="caption"
                        >
                            {user.phone}
                        </PhoneTypography>
                    )}

                    <Typography variant="caption">
                        <EmailOutlined
                            color="primary"
                            fontSize="small"
                        />

                        <MuiLink href={`mailto:${user.email}`}>{user.email}</MuiLink>
                    </Typography>
                </div>
            </Popover>

            {showAvatar ? (
                <Avatar
                    className={styles.avatar}
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                >
                    {`${user.firstName.charAt(0)}${user.lastName.charAt(0)}`}
                </Avatar>
            ) : (
                <MuiLink
                    component="button"
                    variant="caption"
                    className={styles.nameLink}
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                >
                    {`${user.firstName} ${user.lastName}`}
                </MuiLink>
            )}
        </div>
    );
}
