import { Investor, InvestorStatus } from '@api';
import { CorporateFare } from '@mui/icons-material';
import {
    Paper, Tooltip, Typography
} from '@mui/material';
import { SubtleLink } from '@tsp-ui/core/components';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';

import styles from './InternalInvestorCard.module.scss';


const { INACTIVE } = InvestorStatus;

interface InvestorCardProps {
    investor: Investor;
}

export function InternalInvestorCard({
    investor: {
        name, id, status, code, legalName, isAgency
    }
}: InvestorCardProps) {
    const { search } = useLocation();

    return (
        <Paper
            elevation={0}
            className={clsx(styles.root, {
                [styles.lineThrough]: status === INACTIVE
            })}
        >
            <div>
                <SubtleLink to={id + search}>
                    {name}
                </SubtleLink>

                <Typography
                    variant="body2"
                    color="textSecondary"
                    component="span"
                    className={styles.code}
                >
                    ({code})
                </Typography>

                <Tooltip title="Legal name">
                    <Typography
                        variant="body2"
                        color="textSecondary"
                    >
                        {legalName}
                    </Typography>
                </Tooltip>
            </div>

            <div className={styles.rightContainer}>
                {isAgency && (
                    <Tooltip
                        className={styles.icon}
                        title="This investor is an agency"
                        enterDelay={0}
                    >
                        <CorporateFare color="primary" />
                    </Tooltip>
                )}
            </div>
        </Paper>
    );
}
