import { EligibilityGuideline } from '../investor/investor-api';


export enum LoanProperty {
    LOAN_TYPE = 'LOAN_TYPE',
    OCCUPANCY = 'OCCUPANCY',
    PURPOSE = 'PURPOSE',
    AMORT_TYPE = 'AMORT_TYPE',
    PROPERTY_TYPE = 'PROPERTY_TYPE',
    LOAN_LIMIT_TYPE = 'LOAN_LIMIT_TYPE',
    UNITS = 'UNITS',
    FICO = 'FICO',
    LTV = 'LTV',
    CLTV = 'CLTV',
    DTI = 'DTI',
    TERM = 'TERM',
    AUS = 'AUS',
    RESERVES_MONTHS = 'RESERVES_MONTHS',
    SPECIALTY_PROGRAM = 'SPECIALTY_PROGRAM',
    STATE = 'STATE',
    LOAN_AMOUNT = 'LOAN_AMOUNT',
    HIGH_BALANCE = 'HIGH_BALANCE',
    ESCROWS = 'ESCROWS',
    SUBORDINATE_FINANCING = 'SUBORDINATE_FINANCING',
    IRRL = 'IRRL',
    STREAMLINE = 'STREAMLINE',
    FIRST_TIME_HOMEBUYER = 'FIRST_TIME_HOMEBUYER',
    NON_QM = 'NON_QM',
}

export enum LLPAOnlyLoanProperty {
    INVESTOR = 'INVESTOR'
}

export type LLPAMatrixLoanProperty = LoanProperty | LLPAOnlyLoanProperty;

export const loanPropertyDisplay = {
    [LoanProperty.LOAN_TYPE]: 'Loan Type',
    [LoanProperty.OCCUPANCY]: 'Occupancy',
    [LoanProperty.PURPOSE]: 'Purpose',
    [LoanProperty.AMORT_TYPE]: 'Amortization Type',
    [LoanProperty.PROPERTY_TYPE]: 'Property Type',
    [LoanProperty.LOAN_LIMIT_TYPE]: 'Loan Limit Type',
    [LoanProperty.UNITS]: 'Units',
    [LoanProperty.FICO]: 'FICO',
    [LoanProperty.LTV]: 'LTV',
    [LoanProperty.CLTV]: 'CLTV',
    [LoanProperty.DTI]: 'DTI',
    [LoanProperty.TERM]: 'Term',
    [LoanProperty.AUS]: 'AUS',
    [LoanProperty.RESERVES_MONTHS]: 'Min Reserves Months',
    [LoanProperty.SPECIALTY_PROGRAM]: 'Specialty Program',
    [LoanProperty.STATE]: 'State',
    [LoanProperty.LOAN_AMOUNT]: 'Loan Amount',
    [LoanProperty.HIGH_BALANCE]: 'High Balance',
    [LoanProperty.ESCROWS]: 'Escrows',
    [LoanProperty.SUBORDINATE_FINANCING]: 'Subordinate Financing',
    [LoanProperty.IRRL]: 'IRRL',
    [LoanProperty.STREAMLINE]: 'Streamline',
    [LoanProperty.FIRST_TIME_HOMEBUYER]: 'First Time Homebuyer',
    [LoanProperty.NON_QM]: 'Non-QM',
    [LLPAOnlyLoanProperty.INVESTOR]: 'Investor'
} as const;

export const llpaMatrixLoanPropertyDisplay = {
    ...loanPropertyDisplay,
    [LLPAOnlyLoanProperty.INVESTOR]: 'Investor'
};

export const loanPropertyEnumFieldTypes = {
    [LoanProperty.LOAN_TYPE]: 'enum',
    [LoanProperty.OCCUPANCY]: 'enum',
    [LoanProperty.PURPOSE]: 'enum',
    [LoanProperty.AMORT_TYPE]: 'enum',
    [LoanProperty.PROPERTY_TYPE]: 'enum',
    [LoanProperty.LOAN_LIMIT_TYPE]: 'enum',
    [LoanProperty.UNITS]: 'numeric',
    [LoanProperty.FICO]: 'numeric',
    [LoanProperty.LTV]: 'numeric',
    [LoanProperty.CLTV]: 'numeric',
    [LoanProperty.DTI]: 'numeric',
    [LoanProperty.TERM]: 'numeric',
    [LoanProperty.AUS]: 'enum',
    [LoanProperty.RESERVES_MONTHS]: 'numeric',
    [LoanProperty.SPECIALTY_PROGRAM]: 'enum',
    [LoanProperty.STATE]: 'enum',
    [LoanProperty.LOAN_AMOUNT]: 'numeric',
    [LoanProperty.HIGH_BALANCE]: 'boolean',
    [LoanProperty.ESCROWS]: 'boolean',
    [LoanProperty.SUBORDINATE_FINANCING]: 'boolean',
    [LoanProperty.IRRL]: 'boolean',
    [LoanProperty.STREAMLINE]: 'boolean',
    [LoanProperty.FIRST_TIME_HOMEBUYER]: 'boolean',
    [LoanProperty.NON_QM]: 'boolean'
} as const;

export const llpaMatrixLoanPropertyEnumFieldTypes = {
    ...loanPropertyEnumFieldTypes,
    [LLPAOnlyLoanProperty.INVESTOR]: 'enum'
} as const;

export const loanPropertyEligibilityGuidelineFieldNames: {
    [key in LoanProperty]: keyof EligibilityGuideline;
} = {
    [LoanProperty.LOAN_TYPE]: 'loanType',
    [LoanProperty.OCCUPANCY]: 'occupancy',
    [LoanProperty.PURPOSE]: 'purpose',
    [LoanProperty.AMORT_TYPE]: 'amortType',
    [LoanProperty.PROPERTY_TYPE]: 'propertyType',
    [LoanProperty.LOAN_LIMIT_TYPE]: 'loanLimitType',
    [LoanProperty.UNITS]: 'units',
    [LoanProperty.FICO]: 'fico',
    [LoanProperty.LTV]: 'ltv',
    [LoanProperty.CLTV]: 'cltv',
    [LoanProperty.DTI]: 'dti',
    [LoanProperty.TERM]: 'term',
    [LoanProperty.AUS]: 'aus',
    [LoanProperty.RESERVES_MONTHS]: 'reservesMonths',
    [LoanProperty.SPECIALTY_PROGRAM]: 'specialtyProgram',
    [LoanProperty.STATE]: 'state',
    [LoanProperty.LOAN_AMOUNT]: 'loanAmount',
    [LoanProperty.HIGH_BALANCE]: 'highBalance',
    [LoanProperty.ESCROWS]: 'escrows',
    [LoanProperty.SUBORDINATE_FINANCING]: 'subordinateFinancing',
    [LoanProperty.IRRL]: 'irrl',
    [LoanProperty.STREAMLINE]: 'streamline',
    [LoanProperty.FIRST_TIME_HOMEBUYER]: 'firstTimeHomebuyer',
    [LoanProperty.NON_QM]: 'nonQm'
};

