import { EligibilityMatrix, loanPropertyDisplay } from '@api';
import { DialogContent } from '@mui/material';
import { RoutedDialog, RoutedDialogImplProps } from '@tsp-ui/core';
import { ClientLoanProgramDetailContext } from '@views/admin/investor-eligibility/ClientLoanProgramDetailPage';
import {
    InternalLoanProgramDetailPageContext
} from '@views/admin/investors/InvestorDetailPage/LoanProgramDetailPage/InternalLoanProgramDetailPage';
import {
    ViewHighLevelGuidelineCard,
    formatHighLevelGuidelineValue
} from '@views/admin/investors/InvestorDetailPage/LoanProgramDetailPage/components/HighLevelGuidelineCard';
import {
    matrixToFormValues
} from '@views/admin/investors/InvestorDetailPage/LoanProgramDetailPage/components/LoanProgramForm';
import { useContext } from 'react';

import { ViewEligibilityGuidelineGrid } from '../../components/EligibilityGuidelineGrid';

import HighLevelGuidelines from './HighLevelGuidelines';
import styles from './ReadOnlyEligibilityMatrixDialog.module.scss';


export default function InternalReadOnlyEligibilityMatrixDialog(props: RoutedDialogImplProps) {
    const { matrix } = useContext(InternalLoanProgramDetailPageContext);

    return (
        <ReadOnlyEligibilityMatrixDialog
            matrix={matrix}
            {...props}
        />
    );
}

export function ClientReadOnlyEligibilityMatrixDialog(props: RoutedDialogImplProps) {
    const { matrix } = useContext(ClientLoanProgramDetailContext);

    return (
        <ReadOnlyEligibilityMatrixDialog
            matrix={matrix}
            {...props}
        />
    );
}

interface ReadOnlyEligibilityMatrixDialogProps extends RoutedDialogImplProps {
    matrix: EligibilityMatrix | undefined;
}

function ReadOnlyEligibilityMatrixDialog({ matrix, ...props }: ReadOnlyEligibilityMatrixDialogProps) {
    const formValues = matrix && matrixToFormValues(matrix);
    const cols = (formValues?.columnMetadata || []).filter(({ isHighLevel }) => isHighLevel);

    return (
        <RoutedDialog
            title="View eligibility matrix"
            fullWidth
            maxWidth={false}
            keepLocationSearch
            {...props}
        >
            <DialogContent>
                <div className={styles.matrixContainer}>
                    <HighLevelGuidelines
                        readOnly
                        noContent={!cols.some(({ isHighLevel }) => isHighLevel)}
                        cards={cols.map((column) => (
                            <ViewHighLevelGuidelineCard
                                key={column.loanProperty}
                                columnMetadata={column}
                                title={loanPropertyDisplay[column.loanProperty]}
                                formatValue={formatHighLevelGuidelineValue}
                            />
                        ))}
                    />

                    <div className={styles.gridContainer}>
                        {matrix && (
                            <ViewEligibilityGuidelineGrid matrix={formValues} />
                        )}
                    </div>
                </div>
            </DialogContent>
        </RoutedDialog>
    );
}
