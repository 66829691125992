import api, {
    ClientLoanProgram, ConditionConfig, ConditionConfigGroup, PermissionType, loanTypeDisplay
} from '@api';
import { Add, Edit, RemoveCircleOutline } from '@mui/icons-material';
import {
    Link as MuiLink, Typography
} from '@mui/material';
import {
    Button, FilledSection, IconButton, RoutedDialogManager, useConfirm
} from '@tsp-ui/core/components';
import { useAsyncEffect, usePageMessage } from '@tsp-ui/core/utils';
import { withAuth } from '@utils';
import { useGetCurrentAccount, useHasPermission } from '@utils/hooks';
import Page from '@views/components/Page';
import {
    Dispatch, SetStateAction, createContext, useCallback, useContext, useMemo, useState
} from 'react';
import { Link } from 'react-router-dom';

import { ConditionConfigCard } from './ConditionConfigCard';
import { ConditionConfigDialog } from './ConditionConfigDialog';
import { ConditionConfigGroupDialog } from './ConditionConfigGroupDialog';
import styles from './ConditionConfigPage.module.scss';


export interface ConditionConfigContextValue {
    conditionConfigGroups: ConditionConfigGroup[];
    setConditionConfigGroups: Dispatch<SetStateAction<ConditionConfigGroup[]>>;
    conditionConfigs: ConditionConfig[];
    setConditionConfigs: Dispatch<SetStateAction<ConditionConfig[]>>;
    loanPrograms: ClientLoanProgram[];
}

export const ConditionConfigContext = createContext<ConditionConfigContextValue>({
    conditionConfigGroups: [],
    setConditionConfigGroups: () => {},
    conditionConfigs: [],
    setConditionConfigs: () => {},
    loanPrograms: []
});

export default function ConditionManagementPage() {
    const pageMessage = usePageMessage();
    const { id: clientId } = useGetCurrentAccount();

    const [ loading, setLoading ] = useState(true);
    const [ conditionConfigs, setConditionConfigs ] = useState<ConditionConfig[]>([]);
    const [ conditionConfigGroups, setConditionConfigGroups ] = useState<ConditionConfigGroup[]>([]);
    const [ loanPrograms, setLoanPrograms ] = useState<ClientLoanProgram[]>([]);

    const [ canManage ] = useHasPermission([ PermissionType.MANAGE_CONDITION_CONFIGS ]);

    useAsyncEffect(useCallback(async () => {
        setLoading(true);

        try {
            const [
                conditionConfigs, conditionConfigGroups, loanPrograms
            ] = await Promise.all([
                api.conditionConfig.getConditionConfigs(clientId),
                api.conditionConfig.getConditionConfigGroups(clientId),
                api.client.investors.getLoanPrograms(clientId)
            ]);

            setConditionConfigs(conditionConfigs);
            setConditionConfigGroups(conditionConfigGroups);
            setLoanPrograms(loanPrograms);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching loan condition configurations', error);
        }

        setLoading(false);
    }, [ pageMessage, clientId ]));

    const ConditionContextValue = useMemo(() => ({
        conditionConfigs,
        setConditionConfigs,
        conditionConfigGroups,
        setConditionConfigGroups,
        loanPrograms
    }), [
        conditionConfigs, conditionConfigGroups, loanPrograms
    ]);

    const loanTypeGroups = conditionConfigGroups.filter(group => group.type === 'loanType');
    const loanProgramGroups = conditionConfigGroups.filter(group => group.type === 'loanProgram');

    return (
        <ConditionConfigContext.Provider value={ConditionContextValue}>
            <Page
                header="Default Conditions Management"
                loading={loading}
                headerActions={(
                    <Button
                        component={Link}
                        to="group"
                        variant="contained"
                        disabled={!canManage}
                        tooltip={canManage ? '' : 'You do not have permission to add loan condition configurations'}
                    >
                        Add loan condition group
                    </Button>
                )}
            >
                <div className={styles.root}>
                    {!!loanTypeGroups.length && (
                        <>
                            <Typography variant="h6">
                                By loan type
                            </Typography>

                            <div className={styles.conditionConfigGroups}>
                                {loanTypeGroups.map(group => (
                                    <ConditionConfigGroupSection
                                        key={group.id}
                                        conditionConfigGroup={group}
                                    />
                                ))}
                            </div>
                        </>
                    )}

                    {!!loanProgramGroups.length && (
                        <>
                            <Typography variant="h6">
                                By loan program
                            </Typography>

                            <div className={styles.conditionConfigGroups}>
                                {loanProgramGroups.map(group => (
                                    <ConditionConfigGroupSection
                                        key={group.id}
                                        conditionConfigGroup={group}
                                    />
                                ))}
                            </div>
                        </>
                    )}
                </div>

                <RoutedDialogManager routes={dialogRoutes} />
            </Page>
        </ConditionConfigContext.Provider>
    );
}

const dialogRoutes = {
    group: withAuth(ConditionConfigGroupDialog, [ PermissionType.MANAGE_CONDITION_CONFIGS ], true),
    'group/:conditionConfigGroupId': withAuth(ConditionConfigGroupDialog, [ PermissionType.MANAGE_CONDITION_CONFIGS ], true),
    'group/:conditionConfigGroupId/condition': withAuth(ConditionConfigDialog, [ PermissionType.MANAGE_CONDITION_CONFIGS ], true),
    'group/:conditionConfigGroupId/condition/:conditionId': withAuth(ConditionConfigDialog, [ PermissionType.MANAGE_CONDITION_CONFIGS ], true)
};

function ConditionConfigGroupSection({ conditionConfigGroup }: {conditionConfigGroup: ConditionConfigGroup}) {
    const { loanPrograms, conditionConfigs, setConditionConfigGroups } = useContext(ConditionConfigContext);
    const [ canManage ] = useHasPermission([ PermissionType.MANAGE_CONDITION_CONFIGS ]);
    const [ deleting, setDeleting ] = useState(false);
    const confirm = useConfirm();
    const { id: clientId } = useGetCurrentAccount();
    const pageMessage = usePageMessage();

    const headerText = conditionConfigGroup.type === 'loanProgram'
        ? conditionConfigGroup.loanProgramIds?.map((id) => loanPrograms.find(program => program.id === id)?.name || 'N/A').join(', ')
        : conditionConfigGroup.loanTypes?.map((loanType) => loanTypeDisplay[loanType]).join(', ');

    return (
        <FilledSection
            key={conditionConfigGroup.id}
            className={styles.section}
            header={(
                <>
                    {headerText}

                    <span className={styles.buttons}>
                        <IconButton
                            tooltip="Edit condition group"
                            component={Link}
                            to={`group/${conditionConfigGroup.id}`}
                        >
                            <Edit color="secondary" />
                        </IconButton>

                        <IconButton
                            tooltip={canManage ? 'Delete condition group' : 'You do not have permission to delete condition groups'}
                            disabled={!canManage}
                            onClick={async () => {
                                setDeleting(true);

                                if (!await confirm('This action will delete all conditions that belong to this group. Are you sure you want to continue?')) {
                                    setDeleting(false);
                                    return;
                                }

                                try {
                                    await api.conditionConfig.deleteConditionConfigGroup(
                                        clientId, conditionConfigGroup.id
                                    );

                                    setConditionConfigGroups(
                                        groups => groups.filter(group => group.id !== conditionConfigGroup.id)
                                    );

                                    pageMessage.success('Condition group deleted');
                                } catch (error) {
                                    pageMessage.handleApiError('An error occurred while deleting the condition group', error);
                                }

                                setDeleting(false);
                            }}
                            loading={deleting}
                        >
                            <RemoveCircleOutline color="error" />
                        </IconButton>

                        <IconButton
                            tooltip="Add condition"
                            component={Link}
                            to={`group/${conditionConfigGroup.id}/condition`}
                        >
                            <Add color="secondary" />
                        </IconButton>
                    </span>
                </>
            )}
            noResultsMessage={(
                <>
                    No loan conditions are currently configured.&nbsp;

                    <MuiLink
                        component={Link}
                        to={`group/${conditionConfigGroup.id}/condition`}
                    >
                        Click here
                    </MuiLink> to set one up.
                </>
            )}
        >
            {conditionConfigs.filter(
                config => config.conditionConfigGroupId === conditionConfigGroup.id
            ).map((config) => (
                <ConditionConfigCard
                    key={config.id}
                    conditionConfig={config}
                />
            ))}
        </FilledSection>
    );
}
