export enum UploadStatus {
    COMPLETE = 'COMPLETE',
    PENDING = 'PENDING',
    ERROR = 'ERROR'
}

export const uploadStatusDisplay = {
    [UploadStatus.COMPLETE]: 'Complete',
    [UploadStatus.PENDING]: 'Pending',
    [UploadStatus.ERROR]: 'Error'
};
