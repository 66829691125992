import {
    Address,
    AmortizationType,
    AutomatedUwSystem,
    ClientLoanProgram,
    LoanLimitType,
    LoanProperty,
    LoanPurpose,
    LoanType,
    NumericMatrixEntry,
    OccupancyType,
    PropertyType,
    SpecialtyProgram,
    State
} from '@api';

import { apiUtils } from '../api-utils';

/* Investor */
export function getInvestors(effectiveVersionId?: string): Promise<Investor[]> {
    return apiUtils.get('/investor', { effectiveVersionId });
}

export function updateInvestor(investor: Investor): Promise<Investor> {
    return apiUtils.put(`/investor/${investor.id}`, investor);
}

export function createInvestor(investor: Omit<Investor, 'id'>): Promise<Investor> {
    return apiUtils.post('/investor', investor);
}

export interface InvestorStatusUpdateBody {
    status: InvestorStatus;
    eligibilityVersionId: string;
}

export function updateStatus(investorID: string, status: InvestorStatusUpdateBody): Promise<Investor> {
    return apiUtils.patch(`/investor/${investorID}/status`, status);
}

/* Loan program */
export function getLoanPrograms(investorID: string, effectiveVersionId?: string): Promise<InternalLoanProgram[]> {
    return apiUtils.get(`/investor/${investorID}/loan-program`, { effectiveVersionId });
}

export interface CreateLoanProgramReqBody {
    loanProgram: Omit<CommonLoanProgram, 'id'>;
    matrix: EligibilityMatrix;
}

export interface CreateLoanProgramResBody {
    loanProgram: CommonLoanProgram;
    matrix: EligibilityMatrix;
}

export function createLoanProgram(createLoanProgramBody: CreateLoanProgramReqBody): Promise<CreateLoanProgramResBody> {
    return apiUtils.post(`/investor/${createLoanProgramBody.loanProgram.investorId}/loan-program`, createLoanProgramBody);
}

export function updateLoanProgram(loanProgram: InternalLoanProgram): Promise<InternalLoanProgram> {
    return apiUtils.put(`/investor/${loanProgram.investorId}/loan-program/${loanProgram.id}`, loanProgram);
}

export interface LoanProgramStatusUpdateBody {
    status: LoanProgramStatus;
    eligibilityVersionId: string;
}

export function updateLoanProgramStatus(
    investorID: string, loanProgramID: string, status: LoanProgramStatusUpdateBody
): Promise<InternalLoanProgram> {
    return apiUtils.patch(`/investor/${investorID}/loan-program/${loanProgramID}/status`, status);
}

/* Eligibility */
export function getExclusions(investorID: string, effectiveVersionId?: string): Promise<EligibilityGuideline[]> {
    return apiUtils.get(`/investor/${investorID}/eligibility-exclusions`, { effectiveVersionId });
}

export function updateExclusions(
    investorID: string, exclusions: EligibilityGuideline[]
): Promise<EligibilityGuideline[]> {
    return apiUtils.put(`/investor/${investorID}/eligibility-exclusions`, exclusions);
}

export function getEligibilityMatrix(investorID: string, loanProgramID: string, effectiveVersionId?: string) {
    return apiUtils.get(`/investor/${investorID}/loan-program/${loanProgramID}/matrix`, { effectiveVersionId });
}

export function updateEligibilityMatrix(investorID: string, loanProgramID: string, matrix: EligibilityMatrix) {
    return apiUtils.put(`/investor/${investorID}/loan-program/${loanProgramID}/matrix`, matrix);
}


export enum InvestorStatus {
    PENDING = 'PENDING',
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
}

export enum ManagedEligibilityStatus {
    MANAGED = 'MANAGED',
    PENDING = 'PENDING',
    UNMANAGED = 'UNMANAGED'
}

export enum LoanProgramStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE'
}

export interface Investor {
    id: string;
    code?: string;
    name: string;
    legalName: string;
    isAgency: boolean;
    status: InvestorStatus;
    managedEligibilityStatus: ManagedEligibilityStatus;
    addresses: Address[];
    activeClientIDs: string[];
    eligibilityVersionId: string;
}

export interface InternalLoanProgram {
    id: string;
    name: string;
    description: string;
    investorId: string;
    status: LoanProgramStatus;
    eligibilityVersionId: string;
}

// represents Loan Program that may not have client specific properties
export type CommonLoanProgram = Partial<ClientLoanProgram> & InternalLoanProgram;

export interface EligibilityMatrix {
    columnMetadata: EligibilityColumnMetadata[];
    inclusions: EligibilityGuideline[];
    exclusions: EligibilityGuideline[];
}

export interface MatrixColumnMetadata<T extends string> {
    loanProperty: T;
    isHighLevel: boolean;
    displayOrder: number;
    value?: boolean
        | string[]
        | NumericMatrixEntry;
}

export interface EligibilityColumnMetadata extends MatrixColumnMetadata<LoanProperty> {
    eligibilityVersionId: string;
    loanProgramId: string;
}

export interface EligibilityGuideline {
    id: string;
    eligibilityVersionId: string;
    loanProgramId?: string;
    investorId?: string;
    loanType?: LoanType;
    occupancy?: OccupancyType;
    purpose?: LoanPurpose;
    amortType?: AmortizationType;
    propertyType?: PropertyType;
    loanLimitType?: LoanLimitType;
    aus?: AutomatedUwSystem;
    specialtyProgram?: SpecialtyProgram;
    state?: State;
    units?: NumericMatrixEntry;
    fico?: NumericMatrixEntry;
    ltv?: NumericMatrixEntry;
    cltv?: NumericMatrixEntry;
    dti?: NumericMatrixEntry;
    term?: NumericMatrixEntry;
    reservesMonths?: NumericMatrixEntry;
    loanAmount?: NumericMatrixEntry;
    isExclusion: boolean;
    highBalance?: boolean;
    escrows?: boolean;
    subordinateFinancing?: boolean;
    irrl?: boolean;
    streamline?: boolean;
    firstTimeHomebuyer?: boolean;
    nonQm?: boolean;
}
