export enum ConditionType {
    UNDERWRITING = 'UNDERWRITING',
    POSTCLOSING = 'POSTCLOSING',
    PRELIMINARY = 'PRELIMINARY',
    PURCHASE = 'PURCHASE'
}

export const conditionTypeDisplay = {
    [ConditionType.UNDERWRITING]: 'Underwriting',
    [ConditionType.POSTCLOSING]: 'Post-Closing',
    [ConditionType.PRELIMINARY]: 'Preliminary',
    [ConditionType.PURCHASE]: 'Purchase'
};
