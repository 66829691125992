import { User } from '@api';
import { Button, DialogContent } from '@mui/material';
import { DialogActions, useParams } from '@tsp-ui/core';
import { AdminRouteParams } from '@views/admin/components/AdminPageTemplate';
import { UserForm } from '@views/admin/users/UserForm';
import { useState } from 'react';


interface AddEditUserDialogContentProps {
    user?: User;
    onAdd: (user: User) => void;
    onEdit: (user: User) => void;
    onCancel: () => void;
}

export function AddEditUserDialogContent({
    user, onAdd, onEdit, onCancel
}: AddEditUserDialogContentProps) {
    const { customerID } = useParams<AdminRouteParams<'customer'>>();
    const [ loading, setLoading ] = useState(false);

    return (
        <>
            <DialogContent>
                <UserForm
                    entityToEdit={user}
                    onSubmit={(newUser) => (user ? onEdit(newUser) : onAdd(newUser))}
                    setLoading={setLoading}
                    customerId={customerID}
                />
            </DialogContent>

            <DialogActions loading={loading}>
                <Button onClick={onCancel}>
                    Cancel
                </Button>

                <Button
                    variant="contained"
                    form="add-edit-user-form"
                    type="submit"
                    disabled={loading}
                >
                    Save
                </Button>
            </DialogActions>
        </>
    );
}
