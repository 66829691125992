import api, { AuthUser, ClientAccount } from '@api';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { Auth } from 'aws-amplify';


export enum AuthType {
    INIT = 'init-existing-session',
    LOGIN = 'login'
}

/**
 * Thunk action to pull data about the current authenticated user from
 * amplify auth into the redux store.
 */
export const authenticateSession = createAsyncThunk(
    'auth/authenticateSession',
    async (authType: AuthType) => {
        const session = await Auth.currentSession();

        const {
            sub: id,
            given_name: firstName = 'First',
            family_name: lastName = 'Last',
            email
        } = session.getIdToken().payload;

        const user = {
            id,
            firstName,
            lastName,
            email
        };

        Sentry.setUser(user);

        return {
            authType,
            user: {
                ...user,
                ...(await api.auth.fetchAuthUser())
            }
        };
    }
);

/**
 * Logs a user out
 */
export const logout = createAsyncThunk(
    'auth/logout',
    async () => {
        await Auth.signOut();
    }
);

/**
 * Updates a client's and customers' theme colors and logo
 */
export const updateClientTheme = createAction(
    'auth/updateClientTheme',
    (updatedAccount: Partial<ClientAccount> & { id: string }) => ({
        payload: updatedAccount
    })
);

export const updateAccounts = createAction(
    'auth/updateAccounts',
    (user: AuthUser) => ({
        payload: user
    })
);
