import api, { RateSheet } from '@api';
import {
    Button, MenuItem, Popover, Tooltip
} from '@mui/material';
import { ProgressIndicator, useAsyncEffect, usePageMessage } from '@tsp-ui/core';
import { useGetCurrentAccount } from '@utils';
import { useCallback, useState } from 'react';

import styles from './RateSheetLink.module.scss';


interface RateSheetLinkProps {
    rateSheetId: string | undefined;
}

export default function RateSheetLink({ rateSheetId }: RateSheetLinkProps) {
    const [ anchorEl, setAnchorEl ] = useState<HTMLButtonElement | null>(null);
    const pageMessage = usePageMessage();

    function handleDownloadMsg() {
        pageMessage.success('Downloaded rate sheet document successfully');
        setAnchorEl(null);
    }

    return (
        <>
            <Button
                className={styles.button}
                onClick={(event) => setAnchorEl(event.currentTarget)}
            >
                {rateSheetId}
            </Button>

            <Popover
                open={!!anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorEl={anchorEl}
                anchorOrigin={{
                    horizontal: 'right',
                    vertical: 'bottom'
                }}
                transformOrigin={{
                    horizontal: 'right',
                    vertical: 'top'
                }}
            >
                <RateSheetLinkPopoverContent
                    rateSheetId={rateSheetId!}
                    handleDownloadMsg={handleDownloadMsg}
                />
            </Popover>
        </>
    );
};

function RateSheetLinkPopoverContent(
    { rateSheetId, handleDownloadMsg }: { rateSheetId: string, handleDownloadMsg: () => void}
) {
    const [ rateSheet, setRateSheet ] = useState<RateSheet>();
    const [ loading, setLoading ] = useState(true);
    const pageMessage = usePageMessage();
    const { id: clientId, customerId } = useGetCurrentAccount();

    useAsyncEffect(useCallback(async () => {
        try {
            const rateSheet = await api.pricing.getRateSheetById(clientId, customerId, rateSheetId);
            setRateSheet(rateSheet);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching the rate sheet', error);
        }

        setLoading(false);
    }
    , [
        clientId, customerId, pageMessage, rateSheetId
    ]));
    return loading ? <ProgressIndicator className={styles.loader} /> : (
        <>
            <Tooltip title={!rateSheet?.excelUrl ? 'Excel rate sheet document not found' : ''}>
                <span>
                    <MenuItem
                        component="a"
                        href={rateSheet?.excelUrl}
                        disabled={!rateSheet?.excelUrl}
                        target="_blank"
                        onClick={() => handleDownloadMsg()}
                    >
                        View Excel
                    </MenuItem>
                </span>
            </Tooltip>

            <Tooltip title={!rateSheet?.pdfUrl ? 'No viewable PDF uploaded with this rate sheet' : ''}>
                <span>
                    <MenuItem
                        component="a"
                        href={rateSheet?.pdfUrl}
                        target="_blank"
                        disabled={!rateSheet?.pdfUrl}
                        onClick={() => handleDownloadMsg()}
                    >
                        View PDF
                    </MenuItem>
                </span>
            </Tooltip>
        </>
    );
}
