import api, { ActiveInactiveEnum, DueDiligenceConfig, PermissionType } from '@api';
import {
    ArrowDownward, ArrowUpward, Description, Edit
} from '@mui/icons-material';
import { Paper, Tooltip, Typography } from '@mui/material';
import { IconButton } from '@tsp-ui/core/components';
import { usePageMessage } from '@tsp-ui/core/utils';
import { useGetCurrentAccount, useHasPermission } from '@utils/hooks';
import { ActivateDeactivateButton } from '@views/components/ActivateDeactivateButton';
import clsx from 'clsx';
import React, { useState } from 'react';

import styles from './DueDiligenceConfigCard.module.scss';


interface DueDiligenceConfigCardProps {
    config: DueDiligenceConfig;
    refreshConfigs: () => Promise<void>;
    disableMoveDown: boolean;
    onEditClick?: () => void;
}

export default function DueDiligenceConfigCard({
    config, refreshConfigs, disableMoveDown, onEditClick
}: DueDiligenceConfigCardProps) {
    const { id: clientId } = useGetCurrentAccount();
    const pageMessage = usePageMessage();

    const [ toggleActiveLoading, setToggleActiveLoading ] = useState(false);
    const [ moveUpLoading, setMoveUpLoading ] = useState(false);
    const [ moveDownLoading, setMoveDownLoading ] = useState(false);

    const [ canEditDueDiligenceConfig ] = useHasPermission(
        [ PermissionType.EDIT_DUE_DILIGENCE_CONFIG ]
    );

    const {
        isRequired, isDocRequired, displayOrder, status
    } = config;

    const isActive = status === ActiveInactiveEnum.ACTIVE;

    async function moveStep(direction: 'up' | 'down') {
        const setMoveLoading = direction === 'up' ? setMoveUpLoading : setMoveDownLoading;
        setMoveLoading(true);

        try {
            await api.dueDiligenceConfig.moveStep(clientId, config.id, displayOrder! + (direction === 'up' ? -1 : 1));

            await refreshConfigs();

            pageMessage.success('Step moved');
        } catch (error) {
            pageMessage.handleApiError(
                `An error occurred while ${isActive ? 'deactivating' : 'activating'} the due-diligence step`, error
            );
        }

        setMoveLoading(false);
    }

    async function toggleActive() {
        try {
            setToggleActiveLoading(true);

            await api.dueDiligenceConfig.updateDueDiligenceConfig(clientId, {
                ...config,
                status: isActive ? ActiveInactiveEnum.INACTIVE : ActiveInactiveEnum.ACTIVE,
                displayOrder: undefined
            });

            await refreshConfigs();

            pageMessage.success(`Step ${isActive ? 'deactivated' : 'activated'}`);
        } catch (error) {
            pageMessage.handleApiError(
                `An error occurred while ${isActive ? 'deactivated' : 'activated'}activating the due-diligence step`, error
            );
        }

        setToggleActiveLoading(false);
    }

    return (
        <Paper
            className={styles.root}
            variant="outlined"
        >
            <div>
                <Typography>
                    {config.name}
                </Typography>

                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                    {config.description}
                </Typography>
            </div>

            <Tooltip
                title={isDocRequired ? 'Document required' : 'Document not required'}
                enterDelay={0}
            >
                <Description color={isDocRequired ? 'primary' : 'disabled'} />
            </Tooltip>

            <Typography
                variant="caption"
                className={clsx(styles.optionalChip, {
                    [styles.hidden]: isRequired
                })}
            >
                optional
            </Typography>

            <div>
                {isActive && (
                    <>
                        <IconButton
                            loading={moveUpLoading}
                            tooltip={!canEditDueDiligenceConfig ? 'You don\t have permission to move steps' : 'Move up'}
                            disabled={displayOrder === 1 || !displayOrder || !canEditDueDiligenceConfig}
                            onClick={() => moveStep('up')}
                        >
                            <ArrowUpward color="secondary" />
                        </IconButton>

                        <IconButton
                            loading={moveDownLoading}
                            tooltip={!canEditDueDiligenceConfig
                                ? 'You don\t have permission to move steps' : 'Move down'}
                            disabled={disableMoveDown || displayOrder === null || !canEditDueDiligenceConfig}
                            onClick={() => moveStep('down')}
                        >
                            <ArrowDownward color="secondary" />
                        </IconButton>

                        <IconButton
                            tooltip={!canEditDueDiligenceConfig ? 'You don\t have permission to edit steps' : 'Edit'}
                            onClick={onEditClick}
                            disabled={!canEditDueDiligenceConfig}
                        >
                            <Edit color="secondary" />
                        </IconButton>
                    </>
                )}

                <ActivateDeactivateButton
                    loading={toggleActiveLoading}
                    isActive={isActive}
                    toggleActive={toggleActive}
                    tooltip={!canEditDueDiligenceConfig
                        ? 'You don\t have permission to edit steps' : undefined}
                    disabled={!canEditDueDiligenceConfig}
                />
            </div>
        </Paper>
    );
}
