import { createDate, randomNum } from '@tsp-ui/core';
import { rest } from 'msw';

import {
    ActiveInactiveEnum, ApprovalStatusEnum, Document, DueDiligenceStep, DueDiligenceStepType
} from '../..';
import { getMockUrl } from '../../../mocks/getMockUrl';


export const SAMPLE_PDF_URL = 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf';
let documentID = 0;

export const mocks = [
    rest.get(getMockUrl('/client/:clientID/customer/:customerID/due-diligence-step'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.delay(500),
        ctx.json(dueDiligenceSteps)
    ))),
    rest.put(getMockUrl('/client/:clientID/customer/:customerID/due-diligence-step/:dueDiligenceStepID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.delay(500),
        ctx.json(req.body)
    ))),
    rest.post(getMockUrl('/client/:clientID/customer/:customerID/due-diligence-step/:dueDiligenceStepID/document'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(fileUploadToDocumentArray(req.body as FormData))
    ))),
    rest.delete(getMockUrl('/client/:clientID/customer/:customerID/due-diligence-step/:dueDiligenceStepID/document/:docID'), (req, res, ctx) => (res(
        ctx.delay(500),
        ctx.status(200)
    )))
];

function fileUploadToDocumentArray(formData: FormData): Document[] {
    const checklistDocs: Document[] = [];

    Object.values(formData).forEach(value => {
        checklistDocs.push({
            id: String(documentID++),
            url: `${SAMPLE_PDF_URL}#/${value.name}`,
            preSignedUrl: SAMPLE_PDF_URL,
            expiration: createDate(.001).toISOString()
        });
    });

    return checklistDocs;
}

export const dueDiligenceSteps: DueDiligenceStep[] = [
    {
        customerId: '1',
        id: '1',
        name: 'Customer application',
        description: 'Completed, signed and dated',
        type: DueDiligenceStepType.CUSTOM,
        dueDiligenceConfigId: '1',
        displayOrder: 1,
        approvalStatus: ApprovalStatusEnum.SUBMITTED,
        isRequired: true,
        isCompleted: false,
        completedDate: '',
        notes: '',
        isDocRequired: true,
        documents: getDocuments(),
        status: ActiveInactiveEnum.ACTIVE
    },
    {
        customerId: '1',
        id: '2',
        dueDiligenceConfigId: '1',
        displayOrder: 1,
        approvalStatus: ApprovalStatusEnum.IN_PROGRESS,
        name: 'Customer loan purchase & sale agreement',
        description: 'Completed, signed and dated',
        type: DueDiligenceStepType.CUSTOM,
        isRequired: true,
        isCompleted: false,
        completedDate: '',
        notes: '',
        isDocRequired: false,
        documents: getDocuments(),
        status: ActiveInactiveEnum.ACTIVE
    },
    {
        customerId: '1',
        id: '3',
        name: 'Officer/manager resumes',
        description: 'Resumes for all key officers/managers listed on the application',
        type: DueDiligenceStepType.CUSTOM,
        dueDiligenceConfigId: '1',
        displayOrder: 1,
        approvalStatus: ApprovalStatusEnum.IN_PROGRESS,
        isRequired: true,
        isCompleted: false,
        completedDate: '',
        notes: '',
        isDocRequired: false,
        documents: getDocuments(),
        status: ActiveInactiveEnum.ACTIVE
    },
    {
        customerId: '1',
        id: '4',
        name: 'Optional step',
        description: 'Resumes for all key officers/managers listed on the application',
        type: DueDiligenceStepType.CUSTOM,
        dueDiligenceConfigId: '1',
        displayOrder: 1,
        approvalStatus: ApprovalStatusEnum.APPROVED,
        isRequired: false,
        isCompleted: false,
        completedDate: '',
        notes: '',
        isDocRequired: false,
        documents: getDocuments(),
        status: ActiveInactiveEnum.ACTIVE
    },
    {
        customerId: '1',
        id: '5',
        name: 'Completed step',
        description: 'Resumes for all key officers/managers listed on the application',
        type: DueDiligenceStepType.CUSTOM,
        dueDiligenceConfigId: '1',
        displayOrder: 1,
        approvalStatus: ApprovalStatusEnum.REJECTED,
        isRequired: false,
        isCompleted: true,
        completedDate: '2021-10-31',
        notes: '',
        isDocRequired: false,
        documents: getDocuments(),
        status: ActiveInactiveEnum.ACTIVE
    },
    {
        customerId: '1',
        id: '6',
        name: 'Agency approval',
        description: 'This is where you fill out agency approvals',
        type: DueDiligenceStepType.AGENCY,
        dueDiligenceConfigId: '1',
        displayOrder: 1,
        approvalStatus: ApprovalStatusEnum.REJECTED,
        isRequired: true,
        isCompleted: false,
        completedDate: '',
        notes: '',
        isDocRequired: true,
        documents: getDocuments(),
        status: ActiveInactiveEnum.ACTIVE
    },
    {
        customerId: '1',
        id: '7',
        name: 'Investor relationships',
        description: 'This is where you fill out investor relationships',
        type: DueDiligenceStepType.INVESTORS,
        dueDiligenceConfigId: '1',
        displayOrder: 1,
        approvalStatus: ApprovalStatusEnum.SUBMITTED,
        isRequired: true,
        isCompleted: false,
        completedDate: '',
        notes: '',
        isDocRequired: true,
        documents: getDocuments(),
        status: ActiveInactiveEnum.ACTIVE
    },
    {
        customerId: '1',
        id: '8',
        name: 'Warehouse relationships',
        description: 'This is where you fill out warehouse relationships',
        type: DueDiligenceStepType.WAREHOUSE,
        dueDiligenceConfigId: '1',
        displayOrder: 1,
        approvalStatus: ApprovalStatusEnum.APPROVED,
        isRequired: true,
        isCompleted: false,
        completedDate: '',
        notes: '',
        isDocRequired: true,
        documents: getDocuments(),
        status: ActiveInactiveEnum.ACTIVE
    },
    {
        customerId: '1',
        id: '9',
        name: 'Mortgage insurance relationships',
        description: 'This is where you fill out mortgage insurance relationships',
        type: DueDiligenceStepType.INSURANCE,
        dueDiligenceConfigId: '1',
        displayOrder: 1,
        approvalStatus: ApprovalStatusEnum.IN_PROGRESS,
        isRequired: true,
        isCompleted: false,
        completedDate: '',
        notes: '',
        isDocRequired: true,
        documents: getDocuments(),
        status: ActiveInactiveEnum.ACTIVE
    },
    {
        customerId: '1',
        id: '10',
        name: 'License renewal',
        description: 'This is where you fill out state licenses',
        type: DueDiligenceStepType.STATE_LICENSE,
        dueDiligenceConfigId: '1',
        displayOrder: 1,
        approvalStatus: ApprovalStatusEnum.IN_PROGRESS,
        isRequired: true,
        isCompleted: false,
        completedDate: '',
        notes: '',
        isDocRequired: true,
        documents: getDocuments(),
        status: ActiveInactiveEnum.ACTIVE
    }
];

function getDocuments(): Document[] {
    return [ ...Array(randomNum(0, 12)) ].map(() => ({
        preSignedUrl: `${SAMPLE_PDF_URL}#/document-${documentID}`,
        url: `${SAMPLE_PDF_URL}#/document-${documentID}`,
        id: String(documentID++),
        expiration: createDate(.001).toISOString()
    }));
}
