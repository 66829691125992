import {
    FeeAmountPercentageOf, FeeAmountType, FeeType, RoundingMethod
} from '..';
import { apiUtils } from '../api-utils';


export function getPurchaseAdviceConfig(clientId: string): Promise<PurchaseAdviceConfig | undefined> {
    return apiUtils.get(`/client/${clientId}/purchaseAdvice`);
}

export function addPurchaseAdviceConfig(clientId: string, purchaseAdviceConfig: Omit<PurchaseAdviceConfig, 'id'>): Promise<PurchaseAdviceConfig> {
    return apiUtils.post(`/client/${clientId}/purchaseAdvice`, purchaseAdviceConfig);
}

export function updatePurchaseAdviceConfig(
    clientId: string, purchaseAdviceConfig: PurchaseAdviceConfig
): Promise<PurchaseAdviceConfig> {
    return apiUtils.put(`/client/${clientId}/purchaseAdvice`, purchaseAdviceConfig);
}

export function getPurchaseAdviceFees(clientId: string): Promise<PurchaseAdviceFee[]> {
    return apiUtils.get(`/client/${clientId}/purchaseAdvice/purchaseAdviceFee`);
}

export function addPurchaseAdviceFee(clientId: string, purchaseAdviceFee: Omit<PurchaseAdviceFee, 'feeId'>): Promise<PurchaseAdviceFee> {
    return apiUtils.post(`/client/${clientId}/purchaseAdvice/purchaseAdviceFee`, purchaseAdviceFee);
}

export function updatePurchaseAdviceFee(
    clientId: string, purchaseAdviceFee: PurchaseAdviceFee
): Promise<PurchaseAdviceFee> {
    return apiUtils.put(`/client/${clientId}/purchaseAdvice/purchaseAdviceFee/${purchaseAdviceFee.id}`, purchaseAdviceFee);
}

export function deletePurchaseAdviceFee(clientId: string, feeID: string): Promise<void> {
    return apiUtils.delete(`/client/${clientId}/purchaseAdvice/purchaseAdviceFee/${feeID}`);
}

export interface PurchaseAdviceConfig {
    id: number;
    interestRatePrecision: number;
    roundingMethod: RoundingMethod;
    cutoffDay: number;
}

export interface PurchaseAdviceFee {
    id: string;
    type: FeeType;
    amountType: FeeAmountType;
    amount?: number;
    percentage?: number;
    percentageOf?: FeeAmountPercentageOf;
    minAmount?: number;
    maxAmount?: number;
}
