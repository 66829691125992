import { rest } from 'msw';

import { getMockUrl } from '../../../mocks/getMockUrl';
import { EligibilityVersion } from '../../eligibility-version/eligibility-version-api';
import { mockedEligibilityVersions } from '../../eligibility-version/eligibility-version-mocks';


let eligibilityVersionId = 1;

export const mocks = [
    rest.get(getMockUrl('/client/:clientId/eligibility-version'), (_, res, ctx) => {
        mockedEligibilityVersions.sort((a, b) => (
            parseInt(a.id) - parseInt(b.id)
        ));

        return res(
            ctx.status(200),
            ctx.json(mockedEligibilityVersions)
        );
    }),
    rest.post(getMockUrl('/client/:clientId/eligibility-version'), (req, res, ctx) => {
        const newVersion = {
            ...req.body as EligibilityVersion,
            displayOrder: `${eligibilityVersionId}`,
            id: `${eligibilityVersionId++}`
        };

        mockedEligibilityVersions.push(newVersion);

        return res(
            ctx.status(200),
            ctx.json(newVersion)
        );
    }),
    rest.put(getMockUrl('/client/:clientId/eligibility-version/:eligibilityVersionId'), (req, res, ctx) => {
        const updatedVersion = req.body as EligibilityVersion;

        const indexToUpdate = mockedEligibilityVersions.findIndex(({ id }) => id === updatedVersion.id);
        mockedEligibilityVersions.splice(indexToUpdate, 1, updatedVersion);

        return res(
            ctx.status(200),
            ctx.json(updatedVersion),
            ctx.delay(2000)
        );
    }),
    rest.delete(getMockUrl('/client/:clientId/eligibility-version/:eligibilityVersionId'), (req, res, ctx) => {
        const { eligibilityVersionId } = req.params;

        const itemIndex = mockedEligibilityVersions.findIndex(({ id }) => id === eligibilityVersionId);
        mockedEligibilityVersions.splice(itemIndex, 1);

        return res(
            ctx.status(204)
        );
    })
];

