import { WarehouseRelationship } from '@api';
import {
    CurrencyField, PhoneField, TextField, isEmail
} from '@tsp-ui/core';
import { FormProvider, useForm } from 'react-hook-form';

import styles from './WarehouseRelationshipForm.module.scss';


interface WarehouseRelationshipFormProps {
    defaultValues?: WarehouseRelationship;
    handleSubmit(warehouseRelationship: WarehouseRelationship): void;
}

export function WarehouseRelationshipForm(
    { defaultValues, handleSubmit: handleSubmitProp }: WarehouseRelationshipFormProps
) {
    const formMethods = useForm<WarehouseRelationship>({ defaultValues });
    const handleSubmit = formMethods.handleSubmit(handleSubmitProp);

    return (
        <form
            noValidate
            id={`warehouse-relationship-${defaultValues?.id || 'new'}`}
            onSubmit={handleSubmit}
            className={styles.formContent}
        >
            <FormProvider {...formMethods}>
                <TextField<WarehouseRelationship>
                    name="lenderName"
                    label="Lender name"
                    size="small"
                    variant="outlined"
                    required
                />

                <CurrencyField<WarehouseRelationship>
                    name="lineAmount"
                    label="Line amount"
                    size="small"
                    variant="outlined"
                    required
                />

                <TextField<WarehouseRelationship>
                    name="contact"
                    label="Contact name"
                    size="small"
                    variant="outlined"
                    required
                />

                <PhoneField<WarehouseRelationship>
                    name="phone"
                    label="Phone #"
                    size="small"
                    variant="outlined"
                    required
                />

                <TextField<WarehouseRelationship>
                    name="emailAddress"
                    label="Email address"
                    size="small"
                    variant="outlined"
                    required
                    rules={isEmail}
                />
            </FormProvider>
        </form>
    );
}
