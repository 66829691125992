import { ActiveInactiveEnum } from '@api';
import { rest } from 'msw';

import { getMockUrl } from '../../../mocks/getMockUrl';

import { WarehouseRelationship } from './warehouse-relationship-api';


export const mocks = [
    rest.get(getMockUrl('/client/:clientID/customer/:customerID/warehouse-relationship'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.delay(500),
        ctx.json(warehouseRelationships)
    ))),
    rest.post(getMockUrl('/client/:clientID/customer/:customerID/warehouse-relationship'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json({
            ...req.body as WarehouseRelationship,
            id: String(warehouseRelationshipID++)
        })
    ))),
    rest.put(getMockUrl('/client/:clientID/customer/:customerID/warehouse-relationship/:warehouseRelationshipID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(req.body)
    )))
];

let warehouseRelationshipID = 0;
const warehouseRelationships: WarehouseRelationship[] = [
    {
        id: String(warehouseRelationshipID++),
        lenderName: 'Fannie Mae',
        lineAmount: 1000000,
        contact: 'John Doe',
        phone: '123-456-7890',
        emailAddress: 'email@email.com',
        status: ActiveInactiveEnum.ACTIVE
    }
];
