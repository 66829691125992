import { rest } from 'msw';

import { getMockUrl } from '../../mocks/getMockUrl';
import { PricingFieldName } from '../enums/pricingFieldName';

import { FieldConfig } from './field-config-api';


let fieldConfigs: FieldConfig[] = Object.values(PricingFieldName).map((fieldName, index) => ({
    id: `${index}`,
    fieldName,
    enabled: true  // All fields start as enabled by default
}));

export const mocks = [
    rest.get(getMockUrl('/client/:clientID/field-config'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.json(fieldConfigs)
    )),

    rest.put(getMockUrl('/client/:clientID/field-config'), async (req, res, ctx) => {
        const updatedFieldConfigs = await req.json() as FieldConfig[];

        fieldConfigs = updatedFieldConfigs;

        return res(
            ctx.status(200),
            ctx.json(updatedFieldConfigs)
        );
    })
];
