import { ActiveInactiveEnum } from '@api';
import { rest } from 'msw';

import { getMockUrl } from '../../../mocks/getMockUrl';

import { MICompanyRelationship } from './mi-company-relationship-api';


export const mocks = [
    rest.get(getMockUrl('/client/:clientID/customer/:customerID/mi-company-relationship'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.delay(500),
        ctx.json(miCompanyRelationships)
    ))),
    rest.post(getMockUrl('/client/:clientID/customer/:customerID/mi-company-relationship'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json({
            ...req.body as MICompanyRelationship,
            id: String(miCompanyRelationshipID++)
        })
    ))),
    rest.put(getMockUrl('/client/:clientID/customer/:customerID/mi-company-relationship/:miCompanyRelationshipID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(req.body)
    )))
];

let miCompanyRelationshipID = 0;
const miCompanyRelationships: MICompanyRelationship[] = [
    {
        id: String(miCompanyRelationshipID++),
        insurerName: 'Real Insurer',
        policyNumber: 'Super Policy #1',
        contact: 'Mr Insurance',
        phone: '1111111111',
        emailAddress: 'MrInsurance@insurance.com',
        status: ActiveInactiveEnum.ACTIVE
    },
    {
        id: String(miCompanyRelationshipID++),
        insurerName: 'Other Insurer',
        policyNumber: 'Cool Policy #2',
        contact: 'Ms Insurance',
        phone: '2222222222',
        emailAddress: 'MsInsurance@insurance.com',
        status: ActiveInactiveEnum.ACTIVE
    }
];
