import { apiUtils } from '../../api-utils';


export function getWireInfo(clientID: string, customerID: string): Promise<CustomerWireInfo[]> {
    return apiUtils.get(`/client/${clientID}/customer/${customerID}/wireInfo`);
}

export function createWireInfo(
    clientID: string,
    customerID: string,
    wireInfo: Omit<CustomerWireInfo, 'customerId' | 'id' | 'isApproved' | 'isActive'>
): Promise<CustomerWireInfo> {
    return apiUtils.post(`/client/${clientID}/customer/${customerID}/wireInfo`, wireInfo);
}

export function updateWireInfo(clientID: string, wireInfo: CustomerWireInfo): Promise<CustomerWireInfo> {
    return apiUtils.put(`/client/${clientID}/customer/${wireInfo.customerId}/wireInfo/${wireInfo.id}`, wireInfo);
}

export interface CustomerWireInfo {
    id: string;
    customerId: number;
    isActive: boolean;
    isApproved: boolean;
    bankName: string;
    bankCity: string;
    bankState: string;
    bankAba: string;
    bankAccountNum: string;
    wireDestination: string;
    furtherCreditToAcctName?: string;
    furtherCreditToAcctNum?: string;
}
