import api, { PermissionType, ReferenceGuideFile } from '@api';
import { Edit, RemoveCircleOutline } from '@mui/icons-material';
import { Paper, Typography } from '@mui/material';
import {
    DateTypography, IconButton, ProgressIndicator, useConfirm
} from '@tsp-ui/core/components';
import { removeItemById, replaceItemById, usePageMessage } from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils/hooks';
import { useHasPermission } from '@utils/hooks/useHasPermission';
import { DocumentLink } from '@views/components/DocumentLink';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import { ReferenceGuideManagementPageContext } from '../ClientReferenceGuideManagementPage';

import styles from './ClientReferenceGuideCard.module.scss';


interface ClientReferenceGuideCardProps {
    referenceGuideFile: ReferenceGuideFile;
}

export function ClientReferenceGuideCard({ referenceGuideFile }: ClientReferenceGuideCardProps) {
    const {
        name, document, id, sectionId, description, createdAt
    } = referenceGuideFile;

    const { id: clientID } = useGetCurrentAccount();
    const [ isDeleting, setIsDeleting ] = useState(false);
    const { setEntities: setReferenceGuideSections } = useContext(ReferenceGuideManagementPageContext);

    const confirm = useConfirm();
    const pageMessage = usePageMessage();

    const [ canManageReferenceGuides ] = useHasPermission([ PermissionType.MANAGE_REFERENCE_GUIDES ]);

    return (
        <Paper
            elevation={0}
            className={styles.card}
        >
            <div>
                <DocumentLink
                    name={name}
                    document={document}
                />

                <Typography variant="caption">
                    {description}
                </Typography>
            </div>

            <div>
                <DateTypography
                    variant="caption"
                    date={createdAt}
                />

                <div className={styles.buttons}>
                    <IconButton
                        tooltip={canManageReferenceGuides ? 'Edit file' : 'You do not have permission to edit files'}
                        disabled={!canManageReferenceGuides}
                        component={Link}
                        to={`${sectionId}/${id}/edit`}
                    >
                        <Edit color="secondary" />
                    </IconButton>

                    {isDeleting ? <ProgressIndicator className={styles.progress} /> : (
                        <IconButton
                            tooltip={canManageReferenceGuides ? 'Remove file' : 'You do not have permission to remove files'}
                            disabled={!canManageReferenceGuides}
                            onClick={async () => {
                                try {
                                    if (await confirm('Are you sure you want to delete this reference guide?')) {
                                        setIsDeleting(true);

                                        await api.referenceGuides.deleteReferenceGuide(clientID, sectionId, id);

                                        setReferenceGuideSections(referenceGuideSections => {
                                            const referenceGuideSection = referenceGuideSections.find(
                                                ({ id }) => id === sectionId
                                            )!;

                                            return replaceItemById(referenceGuideSections, {
                                                ...referenceGuideSection,
                                                files: removeItemById(
                                                    referenceGuideSection.files, id
                                                )
                                            });
                                        });

                                        pageMessage.success('Reference guide deleted');
                                    }
                                } catch (error) {
                                    pageMessage.handleApiError(
                                        'An error occurred while deleting the reference guide item',
                                        error
                                    );
                                } finally {
                                    setIsDeleting(false);
                                }
                            }}
                        >
                            <RemoveCircleOutline color="error" />
                        </IconButton>
                    )}
                </div>
            </div>
        </Paper>
    );
}
