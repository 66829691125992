import api, {
    Contact, ContactTitle, PermissionType, contactTitleDisplay
} from '@api';
import { Edit, RemoveCircleOutline, Star } from '@mui/icons-material';
import {
    Paper, Tooltip, Typography
} from '@mui/material';
import {
    IconButton, isEnumValue, useConfirm, usePageMessage, useParams
} from '@tsp-ui/core';
import { tooltipTitle } from '@utils';
import { useActingClientID, useHasPermission } from '@utils/hooks';
import { AdminRouteParams } from '@views/admin/components/AdminPageTemplate';
import { Dispatch, SetStateAction, useState } from 'react';

import ContactDisplay from '../ContactDisplay';

import styles from './ContactCard.module.scss';


interface ContactCardProps {
    contact: Contact;
    setContacts: Dispatch<SetStateAction<Contact[]>>;
    onEditClick: (contact: Contact) => void;
}

export default function ContactCard({ contact, setContacts, onEditClick }: ContactCardProps) {
    const { isPrimary, title, id } = contact;

    const confirm = useConfirm();
    const pageMessage = usePageMessage();
    const clientID = useActingClientID();
    const { customerID } = useParams<AdminRouteParams<'customer'>>();

    const [ deleteLoading, setDeleteLoading ] = useState(false);
    async function handleDelete() {
        if (await confirm('Are you sure you want to delete this contact?')) {
            try {
                setDeleteLoading(true);
                await api.customer.contacts.deleteContact(clientID, customerID, id);

                setContacts((contacts) => contacts.filter(contact => contact.id !== id));

                pageMessage.success('Contact deleted');
            } catch (error) {
                pageMessage.handleApiError('An error occurred while deleting the contact', error);
            }

            setDeleteLoading(false);
        }
    }

    const [ canManageContacts ] = useHasPermission([ PermissionType.MANAGE_CUSTOMER_CONTACTS ]);

    return (
        <Paper
            variant="outlined"
            className={styles.root}
        >
            <Typography
                className={styles.description}
                color="textSecondary"
            >
                {isPrimary && (
                    <Tooltip title="Primary contact">
                        <Star
                            color="primary"
                            fontSize="small"
                            className={styles.primaryContactIcon}
                        />
                    </Tooltip>
                )}

                {isEnumValue(title, ContactTitle) ? contactTitleDisplay[title] : title}
            </Typography>

            <div className={styles.actionsContainer}>
                <IconButton
                    tooltip={!canManageContacts ? 'You do not have permission to edit a contact' : 'Edit Contact'}
                    disabled={!canManageContacts}
                    onClick={() => onEditClick(contact)}
                >
                    <Edit color="secondary" />
                </IconButton>

                <IconButton
                    edge="end"
                    tooltip={tooltipTitle({
                        'You do not have permission to delete a contact': !canManageContacts,
                        'The primary contact cannot be deleted': isPrimary,
                        'Delete contact': true
                    })}
                    disabled={!canManageContacts || isPrimary}
                    onClick={handleDelete}
                    loading={deleteLoading}
                >
                    <RemoveCircleOutline color={isPrimary || !canManageContacts ? 'disabled' : 'error'} />
                </IconButton>
            </div>

            <ContactDisplay
                className={styles.contactInfoContainer}
                contact={contact}
            />
        </Paper>
    );
}
