import api, { PermissionType, Role } from '@api';
import { ContentCopy, RemoveCircleOutline } from '@mui/icons-material';
import { Paper, Typography } from '@mui/material';
import { IconButton, ProgressIndicator, SubtleLink } from '@tsp-ui/core/components';
import { useConfirm, usePageMessage } from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils/hooks';
import { useHasPermission } from '@utils/hooks/useHasPermission';
import { MouseEventHandler, useContext, useState } from 'react';

import { RoleManagementPageContext } from '../RoleManagementPage';

import styles from './RoleCard.module.scss';


interface RoleCardProps {
    role: Role;
}

export function RoleCard({
    role: {
        id: roleID, name, description, type: roleType, isInUse
    }
}: RoleCardProps) {
    const [ isCloning, setIsCloning ] = useState(false);
    const [ isDeleting, setIsDeleting ] = useState(false);

    const { entities: roles, setEntities: setRoles } = useContext(RoleManagementPageContext);
    const pageMessage = usePageMessage();
    const confirm = useConfirm();

    const { id: clientID } = useGetCurrentAccount();

    const [ canClone, canDelete ] = useHasPermission([ PermissionType.CLONE_ROLES, PermissionType.DELETE_ROLES ]);

    const cloneRole: MouseEventHandler<HTMLButtonElement> = async event => {
        event.preventDefault();

        setIsCloning(true);

        try {
            setRoles(roles.concat([
                await api.roles.addRole(clientID, {
                    name: `${name} copy`,
                    description,
                    type: roleType,
                    roleIdToCloneFrom: roleID,
                    isInUse: false
                })
            ]));

            pageMessage.success('Role cloned');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while cloning the role', error);
        }

        setIsCloning(false);
    };

    const deleteRole: MouseEventHandler<HTMLButtonElement> = async event => {
        event.preventDefault();

        setIsDeleting(true);

        try {
            if (await confirm('Are you sure you want to delete this role?')) {
                await api.roles.deleteRole(clientID, roleID);

                setRoles(roles.filter(role => role.id !== roleID));

                pageMessage.success('Role deleted');
            }
        } catch (error) {
            pageMessage.handleApiError('An error occurred while deleting the role', error);
        }

        setIsDeleting(false);
    };

    return (
        <Paper
            className={styles.root}
            elevation={0}
        >
            <div>
                <span>
                    <SubtleLink to={`${roleID}`}>
                        {name}
                    </SubtleLink>

                </span>

                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                    {description}
                </Typography>
            </div>

            <div className={styles.rightContainer}>
                <div className={styles.buttonContainer}>
                    {isCloning ? (
                        <ProgressIndicator className={styles.progress} />
                    ) : (
                        <IconButton
                            tooltip={canClone ? 'Clone' : 'You do not have permission to clone roles'}
                            onClick={cloneRole}
                            disabled={!canClone}
                        >
                            <ContentCopy color="secondary" />
                        </IconButton>

                    )}

                    {isDeleting ? (
                        <ProgressIndicator className={styles.progress} />
                    ) : (
                        <IconButton
                            tooltip={canDelete
                                ? (isInUse ? 'Cannot delete a role that is currently in use' : 'Delete')
                                : 'You do not have permission to delete roles'}
                            onClick={deleteRole}
                            disabled={!canDelete || isInUse}
                        >
                            <RemoveCircleOutline color="error" />
                        </IconButton>
                    )}
                </div>
            </div>
        </Paper>
    );
}
