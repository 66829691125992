import api, { ClientInvestor, LLPA } from '@api';
import {
    SentryRoutes, useAsyncEffect, usePageMessage
} from '@tsp-ui/core';
import { useActingClientID } from '@utils/hooks';
import LLPAManagementPage from '@views/admin/llpas/LLPAManagementPage';
import {
    createContext, useCallback, useMemo, useState
} from 'react';
import { Route } from 'react-router-dom';

import AddLLPAPage from './AddLLPAPage';
import LLPADetailPage from './LLPADetailPage';


interface LLPARoutesContextValues {
    llpas: LLPA[] | undefined;
    setLlpas: (llpas: LLPA[] | undefined) => void;
    investors: ClientInvestor[] | undefined;
}

export const LLPARoutesContext = createContext<LLPARoutesContextValues>({
    llpas: undefined,
    setLlpas: () => {},
    investors: undefined
});

export default function LLPARoutes() {
    const [ llpas, setLlpas ] = useState<LLPA[]>();
    const [ investors, setInvestors ] = useState<ClientInvestor[]>();

    const pageMessage = usePageMessage();
    const clientId = useActingClientID();

    useAsyncEffect(useCallback(async () => {
        try {
            const [ llpas, investors ] = await Promise.all([
                api.llpa.getLLPAs(clientId),
                api.client.investors.getInvestors(clientId)
            ]);

            setLlpas(llpas);
            setInvestors(investors);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching llpas', error);
        }
    }, [ pageMessage, clientId ]));

    const contextValue = useMemo(() => ({
        llpas,
        setLlpas,
        investors
    }), [ llpas, investors ]);

    return (
        <LLPARoutesContext.Provider value={contextValue}>
            <SentryRoutes>
                <Route
                    path="*"
                    element={<LLPAManagementPage />}
                />

                <Route
                    path="add"
                    element={<AddLLPAPage />}
                />

                <Route
                    path=":llpaId/*"
                    element={(
                        <LLPADetailPage />
                    )}
                />
            </SentryRoutes>
        </LLPARoutesContext.Provider>
    );
}
