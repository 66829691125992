import api, { EligibilityMatrix } from '@api';
import { Button, DialogContent } from '@mui/material';
import { useParams } from '@tsp-ui/core';
import {
    DialogActions, RoutedDialog, RoutedDialogImplProps, usePageMessage
} from '@tsp-ui/core/components';
import { useActingClientID } from '@utils/hooks';
import { AdminSubRouteParams } from '@views/admin/components/AdminPageTemplate';
import {
    InternalLoanProgramDetailPageContext
} from '@views/admin/investors/InvestorDetailPage/LoanProgramDetailPage/InternalLoanProgramDetailPage';
import { useEligibilityVersionIdQueryParam } from '@views/admin/investors/components/EligibilityVersionButton';
import {
    Dispatch, SetStateAction, useContext, useState
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';


import styles from './EditInternalEligibilityMatrixDialog.module.scss';
import {
    EligibilityMatrixFormValues,
    EligibilityMatrixGrid, formValuesToMatrix,
    matrixToFormValues
} from './LoanProgramForm';


export function EditInternalEligibilityMatrixDialog(props: RoutedDialogImplProps) {
    const { investorID, loanProgramID } = useParams<AdminSubRouteParams<'loanProgram'>>();
    const { matrix, setMatrix } = useContext(InternalLoanProgramDetailPageContext);

    async function handleSubmit(matrixToSubmit: EligibilityMatrix) {
        setMatrix(await api.investors.updateEligibilityMatrix(investorID, loanProgramID, matrixToSubmit));
    }

    return (
        <EditEligibilityMatrixDialog
            matrix={matrix}
            handleSubmit={handleSubmit}
            {...props}
        />
    );
}

export function EditClientEligibilityMatrixDialog(props: RoutedDialogImplProps) {
    const { investorID, loanProgramID } = useParams<AdminSubRouteParams<'loanProgram'>>();
    const { matrix, setMatrix } = useContext(InternalLoanProgramDetailPageContext);
    const clientId = useActingClientID();

    async function handleSubmit(matrixToSubmit: EligibilityMatrix) {
        setMatrix(
            await api.client.investors.updateEligibilityMatrix(clientId, investorID, loanProgramID, matrixToSubmit)
        );
    }

    return (
        <EditEligibilityMatrixDialog
            matrix={matrix}
            handleSubmit={handleSubmit}
            {...props}
        />
    );
}

interface EditEligibilityMatrixDialogProps extends RoutedDialogImplProps {
    matrix: EligibilityMatrix | undefined;
    handleSubmit: (matrixToSubmit: EligibilityMatrix) => Promise<void>;
}

export function EditEligibilityMatrixDialog({ matrix, handleSubmit, ...props }: EditEligibilityMatrixDialogProps) {
    const [ saveLoading, setSaveLoading ] = useState(false);

    return (
        <RoutedDialog
            {...props}
            title="Edit eligibility matrix"
            loading={!matrix}
            classes={{ paper: styles.root }}
            maxWidth={false}
            fullWidth
            keepLocationSearch
        >
            <DialogContent>
                {matrix && (
                    <EditEligibilityMatrixForm
                        matrix={matrix}
                        setLoading={setSaveLoading}
                        handleSubmit={handleSubmit}
                    />
                )}
            </DialogContent>

            <DialogActions loading={saveLoading}>
                <Button
                    variant="contained"
                    type="submit"
                    form={formId}
                >
                    Save
                </Button>
            </DialogActions>
        </RoutedDialog>
    );
}

interface EditEligibilityMatrixFormValues {
    matrix: EligibilityMatrixFormValues;
}

interface EditEligibilityMatrixFormProps {
    matrix: EligibilityMatrix;
    setLoading: Dispatch<SetStateAction<boolean>>;
    handleSubmit: (matrixToSubmit: EligibilityMatrix) => Promise<void>;
}

function EditEligibilityMatrixForm({
    matrix, setLoading, handleSubmit: handleSubmitProp
}: EditEligibilityMatrixFormProps) {
    const pageMessage = usePageMessage();
    const { search } = useLocation();
    const navigate = useNavigate();
    const eligibilityVersionId = useEligibilityVersionIdQueryParam();

    const formMethods = useForm<EditEligibilityMatrixFormValues>({
        defaultValues: {
            matrix: matrixToFormValues(matrix)
        }
    });

    const handleSubmit = formMethods.handleSubmit(async (formValues) => {
        try {
            setLoading(true);

            await handleSubmitProp({
                ...formValuesToMatrix(formValues.matrix, eligibilityVersionId!),
                exclusions: matrix?.exclusions!
            });

            navigate(`..${search}`);
            pageMessage.success('Eligibility matrix saved');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving the eligibility matrix', error);
        }

        setLoading(false);
    });

    return (
        <form
            id={formId}
            noValidate
            onSubmit={handleSubmit}
            className={styles.form}
        >
            <FormProvider {...formMethods}>
                <EligibilityMatrixGrid />
            </FormProvider>
        </form>
    );
}

const formId = 'edit-eligibility-matrix-form';
