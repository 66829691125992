import { CommonLoanProgram, LoanProgramStatus } from '@api';
import { Paper, Typography } from '@mui/material';
import { SubtleLink } from '@tsp-ui/core/components';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';

import styles from './LoanProgramCard.module.scss';


interface LoanProgramCardProps {
    loanProgram: CommonLoanProgram;
}

export function LoanProgramCard({
    loanProgram: {
        name, description, id, status
    }
}: LoanProgramCardProps) {
    const { search } = useLocation();

    return (
        <Paper
            elevation={0}
            className={clsx(styles.root, {
                [styles.inactive]: status === LoanProgramStatus.INACTIVE
            })}
        >
            <SubtleLink to={`loan-programs/${id}${search}`}>
                {name}
            </SubtleLink>

            <Typography
                variant="body2"
                color="textSecondary"
            >
                {description}
            </Typography>
        </Paper>
    );
}
