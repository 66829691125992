import {
    ActionCardsType, CustomerHistoryEvent, customerActionDisplay
} from '@api';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
    IconButton, Paper, Tooltip, Typography
} from '@mui/material';
import { getFullName } from '@tsp-ui/core/utils';
import clsx from 'clsx';
import { parseISO } from 'date-fns';
import { useState } from 'react';

import DynamicCardDisplay, { customerActionCards } from './DynamicCardDisplay';
import styles from './HistoryEventCard.module.scss';


interface HistoryEventCardProps {
    event: CustomerHistoryEvent;
}

export default function HistoryEventCard({ event } : HistoryEventCardProps) {
    const [ expanded, setExpanded ] = useState(false);
    const {
        id, action, executedBy, date, details
    } = event;

    return (
        <Paper
            key={id}
            variant="outlined"
            className={styles.root}
        >
            <div className={styles.row}>
                <div>
                    <Typography>
                        {customerActionDisplay[action]}
                    </Typography>

                    <Typography
                        variant="body2"
                        component="p"
                    >
                        By {getFullName(executedBy)}
                    </Typography>
                </div>

                <div className={styles.eventsContainer}>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                    >
                        {parseISO(date).toLocaleString()}
                    </Typography>

                    <Tooltip title={`Show ${expanded ? 'less' : 'more'}`}>
                        <IconButton
                            edge="end"
                            className={styles.expandButton}
                            onClick={() => setExpanded(!expanded)}
                        >
                            {expanded ? (
                                <ExpandLess color="secondary" />
                            ) : (
                                <ExpandMore color="secondary" />
                            )}
                        </IconButton>
                    </Tooltip>
                </div>
            </div>

            {expanded && (
                <div
                    className={clsx({
                        [styles.diffContainer]: customerActionCards[action] !== ActionCardsType.DUE_DILIGENCE
                    })}
                >
                    {details.before ? (
                        <>
                            <div>
                                <Typography
                                    variant="caption"
                                    color="textSecondary"
                                >
                                    Before:
                                </Typography>

                                <DynamicCardDisplay
                                    item={details.before}
                                    action={action}
                                />

                            </div>

                            <div>
                                <Typography
                                    variant="caption"
                                    color="textSecondary"
                                >
                                    After:
                                </Typography>

                                <DynamicCardDisplay
                                    item={details.after}
                                    action={action}
                                />

                            </div>
                        </>
                    ) : (
                        <div className={styles.fullWidth}>
                            <Typography
                                variant="caption"
                                color="textSecondary"
                            >
                                Details:
                            </Typography>

                            <DynamicCardDisplay
                                item={details.after}
                                action={action}
                            />
                        </div>
                    )}
                </div>
            )}
        </Paper>
    );
}
