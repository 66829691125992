import { ConfigurableValues } from '@utils';

import { apiUtils } from '../api-utils'; // eslint-disable-line import/order


export function getConfigurableValues(clientID: string): Promise<ConfigurableValues>  {
    return apiUtils.get(`/client/${clientID}/configurable-values`);
}

export function updateConfigurableValues(
    clientID: string, configurableValues: ConfigurableValues
): Promise<ConfigurableValues>  {
    return apiUtils.put(`/client/${clientID}/configurable-values`, configurableValues);
}
