import api, { PermissionType } from '@api';
import {
    Button, CircularProgress, FormLabel, Switch, Tooltip
} from '@mui/material';
import { ExpandableHeader, usePageMessage } from '@tsp-ui/core';
import {
    TogglableEnumValue, TogglableValuesKey, toSentenceCase, togglableValuesMasterList,
    useGetCurrentAccount, useHasPermission
} from '@utils';
import { ClientContext } from '@views/AuthenticatedRouteSwitch';
import { useContext, useState } from 'react';

import styles from './TogglableValuesItemCard.module.scss';


interface TogglableValuesItemCardProps {
    togglableValuesKey: TogglableValuesKey;
}

export function TogglableValuesItemCard(
    { togglableValuesKey }: TogglableValuesItemCardProps
) {
    const [ canManage ] = useHasPermission([ PermissionType.MANAGE_CONFIGURABLE_VALUES ]);
    const pageMessage = usePageMessage();
    const displayValues = togglableValuesMasterList[togglableValuesKey];
    const enumValues = Object.keys(displayValues) as TogglableEnumValue[];
    const { configurableValues, setConfigurableValues } = useContext(ClientContext);
    const defaultValues = configurableValues?.togglableValues[togglableValuesKey] || [];

    const [ values, setValues ] = useState<(string | number)[]>(defaultValues);
    const [ saveLoading, setSaveLoading ] = useState(false);

    const clientId = useGetCurrentAccount().id;

    return (
        <ExpandableHeader
            defaultExpand={false}
            disableExpand={false}
            title={toSentenceCase(togglableValuesKey)}
            secondaryText={(
                <span className={styles.secondaryText}>
                    {`${values.length}/${enumValues.length} active`}
                </span>
            )}
            expandedContent={(
                <div className={styles.switches}>
                    {enumValues.map(value => (
                        <>
                            <FormLabel>
                                {displayValues[value]}
                            </FormLabel>

                            <Tooltip title={canManage ? '' : 'You do not have permission to toggle values'}>
                                <Switch
                                    checked={values.includes(value)}
                                    disabled={!canManage}
                                    onChange={(event) => {
                                        if (event.target.checked) {
                                            setValues([ ...values, value ]);
                                        } else {
                                            setValues(values.filter(v => v !== value));
                                        }
                                    }}
                                />
                            </Tooltip>
                        </>
                    ))}

                    <span className={styles.saveButton}>
                        {saveLoading && (
                            <CircularProgress
                                size={32}
                                className={styles.loading}
                            />
                        )}

                        <Button
                            variant="contained"
                            disabled={saveLoading}
                            onClick={async () => {
                                setSaveLoading(true);

                                try {
                                    setConfigurableValues(
                                        await api.configurableValues.updateConfigurableValues(clientId, {
                                            ...configurableValues!,
                                            togglableValues: {
                                                ...configurableValues!.togglableValues,
                                                [togglableValuesKey]: values
                                            }
                                        })
                                    );

                                    pageMessage.success('Values updated');
                                } catch (error) {
                                    pageMessage.handleApiError('An error occurred while updating values', error);
                                }

                                setSaveLoading(false);
                            }}
                        >
                            Save
                        </Button>
                    </span>

                </div>
            )}
        />
    );
}
