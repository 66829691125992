import {
    ExclusionOverlay, ExclusionOverlayType, PermissionType
} from '@api';
import { Edit, Visibility } from '@mui/icons-material';
import {
    ClickAwayListener, Link as MuiLink, Paper, Popover, Tooltip, Typography
} from '@mui/material';
import { IconButton, OptionalWrapper, useConfirm } from '@tsp-ui/core';
import { useHasPermission } from '@utils/hooks';
import { LoanProgramsContext } from '@views/admin/investors/InternalInvestorDetailRoutes';
import ExclusionsView from '@views/admin/investors/components/ExclusionsView';
import { useContext, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { InvestorEligibilityContext } from '../InvestorEligibilityRoutes';

import styles from './ExclusionOverlayCard.module.scss';


interface ExclusionOverlayCardProps {
    overlay: ExclusionOverlay;
    confirmMessage?: string;
}

export function ExclusionOverlayCard({ overlay, confirmMessage }: ExclusionOverlayCardProps) {
    const confirm = useConfirm();
    const navigate = useNavigate();
    const { search } = useLocation();

    const [ showPreview, setShowPreview ] = useState(false);

    const { isSelectedVersionEditable } = useContext(InvestorEligibilityContext);
    const [ canEdit ] = useHasPermission([ PermissionType.MANAGE_EXCLUSION_OVERLAYS ]);

    return (
        <Paper
            elevation={0}
            className={styles.root}
        >
            <div>
                <div className={styles.header}>
                    <Typography>
                        {overlay.name}
                    </Typography>
                </div>

                <Typography
                    variant="body2"
                    color="textSecondary"
                    className={styles.description}
                >
                    {overlay.description}
                </Typography>

                <Typography
                    color="textSecondary"
                    variant="caption"
                    component="div"
                >
                    {overlay.type === ExclusionOverlayType.LOAN_PROGRAM ? (
                        'Only applies to this loan program'
                    ) : (
                        <>
                            Applies to{' '}

                            {overlay.loanProgramIds?.includes('ALL')
                                ? 'all loan programs'
                                : <LoanProgramsPopover loanProgramIds={overlay.loanProgramIds} />}{' '}

                            from{' '}

                            {overlay.type === ExclusionOverlayType.INVESTOR
                                ? 'this investor'
                                : overlay.investorIds?.includes('ALL')
                                    ? 'all investors'
                                    : <InvestorsPopover investorIds={overlay.investorIds} />}
                        </>
                    )}
                </Typography>
            </div>

            <div>
                <ClickAwayListener onClickAway={() => setShowPreview(false)}>
                    <span>
                        <Tooltip
                            onClose={() => setShowPreview(false)}
                            open={showPreview}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            placement="bottom-end"
                            classes={{
                                tooltip: styles.exclusionDetails
                            }}
                            title={<ExclusionsView exclusions={overlay.exclusions} />}
                        >
                            <span>
                                <IconButton
                                    tooltip="View details"
                                    onClick={() => setShowPreview(true)}
                                >
                                    <Visibility color="secondary" />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </span>
                </ClickAwayListener>

                <IconButton
                    component={Link}
                    to={`overlays/${overlay.id}/edit${search}`}
                    disabled={!canEdit || !isSelectedVersionEditable}
                    tooltip={!canEdit
                        ? 'You don\'t have permission to modify overlays'
                        : !isSelectedVersionEditable
                            ? 'You can only modify exclusion overlays for a future eligibility version'
                            : 'Edit'}
                    onClick={!confirmMessage ? undefined : (async (event) => {
                        event.preventDefault();

                        if (await confirm(confirmMessage)) {
                            navigate(`overlays/${overlay.id}/edit${search}`);
                        }
                    })}
                >
                    <Edit color="secondary" />
                </IconButton>
            </div>
        </Paper>
    );
}

function LoanProgramsPopover({ loanProgramIds }: { loanProgramIds?: string[] }) {
    if (!loanProgramIds) {
        loanProgramIds = [ 'ALL' ];
    }

    const isAll = loanProgramIds[0] === 'ALL';

    const { loanPrograms = [] } = useContext(LoanProgramsContext);
    const [ anchorEl, setAnchorEl ] = useState<HTMLElement>();

    return (
        <>
            <OptionalWrapper
                Component={MuiLink}
                renderWrapper={!!loanProgramIds.length && !isAll}
                href="#"
                onClick={(event) => {
                    event.preventDefault();
                    if (loanProgramIds?.length) {
                        setAnchorEl(event.currentTarget);
                    }
                }}
            >
                {`${isAll ? 'all' : loanProgramIds.length} Loan Program${(loanProgramIds.length === 1 && !isAll) ? '' : 's'}`}
            </OptionalWrapper>

            <Popover
                open={!!anchorEl}
                onClose={() => setAnchorEl(undefined)}
                anchorEl={anchorEl}
                anchorOrigin={{
                    horizontal: 'left',
                    vertical: 'bottom'
                }}
                transformOrigin={{
                    horizontal: 'left',
                    vertical: 'top'
                }}
            >
                <Typography className={styles.popover}>
                    {loanProgramIds.map(loanProgramId => loanPrograms.find(loanProgram => loanProgram.id === loanProgramId)?.name).join(', ')}
                </Typography>
            </Popover>
        </>
    );
}

function InvestorsPopover({ investorIds = [] }: { investorIds?: string[] }) {
    // TODO make this context available from within loan programs as well
    const { investors } = useContext(InvestorEligibilityContext);
    const [ anchorEl, setAnchorEl ] = useState<HTMLElement>();

    return (
        <>
            <OptionalWrapper
                Component={MuiLink}
                renderWrapper={!!investorIds.length}
                href="#"
                onClick={(event) => {
                    event.preventDefault();
                    if (investorIds.length) {
                        setAnchorEl(event.currentTarget);
                    }
                }}
            >
                {`${investorIds.length} Investor${investorIds.length === 1 ? '' : 's'}`}
            </OptionalWrapper>

            <Popover
                open={!!anchorEl}
                onClose={() => setAnchorEl(undefined)}
                anchorEl={anchorEl}
                anchorOrigin={{
                    horizontal: 'left',
                    vertical: 'bottom'
                }}
                transformOrigin={{
                    horizontal: 'left',
                    vertical: 'top'
                }}
            >
                <Typography className={styles.popover}>
                    {investorIds.map(investorId => investors?.find(investor => investor.id === investorId)?.name).join(', ')}
                </Typography>
            </Popover>
        </>
    );
}
