import { PermissionType } from '@api';
import { Typography } from '@mui/material';
import { useHasPermission } from '@utils/hooks/useHasPermission';
import EditableSectionCard from '@views/admin/components/EditableSectionCard';
import { useContext } from 'react';

import { ClientDetailsContext } from '../../ClientDetailPage';

import styles from './ThemeAndLogoCard.module.scss';


export function ThemeAndLogoCard() {
    const { client } = useContext(ClientDetailsContext);

    const [ canEditClient, canEditLogo ] = useHasPermission(
        [ PermissionType.EDIT_CLIENTS, PermissionType.EDIT_CLIENT_LOGO ]
    );

    return (
        <EditableSectionCard
            editTo="edit-theme"
            header="Theme configuration"
            disableEdit={!canEditClient && !canEditLogo}
            disabledTooltip="You do not have permission to edit this client's colors or logo"
        >
            <div className={styles.root}>
                <img
                    alt="account logo"
                    src={client?.logoUrl}
                    className={styles.logo}
                />

                <div className={styles.colorsContainer}>
                    <div className={styles.colorDisplayBox}>
                        <div
                            className={styles.colorDisplay}
                            style={{ backgroundColor: client?.primaryColor }}
                        />

                        <Typography variant="caption">
                            Primary color
                        </Typography>
                    </div>

                    <div className={styles.colorDisplayBox}>
                        <div
                            className={styles.colorDisplay}
                            style={{ backgroundColor: client?.secondaryColor }}
                        />

                        <Typography variant="caption">
                            Secondary color
                        </Typography>
                    </div>
                </div>
            </div>
        </EditableSectionCard>
    );
}
