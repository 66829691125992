import api, { ActiveInactiveEnum, DueDiligenceStep } from '@api';
import { Search } from '@mui/icons-material';
import {
    Button, Checkbox, DialogContent, FormControlLabel, InputAdornment, TextField, Typography
} from '@mui/material';
import { FilledSection, RoutedDialog, RoutedDialogProps } from '@tsp-ui/core/components';
import { replaceItemById, useAsyncEffect, usePageMessage } from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils/hooks';
import { useCallback, useContext, useState } from 'react';
import { useDebounce } from 'use-debounce';

import { CustomerDetailContext } from '../../CustomerDetailPage';

import styles from './DueDiligenceChecklistDialog.module.scss';
import DueDiligenceStepCard from './components/DueDiligenceStepCard';


export default function DueDiligenceChecklistDialog(props: Omit<RoutedDialogProps, 'title' | 'children'>) {
    const { customer, refreshCustomer } = useContext(CustomerDetailContext);
    const { id: customerID } = customer!;
    const { id: clientID } = useGetCurrentAccount();

    const [ showCompleted, setShowCompleted ] = useState(false);
    const [ showOptional, setShowOptional ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ saveLoading, setSaveLoading ] = useState(false);
    const [ steps, setSteps ] = useState<DueDiligenceStep[]>([]);

    const [ searchTerm, setSearchTerm ] = useState('');
    const [ debouncedTerm ] = useDebounce(searchTerm, 300);

    const pageMessage = usePageMessage();

    useAsyncEffect(useCallback(async () => {
        setLoading(true);

        try {
            setSteps(await api.customer.dueDiligenceStep.getDueDiligenceSteps(clientID, customerID));
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching the due diligence checklist', error);
        }

        setLoading(false);
    }, [
        clientID, customerID, pageMessage
    ]));

    const activeSteps = steps.filter(step => step.status === ActiveInactiveEnum.ACTIVE);
    const completeSteps = activeSteps.filter(step => step.isCompleted && filterByName(step.name));
    const incompleteSteps = activeSteps.filter(step => (
        !step.isCompleted && (showOptional || step.isRequired) && filterByName(step.name)
    ));
    const optionalSteps = activeSteps.filter(step => !step.isRequired);

    function filterByName(name: string) {
        return name.toLocaleLowerCase().includes(debouncedTerm);
    }
    function saveStep(savedStep: DueDiligenceStep, isCompletion?: boolean) {
        setSteps(replaceItemById(steps, savedStep));

        const isLastStep = activeSteps.filter(step => !step.isCompleted && step.isRequired).length === 1;

        if (isLastStep && isCompletion) {
            try {
                refreshCustomer();
            } catch (error) {
                pageMessage.handleApiError('An error occurred while marking due diligence as completed', error);
            }
        }
    }

    return (
        <RoutedDialog
            {...props}
            title="Due-diligence checklist"
            maxWidth={false}
            loading={loading}
            saveLoading={saveLoading}
        >
            <DialogContent>
                <div className={styles.filterContainer}>
                    <TextField
                        autoFocus
                        variant="standard"
                        placeholder="Filter checklist items"
                        onChange={e => setSearchTerm(e.target.value.toLocaleLowerCase())}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            )
                        }}
                    />

                    <Button onClick={() => setShowCompleted(!showCompleted)}>
                        {showCompleted ? 'Hide' : 'Show'} completed items
                    </Button>
                </div>

                <div className={styles.root}>
                    <FilledSection
                        className={styles.section}
                        variant="light"
                        header={(
                            <>
                                Pending items

                                <FormControlLabel
                                    control={<Checkbox />}
                                    checked={showOptional}
                                    onChange={() => setShowOptional(!showOptional)}
                                    label="Show optional"
                                    className={styles.showOptionalCheckbox}
                                />
                            </>
                        )}
                    >
                        {incompleteSteps.length ? (
                            incompleteSteps.map(step => (
                                <DueDiligenceStepCard
                                    key={step.id}
                                    dueDiligenceStep={step}
                                    onSave={saveStep}
                                    setSaveLoading={setSaveLoading}
                                />
                            ))
                        ) : (
                            (showOptional && optionalSteps.length) ? (
                                optionalSteps.map(step => (
                                    <DueDiligenceStepCard
                                        key={step.id}
                                        dueDiligenceStep={step}
                                        onSave={saveStep}
                                        setSaveLoading={setSaveLoading}
                                    />
                                ))
                            ) : (
                                <Typography>
                                    No pending items exist for this customer
                                </Typography>
                            )
                        )}
                    </FilledSection>

                    {showCompleted && (
                        <FilledSection
                            className={styles.section}
                            variant="light"
                            header="Completed items"
                        >
                            {completeSteps.length ? completeSteps.map(step => (
                                <DueDiligenceStepCard
                                    key={step.id}
                                    dueDiligenceStep={step}
                                    onSave={saveStep}
                                    setSaveLoading={setSaveLoading}
                                />
                            )) : (
                                <Typography>
                                    No completed items exist for this customer
                                </Typography>
                            )}
                        </FilledSection>
                    )}
                </div>
            </DialogContent>
        </RoutedDialog>
    );
}
