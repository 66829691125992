import api, { EligibilityVersion, Investor } from '@api';
import {
    useAsyncEffect, usePageMessage
} from '@tsp-ui/core';
import {
    isFuture, parseISO
} from 'date-fns';
import React, {
    Dispatch, SetStateAction, createContext, useCallback, useState
} from 'react';
import { useLocation } from 'react-router-dom';

import AdminPageTemplate, {
    AdminEntityGroup, AdminEntityGroupProps, AdminPageContextValues, defaultAdminPageContextValues
} from '../components/AdminPageTemplate';

import InternalInvestorDetailRoutes from './InternalInvestorDetailRoutes';
import InternalInvestorForm from './InvestorDetailPage/components/InternalInvestorForm';
import { EligibilityVersionButton, useEligibilityVersionIdQueryParam } from './components/EligibilityVersionButton';
import { InternalInvestorCard } from './components/InternalInvestorCard';


export const InvestorManagementPageContext = createContext<AdminPageContextValues<Investor>>(
    defaultAdminPageContextValues
);

interface InternalEligibilityVersionsContextValues {
    eligibilityVersions: EligibilityVersion[] | undefined;
    setEligibilityVersions: Dispatch<SetStateAction<EligibilityVersion[] | undefined>>;
    isSelectedVersionEditable: boolean;
}

export const InternalEligibilityVersionsContext = createContext<InternalEligibilityVersionsContextValues>({
    eligibilityVersions: undefined,
    setEligibilityVersions: () => {},
    isSelectedVersionEditable: false
});

export default function InvestorManagementPage() {
    const pageMessage = usePageMessage();
    const { search } = useLocation();
    const eligibilityVersionId = useEligibilityVersionIdQueryParam();

    const fetchEntities = useCallback(() => (
        api.investors.getInvestors(eligibilityVersionId)
    ), [ eligibilityVersionId ]);

    const [ eligibilityVersions, setEligibilityVersions ] = useState<EligibilityVersion[]>();
    useAsyncEffect(useCallback(async () => {
        try {
            setEligibilityVersions(await api.eligibilityVersion.getEligibilityVersions());
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching eligibility versions', error);
        }
    }, [ pageMessage ]));

    const selectedVersion = eligibilityVersions?.find(({ id }) => id === eligibilityVersionId);
    const isSelectedVersionEditable = !!selectedVersion && isFuture(parseISO(selectedVersion.effectiveDate));

    return (
        <InternalEligibilityVersionsContext.Provider
            value={{
                eligibilityVersions,
                setEligibilityVersions,
                isSelectedVersionEditable
            }}
        >
            <AdminPageTemplate
                entityName="investor"
                CreateEditForm={InternalInvestorForm}
                Context={InvestorManagementPageContext}
                DetailPage={InternalInvestorDetailRoutes}
                EntityGroupComponent={InvestorGroup}
                fetchEntities={fetchEntities}
                filterByLabel="name"
                filterEntity={({ name }, filterInputValue) => name.toLocaleLowerCase().includes(filterInputValue)}
                visibleGroups={[ 'Investors' ]}
                headerActions={(
                    <EligibilityVersionButton eligibilityVersions={eligibilityVersions} />
                )}
                editDetails
                routeQueryParam={search}
                disableAddEntity={!isSelectedVersionEditable}
                disabledAddTooltip="You can only add investors to a future eligibility version"
            />
        </InternalEligibilityVersionsContext.Provider>
    );
}

type InvestorGroupName = 'Investors';

function InvestorGroup({
    group, entities: investors
}: AdminEntityGroupProps<Investor, InvestorGroupName>) {
    return (
        <AdminEntityGroup
            header={group}
            noResultsMessage="No investors exist."
        >
            {investors.map(investor => (
                <InternalInvestorCard
                    key={investor.id}
                    investor={investor}
                />
            ))}
        </AdminEntityGroup>
    );
}
