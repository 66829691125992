import { createDate, randomBoolean } from '@tsp-ui/core';
import { rest } from 'msw';

import {
    Document, ReferenceGuideFile, ReferenceGuideSection, ReferenceGuideUpload
} from '..';
import { getMockUrl } from '../../mocks/getMockUrl';
import { SAMPLE_PDF_URL } from '../customer/due-diligence-step/due-diligence-step-mocks';


let referenceGuideSectionID = 4;
let referenceGuideID = 100;

export const mocks = [
    rest.get(getMockUrl('/client/:clientID/reference-guide-section'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(referenceGuideSections)
    ))),
    rest.post(getMockUrl('/client/:clientID/reference-guide-section'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json({
            ...req.body as ReferenceGuideSection,
            id: `${referenceGuideSectionID++}`,
            files: []
        })
    ))),
    rest.post(getMockUrl('/client/:clientID/reference-guide-section/:referenceGuideSectionID'), (req, res, ctx) => {
        const referenceGuideUpload: ReferenceGuideUpload = JSON.parse((req.body as { file: File, data: string }).data);
        const sectionId = req.params.referenceGuideSectionID as string;

        return res(
            ctx.status(200),
            ctx.json<ReferenceGuideFile>({
                id: `${referenceGuideSectionID++}`,
                name: referenceGuideUpload.name,
                description: referenceGuideUpload.description,
                sectionId,
                createdAt: new Date().toISOString(),
                document: {
                    id: `${documentID++}`,
                    url: SAMPLE_PDF_URL,
                    preSignedUrl: SAMPLE_PDF_URL,
                    expiration: createDate(.001).toISOString()
                }
            })
        );
    }),
    rest.put(getMockUrl('/client/:clientID/reference-guide-section/:referenceGuideSectionID/document/:referenceGuideID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(req.body)
    ))),
    rest.delete(getMockUrl('/client/:clientID/reference-guide-section/:referenceGuideSectionID/document/:referenceGuideID'), (req, res, ctx) => (res(
        ctx.status(200)
    ))),
    rest.delete(getMockUrl('/client/:clientID/reference-guide-section/:referenceGuideSectionID'), (req, res, ctx) => (res(
        ctx.status(200)
    )))
];

let documentID = 1234;
function createDocument(): Document {
    return {
        id: `${documentID++}`,
        url: SAMPLE_PDF_URL,
        preSignedUrl: SAMPLE_PDF_URL,
        expiration: createDate(randomBoolean() ? -.001 : .001).toISOString()
    };
}

const referenceGuideSections: ReferenceGuideSection[] = [
    {
        id: '2',
        title: 'Some section',
        files: [
            {
                id: `${referenceGuideID++}`,
                name: 'Some reference guide title',
                sectionId: '2',
                description: 'This is a description for a reference guide file. This file is used to describe a, b, and c.',
                createdAt: '2021-04-05T16:18:06.526Z',
                document: createDocument()
            },
            {
                id: `${referenceGuideID++}`,
                name: 'Another reference guide title',
                sectionId: '2',
                description: 'This is a description for a reference guide file. This file is used to describe d, e, and f.',
                createdAt: '2021-05-06T16:18:06.526Z',
                document: createDocument()
            }
        ]
    },
    {
        id: '3',
        title: 'Another section',
        files: [
            {
                id: `${referenceGuideID++}`,
                name: 'Additional reference guide title',
                sectionId: '3',
                description: 'This is a description for a reference guide file. This file is used to describe g, h, and i.',
                createdAt: '2021-06-07T16:18:06.526Z',
                document: createDocument()
            },
            {
                id: `${referenceGuideID++}`,
                name: 'Supplimental reference guide title',
                sectionId: '3',
                description: 'This is a description for a reference guide file. This file is used to describe x, y, and z.',
                createdAt: '2021-07-08T16:18:06.526Z',
                document: createDocument()
            }
        ]
    }
];

