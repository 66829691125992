import api, { CustomerSummary } from '@api';
import { MenuItem } from '@aws-amplify/ui-react';
import { Autocomplete, TextField } from '@mui/material';
import { useAsyncEffect, usePageMessage } from '@tsp-ui/core';
import { useGetCurrentAccount } from '@utils';
import React, {
    Dispatch, SetStateAction,
    useCallback,
    useState
} from 'react';


interface CustomerAutocompleteProps {
    className?: string;
    customerIdSearch?: string;
    setCustomerIdSearch: Dispatch<SetStateAction<string>> | undefined;
    isRequired?: boolean;
}

export default function CustomerAutocomplete({
    className,
    customerIdSearch,
    setCustomerIdSearch,
    isRequired
}: CustomerAutocompleteProps) {
    const pageMessage = usePageMessage();
    const { id: clientId } = useGetCurrentAccount();
    const [ customers, setCustomers ] = useState<CustomerSummary[]>([]);
    const [ errorText, setErrorText ] = useState('');
    const [ loading, setLoading ] = useState(false);

    useAsyncEffect(useCallback(async () => {
        setLoading(true);
        try {
            setCustomers(await api.customer.getCustomers(clientId));
        } catch (e) {
            pageMessage.handleApiError('An error occurred while fetching customers', e);
            setErrorText('Error fetching customers');
        }
        setLoading(false);
    }, [ clientId, pageMessage ]));

    const customerOptions = customers.map(c => c.id);

    return (
        <Autocomplete
            className={className}
            options={customerOptions}
            value={customerIdSearch}
            onChange={(_, newValue) => {
                setCustomerIdSearch?.(newValue || '');
            }}
            getOptionLabel={option => customers.find(c => c.id === option)?.name || ''}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Filter by customer or id"
                    variant="filled"
                    helperText={errorText}
                    error={!!errorText}
                    required={isRequired}
                >
                    {loading && (
                        <MenuItem>
                            Loading...
                        </MenuItem>
                    )}
                </TextField>
            )}
            size="small"
            loading={loading}
        />
    );
};
