import { Info } from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';
import { FilterTextField } from '@tsp-ui/core';
import { TogglableValuesKey } from '@utils';
import { ClientContext } from '@views/AuthenticatedRouteSwitch';
import Page from '@views/components/Page';
import { useContext, useState } from 'react';

import styles from './ConfigurableValuesPage.module.scss';
import { TogglableValuesItemCard } from './components/TogglableValuesItemCard';


export default function ConfigurableValuesPage() {
    const { configurableValues } = useContext(ClientContext);
    const [ filterText, setFilterText ] = useState('');
    const filteredConfigurableValues = configurableValues && {
        ...configurableValues,
        togglableValues: Object.fromEntries(
            Object.entries(configurableValues.togglableValues)
                .filter(([ key ]) => key.toLowerCase().includes(filterText.toLowerCase()))
        )
    };

    return (
        <Page
            header="Configurable Values"
            loading={!configurableValues}
        >
            <FilterTextField
                autoFocus
                placeholder="Filter values"
                helperText="Filter by value name"
                value={filterText}
                onChange={(event) => setFilterText(event.target.value)}
            />

            <div className={styles.root}>
                <div className={styles.items}>
                    <Typography
                        variant="h6"
                        className={styles.header}
                    >
                        Togglable values

                        <Tooltip title="Togglable values can be toggled on or off from a predefined list. This configuration applies to the current account as well as all customers of this account.">
                            <Info color="info" />
                        </Tooltip>
                    </Typography>

                    {Object.entries(filteredConfigurableValues?.togglableValues || {}).map(([ key ]) => (
                        <TogglableValuesItemCard
                            key={key}
                            togglableValuesKey={key as TogglableValuesKey}
                        />
                    ))}
                </div>

                <div className={styles.items}>
                    <Typography
                        variant="h6"
                        className={styles.header}
                    >
                        Custom values

                        <Tooltip title="Custom values can be configured as any set of values. This configuration applies to the current account as well as all customers of this account.">
                            <Info color="info" />
                        </Tooltip>
                    </Typography>

                    {Object.entries(configurableValues?.togglableValues || {}).slice(0, 2).map(([ key ]) => (
                        // TODO this will be implemented later, these TogglableValuesItemCards are a place holder
                        <TogglableValuesItemCard
                            key={key}
                            togglableValuesKey={key as TogglableValuesKey}
                        />
                    ))}
                </div>
            </div>
        </Page>
    );
}
