import { PermissionType } from '@api';
import { Typography } from '@mui/material';
import { Button, FilterTextField, FilterableFilledSection } from '@tsp-ui/core/components';
import { useHasPermission } from '@utils/hooks';
import { LLPARoutesContext } from '@views/admin/llpas/LLPARoutes';
import { LLPACard } from '@views/admin/llpas/components/LLPACard';
import Page from '@views/components/Page';
import { useContext, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import styles from './LLPAManagementPage.module.scss';


export default function LLPAManagementPage() {
    const { llpas } = useContext(LLPARoutesContext);
    const [ canManage ] = useHasPermission([ PermissionType.MANAGE_LLPAS ]);

    const [ llpaSearchText, setLlpaSearchText ] = useState('');
    const [ debouncedLlpaSearchText ] = useDebounce(llpaSearchText, 300);

    const filteredLlpas = useMemo(() => (
        llpas?.filter(({ isCap, name, description }) => (
            !isCap && (!debouncedLlpaSearchText
                || name.toLowerCase().includes(debouncedLlpaSearchText.toLowerCase())
                || description.toLowerCase().includes(debouncedLlpaSearchText.toLowerCase())
            )
        ))
    ), [ llpas, debouncedLlpaSearchText ]);


    const [ capSearchText, setCapSearchText ] = useState('');
    const [ debouncedCapSearchText ] = useDebounce(llpaSearchText, 300);

    const filteredCaps = useMemo(() => (
        llpas?.filter(({ isCap, name, description }) => (
            isCap && (!debouncedCapSearchText
                || name.toLowerCase().includes(debouncedCapSearchText.toLowerCase())
                || description.toLowerCase().includes(debouncedCapSearchText.toLowerCase())
            )
        ))
    ), [ llpas, debouncedCapSearchText ]);

    return (
        <Page
            header="LLPA Management"
            loading={!llpas}
        >
            <div className={styles.root}>
                <FilterableFilledSection
                    header="LLPAs"
                    addButton={(
                        <Button
                            variant="contained"
                            component={Link}
                            to="add"
                            disabled={!canManage}
                            tooltip={!canManage ? 'You don\'t have permission to manage LLPAs' : ''}
                        >
                            Add LLPA
                        </Button>
                    )}
                    filterField={(
                        <FilterTextField
                            autoFocus
                            placeholder="Filter LLPAs"
                            helperText="Filter by name"
                            value={llpaSearchText}
                            onChange={(event) => setLlpaSearchText(event.target.value)}
                        />
                    )}
                >
                    {!filteredLlpas?.length ? (
                        <Typography>
                            {debouncedLlpaSearchText ? 'No LLPAs match your search text' : 'No LLPAs exist yet'}
                        </Typography>
                    ) : filteredLlpas.map((llpa) => (
                        <LLPACard
                            key={llpa.id}
                            llpa={llpa}
                        />
                    ))}
                </FilterableFilledSection>

                <FilterableFilledSection
                    header="Caps"
                    addButton={(
                        <Button
                            variant="contained"
                            component={Link}
                            to="add?isCap=true"
                            disabled={!canManage}
                            tooltip={!canManage ? 'You don\'t have permission to manage LLPAs' : ''}
                        >
                            Add Cap
                        </Button>
                    )}
                    filterField={(
                        <FilterTextField
                            placeholder="Filter caps"
                            helperText="Filter by name"
                            value={capSearchText}
                            onChange={(event) => setCapSearchText(event.target.value)}
                        />
                    )}
                >
                    {!filteredCaps?.length ? (
                        <Typography>
                            {debouncedCapSearchText ? 'No Caps match your search text' : 'No Caps exist yet'}
                        </Typography>
                    ) : filteredCaps.map((llpa) => (
                        <LLPACard
                            key={llpa.id}
                            llpa={llpa}
                        />
                    ))}
                </FilterableFilledSection>
            </div>
        </Page>
    );
}
