import api, { ClientDetail, ClientStatus, clientStatusDisplay } from '@api';
import { HelpOutline } from '@mui/icons-material';
import { Button, Tooltip } from '@mui/material';
import { createContext, useState } from 'react';

import AdminPageTemplate, {
    AdminEntityGroup, AdminEntityGroupProps, AdminPageContextValues, defaultAdminPageContextValues
} from '../components/AdminPageTemplate';

import ClientDetailPage from './ClientDetailsPage/ClientDetailPage';
import { ClientCard } from './components/ClientCard';
import ClientForm from './components/ClientForm';


const {
    ACTIVE, INACTIVE, PENDING, SUSPENDED
} = ClientStatus;

export const ClientManagementPageContext = createContext<AdminPageContextValues<ClientDetail>>(
    defaultAdminPageContextValues
);

export default function ClientManagementPage() {
    const [ showInactiveAndSuspended, setShowInactiveAndSuspended ] = useState(false);

    return (
        <AdminPageTemplate
            entityName="client"
            Context={ClientManagementPageContext}
            CreateEditForm={ClientForm}
            editDetails
            DetailPage={ClientDetailPage}
            EntityGroupComponent={ClientGroup}
            fetchEntities={api.client.getClients}
            filterByLabel="name or NMLS ID"
            filterEntity={({ legalName, nmlsId }, filterInputValue) => (
                legalName.toLocaleLowerCase().includes(filterInputValue) || nmlsId?.includes(filterInputValue)
            )}
            otherFilters={(
                <Button onClick={() => setShowInactiveAndSuspended(!showInactiveAndSuspended)}>
                    {showInactiveAndSuspended ? 'Hide' : 'Show'} inactive / suspended
                </Button>
            )}
            sortEntitiesBy="legalName"
            visibleGroups={[
                PENDING,
                ACTIVE,
                ...(showInactiveAndSuspended ? [ INACTIVE, SUSPENDED ] : [])
            ]}
        />
    );
}

function ClientGroup({
    entities: clients,
    group: statusProp,
    filterText
}: AdminEntityGroupProps<ClientDetail, ClientStatus>) {
    return (
        <AdminEntityGroup
            header={(
                <>
                    {clientStatusDisplay[statusProp]}

                    {statusProp === PENDING && (
                        <Tooltip title="Pending clients are not yet active. Complete required items to activate them.">
                            <HelpOutline color="primary" />
                        </Tooltip>
                    )}
                </>
            )}
            noResultsMessage={filterText ? `No ${statusProp.toLowerCase()} clients match your filter text` : `No ${statusProp.toLowerCase()} clients exist`}
        >
            {clients
                .filter(({ status }) => status === statusProp)
                .map((client) => (
                    <ClientCard
                        key={client.id}
                        client={client}
                    />
                ))}
        </AdminEntityGroup>
    );
}
