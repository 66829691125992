import api, {
    FieldConfig, PermissionType, pricingFieldNameDisplay
} from '@api';
import {
    Alert,
    Button, FormLabel, Switch, Tooltip
} from '@mui/material';
import { useAsyncEffect, usePageMessage } from '@tsp-ui/core';
import { useGetCurrentAccount, useHasPermission } from '@utils';
import Page from '@views/components/Page';
import React, { useCallback, useState } from 'react';

import styles from './FieldConfigPage.module.scss';


export default function FieldConfigPage() {
    const [ canManage ] = useHasPermission([ PermissionType.MANAGE_FIELD_CONFIGS ]);
    const pageMessage = usePageMessage();
    const { id: clientId } = useGetCurrentAccount();

    const [ fieldConfigs, setFieldConfigs ] = useState<FieldConfig[]>([]);
    const [ saveLoading, setSaveLoading ] = useState(false);
    const [ loading, setLoading ] = useState(false);

    useAsyncEffect(useCallback(async () => {
        setLoading(true);
        try {
            setFieldConfigs(await api.fieldConfig.getFieldConfigs(clientId));
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching loan field configurations', error);
        }
        setLoading(false);
    }, [ clientId, pageMessage ]));

    function handleSwitchChange(changedConfig: FieldConfig) {
        setFieldConfigs(prevConfigs => prevConfigs.map(config => (config.id === changedConfig.id
            ? {
                ...config,
                enabled: !config.enabled
            }
            : config)));
    };

    async function handleSave() {
        setSaveLoading(true);
        try {
            await api.fieldConfig.updateFieldConfig(clientId, fieldConfigs);
            pageMessage.success('Loan field configurations saved');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving loan field configurations', error);
        }
        setSaveLoading(false);
    };

    return (
        <Page
            header="Required Field Values"
            loading={loading}
            headerActions={(
                <Button
                    variant="contained"
                    disabled={saveLoading}
                    onClick={handleSave}
                >
                    Save
                </Button>
            )}
        >
            <div className={styles.root}>
                <Alert
                    color="info"
                >
                    This page is used to configure which loan fields are required to run pricing on a loan.
                </Alert>

                <div className={styles.switchContainer}>
                    {fieldConfigs.map((config) => (
                        <div
                            key={config.id}
                            className={styles.item}
                        >
                            <FormLabel>
                                {pricingFieldNameDisplay[config.fieldName]}
                            </FormLabel>

                            <Tooltip title={canManage ? '' : 'You do not have permission to toggle fields'}>
                                <Switch
                                    checked={config.enabled}
                                    disabled={!canManage}
                                    onChange={() => handleSwitchChange(config)}
                                />
                            </Tooltip>
                        </div>
                    ))}
                </div>
            </div>
        </Page>
    );
}
