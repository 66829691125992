import { Contact, PermissionType } from '@api';
import { useHasPermission } from '@utils/hooks';

import EditableSectionCard from '../../../components/EditableSectionCard';

import styles from './CustomerPrimaryContactCard.module.scss';
import ContactDisplay from './dialogs/ContactDisplay';


interface CustomerPrimaryContactCardProps {
    primaryContact?: Contact;
}

export default function CustomerPrimaryContactCard({ primaryContact }: CustomerPrimaryContactCardProps) {
    const [ canManageContacts, canViewContacts ] = useHasPermission(
        [ PermissionType.MANAGE_CUSTOMER_CONTACTS, PermissionType.VIEW_CUSTOMER_CONTACTS ]
    );

    return (
        <EditableSectionCard
            header="Primary contact"
            disableEdit={!canManageContacts}
            disabledTooltip="You do not have permission to edit the primary contact"
            editTo="edit-primary-contact"
        >
            {canViewContacts ? (
                <ContactDisplay
                    className={styles.root}
                    contact={primaryContact}
                />
            ) : (
                <div className={styles.root}>
                    You do not have permission to view the primary contact.
                </div>
            )}
        </EditableSectionCard>
    );
}
