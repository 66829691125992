export enum ApprovalStatusEnum {
    IN_PROGRESS = 'IN_PROGRESS',
    SUBMITTED = 'SUBMITTED',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED'
}

export const approvalStatusDisplay = {
    [ApprovalStatusEnum.IN_PROGRESS]: 'In Progress',
    [ApprovalStatusEnum.SUBMITTED]: 'Submitted',
    [ApprovalStatusEnum.APPROVED]: 'Approved',
    [ApprovalStatusEnum.REJECTED]: 'Rejected'
};
