import { ActiveInactiveEnum, Agency } from '@api';
import { createDate } from '@tsp-ui/core';
import { rest } from 'msw';

import { getMockUrl } from '../../../mocks/getMockUrl';

import { AgencyApproval } from './agency-approval-api';


export const mocks = [
    rest.get(getMockUrl('/client/:clientID/customer/:customerID/agency-approval'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.delay(500),
        ctx.json(agencyApprovals)
    ))),
    rest.post(getMockUrl('/client/:clientID/customer/:customerID/agency-approval'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json({
            ...req.body as AgencyApproval,
            id: String(agencyApprovalID++)
        })
    ))),
    rest.put(getMockUrl('/client/:clientID/customer/:customerID/agency-approval/:agencyApprovalID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(req.body)
    )))
];

let agencyApprovalID = 0;
const agencyApprovals: AgencyApproval[] = [
    {
        id: String(agencyApprovalID++),
        agency: Agency.FHA,
        firstApprovalDate: createDate(-100).toISOString(),
        latestApprovalDate: createDate(-5).toISOString(),
        status: ActiveInactiveEnum.ACTIVE

    },
    {
        id: String(agencyApprovalID++),
        agency: Agency.FNMA,
        firstApprovalDate: createDate(-100).toISOString(),
        latestApprovalDate: createDate(-5).toISOString(),
        status: ActiveInactiveEnum.ACTIVE

    }
];
