import { apiUtils } from '../../api-utils';
import { EligibilityVersion } from '../../eligibility-version/eligibility-version-api';


export function getEligibilityVersions(clientId: string): Promise<EligibilityVersion[]> {
    return apiUtils.get(`/client/${clientId}/eligibility-version`);
}

export function createEligibilityVersion(clientId: string, version: EligibilityVersion): Promise<EligibilityVersion> {
    return apiUtils.post(`/client/${clientId}/eligibility-version`, version);
}

export function updateEligibilityVersion(clientId: string, version: EligibilityVersion): Promise<EligibilityVersion> {
    return apiUtils.put(`/client/${clientId}/eligibility-version/${version.id}`, version);
}

export function deleteEligibilityVersion(clientId: string, eligibilityVersionID: string): Promise<void> {
    return apiUtils.delete(`/client/${clientId}/eligibility-version/${eligibilityVersionID}`, { emptyResponse: true });
}
