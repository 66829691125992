import api, { Comment } from '@api';
import { Button, DialogActions, DialogContent } from '@mui/material';
import { Dialog, TextField, usePageMessage } from '@tsp-ui/core/components';
import { useGetCurrentAccount } from '@utils';
import { LoanDetailContext } from '@views/loans/LoanDetailPage';
import { useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';


interface AddConditionCommentDialogProps {
    open: boolean;
    onClose: () => void;
    conditionID: string;
}

export default function AddConditionCommentDialog({ open, onClose, conditionID }: AddConditionCommentDialogProps) {
    const losLoanId = useContext(LoanDetailContext).loanDetail?.losLoanId!;
    const { id: clientId, customerId } = useGetCurrentAccount();

    const { setConditions } = useContext(LoanDetailContext);

    const formMethods = useForm<Comment>();

    const [ loading, setLoading ] = useState(false);
    const pageMessage = usePageMessage();

    const handleSubmit = formMethods.handleSubmit(async (formValues) => {
        setLoading(true);

        try {
            await api.loans.createUWConditionComment(
                clientId, losLoanId, conditionID, formValues, customerId
            );

            setConditions(await api.loans.getUnderwritingConditions(
                clientId, customerId, losLoanId
            ));

            onClose();
            pageMessage.success('Comment saved');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving the comment', error);
        }

        setLoading(false);
    });

    const formID = 'add-condition-comment-form';

    return (
        <Dialog
            title="Add condition comment"
            open={open}
            onClose={onClose}
            maxWidth="md"
        >
            <DialogContent>
                <FormProvider {...formMethods}>
                    <TextField<Comment>
                        name="comments"
                        label="Comment description"
                        fullWidth
                        multiline
                        minRows={2}
                    />

                    {/* TODO: determine how to customize "createdBy, createdByName, forRole, forRoleId" */}
                </FormProvider>
            </DialogContent>

            <DialogActions>
                <Button
                    onClick={onClose}
                    disabled={loading}
                >
                    Cancel
                </Button>

                <Button
                    variant="contained"
                    onClick={handleSubmit}
                    id={formID}
                    type="submit"
                    disabled={loading}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
