import {
    Account, AuthUser, PermissionType, UserType
} from '@api';
import { rest } from 'msw';

import clientLogoUrl from '../../images/logo-client.png';
import customerLogoUrl from '../../images/logo-customer.png';
import { getMockUrl } from '../../mocks/getMockUrl';


// Add permissions here to exclude from mocks
const excludeInternalPermissions: PermissionType[] = [];

export const mocks = [
    rest.get(getMockUrl('/auth/me'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.json({
            firstName: 'John',
            lastName: 'Smith',
            email: 'johnsmith@premicorr.com',
            isInternal: true,
            clientAccounts: [ accounts[0] ],
            customerAccounts: [ accounts[1] ],
            internalPermissions: Object.values(PermissionType).filter(
                permission => !excludeInternalPermissions.includes(permission)
            )
        } as AuthUser)
    )),
    rest.get(getMockUrl('/auth/client/:clientID'), (req, res, ctx) => {
        const isCustomer = !!req.url.searchParams.get('customerId');

        return res(
            ctx.status(200),
            ctx.json({ accessToken: `mock${isCustomer ? 'Customer' : 'Client'}Token` })
        );
    })
];

export const accounts: Account[] = [
    {
        id: '1',
        name: 'Client premicorr',
        logoUrl: clientLogoUrl,
        accountUserType: UserType.CLIENT,
        primaryColor: '#954171',
        secondaryColor: '#419565'
    },
    {
        id: '1',
        name: 'Client premicorr',
        logoUrl: customerLogoUrl,
        accountUserType: UserType.CUSTOMER,
        customerId: '1',
        customerName: 'Premicorr Customer',
        primaryColor: '#954171',
        secondaryColor: '#419565'
    }
];
