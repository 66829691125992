export enum LoanType {
    CONVENTIONAL = 'CONVENTIONAL',
    FHA = 'FHA',
    VA = 'VA'
}

export const loanTypeDisplay: Record<LoanType, string> = {
    [LoanType.CONVENTIONAL]: 'Conventional',
    [LoanType.FHA]: 'FHA',
    [LoanType.VA]: 'VA'
};
