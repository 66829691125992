import { LoanCondition, LoanType } from '@api';

import { apiUtils } from '../api-utils';


export function getConditionConfigs(clientID: string): Promise<ConditionConfig[]>  {
    return apiUtils.get(`/client/${clientID}/condition-config`);
}

export function updateConditionConfig(
    clientID: string, conditionConfig: ConditionConfig
): Promise<ConditionConfig>  {
    return apiUtils.put(`/client/${clientID}/condition-config/${conditionConfig.id}`, conditionConfig);
}

export function deleteConditionConfig(
    clientID: string, conditionConfigId: string
): Promise<ConditionConfig> {
    return apiUtils.delete(`/client/${clientID}/condition-config/${conditionConfigId}`);
}

export function createConditionConfig(
    clientID: string, conditionConfig: ConditionConfig
): Promise<ConditionConfig>  {
    return apiUtils.post(`/client/${clientID}/condition-config`, conditionConfig);
}

export function getConditionConfigGroups(clientID: string): Promise<ConditionConfigGroup[]>  {
    return apiUtils.get(`/client/${clientID}/condition-config/group`);
}

export function updateConditionConfigGroup(
    clientID: string, conditionConfigGroup: ConditionConfigGroup
): Promise<ConditionConfigGroup>  {
    return apiUtils.put(`/client/${clientID}/condition-config/group/${conditionConfigGroup.id}`, conditionConfigGroup);
}

export function createConditionConfigGroup(clientID: string, conditionConfigGroup: Omit<ConditionConfigGroup, 'id'>): Promise<ConditionConfigGroup>  {
    return apiUtils.post(`/client/${clientID}/condition-config/group`, conditionConfigGroup);
}

export function deleteConditionConfigGroup(
    clientID: string, conditionConfigGroupId: string
): Promise<ConditionConfigGroup>  {
    return apiUtils.delete(`/client/${clientID}/condition-config/group/${conditionConfigGroupId}`);
}

export interface ConditionConfigGroup {
    id: string;
    type: 'loanType' | 'loanProgram';
    loanTypes?: LoanType[];
    loanProgramIds?: string[];
}

export interface ConditionConfig extends LoanCondition {
    conditionConfigGroupId: string;
}
