import { LLPAMatrixLoanProperty, llpaMatrixLoanPropertyDisplay } from '@api';
import { HelpOutline } from '@mui/icons-material';
import { Paper, Typography } from '@mui/material';
import { IconTypography } from '@tsp-ui/core/components';
import LoanPropertyValueCell from '@views/admin/llpas/components/LLPAMatrix/components/LoanPropertyValueCell';
import MatrixTableRow from '@views/admin/llpas/components/LLPAMatrix/components/MatrixTableRow';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { LLPAMatrixFormCell, MatrixStepFormValues } from '../LLPADialog';

import styles from './LLPAMatrix.module.scss';


interface LLPAMatrixProps {
    readOnly?: boolean;
}

export function LLPAMatrix({ readOnly }: LLPAMatrixProps) {
    const columnMetadata = useWatch<MatrixStepFormValues, 'columnMetadata'>({ name: 'columnMetadata' });
    const cells = useWatch<MatrixStepFormValues, 'llpaCells'>({ name: 'llpaCells' });

    const matrixColumns = columnMetadata
        .filter(({ isHighLevel }) => !isHighLevel)
        .map(({ loanProperty }) => loanProperty);

    const rowProperty = matrixColumns.length === 1 ? null : matrixColumns.slice(-1)[0];
    const colProperties = useMemo(() => (
        matrixColumns.length === 1 ? matrixColumns : matrixColumns.slice(0, matrixColumns.length - 1)
    ), [ matrixColumns ]);

    const numRows = new Set(cells.map((cell) => getKeyForCell(cell, rowProperty))).size;

    const rowHeaderCells: LLPAMatrixFormCell[] = [];
    for (let i = 0; i < cells.length; i += numRows) {
        rowHeaderCells.push(cells[i]);
    }

    const disableInsert = cells.some((cell) => matrixColumns.some(col => !cell[col]));

    return (
        <>
            <Paper
                variant="outlined"
                className={styles.tableContainer}
            >
                {colProperties.length === 0 ? (
                    <div className={styles.noContent}>
                        <Paper
                            variant="outlined"
                            className={styles.noContentPaper}
                        >
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                align="center"
                            >
                                This matrix has no content yet. Add a matrix column above to start building your matrix.
                            </Typography>
                        </Paper>
                    </div>
                ) : (
                    <table>
                        <thead>
                            <tr>
                                {colProperties.map((property) => (
                                    <th
                                        key={property}
                                        rowSpan={rowProperty ? 2 : undefined}
                                    >
                                        {llpaMatrixLoanPropertyDisplay[property]}
                                    </th>
                                ))}

                                {!rowProperty ? (
                                    <th>Price Adjustment</th>
                                ) : (
                                    <th colSpan={rowHeaderCells?.length}>
                                        {llpaMatrixLoanPropertyDisplay[rowProperty]}
                                    </th>
                                )}
                            </tr>

                            {rowProperty && (
                                <tr>
                                    {rowHeaderCells?.map((cell) => (
                                        <LoanPropertyValueCell
                                            key={getKeyForCell(cell)}
                                            variant="row"
                                            property={rowProperty}
                                            rowProperty={rowProperty}
                                            colProperties={colProperties}
                                            cell={cell}
                                            numRows={numRows}
                                            cellIndex={cells.indexOf(cell)}
                                            readOnly={readOnly}
                                            isOnlyRow={rowHeaderCells.length === 1}
                                            disableInsert={disableInsert}
                                        />
                                    ))}
                                </tr>
                            )}
                        </thead>

                        <tbody>
                            {Array(numRows).fill(true).map((_, index) => (
                                <MatrixTableRow
                                    key={getKeyForCell(cells[index])}
                                    rowIndex={index}
                                    cells={cells}
                                    rowProperty={rowProperty}
                                    colProperties={colProperties}
                                    numRows={numRows}
                                    readOnly={readOnly}
                                    disableInsert={disableInsert}
                                />
                            ))}
                        </tbody>
                    </table>
                )}
            </Paper>

            {colProperties.length > 0 && !readOnly && (
                <IconTypography
                    fontWeight={400}
                    variant="body2"
                    color="textSecondary"
                    align="center"
                    className={styles.tableHelperText}
                    icon={(
                        <HelpOutline
                            color="primary"
                            fontSize="small"
                        />
                    )}
                >
                    Hover over cells to reveal additional actions
                </IconTypography>
            )}
        </>
    );
}

export function getKeyForCell(cell: LLPAMatrixFormCell, excludeLoanProperty?: LLPAMatrixLoanProperty | null) {
    return Object.keys(cell)
        .filter((key) => (
            (!excludeLoanProperty || key !== excludeLoanProperty) && key !== 'adjustment'
        ))
        .map((key) => `${key}:${cell[key as LLPAMatrixLoanProperty]}`)
        .join();
}
