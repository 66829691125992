import {
    ActiveInactiveEnum, ApprovalStatusEnum, Document, DueDiligenceStepType
} from '../..';
import { apiUtils } from '../../api-utils';


export function getDueDiligenceSteps(clientID: string, customerID: string): Promise<DueDiligenceStep[]> {
    return apiUtils.get(`/client/${clientID}/customer/${customerID}/due-diligence-step`);
}

export function updateDueDiligenceStep(
    clientID: string, customerID: string, dueDiligenceStep: DueDiligenceStep
): Promise<DueDiligenceStep> {
    return apiUtils.put(`/client/${clientID}/customer/${customerID}/due-diligence-step/${dueDiligenceStep.id}`, dueDiligenceStep);
}

export function uploadDueDiligenceStepDocument(
    clientID: string, customerID: string, dueDiligenceStepID: string, files: File[]
): Promise<Document[]> {
    const formData = new FormData();
    files.forEach(file => formData.append('files', file));

    return apiUtils.post(`/client/${clientID}/customer/${customerID}/due-diligence-step/${dueDiligenceStepID}/document`, formData, { useAutoContentType: true });
}

export function removeDueDiligenceStepDocument(
    clientID: string, customerID: string, dueDiligenceStepID: string, docID: string
): Promise<void> {
    return apiUtils.delete(`/client/${clientID}/customer/${customerID}/due-diligence-step/${dueDiligenceStepID}/document/${docID}`);
}

export interface DueDiligenceStep {
    id: string;
    dueDiligenceConfigId: string;
    customerId: string;
    name: string;
    description: string;
    type: DueDiligenceStepType;
    isRequired: boolean;
    isCompleted: boolean;
    completedDate?: string;
    displayOrder: number;
    notes: string;
    isDocRequired: boolean;
    status: ActiveInactiveEnum;
    approvalStatus: ApprovalStatusEnum;
    documents?: Document[];
}
