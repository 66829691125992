import api, {
    ClientDetail,
    ClientStatus,
    ConditionCategoryAssociation,
    LOS,
    LOSConfiguration,
    LoanFolder,
    PermissionType,
    clientStatusDisplay
} from '@api';
import { Button } from '@mui/material';
import { updateClientTheme } from '@redux/auth';
import { RoutedDialogManager, SentryRoutes, usePageMessage } from '@tsp-ui/core/components';
import {
    getItemById, replaceItemById, useAsyncEffect, useParams
} from '@tsp-ui/core/utils';
import { withAuth } from '@utils';
import DetailPageViewCard from '@views/admin/components/DetailPageViewCard';
import InvestorEligibilityRoutes from '@views/admin/investor-eligibility/InvestorEligibilityRoutes';
import {
    Dispatch, SetStateAction, createContext, useCallback, useContext, useMemo, useState
} from 'react';
import { useDispatch } from 'react-redux';
import { Link, Route } from 'react-router-dom';

import Page from '../../../components/Page';
import { AdminRouteParams } from '../../components/AdminPageTemplate';
import StatusActionsCard from '../../components/StatusActionsCard';
import { ClientManagementPageContext } from '../ClientManagementPage';

import styles from './ClientDetailPage.module.scss';
import EditClientPrimaryContactDialog from './components/EditClientPrimaryContactDialog';
import ClientDetailSummaryCard from './components/client-details/ClientDetailSummaryCard';
import { LOSConfigCard } from './components/los-config/LOSConfigCard';
import LOSConfigDialog from './components/los-config/LOSConfigDialog';
import { EditClientThemeDialog } from './components/theme-and-logo/EditClientThemeDialog';
import { ThemeAndLogoCard } from './components/theme-and-logo/ThemeAndLogoCard';


export interface ClientDetailsContextValue {
    client?: ClientDetail;
    updateClient: (updatedClient: ClientDetail) => void;
    losConfig?: LOSConfiguration;
    setLOSConfig: Dispatch<SetStateAction<LOSConfiguration | undefined>>;
    conditionCategories: ConditionCategoryAssociation[] | undefined;
    setConditionCategories: Dispatch<SetStateAction<ConditionCategoryAssociation[] | undefined>>;
}

export const ClientDetailsContext = createContext<ClientDetailsContextValue>({
    client: undefined,
    updateClient: () => {},
    losConfig: undefined,
    setLOSConfig: () => {},
    conditionCategories: undefined,
    setConditionCategories: () => {}
});

export default function ClientDetailPage() {
    const { clientID } = useParams<AdminRouteParams<'client'>>();
    const dispatch = useDispatch();
    const pageMessage = usePageMessage();
    const [ loading, setLoading ] = useState(false);

    const [ losConfig, setLOSConfig ] = useState<LOSConfiguration>();
    const [ loanFolders, setLoanFolders ] = useState<LoanFolder[] | undefined>();
    const [ conditionCategories, setConditionCategories ] = useState<ConditionCategoryAssociation[] | undefined>();

    useAsyncEffect(useCallback(async () => {
        setLoading(true);

        try {
            const losConfig = await api.client.losConfig.getLOSConfigurations(clientID);
            setLOSConfig(losConfig);
            if (losConfig?.los === LOS.ENCOMPASS) {
                const [ loanFolders, conditionCategories ] = await Promise.all([
                    api.client.losConfig.getLoanFolders(clientID),
                    api.client.losConfig.getMappedConditionCategories(
                        clientID, losConfig?.los.toString()
                    )
                ]);
                setLoanFolders(loanFolders);
                setConditionCategories(conditionCategories);
            }
        } catch (error) {
            pageMessage.handleApiError('An error occurred while loading client LOS configuration', error);
        }

        setLoading(false);
    }, [ clientID, pageMessage ]));

    const { entities: clients, setEntities: setClients } = useContext(ClientManagementPageContext);
    const client = getItemById(clients, clientID);
    const updateClient = useCallback(
        (updatedClient: ClientDetail) => {
            dispatch(updateClientTheme({
                id: client.id,
                primaryColor: updatedClient.primaryColor,
                secondaryColor: updatedClient.secondaryColor,
                logoUrl: updatedClient.logoUrl
            }));

            setClients(clients => replaceItemById(clients, updatedClient));
        }, [
            setClients, client, dispatch
        ]
    );

    const [ statusLoading, setStatusLoading ] = useState(false);

    const clientDetailsContextValue = useMemo(() => ({
        client,
        updateClient,
        losConfig,
        setLOSConfig,
        loanFolders,
        setLoanFolders,
        conditionCategories,
        setConditionCategories
    }), [
        client, losConfig, updateClient, loanFolders, conditionCategories
    ]);

    return (
        <SentryRoutes>
            <Route
                path="*"
                element={(
                    <Page
                        header="Client details"
                        headerActions={(
                            <Button
                                component={Link}
                                to="history"
                            >
                                View history
                            </Button>
                        )}
                        breadcrumbPathLengths={[ 2 ]}
                        breadcrumbs={[
                            'Clients',
                            client?.legalName
                        ]}
                        loading={loading}
                    >
                        {client && (
                            <ClientDetailsContext.Provider value={clientDetailsContextValue}>
                                <div className={styles.root}>
                                    <div>
                                        <ClientDetailSummaryCard />

                                        <ThemeAndLogoCard />

                                        <LOSConfigCard />
                                    </div>

                                    <div>
                                        <DetailPageViewCard
                                            label="Investor eligibility"
                                            to="investor-eligibility"
                                        />
                                    </div>

                                    <div>
                                        <StatusActionsCard
                                            actions={(
                                                <>
                                                    <Button
                                                        variant="contained"
                                                        fullWidth
                                                        disabled={statusLoading}
                                                        onClick={async () => {
                                                            setStatusLoading(true);

                                                            try {
                                                                updateClient(await api.client.updateClient({
                                                                    ...client,
                                                                    status: client.status === ClientStatus.ACTIVE
                                                                        ? ClientStatus.INACTIVE
                                                                        : ClientStatus.ACTIVE
                                                                }));
                                                                pageMessage.success(`Client ${client.status === ClientStatus.ACTIVE ? 'deactivated' : 'activated'}`);
                                                            } catch (error) {
                                                                pageMessage.handleApiError('An error occurred while updating client status', error);
                                                            }
                                                            setStatusLoading(false);
                                                        }}
                                                    >
                                                        {client.status === ClientStatus.ACTIVE ? 'Deactivate' : 'Activate'}
                                                    </Button>

                                                    <Button
                                                        variant="contained"
                                                        fullWidth
                                                        disabled={statusLoading}
                                                        onClick={async () => {
                                                            setStatusLoading(true);

                                                            try {
                                                                const newStatus = client.status === ClientStatus.ACTIVE
                                                                    ? ClientStatus.SUSPENDED
                                                                    : client.status === ClientStatus.SUSPENDED
                                                                        ? ClientStatus.INACTIVE
                                                                        : ClientStatus.SUSPENDED;

                                                                updateClient(await api.client.updateClient({
                                                                    ...client,
                                                                    status: newStatus
                                                                }));
                                                                pageMessage.success(`Client ${client.status === ClientStatus.SUSPENDED ? 'deactivated' : 'suspended'}`);
                                                            } catch (error) {
                                                                pageMessage.handleApiError('An error occurred while updating the client status', error);
                                                            }
                                                            setStatusLoading(false);
                                                        }}
                                                    >
                                                        {client.status === ClientStatus.SUSPENDED ? 'Deactivate' : 'Suspend'}
                                                    </Button>

                                                </>
                                            )}
                                            noBorder={false}
                                            status={clientStatusDisplay[client.status]}
                                            variant={client.status}
                                            isLoading={statusLoading}
                                        />

                                        {/* TODO add this back in later when real data is populated */}

                                        {/* <EditableSectionCard
                                            header="Primary contact"
                                            editTo="edit-primary-contact"
                                        >
                                            <ContactDisplay
                                                className={styles.clientDisplay}
                                                contact={client.primaryContact}
                                            />
                                        </EditableSectionCard> */}
                                    </div>
                                </div>

                                <RoutedDialogManager routes={dialogRoutes} />
                            </ClientDetailsContext.Provider>
                        )}
                    </Page>
                )}
            />

            <Route
                path="investor-eligibility/*"
                element={<InvestorEligibilityRoutes />}
            />
        </SentryRoutes>
    );
}

const dialogRoutes = {
    'edit-primary-contact': withAuth(EditClientPrimaryContactDialog, [ PermissionType.EDIT_CLIENTS ], true),
    'edit-theme': withAuth(EditClientThemeDialog, [ PermissionType.EDIT_CLIENT_LOGO, PermissionType.EDIT_CLIENTS ], true),
    'edit-los-config': withAuth(LOSConfigDialog, [ PermissionType.MANAGE_LOS_CONFIG ], true)
};
