import { AmplifyProvider } from '@aws-amplify/ui-react';
import {
    CssBaseline, StyledEngineProvider, Theme, ThemeProvider, Typography
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AuthType, authenticateSession, isAuthenticationInitialized } from '@redux/auth';
import { useDispatch, useSelector } from '@redux/store';
import {
    ConfirmDialog, ConfirmDialogContext, PageMessageContext, SentryRoutes
} from '@tsp-ui/core/components';
import { useAsyncEffect, useConfirmDialogValue, usePageMessageValue } from '@tsp-ui/core/utils';
import { useTryGetCurrentAccount } from '@utils/hooks';
import AuthenticatedRouteSwitch from '@views/AuthenticatedRouteSwitch';
import LandingPage from '@views/LandingPage';
import LoginPage from '@views/LoginPage';
import { ActiveTokenContext } from '@views/components/ActiveTokenContext';
import UnauthedHeader from '@views/components/UnauthedHeader';
import {
    ReactNode, useCallback, useEffect, useMemo, useState
} from 'react';
import { Route } from 'react-router-dom';
import tinycolor from 'tinycolor2';

import { apiUtils } from './api/api-utils';
import configureApp from './config/configureApp';
import { createPremTheme } from './config/mui-theme';


export default function App() {
    const { isConfigError, isInitialized } = useConfigureApp();
    const { primaryColor = '#7092BF', secondaryColor = '#70BABF' } = useTryGetCurrentAccount() || {};

    const theme = useMemo(() => createPremTheme(primaryColor, secondaryColor), [ primaryColor, secondaryColor ]);

    useEffect(() => {
        const root = document.querySelector(':root') as HTMLElement | null;

        const primaryDark = tinycolor(primaryColor).darken(15).toString();
        const primaryVeryDark = tinycolor(primaryColor).darken(30).toString();
        const primaryLight = tinycolor(primaryColor).setAlpha(.12).toString();
        const primaryVeryLight = tinycolor(primaryColor).setAlpha(.08).toString();

        root?.style.setProperty('--app-color_primary', primaryColor);
        root?.style.setProperty('--app-color_primary-dark', primaryDark);
        root?.style.setProperty('--app-color_primary_very-dark', primaryVeryDark);
        root?.style.setProperty('--app-color_primary-light', primaryLight);
        root?.style.setProperty('--app-color_primary_very-light', primaryVeryLight);
        root?.style.setProperty('--app-color_secondary', secondaryColor);
    }, [ primaryColor, secondaryColor ]);

    return (
        <Providers theme={theme}>
            {isConfigError ? (
                <>
                    <UnauthedHeader />

                    <Typography>
                        We ran into a problem starting the app, please contact support.
                    </Typography>
                </>
            ) : isInitialized && (
                <SentryRoutes>
                    <Route
                        path="/"
                        element={<LandingPage />}
                    />

                    <Route
                        path="/login"
                        element={<LoginPage />}
                    />

                    <Route
                        path="*"
                        element={<AuthenticatedRouteSwitch />}
                    />
                </SentryRoutes>
            )}
        </Providers>
    );
}

function useConfigureApp() {
    const dispatch = useDispatch();
    const isInitialized = useSelector(isAuthenticationInitialized);

    const [ isConfigError, setConfigError ] = useState(false);

    useAsyncEffect(useCallback(async () => {
        try {
            await configureApp();

            dispatch(authenticateSession(AuthType.INIT));
        } catch (error) {
            setConfigError(true);
            console.error(error);
        }
    }, [ dispatch ]));

    return {
        isInitialized,
        isConfigError
    };
}

interface ProvidersProps {
    children?: ReactNode;
    theme: Theme;
}

export function Providers({ children, theme }: ProvidersProps) {
    const pageMessageValue = usePageMessageValue();
    const confirmDialogValue = useConfirmDialogValue();

    const [ activeAuthToken, setActiveAuthToken ] = useState('');

    useEffect(() => {
        apiUtils.setOnActiveTokenChange(setActiveAuthToken);
    }, [ setActiveAuthToken ]);

    return (
        <ActiveTokenContext.Provider
            value={{
                activeAuthToken,
                setActiveAuthToken
            }}
        >
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <AmplifyProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <PageMessageContext.Provider value={pageMessageValue}>
                                <ConfirmDialogContext.Provider value={confirmDialogValue}>
                                    <CssBaseline />

                                    <ConfirmDialog />

                                    {children}
                                </ConfirmDialogContext.Provider>
                            </PageMessageContext.Provider>
                        </LocalizationProvider>
                    </AmplifyProvider>
                </ThemeProvider>
            </StyledEngineProvider>
        </ActiveTokenContext.Provider>
    );
}
