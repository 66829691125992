import { rest } from 'msw';

import {
    Address, CustomerPendingSteps, CustomerStatus, State
} from '..';
import { getMockUrl } from '../../mocks/getMockUrl';
import { mockedProducts } from '../products/products-mocks';

import { mocks as agencyApprovalMocks } from './agency-approval/agency-approval-mocks';
import { branches, mocks as branchesMocks } from './branches/branches-mocks';
import { mocks as compensationMocks } from './compensation/compensation-mocks';
import { contacts, mocks as contactsMocks } from './contacts/contacts-mocks';
import { CustomerDetails, CustomerSummary } from './customer-api';
import { mocks as dueDiligenceStepMocks } from './due-diligence-step/due-diligence-step-mocks';
import { mockedProductTypeConfigs, mocks as productsMocks } from './eligibleProducts/eligibleProducts-mocks';
import { mocks as eventHistoryMocks } from './eventHistory/eventHistory-mocks';
import { mocks as investorRelationshipMocks } from './investor-relationship/investor-relationship-mocks';
import { mocks as licensesMocks } from './licenses/licenses-mocks';
import { mocks as limitsMocks } from './limits/limit-mocks';
import { mocks as miCompanyRelationshipMocks } from './mi-company-relationship/mi-company-relationship-mocks';
import { mocks as warehouseRelationshipMocks } from './warehouse-relationship/warehouse-relationship-mocks';
import { mocks as wireInfoMocks } from './wireInfo/wireInfo-mocks';


let newCustomerID = 100;

export const mocks = [
    rest.get(getMockUrl('/client/:clientID/customer'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(customerSummaryResults)
    ))),
    rest.post(getMockUrl('/client/:clientID/customer'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json({
            ...req.body as CustomerDetails,
            id: `${newCustomerID++}`
        })
    ))),
    rest.get(getMockUrl('/client/:clientID/customer/:customerID'), (req, res, ctx) => {
        somePendingSteps.pop();

        return (res(
            ctx.delay(1000),
            ctx.status(200),
            ctx.json(customer)
        ));
    }),
    rest.put(getMockUrl('/client/:clientID/customer/:customerID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(req.body)
    ))),
    ...branchesMocks,
    ...dueDiligenceStepMocks,
    ...agencyApprovalMocks,
    ...miCompanyRelationshipMocks,
    ...warehouseRelationshipMocks,
    ...investorRelationshipMocks,
    ...compensationMocks,
    ...contactsMocks,
    ...eventHistoryMocks,
    ...licensesMocks,
    ...productsMocks,
    ...wireInfoMocks,
    ...limitsMocks
];

const somePendingSteps: CustomerPendingSteps[] = [
    CustomerPendingSteps.ELIGIBLE_PRODUCTS,
    CustomerPendingSteps.CONTACTS,
    CustomerPendingSteps.DUE_DILIGENCE,
    CustomerPendingSteps.USERS
];

const address: Address = {
    street: '1000 Main St',
    city: 'Mount Laurel',
    state: State.NJ,
    zip: '09835'
};

export const customerSummaryResults: CustomerSummary[] = [
    {
        id: '1',
        name: 'AmCap Mortgate, Ltd.',
        status: CustomerStatus.PENDING,

        dbaNames: [ 'AmCap Mortgate, Ltd.' ],
        nmlsId: '129122',
        taxId: '555555',
        address,

        branches,
        pendingSteps: somePendingSteps
    },
    {
        id: '2',
        name: 'American Bank',
        status: CustomerStatus.PENDING,

        dbaNames: [ 'American Bank' ],
        nmlsId: '417017',
        taxId: '555555',
        address,

        branches,
        pendingSteps: []
    },
    {
        id: '3',
        name: 'Bank of Grand Lake',
        status: CustomerStatus.ACTIVE,

        dbaNames: [ 'Bank of Grand Lake' ],
        nmlsId: '654321',
        taxId: '555555',
        address
    },
    {
        id: '4',
        name: 'Ark Mortgage, Inc.',
        status: CustomerStatus.ACTIVE,

        dbaNames: [ 'Ark Mortgage, Inc.' ],
        nmlsId: '654321',
        taxId: '555555',
        address,

        branches
    },
    {
        id: '5',
        name: 'Bank of Clarke County',
        status: CustomerStatus.ACTIVE,

        dbaNames: [ 'Bank of Clarke County' ],
        nmlsId: '654321',
        taxId: '555555',
        address,

        branches
    },
    {
        id: '6',
        name: 'Bay Equity LLC',
        status: CustomerStatus.ACTIVE,

        dbaNames: [ 'Bay Equity LLC' ],
        nmlsId: '654321',
        taxId: '555555',
        address,

        branches
    },
    {
        id: '7',
        name: 'A I E Financial, Inc.',
        status: CustomerStatus.ACTIVE,

        dbaNames: [ 'A I E Financial, Inc.' ],
        nmlsId: '654321',
        taxId: '555555',
        address,

        branches
    },
    {
        id: '8',
        name: 'AmRes Corporation',
        status: CustomerStatus.ACTIVE,

        dbaNames: [ 'AmRes Bank' ],
        nmlsId: '654321',
        taxId: '555555',
        address,

        branches
    },
    {
        id: '9',
        name: 'Barton Creek Lending Group LLC',
        status: CustomerStatus.ACTIVE,

        dbaNames: [ 'Barton Creek Lending Group LLC' ],
        nmlsId: '654321',
        taxId: '555555',
        address,

        branches
    },
    {
        id: '10',
        name: 'Bay-Valley Mortgage Group',
        status: CustomerStatus.ACTIVE,

        dbaNames: [ 'Bay-Valley Mortgage Group' ],
        nmlsId: '654321',
        taxId: '555555',
        address,

        branches
    },
    {
        id: '11',
        name: 'Inactive Bank',
        status: CustomerStatus.INACTIVE,

        dbaNames: [ 'Inactive Bank' ],
        nmlsId: '123456',
        taxId: '555555',
        address,

        branches
    }
];

export const customer: CustomerDetails = {
    id: '1',
    name: 'American Bank',
    nmlsId: '123456',
    taxId: '1234567890',
    address: branches[0].address,
    dbaNames: [ 'AmCap Mortgage, Ltd.', 'American Bank LTD' ],
    primaryContact: contacts[0],
    status: CustomerStatus.PENDING,
    pendingSteps: somePendingSteps,
    branches,
    productTypeConfigs: mockedProductTypeConfigs,
    eligibleProductIds: mockedProducts.map(({ id }) => id.toString())
};
