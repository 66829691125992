export enum NumericFieldOperator {
    EQUALS = 'EQUALS',
    GREATER_THAN = 'GREATER_THAN',
    LESS_THAN = 'LESS_THAN'
}

export const numericFieldOperatorDisplay = {
    [NumericFieldOperator.EQUALS]: '=',
    [NumericFieldOperator.GREATER_THAN]: '>',
    [NumericFieldOperator.LESS_THAN]: '<'
};

export const numericFieldOperatorTooltips = {
    [NumericFieldOperator.EQUALS]: 'Equals',
    [NumericFieldOperator.GREATER_THAN]: 'Greater than',
    [NumericFieldOperator.LESS_THAN]: 'Less than'
};
