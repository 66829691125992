import { apiUtils } from '../../api-utils';
import { LoanAllocationLimitType } from '../../enums/loanAllocationLimitTypeEnum';
import { UnderwritingType } from '../../enums/underwritingTypeEnum';


export function getLoanAllocationLimits(clientID: string, customerID: string): Promise<LoanAllocationLimit[]> {
    return apiUtils.get(`/client/${clientID}/customer/${customerID}/loan-allocation-limit`);
}

export function createLoanAllocationLimit(
    clientID: string, customerID: string, limit: LoanAllocationLimit
): Promise<LoanAllocationLimit> {
    return apiUtils.post(`/client/${clientID}/customer/${customerID}/loan-allocation-limit`, limit);
}

export function updateLoanAllocationLimit(
    clientID: string, customerID: string, limit: LoanAllocationLimit
): Promise<LoanAllocationLimit> {
    return apiUtils.put(`/client/${clientID}/customer/${customerID}/loan-allocation-limit/${limit.id}`, limit);
}

export function deleteLoanAllocationLimit(
    clientID: string, customerID: string, limitID: string
): Promise<LoanAllocationLimit> {
    return apiUtils.delete(`/client/${clientID}/customer/${customerID}/loan-allocation-limit/${limitID}`);
}

export interface LoanAllocationLimit {
    id: string;
    customerId: string;
    limitAmount: number;
    expirationDate: string;
    type: LoanAllocationLimitType;
    underwritingType?: UnderwritingType;
    productTypeConfigId?: string;
    productId?: string;
}
