import { PermissionType } from '@api';
import { RoutedDialogProps } from '@tsp-ui/core/components';
import { useHasPermission } from '@utils/hooks/useHasPermission';
import { NotAllowedDialog } from '@views/NotAllowedDialog';
import AdminAddEditDialogTemplate from '@views/admin/components/AdminAddEditDialogTemplate';
import { useContext } from 'react';

import CustomerForm  from '../../../CustomerForm';
import { CustomerDetailContext } from '../../CustomerDetailPage';


type EditDetailsDialogProps = Omit<RoutedDialogProps, 'title' | 'children'>;

export default function EditCustomerDetailsDialog(props: EditDetailsDialogProps) {
    const { customer, updateCustomer } = useContext(CustomerDetailContext);

    // TODO move permission handling to CustomerDetailPage
    const [ canEditCustomer ] = useHasPermission([ PermissionType.EDIT_CUSTOMERS ]);

    return canEditCustomer ? (
        <AdminAddEditDialogTemplate
            entityName="customer"
            entityToEdit={customer}
            Form={CustomerForm}
            onFormSubmit={updateCustomer}
            {...props}
        />
    ) : (
        <NotAllowedDialog {...props} />
    );
}
