import api, { Branch, PermissionType } from '@api';
import { CheckCircleOutline, Edit, RemoveCircleOutline } from '@mui/icons-material';
import { Paper } from '@mui/material';
import { AddressTypography, IconButton } from '@tsp-ui/core/components';
import {
    useConfirm, usePageMessage, useParams
} from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils/hooks';
import { useHasPermission } from '@utils/hooks/useHasPermission';
import { AdminRouteParams } from '@views/admin/components/AdminPageTemplate';
import CustomerDetails from '@views/admin/customers/components/CustomerDetails';

import styles from './BranchCard.module.scss';


interface BranchCardProps {
    branch: Branch;
    mainName?: string;
    onEditClick?: (branch: Branch) => void;
    onToggleActive?: (branch: Branch) => void;
    readOnly?: boolean;
}

export default function BranchCard({
    branch,
    mainName,
    onEditClick = () => {},
    onToggleActive,
    readOnly
}: BranchCardProps) {
    const { id: clientID } = useGetCurrentAccount();
    const { customerID } = useParams<AdminRouteParams<'customer'>>();

    const pageMessage = usePageMessage();
    const confirm = useConfirm();

    async function toggleActive() {
        try {
            const shouldDeactivate = !branch.isActive || (await confirm('Are you sure you want to deactivate this branch?'));

            if (shouldDeactivate) {
                const updatedBranch = await api.customer.branches.updateBranch(clientID, customerID, {
                    ...branch,
                    isActive: !branch.isActive
                });

                onToggleActive?.(updatedBranch);

                const activationStatus = branch.isActive ? 'deactivated' : 'activated';
                pageMessage.success(`Branch ${activationStatus}`);
            }
        } catch (error) {
            const action = branch.isActive ? 'deactivating' : 'activating';
            pageMessage.handleApiError(`An error occurred while ${action} the branch`, error);
        }
    }

    const {
        isActive
    } = branch;

    const [ canEditBranches ] = useHasPermission([ PermissionType.EDIT_CUSTOMER_BRANCHES ]);

    return (
        <Paper
            variant="outlined"
            className={styles.branchDetails}
        >
            <CustomerDetails
                variant="body2"
                dba={branch.dba !== mainName ? branch.dba : undefined}
                nmlsID={branch.nmlsId}
            />

            <AddressTypography
                address={branch.address}
                variant="body2"
            />

            <div className={styles.buttonsContainer}>
                {!readOnly && (
                    <IconButton
                        className={styles.button}
                        disabled={!canEditBranches}
                        onClick={() => onEditClick(branch)}
                        tooltip={canEditBranches ? 'Edit' : 'You do not have permission to edit branches'}
                    >
                        <Edit color="secondary" />
                    </IconButton>
                )}

                {isActive && !readOnly && (
                    <IconButton
                        tooltip={canEditBranches ? 'Deactivate' : 'You do not have permission to deactivate branches'}
                        onClick={toggleActive}
                        edge="end"
                    >
                        <RemoveCircleOutline color="error" />
                    </IconButton>
                )}

                {!isActive && !readOnly && (
                    <IconButton
                        tooltip={canEditBranches ? 'Reactivate' : 'You do not have permission to reactivate branches'}
                        onClick={toggleActive}
                        edge="end"
                    >
                        <CheckCircleOutline color="success" />
                    </IconButton>
                )}
            </div>

        </Paper>
    );
}
