import { Button, DialogContent } from '@mui/material';
import { DialogActions, RoutedDialog } from '@tsp-ui/core/components';
import { useState } from 'react';

import LockAvailabilityOverrideForm from './LockAvailabilityOverrideForm';


export function LockAvailabilityOverrideDialog() {
    const [ loading, setLoading ] = useState(false);

    return (
        <RoutedDialog
            title="Override lock availability"
            maxWidth={false}
            closeTo=".."
            loading={loading}
        >
            <DialogContent>
                <LockAvailabilityOverrideForm setLoading={setLoading} />
            </DialogContent>

            <DialogActions loading={loading}>
                <Button
                    type="submit"
                    form={LockAvailabilityOverrideForm.formID}
                    variant="contained"
                >
                    Save
                </Button>
            </DialogActions>
        </RoutedDialog>
    );
}
