import { PermissionType, losDisplay } from '@api';
import { Link as MuiLink, Typography } from '@mui/material';
import { LabelGroup, LabeledValue } from '@tsp-ui/core';
import { useHasPermission } from '@utils/hooks/useHasPermission';
import EditableSectionCard from '@views/admin/components/EditableSectionCard';
import { useContext } from 'react';
import { Link } from 'react-router-dom';

import { ClientDetailsContext } from '../../ClientDetailPage';


export function LOSConfigCard() {
    const { losConfig } = useContext(ClientDetailsContext);

    const [ canViewLOSConfig, canManageLOSConfig ] = useHasPermission(
        [ PermissionType.VIEW_LOS_CONFIG, PermissionType.MANAGE_LOS_CONFIG ]
    );

    return (
        <EditableSectionCard
            editTo="edit-los-config"
            header="LOS configuration"
            disableEdit={!canManageLOSConfig}
            disabledTooltip="You do not have permission to edit this client's LOS configuration"
        >
            {!canViewLOSConfig ? (
                <Typography
                    color="textSecondary"
                    variant="body1"
                >
                    You do not have permission to view this client's LOS configuration.
                </Typography>
            ) : losConfig ? (
                <LabelGroup>
                    <LabeledValue
                        label="LOS:"
                        value={losDisplay[losConfig.los]}
                        variants={{ value: 'body1' }}
                    />

                    {losConfig.defaultLoanFolder && (
                        <LabeledValue
                            label="Loan Folder:"
                            value={losConfig.defaultLoanFolder}
                            variants={{ value: 'body1' }}
                        />
                    )}

                    <LabeledValue
                        label="Api URL:"
                        value={losConfig.apiUrl}
                        variants={{ value: 'body1' }}
                    />

                    <LabeledValue
                        label="Client ID:"
                        value={losConfig.clientId}
                        variants={{ value: 'body1' }}
                    />

                    <LabeledValue
                        label="Client secret:"
                        value={losConfig.clientSecret}
                        variants={{ value: 'body1' }}
                    />

                    {losConfig.instanceId && (
                        <LabeledValue
                            label="Instance ID:"
                            value={losConfig.instanceId}
                            variants={{ value: 'body1' }}
                        />
                    )}

                    {losConfig.userName && (
                        <LabeledValue
                            label="Username:"
                            value={losConfig.userName}
                            variants={{ value: 'body1' }}
                        />
                    )}

                    {losConfig.password && (
                        <LabeledValue
                            label="Password:"
                            value={losConfig.password}
                            variants={{ value: 'body1' }}
                        />
                    )}
                </LabelGroup>
            ) : (
                <Typography variant="body2">
                    No LOS configuration set.

                    {' '}

                    {canManageLOSConfig && (
                        <>
                            <MuiLink
                                component={Link}
                                to="edit-los-config"
                            >
                                Click here
                            </MuiLink> to set one up.
                        </>
                    )}
                </Typography>
            )}
        </EditableSectionCard>
    );
}
