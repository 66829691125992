import { LLPAVersionSummary } from '@api';
import { KeyboardArrowDown } from '@mui/icons-material';
import {
    Button, Popover, Skeleton, Tooltip, Typography
} from '@mui/material';
import LLPAVersionsCard from '@views/admin/llpas/components/LLPAVersionsCard';
import clsx from 'clsx';
import {
    isFuture, isPast, isToday, parseISO
} from 'date-fns';
import React, { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import styles from './LLPAVersionButton.module.scss';


interface LLPAVersionButtonProps {
    llpaVersions: LLPAVersionSummary[] | undefined;
}

export function useLLPAVersionQueryParam() {
    const { search } = useLocation();

    return useMemo(() => {
        const params = new URLSearchParams(search);
        return params.get('llpaVersionId') || undefined;
    }, [ search ]);
}

export function LLPAVersionButton({ llpaVersions }: LLPAVersionButtonProps) {
    const [ anchorEl, setAnchorEl ] = useState<HTMLElement>();
    const selectedVersion = useSelectedLLPAVersion(llpaVersions);

    const isSelectionCurrent = !selectedVersion || isCurrentLLPAVersion(selectedVersion);
    const isSelectionExpired = selectedVersion?.expirationDate && isPast(parseISO(selectedVersion.expirationDate));
    const isSelectionFuture = selectedVersion && isFuture(parseISO(selectedVersion.effectiveDate));

    const selectedVersionDisplay = `Version ${selectedVersion?.versionNumber}`;

    return (
        <>
            {!llpaVersions ? (
                <Skeleton
                    height={34}
                    width={200}
                />
            ) : (
                <>
                    <div
                        className={clsx(styles.versionChip, {
                            [styles.active]: isSelectionCurrent,
                            [styles.pending]: isSelectionFuture,
                            [styles.expired]: isSelectionExpired
                        })}
                    >
                        {isSelectionFuture ? 'Future' : isSelectionCurrent ? 'Current' : 'Expired'}

                        {' '}version
                    </div>

                    <Tooltip title="Click to view other llpa versions">
                        <Button
                            onClick={(event) => setAnchorEl(event.currentTarget)}
                            endIcon={(
                                <KeyboardArrowDown color="primary" />
                            )}
                        >
                            {selectedVersionDisplay}
                        </Button>
                    </Tooltip>
                </>
            )}

            <Popover
                open={!!anchorEl}
                onClose={() => setAnchorEl(undefined)}
                anchorEl={anchorEl}
                anchorOrigin={{
                    horizontal: 'right',
                    vertical: 'bottom'
                }}
                transformOrigin={{
                    horizontal: 'right',
                    vertical: 'top'
                }}
                classes={{
                    paper: styles.popoverPaper
                }}
            >
                <Typography variant="h6">
                    LLPA versions
                </Typography>

                <LLPAVersionsCard
                    versions={llpaVersions}
                    selectedVersionId={selectedVersion?.id}
                    onVersionClick={() => setAnchorEl(undefined)}
                />
            </Popover>
        </>
    );
}

export function useSelectedLLPAVersion(llpaVersions: LLPAVersionSummary[] | undefined) {
    const llpaVersionId = useLLPAVersionQueryParam();

    return llpaVersionId
        ? llpaVersions?.find(({ id }) => id === llpaVersionId)
        : llpaVersions?.find((version) => isCurrentLLPAVersion(version)) || llpaVersions?.[0];
}

export function isCurrentLLPAVersion({ effectiveDate, expirationDate }: LLPAVersionSummary) {
    const effective = parseISO(effectiveDate);
    return (isPast(effective) || isToday(effective)) && (!expirationDate || isFuture(parseISO(expirationDate)));
}
