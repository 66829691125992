import { PermissionType } from '@api';
import { useHasPermission } from '@utils/hooks/useHasPermission';
import { ReactElement, ReactNode } from 'react';


interface PermissionCheckProps {
    permission: PermissionType;
    noPermissionContent: JSX.Element;
    children?: ReactNode;
}

export function PermissionCheck({
    permission,
    noPermissionContent,
    children
}: PermissionCheckProps) {
    const [ hasPermission ] = useHasPermission([ permission ]);

    return hasPermission ? children as ReactElement : noPermissionContent;
};
