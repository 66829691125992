import api, { PermissionType, RateSheet } from '@api';
import {
    DialogActions, Pagination, Paper, Typography
} from '@mui/material';
import {
    Button,
    ExpandableHeader, LabeledValue, PaginatedResponse, RoutedDialogImplProps,
    useAsyncEffect, usePageMessage
} from '@tsp-ui/core';
import { useGetCurrentAccount, useHasPermission } from '@utils';
import UserLink from '@views/admin/users/UserLink';
import CardDialogContent from '@views/components/CardDialogContent';
import RoutedFormDialog from '@views/components/RoutedFormDialog';
import { format, parseISO } from 'date-fns';
import { useCallback, useState } from 'react';

import styles from './RateSheetDialog.module.scss';
import RateSheetForm from './RateSheetForm';
import RateSheetLink from './RateSheetLink';


export default function RateSheetDialog(props: RoutedDialogImplProps) {
    const [ rateSheetResponse, setRateSheetResponse ] = useState<PaginatedResponse<RateSheet>>();
    const [ currentRateSheet, setCurrentRateSheet ] = useState<RateSheet | undefined>();
    const [ loading, setLoading ] = useState(false);
    const [ pageNumber, setPageNumber ] = useState(1);
    const [ showAdd, setShowAdd ] = useState(false);

    const [ canManage ] = useHasPermission([ PermissionType.MANAGE_RATE_SHEETS ]);
    const { id: clientId, customerId } = useGetCurrentAccount();
    const pageMessage = usePageMessage();

    const expiredRateSheets = rateSheetResponse?.data.filter(rateSheet => !rateSheet.isCurrent) || [];

    const getRateSheetsCallback = useCallback(async () => {
        setLoading(true);

        try {
            const rateSheetResponse = await api.pricing.getRateSheets(clientId, customerId, {
                pageNumber,
                pageSize: 8
            });
            setRateSheetResponse(rateSheetResponse);

            // current rate sheet is always returned in the first results set
            const currentRateSheet = rateSheetResponse?.data.find(rateSheet => rateSheet.isCurrent);
            if (currentRateSheet) {
                setCurrentRateSheet(currentRateSheet);
            }
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching the rate sheets', error);
        }

        setLoading(false);
    }, [
        clientId, customerId, pageMessage, pageNumber
    ]);

    useAsyncEffect(getRateSheetsCallback);

    return (
        <RoutedFormDialog
            title="Rate sheets"
            loading={!rateSheetResponse}
            saveLoading={loading}
            maxWidth="md"
            fullWidth
            classNames={{ formShown: styles.formShown }}
            classes={{ paper: styles.dialogRoot }}
            showForm={showAdd}
            formTitle="Add rate sheet"
            form={(
                <RateSheetForm
                    setRateSheets={() => {
                        setPageNumber(1);
                        getRateSheetsCallback();
                    }}
                    closeForm={() => {
                        setShowAdd(false);
                    }}
                />
            )}
            {...props}
        >
            <CardDialogContent className={styles.dialogContent}>
                {currentRateSheet ? (
                    <div>
                        <Typography
                            className={styles.currentHeader}
                            fontWeight={500}
                        >
                            Current rate sheet
                        </Typography>

                        <RateSheetCard rateSheet={currentRateSheet} />
                    </div>
                ) : (
                    <Typography
                        variant="body2"
                    >
                        No current rate sheet uploaded
                    </Typography>
                )}

                {expiredRateSheets.length > 0 && (
                    <div className={styles.expiredRateSheets}>
                        <ExpandableHeader
                            title="Expired rate sheets"
                            defaultExpand={false}
                            disableExpand={false}
                            secondaryText={getExpiredRateSheetSecondaryText(currentRateSheet, rateSheetResponse!)}
                            expandedContent={(
                                <div>
                                    {expiredRateSheets.map(rateSheet => (
                                        <RateSheetCard
                                            key={rateSheet.id}
                                            rateSheet={rateSheet}
                                        />
                                    ))}

                                    {(rateSheetResponse?.totalPages || 0) > 1 && (
                                        <Pagination
                                            className={styles.pagination}
                                            count={rateSheetResponse!.totalPages}
                                            page={rateSheetResponse!.pageNumber}
                                            onChange={(_, page) => setPageNumber(page)}
                                            color="primary"
                                        />
                                    )}
                                </div>
                            )}
                        />

                    </div>
                )}
            </CardDialogContent>

            <DialogActions>
                <Button
                    variant="contained"
                    onClick={() => setShowAdd(true)}
                    disabled={!canManage}
                    tooltip={canManage ? '' : 'You do not have permission to add a rate sheet'}
                >
                    Add rate sheet
                </Button>
            </DialogActions>
        </RoutedFormDialog>
    );
}
interface RateSheetCardProps {
    rateSheet: RateSheet;
}

function RateSheetCard({ rateSheet }: RateSheetCardProps) {
    return (
        <Paper
            variant="outlined"
            className={styles.card}
        >
            <div className={styles.sheetInfo}>
                <LabeledValue
                    label="Rate sheet"
                    value={(
                        <RateSheetLink rateSheetId={rateSheet.id} />
                    )}
                    variant="vertical"
                />

                <div className={styles.dates}>
                    <LabeledValue
                        label="Effective date"
                        value={format(parseISO(rateSheet.effectiveDate), 'MM/dd/yyyy hh:mm a')}
                        variant="vertical"
                    />

                    <LabeledValue
                        label="Expiration date"
                        value={rateSheet.expirationDate ? format(parseISO(rateSheet.expirationDate), 'MM/dd/yyyy hh:mm a') : 'No expiration date'}
                        variant="vertical"
                    />
                </div>
            </div>

            <Typography
                variant="caption"
                color="textSecondary"
                className={styles.createdBy}
            >
                Uploaded by <UserLink userId={rateSheet.creatorUserId} />
            </Typography>
        </Paper>
    );
}

function getExpiredRateSheetSecondaryText(
    currentRateSheet: RateSheet | undefined,
    {
        totalRecords, pageNumber, data, pageSize
    }: PaginatedResponse<RateSheet>
) {
    const totalExpiredRateSheets = currentRateSheet ? totalRecords - 1 : totalRecords;
    const start = (pageNumber - 1) * pageSize + (pageNumber > 1 && currentRateSheet ? 0 : 1);

    const isCurrentRateSheetIncluded = currentRateSheet && pageNumber === 1;

    return `${start}-${start + data.length - (isCurrentRateSheetIncluded ? 2 : 1)} of ${totalExpiredRateSheets} sheet${totalExpiredRateSheets !== 1 ? 's' : ''}`;
}
