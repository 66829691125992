import api, {
    BooleanEnum,
    CreateLoanProgramReqBody,
    EligibilityColumnMetadata,
    EligibilityGuideline,
    EligibilityMatrix,
    LoanProgramStatus,
    LoanProperty,
    MatrixColumnMetadata,
    loanPropertyDisplay,
    loanPropertyEligibilityGuidelineFieldNames,
    loanPropertyEnumFieldTypes
} from '@api';
import { HelpOutline } from '@mui/icons-material';
import {
    Button,
    TextField as MuiTextField,
    Paper,
    Step,
    StepLabel,
    Stepper,
    Tooltip,
    Typography
} from '@mui/material';
import {
    IconTypography,
    PaperSaveLoader,
    renderEnumOptions
} from '@tsp-ui/core/components';
import { usePageMessage, useParams } from '@tsp-ui/core/utils';
import { useLoanPropertyEnumDisplays } from '@utils';
import { numericEntryToString, parseNumericMatrixEntry } from '@utils/numeric-range-utils';
import { AdminSubRouteParams } from '@views/admin/components/AdminPageTemplate';
import { LoanProgramsContext } from '@views/admin/investors/InternalInvestorDetailRoutes';
import {
    formatHighLevelGuidelineValue
} from '@views/admin/investors/InvestorDetailPage/LoanProgramDetailPage/components/HighLevelGuidelineCard';
import {
    EligibilityExclusionsFormValues, ExclusionGroupRow, formValuesToExclusions, getEmptyEligibilityExclusions
} from '@views/admin/investors/InvestorDetailPage/components/ExclusionGroupRow';
import { useEligibilityVersionIdQueryParam } from '@views/admin/investors/components/EligibilityVersionButton';
import clsx from 'clsx';
import {
    Dispatch, SetStateAction, useContext, useState
} from 'react';
import {
    FormProvider, useForm, useFormContext, useWatch
} from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { EligibilityGuidelineGrid } from '../../components/EligibilityGuidelineGrid';

import { EditHighLevelGuidelines, MatrixColumnMetadataFormValues } from './HighLevelGuidelines';
import { LoanProgramDetailsFields } from './LoanProgramDetailsFields';
import styles from './LoanProgramForm.module.scss';


export interface EligibilityGuidelineFormValues extends Omit<
    EligibilityGuideline, 'highBalance' | 'units' | 'fico' | 'ltv' | 'cltv' | 'dti' | 'term' | 'reservesMonths'
> {
    highBalance?: BooleanEnum;
    units?: string;
    fico?: string;
    ltv?: string;
    cltv?: string;
    dti?: string;
    term?: string;
    reservesMonths?: string;
}

export interface EligibilityColumnMetadataFormValues extends Omit<EligibilityColumnMetadata, 'value'> {
    value: string | string[];
}

export interface EligibilityMatrixFormValues {
    columnMetadata: EligibilityColumnMetadataFormValues[];
    inclusions: EligibilityGuidelineFormValues[];
}

export interface CreateLoanProgramFormValues extends Omit<CreateLoanProgramReqBody, 'matrix'> {
    matrix: EligibilityMatrixFormValues & {
        exclusions: EligibilityExclusionsFormValues;
    };
}

interface LoanProgramFormProps {
    activeStep: number;
    setActiveStep: Dispatch<SetStateAction<number>>;
    clientId?: string;
}

export function LoanProgramForm({
    activeStep, setActiveStep, clientId
}: LoanProgramFormProps) {
    const navigate = useNavigate();
    const pageMessage = usePageMessage();
    const { investorID } = useParams<AdminSubRouteParams<'guidelineSet'>>();

    const { search } = useLocation();
    const eligibilityVersionId = useEligibilityVersionIdQueryParam();

    const { loanPrograms = [], setLoanPrograms } = useContext(LoanProgramsContext);

    const formMethods = useForm<CreateLoanProgramFormValues>({
        defaultValues: {
            matrix: {
                columnMetadata: [],
                inclusions: [],
                exclusions: getEmptyEligibilityExclusions()
            },
            loanProgram: {
                investorId: investorID,
                eligibilityVersionId,
                status: LoanProgramStatus.ACTIVE
            }
        }
    });

    const [ loading, setLoading ] = useState(false);
    const [ needsSetup, setNeedsSetup ] = useState(true);
    const [ shouldAddExclusions, setShouldAddExclusions ] = useState<boolean>();

    const handleSubmit = formMethods.handleSubmit(async formValues => {
        if (activeStep < 2) {
            if (needsSetup && activeStep === 1) {
                setNeedsSetup(false);
            } else {
                setActiveStep(activeStep + 1);
            }
        } else {
            setLoading(true);

            try {
                const reqBody = {
                    loanProgram: formValues.loanProgram,
                    matrix: {
                        ...formValuesToMatrix(formValues.matrix, eligibilityVersionId!),
                        exclusions: formValuesToExclusions(formValues.matrix.exclusions, eligibilityVersionId!)
                    }
                };

                const { loanProgram } = clientId
                    ? await api.client.investors.createLoanProgram(clientId, reqBody)
                    : await api.investors.createLoanProgram(reqBody);

                setLoanPrograms([ ...loanPrograms, loanProgram ]);

                navigate(`../loan-programs/${loanProgram.id}${search}`);

                pageMessage.success('Loan program created');
            } catch (error) {
                pageMessage.handleApiError('An error occurred while creating the loan program', error);
            }

            setLoading(false);
        }
    });

    return (
        <>
            <Stepper
                activeStep={activeStep}
                className={styles.stepper}
            >
                <Step>
                    <StepLabel>
                        Details
                    </StepLabel>
                </Step>

                <Step>
                    <StepLabel>
                        Matrix
                    </StepLabel>
                </Step>

                <Step>
                    <StepLabel>
                        Exclusions
                    </StepLabel>
                </Step>
            </Stepper>

            <form
                id="loan-program-form"
                onSubmit={handleSubmit}
                noValidate
                className={clsx({
                    [styles.matrixFormRoot]: activeStep === 1 && !needsSetup
                })}
            >
                <FormProvider {...formMethods}>
                    {activeStep === 0 ? (
                        <Paper
                            variant="outlined"
                            className={styles.detailsFields}
                        >
                            <LoanProgramDetailsFields />
                        </Paper>
                    ) : activeStep === 1 ? (
                        needsSetup ? (
                            <>
                                <MatrixColumnMetadataSetup name="matrix.columnMetadata" />

                                <Typography
                                    variant="caption"
                                    color="textSecondary"
                                    align="center"
                                >
                                    This can be changed on the matrix later by dragging columns out of the matrix<br />
                                    and using the "add column" button in the matrix's more options button
                                </Typography>
                            </>
                        ) : (
                            <EligibilityMatrixGrid />
                        )
                    ) : (
                        <>
                            <Paper
                                variant="outlined"
                                className={styles.exclusionQuestionPaper}
                            >
                                <Typography>
                                    Are there additional exclusions you would like to add to this Loan Program?
                                </Typography>

                                <div>
                                    <Button
                                        variant={shouldAddExclusions === false ? 'contained' : undefined}
                                        onClick={() => {
                                            setShouldAddExclusions(false);
                                            formMethods.setValue(
                                                'matrix.exclusions', getEmptyEligibilityExclusions()
                                            );
                                        }}
                                    >
                                        No
                                    </Button>

                                    <Button
                                        variant={shouldAddExclusions ? 'contained' : undefined}
                                        onClick={() => setShouldAddExclusions(true)}
                                    >
                                        Yes
                                    </Button>
                                </div>
                            </Paper>

                            {shouldAddExclusions && (
                                <Paper
                                    variant="outlined"
                                    className={styles.filterContainer}
                                >
                                    <ExclusionGroupRow nameBase="matrix.exclusions" />
                                </Paper>
                            )}
                        </>
                    )}
                </FormProvider>
            </form>

            <div className={styles.buttonContainer}>
                {activeStep > 0 ? (
                    <Button
                        onClick={() => setActiveStep(activeStep - 1)}
                    >
                        Back
                    </Button>
                ) : (
                    <Button
                        component={Link}
                        to={`..${search}`}
                    >
                        Cancel
                    </Button>
                )}

                <Button
                    variant="contained"
                    type="submit"
                    form="loan-program-form"
                    disabled={activeStep === 2 && shouldAddExclusions === undefined}
                >
                    {(activeStep === 2) ? 'Submit' : 'Next'}
                </Button>
            </div>

            <PaperSaveLoader loading={loading} />
        </>
    );
}

export function EligibilityMatrixGrid() {
    const eligibilityVersionId = useEligibilityVersionIdQueryParam();
    const loanPropertyEnumDisplays = useLoanPropertyEnumDisplays();

    return (
        <div className={styles.gridContainer}>
            <EditHighLevelGuidelines
                nameBase="matrix.columnMetadata"
                defaultNewValues={{ eligibilityVersionId } as Partial<EligibilityColumnMetadataFormValues>}
                getSelectOptions={(loanProperty) => loanPropertyEnumDisplays[loanProperty]}
                getValueType={(loanProperty) => loanPropertyEnumFieldTypes[loanProperty]}
                formatValue={formatHighLevelGuidelineValue}
                loanPropertyOptions={loanPropertyDisplay}
            />

            <div className={styles.innerGridContainer}>
                <EligibilityGuidelineGrid />
            </div>
        </div>
    );
}

interface MatrixColumnMetadataSetupProps  {
    name: string;
}

export function MatrixColumnMetadataSetup({ name }: MatrixColumnMetadataSetupProps) {
    const columnMetadata = useWatch({ name }) as EligibilityColumnMetadataFormValues[];

    const { setValue } = useFormContext();

    return (
        <div className={styles.gridSetupContainer}>
            <Paper
                variant="outlined"
                className={styles.gridSetupPaper}
            >
                <Typography>
                    Which loan properties would you like to be included in the matrix?
                </Typography>

                <MuiTextField
                    label="Loan properties"
                    select
                    value={columnMetadata.map(({ loanProperty }) => loanProperty)}
                    className={styles.propertiesTextField}
                    onChange={(e) => {
                        const value = e.target.value as unknown as LoanProperty[];

                        setValue(name, value.map((loanProperty) => (
                            columnMetadata.find((meta) => meta.loanProperty === loanProperty) || {
                                loanProperty
                            } as EligibilityColumnMetadataFormValues
                        )));
                    }}
                    SelectProps={{
                        multiple: true
                    }}
                >
                    {renderEnumOptions(loanPropertyDisplay)}
                </MuiTextField>

                {!!columnMetadata.length && (
                    <>
                        <IconTypography
                            align="center"
                            iconPosition="after"
                            fontWeight={400}
                            icon={(
                                <Tooltip
                                    title={(
                                        <>
                                            High level guidelines will simplify the management of the eligibility matrix
                                            by applying values across all rows in the matrix.<br /><br />

                                            For loan properties that are numeric, a numeric condition can be defined and
                                            will be applied to each row in the matrix. For loan properties with a list
                                            of possible options, selecting multiple will duplicate all existing rows in
                                            the matrix for each value selected.
                                        </>
                                    )}
                                >
                                    <HelpOutline color="primary" />
                                </Tooltip>
                            )}
                        >
                            Which of these would you like to be high level guidelines?
                        </IconTypography>

                        <MuiTextField
                            label="High level guidelines"
                            select
                            value={columnMetadata
                                .filter(({ isHighLevel }) => isHighLevel)
                                .map(({ loanProperty }) => loanProperty)}
                            className={styles.propertiesTextField}
                            onChange={(e) => {
                                const value = e.target.value as unknown as LoanProperty[];

                                setValue(name, columnMetadata.map((meta) => ({
                                    ...meta,
                                    isHighLevel: value.includes(meta.loanProperty)
                                })));
                            }}
                            SelectProps={{
                                multiple: true
                            }}
                        >
                            {renderEnumOptions(Object.fromEntries(
                                Object.entries(loanPropertyDisplay).filter(([ loanProperty ]) => (
                                    columnMetadata.some((meta) => meta.loanProperty === loanProperty)
                                ))
                            ))}
                        </MuiTextField>
                    </>
                )}
            </Paper>
        </div>
    );
}

export function matrixToFormValues(matrix: EligibilityMatrix): EligibilityMatrixFormValues {
    return {
        columnMetadata: matrix.columnMetadata.map((col) => ({
            ...col,
            value: formatLoanProgramFieldFormValue(col.value)
        })),
        inclusions: matrix.inclusions.map(convertEligibilityGuidelineToFormValues)
    };
}

export function formValuesToMatrix(
    formValues: EligibilityMatrixFormValues, eligibilityVersionId: string
): Omit<EligibilityMatrix, 'exclusions'> {
    const { columnMetadata, inclusions } = formValues;

    return {
        columnMetadata: columnMetadata.map((colFormValues) => ({
            ...colFormValues,
            value: parseLoanProgramFieldFormValue(colFormValues.loanProperty, colFormValues.value)
        })),
        inclusions: inclusions.map((inclusion) => {
            const guideline = convertFormValuesToEligibilityGuideline(inclusion, eligibilityVersionId);

            Object.values(LoanProperty).forEach((loanProperty) => {
                if (!columnMetadata.some((meta) => meta.loanProperty === loanProperty)) {
                    delete guideline[loanPropertyEligibilityGuidelineFieldNames[loanProperty]];
                }
            });

            return guideline;
        })
    };
}

function convertEligibilityGuidelineToFormValues(guideline: EligibilityGuideline): EligibilityGuidelineFormValues {
    return {
        ...guideline,
        highBalance: guideline?.highBalance === undefined ? undefined : (
            guideline?.highBalance ? BooleanEnum.TRUE : BooleanEnum.FALSE
        ),
        units: numericEntryToString(guideline?.units),
        fico: numericEntryToString(guideline?.fico),
        ltv: numericEntryToString(guideline?.ltv),
        cltv: numericEntryToString(guideline?.cltv),
        dti: numericEntryToString(guideline?.dti),
        term: numericEntryToString(guideline?.term),
        reservesMonths: numericEntryToString(guideline?.reservesMonths)
    };
}

function convertFormValuesToEligibilityGuideline(
    formValues: EligibilityGuidelineFormValues, eligibilityVersionId: string
): EligibilityGuideline {
    return {
        ...formValues,
        eligibilityVersionId,
        highBalance: formValues?.highBalance === undefined ? undefined : (
            formValues?.highBalance === BooleanEnum.TRUE
        ),
        units: parseNumericMatrixEntry(formValues?.units),
        fico: parseNumericMatrixEntry(formValues?.fico),
        ltv: parseNumericMatrixEntry(formValues?.ltv),
        cltv: parseNumericMatrixEntry(formValues?.cltv),
        dti: parseNumericMatrixEntry(formValues?.dti),
        term: parseNumericMatrixEntry(formValues?.term),
        reservesMonths: parseNumericMatrixEntry(formValues?.reservesMonths)
    };
}

export function parseLoanProgramFieldFormValue(
    loanProperty: LoanProperty, value: MatrixColumnMetadataFormValues<LoanProperty>['value'] | undefined
) {
    const dataType = loanPropertyEnumFieldTypes[loanProperty];

    return dataType === 'enum'
        ? value as string[]
        : dataType === 'boolean'
            ? value === BooleanEnum.TRUE
            : parseNumericMatrixEntry(value as string)!;
}

export function formatLoanProgramFieldFormValue(
    value: MatrixColumnMetadata<LoanProperty>['value']
): MatrixColumnMetadataFormValues<LoanProperty>['value'] {
    return typeof value === 'boolean'
        ? (value ? BooleanEnum.TRUE : BooleanEnum.FALSE)
        : Array.isArray(value)
            ? value
            : numericEntryToString(value)!;
}
