import { NotificationConfig, PermissionType } from '@api';
import {
    Paper, Typography
} from '@mui/material';
import { Button } from '@tsp-ui/core';
import { useHasPermission } from '@utils/hooks';
import { Link, useLocation } from 'react-router-dom';

import NotificationCard from './NotificationCard';
import styles from './NotificationsSection.module.scss';


interface NotificationsSectionProps {
    notifications: NotificationConfig[];
}

export default function NotificationsSection({ notifications }: NotificationsSectionProps) {
    const location = useLocation();

    const [ canViewNotifications, canManageNotifications ] = useHasPermission(
        [ PermissionType.VIEW_CLIENT_NOTIFICATION_CONFIGS, PermissionType.MANAGE_CLIENT_NOTIFICATION_CONFIGS ]
    );

    return (
        <Paper
            variant="outlined"
            className={styles.paper}
        >
            <div className={styles.header}>
                <Typography variant="h6">Notifications</Typography>

                <Button
                    component={Link}
                    to={`${location.pathname}/notifications/new`}
                    disabled={!canManageNotifications}
                    tooltip={!canManageNotifications ? 'You do not have permission to add a notification' : ''}
                >
                    Add notification
                </Button>
            </div>

            {canViewNotifications ? (
                notifications.map((notification) => (
                    <NotificationCard
                        key={notification.id}
                        notification={notification}
                    />
                ))
            ) : (
                <div>
                    You do not have permission to view notifications.
                </div>
            )}
        </Paper>
    );
}
