import api, { Contact } from '@api';
import { RoutedDialog, RoutedDialogProps, usePageMessage } from '@tsp-ui/core/components';
import { useActingClientID } from '@utils/hooks';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { CustomerDetailContext, CustomerDetailContextValue } from '../../CustomerDetailPage';

import { AddEditContactDialogContent } from './components/AddEditContactDialogContent';


export default function EditCustomerPrimaryContactDialog(props: Omit<RoutedDialogProps, 'title' | 'children' | 'onSubmit'>) {
    const navigate = useNavigate();
    const pageMessage = usePageMessage();

    const clientID = useActingClientID();

    const {
        customer, updateCustomer
    } = useContext(CustomerDetailContext) as Required<CustomerDetailContextValue>;

    async function handleEdit(formValues: Contact) {
        try {
            updateCustomer({
                ...customer,
                primaryContact: await api.customer.contacts.editContact(clientID, customer.id, formValues)
            });

            closeDialog();
        } catch (error) {
            pageMessage.handleApiError('An error occurred while editing the primary contact', error);
        }
    }

    function closeDialog() {
        navigate(props.closeTo, { replace: true });
    }

    return (
        <RoutedDialog
            {...props}
            title="Edit primary contact"
            maxWidth={false}
        >
            <AddEditContactDialogContent
                defaultValues={customer.primaryContact}
                onEdit={handleEdit}
                onCancel={closeDialog}
            />
        </RoutedDialog>
    );
}
