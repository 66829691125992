import { CommonLoanProgram, LoanProgramStatus, PermissionType } from '@api';
import {
    Button as MuiButton, Link as MuiLink, Tooltip, Typography
} from '@mui/material';
import { Button, FilterTextField, FilterableFilledSection } from '@tsp-ui/core/components';
import { tooltipTitle, useHasPermission } from '@utils';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import { LoanProgramCard } from './LoanProgramCard';


interface LoanProgramSectionProps {
    loanPrograms: CommonLoanProgram[] | undefined;
    isSelectedVersionEditable?: boolean;
    editPermission: PermissionType;
    isInvestorManged?: boolean;
}

export function LoanProgramSection({
    loanPrograms, isSelectedVersionEditable, editPermission, isInvestorManged
}: LoanProgramSectionProps) {
    const { search } = useLocation();
    const [ canEdit ] = useHasPermission([ editPermission ]);

    const disableAdd = !canEdit || !isSelectedVersionEditable;
    const title = tooltipTitle({
        'You don\'t have permission to manage loan programs': !canEdit,
        'Loan programs can only be added to a future eligibility version': !isSelectedVersionEditable,
        'Loan programs cannot be added for investors that are managed by Premicorr': isInvestorManged
    });

    const [ showInactive, setShowInactive ] = useState(false);
    const [ filterText, setFilterText ] = useState('');
    const [ debouncedFilterText ] = useDebounce(filterText, 250);

    const filteredLoanPrograms = loanPrograms?.filter(({ name, status }) => (
        (!debouncedFilterText || name.toLowerCase().includes(debouncedFilterText.toLowerCase()))
        && (showInactive || status === LoanProgramStatus.ACTIVE)
    ));

    return (
        <FilterableFilledSection
            header={(
                <>
                    Loan programs

                    <MuiButton onClick={() => setShowInactive(!showInactive)}>
                        {showInactive ? 'Hide' : 'Show'} inactive
                    </MuiButton>
                </>
            )}
            addButton={(
                <Button
                    variant="contained"
                    component={Link}
                    to={`loan-programs/new${search}`}
                    disabled={!canEdit || !isSelectedVersionEditable || isInvestorManged}
                    tooltip={title}
                >
                    Add loan program
                </Button>
            )}
            filterField={(
                <FilterTextField
                    placeholder="Filter loan programs"
                    helperText="Filter by name"
                    value={filterText}
                    onChange={(event) => setFilterText(event.target.value)}
                />
            )}
        >
            {!loanPrograms?.length ? (
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                    No loan programs are currently configured.{' '}

                    <Tooltip title={title}>
                        <MuiLink
                            component={Link}
                            to={disableAdd ? '' : `loan-programs/new${search}`}
                        >
                            Add a new loan program
                        </MuiLink>
                    </Tooltip>.
                </Typography>
            ) : !filteredLoanPrograms?.length ? (
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                    {debouncedFilterText
                        ? 'No loan programs match your filter criteria'
                        : 'No active loan programs'}
                </Typography>
            ) : filteredLoanPrograms.map(loanProgram => (
                <LoanProgramCard
                    key={loanProgram.id}
                    loanProgram={loanProgram}
                />
            ))}
        </FilterableFilledSection>
    );
}
