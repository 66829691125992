import api, { BulkCommitment, BulkCommitmentPricingTier } from '@api';
import { RemoveCircleOutline } from '@mui/icons-material';
import {
    Button, DialogContent, MenuItem, Typography
} from '@mui/material';
import {
    CurrencyField, DateField, DialogActions, IconButton, PercentField, RoutedDialog, RoutedDialogProps, TextField
} from '@tsp-ui/core/components';
import utilStyles from '@tsp-ui/core/sass/style-utils.module.scss';
import {
    DeepPartial, replaceItemById, useForm, usePageMessage, useParams
} from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils/hooks';
import { BulkCommitmentsContext } from '@views/admin/bulk-commitment/BulkCommitmentManagementPage';
import clsx from 'clsx';
import {
    Dispatch, Fragment, SetStateAction, useContext, useState
} from 'react';
import { FormProvider, useFieldArray } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import styles from './BulkCommitmentDialog.module.scss';


interface BulkCommitmentDialogParams {
    commitmentID: string;
}

const formID = 'bulk-commitment-form';

export default function BulkCommitmentDialog(props: Omit<RoutedDialogProps, 'title' | 'children'>) {
    const { commitmentID } = useParams<BulkCommitmentDialogParams>();
    const { bulkCommitments, loading } = useContext(BulkCommitmentsContext);
    const [ saveLoading, setSaveLoading ] = useState(false);

    const bulkCommitment = bulkCommitments?.find(({ id }) => id === commitmentID);

    return (
        <RoutedDialog
            {...props}
            title={`${!commitmentID ? 'Add' : 'Edit'} bulk commitment`}
            maxWidth="md"
            loading={loading}
            saveLoading={saveLoading}
        >
            <DialogContent>
                {(bulkCommitment || !loading) && (
                    <BulkCommitmentForm
                        commitmentToEdit={bulkCommitment}
                        setSaveLoading={setSaveLoading}
                    />
                )}
            </DialogContent>

            <DialogActions>
                <Button
                    type="submit"
                    variant="contained"
                    form={formID}
                    disabled={saveLoading}
                >
                    Save
                </Button>
            </DialogActions>
        </RoutedDialog>
    );
}

type BulkCommitmentFormValues = Omit<BulkCommitment, 'pricingTiers'> & { pricingTiers: Partial<BulkCommitmentPricingTier>[] };

interface BulkCommitmentFormProps {
    commitmentToEdit: BulkCommitment | undefined;
    setSaveLoading: Dispatch<SetStateAction<boolean>>;
}

function BulkCommitmentForm({ commitmentToEdit, setSaveLoading }: BulkCommitmentFormProps) {
    const pageMessage = usePageMessage();
    const navigate = useNavigate();
    const { id: clientId } = useGetCurrentAccount();
    const customerIdQueryParam = new URLSearchParams(useLocation().search).get('customerId');

    const { setBulkCommitments, customers, products } = useContext(BulkCommitmentsContext);

    const formMethods = useForm<BulkCommitmentFormValues>({
        defaultValues: bulkCommitmentToFormValues(commitmentToEdit || {
            customerId: customerIdQueryParam ? customerIdQueryParam : undefined,
            pricingTiers: [ {} ]
        }),
        allowUndefinedDefaultValues: true
    });

    const pricingTiersArray = useFieldArray<BulkCommitmentFormValues>({
        name: 'pricingTiers',
        control: formMethods.control
    });

    function appendPricingTier() {
        pricingTiersArray.append({
            productId: '',
            noteRate: '',
            price: '',
            subLimit: ''
        } as unknown as Partial<BulkCommitmentPricingTier>);
    }

    const handleSubmit = formMethods.handleSubmit(async (formValues) => {
        setSaveLoading(true);

        try {
            const newBulkCommitment = commitmentToEdit
                ? await api.bulkCommitment.updateCommitment(clientId, formValuesToBulkCommitment(formValues))
                : await api.bulkCommitment.createCommitment(clientId, formValuesToBulkCommitment(formValues));

            setBulkCommitments(commitments => (
                commitmentToEdit
                    ? replaceItemById(commitments, newBulkCommitment)
                    : [ ...commitments, newBulkCommitment ]
            ));

            navigate('..');

            pageMessage.success(`Bulk commitment ${commitmentToEdit ? 'updated' : 'saved'}`);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving the bulk commitment', error);
        }

        setSaveLoading(false);
    });

    return (
        <form
            noValidate
            id={formID}
            onSubmit={handleSubmit}
            className={styles.form}
        >
            <FormProvider {...formMethods}>
                <TextField<BulkCommitment>
                    name="customerId"
                    label="Customer"
                    select
                    required
                >
                    {customers?.map(({ id, name }) => (
                        <MenuItem
                            key={id}
                            value={id}
                        >
                            {name}
                        </MenuItem>
                    ))}
                </TextField>

                <Typography className={clsx(styles.fieldsHeader, utilStyles.fullWidth)}>
                    Trade details
                </Typography>

                <CurrencyField<BulkCommitment>
                    name="tradeAmount"
                    label="Amount"
                    required
                />

                <TextField<BulkCommitment>
                    name="tradeIncentive"
                    label="Incentive"
                    type="number"
                    required
                    maskProps={{
                        decimalScale: 5,
                        fixedDecimalScale: true,
                        decimalSeparator: '.',
                        displayType: 'input'
                    }}
                />

                <PercentField<BulkCommitment>
                    name="tradeVariance"
                    label="Variance"
                    required
                    decimalScale={2}
                    maskProps={{
                        fixedDecimalScale: true
                    }}
                />

                <Typography className={clsx(styles.fieldsHeader, utilStyles.fullWidth)}>
                    Dates
                </Typography>

                <DateField<BulkCommitment>
                    name="lockDate"
                    label="Trade lock date"
                    required
                />

                <TextField<BulkCommitment>
                    name="lockWindow"
                    label="Lock window (days)"
                    type="number"
                    required
                />

                <DateField<BulkCommitment>
                    name="deliveryExpiration"
                    label="Delivery expiration"
                    required
                />

                <Typography className={clsx(styles.fieldsHeader, utilStyles.fullWidth)}>
                    Pricing Tiers

                    <Button onClick={() => appendPricingTier()}>
                        Add tier
                    </Button>
                </Typography>

                <div className={clsx(styles.tierFieldRow, utilStyles.fullWidth)}>
                    {pricingTiersArray.fields.map((pricingTier, index) => (
                        <Fragment key={pricingTier.id}>
                            <TextField<BulkCommitment>
                                name={`pricingTiers.${index}.productId`}
                                label="Product"
                                select
                                size="small"
                                required
                            >
                                <MenuItem value="ALL">
                                    --All--
                                </MenuItem>

                                {products.map(({ id, productDescription }) => (
                                    <MenuItem
                                        key={id}
                                        value={id}
                                    >
                                        {productDescription}
                                    </MenuItem>
                                ))}
                            </TextField>

                            <PercentField<BulkCommitment>
                                name={`pricingTiers.${index}.noteRate`}
                                label="Note rate"
                                size="small"
                                required
                                maskProps={{
                                    decimalScale: 3,
                                    fixedDecimalScale: true
                                }}
                            />

                            <TextField<BulkCommitment>
                                name={`pricingTiers.${index}.price`}
                                label="Price"
                                type="number"
                                size="small"
                                required
                                maskProps={{
                                    decimalScale: 5,
                                    fixedDecimalScale: true
                                }}
                            />

                            <CurrencyField<BulkCommitment>
                                name={`pricingTiers.${index}.subLimit`}
                                label="Sub-limit"
                                size="small"
                                type="number"
                            />

                            <IconButton
                                title="Remove tier"
                                className={styles.removeButton}
                                onClick={() => {
                                    pricingTiersArray.remove(index);

                                    // If we removed the last tier, add a new one
                                    if (pricingTiersArray.fields.length === 1) {
                                        appendPricingTier();
                                    }
                                }}
                            >
                                <RemoveCircleOutline color="error" />
                            </IconButton>
                        </Fragment>
                    ))}
                </div>
            </FormProvider>
        </form>
    );
}

function bulkCommitmentToFormValues(bulkCommitment: DeepPartial<BulkCommitment>) {
    return {
        ...bulkCommitment,
        pricingTiers: bulkCommitment.pricingTiers?.map(pricingTier => ({
            ...pricingTier,
            productId: !pricingTier?.productId ? 'ALL' : pricingTier.productId
        }))
    };
}

function formValuesToBulkCommitment(formValues: BulkCommitmentFormValues) {
    return {
        ...formValues,
        pricingTiers: formValues.pricingTiers.map(pricingTier => ({
            ...pricingTier,
            productId: pricingTier.productId === 'ALL' ? undefined : pricingTier.productId
        }))
    } as BulkCommitment;
}
