import api, { PermissionType, llpaMatrixLoanPropertyDisplay } from '@api';
import { Edit } from '@mui/icons-material';
import { Paper, Typography } from '@mui/material';
import {
    HorizontalLabelGroup, IconButton, LabeledValue, RoutedDialogManager, useAsyncEffect, usePageMessage, useParams
} from '@tsp-ui/core';
import { tooltipTitle, withAuth } from '@utils';
import { useActingClientID, useHasPermission } from '@utils/hooks';
import EditableSectionCard from '@views/admin/components/EditableSectionCard';
import {
    ViewHighLevelGuidelineCard
} from '@views/admin/investors/InvestorDetailPage/LoanProgramDetailPage/components/HighLevelGuidelineCard';
import HighLevelGuidelines
    from '@views/admin/investors/InvestorDetailPage/LoanProgramDetailPage/components/HighLevelGuidelines';
import { LLPARoutesContext } from '@views/admin/llpas/LLPARoutes';
import EditLLPADetailsDialog from '@views/admin/llpas/components/EditLLPADetailsDialog';
import LLPADialog, {
    MatrixStepFormValues,
    formatLlpaHighLevelGuidelineValue,
    matrixDetailsToFormValues
} from '@views/admin/llpas/components/LLPADialog';
import { LLPAMatrix } from '@views/admin/llpas/components/LLPAMatrix/LLPAMatrix';
import {
    LLPAVersionButton,
    isCurrentLLPAVersion,
    useSelectedLLPAVersion
} from '@views/admin/llpas/components/LLPAVersionButton';
import { renderBoolean } from '@views/admin/products/components/ProductDetailsCard';
import Page from '@views/components/Page';
import { isFuture, parseISO } from 'date-fns';
import { useCallback, useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';

import styles from './LLPADetailPage.module.scss';


export interface LLPADetailPageParams {
    llpaId: string;
}

export default function LLPADetailPage() {
    const pageMessage = usePageMessage();
    const clientId = useActingClientID();
    const { search } = useLocation();

    const { llpaId } = useParams<LLPADetailPageParams>();
    const { llpas, investors } = useContext(LLPARoutesContext);

    const llpa = llpas?.find(({ id }) => id === llpaId);
    const selectedVersion = useSelectedLLPAVersion(llpa?.versions);

    const formMethods = useForm<MatrixStepFormValues>();
    const { reset, watch } = formMethods;

    const selectedVersionId = selectedVersion?.id;
    const [ loading, setLoading ] = useState(true);

    useAsyncEffect(useCallback(async () => {
        try {
            if (selectedVersionId) {
                setLoading(true);

                reset(matrixDetailsToFormValues(
                    await api.llpa.getLLPAVersionDetails(clientId, llpaId, selectedVersionId)
                ));

                setLoading(false);
            }
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching llpa matrix details', error);
        }
    }, [
        pageMessage, clientId, llpaId, selectedVersionId, reset
    ]));

    const columnMetadata = watch('columnMetadata') || [];
    const disableEditVersion = !selectedVersion || !(
        !isCurrentLLPAVersion(selectedVersion) && isFuture(parseISO(selectedVersion.effectiveDate))
    );

    const [ canManage ] = useHasPermission([ PermissionType.MANAGE_LLPAS ]);

    return (
        <Page
            header={llpa?.isCap ? 'LLPA Cap Details' : 'LLPA Details'}
            loading={!llpa || loading}
            headerActions={(
                <LLPAVersionButton llpaVersions={llpa?.versions} />
            )}
        >
            {llpa && selectedVersion && (
                <div className={styles.root}>
                    <EditableSectionCard
                        header={llpa.name}
                        editTo={`edit${search}`}
                        disableEdit={!canManage}
                        disabledTooltip="You don't have permission to manage LLPAs"
                    >
                        <Typography
                            variant="body2"
                            color="textSecondary"
                        >
                            {llpa.description}
                        </Typography>
                    </EditableSectionCard>

                    <Paper
                        variant="outlined"
                        className={styles.matrixPaper}
                    >
                        <Typography
                            variant="h6"
                            className={styles.versionDetailsHeader}
                        >
                            Version details

                            {!llpa.isCap && (
                                <div className={styles.bools}>
                                    <HorizontalLabelGroup>
                                        <LabeledValue
                                            label="Counts toward cap:"
                                            value={renderBoolean(selectedVersion.countsTowardCap)}
                                            variants={variants}
                                        />
                                    </HorizontalLabelGroup>

                                    <HorizontalLabelGroup>
                                        <LabeledValue
                                            label="Is Gain On Sale:"
                                            value={renderBoolean(selectedVersion.isGainOnSale)}
                                            variants={variants}
                                        />
                                    </HorizontalLabelGroup>

                                    <HorizontalLabelGroup>
                                        <LabeledValue
                                            label="Is SRP:"
                                            value={renderBoolean(selectedVersion.isSRP)}
                                            variants={variants}
                                        />
                                    </HorizontalLabelGroup>
                                </div>
                            )}

                            <IconButton
                                component={Link}
                                to={`versions/${selectedVersion.id}${search}`}
                                disabled={disableEditVersion || !canManage}
                                edge="end"
                                tooltip={tooltipTitle({
                                    'You don\'t have permission to manage LLPAs': !canManage,
                                    'Only future versions of this LLPA can be edited': disableEditVersion,
                                    'Edit version details': true
                                })}
                            >
                                <Edit color="secondary" />
                            </IconButton>
                        </Typography>

                        <FormProvider {...formMethods}>
                            <HighLevelGuidelines
                                readOnly
                                noContent={!columnMetadata.some(({ isHighLevel }) => isHighLevel)}
                                cards={columnMetadata.filter(({ isHighLevel }) => isHighLevel).map((column) => (
                                    <ViewHighLevelGuidelineCard
                                        key={column.loanProperty}
                                        columnMetadata={column}
                                        title={llpaMatrixLoanPropertyDisplay[column.loanProperty]}
                                        formatValue={(meta) => formatLlpaHighLevelGuidelineValue(meta, investors)}
                                    />
                                ))}
                            />

                            <LLPAMatrix readOnly />

                            <RoutedDialogManager routes={dialogRoutes} />
                        </FormProvider>
                    </Paper>
                </div>
            )}
        </Page>
    );
}

const dialogRoutes = {
    edit: withAuth(EditLLPADetailsDialog, [ PermissionType.MANAGE_LLPAS ], true),
    'versions/add': withAuth(LLPADialog, [ PermissionType.MANAGE_LLPAS ], true),
    'versions/:versionId': withAuth(LLPADialog, [ PermissionType.MANAGE_LLPAS ], true)
};

const variants = {
    label: 'body2',
    value: 'body1'
} as const;
