import { EligibilityGuideline } from '@api';
import { Paper, Typography } from '@mui/material';
import { EligibilityExclusionRow } from '@views/admin/investors/InvestorDetailPage/components/EligibilityExclusionRow';
import {
    EligibilityExclusionGroupFormValues,
    exclusionsToFormValues, getNextExclusionFormID
} from '@views/admin/investors/InvestorDetailPage/components/ExclusionGroupRow';


import styles from './ExclusionsView.module.scss';


interface ExclusionsViewProps {
    exclusions: EligibilityGuideline[] | undefined;
}

export default function ExclusionsView({ exclusions }: ExclusionsViewProps) {
    const exclusionFormValues = exclusions && exclusionsToFormValues(exclusions);

    const groups: EligibilityExclusionGroupFormValues[] = exclusionFormValues?.groups || [];

    if (exclusionFormValues?.exclusions?.length) {
        if (exclusionFormValues?.operator === 'or') {
            groups.push(...exclusionFormValues.exclusions.map((exclusion) => ({
                id: exclusion.id,
                exclusions: [ exclusion ]
            })));
        } else {
            groups.push({
                id: getNextExclusionFormID(),
                exclusions: exclusionFormValues.exclusions
            });
        }
    }

    return (
        <>
            <Typography align="center">
                Loans will be disqualified if:
            </Typography>

            <div className={styles.exclusionCardContainer}>
                {groups.map((exclusionGroup, index) => (
                    <div
                        className={styles.logicContainer}
                        key={exclusionGroup.id}
                    >
                        {index > 0 && (
                            <Typography>
                                <u>{exclusionFormValues?.operator.toUpperCase()}</u>
                            </Typography>
                        )}

                        <Paper
                            variant="outlined"
                            className={styles.exclusionCard}
                        >
                            <EligibilityExclusionRow
                                hideIcon
                                exclusionGroup={exclusionGroup}
                            />
                        </Paper>
                    </div>
                ))}
            </div>
        </>
    );
}
