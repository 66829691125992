import {
    AmortizationType,
    OccupancyType,
    PaginatedGetParams,
    ProductEventType,
    ProductType,
    SpecialtyProgram,
    User
} from '@api';
import { PaginatedResponse } from '@tsp-ui/core';

import { apiUtils } from '../api-utils';


export function getAllProducts(clientId: string): Promise<Product[]> {
    return apiUtils.get(`/client/${clientId}/product`);
}

export function updateProduct(clientId: string, product: Product): Promise<Product> {
    return apiUtils.put(`/client/${clientId}/product/${product.id}`, product);
}

export function createProduct(clientId: string, product: Partial<Product>): Promise<Product> {
    return apiUtils.post(`/client/${clientId}/product`, product);
}

export function getProductHistory(
    clientId: string, productID: string, params: ProductHistoryParams
): Promise<PaginatedResponse<ProductHistoryEvent>> {
    return apiUtils.get(`/client/${clientId}/product/${productID}/history`, params);
}

export interface ProductHistoryEvent {
    id: string;
    eventType: ProductEventType;
    date: string;
    executedBy: User;
    before: Product;
    after: Product;
}

export interface ProductHistoryParams extends PaginatedGetParams {
    date?: string | null;
    eventType?: ProductEventType;
}

export interface Product {
    id: string;
    productCode: string;
    productDescription: string;
    isActive: boolean;
    productType: ProductType;
    amortizationType: AmortizationType;
    term: number;

    minTerm?: number;
    maxTerm?: number;
    minLoanAmount?: number;
    maxLoanAmount?: number;

    highBalance: boolean;
    jumbo: boolean;
    specialtyProgram?: SpecialtyProgram;
    nonAgency: boolean;
    servicingReleased: boolean;
    streamline: boolean;

    armInitialPeriod?: number;
    armSubPeriod?: number;
    armInitialRateCap?: number;
    armSubRateCap?: number;
    armLifeRateCap?: number;
    armLookBackDays?: number;

    investorIDs: string[];
    occupancyTypes?: OccupancyType[];
}
