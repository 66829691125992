import { ActiveInactiveEnum, DueDiligenceStepType } from '@api';
import { rest } from 'msw';


import { getMockUrl } from '../../mocks/getMockUrl';

import { DueDiligenceConfig } from './due-diligence-api';


let dueDiligenceItemId = 100;

export const mocks = [
    rest.get(getMockUrl('/client/:clientID/due-diligence-config'), (req, res, ctx) => {
        dueDiligenceItems.sort((a, b) => (
            a.displayOrder === null && b.displayOrder === null
                ? a.name.localeCompare(b.name)
                : a.displayOrder === null && b.displayOrder !== null
                    ? -1 : a.displayOrder !== null && b.displayOrder === null
                        ? 1 : a.displayOrder! - b.displayOrder!
        ));

        return res(
            ctx.status(200),
            ctx.json(dueDiligenceItems)
        );
    }),
    rest.post(getMockUrl('/client/:clientID/due-diligence-config'), (req, res, ctx) => {
        const newConfig = {
            ...req.body as DueDiligenceConfig,
            id: `${dueDiligenceItemId++}`,
            displayOrder: Math.max(...dueDiligenceItems.map(({ displayOrder }) => displayOrder || 0))
        };

        dueDiligenceItems.push(newConfig);

        return res(
            ctx.status(200),
            ctx.json(newConfig)
        );
    }),
    rest.put(getMockUrl('/client/:clientID/due-diligence-config/:configId'), (req, res, ctx) => {
        const updatedConfig = req.body as DueDiligenceConfig;

        const indexToUpdate = dueDiligenceItems.findIndex(({ id }) => id === updatedConfig.id);

        dueDiligenceItems.splice(indexToUpdate, 1, updatedConfig);

        return res(
            ctx.status(200),
            ctx.json(updatedConfig),
            ctx.delay(2000)
        );
    }),
    rest.post(getMockUrl('/client/:clientID/due-diligence-config/:configId/move'), (req, res, ctx) => {
        const { configId } = req.params;
        const { destinationIndex } = req.body as { destinationIndex: number };

        const sourceItemIndex = dueDiligenceItems.findIndex(({ id }) => id === configId);
        const destinationItemIndex = dueDiligenceItems.findIndex(({ displayOrder }) => (
            displayOrder === destinationIndex
        ));

        const sourceItem = dueDiligenceItems[sourceItemIndex];

        dueDiligenceItems.splice(sourceItemIndex, 1, {
            ...sourceItem,
            displayOrder: destinationIndex
        });

        dueDiligenceItems.splice(destinationItemIndex, 1, {
            ...dueDiligenceItems[destinationItemIndex],
            displayOrder: sourceItem.displayOrder
        });

        return res(
            ctx.status(204)
        );
    })
];

export const dueDiligenceItems: DueDiligenceConfig[] = [
    {
        id: `${dueDiligenceItemId++}`,
        name: 'test',
        description: 'test',
        status: ActiveInactiveEnum.ACTIVE,
        isDocRequired: false,
        displayOrder: 0,
        isRequired: true,
        type: DueDiligenceStepType.CUSTOM
    },
    {
        id: `${dueDiligenceItemId++}`,
        name: 'test2',
        description: 'test2',
        status: ActiveInactiveEnum.ACTIVE,
        isDocRequired: false,
        displayOrder: 1,
        isRequired: true,
        type: DueDiligenceStepType.CUSTOM
    },
    {
        id: `${dueDiligenceItemId++}`,
        name: 'test3',
        description: 'test3',
        status: ActiveInactiveEnum.ACTIVE,
        isDocRequired: false,
        displayOrder: 2,
        isRequired: true,
        type: DueDiligenceStepType.CUSTOM
    }
];
