import { MICompanyRelationship } from '@api';
import { PhoneField, TextField, isEmail } from '@tsp-ui/core/components';
import { FormProvider, useForm } from 'react-hook-form';

import styles from './MIRelationshipForm.module.scss';


interface MIRelationshipFormProps {
    defaultValues?: MICompanyRelationship;
    handleSubmit(miRelationship: MICompanyRelationship): void;
}

export function MIRelationshipForm({ defaultValues, handleSubmit: handleSubmitProp }: MIRelationshipFormProps) {
    const formMethods = useForm<MICompanyRelationship>({ defaultValues });
    const handleSubmit = formMethods.handleSubmit(handleSubmitProp);

    return (
        <form
            id={`mi-relationship-${defaultValues?.id || 'new'}`}
            onSubmit={handleSubmit}
            className={styles.formContent}
            noValidate
        >
            <FormProvider {...formMethods}>
                <TextField<MICompanyRelationship>
                    name="insurerName"
                    label="Insurer name"
                    size="small"
                    variant="outlined"
                    required
                />

                <TextField<MICompanyRelationship>
                    name="policyNumber"
                    label="Policy number"
                    size="small"
                    variant="outlined"
                    required
                />

                <TextField<MICompanyRelationship>
                    name="contact"
                    label="Contact name"
                    size="small"
                    variant="outlined"
                    required
                />

                <PhoneField<MICompanyRelationship>
                    name="phone"
                    label="Phone #"
                    size="small"
                    variant="outlined"
                    required
                />

                <TextField<MICompanyRelationship>
                    name="emailAddress"
                    label="Email address"
                    size="small"
                    variant="outlined"
                    required
                    rules={isEmail}
                />
            </FormProvider>
        </form>
    );
}
