import { PreSignedDocumentUrl } from '@api';

import { apiUtils } from '../api-utils';


export async function getDocument(clientID: string, documentID: string): Promise<Document> {
    return await apiUtils.get(`/client/${clientID}/document/${documentID}`);
}

export interface Document extends PreSignedDocumentUrl {
    id: string;
    url: string;
}
