import { ReferenceGuideFile } from '@api';
import { Paper, Typography } from '@mui/material';
import { DateTypography } from '@tsp-ui/core/components';
import { DocumentLink } from '@views/components/DocumentLink';

import styles from './CustomerReferenceGuideCard.module.scss';


interface CustomerReferenceGuideCardProps {
    referenceGuideFile: ReferenceGuideFile;
}

export function CustomerReferenceGuideCard({
    referenceGuideFile: {
        document, name, description, createdAt
    }
}: CustomerReferenceGuideCardProps) {
    return (
        <Paper
            variant="outlined"
            className={styles.card}
        >
            <div>
                <DocumentLink
                    document={document}
                    name={name}
                />

                <Typography variant="caption">
                    {description}
                </Typography>
            </div>

            <div>
                <DateTypography
                    variant="caption"
                    date={createdAt}
                />
            </div>
        </Paper>
    );
}
