import { rest } from 'msw';

import {
    CustomerCompensationPercentageOf, CustomerCompensationType, FeeAmountPercentageOf, FeeAmountType, FeeType
} from '../..';
import { getMockUrl } from '../../../mocks/getMockUrl';

import { CustomerCompensation, CustomerFeesComp } from './compensation-api';


export const mocks = [
    rest.get(getMockUrl('/customer/:customerID/compensation'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(customerFeesComp)
    ))),
    rest.put(getMockUrl('/customer/:customerID/compensation'), (req, res, ctx) => {
        customerFeesComp = req.body as CustomerFeesComp;
        return res(
            ctx.status(200),
            ctx.json(req.body)
        );
    })
];

const compensationDetails: CustomerCompensation = {
    id: '1',
    isActive: true,
    firstLienOnly: true,
    type: CustomerCompensationType.PERCENTAGE,
    percentage: .25,
    percentageOf: CustomerCompensationPercentageOf.BASE_LOAN_AMOUNT,
    minAmount: 12000,
    maxAmount: 64000,
    notes: 'Lorem ipsum\n• Dolor sit amet, consectetur adipiscing elit\n• Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua\n• Ut enim ad minim veniam, quis nostrud exercitation ullamco'
};

export let customerFeesComp: CustomerFeesComp = {
    compensation: compensationDetails,
    fees: [
        {
            id: '1',
            customerId: '1',
            purchaseAdviceFeeId: '1',
            type: FeeType.ADMINISTRATION_FEE,
            amountType: FeeAmountType.FIXED,
            amount: 200,
            isActive: false
        }, {
            id: '2',
            customerId: '1',
            purchaseAdviceFeeId: '2',
            type: FeeType.COMPLIANCE_FEE,
            amountType: FeeAmountType.FIXED,
            amount: 300,
            isActive: true
        }, {
            id: '3',
            customerId: '1',
            purchaseAdviceFeeId: undefined,
            type: FeeType.DOCUMENT_FEE,
            amountType: FeeAmountType.VARIABLE,
            minAmount: 725,
            maxAmount: 1500,
            percentage: 0.03389,
            percentageOf: FeeAmountPercentageOf.BASE_LOAN_AMOUNT,
            isActive: true
        }, {
            id: '4',
            customerId: '1',
            purchaseAdviceFeeId: undefined,
            type: FeeType.ADMINISTRATION_FEE,
            amountType: FeeAmountType.FIXED,
            amount: 450,
            isActive: true
        }
    ]
};
