import api, { CustomerAlertConfig, PermissionType, alertTypeConfigDisplay } from '@api';
import { Edit, RemoveCircleOutline } from '@mui/icons-material';
import { Paper, Typography } from '@mui/material';
import {
    IconButton, ProgressIndicator, removeItemById, useConfirm, usePageMessage
} from '@tsp-ui/core';
import { useGetCurrentAccount, useHasPermission } from '@utils';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import styles from './CustomerAlertConfigCard.module.scss';
import { CustomerAlertConfigContext } from './CustomerAlertConfigPage';


interface CustomerAlertConfigCardProps {
    alertConfig: CustomerAlertConfig;
}

export default function CustomerAlertConfigCard({ alertConfig }: CustomerAlertConfigCardProps) {
    const [ isDeleting, setIsDeleting ] = useState(false);

    const { customerAlertConfigs, setCustomerAlertConfigs } = useContext(CustomerAlertConfigContext);

    const confirm = useConfirm();
    const pageMessage = usePageMessage();
    const { id: clientId } = useGetCurrentAccount();

    const [ canManageCustomerAlertConfigs ] = useHasPermission([ PermissionType.MANAGE_CUSTOMER_ALERT_CONFIGS ]);

    const alertConfigID = alertConfig.id;

    return (
        <Paper
            className={styles.card}
            elevation={0}
        >
            <div className={styles.props}>
                <Typography variant="body2">
                    {alertTypeConfigDisplay[alertConfig.type]}
                </Typography>

                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                    {`Alert persists for ${alertConfig.duration} ${alertConfig.duration >= 2 ? 'days' : 'day'}`}
                </Typography>
            </div>

            <div className={styles.buttons}>
                <IconButton
                    tooltip={canManageCustomerAlertConfigs ? 'Edit alert configuration' : 'You do not have permission to edit alert configurations'}
                    disabled={!canManageCustomerAlertConfigs}
                    component={Link}
                    to={`${alertConfigID}`}
                >
                    <Edit color="secondary" />
                </IconButton>

                {isDeleting ? <ProgressIndicator className={styles.progress} /> : (
                    <IconButton
                        tooltip={canManageCustomerAlertConfigs ? 'Delete alert configurations' : 'You do not have permission to delete alert configurations'}
                        disabled={!canManageCustomerAlertConfigs}
                        loading={isDeleting}
                        edge="end"
                        onClick={async () => {
                            setIsDeleting(true);

                            if (await confirm('Are you sure you want to delete this alert configuration?')) {
                                try {
                                    await api.client.customerAlertConfigs.deleteCustomerAlertConfig(
                                        clientId, alertConfig.id
                                    );

                                    setCustomerAlertConfigs(removeItemById(
                                        customerAlertConfigs, alertConfig.id
                                    ));

                                    pageMessage.success('Alert configuration deleted');
                                } catch (error) {
                                    pageMessage.handleApiError('An error occurred while deleting the alert configuration', error);
                                }
                            }

                            setIsDeleting(false);
                        }}
                    >
                        <RemoveCircleOutline color="error" />
                    </IconButton>
                )}
            </div>
        </Paper>
    );
}
