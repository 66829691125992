import { apiUtils } from '../api-utils';
import { UserType } from '../enums/userTypeEnum';


export function getUsers(clientID: string, customerID?: string): Promise<User[]> {
    return apiUtils.get(getUserURL(clientID, customerID));
}

export function deleteUser(userID: string, clientID: string, customerID?: string): Promise<void> {
    return apiUtils.delete(getUserURL(clientID, customerID, userID));
}

export function addUser(user: User, clientID: string, customerID?: string): Promise<User> {
    return apiUtils.post(getUserURL(clientID, customerID), user);
}

export function editUser(user: User, clientID: string, customerID?: string): Promise<User> {
    return apiUtils.put(getUserURL(clientID, customerID, user.id), user);
}

export function getUserURL(clientID: string, customerID?: string, userID?: string) {
    const clientUrl = clientID !== 'internal' ? `/client/${clientID}` : '';
    const customerUrl = customerID ? `/customer/${customerID}` : '';
    const userUrl = `/user${userID ? `/${userID}` : ''}`;

    return clientUrl + customerUrl + userUrl;
}

export interface User {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    phone: string | null;
    type: UserType;
    isActive: boolean;
    roleIds: string[];
}
