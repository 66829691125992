import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch as baseUseDispatch, useSelector as baseUseSelector } from 'react-redux';

import { AuthenticationState } from '../redux/auth/auth.reducer';

import auth from './auth';


export const reducer = {
    auth
};

export const store = configureStore({
    reducer,
    devTools: process.env.NODE_ENV !== 'production' && {
        name: 'Premicorr'
    }
});

export type AppState = { auth: AuthenticationState };
export type AppDispatch = typeof store.dispatch;

export const useSelector: TypedUseSelectorHook<AppState> = baseUseSelector;
export const useDispatch: () => AppDispatch = baseUseDispatch;
