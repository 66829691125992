import api, {
    ClientInvestor, ExclusionOverlay, ManagedEligibilityStatus, PermissionType
} from '@api';
import { RoutedDialogManager, usePageMessage } from '@tsp-ui/core/components';
import {
    getItemById, replaceItemById, useAsyncEffect, useParams
} from '@tsp-ui/core/utils';
import { useActingClientID } from '@utils/hooks';
import { withAuth } from '@utils/withAuth';
import { InvestorDetailPageBase } from '@views/admin/investors/InvestorDetailPage/InvestorDetailPageBase';
import { LoanProgramSection } from '@views/admin/investors/InvestorDetailPage/components/LoanProgramSection';
import { useEligibilityVersionIdQueryParam } from '@views/admin/investors/components/EligibilityVersionButton';
import { useCallback, useContext, useState } from 'react';

import { LoanProgramsContext } from '../investors/InternalInvestorDetailRoutes';

import { ExclusionOverlayContext } from './InvestorEligibilityManagementPage';
import { InvestorEligibilityContext } from './InvestorEligibilityRoutes';
import { EditClientInvestorDialog } from './components/EditClientInvestorDialog';
import ExclusionOverlaySection from './components/ExclusionOverlaySection';
import { overlayDialogRoutes } from './overlayDialogRoutes';


export default function ClientInvestorDetailPage() {
    const clientID = useActingClientID();
    const pageMessage = usePageMessage();
    const { investorID } = useParams<{ investorID: string }>();
    const eligibilityVersionId = useEligibilityVersionIdQueryParam();

    const [ overlaysLoading, setOverlaysLoading ] = useState(true);
    const { loanPrograms } = useContext(LoanProgramsContext);

    const [ overlays, setOverlays ] = useState<ExclusionOverlay[]>([]);
    useAsyncEffect(useCallback(async () => {
        try {
            setOverlays(await api.overlay.getExclusionOverlays(clientID, {
                investorId: investorID,
                effectiveVersionId: eligibilityVersionId
            }));
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching exclusion overlays.', error);
        }

        setOverlaysLoading(false);
    }, [
        clientID, eligibilityVersionId, investorID, pageMessage
    ]));

    const {
        investors, setInvestors, eligibilityVersions, isSelectedVersionEditable
    } = useContext(InvestorEligibilityContext);
    const [ investor, setInvestor ] = [
        getItemById(investors || [], investorID),
        (updatedInvestor: ClientInvestor) => setInvestors(replaceItemById(investors || [], updatedInvestor))
    ];

    return (
        <ExclusionOverlayContext.Provider
            value={{
                overlays,
                setOverlays
            }}
        >
            <InvestorDetailPageBase
                firstBreadcrumb="Investor Eligibility"
                editPermission={PermissionType.MANAGE_CLIENT_INVESTORS}
                isSelectedVersionEditable={isSelectedVersionEditable}
                loading={overlaysLoading || !loanPrograms || !investors}
                investor={investor}
                setInvestor={setInvestor}
                eligibilityVersions={eligibilityVersions}
                onUpdateStatus={(!investor || !eligibilityVersionId) ? undefined : (status) => (
                    api.client.investors.updateStatus(clientID, investor.id, {
                        status,
                        eligibilityVersionId: eligibilityVersionId!
                    })
                )}
            >
                <RoutedDialogManager routes={dialogRoutes} />

                <LoanProgramSection
                    loanPrograms={loanPrograms}
                    isSelectedVersionEditable={isSelectedVersionEditable}
                    editPermission={PermissionType.MANAGE_CLIENT_LOAN_PROGRAMS}
                    isInvestorManged={investor?.managedEligibilityStatus === ManagedEligibilityStatus.MANAGED}
                />

                <ExclusionOverlaySection
                    overlays={overlays}
                    disableAdd={!isSelectedVersionEditable}
                />
            </InvestorDetailPageBase>
        </ExclusionOverlayContext.Provider>
    );
}

const dialogRoutes = {
    ...overlayDialogRoutes,
    edit: withAuth(EditClientInvestorDialog, [ PermissionType.MANAGE_CLIENT_INVESTORS ], true)
};
