import api, {
    BooleanEnum,
    ClientInvestor,
    CreateLLPAReqBody,
    LLPA,
    LLPACell,
    LLPAMatrixLoanProperty,
    LLPAOnlyLoanProperty,
    LLPAVersionDetails,
    LoanProperty,
    MatrixColumnMetadata,
    llpaMatrixLoanPropertyDisplay,
    llpaMatrixLoanPropertyEnumFieldTypes
} from '@api';
import {
    Autocomplete,
    Button,
    DialogContent,
    TextField as MuiTextField,
    Step,
    StepLabel,
    Stepper
} from '@mui/material';
import {
    RoutedDialog, replaceItemById, useConfirm, useForm, useParams
} from '@tsp-ui/core';
import {
    DateField, DialogActions, RoutedDialogImplProps, Switch, TextField
} from '@tsp-ui/core/components';
import { usePageMessage } from '@tsp-ui/core/utils';
import { useLlpaLoanPropertyEnumDisplays } from '@utils';
import { useActingClientID } from '@utils/hooks';
import { numericEntryToString, parseNumericMatrixEntry } from '@utils/numeric-range-utils';
import {
    HighLevelEnumDisplay,
    formatHighLevelGuidelineValue
} from '@views/admin/investors/InvestorDetailPage/LoanProgramDetailPage/components/HighLevelGuidelineCard';
import {
    EditHighLevelGuidelines,
    MatrixColumnMetadataFormValues
} from '@views/admin/investors/InvestorDetailPage/LoanProgramDetailPage/components/HighLevelGuidelines';
import {
    formatLoanProgramFieldFormValue,
    parseLoanProgramFieldFormValue
} from '@views/admin/investors/InvestorDetailPage/LoanProgramDetailPage/components/LoanProgramForm';
import { LLPADetailPageParams } from '@views/admin/llpas/LLPADetailPage';
import { LLPARoutesContext } from '@views/admin/llpas/LLPARoutes';
import { useInvestorOptions } from '@views/admin/llpas/components/LLPAMatrix/components/LoanPropertyValueCell';
import { useSelectedLLPAVersion } from '@views/admin/llpas/components/LLPAVersionButton';
import {
    formatISO, isBefore, isToday, parse, startOfTomorrow
} from 'date-fns';
import {
    Dispatch, SetStateAction, createContext, useContext, useState
} from 'react';
import {
    FieldPath, FormProvider, useFormContext, useWatch
} from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import styles from './LLPADialog.module.scss';
import { LLPAMatrix } from './LLPAMatrix/LLPAMatrix';


const {
    LOAN_TYPE, OCCUPANCY, PURPOSE, AMORT_TYPE, PROPERTY_TYPE, UNITS, FICO,
    LTV, CLTV, DTI, TERM, AUS, RESERVES_MONTHS, HIGH_BALANCE, SPECIALTY_PROGRAM,
    STATE, LOAN_AMOUNT, IRRL, STREAMLINE, FIRST_TIME_HOMEBUYER, NON_QM, LOAN_LIMIT_TYPE,
    ESCROWS, SUBORDINATE_FINANCING
} = LoanProperty;


interface LLPAColumnMetadataFormValues extends Omit<MatrixColumnMetadata<LLPAMatrixLoanProperty>, 'value' | 'loanProperty'> {
    value: string | string[];
    loanProperty: LLPAMatrixLoanProperty;
}

export interface LLPAMatrixFormCell extends Omit<LLPACell, keyof typeof LoanProperty>,
    Partial<{
        [LOAN_TYPE]: string[];
        [OCCUPANCY]: string[];
        [PURPOSE]: string[];
        [AMORT_TYPE]: string[];
        [PROPERTY_TYPE]: string[];
        [LOAN_LIMIT_TYPE]: string[];
        [AUS]: string[];
        [SPECIALTY_PROGRAM]: string[];
        [STATE]: string[];
        [UNITS]: string;
        [FICO]: string;
        [LTV]: string;
        [CLTV]: string;
        [DTI]: string;
        [TERM]: string;
        [RESERVES_MONTHS]: string;
        [LOAN_AMOUNT]: string;
        [LLPAOnlyLoanProperty.INVESTOR]: string[]; // investorId
        [HIGH_BALANCE]: string[];
        [ESCROWS]: string[];
        [SUBORDINATE_FINANCING]: string[];
        [IRRL]: string[];
        [STREAMLINE]: string[];
        [FIRST_TIME_HOMEBUYER]: string[];
        [NON_QM]: string[];
        [IRRL]: string[];
        [STREAMLINE]: string[];
        [FIRST_TIME_HOMEBUYER]: string[];
        [NON_QM]: string[];
    }>
{}

export interface MatrixStepFormValues {
    columnMetadata: LLPAColumnMetadataFormValues[];
    llpaCells: LLPAMatrixFormCell[];
}

export interface DetailsStepFormValues extends Omit<CreateLLPAReqBody, 'version'> {
    version: Omit<CreateLLPAReqBody['version'], 'columnMetadata' | 'llpaCells'>;
}

interface LLPADialogParams extends LLPADetailPageParams {
    versionId: string | undefined;
}

export default function LLPADialog(props: RoutedDialogImplProps) {
    const pageMessage = usePageMessage();
    const navigate = useNavigate();
    const clientId = useActingClientID();
    const { search } = useLocation();

    const [ activeStep, setActiveStep ] = useState(0);
    const [ detailsFormValues, setDetailsFormValues ] = useState<DetailsStepFormValues>();
    const [ saveLoading, setSaveLoading ] = useState(false);

    const { llpaId, versionId } = useParams<LLPADialogParams>();
    const { llpas, setLlpas } = useContext(LLPARoutesContext);

    const llpa = llpas?.find(({ id }) => id === llpaId);
    const formValues = useWatch<MatrixStepFormValues>();
    const { reset } = useFormContext<MatrixStepFormValues>();

    const stepContent = [
        <DialogContent>
            <LLPADetailsStepForm
                isDialog
                llpa={llpa}
                isCap={llpa?.isCap}
                handleSubmit={(formValues) => {
                    setActiveStep(activeStep + 1);
                    setDetailsFormValues(formValues);
                }}
            />
        </DialogContent>,
        <DialogContent className={styles.matrixStep}>
            <MatrixStepForm
                defaultValues={formValues as MatrixStepFormValues}
                handleSubmit={async (formValues) => {
                    try {
                        setSaveLoading(true);

                        const versionToSubmit = {
                            ...detailsFormValues!.version,
                            ...matrixFormValuesToApi(formValues, llpa?.isCap)
                        };

                        if (versionId) {
                            const updatedVersion = await api.llpa.updateLLPAVersion(clientId, llpaId, {
                                id: versionId,
                                ...versionToSubmit
                            });

                            setLlpas(replaceItemById(llpas || [], {
                                ...llpa!,
                                versions: replaceItemById(llpa!.versions, updatedVersion)
                            }));

                            try {
                                reset(matrixDetailsToFormValues(
                                    await api.llpa.getLLPAVersionDetails(clientId, llpaId, updatedVersion.id)
                                ));
                            } catch (e) {} // Don't show an error if the refresh fails

                            navigate(`..${search}`);
                        } else {
                            const newVersion = await api.llpa.createLLPAVersion(clientId, llpaId, versionToSubmit);

                            setLlpas(replaceItemById(llpas || [], {
                                ...llpa!,
                                versions: llpa!.versions.concat(newVersion)
                            }));

                            navigate(`..?llpaVersionId=${newVersion.id}`);
                        }

                        pageMessage.success(`LLPA Version ${versionId ? 'updated' : 'added'}`);
                    } catch (error) {
                        pageMessage.handleApiError('An error occurred while saving the LLPA', error);
                    }

                    setSaveLoading(false);
                }}
            />
        </DialogContent>
    ];

    return (
        <RoutedDialog
            {...props}
            title={`${!versionId ? 'Add' : 'Edit'} LLPA ${llpa?.isCap ? 'Cap ' : ''}Version`}
            maxWidth={false}
            loading={!llpa || !formValues}
            keepLocationSearch
        >
            <DialogContent className={styles.stepperContent}>
                <Stepper
                    activeStep={activeStep}
                    className={styles.stepper}
                >
                    <Step>
                        <StepLabel>
                            Details
                        </StepLabel>
                    </Step>

                    <Step>
                        <StepLabel>
                            Matrix
                        </StepLabel>
                    </Step>
                </Stepper>
            </DialogContent>

            {stepContent[activeStep]}

            <DialogActions loading={saveLoading}>
                <Button
                    variant="contained"
                    type="submit"
                    form={llpaFormID}
                    disabled={saveLoading}
                >
                    {activeStep === stepContent.length - 1 ? 'Save' : 'Next'}
                </Button>
            </DialogActions>
        </RoutedDialog>
    );
}

export const llpaFormID = 'llpa-form';

interface LLPADetailsStepFormProps {
    llpa?: LLPA;
    handleSubmit: (formValues: DetailsStepFormValues) => void;
    isDialog?: boolean;
    isCap?: boolean;
}

export function LLPADetailsStepForm({
    llpa, handleSubmit, isDialog, isCap
}: LLPADetailsStepFormProps) {
    const selectedVersion = useSelectedLLPAVersion(llpa?.versions);
    const formMethods = useForm<DetailsStepFormValues>({
        allowUndefinedDefaultValues: true,
        defaultValues: {
            isCap,
            version: selectedVersion || {
                comments: 'Initial version',
                countsTowardCap: isCap ? undefined : true,
                effectiveDate: formatISO(startOfTomorrow(), { representation: 'date' })
            }
        }
    });

    const effectiveDateFormValue = formMethods.watch('version.effectiveDate');
    const effectiveDate = parse(effectiveDateFormValue, 'yyyy-MM-dd', startOfTomorrow());

    return (
        <FormProvider {...formMethods}>
            <form
                noValidate
                id={llpaFormID}
                onSubmit={formMethods.handleSubmit(handleSubmit)}
                className={styles.detailsStepForm}
            >
                {!isDialog && (
                    <>
                        <TextField<DetailsStepFormValues>
                            name="name"
                            label={`${isCap ? 'Cap ' : 'LLPA'} name`}
                            required
                        />

                        <TextField<DetailsStepFormValues>
                            name="description"
                            label="Description"
                            required
                            multiline
                            rows={2}
                        />
                    </>
                )}

                <div className={styles.dateFields}>
                    <DateField<DetailsStepFormValues>
                        name="version.effectiveDate"
                        label="Effective date"
                        required
                        dateOnly
                        pickerProps={{
                            disablePast: true,
                            shouldDisableDate: (date) => isToday(date)
                        }}
                    />

                    <DateField<DetailsStepFormValues>
                        name="version.expirationDate"
                        label="Expiration date"
                        dateOnly
                        pickerProps={{
                            disablePast: true,
                            defaultCalendarMonth: effectiveDate,
                            shouldDisableDate: (date) => isToday(date) || isBefore(date, effectiveDate)
                        }}
                        rules={{
                            validate: (value) => (
                                typeof value === 'string' && isBefore(
                                    parse(value, 'yyyy-MM-dd', startOfTomorrow()),
                                    effectiveDate
                                ) ? 'Expiration date must be after or equal to effective date' : undefined
                            )
                        }}
                    />
                </div>

                <TextField<DetailsStepFormValues>
                    name="version.comments"
                    label="Version comments"
                    multiline
                    rows={2}
                />

                {!isCap && (
                    <div className={styles.switches}>
                        <Switch<DetailsStepFormValues>
                            label="Counts toward cap"
                            name="version.countsTowardCap"
                        />

                        <Switch<DetailsStepFormValues>
                            label="Is SRP"
                            name="version.isSRP"
                        />

                        <Switch<DetailsStepFormValues>
                            label="Is GOS"
                            name="version.isGainOnSale"
                        />
                    </div>
                )}
            </form>
        </FormProvider>
    );
}

interface MatrixStepFormProps {
    defaultValues?: MatrixStepFormValues;
    handleSubmit: (formValues: MatrixStepFormValues) => Promise<void>;
}

type ValidationError = { name: FieldPath<MatrixStepFormValues>, message: string };
export const MatrixStepFormContext = createContext<{
    validationErrors: ValidationError[];
    setValidationErrors: Dispatch<SetStateAction<ValidationError[]>>;
}>({
    validationErrors: [],
    setValidationErrors: () => {}
});

export function MatrixStepForm({ defaultValues, handleSubmit }: MatrixStepFormProps) {
    const confirm = useConfirm();
    const formMethods = useForm<MatrixStepFormValues>({
        defaultValues: defaultValues || {
            columnMetadata: [],
            llpaCells: []
        }
    });

    const [ validationErrors, setValidationErrors ] = useState<ValidationError[]>([]);

    const columnMetadata = formMethods.watch('columnMetadata');
    const highLevelCols = columnMetadata.filter(({ isHighLevel }) => isHighLevel);
    const matrixCols = columnMetadata.filter(({ isHighLevel }) => !isHighLevel);
    const llpaLoanPropertyEnumDisplays = useLlpaLoanPropertyEnumDisplays();

    const { investors } = useContext(LLPARoutesContext);
    const investorOptions = useInvestorOptions();

    const pageMessage = usePageMessage();

    return (
        <MatrixStepFormContext.Provider
            value={{
                validationErrors,
                setValidationErrors
            }}
        >
            <FormProvider {...formMethods}>
                <form
                    noValidate
                    id={llpaFormID}
                    onSubmit={validationErrors.length ? (e) => {
                        e.preventDefault();

                        pageMessage.error(
                            'Please fix the following errors in the form:',
                            validationErrors.map(({ message }) => message)
                        );
                    } : formMethods.handleSubmit(handleSubmit)}
                >
                    <EditHighLevelGuidelines<LLPAMatrixLoanProperty>
                        nameBase="columnMetadata"
                        defaultNewValues={{}}
                        getSelectOptions={(loanProperty) => {
                            if (loanProperty === LLPAOnlyLoanProperty.INVESTOR) {
                                return investorOptions;
                            }

                            return llpaLoanPropertyEnumDisplays[loanProperty];
                        }}
                        getValueType={(loanProperty) => llpaMatrixLoanPropertyEnumFieldTypes[loanProperty]}
                        formatValue={(meta) => formatLlpaHighLevelGuidelineValue(meta, investors)}
                        loanPropertyOptions={llpaMatrixLoanPropertyDisplay}
                    />

                    <Autocomplete
                        multiple
                        className={styles.columnAutocomplete}
                        value={matrixCols.map(({ loanProperty }) => loanProperty)}
                        options={Object.values({
                            ...LoanProperty,
                            ...LLPAOnlyLoanProperty
                        }).filter((loanProperty) => (
                            !columnMetadata.some((meta) => meta.loanProperty === loanProperty)
                        ))}
                        getOptionLabel={(value) => llpaMatrixLoanPropertyDisplay[value as LLPAMatrixLoanProperty]}
                        renderInput={params => (
                            <MuiTextField
                                {...params}
                                label="Matrix columns"
                            />
                        )}
                        onChange={async (_, value) => {
                            const cells = formMethods.getValues('llpaCells');

                            const firstAdjustment = parseFloat(cells[0]?.adjustment || '');
                            const shouldConfirm = cells.length > 1 || !!firstAdjustment || matrixCols.some((col) => (
                                cells[0]?.[col.loanProperty]
                            ));

                            const isRemove = value.length < matrixCols.length;
                            if (isRemove) {
                                setValidationErrors([]);
                            }

                            if (!shouldConfirm || await confirm(`Changing the matrix columns will reset the
                                matrix values. Do you want to continue?`)) {
                                formMethods.setValue('columnMetadata', [
                                    ...highLevelCols,
                                    ...value.map((loanProperty, index) => ({
                                        loanProperty,
                                        isHighLevel: false,
                                        displayOrder: highLevelCols.length + index
                                    } as LLPAColumnMetadataFormValues))
                                ]);

                                formMethods.setValue('llpaCells', getDefaultLlpaMatrixFormCells(value));
                            }
                        }}
                    />

                    <LLPAMatrix />
                </form>
            </FormProvider>
        </MatrixStepFormContext.Provider>
    );
}

export function formatLlpaHighLevelGuidelineValue(
    meta: LLPAColumnMetadataFormValues,
    investors: ClientInvestor[] | undefined
) {
    return meta.loanProperty === LLPAOnlyLoanProperty.INVESTOR
        ? (
            <HighLevelEnumDisplay
                values={(meta.value as string[]).map((value) => {
                    const investor = investors?.find(({ id }) => id === value);

                    return `${investor?.name} (${investor?.code})`;
                })}
            />
        )
        : formatHighLevelGuidelineValue(meta as MatrixColumnMetadataFormValues<LoanProperty>);
}

export function getDefaultLlpaMatrixFormCells(loanProperties: LLPAMatrixLoanProperty[]) {
    return [
        Object.fromEntries([
            [ 'adjustment', '0.000' ],
            ...loanProperties.map((col) => [ col, undefined ])
        ]) as LLPAMatrixFormCell
    ];
}

export function matrixFormValuesToApi(
    formValues: MatrixStepFormValues, isCap: boolean | undefined
): LLPAVersionDetails {
    return {
        columnMetadata: formValues.columnMetadata.map((meta) => ({
            ...meta,
            value: meta.loanProperty === LLPAOnlyLoanProperty.INVESTOR
                ? meta.value as string[]
                : parseLoanProgramFieldFormValue(meta.loanProperty, meta.value)
        })),
        llpaCells: formValues.llpaCells.map(({ adjustment, ...cell }) => ({
            ...cell,
            ...(isCap ? { adjustmentCap: adjustment } : { adjustment }),
            [LOAN_TYPE]: cell[LOAN_TYPE] as LLPACell[typeof LOAN_TYPE],
            [OCCUPANCY]: cell[OCCUPANCY] as LLPACell[typeof OCCUPANCY],
            [PURPOSE]: cell[PURPOSE] as LLPACell[typeof PURPOSE],
            [AMORT_TYPE]: cell[AMORT_TYPE] as LLPACell[typeof AMORT_TYPE],
            [LOAN_LIMIT_TYPE]: cell[LOAN_LIMIT_TYPE] as LLPACell[typeof LOAN_LIMIT_TYPE],
            [PROPERTY_TYPE]: cell[PROPERTY_TYPE] as LLPACell[typeof PROPERTY_TYPE],
            [AUS]: cell[AUS] as LLPACell[typeof AUS],
            [SPECIALTY_PROGRAM]: cell[SPECIALTY_PROGRAM] as LLPACell[typeof SPECIALTY_PROGRAM],
            [STATE]: cell[STATE] as LLPACell[typeof STATE],
            [HIGH_BALANCE]: cell[HIGH_BALANCE]?.includes(BooleanEnum.TRUE),
            [ESCROWS]: cell[ESCROWS]?.includes(BooleanEnum.TRUE),
            [SUBORDINATE_FINANCING]: cell[SUBORDINATE_FINANCING]?.includes(BooleanEnum.TRUE),
            [IRRL]: cell[IRRL]?.includes(BooleanEnum.TRUE),
            [STREAMLINE]: cell[STREAMLINE]?.includes(BooleanEnum.TRUE),
            [FIRST_TIME_HOMEBUYER]: cell[FIRST_TIME_HOMEBUYER]?.includes(BooleanEnum.TRUE),
            [NON_QM]: cell[NON_QM]?.includes(BooleanEnum.TRUE),
            [UNITS]: parseNumericMatrixEntry(cell[UNITS]),
            [FICO]: parseNumericMatrixEntry(cell[FICO]),
            [LTV]: parseNumericMatrixEntry(cell[LTV]),
            [CLTV]: parseNumericMatrixEntry(cell[CLTV]),
            [DTI]: parseNumericMatrixEntry(cell[DTI]),
            [TERM]: parseNumericMatrixEntry(cell[TERM]),
            [RESERVES_MONTHS]: parseNumericMatrixEntry(cell[RESERVES_MONTHS]),
            [LOAN_AMOUNT]: parseNumericMatrixEntry(cell[LOAN_AMOUNT])
        }))
    };
}

export function matrixDetailsToFormValues(versionDetails: LLPAVersionDetails): MatrixStepFormValues {
    return {
        columnMetadata: versionDetails.columnMetadata.map((meta) => ({
            ...meta,
            value: meta.loanProperty === LLPAOnlyLoanProperty.INVESTOR
                ? meta.value as string[]
                : formatLoanProgramFieldFormValue(meta.value)
        })),
        llpaCells: versionDetails.llpaCells.map(({ adjustment, adjustmentCap, ...cell }) => ({
            ...cell,
            adjustment: adjustmentCap || adjustment,
            [LOAN_TYPE]: cell[LOAN_TYPE] as LLPAMatrixFormCell[typeof LOAN_TYPE],
            [OCCUPANCY]: cell[OCCUPANCY] as LLPAMatrixFormCell[typeof OCCUPANCY],
            [PURPOSE]: cell[PURPOSE] as LLPAMatrixFormCell[typeof PURPOSE],
            [AMORT_TYPE]: cell[AMORT_TYPE] as LLPAMatrixFormCell[typeof AMORT_TYPE],
            [LOAN_LIMIT_TYPE]: cell[LOAN_LIMIT_TYPE] as LLPAMatrixFormCell[typeof LOAN_LIMIT_TYPE],
            [PROPERTY_TYPE]: cell[PROPERTY_TYPE] as LLPAMatrixFormCell[typeof PROPERTY_TYPE],
            [AUS]: cell[AUS] as LLPAMatrixFormCell[typeof AUS],
            [SPECIALTY_PROGRAM]: cell[SPECIALTY_PROGRAM] as LLPAMatrixFormCell[typeof SPECIALTY_PROGRAM],
            [STATE]: cell[STATE] as LLPAMatrixFormCell[typeof STATE],
            [HIGH_BALANCE]: booleanToEnumArray(cell[HIGH_BALANCE]),
            [ESCROWS]: booleanToEnumArray(cell[ESCROWS]),
            [SUBORDINATE_FINANCING]: booleanToEnumArray(cell[SUBORDINATE_FINANCING]),
            [IRRL]: booleanToEnumArray(cell[IRRL]),
            [STREAMLINE]: booleanToEnumArray(cell[STREAMLINE]),
            [FIRST_TIME_HOMEBUYER]: booleanToEnumArray(cell[FIRST_TIME_HOMEBUYER]),
            [NON_QM]: booleanToEnumArray(cell[NON_QM]),
            [UNITS]: numericEntryToString(cell[UNITS]),
            [FICO]: numericEntryToString(cell[FICO]),
            [LTV]: numericEntryToString(cell[LTV]),
            [CLTV]: numericEntryToString(cell[CLTV]),
            [DTI]: numericEntryToString(cell[DTI]),
            [TERM]: numericEntryToString(cell[TERM]),
            [RESERVES_MONTHS]: numericEntryToString(cell[RESERVES_MONTHS]),
            [LOAN_AMOUNT]: numericEntryToString(cell[LOAN_AMOUNT])
        }))
    };
}

function booleanToEnumArray(value: boolean | undefined) {
    if (value === true || value === false) {
        return [ value ? BooleanEnum.TRUE : BooleanEnum.FALSE ];
    }

    return undefined;
}
