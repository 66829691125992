import { Dispatch, SetStateAction, createContext } from 'react';


export const ActiveTokenContext = createContext<{
    activeAuthToken: string;
    setActiveAuthToken: Dispatch<SetStateAction<string>>;
}>({
    activeAuthToken: '',
    setActiveAuthToken: () => {}
});
