import { ActiveInactiveEnum } from '@api';

import { apiUtils } from '../../api-utils';


export function getWarehouseRelationships(clientID: string, customerID: string): Promise<WarehouseRelationship[]> {
    return apiUtils.get(`/client/${clientID}/customer/${customerID}/warehouse-relationship`);
}

export function updateWarehouseRelationship(
    clientID: string, customerID: string, warehouseRelationship: WarehouseRelationship
): Promise<WarehouseRelationship> {
    return apiUtils.put(`/client/${clientID}/customer/${customerID}/warehouse-relationship/${warehouseRelationship.id}`, warehouseRelationship);
}

export function createWarehouseRelationship(
    clientID: string, customerID: string, warehouseRelationship: WarehouseRelationship
): Promise<WarehouseRelationship> {
    return apiUtils.post(`/client/${clientID}/customer/${customerID}/warehouse-relationship`, warehouseRelationship);
}

export function softDeleteWarehouseRelationship(
    clientID: string, customerID: string, warehouseRelationship: WarehouseRelationship
): Promise<WarehouseRelationship> {
    return updateWarehouseRelationship(clientID, customerID, {
        ...warehouseRelationship,
        status: ActiveInactiveEnum.INACTIVE
    });
}

export interface WarehouseRelationship {
    id: string;
    lenderName: string;
    lineAmount: number;
    contact: string;
    phone: string;
    emailAddress: string;
    status: ActiveInactiveEnum;
}
