import { UploadStatus } from '@api';
import { Paper, Tooltip, Typography } from '@mui/material';
import clsx from 'clsx';
import { ReactNode, forwardRef } from 'react';

import styles from './UploadStatusCard.module.scss';


interface StatusCardProps {
    className?: string;
    primaryText: ReactNode;
    secondaryText: ReactNode;
    actions: ReactNode;
    status: UploadStatus;
}

export const UploadStatusCard = forwardRef<HTMLDivElement, StatusCardProps>(({
    className, primaryText, secondaryText, actions, status
}, ref) => (
    <Paper
        ref={ref}
        variant="outlined"
        className={clsx(styles.root, className, {
            [styles.done]: status === UploadStatus.COMPLETE,
            [styles.error]: status === UploadStatus.ERROR
        })}
    >
        <div className={styles.completedOverlay} />

        <Tooltip title={primaryText}>
            <Typography
                variant="body2"
                className={styles.primaryText}
            >
                {primaryText}
            </Typography>
        </Tooltip>

        <div className={styles.iconContainer}>
            <Typography variant="body2">
                {secondaryText}
            </Typography>

            {actions}
        </div>
    </Paper>
));
