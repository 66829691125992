export enum CustomerPendingSteps {
    COMPENSATION = 'COMPENSATION',
    DUE_DILIGENCE = 'DUE_DILIGENCE',
    ELIGIBLE_PRODUCTS = 'ELIGIBLE_PRODUCTS',
    CONTACTS = 'CONTACTS',
    USERS = 'USERS',
    WIRE_INSTRUCTIONS = 'WIRE_INSTRUCTIONS'
}

// TODO: update enum values when WireInfo & License get refactored into DueDiligence

export const customerPendingStepsDisplay = {
    [CustomerPendingSteps.COMPENSATION]: 'Compensation',
    [CustomerPendingSteps.DUE_DILIGENCE]: 'Due diligence',
    [CustomerPendingSteps.ELIGIBLE_PRODUCTS]: 'Eligible products',
    [CustomerPendingSteps.CONTACTS]: 'Contacts',
    [CustomerPendingSteps.USERS]: 'Users',
    [CustomerPendingSteps.WIRE_INSTRUCTIONS]: 'Wire instructions'
};
