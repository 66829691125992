import { License, licenseTypeDisplay } from '@api';
import { TextField, renderEnumOptions } from '@tsp-ui/core/components';
import { useRenderTogglableEnumOptions } from '@utils';
import { FormProvider, useForm } from 'react-hook-form';

import styles from './LicenseForm.module.scss';


interface LicenseFormProps {
    defaultValues?: License;
    handleSubmit(license: License): void;
}

export function LicenseForm({ defaultValues, handleSubmit: handleSubmitProp }: LicenseFormProps) {
    const formMethods = useForm<License>({ defaultValues });
    const handleSubmit = formMethods.handleSubmit(handleSubmitProp);
    const renderTogglableEnumOptions = useRenderTogglableEnumOptions();

    return (
        <form
            noValidate
            id={`licenses-${defaultValues?.id || 'new'}`}
            onSubmit={handleSubmit}
            className={styles.formContent}
        >
            <FormProvider {...formMethods}>
                <TextField<License>
                    name="type"
                    label="Type"
                    size="small"
                    variant="outlined"
                    required
                    select
                >
                    {renderEnumOptions(licenseTypeDisplay)}
                </TextField>

                <div />

                <TextField<License>
                    name="state"
                    label="State"
                    size="small"
                    variant="outlined"
                    required
                    select
                >
                    {renderTogglableEnumOptions('state')}
                </TextField>

                <TextField<License>
                    name="licenseNumber"
                    label="License Number"
                    size="small"
                    variant="outlined"
                    required
                />

                <TextField<License>
                    name="renewedThroughYear"
                    label="Renewed through year"
                    size="small"
                    variant="outlined"
                    type="number"
                    required
                />
            </FormProvider>
        </form>
    );
}
