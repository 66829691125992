import { LoanPricingResult, RegisteredLoan, RegistrationType } from '@api';
import { Description, OpenInNew } from '@mui/icons-material';
import {
    Chip, Link as MuiLink, Paper, Typography
} from '@mui/material';
import {
    CurrencyTypography, ExpandableCard, IconButton, LabeledValue, getItemById
} from '@tsp-ui/core';
import { useGetCurrentAccount } from '@utils';
import { ClientContext } from '@views/AuthenticatedRouteSwitch';
import clsx from 'clsx';
import { ReactNode, useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import styles from './LoanCard.module.scss';


interface LoanCardProps {
    className?: string;
    loanPricingResult?: LoanPricingResult;
    index?: number;
    loan?: RegisteredLoan;
    expandedContent?: ReactNode;
    /**
     * Additional details are displayed on the right side of the card
     */
    additionalDetails?: ReactNode;
    isPendingLoan: boolean;
};

/**
 * This card can be used for loan pricing results, registered loans, and loan dashboard loans
 */
export function LoanCard({
    className,
    loanPricingResult,
    index,
    loan,
    expandedContent,
    additionalDetails,
    isPendingLoan,
    ...transitionProps
}: LoanCardProps) {
    const nodeRef = useRef<HTMLDivElement | null>(null);
    const { customerId: accountCustomerId, id: clientId } = useGetCurrentAccount();
    const { customers } = useContext(ClientContext);

    const loanId = loan?.id || loanPricingResult?.loanId;
    const {
        loanNumber, loanAmount, interestRate, borrowerName, customerId: loanCustomerId
    } = loan || loanPricingResult || {};
    const customerName = getItemById(customers || [], loanCustomerId || '')?.name;

    const CardComponent = expandedContent ? ExpandableCard : Paper;

    const accountUrlPrefix = accountCustomerId
        ? `/accounts/${clientId}/${accountCustomerId}`
        : `/accounts/${clientId}`;

    return (
        <CSSTransition
            {...transitionProps} // required when CSSTransition is not a direct child of TransitionGroup
            nodeRef={nodeRef}
            exit={false}
            timeout={250 + ((index || 1) * 50)}
            classNames={{
                enter: styles.loanCardTransition_enter,
                enterActive: styles.loanCardTransition_enter_active
            }}
        >
            <CardComponent
                nodeRef={nodeRef}
                className={className}
                indentContent
                expandedContent={expandedContent}
                variant="outlined"
            >
                <div className={clsx(styles.mainRow, {
                    // need to reduce the gap to fit the lock button
                    [styles.compact]: loan?.registrationType === RegistrationType.FLOAT
                })}
                >
                    <Typography
                        color="textSecondary"
                        className={styles.loanNumber}
                    >
                        Loan #

                        <MuiLink
                            component={Link}
                            to={loan ? `${accountUrlPrefix}/loans/${loanId}/detail/all` : `${accountUrlPrefix}/loans/${loanId}/pending/detail/all`}
                        >
                            {loanNumber}
                        </MuiLink>

                        {!accountCustomerId && customerName && (
                            <Chip
                                size="small"
                                label={customerName}
                                className={styles.chip}
                            />
                        )}

                        <IconButton
                            component={Link}
                            to={`${loanId}/loan-data`}
                            state={{ isPendingLoan }}
                            tooltip="View loan data"
                        >
                            <OpenInNew
                                color="secondary"
                                fontSize="small"
                            />
                        </IconButton>

                        {loan && (
                            <IconButton
                                component={Link}
                                to={`${loanId}/loan-documents`}
                                tooltip="View loan documents"
                                state={loanNumber}
                            >
                                <Description
                                    color="secondary"
                                    fontSize="small"
                                />
                            </IconButton>
                        )}
                    </Typography>

                    <div>
                        {loanAmount ? (
                            <CurrencyTypography
                                value={loanAmount}
                                variant="body2"
                            />
                        ) : (
                            <Typography variant="body2">
                                N/A
                            </Typography>
                        )}

                        <Typography variant="body2">
                            <span className={styles.hidden}>$</span>{interestRate ? `${interestRate.toFixed(3)}%` : 'N/A'}
                        </Typography>
                    </div>

                    <LabeledValue
                        label="Borrower"
                        value={borrowerName}
                        variant="vertical"
                    />

                    {additionalDetails}
                </div>
            </CardComponent>
        </CSSTransition>
    );
}
