import api, {
    EligibilityMatrix,
    ExclusionOverlay,
    LoanProgramStatus,
    ManagedEligibilityStatus,
    PermissionType
} from '@api';
import { AutoFixNormal } from '@mui/icons-material';
import {
    Button, Paper, Tooltip, Typography
} from '@mui/material';
import { useAsyncEffect, useParams } from '@tsp-ui/core';
import { RoutedDialogManager, usePageMessage } from '@tsp-ui/core/components';
import { useActingClientID, useHasPermission, withAuth } from '@utils';
import { AdminSubRouteParams } from '@views/admin/components/AdminPageTemplate';
import EditableSectionCard from '@views/admin/components/EditableSectionCard';
import { overlayDialogRoutes } from '@views/admin/investor-eligibility/overlayDialogRoutes';
import {
    LoanProgramDetailPage
} from '@views/admin/investors/InvestorDetailPage/LoanProgramDetailPage/InternalLoanProgramDetailPage';
import {
    EditClientEligibilityMatrixDialog
} from '@views/admin/investors/InvestorDetailPage/LoanProgramDetailPage/components/EditInternalEligibilityMatrixDialog';
import {
    EditClientLoanProgramDetailsDialog
} from '@views/admin/investors/InvestorDetailPage/LoanProgramDetailPage/components/EditLoanProgramDetailsDialog';
import {
    ClientReadOnlyEligibilityMatrixDialog
} from '@views/admin/investors/InvestorDetailPage/LoanProgramDetailPage/components/ReadOnlyEligibilityMatrixDialog';
import { useEligibilityVersionIdQueryParam } from '@views/admin/investors/components/EligibilityVersionButton';
import {
    createContext, useCallback, useContext, useState
} from 'react';
import { Link, useLocation } from 'react-router-dom';


import { useTryGetInvestor } from '../investors/InvestorDetailPage/InternalInvestorDetailPage';

import styles from './ClientLoanProgramDetailPage.module.scss';
import { ExclusionOverlayContext } from './InvestorEligibilityManagementPage';
import { InvestorEligibilityContext } from './InvestorEligibilityRoutes';
import ExclusionOverlaySection from './components/ExclusionOverlaySection';


interface ClientLoanProgramMatrixContextValues {
    matrix: EligibilityMatrix | undefined;
}

export const ClientLoanProgramDetailContext = createContext<ClientLoanProgramMatrixContextValues>({
    matrix: undefined
});

const { ACTIVE, INACTIVE } = LoanProgramStatus;

export function ClientLoanProgramDetailPage() {
    const [ loading, setLoading ] = useState(true);

    const pageMessage = usePageMessage();
    const clientId = useActingClientID();
    const eligibilityVersionId = useEligibilityVersionIdQueryParam();
    const { investorID, loanProgramID } = useParams<AdminSubRouteParams<'loanProgram'>>();
    const { eligibilityVersions, isSelectedVersionEditable } = useContext(InvestorEligibilityContext);
    const { search } = useLocation();

    const [ exclusionOverlays, setExclusionOverlays ] = useState<ExclusionOverlay[]>([]);
    useAsyncEffect(useCallback(async () => {
        setLoading(true);

        try {
            setExclusionOverlays(await api.overlay.getExclusionOverlays(clientId, {
                loanProgramId: loanProgramID,
                investorId: investorID,
                effectiveVersionId: eligibilityVersionId
            }));
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching exclusion overlays.', error);
        }

        setLoading(false);
    }, [
        clientId, loanProgramID, investorID, eligibilityVersionId, pageMessage
    ]));

    const [ matrix, setMatrix ] = useState<EligibilityMatrix>();
    useAsyncEffect(useCallback(async () => {
        try {
            setMatrix(await api.client.investors.getEligibilityMatrix(
                clientId, investorID, loanProgramID, eligibilityVersionId
            ));
        } catch (error) {
            pageMessage.handleApiError(
                'An error occurred while fetching eligibility details for this loan program', error
            );
        }
    }, [
        clientId, investorID, loanProgramID, eligibilityVersionId, pageMessage
    ]));

    const [ investor ] = useTryGetInvestor();
    const [ canManage ] = useHasPermission([ PermissionType.MANAGE_CLIENT_ELIGIBILITY ]);

    return (
        <ExclusionOverlayContext.Provider
            value={{
                overlays: exclusionOverlays,
                setOverlays: setExclusionOverlays
            }}
        >
            <LoanProgramDetailPage
                loading={loading || !investor}
                matrix={matrix}
                setMatrix={setMatrix}
                eligibilityVersions={eligibilityVersions}
                firstBreadcrumb="Investor Eligibility"
                editPermission={PermissionType.MANAGE_CLIENT_LOAN_PROGRAMS}
                isSelectedVersionEditable={isSelectedVersionEditable}
                onToggleActive={(loanProgram) => api.client.investors.updateLoanProgramStatus(
                    clientId,
                    loanProgram.investorId,
                    loanProgram.id,
                    {
                        status: loanProgram.status === ACTIVE ? INACTIVE : ACTIVE,
                        eligibilityVersionId: eligibilityVersionId!
                    }
                )}
            >
                {investor?.managedEligibilityStatus === ManagedEligibilityStatus.MANAGED ? (
                    <Paper
                        variant="outlined"
                        className={styles.card}
                    >
                        <Typography
                            variant="h6"
                            className={styles.title}
                        >
                            Eligibility matrix

                            <Tooltip
                                title="This loan program's eligibility is managed by Premicorr. Eligibility
                                    will automatically be updated if Premicorr makes changes to the base eligibility."
                            >
                                <AutoFixNormal color="primary" />
                            </Tooltip>
                        </Typography>

                        <Typography>
                            Eligibility for this program is managed by Premicorr
                        </Typography>

                        <Button
                            component={Link}
                            to={`matrix${search}`}
                            className={styles.button}
                        >
                            View eligibility matrix
                        </Button>
                    </Paper>
                ) : (
                    <EditableSectionCard
                        header="Eligibility matrix"
                        editTo={`matrix/edit${search}`}
                        disableEdit={!canManage || !isSelectedVersionEditable}
                        disabledTooltip={!canManage
                            ? 'You don\'t have permission to manage eligibility'
                            : 'The eligibility matrix can only be edited for a future eligibility version'}
                    >
                        <Typography>
                            Eligibility for this program is self-managed
                        </Typography>

                        <Button
                            component={Link}
                            to={`matrix${search}`}
                            className={styles.button}
                        >
                            View eligibility matrix
                        </Button>
                    </EditableSectionCard>
                )}

                <ExclusionOverlaySection
                    overlays={exclusionOverlays}
                    disableAdd={!isSelectedVersionEditable}
                />

                <ClientLoanProgramDetailContext.Provider value={{ matrix }}>
                    <RoutedDialogManager routes={dialogRoutes} />
                </ClientLoanProgramDetailContext.Provider>
            </LoanProgramDetailPage>
        </ExclusionOverlayContext.Provider>
    );
}

const { VIEW_CLIENT_ELIGIBILITY, MANAGE_CLIENT_ELIGIBILITY, MANAGE_CLIENT_LOAN_PROGRAMS } = PermissionType;

const dialogRoutes = {
    ...overlayDialogRoutes,
    matrix: withAuth(ClientReadOnlyEligibilityMatrixDialog, [ VIEW_CLIENT_ELIGIBILITY ], true),
    'matrix/edit': withAuth(
        EditClientEligibilityMatrixDialog, [ MANAGE_CLIENT_ELIGIBILITY ], true
    ),
    edit: withAuth(EditClientLoanProgramDetailsDialog, [ MANAGE_CLIENT_LOAN_PROGRAMS ], true)
};
