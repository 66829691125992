import { ActiveInactiveEnum } from '@api';

import { apiUtils } from '../../api-utils';


export function getInvestorRelationships(clientID: string, customerID: string): Promise<InvestorRelationship[]> {
    return apiUtils.get(`/client/${clientID}/customer/${customerID}/investor-relationship`);
}

export function updateInvestorRelationship(
    clientID: string, customerID: string, investorRelationship: InvestorRelationship
): Promise<InvestorRelationship> {
    return apiUtils.put(`/client/${clientID}/customer/${customerID}/investor-relationship/${investorRelationship.id}`, investorRelationship);
}

export function createInvestorRelationship(
    clientID: string, customerID: string, investorRelationship: InvestorRelationship
): Promise<InvestorRelationship> {
    return apiUtils.post(`/client/${clientID}/customer/${customerID}/investor-relationship`, investorRelationship);
}

export function softDeleteInvestorRelationship(
    clientID: string, customerID: string, investorRelationship: InvestorRelationship
): Promise<InvestorRelationship> {
    return updateInvestorRelationship(clientID, customerID, {
        ...investorRelationship,
        status: ActiveInactiveEnum.INACTIVE
    });
}

export interface InvestorRelationship {
    id: string;
    companyName: string;
    contact: string;
    phone: string;
    emailAddress: string;
    status: ActiveInactiveEnum;
}
