import api, { ActiveInactiveEnum, InvestorRelationship, PermissionType } from '@api';
import { Edit, RemoveCircleOutline, Save } from '@mui/icons-material';
import { Paper, Typography } from '@mui/material';
import { replaceItemById, useParams } from '@tsp-ui/core';
import {
    IconButton, LabelGroup, LabeledValue, PhoneTypography, usePageMessage
} from '@tsp-ui/core/components';
import { tooltipTitle, useHasPermission } from '@utils';
import { useGetCurrentAccount } from '@utils/hooks/useGetCurrentAccount';
import { AdminRouteParams } from '@views/admin/components/AdminPageTemplate';
import { Dispatch, SetStateAction, useState } from 'react';

import styles from './InvestorRelationshipCard.module.scss';
import { InvestorRelationshipForm } from './InvestorRelationshipForm';


interface InvestorRelationshipCardProps {
    investorRelationship: InvestorRelationship | undefined;
    setAdding?: Dispatch<SetStateAction<boolean>>;
    setInvestorRelationships: Dispatch<SetStateAction<InvestorRelationship[]>>;
    locked?: boolean;
}

export function InvestorRelationshipCard(
    {
        investorRelationship, setAdding, setInvestorRelationships, locked
    }: InvestorRelationshipCardProps
) {
    const [ editing, setEditing ] = useState(!investorRelationship);
    const [ deleting, setDeleting ] = useState(false);
    const [ saving, setSaving ] = useState(false);
    const pageMessage = usePageMessage();

    const { id: clientID, customerId } = useGetCurrentAccount();
    const { customerID } = useParams<AdminRouteParams<'customer'>>();

    const [ canManage ] = useHasPermission([ PermissionType.MANAGE_INVESTOR_RELATIONSHIP ]);

    async function handleDelete() {
        setDeleting(true);

        if (investorRelationship) {
            try {
                const deactivatedInvestorRelationship = await api.customer.investorRelationship
                    .softDeleteInvestorRelationship(clientID, customerId || customerID, investorRelationship);

                setInvestorRelationships(
                    investorRelationships => replaceItemById(investorRelationships, deactivatedInvestorRelationship)
                );

                pageMessage.success('Investor relationship deleted');
            } catch (error) {
                pageMessage.handleApiError('An error occurred while deleting the investor relationship', error);
            }
        } else {
            setAdding?.(false);
        }

        setDeleting(false);
    }

    async function handleSubmit(formValues: InvestorRelationship) {
        setSaving(true);

        try {
            const newInvestorRelationship = investorRelationship
                ? await api.customer.investorRelationship.updateInvestorRelationship(
                    clientID, customerId || customerID, formValues
                )
                : await api.customer.investorRelationship.createInvestorRelationship(
                    clientID, customerId || customerID, {
                        ...formValues,
                        status: ActiveInactiveEnum.ACTIVE
                    }
                );

            if (investorRelationship) {
                setInvestorRelationships(
                    investorRelationships => replaceItemById(investorRelationships, newInvestorRelationship)
                );
                setEditing(false);
            } else {
                setInvestorRelationships(
                    investorRelationships => [ ...investorRelationships, newInvestorRelationship ]
                );
                setAdding?.(false);
            }

            pageMessage.success('Investor relationship saved');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving the investor relationship', error);
        }

        setSaving(false);
    }

    return (
        <Paper
            className={styles.root}
            variant="outlined"
        >
            <div className={styles.displayHeader}>
                <Typography>
                    {investorRelationship?.companyName || 'New Investor Relationship'}
                </Typography>

                <div>
                    {editing ? (
                        <IconButton
                            tooltip="Save investor relationship"
                            form={`investor-relationship-${investorRelationship?.id || 'new'}`}
                            type="submit"
                            loading={saving}
                        >
                            <Save color="success" />
                        </IconButton>
                    ) : (
                        <IconButton
                            tooltip={tooltipTitle({
                                'You must unlock this data request to make changes': locked,
                                'Edit investor relationship': canManage,
                                'You do not have permission to edit investor relationships': !canManage
                            })}
                            disabled={!canManage || locked}
                            onClick={() => setEditing(true)}
                            key="edit"
                        >
                            <Edit color="primary" />
                        </IconButton>
                    )}

                    <IconButton
                        tooltip={tooltipTitle({
                            'You must unlock this data request to make changes': locked,
                            'Delete investor relationship': canManage,
                            'You do not have permission to delete investor relationships': !canManage
                        })}
                        disabled={!canManage || locked}
                        loading={deleting}
                        onClick={handleDelete}
                        edge="end"
                    >
                        <RemoveCircleOutline color="error" />
                    </IconButton>
                </div>
            </div>

            {editing ? (
                <InvestorRelationshipForm
                    defaultValues={investorRelationship}
                    handleSubmit={handleSubmit}
                />
            ) : (
                <LabelGroup>
                    <LabeledValue
                        label="Contact name:"
                        value={investorRelationship?.contact}
                    />

                    <LabeledValue
                        label="Phone #:"
                        value={(
                            <PhoneTypography variant="body2">
                                {investorRelationship?.phone || ''}
                            </PhoneTypography>
                        )}
                    />

                    <LabeledValue
                        label="Email:"
                        value={investorRelationship?.emailAddress}
                    />
                </LabelGroup>
            )}
        </Paper>
    );
}
