import api, { CommonLoanProgram } from '@api';
import { Button, DialogContent } from '@mui/material';
import { DialogActions, RoutedDialog, RoutedDialogImplProps } from '@tsp-ui/core/components';
import { usePageMessage } from '@tsp-ui/core/utils';
import { useActingClientID } from '@utils/hooks';
import { Dispatch, SetStateAction, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { useGetLoanProgram } from '../InternalLoanProgramDetailPage';
import { LoanProgramDetailsFields } from '../components/LoanProgramDetailsFields';

import styles from './EditLoanProgramDetailsDialog.module.scss';


export function EditInternalLoanProgramDetailsDialog(props: RoutedDialogImplProps) {
    const [ loanProgram, setLoanProgram ] = useGetLoanProgram();

    async function handleSubmit(formValues: LoanProgramDetailsFormValues) {
        setLoanProgram(await api.investors.updateLoanProgram(formValues.loanProgram));
    }

    return (
        <EditLoanProgramDetailsDialog
            {...props}
            loanProgram={loanProgram}
            handleSubmit={handleSubmit}
        />
    );
}

export function EditClientLoanProgramDetailsDialog(props: RoutedDialogImplProps) {
    const clientId = useActingClientID();
    const [ loanProgram, setLoanProgram ] = useGetLoanProgram();

    async function handleSubmit(formValues: LoanProgramDetailsFormValues) {
        setLoanProgram(await api.client.investors.updateLoanProgram(clientId, formValues.loanProgram));
    }

    return (
        <EditLoanProgramDetailsDialog
            {...props}
            loanProgram={loanProgram}
            handleSubmit={handleSubmit}
        />
    );
}

interface EditLoanProgramDetailsDialogProps extends RoutedDialogImplProps {
    loanProgram: CommonLoanProgram;
    handleSubmit: (formValues: LoanProgramDetailsFormValues) => Promise<void>;
}

export function EditLoanProgramDetailsDialog({
    handleSubmit,
    loanProgram,
    ...props
}: EditLoanProgramDetailsDialogProps) {
    const { search } = useLocation();

    const [ loading, setLoading ] = useState(false);

    return (
        <RoutedDialog
            {...props}
            title="Edit loan program details"
            saveLoading={loading}
            keepLocationSearch
        >
            <DialogContent>
                {loanProgram && (
                    <LoanProgramDetailsForm
                        setLoading={setLoading}
                        loanProgram={loanProgram}
                        handleSubmit={handleSubmit}
                    />
                )}
            </DialogContent>

            <DialogActions>
                <Button
                    component={Link}
                    to={`..${search}`}
                >
                    Cancel
                </Button>

                <Button
                    variant="contained"
                    type="submit"
                    form={formId}
                >
                    Save
                </Button>
            </DialogActions>
        </RoutedDialog>
    );
}

interface LoanProgramDetailsFormProps {
    loanProgram: CommonLoanProgram;
    setLoading: Dispatch<SetStateAction<boolean>>;
    handleSubmit: (formValues: LoanProgramDetailsFormValues) => Promise<void>;
}

interface LoanProgramDetailsFormValues {
    loanProgram: CommonLoanProgram;
}

function LoanProgramDetailsForm({
    loanProgram,
    handleSubmit: handleSubmitProp,
    setLoading
}: LoanProgramDetailsFormProps) {
    const pageMessage = usePageMessage();
    const navigate = useNavigate();
    const { search } = useLocation();

    const formMethods = useForm<LoanProgramDetailsFormValues>({
        defaultValues: { loanProgram }
    });

    const handleSubmit = formMethods.handleSubmit(async formValues => {
        setLoading(true);

        try {
            await handleSubmitProp(formValues);

            navigate(`..${search}`);

            pageMessage.success('Loan program updated');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while updating the loan program', error);
        }

        setLoading(false);
    });

    return (
        <form
            id={formId}
            onSubmit={handleSubmit}
            className={styles.root}
        >
            <FormProvider {...formMethods}>
                <LoanProgramDetailsFields />
            </FormProvider>
        </form>
    );
}

const formId = 'loan-program-form';
