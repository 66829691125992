import { MatrixColumnMetadata } from '@api';
import { HelpOutline } from '@mui/icons-material';
import { Button, Tooltip, Typography } from '@mui/material';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';

import {
    EditHighLevelGuidelineCard,
    EditHighLevelGuidelineCardProps,
    ViewHighLevelGuidelineCardProps
} from './HighLevelGuidelineCard';
import styles from './HighLevelGuidelines.module.scss';


export interface MatrixColumnMetadataFormValues<T extends string> extends Omit<MatrixColumnMetadata<T>, 'value'> {
    value: string | string[];
}

interface HighLevelGuidelinesBaseProps {
    addButton?: ReactNode;
    cards: ReactNode;
    noContent: boolean;
    readOnly?: boolean;
}

export default function HighLevelGuidelines({
    addButton, cards, noContent, readOnly
}: HighLevelGuidelinesBaseProps) {
    return (
        <>
            <Typography
                color="textSecondary"
                variant="caption"
                className={clsx(styles.highLevelLabel, {
                    [styles.readOnly]: readOnly
                })}
            >
                High level guidelines

                <Tooltip
                    title="High level guidelines apply to all rows in the matrix. The columns for high
                        level guidelines will not be visible in the matrix unless they are deleted."
                >
                    <HelpOutline
                        color="primary"
                        fontSize="small"
                    />
                </Tooltip>

                {addButton}
            </Typography>

            {noContent ? (
                <Typography variant="body2">
                    There are no high level guidelines for this matrix
                </Typography>
            ) : (
                <div className={styles.highLevelContainer}>
                    {cards}
                </div>
            )}
        </>
    );
}

interface EditHighLevelGuidelinesProps<
    T extends string
> extends Pick<ViewHighLevelGuidelineCardProps<T>, 'formatValue'>,
    Pick<EditHighLevelGuidelineCardProps<T>, 'loanPropertyOptions' | 'getValueType'> {
    nameBase: string;
    defaultNewValues: Partial<MatrixColumnMetadataFormValues<T>>;
    getSelectOptions: (loanProperty: T) => null | {
        [key: string]: string;
    };
}

export function EditHighLevelGuidelines<T extends string>({
    nameBase, defaultNewValues, getValueType, getSelectOptions, formatValue, loanPropertyOptions
}: EditHighLevelGuidelinesProps<T>) {
    const { append, remove } = useFieldArray({ name: nameBase });
    const cols = useWatch({ name: nameBase }) as MatrixColumnMetadataFormValues<T>[];

    return (
        <HighLevelGuidelines
            noContent={!cols.some(({ isHighLevel }) => isHighLevel)}
            addButton={(
                <Button
                    className={styles.addButton}
                    onClick={() => append?.({
                        isHighLevel: true,
                        ...defaultNewValues
                    } as MatrixColumnMetadataFormValues<T>)}
                >
                    Add high level guideline
                </Button>
            )}
            cards={cols.map(({ loanProperty, isHighLevel }, index) => (!isHighLevel ? null : (
                <EditHighLevelGuidelineCard
                    key={loanProperty}
                    index={index}
                    fieldName={`${nameBase}.${index}`}
                    remove={remove!}
                    selectedLoanProperties={cols.map(({ loanProperty }) => loanProperty)}
                    title={loanPropertyOptions[loanProperty]}
                    getValueType={getValueType}
                    selectOptions={getSelectOptions(loanProperty)}
                    formatValue={formatValue}
                    loanPropertyOptions={loanPropertyOptions}
                />
            )))}
        />
    );
}
