import { Tooltip, Typography } from '@mui/material';

import styles from './ColorPicker.module.scss';


interface ColorPickerProps {
    color: string | undefined;
    label: string;
    setColor: (color: string) => void;
    hasPermission?: boolean;
    noPermissionMessage?: string;
}

export function ColorPicker({
    color, label, setColor, hasPermission = true, noPermissionMessage
}: ColorPickerProps) {
    return (
        <Tooltip title={!hasPermission ? noPermissionMessage : ''}>
            <div className={styles.colorPickerItem}>
                <input
                    type="color"
                    value={color}
                    className={styles.colorInput}
                    onChange={(event) => setColor(event.target.value)}
                    disabled={!hasPermission}
                />

                <Typography variant="body2">
                    {label}
                </Typography>
            </div>
        </Tooltip>
    );
}
