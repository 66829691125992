import api, {
    Contact, Role, User, UserType
} from '@api';
import { RoutedDialog, RoutedDialogProps } from '@tsp-ui/core/components';
import { replaceItemById, useAsyncEffect, usePageMessage } from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils/hooks';
import { RolesContext } from '@views/admin/users/UserManagementPage';
import { useCallback, useContext, useState } from 'react';

import { CustomerDetailContext, CustomerDetailContextValue } from '../../CustomerDetailPage';

import { AddEditContactDialogContent } from './components/AddEditContactDialogContent';
import { AddEditUserDialogContent } from './components/AddEditUserDialogContent';
import { UsersAndContactsListDialogContent } from './components/UsersAndContactsListDialogContent';


enum DialogType {
    ADD_CONTACT,
    EDIT_CONTACT,
    ADD_USER,
    EDIT_USER,
    LIST
}

const {
    ADD_CONTACT, EDIT_CONTACT, ADD_USER, EDIT_USER, LIST
} = DialogType;

const dialogTitles = {
    [ADD_CONTACT]: 'Add contact',
    [EDIT_CONTACT]: 'Edit contact',
    [ADD_USER]: 'Add user',
    [EDIT_USER]: 'Edit user',
    [LIST]: 'Users & contacts'
};

export type UserOrContact = 'user' | 'contact';

function isUser(item: Contact | User): item is User {
    return item.hasOwnProperty('roleIds');
}

function sortByPrimaryContact(contacts: Contact[]) {
    return contacts.sort((baseContact, comparedContact) => +comparedContact.isPrimary - +baseContact.isPrimary);
}

export default function UsersAndContactsDialog(props: Omit<RoutedDialogProps, 'title' | 'children' | 'onSubmit'>) {
    const [ dialogType, setDialogType ] = useState(LIST);
    const [ users, setUsers ] = useState<User[]>([]);
    const [ contacts, setContacts ] = useState<Contact[]>([]);
    const [ defaultUser, setDefaultUser ] = useState<User>();
    const [ defaultContact, setDefaultContact ] = useState<Contact>();
    const [ roles, setRoles ] = useState<Role[]>([]);
    const [ loading, setLoading ] = useState(false);

    const pageMessage = usePageMessage();
    const {
        customer, refreshCustomer, updateCustomer
    } = useContext(CustomerDetailContext) as Required<CustomerDetailContextValue>;

    const customerID = customer.id;
    const clientID = useGetCurrentAccount().id;

    const rolesContextValue = {
        entities: roles,
        setEntities: setRoles
    };

    useAsyncEffect(useCallback(async () => {
        try {
            setRoles(await api.roles.getRoles(clientID, UserType.CUSTOMER));
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching user roles', error);
        }
    }, [ pageMessage, clientID ]));

    useAsyncEffect(useCallback(async () => {
        if (customerID) {
            setLoading(true);

            try {
                setUsers(await api.users.getUsers(clientID, customerID));
                setContacts(
                    sortByPrimaryContact(await api.customer.contacts.getContacts(clientID, customerID))
                );
            } catch (error) {
                pageMessage.handleApiError('An error occurred while fetching users and contacts', error);
            }

            setLoading(false);
        }
    }, [
        clientID, customerID, pageMessage
    ]));

    function handleAddUser(newUser: User) {
        setUsers([ ...users, newUser ]);

        if (newUser.isActive) {
            refreshCustomer();
        }
        setDialogType(LIST);
    }

    function handleEditUser(editedUser: User) {
        setUsers(replaceItemById(users, editedUser));
        setDialogType(LIST);
    }

    function handleAddContact(newContact: Contact) {
        setContacts(contacts.concat(newContact));

        if (newContact.isPrimary) {
            refreshCustomer();
        }

        setDialogType(LIST);
    }

    function handleEditContact(editedContact: Contact) {
        setContacts(
            sortByPrimaryContact(contacts.map(contact => (
                contact.id === editedContact.id ? editedContact : ({
                    ...contact,
                    ...(editedContact.isPrimary && contact.isPrimary && {
                        isPrimary: false
                    })
                })
            )))
        );

        if (editedContact.isPrimary) {
            updateCustomer({
                ...customer,
                primaryContact: editedContact
            });
        }

        setDialogType(LIST);
    }

    function handleAddClick(type: UserOrContact) {
        if (type === 'user') {
            setDialogType(ADD_USER);
            setDefaultUser(undefined);
        } else {
            setDialogType(ADD_CONTACT);
            setDefaultContact(undefined);
        }
    }

    function handleEditClick(item: Contact | User) {
        if (isUser(item)) {
            setDialogType(EDIT_USER);
            setDefaultUser(item);
        } else {
            setDialogType(EDIT_CONTACT);
            setDefaultContact(item);
        }
    }

    function handleClose() {
        setDialogType(LIST);
        setDefaultUser(undefined);
        setDefaultContact(undefined);
    }

    return (
        <RolesContext.Provider value={rolesContextValue}>
            <RoutedDialog
                {...props}
                title={dialogTitles[dialogType]}
                onClose={handleClose}
                maxWidth={false}
                loading={loading}
            >
                {dialogType === LIST && (
                    <UsersAndContactsListDialogContent
                        users={users}
                        setUsers={setUsers}
                        contacts={contacts}
                        setContacts={setContacts}
                        onAddClick={handleAddClick}
                        onEditClick={handleEditClick}
                    />
                )}

                {(dialogType === ADD_USER || dialogType === EDIT_USER) && (
                    <AddEditUserDialogContent
                        user={defaultUser}
                        onAdd={handleAddUser}
                        onEdit={handleEditUser}
                        onCancel={() => setDialogType(LIST)}
                    />
                )}

                {(dialogType === ADD_CONTACT || dialogType === EDIT_CONTACT) && (
                    <AddEditContactDialogContent
                        defaultValues={defaultContact}
                        onAdd={handleAddContact}
                        onEdit={handleEditContact}
                        onCancel={() => setDialogType(LIST)}
                    />
                )}
            </RoutedDialog>
        </RolesContext.Provider>
    );
}
