import api, { Product } from '@api';
import {
    Paper, Typography
} from '@mui/material';
import { SubtleLink, usePageMessage } from '@tsp-ui/core/components';
import { replaceItemById } from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils/hooks';
import { ActivateDeactivateButton } from '@views/components/ActivateDeactivateButton';
import clsx from 'clsx';
import { MouseEventHandler, useContext, useState } from 'react';

import { ProductManagementPageContext } from '../ProductManagementPage';

import styles from './ProductCard.module.scss';


interface ProductCardProps {
    product: Product;
}

export default function ProductCard({ product }: ProductCardProps) {
    // TODO post-demo add a link with investors instead of chips
    const pageMessage = usePageMessage();
    const { entities, setEntities } = useContext(ProductManagementPageContext);
    const [ isDeleting, setIsDeleting ] = useState(false);
    const { id: clientId } = useGetCurrentAccount();

    const toggleActive: MouseEventHandler<HTMLButtonElement> = async event => {
        event.preventDefault();
        setIsDeleting(true);

        setEntities(replaceItemById(entities, await api.products.updateProduct(clientId, {
            ...product,
            isActive: !product.isActive
        })));

        const activationStatus = product.isActive ? 'deactivated' : 'activated';
        pageMessage.success(`Product ${activationStatus}`);

        setIsDeleting(false);
    };

    return (
        <Paper
            className={styles.root}
            elevation={0}
        >
            <Typography className={clsx(({ [styles.inactive]: !product.isActive }))}>
                <SubtleLink to={product.id}>
                    {product.productDescription}
                </SubtleLink>
            </Typography>

            <div className={styles.buttons}>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                    ({product.productCode})
                </Typography>

                <ActivateDeactivateButton
                    loading={isDeleting}
                    isActive={product.isActive}
                    toggleActive={toggleActive}
                />
            </div>
        </Paper>
    );
}
