import { ErrorOutline, ExpandLess, ExpandMore } from '@mui/icons-material';
import {
    IconButton, Paper, Tooltip, Typography
} from '@mui/material';
import { useState } from 'react';

import styles from './ExpandableErrorList.module.scss';


interface ExpandableErrorListProps {
    loanNumber: string;
    errorDetails: string[];
}

export function ExpandableErrorList({ loanNumber, errorDetails }: ExpandableErrorListProps) {
    const [ expanded, setExpanded ] = useState(false);

    return (
        <Paper
            variant="outlined"
            className={styles.root}
        >
            <div className={styles.mainRow}>
                {errorDetails.length > 1 && (
                    <Tooltip title={`${expanded ? 'Hide' : 'Show'} error details`}>
                        <IconButton
                            onClick={() => setExpanded(!expanded)}
                            disabled={errorDetails.length === 1}
                        >
                            {expanded ? (
                                <ExpandLess color="primary" />
                            ) : (
                                <ExpandMore color="primary" />
                            )}
                        </IconButton>
                    </Tooltip>
                )}

                <Typography
                    variant="body1"
                    className={styles.primaryText}
                >
                    Loan: {loanNumber}
                </Typography>

                <Typography
                    variant="body2"
                >
                    {errorDetails.length > 1 ? `${errorDetails.length} errors` : errorDetails[0]}
                </Typography>

                <ErrorOutline
                    fontSize="small"
                    color="error"
                />
            </div>

            {expanded && (
                <div className={styles.expandedRow}>
                    <ul>
                        {errorDetails.map((detail) => (
                            <li>
                                {detail}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </Paper>
    );
}
