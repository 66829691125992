import { LLPAVersionSummary } from '@api';
import { Button, Typography } from '@mui/material';
import { isFutureDate, isPastDate } from '@tsp-ui/core';
import { isCurrentLLPAVersion } from '@views/admin/llpas/components/LLPAVersionButton';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';


import LLPAVersionCard from './LLPAVersionCard';
import styles from './LLPAVersionsCard.module.scss';


interface LLPAVersionsCardProps {
    versions: LLPAVersionSummary[] | undefined;
    selectedVersionId: string | undefined;
    onVersionClick: () => void;
}

export default function LLPAVersionsCard({
    versions, selectedVersionId, onVersionClick
}: LLPAVersionsCardProps) {
    const { search } = useLocation();

    const currentVersion = versions?.find(isCurrentLLPAVersion);
    const futureVersions = versions?.filter(({ effectiveDate }) => isFutureDate(effectiveDate));
    const expiredVersions = versions?.filter(({ expirationDate }) => isPastDate(expirationDate));

    const [ showExpired, setShowExpired ] = useState(false);

    return (
        <div className={styles.root}>
            <Typography
                variant="body2"
                color="textSecondary"
            >
                Current
            </Typography>

            {currentVersion ? (
                <LLPAVersionCard
                    version={currentVersion}
                    selectedVersionId={selectedVersionId}
                    onClick={onVersionClick}
                />
            ) : (
                <Typography variant="body2">
                    There is no current version for this LLPA
                </Typography>
            )}

            <Typography
                variant="body2"
                component="div"
                className={styles.label}
            >
                Future
            </Typography>

            {futureVersions?.length ? (
                <div className={styles.guidelineContainer}>
                    {futureVersions.map(pendingVersion => (
                        <LLPAVersionCard
                            key={pendingVersion.id}
                            version={pendingVersion}
                            selectedVersionId={selectedVersionId}
                            onClick={onVersionClick}
                        />
                    ))}
                </div>
            ) : (
                <Typography variant="body2">
                    There are no future versions for this LLPA
                </Typography>
            )}

            {!!expiredVersions?.length && (
                <>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        component="div"
                        className={styles.label}
                    >
                        Expired
                    </Typography>

                    {showExpired ? (
                        <div className={styles.guidelineContainer}>
                            {expiredVersions.map(expiredGuideline => (
                                <LLPAVersionCard
                                    key={expiredGuideline.id}
                                    version={expiredGuideline}
                                    selectedVersionId={selectedVersionId}
                                    onClick={onVersionClick}
                                />
                            ))}
                        </div>
                    ) : (
                        <Button
                            size="small"
                            className={styles.button}
                            onClick={() => setShowExpired(!showExpired)}
                        >
                            View expired versions
                        </Button>
                    )}
                </>
            )}

            <div className={styles.addButtonContainer}>
                <Button
                    component={Link}
                    to={`versions/add${search}`}
                    variant="contained"
                    className={styles.addButton}
                    onClick={onVersionClick}
                >
                    Add new version
                </Button>
            </div>
        </div>
    );
}
