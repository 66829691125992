import {
    CreateLLPAReqBody, LLPA, LLPAVersionDetails, LLPAVersionSummary, LoanProperty, LoanPurpose, LoanType
} from '@api';
import { createDate } from '@tsp-ui/core/utils';
import { rest } from 'msw';


import { getMockUrl } from '../../mocks/getMockUrl';


let llpaId = 100;
let llpaVersionId = 100;

export const mocks = [
    rest.get(getMockUrl('/client/:clientID/llpa'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.json(llpaItems)
    )),
    rest.post(getMockUrl('/client/:clientID/llpa'), (req, res, ctx) => {
        const { version, ...body } = req.body as CreateLLPAReqBody;
        const newConfig: LLPA = {
            ...body,
            id: `${llpaId++}`,
            versions: [
                {
                    ...version,
                    id: `${llpaVersionId++}`
                }
            ]
        };

        llpaItems.push(newConfig);

        return res(
            ctx.status(200),
            ctx.json(newConfig)
        );
    }),
    rest.put(getMockUrl('/client/:clientID/llpa/:llpaId'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.json(llpaItems)
    )),
    rest.get(getMockUrl('/client/:clientId/llpa/:llpaId/version/:versionId'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.json(matrixDetails)
    )),
    rest.post(getMockUrl('/client/:clientId/llpa/:llpaId/version'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.json(req.body as LLPAVersionDetails)
    )),
    rest.put(getMockUrl('/client/:clientId/llpa/:llpaId/version/:versionId'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.json(req.body as LLPAVersionDetails)
    ))
];

const versions: LLPAVersionSummary[] = [
    {
        id: `${llpaVersionId++}`,
        countsTowardCap: true,
        effectiveDate: createDate(30).toISOString(),
        expirationDate: undefined,
        isGainOnSale: false,
        isSRP: false,
        versionNumber: '3'
    },
    {
        id: `${llpaVersionId++}`,
        countsTowardCap: true,
        effectiveDate: createDate(-30).toISOString(),
        expirationDate: createDate(30).toISOString(),
        isGainOnSale: false,
        isSRP: false,
        versionNumber: '2'
    },
    {
        id: `${llpaVersionId++}`,
        countsTowardCap: true,
        effectiveDate: createDate(-60).toISOString(),
        expirationDate: createDate(-30).toISOString(),
        isGainOnSale: false,
        isSRP: false,
        versionNumber: '1'
    }
];

export const llpaItems: LLPA[] = [
    {
        id: `${llpaId++}`,
        name: 'FNMA Standard Balance Products',
        description: 'Fannie mae adjustments based on loan amount',
        isCap: false,
        versions
    },
    {
        id: `${llpaId++}`,
        name: 'FNMA State/Escrow Adjustments',
        description: 'Fannie mae adjustments based on state and escrow status',
        isCap: false,
        versions
    },
    {
        id: `${llpaId++}`,
        name: 'FNMA HomeReady',
        description: 'Cumulative LLPA caps for HomeReady loans',
        isCap: true,
        versions
    }
];

const matrixDetails: LLPAVersionDetails = {
    columnMetadata: [
        {
            loanProperty: LoanProperty.LOAN_TYPE,
            isHighLevel: false,
            displayOrder: 0
        },
        {
            loanProperty: LoanProperty.PURPOSE,
            isHighLevel: false,
            displayOrder: 1
        }
    ],
    llpaCells: [
        {
            adjustment: '3',
            LOAN_TYPE: [ LoanType.CONVENTIONAL ],
            PURPOSE: [ LoanPurpose.PURCHASE ]
        },
        {
            adjustment: '4',
            LOAN_TYPE: [ LoanType.FHA ],
            PURPOSE: [ LoanPurpose.PURCHASE ]
        }
    ]
};
