import { ConditionPriorTo, LoanType, PremicorrConditionCategory } from '@api';
import { rest } from 'msw';

import { getMockUrl } from '../../mocks/getMockUrl';

import { ConditionConfig, ConditionConfigGroup } from './condition-config-api';


export const mocks = [
    rest.get(getMockUrl('/client/:clientID/condition-config'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.json(conditionConfigs)
    )),
    rest.put(getMockUrl('/client/:clientID/condition-config/:configId'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.json(req.body)
    )),
    rest.delete(getMockUrl('/client/:clientID/condition-config/:configId'), (req, res, ctx) => res(
        ctx.status(201),
        ctx.json(req.body)
    )),
    rest.post(getMockUrl('/client/:clientID/condition-config'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.json({
            ...req.body as ConditionConfig,
            id: String(conditionConfigId++)
        })
    )),
    rest.get(getMockUrl('/client/:clientID/condition-config/group'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.json(conditionConfigGroups)
    )),
    rest.put(getMockUrl('/client/:clientID/condition-config/group/:groupId'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.json(req.body)
    )),
    rest.delete(getMockUrl('/client/:clientID/condition-config/group/:groupId'), (req, res, ctx) => res(
        ctx.status(201),
        ctx.json(req.body)
    )),
    rest.post(getMockUrl('/client/:clientID/condition-config/group'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.json({
            ...req.body as ConditionConfigGroup,
            id: String(conditionConfigGroupId++)
        })
    ))
];

let conditionConfigId = 0;
let conditionConfigGroupId = 0;
const conditionConfigGroups: ConditionConfigGroup[] = [
    {
        id: String(conditionConfigGroupId++),
        type: 'loanType',
        loanTypes: [
            LoanType.CONVENTIONAL, LoanType.FHA, LoanType.VA
        ]
    },
    {
        id: String(conditionConfigGroupId++),
        type: 'loanProgram',
        loanProgramIds: [
            '0', '1', '2'
        ]
    }
];

const conditionConfigs: ConditionConfig[] = [
    {
        id: String(conditionConfigId++),
        requestedFrom: 'Some source',
        title: 'Some title',
        description: 'Some description',
        expectedDate: '2022-01-01',
        daysToReceive: 10,
        priorTo: ConditionPriorTo.CLOSING,
        category: PremicorrConditionCategory.ASSETS_AND_INCOME,
        conditionConfigGroupId: '1',
        allowToClear: true,
        printExternally: true,
        printInternally: true
    }
];
