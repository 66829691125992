import { EligibilityGuideline, PermissionType } from '@api';
import { Typography } from '@mui/material';
import { Button } from '@tsp-ui/core';
import { tooltipTitle } from '@utils';
import { useHasPermission } from '@utils/hooks';
import EditableSectionCard from '@views/admin/components/EditableSectionCard';
import styles from '@views/admin/investors/InvestorDetailPage/InternalInvestorDetailPage.module.scss';
import { ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';

import ExclusionsView from './ExclusionsView';


interface EligibilityExclusionsCardProps {
    exclusions: EligibilityGuideline[] | undefined;
    isSelectedVersionEditable: boolean | undefined;
    caption: ReactNode;
}

export default function EligibilityExclusionsCard({
    exclusions, isSelectedVersionEditable, caption
}: EligibilityExclusionsCardProps) {
    const { search } = useLocation();

    const hasExclusions = exclusions?.length;
    const [ canEditExclusions ] = useHasPermission([ PermissionType.MANAGE_ELIGIBILITY ]);

    const disableAdd = !canEditExclusions || !isSelectedVersionEditable;
    const tooltip = tooltipTitle({
        'You don\'t have permission to manage investor eligibility': !canEditExclusions,
        'Eligibility exclusions can only be edited for a future eligibility version': !isSelectedVersionEditable
    });

    return (
        <div className={styles.exclusions}>
            <EditableSectionCard
                header="Eligibility exclusions"
                editTo={`exclusions${search}`}
                readOnly={!hasExclusions}
                disableEdit={disableAdd}
                disabledTooltip={tooltip}
            >
                {!exclusions?.length ? (
                    <>
                        <Typography className={styles.noExclusions}>
                            This investor does not have any eligibility exclusions
                        </Typography>

                        <Button
                            component={Link}
                            to={`exclusions${search}`}
                            className={styles.button}
                            disabled={disableAdd}
                            tooltip={tooltip}
                        >
                            Add eligibility exclusions
                        </Button>
                    </>
                ) : (
                    <ExclusionsView exclusions={exclusions} />
                )}
            </EditableSectionCard>

            <Typography
                variant="caption"
                color="textSecondary"
                align="center"
                className={styles.caption}
            >
                {caption}
            </Typography>
        </div>
    );
}
