import {
    AgencyApproval,
    DueDiligenceStep,
    DueDiligenceStepType,
    InvestorRelationship,
    License,
    MICompanyRelationship,
    WarehouseRelationship
} from '@api';
import { Paper, Typography } from '@mui/material';
import { CardTable } from '@tsp-ui/core/components';

import AgenciesTableRow from './AgenciesTableRow';
import styles from './DueDiligenceHistoryCard.module.scss';
import { DueDiligenceEntity } from './DueDiligenceStepCard';
import InsurancesTableRow from './InsurancesTableRow';
import InvestorsTableRow from './InvestorsTableRow';
import LicensesTableRow from './LicensesTableRow';
import WarehousesTableRow from './WarehousesTableRow';


const {
    AGENCY, INSURANCE, INVESTORS, WAREHOUSE, STATE_LICENSE, CUSTOM
} = DueDiligenceStepType;

enum tableDataKeys {
    AGENCY = 'agencies',
    INSURANCE = 'insurances',
    INVESTORS = 'investors',
    WAREHOUSE = 'warehouses',
    STATE_LICENSE = 'state licenses',
    CUSTOM = ''
}

const tableHeaders = {
    [AGENCY]: [
        'Agency name',
        'First approval date',
        'Last approval date'
    ],
    [INSURANCE]: [
        'Contact',
        'Insurer name',
        'Policy number',
        'Email address',
        'Phone #'
    ],
    [INVESTORS]: [
        'Company name',
        'Contact name',
        'Email address',
        'Phone #'
    ],
    [WAREHOUSE]: [
        'Contact',
        'Lender name',
        'Line amount',
        'Email address',
        'Phone #'
    ],
    [STATE_LICENSE]: [
        'Type',
        'State',
        'License #',
        'Renewed through year'
    ],
    [CUSTOM]: null
};

interface DueDiligenceCardProps {
    item: DueDiligenceStep;
}

export default function DueDiligenceHistoryCard({ item }: DueDiligenceCardProps) {
    const tableDataKey = tableDataKeys[item.type];
    // TODO this will need to be refactored
    const tableItem: DueDiligenceEntity[] = [];
    // const tableItem = tableDataKey ? item[tableDataKey] : [];
    const headers = tableHeaders[item.type] || [];

    return (
        <Paper
            variant="outlined"
            className={styles.root}
        >
            <Typography className={styles.header}>
                {item.name}
            </Typography>

            <div className={styles.flex}>
                {tableItem?.length && (
                    <CardTable
                        headers={headers}
                        className={styles.scroll}
                    >
                        {tableItem.map(values => (tableDataKey === tableDataKeys.INVESTORS ? (
                            <InvestorsTableRow
                                key={(values as InvestorRelationship).companyName}
                                values={values as InvestorRelationship}
                            />
                        ) : tableDataKey === tableDataKeys.WAREHOUSE ? (
                            <WarehousesTableRow
                                key={(values as WarehouseRelationship).lenderName}
                                values={values as WarehouseRelationship}
                            />
                        ) : tableDataKey === tableDataKeys.AGENCY ? (
                            <AgenciesTableRow
                                key={(values as AgencyApproval).id}
                                values={values as AgencyApproval}
                            />
                        ) : tableDataKey === tableDataKeys.INSURANCE ? (
                            <InsurancesTableRow
                                key={(values as MICompanyRelationship).policyNumber}
                                values={values as MICompanyRelationship}
                            />
                        ) : tableDataKey === tableDataKeys.STATE_LICENSE ? (
                            <LicensesTableRow
                                key={(values as License).type}
                                values={values as License}
                            />
                        ) : null))}
                    </CardTable>
                )}

                {item.documents && (
                    <CardTable
                        headers={[ 'Attached documents' ]}
                        className={styles.documentsTable}
                    >
                        {item.documents?.map(({ url, id }) => (
                            <tr>
                                <Typography
                                    component="td"
                                    key={id}
                                    padding="4px"
                                    variant="body2"
                                    width="100%"
                                >
                                    {url /* TODO add document name, replace url here */}
                                </Typography>
                            </tr>
                        ))}
                    </CardTable>
                )}
            </div>

            {item.notes && (
                <div className={styles.fullWidth}>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                    >
                        Notes:
                    </Typography>

                    <Typography
                        variant="body2"
                    >
                        {item.notes}
                    </Typography>
                </div>
            )}
        </Paper>
    );
}
