import { DialogTitle, Slide } from '@mui/material';
import { RoutedDialog, RoutedDialogProps } from '@tsp-ui/core';
import clsx from 'clsx';
import React, { ReactNode } from 'react';

import styles from './RoutedFormDialog.module.scss';


interface RoutedFormDialogProps extends RoutedDialogProps {
    showForm: boolean;
    children: ReactNode;
    form: ReactNode;
    formTitle: string;
    classNames?: Partial<{
        formShown: string;
    }>;
}

export default function RoutedFormDialog({
    children, showForm, form, formTitle, classNames, ...props
}: RoutedFormDialogProps) {
    return (
        <RoutedDialog
            {...props}
            classes={{
                paper: clsx(props.classes?.paper, styles.paper, {
                    [styles.formShown]: showForm,
                    [classNames?.formShown || '']: showForm
                })
            }}
        >
            {children}

            <Slide
                in={showForm}
                direction="left"
            >
                <div className={styles.editFormContainer}>
                    <DialogTitle>
                        {formTitle}
                    </DialogTitle>

                    {form}
                </div>
            </Slide>
        </RoutedDialog>
    );
}
