import api, {
    ConditionConfig, PermissionType, premicorrConditionCategoryDisplay
} from '@api';
import {
    Edit, RemoveCircleOutline, RemoveRedEye
} from '@mui/icons-material';
import { Paper, Popover, Typography } from '@mui/material';
import {
    FilledSection, IconButton, LabelGroup, LabeledValue, formatDate, usePageMessage
} from '@tsp-ui/core';
import { useGetCurrentAccount, useHasPermission } from '@utils';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import { renderBoolean } from '../products/components/ProductDetailsCard';

import styles from './ConditionConfigCard.module.scss';
import { ConditionConfigContext } from './ConditionConfigPage';


interface LoanStatusConfigCardProps {
    conditionConfig: ConditionConfig;
}

export function ConditionConfigCard({ conditionConfig }: LoanStatusConfigCardProps) {
    const [ canManage ] = useHasPermission([ PermissionType.MANAGE_LOAN_STATUS_CONFIGS ]);
    const [ anchorEl, setAnchorEl ] = useState<HTMLElement>();
    const [ deleting, setDeleting ] = useState(false);
    const { id: clientId } = useGetCurrentAccount();
    const { setConditionConfigs } = useContext(ConditionConfigContext);
    const pageMessage = usePageMessage();

    return (
        <Paper
            className={styles.root}
            elevation={0}
        >
            <div>
                <Typography variant="body2">
                    {conditionConfig.title}
                </Typography>

                <Typography
                    variant="caption"
                    color="textSecondary"
                >
                    {conditionConfig.description}
                </Typography>
            </div>

            <span>
                <IconButton
                    tooltip="View loan status configuration"
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                >
                    <RemoveRedEye color="primary" />
                </IconButton>

                <IconButton
                    tooltip={canManage ? 'Edit loan condition configuration' : 'You do not have permission to edit loan condition configurations'}
                    disabled={!canManage}
                    component={Link}
                    to={`group/${conditionConfig.conditionConfigGroupId}/condition/${conditionConfig.id}`}
                >
                    <Edit color="secondary" />
                </IconButton>

                <IconButton
                    tooltip={canManage ? 'Delete loan condition configuration' : 'You do not have permission to delete loan condition configurations'}
                    disabled={!canManage}
                    onClick={async () => {
                        setDeleting(true);

                        try {
                            await api.conditionConfig.deleteConditionConfig(clientId, conditionConfig.id);

                            setConditionConfigs(
                                configs => configs.filter(config => config.id !== conditionConfig.id)
                            );

                            pageMessage.success('Loan condition configuration deleted');
                        } catch (error) {
                            pageMessage.handleApiError('An error occurred while deleting the loan condition configuration', error);
                        }

                        setDeleting(false);
                    }}
                    loading={deleting}
                >
                    <RemoveCircleOutline color="error" />
                </IconButton>
            </span>

            <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(undefined)}
                anchorOrigin={{
                    horizontal: 'left',
                    vertical: 'bottom'
                }}
                transformOrigin={{
                    horizontal: 'left',
                    vertical: 'top'
                }}
            >
                <div className={styles.popover}>
                    <FilledSection>
                        <LabelGroup>
                            {conditionConfig.requestedFrom && (
                                <LabeledValue
                                    label="Requested from:"
                                    value={conditionConfig.requestedFrom}
                                />
                            )}

                            {conditionConfig.source && (
                                <LabeledValue
                                    label="Source:"
                                    value={conditionConfig.source}
                                />
                            )}

                            <LabeledValue
                                label="Expected date:"
                                value={formatDate(conditionConfig.expectedDate)}
                            />

                            <LabeledValue
                                label="Days to receive:"
                                value={conditionConfig.daysToReceive}
                            />

                            <LabeledValue
                                label="Category:"
                                value={premicorrConditionCategoryDisplay[conditionConfig.category]}
                            />

                            <LabeledValue
                                label="Prior to:"
                                value={conditionConfig.priorTo}
                            />

                            <LabeledValue
                                label="Allow to clear:"
                                value={renderBoolean(conditionConfig.allowToClear)}
                            />

                            <LabeledValue
                                label="Print externally:"
                                value={renderBoolean(conditionConfig.printExternally)}
                            />

                            <LabeledValue
                                label="Print internally:"
                                value={renderBoolean(conditionConfig.printInternally)}
                            />
                        </LabelGroup>
                    </FilledSection>
                </div>
            </Popover>
        </Paper>
    );
}
