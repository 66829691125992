import { CommitmentLoanStatus } from '@api';
import { createDate, randomNum, replaceItemById } from '@tsp-ui/core/utils';
import { rest } from 'msw';

import { getMockUrl } from '../../mocks/getMockUrl';
import { createBatchFromReq } from '../loan/loan-mocks';

import { BulkCommitment, BulkCommitmentDetails } from './bulk-commitment-api';


let commitmentId = 10000;
let loanNumber = 71435423;

export const mocks = [
    rest.get(getMockUrl('/client/:clientId/bulk-commitment'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(commitments)
    ))),
    rest.get(getMockUrl('/client/:clientId/bulk-commitment/:commitmentId'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(commitments.find(({ id }) => id === req.params.commitmentId))
    ))),
    rest.post(getMockUrl('/client/:clientId/bulk-commitment'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json({
            ...(req.body as BulkCommitment),
            id: `${commitmentId++}`
        } as BulkCommitment)
    ))),
    rest.put(getMockUrl('/client/:clientId/bulk-commitment/:commitmentId'), (req, res, ctx) => {
        const newCommitment = req.body as BulkCommitmentDetails;
        commitments = replaceItemById(commitments, newCommitment);

        return res(
            ctx.status(200),
            ctx.json(newCommitment)
        );
    }),
    rest.post(getMockUrl('/client/:clientId/bulk-commitment/:commitmentId/upload'), (req, res, ctx) => {
        // TODO PREDEV-2455
        // timesUploaded++;
        const batch = createBatchFromReq(req);
        // const commitment = commitments.find(({ id }) => id === req.params.commitmentId)!;
        // const currentDelivered = commitment.pricingTiers.flatMap(({ loans }) => loans)
        //     .reduce((total, loan) => total + loan.loanAmount, 0);

        // setTimeout(() => {
        //     batch.files.forEach((file) => {
        //         const status = file.fileName.includes('ERR')
        //             ? UploadStatus.ERROR
        //             : UploadStatus.COMPLETE;
        //
        //         if (status === UploadStatus.COMPLETE) {
        //             commitment.pricingTiers[0].loans.push({
        //                 loanNumber: `${loanNumber++}`,
        //                 loanAmount: timesUploaded === 1
        //                     ? (commitment.tradeAmount - currentDelivered) / 2
        //                     : commitment.tradeAmount - currentDelivered,
        //                 lockDate: new Date().toISOString(),
        //                 lockExpiration: createDate(30).toISOString(),
        //                 status: CommitmentLoanStatus.DELIVERED
        //             });
        //         }
        //     });
        //
        //     api.webSocket.simulateUploadComplete({
        //         ...batch,
        //         commitmentId: commitment.id,
        //         status: batch.files.some(({ fileName }) => fileName.includes('ERR'))
        //             ? UploadStatus.ERROR
        //             : UploadStatus.COMPLETE
        //     });
        // }, 2000);

        return (res(
            ctx.status(200),
            ctx.json(batch)
        ));
    })
];

let commitments: BulkCommitmentDetails[] = [
    {
        ...getCommitment(),
        lockDate: createDate(-60).toISOString(),
        deliveryExpiration: createDate(-30).toISOString()
    },
    {
        ...getCommitment('2'),
        lockDate: createDate(-60).toISOString(),
        deliveryExpiration: createDate(-30).toISOString()
    },
    {
        ...getCommitment('3'),
        lockDate: createDate(-60).toISOString(),
        deliveryExpiration: createDate(-30).toISOString()
    },
    getCommitment(),
    getCommitment(),
    getCommitment(),
    getCommitment(),
    getCommitment('2'),
    getCommitment('2'),
    getCommitment('3')
];

function getCommitment(customerId: string = '1'): BulkCommitmentDetails {
    return {
        id: `${commitmentId++}`,
        customerId,
        deliveryExpiration: createDate(60).toISOString(),
        lockDate: createDate(30).toISOString(),
        lockWindow: 30,
        pricingTiers: [
            {
                productId: '1',
                noteRate: 6.375,
                price: 100.145,
                subLimit: undefined,
                loans: [
                    {
                        loanNumber: `${loanNumber++}`,
                        loanAmount: randomNum(300000, 800000, 2),
                        lockDate: createDate(-1).toISOString(),
                        lockExpiration: createDate(30).toISOString(),
                        status: CommitmentLoanStatus.DELIVERED
                    },
                    {
                        loanNumber: `${loanNumber++}`,
                        loanAmount: randomNum(300000, 800000, 2),
                        lockDate: createDate(-2).toISOString(),
                        lockExpiration: createDate(30).toISOString(),
                        status: CommitmentLoanStatus.PURCHASED
                    },
                    {
                        loanNumber: `${loanNumber++}`,
                        loanAmount: randomNum(300000, 800000, 2),
                        lockDate: createDate(-3).toISOString(),
                        lockExpiration: createDate(30).toISOString(),
                        status: CommitmentLoanStatus.SOLD
                    }
                ]
            },
            {
                productId: '2',
                noteRate: 6.500,
                price: 100.145,
                subLimit: 3000000,
                loans: [
                    {
                        loanNumber: `${loanNumber++}`,
                        loanAmount: randomNum(300000, 800000, 2),
                        lockDate: createDate(-4).toISOString(),
                        lockExpiration: createDate(30).toISOString(),
                        status: CommitmentLoanStatus.DELIVERED
                    },
                    {
                        loanNumber: `${loanNumber++}`,
                        loanAmount: randomNum(300000, 800000, 2),
                        lockDate: createDate(-5).toISOString(),
                        lockExpiration: createDate(30).toISOString(),
                        status: CommitmentLoanStatus.PURCHASED
                    },
                    {
                        loanNumber: `${loanNumber++}`,
                        loanAmount: randomNum(300000, 800000, 2),
                        lockDate: createDate(-6).toISOString(),
                        lockExpiration: createDate(30).toISOString(),
                        status: CommitmentLoanStatus.SOLD
                    }
                ]
            },
            {
                productId: '3',
                noteRate: 6.625,
                price: 100.145,
                subLimit: 2000000,
                loans: [
                    {
                        loanNumber: `${loanNumber++}`,
                        loanAmount: randomNum(300000, 800000, 2),
                        lockDate: createDate(-7).toISOString(),
                        lockExpiration: createDate(30).toISOString(),
                        status: CommitmentLoanStatus.DELIVERED
                    },
                    {
                        loanNumber: `${loanNumber++}`,
                        loanAmount: randomNum(300000, 800000, 2),
                        lockDate: createDate(-8).toISOString(),
                        lockExpiration: createDate(30).toISOString(),
                        status: CommitmentLoanStatus.PURCHASED
                    },
                    {
                        loanNumber: `${loanNumber++}`,
                        loanAmount: randomNum(300000, 800000, 2),
                        lockDate: createDate(-9).toISOString(),
                        lockExpiration: createDate(30).toISOString(),
                        status: CommitmentLoanStatus.SOLD
                    }
                ]
            }
        ],
        tradeAmount: 6000000,
        tradeIncentive: 0.025,
        tradeVariance: 1.000
    };
}
