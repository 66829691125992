import {
    ActiveInactiveEnum,
    ApprovalStatusEnum,
    CommitmentType,
    ContactResponsibility,
    ContactTitle,
    CustomerAction,
    CustomerCompensationType,
    CustomerStatus,
    DueDiligenceStepType,
    FeeAmountType,
    FeeType,
    LockPeriod,
    ProductType,
    State,
    UnderwritingType,
    User,
    UserType
} from '@api';
import { createDate } from '@tsp-ui/core';
import { rest } from 'msw';

import { getMockUrl } from '../../../mocks/getMockUrl';
import { mockedProducts } from '../../products/products-mocks';
import { branches } from '../branches/branches-mocks';
import { customerFeesComp } from '../compensation/compensation-mocks';
import { CustomerDetails } from '../customer-api';
import { SAMPLE_PDF_URL, dueDiligenceSteps } from '../due-diligence-step/due-diligence-step-mocks';
import { mockedProductTypeConfigs } from '../eligibleProducts/eligibleProducts-mocks';
import { licenses } from '../licenses/licenses-mocks';
import { wireInstructionsSet } from '../wireInfo/wireInfo-mocks';

import { CustomerHistoryEvent, setMockHistoryEvents } from './eventHistory-api';


export const mocks = [
    rest.get(getMockUrl('/customer/:id/eventHistory'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(historyEvents)
    )))
];

const historyCustomer: CustomerDetails = {
    id: '1',
    name: 'American Bank',
    nmlsId: '123456',
    taxId: '1234567890',
    address: branches[0].address,
    dbaNames: [ 'AmCap Mortgage, Ltd.', 'American Bank LTD' ],
    primaryContact: {
        id: '1',
        responsibilities: [ ContactResponsibility.ACCOUNTING ],
        name: 'P. Contact',
        phone: '123-456-7890',
        email: 'primarycontact@email.com',
        title: ContactTitle.SVP,
        isPrimary: false
    },
    status: CustomerStatus.PENDING,
    productTypeConfigs: mockedProductTypeConfigs,
    eligibleProductIds: mockedProducts.map(({ id }) => id.toString())
};

const historyUser: User = {
    id: '1',
    firstName: 'Joeseph',
    lastName: 'Schmo',
    email: 'joe@shmos-mortgage.com',
    roleIds: [ '1', '2' ],
    isActive: true,
    phone: null,
    type: UserType.CUSTOMER
};

const historyEvents: CustomerHistoryEvent[] = [
    {
        id: '1',
        action: CustomerAction.CUSTOMER_DETAILS_UPDATED,
        executedBy: historyUser,
        date: '2020-12-12T09:08:12.496Z',
        details: {
            before: historyCustomer,
            after: {
                ...historyCustomer,
                dbaNames: undefined
            }
        }
    }, {
        id: '2',
        action: CustomerAction.CONTACT_UPDATED,
        executedBy: historyUser,
        date: '2021-04-05T16:18:06.526Z',
        details: {
            before: {
                id: '1',
                responsibilities: [ ContactResponsibility.COMPLIANCE ],
                name: 'Joeseph',
                phone: '094-234-9465',
                email: 'joe@shmos-mortgage.com',
                title: ContactTitle.SVP,
                isPrimary: false
            },
            after: {
                id: '1',
                responsibilities: [ ContactResponsibility.COMPLIANCE ],
                name: 'Johnathan',
                phone: '094-234-9465',
                email: 'john@doe-mortgage.com',
                title: ContactTitle.SVP,
                isPrimary: false
            }
        }
    }, {
        id: '3',
        action: CustomerAction.FEE_DISABLED,
        executedBy: historyUser,
        date: '2021-10-17T05:12:43.123Z',
        details: {
            after: {
                id: '1',
                customerId: '5234',
                purchaseAdviceFeeId: '1',
                type: FeeType['203K_SUPPLEMENTAL_FEE'],
                amountType: FeeAmountType.FIXED,
                isActive: true
            }
        }
    }, {
        id: '5',
        action: CustomerAction.WIRE_INSTRUCTIONS_APPROVED,
        executedBy: historyUser,
        date: '2021-11-01T02:10:18.456Z',
        details: {
            after: wireInstructionsSet[0]
        }
    }, {
        id: '6',
        action: CustomerAction.LICENSE_ADDED,
        executedBy: historyUser,
        date: '2021-11-01T02:10:18.456Z',
        details: {
            after: licenses[0]
        }
    }, {
        id: '7',
        action: CustomerAction.LICENSE_UPDATED,
        executedBy: historyUser,
        date: '2021-11-01T02:10:18.456Z',
        details: {
            before: licenses[0],
            after: licenses[1]
        }
    }, {
        id: '8',
        action: CustomerAction.DUE_DILIGENCE_ITEM_COMPLETED,
        executedBy: historyUser,
        date: '2021-11-01T02:10:18.456Z',
        details: {
            after: dueDiligenceSteps[0]
        }
    }, {
        id: '9',
        action: CustomerAction.BRANCH_ADDED,
        executedBy: historyUser,
        date: '2021-11-01T02:10:18.456Z',
        details: {
            after: branches[0]
        }
    }, {
        id: '10',
        action: CustomerAction.FEE_ADJUSTED,
        executedBy: historyUser,
        date: '2021-11-01T02:10:18.456Z',
        details: {
            before: customerFeesComp.fees![0],
            after: customerFeesComp.fees![1]
        }
    }, {
        id: '11',
        action: CustomerAction.COMPENSATION_DETAILS_UPDATED,
        executedBy: historyUser,
        date: '2021-11-01T02:10:18.456Z',
        details: {
            before: customerFeesComp.compensation,
            after: {
                id: '1',
                amount: 12000,
                firstLienOnly: false,
                isActive: true,
                type: CustomerCompensationType.FIXED,
                notes: 'N/A'
            }
        }
    }, {
        id: '12',
        action: CustomerAction.BRANCH_ADDED,
        executedBy: historyUser,
        date: '2020-12-12T09:08:12.496Z',
        details: {
            after: {
                id: '45',
                nmlsId: '12345',
                address: {
                    street: '123 E St',
                    line2: '#301',
                    city: 'Phoenix',
                    state: State.AZ,
                    zip: '85250'
                },
                isActive: true
            }
        }
    }, {
        id: '14',
        action: CustomerAction.ELIGIBLE_PRODUCTS_TYPE_UPDATED,
        executedBy: historyUser,
        date: '2020-12-12T09:08:12.496Z',
        details: {
            before: {
                id: '1',
                productType: ProductType.CONVENTIONAL,
                underwritingType: UnderwritingType.DELEGATED,
                allowedCommitmentTypes: [ CommitmentType.BEST_EFFORT, CommitmentType.MANDATORY ],
                allowedLockPeriods: [
                    LockPeriod.FIFTEEN, LockPeriod.THIRTY, LockPeriod.SIXTY
                ],
                isEnabled: true
            },
            after: {
                id: '1',
                productType: ProductType.CONVENTIONAL,
                underwritingType: UnderwritingType.DELEGATED,
                allowedCommitmentTypes: [ CommitmentType.BEST_EFFORT, CommitmentType.MANDATORY ],
                allowedLockPeriods: [
                    LockPeriod.FIFTEEN, LockPeriod.THIRTY, LockPeriod.SIXTY
                ],
                isEnabled: true
            }
        }
    }, {
        id: '15',
        action: CustomerAction.ELIGIBLE_PRODUCTS_UW_CATEGORY_DISABLED,
        executedBy: historyUser,
        date: '2020-12-12T09:08:12.496Z',
        details: {
            after: {
                type: UnderwritingType.DELEGATED,
                products: [
                    '10 year conventional', '20 year conventional', '10 year jumbo', '10 year VA'
                ]
            }
        }
    }, {
        id: '16',
        action: CustomerAction.ELIGIBLE_PRODUCTS_TYPE_DISABLED,
        executedBy: historyUser,
        date: '2020-12-12T09:08:12.496Z',
        details: {
            after: {
                type: ProductType.CONVENTIONAL,
                products: [
                    '10 year conventional', '20 year conventional', '30 year conventional'
                ]
            }
        }
    }, {
        id: '17',
        action: CustomerAction.ELIGIBLE_PRODUCTS_PRODUCT_ENABLED,
        executedBy: historyUser,
        date: '2020-12-12T09:08:12.496Z',
        details: {
            after: {
                product: '10 year conventional'
            }
        }
    }, {
        id: '18',
        action: CustomerAction.DUE_DILIGENCE_ITEM_UPDATED,
        executedBy: historyUser,
        date: '2020-12-12T09:08:12.496Z',
        details: {
            before: {
                customerId: '1',
                id: '7',
                dueDiligenceConfigId: '1',
                displayOrder: 1,
                status: ActiveInactiveEnum.ACTIVE,
                approvalStatus: ApprovalStatusEnum.IN_PROGRESS,
                name: 'Investor Relationships',
                description: 'This is where you fill out investor relationships',
                type: DueDiligenceStepType.INVESTORS,
                isRequired: true,
                isCompleted: false,
                completedDate: '',
                notes: '',
                documents: [
                    {
                        id: '1',
                        // name: 'Document 1.pdf', // TODO add document name
                        url: SAMPLE_PDF_URL,
                        preSignedUrl: SAMPLE_PDF_URL,
                        expiration: createDate(.001).toISOString() // 1.44 minutes from now
                    },
                    {
                        id: '2',
                        // name: 'Document 2.pdf', // TODO add document name
                        url: SAMPLE_PDF_URL,
                        preSignedUrl: SAMPLE_PDF_URL,
                        expiration: createDate(.001).toISOString()
                    },
                    {
                        id: '3',
                        // name: 'Document 3.pdf', // TODO add document name
                        url: SAMPLE_PDF_URL,
                        preSignedUrl: SAMPLE_PDF_URL,
                        expiration: createDate(.001).toISOString()
                    }
                ],
                isDocRequired: true
            },
            after: {
                customerId: '1',
                id: '7',
                dueDiligenceConfigId: '1',
                displayOrder: 1,
                status: ActiveInactiveEnum.ACTIVE,
                approvalStatus: ApprovalStatusEnum.IN_PROGRESS,
                name: 'Investor Relationships',
                description: 'This is where you fill out investor relationships',
                type: DueDiligenceStepType.INVESTORS,
                isRequired: true,
                isCompleted: false,
                completedDate: '',
                notes: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                documents: [
                    {
                        id: '1',
                        // name: 'Document 1.pdf', // TODO add document name
                        url: SAMPLE_PDF_URL,
                        preSignedUrl: SAMPLE_PDF_URL,
                        expiration: createDate(.001).toISOString()
                    },
                    {
                        id: '2',
                        // name: 'Document 2.pdf', // TODO add document name
                        url: SAMPLE_PDF_URL,
                        preSignedUrl: SAMPLE_PDF_URL,
                        expiration: createDate(.001).toISOString()
                    },
                    {
                        id: '3',
                        // name: 'Document 3.pdf', // TODO add document name
                        url: SAMPLE_PDF_URL,
                        preSignedUrl: SAMPLE_PDF_URL,
                        expiration: createDate(.001).toISOString()
                    }
                ],
                isDocRequired: true
            }
        }
    }, {
        id: '19',
        action: CustomerAction.DUE_DILIGENCE_DOCUMENT_UPLOADED,
        executedBy: historyUser,
        date: '2020-12-12T09:08:12.496Z',
        details: {
            before: {
                customerId: '1',
                id: '7',
                name: 'Agency Approval',
                dueDiligenceConfigId: '1',
                displayOrder: 1,
                status: ActiveInactiveEnum.ACTIVE,
                approvalStatus: ApprovalStatusEnum.IN_PROGRESS,
                description: 'This is where you fill out investor relationships',
                type: DueDiligenceStepType.AGENCY,
                isRequired: true,
                isCompleted: false,
                completedDate: '',
                notes: '',
                isDocRequired: true
            },
            after: {
                customerId: '1',
                id: '7',
                name: 'Agency Approval',
                dueDiligenceConfigId: '1',
                displayOrder: 1,
                status: ActiveInactiveEnum.ACTIVE,
                approvalStatus: ApprovalStatusEnum.IN_PROGRESS,
                description: 'This is where you fill out investor relationships',
                type: DueDiligenceStepType.AGENCY,
                isRequired: true,
                isCompleted: false,
                completedDate: '',
                notes: '',
                documents: [
                    {
                        id: '1',
                        // name: 'Document 1.pdf', // TODO add document name
                        url: SAMPLE_PDF_URL,
                        preSignedUrl: SAMPLE_PDF_URL,
                        expiration: createDate(.001).toISOString()
                    }
                ],
                isDocRequired: true
            }
        }
    }, {
        id: '20',
        action: CustomerAction.DUE_DILIGENCE_DOCUMENT_UPLOADED,
        executedBy: historyUser,
        date: '2020-12-12T09:08:12.496Z',
        details: {
            before: {
                customerId: '1',
                id: '7',
                name: 'Insurance',
                description: 'This is where you fill out investor relationships',
                type: DueDiligenceStepType.INSURANCE,
                dueDiligenceConfigId: '1',
                displayOrder: 1,
                status: ActiveInactiveEnum.ACTIVE,
                approvalStatus: ApprovalStatusEnum.IN_PROGRESS,
                isRequired: true,
                isCompleted: false,
                completedDate: '',
                notes: '',
                isDocRequired: true
            },
            after: {
                customerId: '1',
                id: '7',
                name: 'Insurance',
                description: 'This is where you fill out investor relationships',
                type: DueDiligenceStepType.INSURANCE,
                isRequired: true,
                isCompleted: false,
                completedDate: '',
                notes: '',
                dueDiligenceConfigId: '1',
                displayOrder: 1,
                status: ActiveInactiveEnum.ACTIVE,
                approvalStatus: ApprovalStatusEnum.IN_PROGRESS,
                documents: [
                    {
                        id: '1',
                        // name: 'Document 1.pdf', // TODO add document name
                        url: SAMPLE_PDF_URL,
                        preSignedUrl: SAMPLE_PDF_URL,
                        expiration: createDate(.001).toISOString()
                    }
                ],
                isDocRequired: true
            }
        }
    }, {
        id: '21',
        action: CustomerAction.DUE_DILIGENCE_DOCUMENT_UPLOADED,
        executedBy: historyUser,
        date: '2020-12-12T09:08:12.496Z',
        details: {
            before: {
                id: '7',
                customerId: '1',
                dueDiligenceConfigId: '1',
                displayOrder: 1,
                status: ActiveInactiveEnum.ACTIVE,
                approvalStatus: ApprovalStatusEnum.IN_PROGRESS,
                documents: [],
                name: 'Warehouses',
                description: 'This is where you fill out investor relationships',
                type: DueDiligenceStepType.WAREHOUSE,
                isRequired: true,
                isCompleted: false,
                completedDate: '',
                notes: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                isDocRequired: true
            },
            after: {
                customerId: '1',
                id: '7',
                name: 'Warehouses',
                description: 'This is where you fill out investor relationships',
                type: DueDiligenceStepType.WAREHOUSE,
                isRequired: true,
                dueDiligenceConfigId: '1',
                displayOrder: 1,
                status: ActiveInactiveEnum.ACTIVE,
                approvalStatus: ApprovalStatusEnum.APPROVED,
                isCompleted: false,
                completedDate: '',
                notes: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                documents: [
                    {
                        id: '1',
                        // name: 'Document 1.pdf', // TODO add document name
                        url: SAMPLE_PDF_URL,
                        preSignedUrl: SAMPLE_PDF_URL,
                        expiration: createDate(.001).toISOString()
                    }
                ],
                isDocRequired: true
            }
        }
    }, {
        id: '22',
        action: CustomerAction.USER_UPDATED,
        executedBy: historyUser,
        date: '2020-12-12T09:08:12.496Z',
        details: {
            before: historyUser,
            after: historyUser
        }
    }
];

setMockHistoryEvents(historyEvents); // TODO post-demo remove this
