import { EligibilityVersion } from '@api';
import { Button, Link as MuiLink, Typography } from '@mui/material';
import { isFutureDate, isPastDate, isToday } from '@tsp-ui/core';
import { useState } from 'react';
import { Link } from 'react-router-dom';


import EligibilityVersionCard from './EligibilityVersionCard';
import styles from './EligibilityVersionsCard.module.scss';


interface EligibilityVersionsCardProps {
    versions: EligibilityVersion[] | undefined;
    selectedVersionId: string | undefined;
    onVersionClick: () => void;
}

export default function EligibilityVersionsCard({
    versions, selectedVersionId, onVersionClick
}: EligibilityVersionsCardProps) {
    const activeVersion = versions?.find(isActiveVersion);
    const futureVersions = versions?.filter(({ effectiveDate }) => isFutureDate(effectiveDate));
    const expiredVersions = versions?.filter(({ expirationDate }) => isPastDate(expirationDate));

    const [ showExpired, setShowExpired ] = useState(false);

    return (
        <div className={styles.root}>
            <Typography
                variant="body2"
                color="textSecondary"

            >
                Current
            </Typography>

            {activeVersion ? (
                <EligibilityVersionCard
                    version={activeVersion}
                    selectedVersionId={selectedVersionId}
                    onClick={onVersionClick}
                />
            ) : (
                <Typography variant="body2">
                    There are no current eligibility versions.&nbsp;

                    <MuiLink
                        component={Link}
                        to="guidelines/new"
                    >
                        Click here
                    </MuiLink> to create new ones.
                </Typography>
            )}

            <Typography
                variant="body2"
                component="div"
                className={styles.label}
            >
                Future
            </Typography>

            {futureVersions?.length ? (
                <div className={styles.guidelineContainer}>
                    {futureVersions.map(pendingVersion => (
                        <EligibilityVersionCard
                            key={pendingVersion.id}
                            version={pendingVersion}
                            selectedVersionId={selectedVersionId}
                            onClick={onVersionClick}
                        />
                    ))}
                </div>
            ) : (
                <Typography variant="body2">
                    There are no future eligibility versions.
                </Typography>
            )}

            {!!expiredVersions?.length && (
                <>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        component="div"
                        className={styles.label}
                    >
                        Expired
                    </Typography>

                    {showExpired ? (
                        <div className={styles.guidelineContainer}>
                            {expiredVersions.map(expiredGuideline => (
                                <EligibilityVersionCard
                                    key={expiredGuideline.id}
                                    version={expiredGuideline}
                                    selectedVersionId={selectedVersionId}
                                    onClick={onVersionClick}
                                />
                            ))}
                        </div>
                    ) : (
                        <Button
                            size="small"
                            className={styles.button}
                            onClick={() => setShowExpired(!showExpired)}
                        >
                            View expired versions
                        </Button>
                    )}
                </>
            )}
        </div>
    );
}

export function isActiveVersion(version: EligibilityVersion | undefined) {
    return version && (
        isPastDate(version.effectiveDate) || isToday(version.effectiveDate)
    ) && (
        !version.expirationDate || isFutureDate(version.expirationDate)
    );
}
