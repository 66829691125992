import { apiUtils } from '../api-utils';


export function getLoanStatusConfigs(clientId: string): Promise<LoanStatusConfig[]> {
    return apiUtils.get(`/client/${clientId}/loan-status/config`);
}

export function getLOSLoanStatuses(clientId: string): Promise<LOSLoanStatus[]> {
    return apiUtils.get(`/client/${clientId}/loan-status`);
}

export function createLoanStatusConfig(clientId: string, LoanStatusConfigToCreate: Omit<LoanStatusConfig, 'id'>): Promise<LoanStatusConfig> {
    return apiUtils.post(`/client/${clientId}/loan-status/config`, LoanStatusConfigToCreate);
}

export function updateLoanStatusConfig(
    clientId: string, loanStatusConfigToUpdate: LoanStatusConfig
): Promise<LoanStatusConfig> {
    return apiUtils.put(`/client/${clientId}/loan-status/config/${loanStatusConfigToUpdate.id}`, loanStatusConfigToUpdate);
}

export function deleteLoanStatusConfig(clientId: string, loanStatusConfigId: number): Promise<void> {
    return apiUtils.delete(`/client/${clientId}/loan-status/config/${loanStatusConfigId}`);
}

export interface LoanStatusConfig {
    id: number;
    title: string;
    losLoanStatusIds: string[];
    displayOrder: number;
}

export interface LOSLoanStatus {
    id: string;
    name: string;
}
