import api, { CustomerWireInfo, PermissionType } from '@api';
import {
    CheckCircleOutline, Edit, ExpandLess, ExpandMore, RemoveCircleOutline
} from '@mui/icons-material';
import { Paper, Tooltip, Typography } from '@mui/material';
import { IconButton, LabelGroup, LabeledValue } from '@tsp-ui/core/components';
import { useConfirm, usePageMessage } from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils/hooks';
import { useHasPermission } from '@utils/hooks/useHasPermission';
import {
    Dispatch, SetStateAction, useContext, useState
} from 'react';

import { CustomerDetailContext, CustomerDetailContextValue } from '../../../CustomerDetailPage';
import { WireInstructionsListContentProps } from '../WireInstructionsListContent';

import styles from './WireInstructionsCard.module.scss';


type WireInstructionsCardProps =
(WireInstructionsListContentProps & {
    wireInstructions: CustomerWireInfo;
    readOnly?: boolean;
    setLoading: Dispatch<SetStateAction<boolean>>;
});

export default function WireInstructionsCard({
    wireInstructions,
    wireInstructionsArray,
    onApprove,
    onToggleActive,
    setWireInstructionsToEdit,
    readOnly,
    setLoading
}: WireInstructionsCardProps) {
    const [ expanded, setExpanded ] = useState(false);

    const pageMessage = usePageMessage();
    const confirm = useConfirm();
    const { id: clientID } = useGetCurrentAccount();

    const {
        refreshCustomer
    } = useContext(CustomerDetailContext) as Required<CustomerDetailContextValue>;

    async function toggleActive() {
        try {
            if (!wireInstructions.isActive || await confirm('Are you sure you want to deactivate this wire instruction?')) {
                setLoading(true);

                await api.customer.wireInfo.updateWireInfo(clientID, {
                    ...wireInstructions,
                    isActive: !wireInstructions.isActive
                });

                onToggleActive(wireInstructions.id);

                const length = wireInstructionsArray?.filter(x => x.isActive && x.isApproved).length;
                const deactivatingLastItem = length === 1 && wireInstructions.isActive;
                const activatingFirstItem = length === 0 && !wireInstructions.isActive;

                if (deactivatingLastItem || activatingFirstItem) {
                    await refreshCustomer();
                }

                pageMessage.success(`Wire instructions ${wireInstructions.isActive ? 'de' : ''}activated`);
            }
        } catch (error) {
            const action = wireInstructions.isActive ? 'deactivating' : 'activating';
            pageMessage.handleApiError(`An error occurred while ${action} the wire instructions`, error);
        }

        setLoading(false);
    }

    async function approve() {
        setLoading(true);

        try {
            await api.customer.wireInfo.updateWireInfo(clientID, {
                ...wireInstructions,
                isApproved: true
            });

            onApprove(wireInstructions.id);

            if (wireInstructionsArray?.filter(x => x.isActive && x.isApproved).length === 0) {
                await refreshCustomer();
            }

            pageMessage.success('Wire instructions approved');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while approving the wire instructions', error);
        }

        setLoading(false);
    }

    const {
        isActive,
        bankName,
        bankCity,
        bankState,
        isApproved,
        bankAccountNum,
        bankAba,
        wireDestination,
        furtherCreditToAcctName,
        furtherCreditToAcctNum
    } = wireInstructions;

    const [ canEditCustomerWireInfo ] = useHasPermission([ PermissionType.EDIT_WIRE_INFO ]);

    return (
        <Paper
            variant="outlined"
            className={styles.root}
        >
            <div className={styles.row}>
                <div>
                    <Typography>
                        {bankName}
                    </Typography>

                    <Typography
                        variant="body2"
                        component="p"
                    >
                        {`${bankCity}, ${bankState}`}
                    </Typography>
                </div>

                <div className={styles.actionsContainer}>
                    {isActive && !readOnly && (
                        <IconButton
                            tooltip={canEditCustomerWireInfo ? 'Deactivate' : 'You do not have permission to deactivate wire instructions'}
                            onClick={toggleActive}
                            disabled={!canEditCustomerWireInfo}
                        >
                            <RemoveCircleOutline color="error" />
                        </IconButton>
                    )}

                    {!readOnly && (
                        <IconButton
                            tooltip={canEditCustomerWireInfo ? 'Edit' : 'You do not have permission to edit wire instructions'}
                            onClick={() => setWireInstructionsToEdit(wireInstructions)}
                            disabled={!canEditCustomerWireInfo}
                        >
                            <Edit color="secondary" />
                        </IconButton>
                    )}

                    {!isApproved && isActive && !readOnly && (
                        <IconButton
                            tooltip={canEditCustomerWireInfo ? 'Approve' : 'You do not have permission to approve wire instructions'}
                            onClick={approve}
                            disabled={!canEditCustomerWireInfo}
                        >
                            <CheckCircleOutline color="success" />
                        </IconButton>
                    )}

                    {!isActive && !readOnly && (
                        <IconButton
                            tooltip={canEditCustomerWireInfo ? 'Activate' : 'You do not have permission to activate wire instructions'}
                            onClick={toggleActive}
                            disabled={!canEditCustomerWireInfo}
                        >
                            <CheckCircleOutline color="success" />
                        </IconButton>
                    )}
                </div>
            </div>

            <div className={styles.row}>
                <LabelGroup>
                    <LabeledValue
                        label="Account number:"
                        value={bankAccountNum}
                    />

                    <LabeledValue
                        label="ABA number:"
                        value={bankAba}
                    />

                    {expanded && (
                        <>
                            <LabeledValue
                                label="Wire destination:"
                                value={wireDestination}
                                classNames={{ label: styles.additionalInfoMargin }}
                            />

                            {(furtherCreditToAcctName || furtherCreditToAcctNum) && (
                                <LabeledValue
                                    label="Further credit to:"
                                    value={(
                                        <>
                                            {furtherCreditToAcctName}<br />

                                            {furtherCreditToAcctNum}
                                        </>
                                    )}
                                />
                            )}
                        </>
                    )}
                </LabelGroup>

                <Tooltip title={`Show ${expanded ? 'less' : 'more'}`}>
                    <IconButton
                        edge="end"
                        className={styles.expandButton}
                        onClick={() => setExpanded(!expanded)}
                    >
                        {expanded ? (
                            <ExpandLess color="secondary" />
                        ) : (
                            <ExpandMore color="secondary" />
                        )}
                    </IconButton>
                </Tooltip>
            </div>
        </Paper>
    );
}
