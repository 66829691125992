import { rest } from 'msw';

import {
    ConditionEvent,
    ConditionExceptionStatus,
    LoanRoleAssignment,
    LoanStatusNonDelegated,
    NotificationFrequency,
    NotificationMethod,
    NotificationType
} from '../..';
import { getMockUrl } from '../../../mocks/getMockUrl';

import { NotificationConfig } from './notifications-api';


export const mocks = [
    // Get notifications
    rest.get(getMockUrl('/role/:roleID/notification'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(notifications)
    ))),
    rest.get(getMockUrl('/client/:clientID/role/:roleID/notification'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(notifications)
    ))),

    // Add notification
    rest.post(getMockUrl('/role/:roleID/notification'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json({
            ...req.body as NotificationConfig,
            id: `${notificationID++}`
        })
    ))),
    rest.post(getMockUrl('/client/:clientID/role/:roleID/notification'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json({
            ...req.body as NotificationConfig,
            id: `${notificationID++}`
        })
    ))),

    // Edit notification
    rest.put(getMockUrl('/role/:roleID/notification/:notificationID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(req.body)
    ))),
    rest.put(getMockUrl('/client/:clientID/role/:roleID/notification/:notificationID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(req.body)
    ))),

    // Delete notification
    rest.delete(getMockUrl('/role/:roleID/notification/:notificationID'), (req, res, ctx) => (res(
        ctx.status(200)
    ))),
    rest.delete(getMockUrl('/client/:clientID/role/:roleID/notification/:notificationID'), (req, res, ctx) => (res(
        ctx.status(200)
    )))
];
let notificationID = 0;
// Get subscribed notifications for Client Underwriter role
const notifications: NotificationConfig[] = [
    {
        id: `${notificationID++}`,
        roleId: '6',
        type: NotificationType.LOAN_STATUS_NON_DELEGATED,
        parameters: [ LoanStatusNonDelegated.IN_UNDERWRITING ],
        methods: [ NotificationMethod.EMAIL ],
        frequency: NotificationFrequency.DAILY,
        reminderFrequency: NotificationFrequency.DAILY,
        includeUnassigned: false
    },
    {
        id: `${notificationID++}`,
        roleId: '6',
        type: NotificationType.CONDITION_EVENT,
        parameters: [ ConditionEvent.RESPONSIBLE_PARTY_CHANGES ],
        methods: [ NotificationMethod.IN_APP, NotificationMethod.EMAIL ],
        frequency: NotificationFrequency.IMMEDIATELY,
        reminderFrequency: NotificationFrequency.DAILY,
        includeUnassigned: true
    },
    {
        id: `${notificationID++}`,
        roleId: '6',
        type: NotificationType.CONDITION_EXCEPTION_STATUS,
        parameters: [ ConditionExceptionStatus.APPROVED_BY_INVESTOR ],
        methods: [ NotificationMethod.IN_APP ],
        frequency: NotificationFrequency.WEEKLY,
        reminderFrequency: NotificationFrequency.WEEKLY,
        includeUnassigned: true
    },
    {
        id: `${notificationID++}`,
        roleId: '6',
        type: NotificationType.LOAN_ROLE_ASSIGNMENT,
        parameters: [ LoanRoleAssignment.UNDERWRITER ],
        methods: [],
        frequency: NotificationFrequency.IMMEDIATELY,
        reminderFrequency: NotificationFrequency.IMMEDIATELY,
        includeUnassigned: true
    },
    {
        id: `${notificationID++}`,
        roleId: '8',
        type: NotificationType.LOAN_STATUS_NON_DELEGATED,
        parameters: [
            LoanStatusNonDelegated.IN_UNDERWRITING,
            LoanStatusNonDelegated.LOAN_SETUP
        ],
        methods: [ NotificationMethod.EMAIL ],
        frequency: NotificationFrequency.WEEKLY,
        reminderFrequency: NotificationFrequency.DAILY,
        includeUnassigned: false
    },
    {
        id: `${notificationID++}`,
        roleId: '8',
        type: NotificationType.LOAN_ROLE_ASSIGNMENT,
        parameters: [
            LoanRoleAssignment.LOAN_SETUP,
            LoanRoleAssignment.UNDERWRITER
        ],
        methods: [ NotificationMethod.EMAIL, NotificationMethod.IN_APP ],
        frequency: NotificationFrequency.DAILY,
        reminderFrequency: NotificationFrequency.WEEKLY,
        includeUnassigned: true
    }
];
