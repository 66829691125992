import api, { LLPA, UpdateLLPAReqBody } from '@api';
import { Button, DialogContent } from '@mui/material';
import {
    DialogActions, RoutedDialog, RoutedDialogImplProps, TextField
} from '@tsp-ui/core/components';
import { replaceItemById, usePageMessage, useParams } from '@tsp-ui/core/utils';
import { useActingClientID } from '@utils/hooks';
import { useIsCapQueryParam } from '@views/admin/llpas/AddLLPAPage';
import { LLPADetailPageParams } from '@views/admin/llpas/LLPADetailPage';
import { LLPARoutesContext } from '@views/admin/llpas/LLPARoutes';
import {
    Dispatch, SetStateAction, useContext, useState
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';


import styles from './EditLLPADetailsDialog.module.scss';


export default function EditLLPADetailsDialog(props: RoutedDialogImplProps) {
    const { search } = useLocation();
    const isCap = useIsCapQueryParam();

    const [ loading, setLoading ] = useState(false);
    const { llpaId } = useParams<LLPADetailPageParams>();
    const { llpas } = useContext(LLPARoutesContext);

    const llpa = llpas?.find(({ id }) => id === llpaId);

    return (
        <RoutedDialog
            {...props}
            title={`Edit ${isCap ? 'Cap' : 'LLPA'} details`}
            keepLocationSearch
        >
            <DialogContent>
                {llpa && (
                    <LLPADetailsForm
                        setLoading={setLoading}
                        llpa={llpa}
                    />
                )}
            </DialogContent>

            <DialogActions loading={loading}>
                <Button
                    component={Link}
                    to={`..${search}`}
                >
                    Cancel
                </Button>

                <Button
                    variant="contained"
                    type="submit"
                    form={formId}
                >
                    Save
                </Button>
            </DialogActions>
        </RoutedDialog>
    );
}

interface LLPADetailsFormProps {
    llpa: LLPA;
    setLoading: Dispatch<SetStateAction<boolean>>;
}

function LLPADetailsForm({ llpa, setLoading }: LLPADetailsFormProps) {
    const pageMessage = usePageMessage();
    const navigate = useNavigate();
    const { search } = useLocation();
    const clientId = useActingClientID();
    const isCap = useIsCapQueryParam();
    const { llpas, setLlpas } = useContext(LLPARoutesContext);

    const formMethods = useForm<UpdateLLPAReqBody>({
        defaultValues: llpa
    });

    const handleSubmit = formMethods.handleSubmit(async formValues => {
        setLoading(true);

        try {
            const updatedLlpa = await api.llpa.updateLLPADetails(clientId, formValues);

            setLlpas(llpas ? replaceItemById(llpas, updatedLlpa) : [ updatedLlpa ]);

            navigate(`..${search}`);

            pageMessage.success(`${isCap ? 'Cap' : 'LLPA'} updated`);
        } catch (error) {
            pageMessage.handleApiError(`An error occurred while updating the ${isCap ? 'Cap' : 'LLPA'}`, error);
        }

        setLoading(false);
    });

    return (
        <form
            id={formId}
            onSubmit={handleSubmit}
            className={styles.root}
        >
            <FormProvider {...formMethods}>
                <TextField<UpdateLLPAReqBody>
                    name="name"
                    label={`${isCap ? 'Cap ' : 'LLPA'} name`}
                    required
                />

                <TextField<UpdateLLPAReqBody>
                    name="description"
                    label="Description"
                    required
                    multiline
                    rows={2}
                />
            </FormProvider>
        </form>
    );
}

const formId = 'llpa-details-form';
