import { apiUtils } from '../api-utils';


export function getEligibilityVersions(): Promise<EligibilityVersion[]> {
    return apiUtils.get('/eligibility-version');
}

export function createEligibilityVersion(version: EligibilityVersion): Promise<EligibilityVersion> {
    return apiUtils.post('/eligibility-version', version);
}

export function updateEligibilityVersion(version: EligibilityVersion): Promise<EligibilityVersion> {
    return apiUtils.put(`/eligibility-version/${version.id}`, version);
}

export function deleteEligibilityVersion(eligibilityVersionID: string): Promise<void> {
    return apiUtils.delete(`/eligibility-version/${eligibilityVersionID}`, { emptyResponse: true });
}

export interface EligibilityVersion {
    id: string;
    displayVersionId: string;
    comments: string;
    effectiveDate: string;
    expirationDate?: string;
}
