import api, { CommonLoanProgram, InternalLoanProgram } from '@api';
import { SentryRoutes } from '@tsp-ui/core/components';
import { useAsyncEffect, usePageMessage, useParams } from '@tsp-ui/core/utils';
import { AdminRouteParams } from '@views/admin/components/AdminPageTemplate';
import { useEligibilityVersionIdQueryParam } from '@views/admin/investors/components/EligibilityVersionButton';
import {
    Dispatch, SetStateAction, createContext, useCallback, useState
} from 'react';
import { Route } from 'react-router-dom';

import CreateLoanProgramPage from './InvestorDetailPage/CreateLoanProgramPage';
import InternalInvestorDetailPage from './InvestorDetailPage/InternalInvestorDetailPage';
import { InternalLoanProgramDetailPage } from './InvestorDetailPage/LoanProgramDetailPage/InternalLoanProgramDetailPage';


export const LoanProgramsContext = createContext<{
    loanPrograms?: CommonLoanProgram[];
    setLoanPrograms: Dispatch<SetStateAction<CommonLoanProgram[] | undefined>>;
}>({
    loanPrograms: undefined,
    setLoanPrograms: () => {}
});

export default function InternalInvestorDetailRoutes() {
    const pageMessage = usePageMessage();
    const eligibilityVersionId = useEligibilityVersionIdQueryParam();
    const { investorID } = useParams<AdminRouteParams<'investor'>>();

    const [ loanPrograms, setLoanPrograms ] = useState<InternalLoanProgram[]>();

    useAsyncEffect(useCallback(async () => {
        try {
            if (investorID !== 'add') {
                setLoanPrograms(undefined);
                setLoanPrograms(await api.investors.getLoanPrograms(investorID, eligibilityVersionId));
            }
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching loan programs', error);
        }
    }, [
        investorID, pageMessage, eligibilityVersionId
    ]));

    return (
        <LoanProgramsContext.Provider
            value={{
                loanPrograms,
                setLoanPrograms
            }}
        >
            <SentryRoutes>
                <Route
                    path="*"
                    element={<InternalInvestorDetailPage />}
                />

                <Route
                    path="loan-programs/new"
                    element={<CreateLoanProgramPage />}
                />

                <Route
                    path="loan-programs/:loanProgramID/*"
                    element={<InternalLoanProgramDetailPage />}
                />
            </SentryRoutes>
        </LoanProgramsContext.Provider>
    );
}
