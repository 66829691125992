import { ActiveInactiveEnum } from '@api';

import { apiUtils } from '../../api-utils';


export function getMICompanyRelationships(clientID: string, customerID: string): Promise<MICompanyRelationship[]> {
    return apiUtils.get(`/client/${clientID}/customer/${customerID}/mi-company-relationship`);
}

export function updateMICompanyRelationship(
    clientID: string, customerID: string, miRelationship: MICompanyRelationship
): Promise<MICompanyRelationship> {
    return apiUtils.put(`/client/${clientID}/customer/${customerID}/mi-company-relationship/${miRelationship.id}`, miRelationship);
}

export function createMICompanyRelationship(
    clientID: string, customerID: string, miRelationship: MICompanyRelationship
): Promise<MICompanyRelationship> {
    return apiUtils.post(`/client/${clientID}/customer/${customerID}/mi-company-relationship`, miRelationship);
}

export function softDeleteMICompanyRelationship(
    clientID: string, customerID: string, miRelationship: MICompanyRelationship
): Promise<MICompanyRelationship> {
    return updateMICompanyRelationship(clientID, customerID, {
        ...miRelationship,
        status: ActiveInactiveEnum.INACTIVE
    });
}

export interface MICompanyRelationship {
    id: string;
    insurerName: string;
    policyNumber: string;
    contact: string;
    phone: string;
    emailAddress: string;
    status: ActiveInactiveEnum;
}
