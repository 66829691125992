import api, {  PermissionType, Product } from '@api';
import { Button as MuiButton } from '@mui/material';
import { Button, RoutedDialogManager } from '@tsp-ui/core/components';
import {
    getItemById, replaceItemById, usePageMessage, useParams
} from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils/hooks';
import { useHasPermission } from '@utils/hooks/useHasPermission';
import { AdminRouteParams } from '@views/admin/components/AdminPageTemplate';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Page from '../../../components/Page';
import StatusActionsCard from '../../components/StatusActionsCard';
import { ProductManagementPageContext } from '../ProductManagementPage';
import { ProductDetailsCard } from '../components/ProductDetailsCard';
import { ProductDetailsDialog } from '../components/ProductDetailsDialog';
import { ProductHistoryDialog } from '../components/ProductHistoryDialog';

import styles from './ProductDetailPage.module.scss';


export default function ProductDetailPage() {
    const pageMessage = usePageMessage();
    const { id: clientId } = useGetCurrentAccount();

    const [ loading, setLoading ] = useState(true);
    const [ statusLoading, setStatusLoading ] = useState(false);
    const [ product, setProduct ] = useGetProduct();

    const toggleActive = async () => {
        try {
            setStatusLoading(true);
            setProduct(await api.products.updateProduct(clientId, {
                ...product!,
                isActive: !product?.isActive
            }));

            const activationStatus = product.isActive ? 'deactivated' : 'activated';
            pageMessage.success(`Product ${activationStatus}`);
        } catch (error) {
            const action = product.isActive ? 'deactivating' : 'activating';
            pageMessage.handleApiError(`An error occurred while ${action} the product`, error);
        }
        setStatusLoading(false);
    };

    useEffect(() => product && setLoading(false), [ product ]);

    const [ canManageProduct ] = useHasPermission(
        [ PermissionType.MANAGE_PRODUCTS ]
    );

    return (
        <Page
            header="Product Details"
            loading={loading}
            headerActions={(
                <MuiButton
                    component={Link}
                    to="history"
                >
                    View history
                </MuiButton>
            )}
            breadcrumbs={[
                'Products',
                product?.productDescription
            ]}
        >
            {product && (
                <div className={styles.root}>
                    <div>
                        <ProductDetailsCard product={product} />
                    </div>

                    <div />

                    <div>
                        <StatusActionsCard
                            actions={(
                                <Button
                                    disabled={!canManageProduct}
                                    onClick={toggleActive}
                                    fullWidth
                                    tooltip={!canManageProduct ? 'You do not have permission to update the status of this product' : ''}
                                >
                                    {product.isActive ? 'Deactivate' : 'Activate'}
                                </Button>
                            )}
                            noBorder={false}
                            status={product.isActive ? 'Active' : 'Inactive'}
                            variant={product.isActive ? 'ACTIVE' : 'INACTIVE'}
                            isLoading={statusLoading}
                        />
                    </div>
                </div>
            )}

            <RoutedDialogManager
                routes={{
                    edit: ProductDetailsDialog,
                    history: ProductHistoryDialog
                }}
            />
        </Page>
    );
}

export function useGetProduct(): [Product, ((newProduct: Product) => void)] {
    const { productID } = useParams<AdminRouteParams<'product'>>();
    const { entities, setEntities } = useContext(ProductManagementPageContext);

    return [
        getItemById(entities, productID),
        (newProduct: Product) => setEntities(replaceItemById(entities, newProduct))
    ];
}
