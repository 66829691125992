import {
    CustomerFeesComp,
    FeeAmountType,
    PermissionType,
    PurchaseAdviceFee
} from '@api';
import { FmdBad, RotateLeftOutlined } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { IconButton } from '@tsp-ui/core/components';
import { formatCurrency } from '@tsp-ui/core/utils';
import { useHasPermission } from '@utils/hooks';
import { UseFieldArrayReturn, useFormContext } from 'react-hook-form';

import CustomerFeeCard from './CustomerFeeCard';
import styles from './CustomerPurchaseAdviceFeeCard.module.scss';


interface CustomerPurchaseAdviceFeeTableRowProps {
    customerId: string;
    fee: PurchaseAdviceFee;
    feesArray?: UseFieldArrayReturn<CustomerFeesComp, 'fees'>;
}

export default function CustomerPurchaseAdviceFeeCard({
    customerId, fee, feesArray
}: CustomerPurchaseAdviceFeeTableRowProps) {
    const { setValue, watch } = useFormContext<CustomerFeesComp>();

    const fees =  watch('fees');

    const formFieldIndex = fees ? fees.findIndex(formFee => formFee.purchaseAdviceFeeId === fee.id) : -1;

    const nameBase: `fees.${number}` = `fees.${formFieldIndex}`;

    const formFee = watch(nameBase);

    const customerFee = formFieldIndex === -1 ? ({
        ...fee,
        customerId,
        isActive: true
    }) : formFee;

    const {
        amount, minAmount, maxAmount, percentage, percentageOf
    } = customerFee;

    const isVariable = fee.amountType === FeeAmountType.VARIABLE;

    const isAdjusted = isVariable
        ? minAmount !== fee.minAmount || maxAmount !== fee.maxAmount
            || percentage !== fee.percentage || percentageOf !== fee.percentageOf
        : amount !== fee.amount;

    function handleToggleActive(isActive: boolean) {
        if (formFieldIndex !== -1) {
            setValue(`${nameBase}.isActive`, isActive);
        } else {
            feesArray?.append({
                ...fee,
                id: undefined,
                purchaseAdviceFeeId: fee.id,
                isActive,
                customerId
            });
        }
    }

    function triggerAdjustment() {
        if (formFieldIndex === -1) {
            feesArray?.append({
                ...fee,
                id: undefined,
                purchaseAdviceFeeId: fee.id,
                isActive: true,
                customerId
            });
        }
    }

    const resetToDefault = () => feesArray?.remove(formFieldIndex);

    const [ canManageFeesComp ] = useHasPermission([ PermissionType.MANAGE_FEE_COMPENSATION ]);

    return (
        <CustomerFeeCard
            fee={customerFee}
            formIndex={formFieldIndex}
            onToggleActive={handleToggleActive}
            onEditClick={triggerAdjustment}
            firstButton={isAdjusted && (
                <IconButton
                    tooltip={!canManageFeesComp ? 'You do not have permission to manage fees' : 'Reset to default'}
                    disabled={!canManageFeesComp}
                    size="small"
                    onClick={resetToDefault}
                >
                    <RotateLeftOutlined fontSize="small" />
                </IconButton>
            )}
            headerIcon={isAdjusted && (
                isVariable ? (
                    <Tooltip
                        title={(
                            <>
                                Fee adjusted
                                <br />

                                Original min:

                                {' '}

                                {formatCurrency(fee.minAmount)}

                                <br />

                                Original max:

                                {' '}

                                {formatCurrency(fee.maxAmount)}
                            </>
                        )}
                    >
                        <FmdBad
                            className={styles.adjustedIcon}
                            color="warning"
                        />
                    </Tooltip>
                ) : (
                    !isVariable && (
                        <Tooltip
                            title={(
                                <>
                                    Fee adjusted
                                    <br />

                                    Original amount:

                                    {formatCurrency(fee.amount)}
                                </>
                            )}
                        >
                            <FmdBad
                                className={styles.adjustedIcon}
                                color="warning"
                            />
                        </Tooltip>
                    )
                )
            )}
        />
    );
}
