import api, { ReferenceGuideSection } from '@api';
import { Button, DialogContent } from '@mui/material';
import { DialogActions, RoutedDialog, TextField } from '@tsp-ui/core/components';
import { usePageMessage } from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils/hooks';
import { useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { ReferenceGuideManagementPageContext } from '../ClientReferenceGuideManagementPage';

import styles from './ReferenceGuideSectionDialog.module.scss';


export function ReferenceGuideSectionDialog() {
    const [ loading, setLoading ] = useState(false);
    const { id: clientID } = useGetCurrentAccount();
    const {
        entities: referenceGuideSections, setEntities: setReferenceGuideSections
    } = useContext(ReferenceGuideManagementPageContext);

    const navigate = useNavigate();

    const pageMessage = usePageMessage();
    const formMethods = useForm<ReferenceGuideSection>();

    const handleSubmit = formMethods.handleSubmit(async (formValues: ReferenceGuideSection) => {
        setLoading(true);

        try {
            setReferenceGuideSections(
                referenceGuideSections.concat(
                    await api.referenceGuides.createReferenceGuideSection(clientID, formValues)
                )
            );

            navigate('..');

            pageMessage.success('Reference guide section saved');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving the reference guide section', error);
        }

        setLoading(false);
    });

    return (
        <RoutedDialog
            closeTo=".."
            title="Create reference guide section"
        >
            <form
                onSubmit={handleSubmit}
                noValidate
            >
                <FormProvider {...formMethods}>
                    <DialogContent className={styles.root}>
                        <TextField<ReferenceGuideSection>
                            name="title"
                            label="Section title"
                            required
                        />
                    </DialogContent>

                    <DialogActions loading={loading}>
                        <Button
                            type="submit"
                            variant="contained"
                        >
                            Submit
                        </Button>
                    </DialogActions>
                </FormProvider>
            </form>
        </RoutedDialog>
    );
}
