import { PermissionType, UserType } from '@api';
import { ActiveTokenContext } from '@views/components/ActiveTokenContext';
import { useContext } from 'react';

import { decodeAuthToken, isClientTokenPayload } from '../../api/api-utils';

import { useTryGetCurrentAccount } from './useGetCurrentAccount';
import { useGetCurrentUser } from './useGetCurrentUser';


const { INTERNAL } = UserType;

/**
 * Internal users register permissions from AuthUser.internalPermissions.
 * Client users register permissions from ClientTokenPayload.permission
 */
export function useHasPermission(permissionsProp: PermissionType[]) {
    const { accountUserType } = useTryGetCurrentAccount() || {};
    const { internalPermissions = [] } = useGetCurrentUser();
    const { activeAuthToken } = useContext(ActiveTokenContext);

    let userPermissions: PermissionType[] = [];

    if (!accountUserType || !activeAuthToken) { // if not logged in
        return [];
    }

    const tokenPayload = decodeAuthToken(activeAuthToken); // permissions are false while this is loading
    const isClientToken = isClientTokenPayload(tokenPayload);

    // if current token is from cognito and current user is not an internal user, return false until client token loads
    if (!isClientToken && accountUserType !== INTERNAL) {
        return [];
    }

    if (isClientToken) {
        userPermissions = tokenPayload.permission;
    } else {
        userPermissions = internalPermissions;
    }

    return permissionsProp.map(permission => userPermissions.includes(permission));
}
