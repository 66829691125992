import api, { LoanStatusConfig } from '@api';
import { Button, DialogContent } from '@mui/material';
import {
    getItemById, replaceItemById, useForm, useParams
} from '@tsp-ui/core';
import {
    Autocomplete, DialogActions, RoutedDialog, RoutedDialogProps, TextField, usePageMessage
} from '@tsp-ui/core/components';
import { useGetCurrentAccount } from '@utils';
import { useContext, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import styles from './LoanStatusDialog.module.scss';
import { LoanStatusContext } from './LoanStatusPage';


export function LoanStatusDialog(props: Omit<RoutedDialogProps, 'title'>) {
    const [ loading, setLoading ] = useState(false);
    const { loanStatusConfigId } = useParams();
    const { loanStatusConfigs, setLoanStatusConfigs, losLoanStatuses } = useContext(LoanStatusContext);
    const configToEdit = loanStatusConfigs.find(({ id }) => id === +loanStatusConfigId!);

    const { id: clientId } = useGetCurrentAccount();

    const pageMessage = usePageMessage();
    const navigate = useNavigate();

    const formMethods = useForm<LoanStatusConfig>({
        defaultValues: configToEdit
    });

    const handleSubmit = formMethods.handleSubmit(async (formData) => {
        setLoading(true);

        if (!configToEdit) {
            const highestDisplayOrder = Math.max(...loanStatusConfigs.map(({ displayOrder }) => displayOrder));
            formData.displayOrder = highestDisplayOrder + 1;
        }

        try {
            setLoanStatusConfigs(configToEdit
                ? replaceItemById(loanStatusConfigs, await api.loanStatus.updateLoanStatusConfig(clientId, formData))
                : [ ...loanStatusConfigs, await api.loanStatus.createLoanStatusConfig(clientId, formData) ]);

            pageMessage.success('Loan status configuration saved');

            navigate('..');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving loan status configuration', error);
        }

        setLoading(false);
    });

    return (
        <RoutedDialog
            {...props}
            title={`${configToEdit ? 'Edit' : 'Add'} loan status configuration`}
            maxWidth={false}
        >
            <DialogContent>
                <FormProvider {...formMethods}>
                    <form
                        id="loan-status-form"
                        className={styles.root}
                        onSubmit={handleSubmit}
                        noValidate
                    >
                        <TextField<LoanStatusConfig>
                            name="title"
                            label="Title"
                            required
                        />

                        <Autocomplete<LoanStatusConfig>
                            name="losLoanStatusIds"
                            label="Loan statuses"
                            options={losLoanStatuses.map(({ id }) => id)}
                            getOptionLabel={id => getItemById(losLoanStatuses, id)?.name}
                            multiple
                            disableCloseOnSelect
                            required
                        />
                    </form>
                </FormProvider>
            </DialogContent>

            <DialogActions loading={loading}>
                <Button
                    type="submit"
                    form="loan-status-form"
                    variant="contained"
                    disabled={loading}
                >
                    Save
                </Button>
            </DialogActions>
        </RoutedDialog>
    );
}
