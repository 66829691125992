import { PermissionType } from '@api';
import { NotAllowedDialog } from '@views/NotAllowedDialog';
import NotAllowedPage from '@views/NotAllowedPage';
import { ComponentType } from 'react';

import { useTryGetCurrentAccount } from './hooks/index';
import { useHasPermission } from './hooks/useHasPermission';

// Handles user authentication & authorization
export function withAuth<TProps extends Record<string, any>>(
    Component: ComponentType<TProps>, authorizedPermissions?: PermissionType[], isDialog?: boolean
) {
    return function AuthenticationWrapper(props: TProps) {
        const account = useTryGetCurrentAccount();

        const hasPermission = useHasPermission(authorizedPermissions || []).includes(true);
        const canAccess = (account && hasPermission) || !authorizedPermissions;

        return canAccess ? (
            <Component {...props} />
        ) : (
            isDialog ? (
                <NotAllowedDialog
                    closeTo=".."
                    {...props}
                />
            ) : <NotAllowedPage />
        );
    };
}
