import {
    ConditionCategory, LOS, PremicorrConditionCategory
} from '@api';

import { apiUtils } from '../../api-utils';


export function getLOSConfigurations(clientId: string): Promise<LOSConfiguration | undefined> {
    return apiUtils.get(`/client/${clientId}/los`);
}

export function getLoanFolders(clientId: string): Promise<LoanFolder[] | undefined> {
    return apiUtils.get(`/client/${clientId}/los/loan-folders`);
}

// There is no post here because these values are set in AWS Parameter store
export function setLOSConfiguration(clientId: string, config: LOSConfiguration): Promise<LOSConfiguration> {
    return apiUtils.put(`/client/${clientId}/los`, config);
}

// Query param supplied to filter results based on selected LOS
export function getMappedConditionCategories(
    clientId: string,
    los: string
): Promise<ConditionCategoryAssociation[] | undefined> {
    return apiUtils.get(`/client/${clientId}/los/condition-categories`, { los });
}

// Update is technically an upsert on the backend (for when new Prem categories are added)
export function updateMappedConditionCategories(
    clientId: string,
    categories: ConditionCategoryAssociation[]
): Promise<ConditionCategoryAssociation[]> {
    return apiUtils.put(`/client/${clientId}/los/condition-categories`, categories);
}

export interface LOSConfiguration {
    los: LOS;
    apiUrl: string;
    defaultLoanFolder?: string; // only needed for Encompass currently
    clientId: string;
    clientSecret: string;
    instanceId?: string; // only needed for Encompass
    userName?: string; // only needed for MCP
    password?: string; // only needed for MCP
}

export interface LoanFolder {
    folderName: string;
    folderType: string;
    actionsAllowed: string[];
    isArchiveFolder: boolean;
    includeDuplicateLoanCheck: boolean;
}

export interface ConditionCategoryAssociation {
    associationID: string;
    los: LOS;
    losConditionCategory: ConditionCategory;
    premicorrConditionCategory: PremicorrConditionCategory;
}

export interface LOSConditionCategoryRequest {
    los: string;
    premicorrConditionCategory?: string;
    losConditionID?: string;
}
