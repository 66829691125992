import { TemplateFile } from '@api';


export function tooltipTitle(titles: { [title: string]: any }) {
    const [ title ] = Object.entries(titles).find(([ _, shouldShow ]) => shouldShow) || [];
    return title || '';
}

/**
 * converts from camel or pascal case to lower case with spaces, i.e. "camelCase" -> "camel case"
 */
export function toLowerCaseWithSpaces(str: string) {
    return str.replace(/([A-Z])/g, ' $1').toLowerCase();
}

/**
 * converts from camel or pascal case to sentence case
 */
export function toSentenceCase(str: string) {
    return str.replace(/([A-Z][a-z])/g, ' $1')
        .replace(/\//g, ' /') // add spaces around slashes
        .replace(/^./, (s) => s.toUpperCase());
}

export function handleDownload(file: Blob | TemplateFile, fileName: string) {
    if (file instanceof Blob) {
        const url = window.URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    } else {
        const link = document.createElement('a');
        link.href = file.fileUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}
