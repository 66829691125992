export enum AlertConfigType {
    LICENSE_EXPIRATION = 'LICENSE_EXPIRATION',
    REFERENCE_GUIDE = 'REFERENCE_GUIDE',
    ELIGIBILITY = 'ELIGIBILITY',
    LOCK_AVAILABILITY = 'LOCK_AVAILABILITY',
    COMPENSATION_FEE = 'COMPENSATION_FEE',
    ELIGIBLE_PRODUCT = 'ELIGIBLE_PRODUCT',
    LIMIT = 'LIMIT'
}

export const alertTypeConfigDisplay = {
    [AlertConfigType.LICENSE_EXPIRATION]: 'When a state license expires',
    [AlertConfigType.REFERENCE_GUIDE]: 'When reference guides change',
    [AlertConfigType.ELIGIBILITY]: 'When eligibility changes',
    [AlertConfigType.LOCK_AVAILABILITY]: 'When lock availability changes',
    [AlertConfigType.COMPENSATION_FEE]: 'When compensation or fee changes',
    [AlertConfigType.ELIGIBLE_PRODUCT]: 'When eligible product change',
    [AlertConfigType.LIMIT]: 'When a limit changes'
};
