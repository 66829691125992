import { Product, ProductTypeConfig, productTypeDisplay } from '@api';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';
import { useState } from 'react';

import styles from './ViewTypeSection.module.scss';


interface ProductTypeSectionProps {
    productTypeConfig: ProductTypeConfig;
    products?: Product[];
}

export function ViewProductTypeSection({ productTypeConfig, products }: ProductTypeSectionProps) {
    const [ expanded, setExpanded ] = useState(false);

    const activeProducts = products?.filter(({ isActive }) => isActive);

    return (
        <>
            <div className={styles.productTypeRow}>
                <IconButton
                    size="small"
                    onClick={() => setExpanded(!expanded)}
                    disabled={!activeProducts?.length}
                >
                    {expanded ? (
                        <ExpandLess color="secondary" />
                    ) : (
                        <ExpandMore color={activeProducts?.length ? 'secondary' : 'disabled'} />
                    )}
                </IconButton>

                <Typography>
                    {productTypeDisplay[productTypeConfig.productType]}
                </Typography>

                <Typography
                    variant="body2"
                    color="textSecondary"
                    className={styles.numProducts}
                >
                    {activeProducts?.length || 'No'}{' '}

                    {activeProducts?.length === 1 ? 'product' : 'products'}
                </Typography>
            </div>

            {expanded && (
                <ul className={styles.productRowList}>
                    {activeProducts?.map(({ id, productDescription }) => (
                        <Typography
                            key={id}
                            component="li"
                            variant="body2"
                            className={styles.productRow}
                        >
                            {productDescription}
                        </Typography>
                    ))}
                </ul>
            )}
        </>
    );
}
