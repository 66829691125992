import { Investor } from '@api';
import { Button, Tooltip, Typography } from '@mui/material';
import { useCreateAccountUrl } from '@utils/hooks';
import { Link } from 'react-router-dom';

import styles from './InvestorChip.module.scss';


interface InvestorChipProps {
    investor?: Investor;
}

export function InvestorChip({ investor }: InvestorChipProps) {
    const createAccountUrl = useCreateAccountUrl();

    return !investor ? null : (
        <Tooltip title={investor.name}>
            <Button
                component={Link}
                to={createAccountUrl(`admin/investor-eligibility/investors/${investor.id}`)}
                variant="outlined"
                className={styles.root}
            >
                <Typography
                    variant="body2"
                    color="inherit"
                >
                    {investor.code}
                </Typography>
            </Button>
        </Tooltip>
    );
}
