export enum ConditionPriorTo {
    APPROVAL = 'APPROVAL',
    DOCS = 'DOCS',
    FUNDING = 'FUNDING',
    CLOSING = 'CLOSING',
    PURCHASE = 'PURCHASE'
}

export const conditionPriorToDisplay = {
    [ConditionPriorTo.APPROVAL]: 'Approval',
    [ConditionPriorTo.DOCS]: 'Docs',
    [ConditionPriorTo.FUNDING]: 'Funding',
    [ConditionPriorTo.CLOSING]: 'Closing',
    [ConditionPriorTo.PURCHASE]: 'Purchase'
};
