import {
    Button, DialogContent, Step, StepLabel, Stepper
} from '@mui/material';
import { DialogActions, RoutedDialog, RoutedDialogProps } from '@tsp-ui/core/components';
import { useState } from 'react';

import styles from './LOSConfigDialog.module.scss';
import { LOSConfigForm } from './LOSConfigForm';


export default function LOSConfigDialog(props: Omit<RoutedDialogProps, 'title' | 'children'>) {
    const [ saveLoading, setSaveLoading ] = useState(false);
    const [ activeStep, setActiveStep ] = useState(0);
    const lastStep = 2;

    return (
        <RoutedDialog
            {...props}
            title="Set LOS Configration"
            maxWidth={false}
            saveLoading={saveLoading}
        >
            <DialogContent className={styles.stepperContent}>
                <Stepper
                    activeStep={activeStep}
                    className={styles.stepper}
                >
                    <Step>
                        <StepLabel>
                            LOS credentials
                        </StepLabel>
                    </Step>

                    <Step>
                        <StepLabel>
                            Loan folder
                        </StepLabel>
                    </Step>

                    <Step>
                        <StepLabel>
                            Condition categories
                        </StepLabel>
                    </Step>
                </Stepper>
            </DialogContent>

            <DialogContent>
                <LOSConfigForm
                    activeStep={activeStep}
                    lastStep={lastStep}
                    setActiveStep={setActiveStep}
                    setSaveLoading={setSaveLoading}
                />
            </DialogContent>

            <DialogActions>
                <Button
                    type="submit"
                    variant="contained"
                    form={LOSConfigForm.formID}
                    disabled={saveLoading}
                >
                    {activeStep === lastStep ? 'Save' : 'Next'}
                </Button>
            </DialogActions>
        </RoutedDialog>
    );
}
