import { LLPAMatrixLoanProperty } from '@api';
import { LLPAMatrixFormCell } from '@views/admin/llpas/components/LLPADialog';
import AdjustmentCell from '@views/admin/llpas/components/LLPAMatrix/components/AdjustmentCell';
import deepEqual from 'fast-deep-equal';

import { getKeyForCell } from '../LLPAMatrix';

import LoanPropertyValueCell from './LoanPropertyValueCell';


interface MatrixTableRowProps {
    rowIndex: number;
    cells: LLPAMatrixFormCell[];
    rowProperty: LLPAMatrixLoanProperty | null;
    colProperties: LLPAMatrixLoanProperty[];
    numRows: number;
    disableInsert: boolean;
    readOnly?: boolean;
}

export default function MatrixTableRow({
    rowIndex,
    cells,
    rowProperty,
    colProperties,
    numRows,
    disableInsert,
    readOnly
}: MatrixTableRowProps) {
    const firstEntry = cells[rowIndex];

    const adjustmentEntries = cells.filter((entry) => (
        colProperties.every(property => deepEqual(entry[property], cells[rowIndex][property]))
    ));

    const remainingColEntries = cells.slice(rowIndex, numRows);

    function getRowSpanForColumn(property: LLPAMatrixLoanProperty) {
        const propertiesToCompare = colProperties.slice(0, colProperties.indexOf(property) + 1);
        let rowSpan = 1;

        for (let i = 0; i < remainingColEntries.length; i++) {
            const nextEntry = remainingColEntries[i + 1];

            if (!arePropertiesEqual(propertiesToCompare, nextEntry, firstEntry)) {
                break;
            }

            if (deepEqual(remainingColEntries[i][property], firstEntry[property])) {
                rowSpan++;
            }
        }

        return rowSpan;
    }

    return (
        <tr>
            {colProperties.filter((property) => (
                cells.length === adjustmentEntries.length || !arePropertiesEqual(
                    colProperties.slice(0, colProperties.indexOf(property) + 1),
                    firstEntry,
                    cells[rowIndex - 1]
                )
            )).map((property) => (
                <LoanPropertyValueCell
                    key={`${getKeyForCell(firstEntry)}_${property}`}
                    variant="column"
                    property={property}
                    rowProperty={rowProperty}
                    colProperties={colProperties}
                    cell={firstEntry}
                    cellIndex={rowIndex}
                    rowSpan={getRowSpanForColumn(property)}
                    numRows={numRows}
                    readOnly={readOnly}
                    disableInsert={disableInsert}
                    hideBottomBorder={remainingColEntries.every((llpaEntry) => (
                        arePropertiesEqual(
                            colProperties.slice(0, colProperties.indexOf(property) + 1),
                            llpaEntry,
                            firstEntry
                        )
                    ))}
                />
            ))}

            {adjustmentEntries.map((entry) => (
                <AdjustmentCell
                    key={`${getKeyForCell(entry)}_adjustment`}
                    cell={entry}
                    cellIndex={cells.indexOf(entry)}
                    readOnly={readOnly}
                />
            ))}
        </tr>
    );
}

function arePropertiesEqual(
    properties: LLPAMatrixLoanProperty[],
    cellA: LLPAMatrixFormCell | undefined,
    cellB: LLPAMatrixFormCell | undefined
) {
    return properties.every((property) => (
        deepEqual(cellA?.[property], cellB?.[property])
    ));
}
