import {
    LLPAOnlyLoanProperty, LoanProperty, agencyDisplay,
    amortizationTypeDisplay,
    automatedUwRecommendationDisplay,
    automatedUwSystemDisplay,
    booleanEnumDisplay,
    commitmentTypeDisplay,
    conditionEventDisplay,
    conditionExceptionStatusDisplay,
    contactResponsibilityDisplay,
    contactTitleDisplay,
    documentationTypeDisplay,
    feeTypeDisplay,
    loanLimitTypeDisplay,
    loanPurposeDisplay,
    loanTypeDisplay,
    lockPeriodDisplay,
    numUnitsDisplay,
    occupancyTypeDisplay,
    productTypeDisplay,
    propertyTypeDisplay,
    servicingTypeDisplay,
    specialtyProgramDisplay,
    stateDisplay
} from '@api';
import { renderEnumOptions } from '@tsp-ui/core';
import { ClientContext } from '@views/AuthenticatedRouteSwitch';
import { useContext } from 'react';

import { useTryGetCurrentAccount } from './hooks/useGetCurrentAccount';


const togglableValuesMasterListSource = {
    agency: agencyDisplay,
    amortizationType: amortizationTypeDisplay,
    automatedUnderwritingRecommendation: automatedUwRecommendationDisplay,
    automatedUnderwritingSystem: automatedUwSystemDisplay,
    commitmentType: commitmentTypeDisplay,
    conditionEvent: conditionEventDisplay,
    conditionExceptionStatus: conditionExceptionStatusDisplay,
    contactTite: contactTitleDisplay,
    contactResponsibility: contactResponsibilityDisplay,
    documentationType: documentationTypeDisplay,
    feeType: feeTypeDisplay,
    loanPurpose: loanPurposeDisplay,
    loanType: loanTypeDisplay,
    loanLimitType: loanLimitTypeDisplay,
    lockPeriod: lockPeriodDisplay,
    numUnits: numUnitsDisplay,
    occupancyType: occupancyTypeDisplay,
    productType: productTypeDisplay,
    propertyType: propertyTypeDisplay,
    servicingType: servicingTypeDisplay,
    specialtyProgram: specialtyProgramDisplay,
    state: stateDisplay
};

// exporting a casted version of this variable to make the type more flexible and easier to work with in the code
export const togglableValuesMasterList = togglableValuesMasterListSource as Record<keyof TogglableValuesMasterList, Record<TogglableEnumValue, string>>;  // eslint-disable-line max-len

type TogglableValuesMasterList = typeof togglableValuesMasterListSource;
export type TogglableValuesKey = keyof TogglableValuesMasterList;
export type TogglableEnumValue = {
    [K in keyof TogglableValuesMasterList]: keyof TogglableValuesMasterList[K];
  }[keyof TogglableValuesMasterList];

export type CustomValuesKey = 'sampleValue'; // TODO custom values to be implemented with future story

export type ConfigurableValues = {
    // custom values are values that clients can configure to be whatever they want
    customValues: Record<CustomValuesKey, string[]>; // TODO custom values to be implemented with future story
    // togglable values are values that clients can toggle on or off from a predefined enum
    togglableValues: Record<TogglableValuesKey, TogglableEnumValue[]>;
    // togglableValues: { // this type def is more accurate but less felxible
    //     [K in TogglableValuesKey]: (keyof TogglableValuesMasterList[K])[]
    // };
};


export function useRenderTogglableEnumOptions() {
    const isInternal = useTryGetCurrentAccount()?.id === 'internal';
    const togglableValues = useContext(ClientContext)?.configurableValues?.togglableValues;

    // if internal, use the whole display object. if client or customer, filter the display object to only include the
    // values that are included in the togglableValues[key] array
    return (togglableValuesKey: TogglableValuesKey) => renderEnumOptions(isInternal
        ? togglableValuesMasterList[togglableValuesKey]
        : Object.fromEntries(
            Object.entries(togglableValuesMasterList[togglableValuesKey])
                .filter(
                    ([ key ]) => togglableValues?.[togglableValuesKey].includes(key as TogglableEnumValue)
                )
        ));
}

export function useClientTogglableValuesDisplay(togglableValuesKeys: TogglableValuesKey[]) {
    const isInternal = useTryGetCurrentAccount()?.id === 'internal';
    const togglableValues = useContext(ClientContext)?.configurableValues?.togglableValues;
    return togglableValuesKeys.map(togglableValuesKey => {
        const displayObject = togglableValuesMasterList[togglableValuesKey];

        // if internal, use the whole display object. if client or customer, filter the display object to only include
        // the values that are included in the togglableValues[key] array
        return isInternal ? displayObject : Object.fromEntries(
            Object.entries(displayObject).filter(
                ([ key ]) => togglableValues?.[togglableValuesKey].includes(key as TogglableEnumValue)
            )
        );
    });
}

export const loanPropertyEnumDisplaysBase = {
    [LoanProperty.LOAN_TYPE]: loanTypeDisplay,
    [LoanProperty.OCCUPANCY]: occupancyTypeDisplay,
    [LoanProperty.PURPOSE]: loanPurposeDisplay,
    [LoanProperty.AMORT_TYPE]: amortizationTypeDisplay,
    [LoanProperty.PROPERTY_TYPE]: propertyTypeDisplay,
    [LoanProperty.UNITS]: numUnitsDisplay,
    [LoanProperty.AUS]: automatedUwSystemDisplay,
    [LoanProperty.SPECIALTY_PROGRAM]: specialtyProgramDisplay,
    [LoanProperty.STATE]: stateDisplay,
    [LoanProperty.LOAN_LIMIT_TYPE]: loanLimitTypeDisplay,
    [LoanProperty.FICO]: null,
    [LoanProperty.LTV]: null,
    [LoanProperty.CLTV]: null,
    [LoanProperty.DTI]: null,
    [LoanProperty.TERM]: null,
    [LoanProperty.RESERVES_MONTHS]: null,
    [LoanProperty.LOAN_AMOUNT]: null,
    [LoanProperty.HIGH_BALANCE]: booleanEnumDisplay,
    [LoanProperty.ESCROWS]: booleanEnumDisplay,
    [LoanProperty.SUBORDINATE_FINANCING]: booleanEnumDisplay,
    [LoanProperty.IRRL]: booleanEnumDisplay,
    [LoanProperty.STREAMLINE]: booleanEnumDisplay,
    [LoanProperty.FIRST_TIME_HOMEBUYER]: booleanEnumDisplay,
    [LoanProperty.NON_QM]: booleanEnumDisplay
} as const;

export function useLoanPropertyEnumDisplays() {
    const [
        loanTypeDisplay, occupancyTypeDisplay, loanPurposeDisplay, amortizationTypeDisplay, propertyTypeDisplay,
        numUnitsDisplay, automatedUwSystemDisplay, specialtyProgramDisplay, stateDisplay
    ] = useClientTogglableValuesDisplay([
        'loanType', 'occupancyType', 'loanPurpose', 'amortizationType', 'propertyType', 'numUnits', 'automatedUnderwritingSystem', 'specialtyProgram', 'state'
    ]);

    return {
        ...loanPropertyEnumDisplaysBase,
        [LoanProperty.LOAN_TYPE]: loanTypeDisplay,
        [LoanProperty.OCCUPANCY]: occupancyTypeDisplay,
        [LoanProperty.PURPOSE]: loanPurposeDisplay,
        [LoanProperty.AMORT_TYPE]: amortizationTypeDisplay,
        [LoanProperty.PROPERTY_TYPE]: propertyTypeDisplay,
        [LoanProperty.UNITS]: numUnitsDisplay,
        [LoanProperty.AUS]: automatedUwSystemDisplay,
        [LoanProperty.SPECIALTY_PROGRAM]: specialtyProgramDisplay,
        [LoanProperty.STATE]: stateDisplay
    } as const;
}

export function useLlpaLoanPropertyEnumDisplays() {
    return {
        ...useLoanPropertyEnumDisplays(),
        [LLPAOnlyLoanProperty.INVESTOR]: null
    } as const;
}
