import { Account } from '@api';
import { getAccount } from '@redux/auth';
import { useSelector } from '@redux/store';
import { useRouteMatch } from '@tsp-ui/core/utils';


export function useGetCurrentAccount(): Account {
    const account = useTryGetCurrentAccount();

    if (!account) {
        throw new Error('No current account found. If this is expected, use useTryGetCurrentAccount instead.');
    }

    return account;
}

export function useTryGetCurrentAccount(): Account | undefined {
    const clientId = useRouteMatch('accounts/:accountID/*')?.params.accountID;
    const customerId = useRouteMatch('accounts/:accountID/:customerId/*')?.params.customerId;

    const isCustomerId = customerId && !isNaN(parseInt(customerId));

    return useSelector((state) => (
        getAccount(state, clientId, isCustomerId ? customerId : undefined)
    ));
}
