import {
    ReactNode, useLayoutEffect, useRef, useState
} from 'react';


interface ViewportFillProps {
    children: ReactNode;
    className?: string;
}

export default function ViewportFill({
    children, className
}: ViewportFillProps) {
    const containerRef = useRef<HTMLDivElement>(null);
    const [ offsetTop, setOffsetTop ] = useState<number>(0);

    useLayoutEffect(() => {
        function updateOffsetTop() {
            if (containerRef.current) {
                setOffsetTop(containerRef.current.offsetTop);
            }
        }

        updateOffsetTop();

        window.addEventListener('resize', updateOffsetTop);
        return () => window.removeEventListener('resize', updateOffsetTop);
    }, []);

    return (
        <div
            ref={containerRef}
            className={className}
            style={{
                height: `calc(100vh - ${offsetTop}px - ${48}px)` // page component has 48px of padding on the bottom
            }}
        >
            {children}
        </div>
    );
}
