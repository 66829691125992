import { loanPropertyDisplay, loanPropertyEnumFieldTypes } from '@api';
import { AutoFixNormal, Layers } from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';
import { useLoanPropertyEnumDisplays } from '@utils';
import {
    EligibilityExclusionFormValues,
    EligibilityExclusionGroupFormValues
} from '@views/admin/investors/InvestorDetailPage/components/ExclusionGroupRow';
import { Fragment } from 'react';

import styles from './EligibilityExclusionRow.module.scss';


interface EligibilityExclusionRowProps {
    exclusionGroup: EligibilityExclusionGroupFormValues;
    isManaged?: boolean;
    hideIcon?: boolean;
}

export function EligibilityExclusionRow({ exclusionGroup, isManaged, hideIcon }: EligibilityExclusionRowProps) {
    return (
        <div className={styles.root}>
            {hideIcon ? (
                <div />
            ) : isManaged ? (
                <Tooltip title="This exclusion is managed by Premicorr">
                    <AutoFixNormal
                        color="primary"
                        fontSize="small"
                        className={styles.info}
                    />
                </Tooltip>
            ) : (
                <Tooltip title="This exclusion is an overlay">
                    <Layers
                        color="primary"
                        fontSize="small"
                        className={styles.info}
                    />
                </Tooltip>
            )}

            {exclusionGroup.exclusions?.map((exclusion, index) => (
                <Fragment key={exclusion.id}>
                    {index > 0 && (
                        <Typography>
                            <u>AND</u>
                        </Typography>
                    )}

                    <ExclusionContent exclusion={exclusion} />
                </Fragment>
            ))}
        </div>
    );
}

interface ExclusionContentProps {
    exclusion: EligibilityExclusionFormValues;
}

function ExclusionContent({ exclusion: { loanProperty, value } }: ExclusionContentProps) {
    const loanPropertyEnumDisplays = useLoanPropertyEnumDisplays();

    if (!loanProperty) {
        return null;
    }

    const type = loanPropertyEnumFieldTypes[loanProperty];
    const display = loanPropertyEnumDisplays[loanProperty];
    const formattedValue = display
        ? display[value as keyof typeof display]
        : value;

    return (
        <Typography>
            <b>{loanPropertyDisplay[loanProperty]}</b>{' '}

            {type === 'numeric' ? null : '= '}

            <b>{formattedValue}</b>{' '}
        </Typography>
    );
}
