
import { rest } from 'msw';

import { getMockUrl } from '../../mocks/getMockUrl';

import { DocumentType } from './document-type-api';


export const mocks = [
    rest.get(getMockUrl('/client/:clientId/document-type/available'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.delay(500),
        ctx.json(documentTypes)
    )),
    rest.get(getMockUrl('/client/:clientId/document-type/required'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.delay(500),
        ctx.json(documentTypes.slice(0, 15).map(({ code }) => code))
    )),
    rest.put(getMockUrl('/client/:clientId/document-type/required'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.delay(500),
        ctx.json(req.body)
    ))
];

const documentTypes: DocumentType[] = [
    {
        name: '1-4 Family Rider',
        containerLabel: 'Deed of Trust or Mortgage - Certified',
        code: '484'
    },
    {
        name: '1040',
        containerLabel: 'Tax Returns',
        code: '2001'
    },
    {
        name: '1041',
        containerLabel: 'Tax Returns',
        code: '2009'
    },
    {
        name: '1041-K1',
        containerLabel: 'W2s / 1099 / K1s',
        code: '2054'
    },
    {
        name: '1065',
        containerLabel: 'Tax Returns',
        code: '2002'
    },
    {
        name: '1099',
        containerLabel: 'W2s / 1099 / K1s',
        code: '610'
    },
    {
        name: '1099 OID',
        containerLabel: 'Tax Returns',
        code: '1992'
    },
    {
        name: '1099-DIV',
        containerLabel: 'Tax Returns',
        code: '2055'
    },
    {
        name: '1099-INT',
        containerLabel: 'W2s / 1099 / K1s',
        code: '1993'
    },
    {
        name: '1099-MISC',
        containerLabel: 'Tax Returns',
        code: '2008'
    },
    {
        name: '1099-R',
        containerLabel: 'W2s / 1099 / K1s',
        code: '2044'
    },
    {
        name: '1120',
        containerLabel: 'Tax Returns',
        code: '1997'
    },
    {
        name: '1120S',
        containerLabel: 'Tax Returns',
        code: '1994'
    },
    {
        name: '203k and Streamlined k Maximum Mortgage Worksheet',
        containerLabel: '203K Docs',
        code: '571'
    },
    {
        name: '203k Borrowers Acknowledgement',
        containerLabel: '203K Docs',
        code: '1489'
    },
    {
        name: '203k Documents',
        containerLabel: '203K Docs',
        code: '1394'
    },
    {
        name: '2106',
        containerLabel: 'Tax Returns',
        code: '2004'
    },
    {
        name: '411.com',
        containerLabel: 'Conditions - Internal',
        code: '477'
    },
    {
        name: '4137',
        containerLabel: 'Tax Returns',
        code: '2007'
    },
    {
        name: '4506T',
        containerLabel: '4506T Request for Transcripts',
        code: '1168'
    },
    {
        name: '4506T Single',
        containerLabel: '4506T Request for Transcripts',
        code: '1169'
    },
    {
        name: 'Ability to Repay Worksheet',
        containerLabel: 'ATR QM Worksheet',
        code: '1567'
    },
    {
        name: 'Acknowledgement as to Fair Market Value of Homestead Property',
        containerLabel: 'Disclosures - Misc',
        code: '728'
    },
    {
        name: 'Acknowledgement of Receipt of Closing Disclosure',
        containerLabel: 'Closing Disclosure',
        code: '1655'
    },
    {
        name: 'Acknowledgement of Receipt of Disclosures',
        containerLabel: 'Disclosures - Misc',
        code: '1367'
    },
    {
        name: 'Acknowledgement of Receipt of Loan Estimate',
        containerLabel: 'Disclosures - Misc',
        code: '1654'
    },
    {
        name: 'Acknowledgment of Note Delivery',
        containerLabel: 'Closing Docs - Misc',
        code: '186'
    },
    {
        name: 'Acknowledgment Waiver Rider',
        containerLabel: 'Deed of Trust or Mortgage - Certified',
        code: '2041'
    },
    {
        name: 'Act of Cash Sale',
        containerLabel: 'Closing Docs - Misc',
        code: '1883'
    },
    {
        name: 'Addendum to Closing Disclosure',
        containerLabel: 'Closing Disclosure',
        code: '1646'
    },
    {
        name: 'Addendum to Residential Mortgage Loan Application',
        containerLabel: '92900A - Addendum to Application',
        code: '272'
    },
    {
        name: 'Address Certification',
        containerLabel: 'Closing Docs - Misc',
        code: '611'
    },
    {
        name: 'Adjustable Rate Balloon Rider',
        containerLabel: 'Deed of Trust or Mortgage - Certified',
        code: '1256'
    },
    {
        name: 'Adjustable Rate Rider',
        containerLabel: 'Deed of Trust or Mortgage - Certified',
        code: '244'
    },
    {
        name: 'Advance Fee Agreement',
        containerLabel: 'Fee Sheet',
        code: '1834'
    },
    {
        name: 'Affidavit Other',
        containerLabel: 'Closing Docs - Misc',
        code: '135'
    },
    {
        name: 'Affiliated Business Arrangement Disclosure Statement Notice',
        containerLabel: 'Closing Docs - Misc',
        code: '101'
    },
    {
        name: 'Alive and Well Certification',
        containerLabel: 'Closing Docs - Misc',
        code: '1460'
    },
    {
        name: 'Allonge',
        containerLabel: 'Allonge to Note',
        code: '537'
    },
    {
        name: 'Allonge to Note',
        containerLabel: 'Allonge to Note',
        code: '153'
    },
    {
        name: 'ALTA Settlement Statement',
        containerLabel: 'Loan Estimate',
        code: '600'
    },
    {
        name: 'Amendatory Clause',
        containerLabel: 'Deed of Trust or Mortgage - Certified',
        code: '301'
    },
    {
        name: 'Amortization Schedule',
        containerLabel: 'Amortization Schedule',
        code: '31'
    },
    {
        name: 'Anti-Steering or Anti-Coercion Disclosure',
        containerLabel: 'Disclosures - Misc',
        code: '24'
    },
    {
        name: 'Applicant Credit Disclosure',
        containerLabel: 'Disclosures - Misc',
        code: '1832'
    },
    {
        name: 'Application Disclosure',
        containerLabel: 'Disclosures - Misc',
        code: '353'
    },
    {
        name: 'Application Fee Disclosure',
        containerLabel: 'Disclosures - Misc',
        code: '732'
    },
    {
        name: 'Appraisal',
        containerLabel: 'Appraisal',
        code: '263'
    },
    {
        name: 'Appraisal Acknowledgement',
        containerLabel: 'Disclosures - Misc',
        code: '1032'
    },
    {
        name: 'Appraisal Disclosure',
        containerLabel: 'Disclosures - Misc',
        code: '86'
    },
    {
        name: 'Appraisal Findings Detail Report',
        containerLabel: 'Appraisal Misc',
        code: '1399'
    },
    {
        name: 'Appraisal Independence Requirements Acknowledgement Disclosure',
        containerLabel: 'Appraisal Misc',
        code: '1391'
    },
    {
        name: 'Appraisal Invoice',
        containerLabel: 'Invoices & Checks - Internal',
        code: '417'
    },
    {
        name: 'Appraisal Logging',
        containerLabel: 'Appraisal Misc',
        code: '329'
    },
    {
        name: 'Appraisal Update and or completion report',
        containerLabel: 'Inspection Completed by Local or FHA Inspector',
        code: '525'
    },
    {
        name: 'Appraised Value Disclosure',
        containerLabel: '92800.5B Appraised Value',
        code: '1342'
    },
    {
        name: 'Appraiser License',
        containerLabel: 'Appraisal Misc',
        code: '1279'
    },
    {
        name: 'ARM Disclosure',
        containerLabel: 'Disclosures - Misc',
        code: '392'
    },
    {
        name: 'Asset Other',
        containerLabel: 'Assets - Misc',
        code: '937'
    },
    {
        name: 'Assignment of Mortgage',
        containerLabel: 'Assignment - MERS / MIN',
        code: '174'
    },
    {
        name: 'Attorney Disclosure',
        containerLabel: 'Disclosures - Misc',
        code: '246'
    },
    {
        name: 'Attorneys Certification of Title',
        containerLabel: 'Title - Misc',
        code: '572'
    },
    {
        name: 'Authorization to Complete Blank Spaces',
        containerLabel: 'Disclosures - Misc',
        code: '1841'
    },
    {
        name: 'Authorization to Release Information',
        containerLabel: 'Borrower Certification and Authorization',
        code: '261'
    },
    {
        name: 'Automated Valuation Model Disclosure',
        containerLabel: 'Appraisal Misc',
        code: '1842'
    },
    {
        name: 'AVM Automated Valuation',
        containerLabel: 'SSR / UCDP',
        code: '234'
    },
    {
        name: 'Avoid Mortgage Pitfalls',
        containerLabel: 'Disclosures - Misc',
        code: '1372'
    },
    {
        name: 'Bailee Letter',
        containerLabel: 'Wire Instructions',
        code: '155'
    },
    {
        name: 'Balloon Rider',
        containerLabel: 'Deed of Trust or Mortgage - Certified',
        code: '17'
    },
    {
        name: 'Bank Statement Worksheet',
        containerLabel: 'Bank Statements',
        code: '2020'
    },
    {
        name: 'Bank Statements',
        containerLabel: 'Bank Statements',
        code: '1137'
    },
    {
        name: 'Bankruptcy Papers',
        containerLabel: 'Bankruptcy Docs',
        code: '370'
    },
    {
        name: 'Birth Certificate',
        containerLabel: 'Conditions - Internal',
        code: '1258'
    },
    {
        name: 'Borrower Acknowledgement of Appraisal Delivery',
        containerLabel: 'Disclosures - Misc',
        code: '1418'
    },
    {
        name: 'Borrower Interest Rate Date',
        containerLabel: 'Disclosures - Misc',
        code: '1476'
    },
    {
        name: 'Borrowers Acknowledgement to Pay Supplemental Tax Bill',
        containerLabel: 'Disclosures - Misc',
        code: '1379'
    },
    {
        name: 'Borrowers Authorization to Obtain a Credit Report',
        containerLabel: 'Borrower Certification and Authorization',
        code: '1381'
    },
    {
        name: 'Borrowers Certification and Authorization Letter',
        containerLabel: 'Borrower Certification and Authorization',
        code: '421'
    },
    {
        name: 'Borrowers Contract with Respect to Hotel and Transient use of Property',
        containerLabel: 'Disclosures - Misc',
        code: '226'
    },
    {
        name: 'Borrowers Identity of Interest Disclosure',
        containerLabel: 'Disclosures - Misc',
        code: '1802'
    },
    {
        name: 'Borrowers Signature Authorization',
        containerLabel: 'Borrower Certification and Authorization',
        code: '282'
    },
    {
        name: 'Broker Agent Certification in an FHA Insured Loan Transaction',
        containerLabel: 'Govt Misc',
        code: '1085'
    },
    {
        name: 'Broker and Fee Agreement',
        containerLabel: 'Fee Sheet',
        code: '175'
    },
    {
        name: 'Builders Certification of Plans Specifications and Site',
        containerLabel: 'Builders Certification',
        code: '1245'
    },
    {
        name: 'Buydown and Escrow Agreement',
        containerLabel: 'Buydown Agreement',
        code: '384'
    },
    {
        name: 'CAIVRS Number Results',
        containerLabel: 'CAIVRS',
        code: '1952'
    },
    {
        name: 'California Additional Per Diem Interest Charge Disclosure',
        containerLabel: 'Disclosures - Misc',
        code: '1398'
    },
    {
        name: 'California Finance Lenders Law Statement of Loan Disclosure',
        containerLabel: 'Disclosures - Misc',
        code: '1388'
    },
    {
        name: 'California Loan Impound Disclosure and Waiver',
        containerLabel: 'Disclosures - Misc',
        code: '1389'
    },
    {
        name: 'California Per Diem Interest Disclosure',
        containerLabel: 'Disclosures - Misc',
        code: '376'
    },
    {
        name: 'Case Number Assignment Results',
        containerLabel: 'FHA Case Number Assignment',
        code: '47'
    },
    {
        name: 'Certificate of HVCC',
        containerLabel: 'Appraisal Misc',
        code: '1158'
    },
    {
        name: 'Certificate of Occupancy',
        containerLabel: 'Certificate of Occupancy',
        code: '466'
    },
    {
        name: 'Certificate of Release or Discharge from Active Duty',
        containerLabel: 'Disclosures - Misc',
        code: '712'
    },
    {
        name: 'Certification of Non Referral',
        containerLabel: 'Closing Docs - Misc',
        code: '416'
    },
    {
        name: 'Changed Circumstance',
        containerLabel: 'Change of Circumstances',
        code: '1159'
    },
    {
        name: 'Check from Title Company',
        containerLabel: 'Closing Docs - Misc',
        code: '88'
    },
    {
        name: 'Checks Other',
        containerLabel: 'Invoices & Checks - Internal',
        code: '1149'
    },
    {
        name: 'Child Care Expense Statement',
        containerLabel: 'VA Child Care Letter',
        code: '1376'
    },
    {
        name: 'Child Support Documentation',
        containerLabel: 'Income Misc',
        code: '1262'
    },
    {
        name: 'Choice of Insurance Notice',
        containerLabel: 'Disclosures - Misc',
        code: '1854'
    },
    {
        name: 'Choice of Settlement Agent',
        containerLabel: 'Disclosures - Settlement Service Provider List',
        code: '1170'
    },
    {
        name: 'Closing Disclosure',
        containerLabel: 'Closing Disclosure',
        code: '1624'
    },
    {
        name: 'Closing Instructions',
        containerLabel: 'Closing Instructions',
        code: '102'
    },
    {
        name: 'Closing Instructions Addendum',
        containerLabel: 'Closing Instructions',
        code: '744'
    },
    {
        name: 'Closing Protection Letter',
        containerLabel: 'Closing Protection Letter',
        code: '99'
    },
    {
        name: 'Closing Worksheet',
        containerLabel: 'Closing Instructions',
        code: '1959'
    },
    {
        name: 'Collateral Protection Insurance Notice',
        containerLabel: 'Disclosures - Misc',
        code: '310'
    },
    {
        name: 'College Transcript',
        containerLabel: 'Conditions - Internal',
        code: '1261'
    },
    {
        name: 'Commercially Reasonable Means or Mechanism Worksheet',
        containerLabel: 'Closing Docs - Misc',
        code: '1844'
    },
    {
        name: 'Commitment and Certificate',
        containerLabel: 'UW Approval',
        code: '446'
    },
    {
        name: 'Commitment Letter Waiver',
        containerLabel: 'Closing Docs - Misc',
        code: '1864'
    },
    {
        name: 'Compliance Agreement',
        containerLabel: 'Compliance Agreement',
        code: '472'
    },
    {
        name: 'Compliance Inspection Report',
        containerLabel: 'Compliance Inspection Report',
        code: '624'
    },
    {
        name: 'Compliance Summary',
        containerLabel: 'Compliance Agreement',
        code: '1953'
    },
    {
        name: 'Conditional Commitment Direct Endorsement Statement of Appraised Value',
        containerLabel: '92800.5B Appraised Value',
        code: '467'
    },
    {
        name: 'Conditional Commitment for Single Family Housing Loan Guarantee',
        containerLabel: 'UW Approval',
        code: '1419'
    },
    {
        name: 'Condo Insurance',
        containerLabel: 'Condo Docs',
        code: '1171'
    },
    {
        name: 'Condominium or PUD Related Documentation',
        containerLabel: 'Condo Docs',
        code: '1160'
    },
    {
        name: 'Condominium Rider',
        containerLabel: 'Deed of Trust or Mortgage - Certified',
        code: '302'
    },
    {
        name: 'Conflict of Interest Disclosure',
        containerLabel: 'Disclosures - Misc',
        code: '372'
    },
    {
        name: 'Consent to Receive Documents Electronically',
        containerLabel: 'E-Consent',
        code: '1813'
    },
    {
        name: 'Consequences to Borrower of Providing Inaccurate Information',
        containerLabel: 'Disclosures - Misc',
        code: '1651'
    },
    {
        name: 'Consolidation Extension and Modification Agreement',
        containerLabel: 'Loan Modification Agreement',
        code: '1172'
    },
    {
        name: 'Construction Loan Agreement',
        containerLabel: 'Note Addendum',
        code: '626'
    },
    {
        name: 'Consultant Work Write Up',
        containerLabel: 'Closing Docs - Misc',
        code: '1946'
    },
    {
        name: 'Contract of Sale',
        containerLabel: 'Sales Contract / Purchase Agreement',
        code: '359'
    },
    {
        name: 'Contract of Sale Addendum',
        containerLabel: 'Sales Contract / Purchase Agreement',
        code: '1266'
    },
    {
        name: 'Correction Agreement Limited POA',
        containerLabel: 'Power of Attorney',
        code: '1173'
    },
    {
        name: 'Correspondence',
        containerLabel: 'Internal Miscellaneous',
        code: '536'
    },
    {
        name: 'Counseling Checklist for Military Homebuyers',
        containerLabel: 'First Time Homebuyer Counseling Cert',
        code: '345'
    },
    {
        name: 'Cover Letter',
        containerLabel: 'Income Misc',
        code: '1638'
    },
    {
        name: 'CPA Letter',
        containerLabel: 'Income Misc',
        code: '1063'
    },
    {
        name: 'Credit Explanation Letter',
        containerLabel: 'Credit Explanation Letter',
        code: '1161'
    },
    {
        name: 'Credit Report Joint',
        containerLabel: 'Credit Report',
        code: '30'
    },
    {
        name: 'Credit Report Single',
        containerLabel: 'Credit Report',
        code: '80'
    },
    {
        name: 'Credit Report Supplement',
        containerLabel: 'Credit Report',
        code: '2038'
    },
    {
        name: 'Credit Score Disclosure Notice to the Home Loan Applicant',
        containerLabel: 'Disclosures - Misc',
        code: '314'
    },
    {
        name: 'Customer Identification Documentation Patriot Act',
        containerLabel: 'Disclosures - Patriot Act',
        code: '337'
    },
    {
        name: 'Debt and Income Worksheet',
        containerLabel: 'Income Calculation Worksheet',
        code: '1565'
    },
    {
        name: 'Debt Questionnaire',
        containerLabel: 'Conditions - Internal',
        code: '410'
    },
    {
        name: 'Deed',
        containerLabel: 'Deed of Trust or Mortgage - Certified',
        code: '19'
    },
    {
        name: 'Deed Of Trust',
        containerLabel: 'Deed of Trust or Mortgage - Certified',
        code: '100'
    },
    {
        name: 'Delivery of Net Proceeds Authorization',
        containerLabel: 'Net Tangible Benefits Worksheet',
        code: '114'
    },
    {
        name: 'Delivery Service Fee Authorization',
        containerLabel: 'Conditions - Internal',
        code: '1877'
    },
    {
        name: 'Demographic Information Addendum',
        containerLabel: 'Disclosures - Misc',
        code: '1821'
    },
    {
        name: 'Department of Veterans Affairs Benefits Increased',
        containerLabel: 'Govt Misc',
        code: '1343'
    },
    {
        name: 'Department of Veterans Affairs Loan Analysis',
        containerLabel: 'VA - Loan Analysis 26-6393',
        code: '1451'
    },
    {
        name: 'Description of Underwriting Criteria and Required Documentation',
        containerLabel: 'UW Approval',
        code: '1102'
    },
    {
        name: 'Desktop Appraisal Review',
        containerLabel: 'AUS Findings',
        code: '1395'
    },
    {
        name: 'Diploma',
        containerLabel: 'Conditions - Internal',
        code: '1260'
    },
    {
        name: 'Direct Endorsement Underwriter - HUD Reviewer Analysis of Appraisal Report',
        containerLabel: 'Govt Misc',
        code: '917'
    },
    {
        name: 'Disclosure Dates Screen Shots',
        containerLabel: 'Disclosures - Misc',
        code: '1659'
    },
    {
        name: 'Disclosure Notice',
        containerLabel: 'Disclosures - Misc',
        code: '638'
    },
    {
        name: 'Disclosure of Business Relationships',
        containerLabel: 'Disclosures - Misc',
        code: '815'
    },
    {
        name: 'Disclosure of Certain Mortgage Loan Provisions',
        containerLabel: 'Disclosures - Misc',
        code: '1860'
    },
    {
        name: 'Disclosure Other',
        containerLabel: 'Disclosures - Misc',
        code: '251'
    },
    {
        name: 'Disclosure Tracking Form',
        containerLabel: 'Disclosures - Misc',
        code: '1650'
    },
    {
        name: 'Discount Point Fee Disclosure',
        containerLabel: 'Disclosures - Misc',
        code: '1845'
    },
    {
        name: 'Divorce Decree',
        containerLabel: 'Divorce Documentation',
        code: '402'
    },
    {
        name: 'Draw Request',
        containerLabel: 'Appraisal Misc',
        code: '1798'
    },
    {
        name: 'ECOA Notice Application Disclosure',
        containerLabel: 'Disclosures - Misc',
        code: '58'
    },
    {
        name: 'Effect of Untimely Payment Disclosure',
        containerLabel: 'Disclosures - Misc',
        code: '1867'
    },
    {
        name: 'Elevation Certificate',
        containerLabel: 'Flood Certificate/Notice',
        code: '1947'
    },
    {
        name: 'Email Authorization Form',
        containerLabel: 'Disclosures - Misc',
        code: '1370'
    },
    {
        name: 'Encompass Product and Pricing Service',
        containerLabel: 'Lock Confirmation',
        code: '1960'
    },
    {
        name: 'Equal Credit Opportunity Act',
        containerLabel: 'Equal Credit Opportunity Act',
        code: '348'
    },
    {
        name: 'Escrow Analysis',
        containerLabel: 'Escrow Docs',
        code: '1413'
    },
    {
        name: 'Escrow Instructions',
        containerLabel: 'Escrow Docs',
        code: '933'
    },
    {
        name: 'Escrow Waiver',
        containerLabel: 'Escrow Docs',
        code: '1150'
    },
    {
        name: 'Failure to Close within Specified Commitment Period Disclosure',
        containerLabel: 'Disclosures - Misc',
        code: '1103'
    },
    {
        name: 'Fair Credit Reporting Act',
        containerLabel: 'Disclosures - Misc',
        code: '1835'
    },
    {
        name: 'Federal Collection Policy Notice',
        containerLabel: 'Disclosures - Misc',
        code: '199'
    },
    {
        name: 'Fee Detail Sheet',
        containerLabel: 'Fee Sheet',
        code: '1568'
    },
    {
        name: 'Fee Disclosure',
        containerLabel: 'Fee Sheet',
        code: '179'
    },
    {
        name: 'FFIEC Rate Spread Calculator',
        containerLabel: 'Disclosures - Misc',
        code: '1473'
    },
    {
        name: 'FHA Addendum',
        containerLabel: '92900A - Addendum to Application',
        code: '105'
    },
    {
        name: 'FHA Connection',
        containerLabel: 'FHA Case Number Assignment',
        code: '235'
    },
    {
        name: 'FHA Loan Underwriting Summary Joint',
        containerLabel: '92900 FHA Loan Transmittal',
        code: '1176'
    },
    {
        name: 'FHA Loan Underwriting Summary Single',
        containerLabel: '92900 FHA Loan Transmittal',
        code: '1177'
    },
    {
        name: 'FHA Planned Unit Development Rider',
        containerLabel: 'Deed of Trust or Mortgage - Certified',
        code: '543'
    },
    {
        name: 'FHA Rehabilitation Loan Rider',
        containerLabel: 'Deed of Trust or Mortgage - Certified',
        code: '582'
    },
    {
        name: 'FHA Streamline Worksheet',
        containerLabel: 'Govt Misc',
        code: '1132'
    },
    {
        name: 'FHA Total Scorecard Findings',
        containerLabel: 'Govt Misc',
        code: '1652'
    },
    {
        name: 'FHA VA Allowed Disallowed Borrower Paid Charges Disclosure',
        containerLabel: 'Govt Misc',
        code: '1101'
    },
    {
        name: 'FHA-Disclosures-Amendatory-Clause-Real-Estate-Certification',
        containerLabel: 'Govt Misc',
        code: '264'
    },
    {
        name: 'Field Review',
        containerLabel: 'Appraisal Misc',
        code: '914'
    },
    {
        name: 'Final Docs Assignment of Mortgage',
        containerLabel: 'Assignment - MERS / MIN',
        code: '1125'
    },
    {
        name: 'Final TIL Testing Disclosure Worksheet',
        containerLabel: 'Disclosures - Misc',
        code: '1961'
    },
    {
        name: 'Financing Agreement',
        containerLabel: 'Disclosures - Misc',
        code: '269'
    },
    {
        name: 'First Lien Letter',
        containerLabel: 'First Payment Letter',
        code: '1836'
    },
    {
        name: 'First Time Home Buyer Loan Program Docs',
        containerLabel: 'First Time Homebuyer Counseling Cert',
        code: '1178'
    },
    {
        name: 'Fixed Interest Rate Rider',
        containerLabel: 'Deed of Trust or Mortgage - Certified',
        code: '2032'
    },
    {
        name: 'Fixed-Adjustable Rate Rider',
        containerLabel: 'Deed of Trust or Mortgage - Certified',
        code: '539'
    },
    {
        name: 'Float Lock Agreement',
        containerLabel: 'Lock Confirmation',
        code: '1373'
    },
    {
        name: 'Flood Cert Disclosure',
        containerLabel: 'Flood Certificate/Notice',
        code: '1369'
    },
    {
        name: 'Flood Certification',
        containerLabel: 'Flood Certificate/Notice',
        code: '1179'
    },
    {
        name: 'Flood disaster Protection Act of 1973',
        containerLabel: 'Flood Certificate/Notice',
        code: '71'
    },
    {
        name: 'Flood Hazard Notification',
        containerLabel: 'Flood Certificate/Notice',
        code: '358'
    },
    {
        name: 'Flood Paid Receipt',
        containerLabel: 'Flood Insurance Policy',
        code: '154'
    },
    {
        name: 'Flood-Insurance',
        containerLabel: 'Flood Insurance Policy',
        code: '1180'
    },
    {
        name: 'For Your Protection - Get a Home Inspection',
        containerLabel: 'Disclosures - Ownership Counseling',
        code: '583'
    },
    {
        name: 'Forbearance Agreement',
        containerLabel: 'Closing Docs - Misc',
        code: '1415'
    },
    {
        name: 'Foreclosure Disclosure',
        containerLabel: 'Disclosures - Misc',
        code: '330'
    },
    {
        name: 'Form 1040A',
        containerLabel: 'Tax Returns',
        code: '2046'
    },
    {
        name: 'Form 1040EZ',
        containerLabel: 'Tax Returns',
        code: '2047'
    },
    {
        name: 'Form 1040NR',
        containerLabel: 'Tax Returns',
        code: '2048'
    },
    {
        name: 'Form 1125-E',
        containerLabel: 'Govt Misc',
        code: '2045'
    },
    {
        name: 'Form 2439',
        containerLabel: 'Govt Misc',
        code: '2043'
    },
    {
        name: 'Form 4562',
        containerLabel: 'Govt Misc',
        code: '2049'
    },
    {
        name: 'Form 4868',
        containerLabel: 'Govt Misc',
        code: '2050'
    },
    {
        name: 'Form 8829',
        containerLabel: 'Govt Misc',
        code: '2042'
    },
    {
        name: 'Freddie Mac Funding Details Report',
        containerLabel: 'Purchase Advice',
        code: '1973'
    },
    {
        name: 'Funding Fee Notice',
        containerLabel: 'Disclosures - Misc',
        code: '1181'
    },
    {
        name: 'Gift Letter',
        containerLabel: 'Gift Docs',
        code: '294'
    },
    {
        name: 'GLS Lender Loan Closing Confirmation',
        containerLabel: 'Conditions - Internal',
        code: '1819'
    },
    {
        name: 'Good Faith Estimate',
        containerLabel: 'Disclosures - Loan Estimate',
        code: '229'
    },
    {
        name: 'Grantee Rider',
        containerLabel: 'Closing Docs - Misc',
        code: '2037'
    },
    {
        name: 'Guaranteed Loan Closing Report',
        containerLabel: 'Govt Misc',
        code: '1420'
    },
    {
        name: 'Guaranteed Rural Housing Lender Record Change',
        containerLabel: 'Govt Misc',
        code: '1421'
    },
    {
        name: 'GUS Underwriting Findings',
        containerLabel: 'Govt Misc',
        code: '1653'
    },
    {
        name: 'Hardship',
        containerLabel: 'Credit Misc',
        code: '1387'
    },
    {
        name: 'Hazard Insurance',
        containerLabel: 'Hazard Insurance Policy',
        code: '1182'
    },
    {
        name: 'Hazard Insurance Authorization and Requirements',
        containerLabel: 'Hazard Insurance Policy',
        code: '1402'
    },
    {
        name: 'Hazard Invoice',
        containerLabel: 'Hazard Insurance Policy',
        code: '216'
    },
    {
        name: 'Hazard Paid Receipt',
        containerLabel: 'Hazard Insurance Policy',
        code: '425'
    },
    {
        name: 'HELOC Rider',
        containerLabel: '2nd Deed of Trust/Mortgage',
        code: '2034'
    },
    {
        name: 'High Loan to Value Disclosure',
        containerLabel: 'Disclosures - Misc',
        code: '1849'
    },
    {
        name: 'HOEPA HMDA Required Information',
        containerLabel: 'HOEPA/Section 32',
        code: '1243'
    },
    {
        name: 'Hold Harmless Letter',
        containerLabel: 'Hold Harmless Agreement',
        code: '18'
    },
    {
        name: 'Home Equity Credit Line and Disclosure Statement',
        containerLabel: 'Disclosures - Misc',
        code: '806'
    },
    {
        name: 'Home Owners Association (HOA) Documentation',
        containerLabel: 'Closing Docs - Misc',
        code: '2024'
    },
    {
        name: 'Home Valuation Code of Conduct - Appraisal Acknowledgement',
        containerLabel: 'Appraisal Misc',
        code: '1472'
    },
    {
        name: 'Home Value Explorer',
        containerLabel: 'Appraisal Misc',
        code: '1396'
    },
    {
        name: 'Homeowner Contractor Agreement',
        containerLabel: 'Closing Docs - Misc',
        code: '1491'
    },
    {
        name: 'Homeowners Insurance Quote Disclosure',
        containerLabel: 'Disclosures - Ownership Counseling',
        code: '1374'
    },
    {
        name: 'Homeownership Counseling Notice-Disclosure',
        containerLabel: 'Disclosures - Ownership Counseling',
        code: '1643'
    },
    {
        name: 'HomeReady Cert of Completion',
        containerLabel: 'Appraisal Misc',
        code: '1799'
    },
    {
        name: 'HomeStyle Renovation Consumer Tips',
        containerLabel: 'Disclosures - Misc',
        code: '1828'
    },
    {
        name: 'HomeStyle Renovation Maximum Mortgage Worksheet',
        containerLabel: 'Appraisal Misc',
        code: '1812'
    },
    {
        name: 'Housing Counseling Attachment C to Mortgagee Letter 96 48',
        containerLabel: 'Closing Docs - Misc',
        code: '723'
    },
    {
        name: 'HUD 1 Settlement Statement',
        containerLabel: 'Closing Disclosure',
        code: '300'
    },
    {
        name: 'HUD VA Addendum',
        containerLabel: 'Govt Misc',
        code: '1185'
    },
    {
        name: 'HUD VA Addendum Single',
        containerLabel: '92900A - Addendum to Application',
        code: '1186'
    },
    {
        name: 'HUD-1-Addendum',
        containerLabel: 'Closing Disclosure',
        code: '1222'
    },
    {
        name: 'ID Joint',
        containerLabel: 'Photo ID',
        code: '516'
    },
    {
        name: 'ID Single',
        containerLabel: 'Photo ID',
        code: '127'
    },
    {
        name: 'Illinois Borrower Information Document',
        containerLabel: 'Closing Docs - Misc',
        code: '201'
    },
    {
        name: 'Illinois Escrow Election Letter',
        containerLabel: 'Closing Docs - Misc',
        code: '1088'
    },
    {
        name: 'Illinois Notice of Choice of Agent or Insurer',
        containerLabel: 'Disclosures - Misc',
        code: '1086'
    },
    {
        name: 'Importance of Home Inspections',
        containerLabel: '92564-CN - For Your Protection',
        code: '104'
    },
    {
        name: 'Important Applicant Information',
        containerLabel: 'Disclosures - Misc',
        code: '1366'
    },
    {
        name: 'Important Notice to Homebuyers',
        containerLabel: '92900-B - Important Notice to Homebuyers',
        code: '308'
    },
    {
        name: 'Impound Authorization',
        containerLabel: 'Escrow Docs',
        code: '649'
    },
    {
        name: 'Income Calculations Worksheet',
        containerLabel: 'Income Calculation Worksheet',
        code: '1242'
    },
    {
        name: 'Income Other',
        containerLabel: 'Income Misc',
        code: '7'
    },
    {
        name: 'Indenture',
        containerLabel: 'Closing Docs - Misc',
        code: '110'
    },
    {
        name: 'Information Disclosure Authorization',
        containerLabel: 'Disclosures - Misc',
        code: '1365'
    },
    {
        name: 'Informed Consumer Choice Disclosure Notice',
        containerLabel: 'Disclosures - Misc',
        code: '90'
    },
    {
        name: 'Initial Closing Disclosure Notice',
        containerLabel: 'Closing Disclosure',
        code: '1839'
    },
    {
        name: 'Initial Escrow Account Disclosure Statement',
        containerLabel: 'Escrow Docs',
        code: '50'
    },
    {
        name: 'Insurance Rating',
        containerLabel: 'Closing Docs - Misc',
        code: '2025'
    },
    {
        name: 'Intent to Proceed',
        containerLabel: 'Disclosures - Intent to Proceed',
        code: '1162'
    },
    {
        name: 'Inter Vivos Trust Rider',
        containerLabel: 'Deed of Trust or Mortgage - Certified',
        code: '2028'
    },
    {
        name: 'Interest Rate and Discount Disclosure Statement',
        containerLabel: 'Lock Confirmation',
        code: '299'
    },
    {
        name: 'Interest Rate-Lock Timing Disclosure',
        containerLabel: 'Disclosures - Misc',
        code: '1851'
    },
    {
        name: 'InterestRateReductionRefinancingLoanWorksheet',
        containerLabel: 'Govt Misc',
        code: '699'
    },
    {
        name: 'Investment Statement',
        containerLabel: 'Stock/Bonds Docs',
        code: '34'
    },
    {
        name: 'IRRRL Loan Comparison Statement',
        containerLabel: 'Govt Misc',
        code: '697'
    },
    {
        name: 'Itemization of Amount Financed',
        containerLabel: 'Itemized Fee Worksheet',
        code: '139'
    },
    {
        name: 'Itemization of Settlement Charges',
        containerLabel: 'Itemized Fee Worksheet',
        code: '1642'
    },
    {
        name: 'Lead Based Paint Certification',
        containerLabel: 'Govt Misc',
        code: '1816'
    },
    {
        name: 'Lease',
        containerLabel: 'Appraisal Misc',
        code: '259'
    },
    {
        name: 'Leasehold Rider',
        containerLabel: 'Deed of Trust or Mortgage - Certified',
        code: '2033'
    },
    {
        name: 'Legal Description',
        containerLabel: 'Legal Description',
        code: '192'
    },
    {
        name: 'Lender Affiliate Arrangement Certification for the Purpose of QM',
        containerLabel: 'ATR QM Worksheet',
        code: '1578'
    },
    {
        name: 'Lenders Certification',
        containerLabel: 'Disclosures - Misc',
        code: '449'
    },
    {
        name: 'Lenders Notice of Value',
        containerLabel: 'Notice of Value (NOV)',
        code: '419'
    },
    {
        name: 'Letter from Borrower',
        containerLabel: 'Credit Explanation Letter',
        code: '182'
    },
    {
        name: 'Licensee Information',
        containerLabel: 'Disclosures - Misc',
        code: '1241'
    },
    {
        name: 'Licensing Disclosure',
        containerLabel: 'Disclosures - Misc',
        code: '1861'
    },
    {
        name: 'Limited Denial of Participation and Voluntary Abstention List',
        containerLabel: 'Govt Misc',
        code: '458'
    },
    {
        name: 'Loan Agreement Rider',
        containerLabel: 'Deed of Trust or Mortgage - Certified',
        code: '2036'
    },
    {
        name: 'Loan Analysis',
        containerLabel: 'VA - Loan Analysis 26-6393',
        code: '455'
    },
    {
        name: 'Loan Approval',
        containerLabel: 'UW Approval',
        code: '1157'
    },
    {
        name: 'Loan Closing Advisor - FreddieMac',
        containerLabel: 'AUS Findings',
        code: '1954'
    },
    {
        name: 'Loan Commitment',
        containerLabel: 'UW Approval',
        code: '480'
    },
    {
        name: 'Loan Detail Report',
        containerLabel: 'Compliance Inspection Report',
        code: '1582'
    },
    {
        name: 'Loan Estimate',
        containerLabel: 'Loan Estimate',
        code: '1625'
    },
    {
        name: 'Loan Note Guarantee',
        containerLabel: 'Govt Misc',
        code: '971'
    },
    {
        name: 'Loan Options Disclosure',
        containerLabel: 'Disclosures - Misc',
        code: '1874'
    },
    {
        name: 'Loan Submission Checklist',
        containerLabel: 'Investor Submission Form/Checklist',
        code: '563'
    },
    {
        name: 'Loan Summary',
        containerLabel: 'UW Approval',
        code: '653'
    },
    {
        name: 'Loan-Product-Advisor-Feedback-Certificate',
        containerLabel: 'AUS Findings',
        code: '1807'
    },
    {
        name: 'LoanProspectorFullFeedbackCertificate',
        containerLabel: 'AUS Findings',
        code: '801'
    },
    {
        name: 'Lock In Agreement',
        containerLabel: 'Lock Confirmation',
        code: '240'
    },
    {
        name: 'Lock In Confirmation',
        containerLabel: 'Lock Confirmation',
        code: '1268'
    },
    {
        name: 'Lock In Document',
        containerLabel: 'Lock Confirmation',
        code: '1187'
    },
    {
        name: 'Lock-in Expiration Notice',
        containerLabel: 'Lock Confirmation',
        code: '1853'
    },
    {
        name: 'Manufactured Home Rider',
        containerLabel: 'Closing Docs - Misc',
        code: '2031'
    },
    {
        name: 'Market Conditions Addendum to the Appraisal Report',
        containerLabel: 'Appraisal Misc',
        code: '2040'
    },
    {
        name: 'Marriage License',
        containerLabel: 'Conditions - Internal',
        code: '1259'
    },
    {
        name: 'Master Policies',
        containerLabel: 'Condo Docs',
        code: '1811'
    },
    {
        name: 'Mavent Compliance Review',
        containerLabel: 'Fraud Reports',
        code: '1385'
    },
    {
        name: 'MERS Rider',
        containerLabel: 'Assignment - MERS / MIN',
        code: '2027'
    },
    {
        name: 'MIN Number Documentation',
        containerLabel: 'Assignment - MERS / MIN',
        code: '1163'
    },
    {
        name: 'Miscellaneous',
        containerLabel: 'Internal Miscellaneous',
        code: '5'
    },
    {
        name: 'Modification Agreement',
        containerLabel: 'Loan Modification Agreement',
        code: '109'
    },
    {
        name: 'Mortgage',
        containerLabel: 'Deed of Trust or Mortgage - Certified',
        code: '498'
    },
    {
        name: 'Mortgage Application Disclosure',
        containerLabel: '1003 Loan Application - Initial',
        code: '23'
    },
    {
        name: 'Mortgage Banker Application Disclosure',
        containerLabel: 'Disclosures - Misc',
        code: '657'
    },
    {
        name: 'Mortgage Broker Fee Agreement and Disclosure',
        containerLabel: 'Disclosures - Misc',
        code: '56'
    },
    {
        name: 'Mortgage Broker Loan Origination Agreement',
        containerLabel: 'Disclosures - Misc',
        code: '113'
    },
    {
        name: 'Mortgage Credit Analysis Worksheet',
        containerLabel: 'Conditions - Internal',
        code: '25'
    },
    {
        name: 'Mortgage Fraud is Investigated by the FBI',
        containerLabel: 'Closing Docs - Misc',
        code: '696'
    },
    {
        name: 'Mortgage Insurance Certificate',
        containerLabel: 'PMI Certificate',
        code: '1962'
    },
    {
        name: 'Mortgage Insurance Disclosure',
        containerLabel: 'Disclosures - Misc',
        code: '812'
    },
    {
        name: 'Mortgage Statement',
        containerLabel: 'Internal Miscellaneous',
        code: '138'
    },
    {
        name: 'Mortgagee Assurance of Completion',
        containerLabel: 'Mortgage Assurance of Completion',
        code: '1948'
    },
    {
        name: 'NMLS Verification',
        containerLabel: 'Disclosures - Misc',
        code: '1579'
    },
    {
        name: 'No HUD Warranty',
        containerLabel: 'Closing Docs - Misc',
        code: '1087'
    },
    {
        name: 'Note',
        containerLabel: 'Note Addendum',
        code: '267'
    },
    {
        name: 'Notice concerning Equity Loan Extension of Credit-Cooling Off Period',
        containerLabel: 'Disclosures - Misc',
        code: '1423'
    },
    {
        name: 'Notice concerning refinance of existing home equity loan to non- home equity loan',
        containerLabel: 'Disclosures - Misc',
        code: '2022'
    },
    {
        name: 'Notice of Action Taken',
        containerLabel: 'Adverse Action Docs',
        code: '1951'
    },
    {
        name: 'Notice of Assignment Sale or Transfer of Servicing Rights',
        containerLabel: 'Servicing Transfer Disclosure',
        code: '1192'
    },
    {
        name: 'Notice of Change of Servicer',
        containerLabel: 'Servicing Transfer Disclosure',
        code: '2023'
    },
    {
        name: 'Notice of Legal Representation',
        containerLabel: 'Disclosures - Misc',
        code: '94'
    },
    {
        name: 'Notice of Material Change in Loan Terms',
        containerLabel: 'Disclosures - Misc',
        code: '1104'
    },
    {
        name: 'Notice of Penalties for Making a False or Misleading Written Statement',
        containerLabel: 'Disclosures - Misc',
        code: '1880'
    },
    {
        name: 'Notice of Right to Cancel Joint',
        containerLabel: 'Right of Rescission & Certs',
        code: '1193'
    },
    {
        name: 'Notice of Right to Cancel Single',
        containerLabel: 'Right of Rescission & Certs',
        code: '1194'
    },
    {
        name: 'Notice of Right to Discontinue Escrow',
        containerLabel: 'Disclosures - Misc',
        code: '1869'
    },
    {
        name: 'Notice of Right to Rescind',
        containerLabel: 'Right of Rescission & Certs',
        code: '769'
    },
    {
        name: 'Notice of Separate Credit Account',
        containerLabel: 'Disclosures - Misc',
        code: '1856'
    },
    {
        name: 'Notice Regarding Non Refundability of Loan Fees',
        containerLabel: 'Disclosures - Misc',
        code: '1857'
    },
    {
        name: 'Notice to Applicant of right to receive copy of appraisal report',
        containerLabel: 'Disclosures - Misc',
        code: '91'
    },
    {
        name: 'Notice To Borrower - Prospective Borrower',
        containerLabel: 'Disclosures - Misc',
        code: '1134'
    },
    {
        name: 'Notice to Cosigner',
        containerLabel: 'Disclosures - Misc',
        code: '406'
    },
    {
        name: 'Notice to Homeowner Assumption of HUD FHA insured Mortgages Release of Personal Liability',
        containerLabel: 'Govt Misc',
        code: '284'
    },
    {
        name: 'Notice to Purchaser - Mortgagor',
        containerLabel: 'Servicing Transfer Disclosure',
        code: '1871'
    },
    {
        name: 'Notices to Applicants',
        containerLabel: 'Disclosures - Intent to Proceed',
        code: '167'
    },
    {
        name: 'Notification Other',
        containerLabel: 'Disclosures - Misc',
        code: '463'
    },
    {
        name: 'Notification to Buyers of Withholding Tax Requirement',
        containerLabel: 'Disclosures - Misc',
        code: '207'
    },
    {
        name: 'Occupancy Affidavit',
        containerLabel: 'Occupancy Affidavit',
        code: '664'
    },
    {
        name: 'Occupancy Affidavit Employment Cert Joint',
        containerLabel: 'Occupancy Affidavit',
        code: '1196'
    },
    {
        name: 'Occupancy Affidavit Employment Cert Single',
        containerLabel: 'Occupancy Affidavit',
        code: '1197'
    },
    {
        name: 'Occupancy Rider',
        containerLabel: 'Deed of Trust or Mortgage - Certified',
        code: '2030'
    },
    {
        name: 'Oral Agreement Notice',
        containerLabel: 'Disclosures - Misc',
        code: '1858'
    },
    {
        name: 'Order IRRRL Case',
        containerLabel: 'CAIVRS',
        code: '1462'
    },
    {
        name: 'Patriot Act Information Disclosure',
        containerLabel: 'Disclosures - Patriot Act',
        code: '215'
    },
    {
        name: 'Pay History',
        containerLabel: 'Payment History',
        code: '296'
    },
    {
        name: 'Payment Coupon',
        containerLabel: 'First Payment Letter',
        code: '320'
    },
    {
        name: 'Payment Letter',
        containerLabel: 'First Payment Letter',
        code: '128'
    },
    {
        name: 'Payoff Letter',
        containerLabel: 'Payoff Statement - Max Loan Amount Worksheet',
        code: '1198'
    },
    {
        name: 'Paystubs',
        containerLabel: 'Paystubs',
        code: '64'
    },
    {
        name: 'Personal Property Insurance',
        containerLabel: 'Hazard Insurance Policy',
        code: '1263'
    },
    {
        name: 'Planned Unit Development Rider',
        containerLabel: 'Deed of Trust or Mortgage - Certified',
        code: '35'
    },
    {
        name: 'PMI-Certification',
        containerLabel: 'PMI Certificate',
        code: '1199'
    },
    {
        name: 'Policy Notices Disclosure',
        containerLabel: 'Disclosures - Misc',
        code: '367'
    },
    {
        name: 'Power of Attorney (POA)',
        containerLabel: 'Power of Attorney',
        code: '89'
    },
    {
        name: 'Pre Application Disclosure and Fee Agreement',
        containerLabel: 'Disclosures - Misc',
        code: '1200'
    },
    {
        name: 'PredProtect Report',
        containerLabel: 'Fraud Reports',
        code: '1569'
    },
    {
        name: 'Prevailing Interest Rate Commitment',
        containerLabel: 'Disclosures - Misc',
        code: '670'
    },
    {
        name: 'Privacy Notice or Disclosure',
        containerLabel: 'Disclosures - Misc',
        code: '1202'
    },
    {
        name: 'Private Mortgage Insurance Disclosure',
        containerLabel: 'Disclosures - Misc',
        code: '120'
    },
    {
        name: 'Processor Certification',
        containerLabel: 'Conditions - Internal',
        code: '437'
    },
    {
        name: 'Prohibited Acts and Practices Disclosure Regarding All Home Loans',
        containerLabel: 'Disclosures - Misc',
        code: '1862'
    },
    {
        name: 'Property Insurance Disclosure',
        containerLabel: 'Disclosures - Misc',
        code: '147'
    },
    {
        name: 'Property Miscellaneous',
        containerLabel: 'Appraisal Misc',
        code: '1810'
    },
    {
        name: 'Property Report',
        containerLabel: 'Appraisal Misc',
        code: '1251'
    },
    {
        name: 'Providers-of-Service',
        containerLabel: 'Disclosures - Misc',
        code: '121'
    },
    {
        name: 'Purchase Advice or Confirmation',
        containerLabel: 'Purchase Advice',
        code: '1341'
    },
    {
        name: 'QM Findings',
        containerLabel: 'ATR QM Worksheet',
        code: '1562'
    },
    {
        name: 'Rate Lock Cap Agreement',
        containerLabel: 'Lock Confirmation',
        code: '505'
    },
    {
        name: 'Rate Lock Sheet',
        containerLabel: 'Lock Confirmation',
        code: '1477'
    },
    {
        name: 'Re-verification of Employment',
        containerLabel: 'VOE - Verbal',
        code: '596'
    },
    {
        name: 'Receipt of Document Copies',
        containerLabel: 'Closing Docs - Misc',
        code: '777'
    },
    {
        name: 'Regulatory Agency Disclosure',
        containerLabel: 'Disclosures - Misc',
        code: '1863'
    },
    {
        name: 'Regulatory Required Data Certification',
        containerLabel: 'SSR / UCDP',
        code: '1656'
    },
    {
        name: 'Rehabilitation Loan Agreement',
        containerLabel: 'Closing Docs - Misc',
        code: '597'
    },
    {
        name: 'Renewal and Extension Exhibit',
        containerLabel: 'Lock Confirmation',
        code: '779'
    },
    {
        name: 'Replacement Cost Estimate',
        containerLabel: 'Conditions - Internal',
        code: '1964'
    },
    {
        name: 'Report and Certification of Loan Disbursement',
        containerLabel: 'Closing Docs - Misc',
        code: '145'
    },
    {
        name: 'Request for a Certificate of Eligibility',
        containerLabel: 'COE',
        code: '219'
    },
    {
        name: 'Request for Single Family Housing Loan Guarantee',
        containerLabel: 'Govt Misc',
        code: '1425'
    },
    {
        name: 'Required Documentation',
        containerLabel: 'Conditions - Internal',
        code: '1888'
    },
    {
        name: 'Required Use Providers Disclosure',
        containerLabel: 'Disclosures - Misc',
        code: '1375'
    },
    {
        name: 'Reserve Account Disclosure',
        containerLabel: 'Disclosures - Misc',
        code: '1870'
    },
    {
        name: 'Reserves National Guard Certification',
        containerLabel: 'Govt Misc',
        code: '65'
    },
    {
        name: 'Rider - Other',
        containerLabel: 'Closing Docs - Misc',
        code: '405'
    },
    {
        name: 'Rider to Note and Mortgage',
        containerLabel: 'Deed of Trust or Mortgage - Certified',
        code: '538'
    },
    {
        name: 'Rider to the Deed of Trust',
        containerLabel: 'Deed of Trust or Mortgage - Certified',
        code: '781'
    },
    {
        name: 'Rights of VA Loan Borrowers',
        containerLabel: 'Govt Misc',
        code: '701'
    },
    {
        name: 'Risk Assessment',
        containerLabel: 'Compliance Inspection Report',
        code: '1255'
    },
    {
        name: 'RRB-1099',
        containerLabel: 'Tax Returns',
        code: '2051'
    },
    {
        name: 'RRB-1099-R',
        containerLabel: 'Tax Returns',
        code: '2052'
    },
    {
        name: 'Schedule A',
        containerLabel: 'Tax Returns',
        code: '2053'
    },
    {
        name: 'Schedule B - Form 1040A or 1040',
        containerLabel: 'Tax Returns',
        code: '2003'
    },
    {
        name: 'Schedule C - Form 1040',
        containerLabel: 'Tax Returns',
        code: '1998'
    },
    {
        name: 'Schedule C-EZ',
        containerLabel: 'Tax Returns',
        code: '2005'
    },
    {
        name: 'Schedule K-1',
        containerLabel: 'Tax Returns',
        code: '1995'
    },
    {
        name: 'Schedule K-1 1120S',
        containerLabel: 'Tax Returns',
        code: '1996'
    },
    {
        name: 'Schedule-D---Form-1040',
        containerLabel: 'Tax Returns',
        code: '1999'
    },
    {
        name: 'Schedule-E---Form-1040',
        containerLabel: 'Tax Returns',
        code: '2000'
    },
    {
        name: 'Schedule-F---Form-1040',
        containerLabel: 'Tax Returns',
        code: '2006'
    },
    {
        name: 'Second Home Rider',
        containerLabel: 'Deed of Trust or Mortgage - Certified',
        code: '474'
    },
    {
        name: 'Seller Closing Disclosure',
        containerLabel: 'Closing Disclosure',
        code: '1645'
    },
    {
        name: 'Selling System Document Checklist',
        containerLabel: 'Investor Submission Form/Checklist',
        code: '1974'
    },
    {
        name: 'Septic Certification',
        containerLabel: 'Builders Certification',
        code: '1814'
    },
    {
        name: 'Servicing Disclosure Statement',
        containerLabel: 'Servicing Transfer Disclosure',
        code: '276'
    },
    {
        name: 'Servicing Documentation',
        containerLabel: 'Servicing Docs',
        code: '1238'
    },
    {
        name: 'Settlement Certification in an FHA-Insured Loan Transaction',
        containerLabel: 'Govt Misc',
        code: '1649'
    },
    {
        name: 'Signature Name Affidavit Joint',
        containerLabel: 'Borrower / Name Affidavit',
        code: '898'
    },
    {
        name: 'Signature Name Affidavit Single',
        containerLabel: 'Borrower / Name Affidavit',
        code: '1203'
    },
    {
        name: 'Social Security Number Certification',
        containerLabel: 'SSN Verification Docs',
        code: '137'
    },
    {
        name: 'Social Security Number Verification',
        containerLabel: 'SSN Verification Docs',
        code: '559'
    },
    {
        name: 'Social Security Release Form',
        containerLabel: 'Income - SS Award Letter, Pensions, Annuities',
        code: '1204'
    },
    {
        name: 'Social-Secuirty-Benefits-1099-Statement',
        containerLabel: 'Income - SS Award Letter, Pensions, Annuities',
        code: '1801'
    },
    {
        name: 'Social-Security-Benefits-Letter',
        containerLabel: 'Income - SS Award Letter, Pensions, Annuities',
        code: '1138'
    },
    {
        name: 'Source of Funds',
        containerLabel: 'Assets - Misc',
        code: '400'
    },
    {
        name: 'Statement of Credit Denial-Termination or Change',
        containerLabel: 'Adverse Action Docs',
        code: '1345'
    },
    {
        name: 'Statement of Information',
        containerLabel: 'Closing Docs - Misc',
        code: '1386'
    },
    {
        name: 'Statement of Service',
        containerLabel: 'Income Misc',
        code: '1955'
    },
    {
        name: 'Student Loan Information',
        containerLabel: 'Credit Misc',
        code: '785'
    },
    {
        name: 'Subordination Documents',
        containerLabel: 'Subordination Agreement',
        code: '1165'
    },
    {
        name: 'Supporting Credit Documents',
        containerLabel: 'Credit Misc',
        code: '1257'
    },
    {
        name: 'Survey or Plat Map',
        containerLabel: 'Survey or Plat Map',
        code: '342'
    },
    {
        name: 'Tangible Net Benefit',
        containerLabel: 'Govt Misc',
        code: '680'
    },
    {
        name: 'Tax Authorization Form',
        containerLabel: 'Tax Certificate',
        code: '502'
    },
    {
        name: 'Tax Exempt Rider',
        containerLabel: 'Tax Returns',
        code: '2035'
    },
    {
        name: 'Tax Return Transcript Joint',
        containerLabel: '4506T Request for Transcripts',
        code: '332'
    },
    {
        name: 'Tax Return Transcript Single',
        containerLabel: 'Tax Transcripts',
        code: '338'
    },
    {
        name: 'Tax Returns Business',
        containerLabel: 'Tax Returns',
        code: '838'
    },
    {
        name: 'Tax Returns Joint',
        containerLabel: 'Tax Returns',
        code: '211'
    },
    {
        name: 'Tax Returns Single',
        containerLabel: 'Tax Returns',
        code: '185'
    },
    {
        name: 'Tax Search',
        containerLabel: 'Tax Information Worksheet',
        code: '209'
    },
    {
        name: 'Tax-Information-Sheet',
        containerLabel: 'Tax Information Worksheet',
        code: '682'
    },
    {
        name: 'Texas Home Equity Affidavit And Agreement First Lien',
        containerLabel: 'Closing Docs - Misc',
        code: '788'
    },
    {
        name: 'Texas T-64 Disclosure',
        containerLabel: 'Disclosures - Misc',
        code: '1648'
    },
    {
        name: 'The Housing Financial Discrimination Act',
        containerLabel: 'Disclosures - Misc',
        code: '119'
    },
    {
        name: 'The Work #',
        containerLabel: 'Income Misc',
        code: '524'
    },
    {
        name: 'Third Party Originator - MERS',
        containerLabel: 'Assignment - MERS / MIN',
        code: '969'
    },
    {
        name: 'TILA RESPA Integrated Disclosure Calculator',
        containerLabel: 'Disclosures - Misc',
        code: '1658'
    },
    {
        name: 'Title Commitment',
        containerLabel: 'Title Report - Commitment',
        code: '813'
    },
    {
        name: 'Title Fees Breakdown',
        containerLabel: 'Fee Sheet',
        code: '1338'
    },
    {
        name: 'Title Insurance Notice',
        containerLabel: 'Title - Misc',
        code: '1866'
    },
    {
        name: 'Title Insurance Other',
        containerLabel: 'Title - Misc',
        code: '326'
    },
    {
        name: 'Title Insurance Rider',
        containerLabel: 'Title Report - Commitment',
        code: '2029'
    },
    {
        name: 'Title Policy',
        containerLabel: 'Title Policy - Final',
        code: '814'
    },
    {
        name: 'Title-Insurance-Preliminary-Report',
        containerLabel: 'Title Report - Commitment',
        code: '564'
    },
    {
        name: 'Transmittal for Payment Up Front Mortgage Insurance Premium UFMIP',
        containerLabel: 'Conditions - Internal',
        code: '1090'
    },
    {
        name: 'Trust Agreement',
        containerLabel: 'Trust Documents',
        code: '1500'
    },
    {
        name: 'Trust Certification',
        containerLabel: 'Trust Documents',
        code: '1501'
    },
    {
        name: 'Truth in Lending Disclosure Statement',
        containerLabel: 'Truth-In-Lending - Final',
        code: '482'
    },
    {
        name: 'UCD Findings - FNMA',
        containerLabel: 'Internal Miscellaneous',
        code: '1885'
    },
    {
        name: 'Underwriting Analysis',
        containerLabel: 'VA - Loan Analysis 26-6393',
        code: '45'
    },
    {
        name: 'Underwriting Comments',
        containerLabel: 'UW Approval',
        code: '1166'
    },
    {
        name: 'Underwriting-Findings',
        containerLabel: 'AUS Findings',
        code: '287'
    },
    {
        name: 'Undisclosed Debts Disclosure',
        containerLabel: 'Disclosures - Misc',
        code: '1378'
    },
    {
        name: 'Uniform Residential Loan Application',
        containerLabel: '1003 Loan Application - Final',
        code: '1206'
    },
    {
        name: 'Uniform Residential Loan Application Single',
        containerLabel: '1003 Loan Application - Final',
        code: '1209'
    },
    {
        name: 'Uniform Transmittal Summary',
        containerLabel: '1008 Transmittal',
        code: '412'
    },
    {
        name: 'Universal Loan Identifier Form',
        containerLabel: 'Internal Miscellaneous',
        code: '1967'
    },
    {
        name: 'USA Patriot Act Section 326 Joint',
        containerLabel: 'Disclosures - Patriot Act',
        code: '1214'
    },
    {
        name: 'USA Patriot Act Section 326 Single',
        containerLabel: 'Disclosures - Patriot Act',
        code: '1215'
    },
    {
        name: 'USDA Documents',
        containerLabel: 'Govt Misc',
        code: '1167'
    },
    {
        name: 'VA Addendum to Closing Instructions',
        containerLabel: 'Closing Instructions',
        code: '531'
    },
    {
        name: 'VA Affirmative Marketing Certification',
        containerLabel: 'Govt Misc',
        code: '385'
    },
    {
        name: 'VA Benefit Related Indebtedness Letter Certification',
        containerLabel: 'Govt Misc',
        code: '461'
    },
    {
        name: 'VA Certificate of Eligibility',
        containerLabel: 'COE',
        code: '78'
    },
    {
        name: 'VA Docs Other',
        containerLabel: 'Govt Misc',
        code: '1216'
    },
    {
        name: 'VA FUNDING Fee Paid Receipt',
        containerLabel: 'Govt Misc',
        code: '1427'
    },
    {
        name: 'VA Guaranteed Loan and Assumption Policy Rider',
        containerLabel: 'Govt Misc',
        code: '481'
    },
    {
        name: 'VA Interest Rate Refinance Comparison Statement',
        containerLabel: 'Govt Misc',
        code: '698'
    },
    {
        name: 'VA Loan Comparison Form',
        containerLabel: 'Govt Misc',
        code: '1820'
    },
    {
        name: 'VA Loan Guaranty Certificate',
        containerLabel: 'Govt Misc',
        code: '805'
    },
    {
        name: 'VA Loan Summary',
        containerLabel: 'VA-26-0286 - Loan Summary Sheet',
        code: '688'
    },
    {
        name: 'VA Next of Kin',
        containerLabel: 'Govt Misc',
        code: '702'
    },
    {
        name: 'VA Questionnaire',
        containerLabel: 'Govt Misc',
        code: '493'
    },
    {
        name: 'VA Rate Reduction Certification',
        containerLabel: 'Govt Misc',
        code: '1873'
    },
    {
        name: 'VA Transmittal List',
        containerLabel: 'Govt Misc',
        code: '32'
    },
    {
        name: 'Verbal Verification of Employment (VVOE)',
        containerLabel: 'VOE - Verbal',
        code: '103'
    },
    {
        name: 'Verification of Deposit (VOD)',
        containerLabel: 'Bank Statements',
        code: '454'
    },
    {
        name: 'Verification of Employment (VOE)',
        containerLabel: 'VOE - Written',
        code: '249'
    },
    {
        name: 'Verification of Mortgage (VOM) or Verification of Rent (VOR)',
        containerLabel: 'VOL / VOM / VOR',
        code: '1264'
    },
    {
        name: 'Verification of VA Benefits',
        containerLabel: 'COE',
        code: '97'
    },
    {
        name: 'Veterans Affairs Origination Statement',
        containerLabel: 'Govt Misc',
        code: '1406'
    },
    {
        name: 'Veterans Statement and Lenders Certification',
        containerLabel: 'Govt Misc',
        code: '1872'
    },
    {
        name: 'W-2',
        containerLabel: 'W2s / 1099 / K1s',
        code: '48'
    },
    {
        name: 'W-8',
        containerLabel: 'Tax Returns',
        code: '1587'
    },
    {
        name: 'W-9',
        containerLabel: 'W-9 Form',
        code: '40'
    },
    {
        name: 'Wage and Income Transcript',
        containerLabel: 'Income Misc',
        code: '607'
    },
    {
        name: 'Warehouse Bank Notification Form',
        containerLabel: 'Purchase Advice',
        code: '1585'
    },
    {
        name: 'Warranty Acceptance Letter',
        containerLabel: 'Conditions - Internal',
        code: '1817'
    },
    {
        name: 'Warranty of Completion of Construction',
        containerLabel: 'Closing Docs - Misc',
        code: '1239'
    },
    {
        name: 'Water Test Certification',
        containerLabel: 'Conditions - Internal',
        code: '1815'
    },
    {
        name: 'White Pages.Com',
        containerLabel: 'VOE - Verbal',
        code: '608'
    },
    {
        name: 'Wind Insurance',
        containerLabel: 'Hazard Insurance Policy',
        code: '1269'
    },
    {
        name: 'Wire Instructions',
        containerLabel: 'Wire Instructions',
        code: '146'
    },
    {
        name: 'Wisconsin Tax Disclosure',
        containerLabel: 'Disclosures - Misc',
        code: '1502'
    },
    {
        name: 'Wood Destroying Insect Inspection Report',
        containerLabel: 'Wood Destroying Insect Infestation Report',
        code: '274'
    },
    {
        name: 'Wood Destroying Insect Other Documents',
        containerLabel: 'Wood Destroying Insect Infestation Report',
        code: '1218'
    },
    {
        name: 'Yellow Pages.Com',
        containerLabel: 'VOE - Verbal',
        code: '609'
    },
    {
        name: 'Your Home Loan Toolkit',
        containerLabel: 'Disclosures - Misc',
        code: '1826'
    }
];
