import api, {
    ClientInvestor, EligibilityGuideline, Investor, InvestorStatus, PermissionType
} from '@api';
import { RoutedDialogManager } from '@tsp-ui/core/components';
import {
    replaceItemById, tryGetItemById, useAsyncEffect, usePageMessage, useParams
} from '@tsp-ui/core/utils';
import { withAuth } from '@utils/withAuth';
import { AdminRouteParams } from '@views/admin/components/AdminPageTemplate';
import { InvestorEligibilityContext } from '@views/admin/investor-eligibility/InvestorEligibilityRoutes';
import EligibilityExclusionsCard from '@views/admin/investors/components/EligibilityExclusionsCard';
import { useEligibilityVersionIdQueryParam } from '@views/admin/investors/components/EligibilityVersionButton';
import {
    Dispatch, SetStateAction, createContext, useCallback, useContext, useState
} from 'react';
import { useLocation } from 'react-router-dom';

import { LoanProgramsContext } from '../InternalInvestorDetailRoutes';
import { InternalEligibilityVersionsContext, InvestorManagementPageContext } from '../InternalInvestorManagementPage';

import { InvestorDetailPageBase } from './InvestorDetailPageBase';
import { ActiveClients } from './components/ActiveClients';
import InternalInvestorEligibilityExclusionsDialog from './components/InternalInvestorEligibilityExclusionsDialog';
import { LoanProgramSection } from './components/LoanProgramSection';


const { ACTIVE } = InvestorStatus;

export function useTryGetInvestor(): [Investor | ClientInvestor | undefined, (
    investor: Investor | ClientInvestor
) => void] {
    const { investorID } = useParams<AdminRouteParams<'investor'>>();
    const isClientInvestor = useLocation().pathname.includes('investor-eligibility');

    const {
        entities: internalInvestors,
        setEntities: setInternalInvestors
    } = useContext(InvestorManagementPageContext);

    const {
        investors: clientInvestors,
        setInvestors: setClientInvestors
    } = useContext(InvestorEligibilityContext);

    const investors = (isClientInvestor ? clientInvestors : internalInvestors) || [];
    const investor = tryGetItemById(investors, investorID);
    const setInvestors = isClientInvestor ? setClientInvestors : setInternalInvestors;

    function setInvestor(investor: Investor | ClientInvestor) {
        setInvestors(replaceItemById(investors, investor));
    }

    return [ investor, setInvestor ];
}

export function useGetInvestor() {
    return useTryGetInvestor() as [Investor | ClientInvestor, (investor: Investor | ClientInvestor) => void];
}

export const ExclusionsContext = createContext<{
    exclusions?: EligibilityGuideline[];
    setExclusions: Dispatch<SetStateAction<EligibilityGuideline[] | undefined>>;
}>({
    exclusions: undefined,
    setExclusions: () => {}
});

export default function InternalInvestorDetailPage() {
    const pageMessage = usePageMessage();
    const { investorID } = useParams<AdminRouteParams<'investor'>>();

    const { loanPrograms } = useContext(LoanProgramsContext);
    const { eligibilityVersions, isSelectedVersionEditable } = useContext(InternalEligibilityVersionsContext);
    const eligibilityVersionId = useEligibilityVersionIdQueryParam();

    const [ investor, setInvestor ] = useTryGetInvestor();
    const [ exclusions, setExclusions ] = useState<EligibilityGuideline[]>();
    const [ exclusionsLoading, setExclusionsLoading ] = useState(true);

    const { status, activeClientIDs } = investor || {};

    useAsyncEffect(useCallback(async () => {
        try {
            if (investorID) {
                setExclusions(await api.investors.getExclusions(investorID, eligibilityVersionId));
            }
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching eligibility exclusions', error);
        }

        setExclusionsLoading(false);
    }, [
        investorID, eligibilityVersionId, pageMessage
    ]));

    return (
        <ExclusionsContext.Provider
            value={{
                exclusions,
                setExclusions
            }}
        >
            <InvestorDetailPageBase
                firstBreadcrumb="Investors"
                editPermission={PermissionType.MANAGE_INVESTORS}
                isSelectedVersionEditable={isSelectedVersionEditable}
                eligibilityVersions={eligibilityVersions}
                investor={investor}
                setInvestor={setInvestor}
                loading={!loanPrograms || exclusionsLoading}
                statusCardChildren={status === ACTIVE && <ActiveClients clientIDs={activeClientIDs} />}
                onUpdateStatus={(!investor || !eligibilityVersionId) ? undefined : (status) => (
                    api.investors.updateStatus(investor.id, {
                        status,
                        eligibilityVersionId
                    })
                )}
            >
                <LoanProgramSection
                    loanPrograms={loanPrograms}
                    isSelectedVersionEditable={isSelectedVersionEditable}
                    editPermission={PermissionType.MANAGE_LOAN_PROGRAMS}
                />

                <EligibilityExclusionsCard
                    exclusions={exclusions}
                    isSelectedVersionEditable={isSelectedVersionEditable}
                    caption="These exclusions apply to all loan programs for this investor. Additional exclusions can
                        be managed on each Loan Program."
                />

                <RoutedDialogManager routes={routes} />
            </InvestorDetailPageBase>
        </ExclusionsContext.Provider>
    );
}

const routes = {
    exclusions: withAuth(
        InternalInvestorEligibilityExclusionsDialog,
        [ PermissionType.MANAGE_ELIGIBILITY ],
        true
    )
};
