import api, { ActiveInactiveEnum, DueDiligenceConfig, PermissionType } from '@api';
import {
    DialogContent, DialogTitle, Button as MuiButton, Paper, Slide, Typography
} from '@mui/material';
import { RoutedDialog } from '@tsp-ui/core';
import { Button, DialogActions, RoutedDialogImplProps } from '@tsp-ui/core/components';
import { useAsyncEffect, usePageMessage } from '@tsp-ui/core/utils';
import { useGetCurrentAccount, useHasPermission } from '@utils/hooks';
import clsx from 'clsx';
import { useCallback, useState } from 'react';

import DueDiligenceConfigCard from './DueDiligenceConfigCard';
import styles from './DueDiligenceConfigDialog.module.scss';
import DueDiligenceSettingsForm from './DueDiligenceSettingsForm';


export default function DueDiligenceConfigDialog(props: RoutedDialogImplProps) {
    const pageMessage = usePageMessage();
    const { id: clientId } = useGetCurrentAccount();

    const [ configs, setConfigs ] = useState<DueDiligenceConfig[]>();
    const [ showInactive, setShowInactive ] = useState(false);

    useAsyncEffect(useCallback(async () => {
        try {
            setConfigs(await api.dueDiligenceConfig.getDueDiligenceConfigs(clientId));
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching the due diligence configuration', error);
        }
    }, [ pageMessage, clientId ]));

    const activeConfigs = configs?.filter(({ status }) => status === ActiveInactiveEnum.ACTIVE);
    const inactiveConfigs = configs?.filter(({ status }) => status === ActiveInactiveEnum.INACTIVE);

    async function refreshConfigs() {
        setConfigs(await api.dueDiligenceConfig.getDueDiligenceConfigs(clientId));
    }

    const [ isAdd, setIsAdd ] = useState(false);
    const [ editDueDiligenceConfigId, setEditDueDiligenceConfigId ] = useState<string>();

    const configToEdit = configs?.find(({ id }) => id === editDueDiligenceConfigId);
    const [ canEditDueDiligenceConfig ] = useHasPermission([ PermissionType.EDIT_DUE_DILIGENCE_CONFIG ]);

    return (
        <RoutedDialog
            {...props}
            title="Due-Diligence Steps"
            loading={!configs}
            maxWidth={false}
            classes={{
                paper: clsx(styles.paper, {
                    [styles.formShown]: isAdd || editDueDiligenceConfigId
                })
            }}
        >
            <DialogContent className={styles.mainContent}>
                {showInactive && (
                    <Typography
                        fontWeight={500}
                        color="textSecondary"
                    >
                        Active
                    </Typography>
                )}

                {!activeConfigs?.length ? (
                    <Paper
                        variant="outlined"
                        className={styles.noContentCard}
                    >
                        <Typography variant="body2">
                            You don't have any active due-diligence steps
                        </Typography>
                    </Paper>
                ) : activeConfigs?.map((config, index) => (
                    <DueDiligenceConfigCard
                        key={config.id}
                        config={config}
                        disableMoveDown={index === activeConfigs.length - 1}
                        refreshConfigs={refreshConfigs}
                        onEditClick={() => setEditDueDiligenceConfigId(config.id)}
                    />
                ))}

                {showInactive && (
                    <Typography
                        fontWeight={500}
                        color="textSecondary"
                    >
                        Inactive
                    </Typography>
                )}

                {showInactive && (!inactiveConfigs?.length ? (
                    <Paper
                        variant="outlined"
                        className={styles.noContentCard}
                    >
                        <Typography variant="body2">
                            You don't have any inactive due-diligence steps
                        </Typography>
                    </Paper>
                ) : inactiveConfigs?.map((config) => (
                    <DueDiligenceConfigCard
                        key={config.id}
                        config={config}
                        disableMoveDown
                        refreshConfigs={refreshConfigs}
                    />
                )))}
            </DialogContent>

            <DialogActions>
                <MuiButton
                    onClick={() => setShowInactive(!showInactive)}
                    disabled={!inactiveConfigs?.length}
                >
                    {showInactive ? 'Hide' : 'Show'} inactive steps
                </MuiButton>

                <Button
                    variant="contained"
                    onClick={() => setIsAdd(true)}
                    disabled={!canEditDueDiligenceConfig}
                    tooltip={!canEditDueDiligenceConfig ? 'You don\'t have permission to add steps' : ''}
                >
                    Add step
                </Button>
            </DialogActions>

            {(isAdd || !!editDueDiligenceConfigId) && (
                <Slide
                    in={(isAdd || !!editDueDiligenceConfigId)}
                    direction="left"
                >
                    <div className={styles.editFormContainer}>
                        <DialogTitle>
                            {isAdd ? 'Add' : 'Edit'} Due-Diligence Step
                        </DialogTitle>

                        <DueDiligenceSettingsForm
                            key={configToEdit?.id || 'new'}
                            defaultValues={configToEdit}
                            configs={configs}
                            setConfigs={setConfigs}
                            closeForm={() => {
                                setIsAdd(false);
                                setEditDueDiligenceConfigId(undefined);
                            }}
                        />
                    </div>
                </Slide>
            )}
        </RoutedDialog>
    );
}
