import api, {
    CustomerStatus, PermissionType, customerPendingStepsDisplay, customerStatusDisplay
} from '@api';
import { Button } from '@tsp-ui/core/components';
import { useConfirm, usePageMessage } from '@tsp-ui/core/utils';
import { useGetCurrentAccount, useHasPermission } from '@utils/hooks';
import { useContext, useState } from 'react';

import StatusActionsCard from '../../../components/StatusActionsCard';
import StatusChecklistItem from '../../components/StatusChecklistItem';
import { CustomerDetailContext } from '../CustomerDetailPage';


export default function CustomerActionsCard() {
    const pageMessage = usePageMessage();
    const confirm = useConfirm();

    const { id: clientID } = useGetCurrentAccount();
    const { updateCustomer, customer } = useContext(CustomerDetailContext);
    const { status, pendingSteps } = customer!;

    const canApprove = !pendingSteps?.length;

    const [ canEditCustomer ] = useHasPermission([ PermissionType.EDIT_CUSTOMERS ]);

    const [ loading, setLoading ] = useState(false);
    async function updateStatus(status: CustomerStatus) {
        try {
            setLoading(true);

            const updatedCustomerSummary = await api.customer.updateCustomer(clientID, {
                ...customer!,
                status
            });

            updateCustomer(customer => ({
                ...customer!,
                ...updatedCustomerSummary
            }));

            pageMessage.success(`Customer ${status === CustomerStatus.INACTIVE ? 'deactivated' : 'activated'}`);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while updating the Customer status', error);
        }

        setLoading(false);
    }

    return (
        <StatusActionsCard
            isLoading={loading}
            actions={status !== CustomerStatus.ACTIVE ? (
                <Button
                    variant="contained"
                    disabled={!canApprove || !canEditCustomer || loading}
                    fullWidth
                    onClick={() => updateStatus(CustomerStatus.ACTIVE)}
                    tooltip={canEditCustomer
                        ? canApprove ? '' : 'Required items must be completed prior to approval'
                        : 'You don\'t have permission to approve a customer'}
                >
                    {status === CustomerStatus.PENDING ? 'Approve' : 'Activate'}
                </Button>
            ) : status === CustomerStatus.ACTIVE && (
                <Button
                    disabled={!canEditCustomer || loading}
                    fullWidth
                    onClick={async () => {
                        if (await confirm('Are you sure you want to deactivate this customer?')) {
                            await updateStatus(CustomerStatus.INACTIVE);
                        }
                    }}
                    tooltip={canEditCustomer ? '' : 'You don\'t have permission to deactivate a customer'}
                >
                    Deactivate
                </Button>
            )}
            checklistItems={pendingSteps?.map((step) => (
                <StatusChecklistItem
                    label={customerPendingStepsDisplay[step]}
                    key={step}
                />
            ))}
            noBorder={false}
            status={customerStatusDisplay[status]}
            variant={status}
        />
    );
}
