import api, { Contact } from '@api';
import { RoutedDialog, RoutedDialogProps, usePageMessage } from '@tsp-ui/core/components';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { AddEditContactDialogContent } from '../../../customers/CustomerDetailPage/components/dialogs/components/AddEditContactDialogContent';
import { ClientDetailsContext, ClientDetailsContextValue } from '../ClientDetailPage';


export default function EditClientPrimaryContactDialog(props: Omit<RoutedDialogProps, 'title' | 'children' | 'onSubmit'>) {
    const navigate = useNavigate();
    const pageMessage = usePageMessage();

    const { client, updateClient } = useContext(ClientDetailsContext) as Required<ClientDetailsContextValue>;

    async function handleEdit(formValues: Contact) {
        try {
            updateClient({
                ...client,
                primaryContact: await api.client.contacts.editContact(client.id, formValues)
            });

            closeDialog();
        } catch (error) {
            pageMessage.handleApiError('An error occurred while editing the primary contact', error);
        }
    }

    function closeDialog() {
        navigate(props.closeTo, { replace: true });
    }

    return (
        <RoutedDialog
            {...props}
            title="Edit primary contact"
            maxWidth={false}
        >
            <AddEditContactDialogContent
                defaultValues={client.primaryContact}
                onEdit={handleEdit}
                onCancel={closeDialog}
            />
        </RoutedDialog>
    );
}
