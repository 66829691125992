import { Address } from '../..';
import { apiUtils } from '../../api-utils';


export function getBranches(clientID: string, customerID: string): Promise<Branch[]> {
    return apiUtils.get(`/client/${clientID}/customer/${customerID}/branch`);
}

export function createBranch(clientID: string, customerID: string, branchData: Branch): Promise<Branch> {
    return apiUtils.post(`/client/${clientID}/customer/${customerID}/branch`, branchData);
}

export function updateBranch(clientID: string, customerID: string, branchData: Branch): Promise<Branch> {
    return apiUtils.put(`/client/${clientID}/customer/${customerID}/branch/${branchData.id}`, branchData);
}

export interface Branch {
    id: string;
    dba?: string;
    nmlsId: string;
    address: Address;
    isActive: boolean;
}
