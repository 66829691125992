import { DialogContent } from '@mui/material';
import { RoutedDialog, RoutedDialogProps } from '@tsp-ui/core';


export function NotAllowedDialog(props: Omit<RoutedDialogProps, 'title'>) {
    return (
        <RoutedDialog
            {...props}
            title="Permission denied"
        >
            <DialogContent>
                You do not have permission to view this dialog
            </DialogContent>
        </RoutedDialog>
    );
}
