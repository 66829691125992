export enum LoanAllocationLimitType {
    ALL = 'ALL',
    UNDERWRITING_TYPE = 'UNDERWRITING_TYPE',
    PRODUCT_TYPE = 'PRODUCT_TYPE',
    PRODUCT = 'PRODUCT'
}

export const loanAllocationLimitTypeDisplay = {
    [LoanAllocationLimitType.ALL]: 'General limit',
    [LoanAllocationLimitType.UNDERWRITING_TYPE]: 'Underwriting type',
    [LoanAllocationLimitType.PRODUCT_TYPE]: 'Product type',
    [LoanAllocationLimitType.PRODUCT]: 'Product'
};
