import { Document } from '@api';

import { apiUtils } from '../api-utils';


export function getReferenceGuideSections(clientID: string): Promise<ReferenceGuideSection[]> {
    return apiUtils.get(`/client/${clientID}/reference-guide-section`);
}

export function createReferenceGuideSection(clientID: string, referenceGuideSection: Omit<ReferenceGuideSection, 'id' | 'files'>): Promise<ReferenceGuideSection> {
    return apiUtils.post(`/client/${clientID}/reference-guide-section`, referenceGuideSection);
}

export function deleteReferenceGuideSection(clientID: string, referenceGuideSectionID: string): Promise<void> {
    return apiUtils.delete(`/client/${clientID}/reference-guide-section/${referenceGuideSectionID}`);
}

export function uploadReferenceGuide(
    clientID: string, referenceGuideUpload: ReferenceGuideUpload, referenceGuideSectionID: string, file: File
): Promise<ReferenceGuideFile> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('data', JSON.stringify(referenceGuideUpload));

    return apiUtils.post(`/client/${clientID}/reference-guide-section/${referenceGuideSectionID}/document`, formData, { useAutoContentType: true });
}

export function updateReferenceGuide(
    clientID: string, referenceGuide: ReferenceGuideFile
): Promise<ReferenceGuideFile> {
    return apiUtils.put(`/client/${clientID}/reference-guide-section/${referenceGuide.sectionId}/document/${referenceGuide.id}`, referenceGuide);
}

export function deleteReferenceGuide(
    clientID: string, referenceGuideSectionID: string, referenceGuideID: string
): Promise<void> {
    return apiUtils.delete(`/client/${clientID}/reference-guide-section/${referenceGuideSectionID}/document/${referenceGuideID}`);
}

export interface ReferenceGuideSection {
    id: string;
    title: string;
    files: ReferenceGuideFile[];
}

export interface ReferenceGuideFile {
    id: string;
    sectionId: string;
    description?: string;
    createdAt: string;
    document: Document;
    name: string;
}

export interface ReferenceGuideUpload {
    name: string;
    description?: string;
}

export function isReferenceGuide(object: Partial<ReferenceGuideFile> | undefined): object is ReferenceGuideFile {
    return object instanceof Object && object.hasOwnProperty('id');
}
