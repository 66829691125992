export enum ConditionSource {
    ESCROW = 'Escrow',
    INVESTOR = 'Investor',
    RECORDERS_OFFICE = 'Recorder\'s Office',
    BORROWERS = 'Borrowers',
    FHA = 'FHA',
    VA = 'VA',
    MI_COMPANY = 'MI Company',
    OTHER = 'Other',
    MANUAL = 'Manual',
    CONDITION_SET = 'Condition Set',
    AUTOMATED_CONDITIONS = 'Automated Conditions'
}

export const conditionSourceDisplay = {
    [ConditionSource.ESCROW]: 'Escrow',
    [ConditionSource.INVESTOR]: 'Investor',
    [ConditionSource.RECORDERS_OFFICE]: 'Recorder\'s Office',
    [ConditionSource.BORROWERS]: 'Borrowers',
    [ConditionSource.FHA]: 'FHA',
    [ConditionSource.VA]: 'VA',
    [ConditionSource.MI_COMPANY]: 'MI Company',
    [ConditionSource.OTHER]: 'Other',
    [ConditionSource.MANUAL]: 'Manual',
    [ConditionSource.CONDITION_SET]: 'Condition Set',
    [ConditionSource.AUTOMATED_CONDITIONS]: 'Automated Conditions'
};
