import { Product, ProductType, productTypeDisplay } from '@api';
import { Paper, Tooltip } from '@mui/material';
import { ExpandableHeader, Switch } from '@tsp-ui/core/components';
import {
    CustomerEligibleProductsFormValues
} from '@views/admin/customers/CustomerDetailPage/components/dialogs/EligibleProductsDialog';
import { useWatch } from 'react-hook-form';

import styles from './ProductTypeSection.module.scss';


interface ProductTypeSectionProps {
    productType: ProductType;
    products: Product[] | undefined;
}

export default function ProductTypeSection({ productType, products }: ProductTypeSectionProps) {
    const productsOfThisType = products?.filter((product) => product.productType === productType);

    const productTypeConfigs = useWatch<CustomerEligibleProductsFormValues, 'productTypeConfigs'>({
        name: 'productTypeConfigs'
    });

    return !productTypeConfigs.find(config => config.productType === productType)?.isEnabled ? null : (
        <ExpandableHeader
            disableExpand={false}
            defaultExpand
            title={productTypeDisplay[productType]}
            secondaryText={(
                <NumActiveProductsDisplay products={productsOfThisType} />
            )}
            expandedContent={(
                <div className={styles.productCardContainer}>
                    {productsOfThisType?.map(({
                        productDescription, productCode, id, isActive
                    }) => (
                        <Paper
                            key={id}
                            variant="outlined"
                            className={styles.card}
                        >
                            <Tooltip
                                title={isActive ? ''
                                    : 'This product is disabled globally and cannot be activated for a customer'}
                            >
                                <span>
                                    <Switch<CustomerEligibleProductsFormValues>
                                        label={`${productDescription} (${productCode})`}
                                        name={`eligibleProducts.${id}`}
                                        disabled={!isActive}
                                    />
                                </span>
                            </Tooltip>
                        </Paper>
                    ))}
                </div>
            )}
        />
    );
}

interface NumActiveProductsDisplayProps {
    products: Product[] | undefined;
}

function NumActiveProductsDisplay({ products }: NumActiveProductsDisplayProps) {
    const productFormValues = useWatch<CustomerEligibleProductsFormValues, 'eligibleProducts'>({
        name: 'eligibleProducts'
    });

    const numActive = Object.entries(productFormValues).filter(([ productID, enabled ]) => (
        enabled && products?.some(({ id }) => productID === id)
    )).length;

    return (
        <>
            {numActive}

            /

            {products?.length}
        </>
    );
}
