import api, {
    PermissionType, PurchaseAdviceConfig, PurchaseAdviceFee, feeTypeDisplay, roundingMethodDisplay
} from '@api';
import { AddCircleOutline } from '@mui/icons-material';
import { Link as MuiLink, Typography } from '@mui/material';
import {
    FilledSection, FilterTextField, IconButton, LabelGroup, LabeledValue, RoutedDialogManager
} from '@tsp-ui/core/components';
import { toOrdinal, useAsyncEffect, usePageMessage } from '@tsp-ui/core/utils';
import { useGetCurrentAccount, useHasPermission } from '@utils/hooks';
import { withAuth } from '@utils/withAuth';
import Page from '@views/components/Page';
import { PermissionCheck } from '@views/components/PermissionCheck';
import {
    Dispatch, SetStateAction, createContext, useCallback, useMemo, useState
} from 'react';
import { Link } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import EditableSectionCard from '../components/EditableSectionCard';

import { PurchaseAdviceDialog } from './PurchaseAdviceDialog';
import { PurchaseAdviceFeeCard } from './PurchaseAdviceFeeCard';
import { PurchaseAdviceFeeDialog } from './PurchaseAdviceFeeDialog';
import styles from './PurchaseAdviceManagementPage.module.scss';


export interface PurchaseAdviceContextValue {
    purchaseAdviceConfig?: PurchaseAdviceConfig;
    setPurchaseAdviceConfig: Dispatch<SetStateAction<PurchaseAdviceConfig | undefined>>;
    purchaseAdviceFees: PurchaseAdviceFee[];
    setPurchaseAdviceFees: Dispatch<SetStateAction<PurchaseAdviceFee[]>>;
}

export const PurchaseAdviceContext = createContext<PurchaseAdviceContextValue>({
    purchaseAdviceConfig: undefined,
    setPurchaseAdviceConfig: () => {},
    purchaseAdviceFees: [],
    setPurchaseAdviceFees: () => {}
});

export default function PurchaseAdviceManagementPage() {
    const [ purchaseAdviceConfig, setPurchaseAdviceConfig ] = useState<PurchaseAdviceConfig>();
    const [ purchaseAdviceFees, setPurchaseAdviceFees ] = useState<PurchaseAdviceFee[]>([]);
    const [ loading, setLoading ] = useState(true);

    const { id: clientId } = useGetCurrentAccount();
    const pageMessage = usePageMessage();

    const { interestRatePrecision, roundingMethod, cutoffDay } = purchaseAdviceConfig || {};

    const purchaseAdviceContextValue = useMemo(() => ({
        purchaseAdviceConfig,
        setPurchaseAdviceConfig,
        purchaseAdviceFees,
        setPurchaseAdviceFees
    }), [
        purchaseAdviceConfig, setPurchaseAdviceConfig, purchaseAdviceFees, setPurchaseAdviceFees
    ]);

    const [ searchTerm, setSearchTerm ] = useState('');
    const [ debouncedTerm ] = useDebounce(searchTerm, 300);

    const filteredPurchaseAdviceFees = purchaseAdviceFees.filter(({ type }) => (
        feeTypeDisplay[type].toLocaleLowerCase().includes(debouncedTerm)
    ));

    const [
        canViewPurchaseAdviceFee,
        canManageFees,
        canManageConfig
    ] = useHasPermission([
        PermissionType.VIEW_PURCHASE_ADVICE_FEE,
        PermissionType.MANAGE_PURCHASE_ADVICE_FEE,
        PermissionType.MANAGE_PURCHASE_ADVICE_CONFIG
    ]);

    useAsyncEffect(useCallback(async () => {
        setLoading(true);

        try {
            const [ purchaseAdviceConfig, purchaseAdviceFees ] = await Promise.all(
                [
                    api.purchaseAdvice.getPurchaseAdviceConfig(clientId),
                    api.purchaseAdvice.getPurchaseAdviceFees(clientId)
                ]
            );

            setPurchaseAdviceConfig(purchaseAdviceConfig);
            setPurchaseAdviceFees(purchaseAdviceFees);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching the purchase advice configuration', error);
        }

        setLoading(false);
    }, [ clientId, pageMessage ]));

    return (
        <Page
            header="Purchase advice management"
            headerActions={canViewPurchaseAdviceFee ? (
                <FilterTextField
                    autoFocus
                    placeholder="Filter fees"
                    helperText="Filter by fee name"
                    onChange={e => setSearchTerm(e.target.value.toLocaleLowerCase())}
                />
            ) : null}
            loading={loading}
        >
            <div className={styles.root}>
                <PurchaseAdviceContext.Provider value={purchaseAdviceContextValue}>
                    <PermissionCheck
                        permission={PermissionType.VIEW_PURCHASE_ADVICE_CONFIG}
                        noPermissionContent={(
                            <Typography
                                variant="caption"
                                color="textSecondary"
                                align="center"
                                className={styles.caption}
                            >
                                You do not have permission to view purchase advice fees.
                            </Typography>
                        )}
                    >
                        {
                            purchaseAdviceConfig?.id ? (
                                <EditableSectionCard
                                    header="Interest settings"
                                    editTo="interest"
                                    disableEdit={!canManageConfig}
                                    disabledTooltip="You do not have permission to edit interest settings"
                                >
                                    <LabelGroup>
                                        <LabeledValue
                                            label="Precision:"
                                            value={(
                                                <>
                                                    {interestRatePrecision} decimals&nbsp;

                                                    <>(
                                                        <Typography
                                                            component="span"
                                                            fontSize="inherit"
                                                            fontWeight={500}
                                                        >
                                                            {`.${'0'.repeat(interestRatePrecision! - 1)}1`}
                                                        </Typography>)
                                                    </>
                                                </>
                                            )}
                                            variants={variants}
                                        />

                                        <LabeledValue
                                            label="Rounding:"
                                            value={roundingMethodDisplay[roundingMethod!]}
                                            variants={variants}
                                        />

                                        <LabeledValue
                                            label="Cutoff day:"
                                            value={`${toOrdinal(cutoffDay!)} of the month`}
                                            variants={variants}
                                        />
                                    </LabelGroup>
                                </EditableSectionCard>
                            ) : (
                                <Typography
                                    variant="caption"
                                    color="textSecondary"
                                    align="center"
                                    className={styles.caption}
                                >
                                    You haven't configured purchase advice yet.&nbsp;

                                    {canManageConfig && (
                                        <>
                                            <MuiLink
                                                component={Link}
                                                to="interest"
                                            >
                                                Click here to get started
                                            </MuiLink>.
                                        </>
                                    )}
                                </Typography>
                            )
                        }

                    </PermissionCheck>

                    <PermissionCheck
                        permission={PermissionType.VIEW_PURCHASE_ADVICE_FEE}
                        noPermissionContent={(
                            <Typography
                                variant="caption"
                                color="textSecondary"
                                align="center"
                                className={styles.caption}
                            >
                                You do not have permission to view purchase advice fees.
                            </Typography>
                        )}
                    >
                        {purchaseAdviceFees.length ? (
                            <div>
                                <FilledSection
                                    className={styles.feesSection}
                                    header={(
                                        <>
                                            Fees

                                            <IconButton
                                                tooltip={canManageFees ? 'Add fee' : 'You do not have permission to add fees'}
                                                disabled={!canManageFees}
                                                className={styles.addFeeButton}
                                                component={Link}
                                                to="fees/new"
                                            >
                                                <AddCircleOutline color="secondary" />
                                            </IconButton>
                                        </>
                                    )}
                                >
                                    {filteredPurchaseAdviceFees.map(fee => (
                                        <PurchaseAdviceFeeCard
                                            key={fee.id}
                                            fee={fee}
                                        />
                                    ))}
                                </FilledSection>

                                <Typography
                                    variant="caption"
                                    color="textSecondary"
                                    align="center"
                                    className={styles.caption}
                                >
                                    These fees are the base fees that apply to all customers.
                                    Customer-specific configuration is available in&nbsp;

                                    <MuiLink
                                        component={Link}
                                        to="../customers"
                                    >
                                        Customer management
                                    </MuiLink>.
                                </Typography>
                            </div>
                        ) : (
                            <Typography
                                variant="caption"
                                color="textSecondary"
                                align="center"
                                className={styles.caption}
                            >
                                No fees exist yet.&nbsp;

                                {canManageFees && (
                                    <>
                                        <MuiLink
                                            component={Link}
                                            to="fees/new"
                                        >
                                            Click here to create a new fee
                                        </MuiLink>.
                                    </>
                                )}
                            </Typography>
                        )}
                    </PermissionCheck>


                    <RoutedDialogManager routes={dialogRoutes} />
                </PurchaseAdviceContext.Provider>
            </div>
        </Page>
    );
}

const dialogRoutes = {
    interest: withAuth(PurchaseAdviceDialog, [ PermissionType.MANAGE_PURCHASE_ADVICE_CONFIG ], true),
    'fees/:feeID': withAuth(PurchaseAdviceFeeDialog, [ PermissionType.MANAGE_PURCHASE_ADVICE_FEE ], true)
};

const variants = {
    value: 'body1',
    label: 'body2'
} as const;
