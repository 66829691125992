import { usePageMessage } from '@tsp-ui/core';
import { Dispatch, SetStateAction, useCallback } from 'react';

/**
 * This hook allows us to handle promise results independently while executing promises simultaneously. This allows
 * us to update state for all promises that succeed, regardless if one fails, as well as provide a unique error
 * message for each promise.
 *
 * Instead of doing:
 * ```
 * try {
 *    const [ data1, data2 ] = await Promise.all([ promise1, promise2 ]);
 *    setState1(data1);
 *    setState2(data2);
 * } catch (error) {
 *   pageMessage.handleApiError('An error occurred while fetching data', error);
 * }
 * ```
 *
 * we can do
 * ```
 * const handlePromiseSettledResult = useHandlePromiseSettledResult();
 * ...
 * const [ result1, result2 ] = await Promise.allSettled([ promise1, promise2 ]);
 * handlePromiseSettledResult(result1, setState1, 'An error occurred while fetching data1');
 * handlePromiseSettledResult(result2, setState2, 'An error occurred while fetching data2');
 * ```
 *
 * @returns A memoized function that takes a promise result, a state setter, and an optional error message. If the
 * promise resolves, the state is updated with the resolved value. If the promise rejects, a page message is triggered
 * with the error & error message.
 */
export function useHandlePromiseSettledResult() {
    const pageMessage = usePageMessage();

    return useCallback(<TData>(
        result: PromiseSettledResult<TData>,
        stateSetter: Dispatch<SetStateAction<TData>> | Dispatch<SetStateAction<TData | undefined>>,
        errorMessage: string = 'An error occurred while fetching data'
    ) => {
        if (result.status === 'rejected') {
            pageMessage.handleApiError(errorMessage, result.reason);
        } else {
            stateSetter(result.value);
        }
    }, [ pageMessage ]);
}
