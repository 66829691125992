import { PermissionType } from '@api';
import { withAuth } from '@utils/withAuth';
import ExclusionOverlayDialog from '@views/admin/investor-eligibility/components/ExclusionOverlayDialog';


const { MANAGE_EXCLUSION_OVERLAYS } = PermissionType;

export const overlayDialogRoutes = {
    'overlays/add': withAuth(ExclusionOverlayDialog, [ MANAGE_EXCLUSION_OVERLAYS ], true),
    'overlays/:overlayID/edit': withAuth(
        ExclusionOverlayDialog, [ MANAGE_EXCLUSION_OVERLAYS ], true
    )
};
