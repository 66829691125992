import {
    CustomerFee,
    CustomerFeesComp,
    FeeAmountType,
    PermissionType,
    feeAmountPercentageOfDisplay,
    feeAmountTypeDisplay,
    feeTypeDisplay
} from '@api';
import { CheckCircleOutline, Edit, RemoveCircleOutline } from '@mui/icons-material';
import { Paper, Tooltip, Typography } from '@mui/material';
import {
    CurrencyField, HorizontalLabeledValue, IconButton, PercentField, Radio, RadioGroup, TextField, renderEnumOptions
} from '@tsp-ui/core/components';
import { formatCurrency } from '@tsp-ui/core/utils';
import { useHasPermission } from '@utils/hooks';
import clsx from 'clsx';
import {
    KeyboardEvent, ReactNode, useEffect, useState
} from 'react';
import { useFormContext } from 'react-hook-form';

import styles from './CustomerFeeCard.module.scss';


interface CustomerFeeCardProps {
    fee: CustomerFee;
    feeTypeDropdown?: ReactNode;
    firstButton?: ReactNode;
    formIndex: number;
    headerIcon?: ReactNode;
    onEditClick?: () => void;
    onRemove?: () => void;
    onToggleActive?: (isActive: boolean) => void;
}

export default function CustomerFeeCard({
    fee, feeTypeDropdown, firstButton, formIndex, headerIcon, onEditClick, onRemove, onToggleActive
}: CustomerFeeCardProps) {
    const { setValue } = useFormContext<CustomerFeesComp>();

    const nameBase: `fees.${number}` = `fees.${formIndex}`;

    const [ isEditing, setIsEditing ] = useState(false);

    const { amountType } = fee;

    const isVariable = amountType === FeeAmountType.VARIABLE;

    const isEditable = fee.id === undefined && fee.amount === 0 && fee.amountType === FeeAmountType.FIXED;

    useEffect(() => {
        if (isEditable) {
            setIsEditing(true);
        }
    }, [ isEditable, setIsEditing ]);


    useEffect(() => {
        if (amountType === FeeAmountType.FIXED) {
            setValue(`${nameBase}.percentage`, undefined);
            setValue(`${nameBase}.percentageOf`, undefined);
            setValue(`${nameBase}.minAmount`, undefined);
            setValue(`${nameBase}.maxAmount`, undefined);
        } else if (amountType === FeeAmountType.VARIABLE) {
            setValue(`${nameBase}.amount`, undefined);
        }
    }, [
        amountType, nameBase, setValue
    ]);

    const toggleActive = () => {
        onToggleActive?.(!fee.isActive);
        if (isEditing) {
            setIsEditing(false);
        }
    };

    function handleKeyDown(e: KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Enter') {
            setIsEditing(false);
        }
    }

    const [ canManageFeesComp ] = useHasPermission([ PermissionType.MANAGE_FEE_COMPENSATION ]);

    return (
        <Paper
            className={clsx(styles.feeCard, {
                [styles.cardInactive]: !fee.isActive
            })}
            elevation={0}
        >
            <div>
                <div className={styles.chips}>
                    <Typography>
                        {feeTypeDisplay[fee.type]}
                    </Typography>

                    {!isEditing && (
                        <Tooltip title="Fee amount type">
                            <Typography
                                className={styles.chip}
                                variant="body2"
                            >
                                {feeAmountTypeDisplay[fee.amountType]}
                            </Typography>
                        </Tooltip>
                    )}

                    {!isEditing && headerIcon}
                </div>

                {isEditing ? (
                    <div className={styles.edit}>
                        {feeTypeDropdown}

                        <RadioGroup<CustomerFeesComp>
                            name={`${nameBase}.amountType`}
                            defaultValue={fee.amountType}
                            row
                        >
                            <Radio
                                label={feeAmountTypeDisplay[FeeAmountType.FIXED]}
                                value={FeeAmountType.FIXED}
                                disabled={!canManageFeesComp}
                            />

                            <Radio
                                label={feeAmountTypeDisplay[FeeAmountType.VARIABLE]}
                                value={FeeAmountType.VARIABLE}
                                disabled={!canManageFeesComp}
                            />
                        </RadioGroup>

                        {isEditing && isVariable ? (
                            <div className={styles.variableEdit}>
                                <div className={styles.percentageRow}>
                                    <PercentField<CustomerFeesComp>
                                        className={styles.percentageInput}
                                        name={`${nameBase}.percentage`}
                                        percentVariant="adorned"
                                        onKeyDown={handleKeyDown}
                                    />

                                    <Typography
                                        className={styles.separationText}
                                        variant="body2"
                                    >
                                        of the:
                                    </Typography>

                                    <TextField<CustomerFeesComp>
                                        className={styles.loanAmount}
                                        name={`${nameBase}.percentageOf`}
                                        label="Loan amount type"
                                        select
                                        required
                                        hideRequiredIndicator
                                    >
                                        {renderEnumOptions(feeAmountPercentageOfDisplay)}
                                    </TextField>
                                </div>

                                <CurrencyField<CustomerFeesComp>
                                    name={`${nameBase}.minAmount`}
                                    label="Min amount"
                                    onKeyDown={handleKeyDown}
                                />

                                <CurrencyField<CustomerFeesComp>
                                    name={`${nameBase}.maxAmount`}
                                    label="Max amount"
                                    onKeyDown={handleKeyDown}
                                />
                            </div>
                        ) : (
                            !isVariable && (
                                <CurrencyField<CustomerFeesComp>
                                    className={styles.adjustmentField}
                                    name={`${nameBase}.amount`}
                                    variant="standard"
                                    autoFocus
                                    onKeyDown={handleKeyDown}
                                />
                            )
                        )}
                    </div>
                ) : (
                    <Typography
                        className={styles.adjusted}
                        variant="body2"
                        align="left"
                    >
                        {isVariable ? (
                            <div>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    {(fee.percentage! * 100).toFixed(3)}

                                    % of the{' '}

                                    {fee.percentageOf ? feeAmountPercentageOfDisplay[fee.percentageOf!].toLocaleLowerCase() : ''}
                                </Typography>

                                <div className={styles.labels}>
                                    <HorizontalLabeledValue
                                        label="Min:"
                                        value={typeof fee.minAmount === 'number' ? formatCurrency(fee.minAmount) : '--'}
                                    />

                                    <HorizontalLabeledValue
                                        classNames={{ label: styles.maxLabel }}
                                        label="Max:"
                                        value={typeof fee.maxAmount === 'number' ? formatCurrency(fee.maxAmount) : '--'}
                                    />
                                </div>
                            </div>
                        ) : (
                            <>{formatCurrency(fee.amount)}</>
                        )}
                    </Typography>
                )}
            </div>

            <div className={styles.buttons}>
                {firstButton}

                {isEditing ? (
                    <IconButton
                        tooltip={!canManageFeesComp ? 'You do not have permission to manage fees' : 'Done'}
                        size="small"
                        onClick={() => setIsEditing(false)}
                    >
                        <CheckCircleOutline
                            color="secondary"
                            fontSize="small"
                        />
                    </IconButton>
                ) : fee.isActive && (
                    <IconButton
                        tooltip={!canManageFeesComp ? 'You do not have permission to manage fees' : 'Edit fee'}
                        disabled={!canManageFeesComp}
                        size="small"
                        onClick={() => {
                            onEditClick?.();
                            setIsEditing(true);
                        }}
                    >
                        <Edit
                            color="secondary"
                            fontSize="small"
                        />
                    </IconButton>
                )}

                {onToggleActive
                    ? fee.isActive ? (
                        <IconButton
                            tooltip={!canManageFeesComp ? 'You do not have permission to manage fees' : 'Disable fee'}
                            disabled={!canManageFeesComp}
                            size="small"
                            onClick={toggleActive}
                        >
                            <RemoveCircleOutline
                                fontSize="small"
                                color="error"
                            />
                        </IconButton>
                    ) : (
                        <IconButton
                            tooltip={!canManageFeesComp ? 'You do not have permission to manage fees' : 'Enable fee'}
                            disabled={!canManageFeesComp}
                            size="small"
                            onClick={toggleActive}
                        >
                            <CheckCircleOutline
                                fontSize="small"
                                color="success"
                            />
                        </IconButton>
                    ) : (
                        <IconButton
                            tooltip={!canManageFeesComp ? 'You do not have permission to manage fees' : 'Delete fee'}
                            disabled={!canManageFeesComp}
                            size="small"
                            onClick={onRemove}
                        >
                            <RemoveCircleOutline
                                fontSize="small"
                                color="error"
                            />
                        </IconButton>

                    )}
            </div>
        </Paper>

    );
}
