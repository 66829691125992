import {
    Agency, AmortizationType, AutomatedUwRecommendation, AutomatedUwSystem, CommitmentType, ConditionEvent,
    ConditionExceptionStatus, ContactResponsibility, ContactTitle,
    DocumentationType, FeeType, LoanLimitType, LoanPurpose, LoanType, LockPeriod, NumUnits, OccupancyType, ProductType,
    PropertyType, ServicingType, SpecialtyProgram, State
} from '@api';
import { ConfigurableValues } from '@utils';
import { rest } from 'msw';

import { getMockUrl } from '../../mocks/getMockUrl';


export const mocks = [
    rest.get(getMockUrl('/client/:clientID/configurable-values'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.json(configurableValues)
    )),
    rest.put(getMockUrl('/client/:clientID/configurable-values'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.delay(500),
        ctx.json(req.body)
    ))
];

const configurableValues: ConfigurableValues = {
    customValues: { // TODO future implementation
        sampleValue: [
            'value1', 'value2', 'value3'
        ]
    },
    togglableValues: {
        agency: [
            Agency.FHA, Agency.VA, Agency.USDA, Agency.FNMA, Agency.FHLMC
        ],
        amortizationType: [ AmortizationType.FIXED, AmortizationType.ADJUSTABLE ],
        automatedUnderwritingRecommendation: [
            AutomatedUwRecommendation.ACCEPT,
            AutomatedUwRecommendation.APPROVE_ELIGIBLE,
            AutomatedUwRecommendation.CAUTION,
            AutomatedUwRecommendation.REFER_WITH_CAUTION,
            AutomatedUwRecommendation.OUT_OF_SCOPE
        ],
        automatedUnderwritingSystem: [
            AutomatedUwSystem.DU, AutomatedUwSystem.LPA, AutomatedUwSystem.MANUAL
        ],
        commitmentType: [ CommitmentType.BEST_EFFORT, CommitmentType.MANDATORY ],
        conditionEvent: [
            ConditionEvent.RESPONSIBLE_PARTY_CHANGES,
            ConditionEvent.STATUS_ADDED,
            ConditionEvent.STATUS_CLEARED,
            ConditionEvent.STATUS_FULFILLED,
            ConditionEvent.STATUS_RECEIVED,
            ConditionEvent.STATUS_REJECTED,
            ConditionEvent.STATUS_REQUESTED,
            ConditionEvent.STATUS_REREQUESTED,
            ConditionEvent.STATUS_REVIEWED,
            ConditionEvent.STATUS_WAIVED
        ],
        conditionExceptionStatus: [ ConditionExceptionStatus.APPROVED_BY_INVESTOR ],
        contactTite: [
            ContactTitle.ACCOUNTANT,
            ContactTitle.CAPITAL_MARKETS_MANAGER,
            ContactTitle.CEO,
            ContactTitle.CFO,
            ContactTitle.COLLATERAL_SHIPMENT,
            ContactTitle.COMPLIANCE_MANAGER,
            ContactTitle.COMPLIANCE_OFFICER,
            ContactTitle.COO,
            ContactTitle.DIRECTOR_OF_POST_FUNDING,
            ContactTitle.DIRECTOR_OF_SECONDARY,
            ContactTitle.EVP_HOME_LENDING,
            ContactTitle.FINAL_DOCUMENT_SPECIALIST,
            ContactTitle.FUNDING,
            ContactTitle.HEDGING,
            ContactTitle.HEDGING_LOAN_SALES_MANAGER,
            ContactTitle.INTERNAL_WAREHOUSE_CONTACT,
            ContactTitle.INVESTOR_PURCHASE_ANALYST,
            ContactTitle.INVESTOR_PURCHASE_SUPERVISOR,
            ContactTitle.INVESTOR_SUSPENSE_COORDINATOR,
            ContactTitle.JR_PROCESSOR,
            ContactTitle.LOAN_OFFICER,
            ContactTitle.LOAN_SALE_COORDINATOR,
            ContactTitle.LOAN_SERVICING_MANAGER,
            ContactTitle.LOCK_DESK_MANAGER,
            ContactTitle.MANDATORY_DESK,
            ContactTitle.MERS_TRANSFERS,
            ContactTitle.OPERATIONS_MANAGER,
            ContactTitle.OPERATIONS_UNDERWRITING_MANAGER,
            ContactTitle.PAY_HISTORIES,
            ContactTitle.PAY_HISTORIES_TRAILING_CORRECTED_DOCS,
            ContactTitle.POST_CLOSING_ASSOCIATE,
            ContactTitle.POST_CLOSING_MANAGER,
            ContactTitle.POST_CLOSING_PROCESSOR,
            ContactTitle.POST_CLOSING_SPECIALIST,
            ContactTitle.POST_FUNDER_MERS,
            ContactTitle.POST_FUNDING_SPECIALIST,
            ContactTitle.PRESIDENT,
            ContactTitle.PRE_PURCHASE_REVIEW,
            ContactTitle.PROCESSING,
            ContactTitle.PRODUCTION_MANAGER,
            ContactTitle.PURCHASE_ADVICE,
            ContactTitle.QUALITY_CONTROL_MANAGER,
            ContactTitle.RATE_SHEET_DISTRIBUTION,
            ContactTitle.SECONDARY_ANALYST,
            ContactTitle.SECONDARY_MARKETING_BA,
            ContactTitle.SECONDARY_MARKETING_MANAGER,
            ContactTitle.SENIOR_MANAGER_FOR_PRODUCT_AND_CREDIT_POLICY,
            ContactTitle.SENIOR_POST_CLOSER_PRIMARY_CONTACT_ON_FILES,
            ContactTitle.SERVICING_MANAGER,
            ContactTitle.SERVICING_MANAGER_MERS_PAY_HISTORIES,
            ContactTitle.SHIPPER,
            ContactTitle.SHIPPING_MANAGER,
            ContactTitle.SVP,
            ContactTitle.TEAM_LEAD_UPLOADING_CLOSED_LOAN_FILES,
            ContactTitle.TRADER_HEDGE_DESK,
            ContactTitle.TRADES_PAIR_OFFS,
            ContactTitle.TRAILING_DOCS,
            ContactTitle.UNDERWRITING_MANAGER,
            ContactTitle.UNDERWRITING_TEAM_LEAD,
            ContactTitle.VP,
            ContactTitle.WEBSITE_ADMIN
        ],
        contactResponsibility: [
            ContactResponsibility.ACCOUNTING,
            ContactResponsibility.CAPITAL_MARKETS,
            ContactResponsibility.COMPLIANCE,
            ContactResponsibility.FINAL_DOCS,
            ContactResponsibility.INTERNAL_WAREHOUSE,
            ContactResponsibility.MERS_TRANSFERS,
            ContactResponsibility.OPERATIONS,
            ContactResponsibility.PAY_HISTORIES,
            ContactResponsibility.POST_CLOSING,
            ContactResponsibility.PRE_PURCHASE_REVIEW,
            ContactResponsibility.PROCESSING,
            ContactResponsibility.PRODUCTION,
            ContactResponsibility.QUALITY_CONTROL,
            ContactResponsibility.SECONDARY_MARKET,
            ContactResponsibility.SENIOR_MANAGER,
            ContactResponsibility.SERVICING,
            ContactResponsibility.SHIPPING,
            ContactResponsibility.TRAILING_DOCS,
            ContactResponsibility.UNDERWRITING
        ],
        documentationType: [ DocumentationType.FULL, DocumentationType.STREAMLINE ],
        feeType: [
            FeeType['203K_SUPPLEMENTAL_FEE'],
            FeeType.ADMINISTRATION_FEE,
            FeeType.CDFI_CREDIT,
            FeeType.CODE_COMPLIANCE_FEE,
            FeeType.COMPLIANCE_FEE,
            FeeType.CONDO_REVIEW,
            FeeType.DOCUMENT_FEE,
            FeeType.DPA_REIMBURSED_BY_TSACH,
            FeeType.DPA_REIMBURSEMENT,
            FeeType.EARLY_RENO_REVIEW_FEE,
            FeeType.ELECTRONIC_UPLOAD_FEE,
            FeeType.FHA_MANUAL_UW,
            FeeType.FLOOD_CERT_FEE,
            FeeType.FLOOD_DETERMINATION,
            FeeType.FLOOD_TRANSFER_FEE,
            FeeType.FUNDING_FEE,
            FeeType.LENDER_FEE,
            FeeType.LOCK_PURCHASE_EXTENSION_FEE,
            FeeType.MCC_FEE,
            FeeType.MERS_FEE,
            FeeType.OTHER,
            FeeType.PIA_FEE,
            FeeType.PIW_FEE,
            FeeType.PREMIER_EVALUATING_FEE,
            FeeType.PROGRAM_APPLICATION_FEE,
            FeeType.REFINOW_APPRAISAL_FEE,
            FeeType.REFIPOSSIBLE_APPRAISAL_FEE,
            FeeType.SBHL_FEE,
            FeeType.SPECIAL_PROGRAM_CREDIT,
            FeeType.SUPPLEMENTAL_ORIGINATION_FEE,
            FeeType.SUSPENSE_FEE,
            FeeType.TAX_SERVICE_FEE,
            FeeType.TEXAS_DPA_FEE,
            FeeType.TX50A6,
            FeeType.UFMIP_FEE,
            FeeType.UNDERWRITING_AND_PURCHASE_FEE,
            FeeType.UNDERWRITING_FEE,
            FeeType.VA_FUNDING_FEE
        ],
        loanPurpose: [
            LoanPurpose.CONSTRUCTION_TO_PERMANENT,
            LoanPurpose.PURCHASE,
            LoanPurpose.REFINANCE,
            LoanPurpose.REFINANCE_CASH_OUT
        ],
        loanType: [
            LoanType.CONVENTIONAL,
            LoanType.FHA,
            LoanType.VA
        ],
        loanLimitType: [
            LoanLimitType.HIGH_BALANCE,
            LoanLimitType.CONFORMING,
            LoanLimitType.JUMBO
        ],
        lockPeriod: [
            LockPeriod.FIFTEEN,
            LockPeriod.THIRTY,
            LockPeriod.FORTY_FIVE,
            LockPeriod.SIXTY,
            LockPeriod.NINETY,
            LockPeriod.SEVEN,
            LockPeriod.TEN,
            LockPeriod.SEVENTY_FIVE
        ],
        numUnits: [
            NumUnits.ONE,
            NumUnits.TWO,
            NumUnits.THREE,
            NumUnits.FOUR
        ],
        occupancyType: [
            OccupancyType.OWNER_OCCUPIED,
            OccupancyType.SECOND_HOME,
            OccupancyType.INVESTMENT
        ],
        productType: [
            ProductType.CONVENTIONAL, ProductType.FHA, ProductType.VA, ProductType.USDA, ProductType.NON_AGENCY
        ],
        propertyType: [
            PropertyType.CONDO, PropertyType.MANUFACTURED, PropertyType.PUD, PropertyType.SINGLE_FAMILY_RESIDENCE
        ],
        servicingType: [ ServicingType.RELEASED, ServicingType.RETAINED ],
        specialtyProgram: [
            SpecialtyProgram.HOME_ONE,
            SpecialtyProgram.HOME_POSSIBLE,
            SpecialtyProgram.HOME_READY,
            SpecialtyProgram.HOME_STYLE,
            SpecialtyProgram.REFI_NOW,
            SpecialtyProgram.REFI_POSSIBLE,
            SpecialtyProgram.VA_IRRRL
        ],
        state: [
            State.AL,
            State.AK,
            State.AZ,
            State.AR,
            State.CA,
            State.CO,
            State.CT,
            State.DE,
            State.DC,
            State.FL,
            State.GA,
            State.HI,
            State.ID,
            State.IL,
            State.IN,
            State.IA,
            State.KS,
            State.KY,
            State.LA,
            State.ME,
            State.MD,
            State.MA,
            State.MI,
            State.MN,
            State.MS,
            State.MO,
            State.MT,
            State.NE,
            State.NV,
            State.NH,
            State.NJ,
            State.NM,
            State.NY,
            State.NC,
            State.ND,
            State.OH,
            State.OK,
            State.OR,
            State.PA,
            State.RI,
            State.SC,
            State.SD,
            State.TN,
            State.TX,
            State.UT,
            State.VT,
            State.VA,
            State.WA,
            State.WV,
            State.WI,
            State.WY
        ]
    }
};


/* eslint-disable max-len */
// Used this to generate the SQL insert statements for the configurable values seed data. Leaving here commented out in case we need to update the seed data in the future.
// const sqlInserts = [];

// for (const type in configurableValues) {
//     for (const name in configurableValues[type as keyof ConfigurableValues]) {
//         const enabledValues = (configurableValues[type as keyof ConfigurableValues][name as TogglableValuesKey & CustomValuesKey] as string[]).join('|');
//         sqlInserts.push(`('${name}', '${type === 'customValues' ? 'custom' : 'togglable'}', '${enabledValues}')`);
//     }
// }

// const sqlStatement = `INSERT INTO config.ConfigurableValue (Name, Type, EnabledValues) VALUES\n${sqlInserts.join(',\n')};`;

// console.log(sqlStatement);

