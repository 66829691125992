import { Document } from '@api';
import { RemoveCircleOutline } from '@mui/icons-material';
import { Paper } from '@mui/material';
import { IconButton } from '@tsp-ui/core';
import { DocumentLink } from '@views/components/DocumentLink';
import clsx from 'clsx';
import { useState } from 'react';

import styles from './DocumentCard.module.scss';


interface DocumentCardProps {
    onRemoveDocument: ((removedDocument: Document) => Promise<void> | void) | undefined;
    document: Document;
    compact?: boolean;
    canManage?: boolean;
    readOnly?: boolean;
    readOnlyTooltip?: string;
}

export function DocumentCard({
    onRemoveDocument, document, compact, canManage, readOnly, readOnlyTooltip
}: DocumentCardProps) {
    const [ isDeleting, setIsDeleting ] = useState(false);

    return (
        <Paper
            className={clsx(styles.documentCard, {
                [styles.compact]: compact
            })}
            variant="outlined"
            key={document.id}
        >
            <DocumentLink
                document={document}
                name={document.url.split('/').pop() || 'unnamed document'}
                variant="body2"
            />

            <IconButton
                tooltip={readOnly ? readOnlyTooltip : canManage ? 'Remove document' : 'You do not have permission to remove documents'}
                loading={isDeleting}
                disabled={!canManage || readOnly}
                edge="end"
                size={compact ? 'small' : undefined}
                onClick={async () => {
                    setIsDeleting(true);
                    try {
                        await onRemoveDocument?.(document);
                    } finally {
                        setIsDeleting(false);
                    }
                }}
            >
                <RemoveCircleOutline
                    color="error"
                    fontSize={compact ? 'small' : undefined}
                />
            </IconButton>
        </Paper>
    );
}
