import api, {
    Permission,
    PermissionCategory,
    PermissionLevel,
    RolePermissionLevel,
    UserType,
    permissionCategoryDisplay
} from '@api';
import { HelpOutline } from '@mui/icons-material';
import {
    Button, DialogContent, Tooltip, Typography
} from '@mui/material';
import {
    DialogActions, FilterTextField,
    IconTypography,
    Radio, RadioGroup, RoutedDialog, RoutedDialogProps
} from '@tsp-ui/core/components';
import { usePageMessage, useParams } from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils/hooks';
import clsx from 'clsx';
import { useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import { AdminRouteParams } from '../../components/AdminPageTemplate';
import { RoleDetailPageContext } from '../RoleDetailPage';

import styles from './EditPermissionsDialog.module.scss';


const EDIT_PERMISSIONS_FORM = 'edit-permissions-form';

interface PermissionLevelsFormValues {
    permissionLevels: RolePermissionLevel[];
}

export default function EditPermissionsDialog(props: Omit<RoutedDialogProps, 'title' | 'children'>) {
    const { roleID } = useParams<AdminRouteParams<'role'>>();

    const { id: clientID } = useGetCurrentAccount();

    const [ loading, setLoading ] = useState(false);

    const navigate = useNavigate();
    const {
        role, permissions, permissionLevels, updatePermissionLevels
    } = useContext(RoleDetailPageContext);

    const [ searchTerm, setSearchTerm ] = useState('');
    const [ debouncedTerm ] = useDebounce(searchTerm, 300);

    const filteredPermissions = permissions.filter(({ description }) => (
        description.toLocaleLowerCase().includes(debouncedTerm)
    ));

    const pageMessage = usePageMessage();
    const formMethods = useForm<PermissionLevelsFormValues>({ defaultValues: { permissionLevels } });

    const handleSubmit = formMethods.handleSubmit(async ({ permissionLevels }) => {
        setLoading(true);

        try {
            updatePermissionLevels(
                await api.roles.editPermissionLevels(clientID, roleID, permissionLevels)
            );

            navigate(props.closeTo);

            pageMessage.success('Role permissions saved');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving the role permissions', error);
        }

        setLoading(false);
    });

    return (
        <RoutedDialog
            {...props}
            title="Edit Permissions"
        >
            <DialogContent>
                <div className={styles.buttonContainer}>
                    <FilterTextField
                        autoFocus
                        placeholder="Filter permissions"
                        helperText="Filter by description"
                        onChange={e => setSearchTerm(e.target.value.toLocaleLowerCase())}
                    />
                </div>

                <form
                    noValidate
                    id={EDIT_PERMISSIONS_FORM}
                    onSubmit={handleSubmit}
                >
                    <FormProvider {...formMethods}>
                        <div className={styles.permissions}>
                            <div className={clsx(styles.row, styles.headerRow)}>
                                <div />

                                <Typography fontWeight={500}>
                                    Always
                                </Typography>

                                <IconTypography
                                    iconPosition="after"
                                    icon={(
                                        <Tooltip title="Gives permission when user is assigned to a loan">
                                            <HelpOutline color="primary" />
                                        </Tooltip>
                                    )}
                                >
                                    When assigned
                                </IconTypography>

                                <Typography fontWeight={500}>
                                    Never
                                </Typography>
                            </div>

                            {Object.keys(PermissionCategory).map((category) => (
                                <CategoryPermissions
                                    key={category}
                                    category={category as PermissionCategory}
                                    permissions={filteredPermissions}
                                    permissionLevels={permissionLevels}
                                    userType={role?.type}
                                />
                            ))}
                        </div>
                    </FormProvider>
                </form>
            </DialogContent>

            <DialogActions loading={loading}>
                <Button onClick={() => navigate(props.closeTo)}>
                    Cancel
                </Button>

                <Button
                    variant="contained"
                    form={EDIT_PERMISSIONS_FORM}
                    type="submit"
                    disabled={loading}
                >
                    Save
                </Button>
            </DialogActions>
        </RoutedDialog>
    );
}

interface CategoryPermissionsProps {
    category: PermissionCategory;
    permissions: Permission[];
    permissionLevels: RolePermissionLevel[];
    userType?: UserType;
}

function CategoryPermissions({
    category, permissions, permissionLevels, userType
}: CategoryPermissionsProps) {
    const categoryPermissions = permissions.filter(perm => (
        perm.category === category && userType && perm.allowableRoleTypes.includes(userType)
    ));

    return categoryPermissions.length ? (
        <>
            <Typography className={styles.sectionHeader}>
                {permissionCategoryDisplay[category]}
            </Typography>

            {categoryPermissions.map(permission => (
                <div
                    className={styles.row}
                    key={permission.id}
                >
                    <Typography
                        variant="body2"
                        className={styles.permissionTypography}
                    >
                        {permission.description}
                    </Typography>

                    <RadioGroup<PermissionLevelsFormValues>
                        name={`permissionLevels.${
                            permissionLevels.findIndex(permissionLevel => (
                                permission?.permissionType === permissionLevel.permissionType
                            ))
                        }.permissionLevel`}
                        className={styles.radioGroup}
                    >
                        <Radio
                            value={PermissionLevel.ALWAYS}
                            label=""
                        />

                        {permission?.allowAssigned ? (
                            <Radio
                                value={PermissionLevel.WHEN_ASSIGNED}
                                label=""
                            />
                        ) : <div />}

                        <Radio
                            value={PermissionLevel.NEVER}
                            label=""
                        />
                    </RadioGroup>
                </div>
            ))}
        </>
    ) : null;
}
