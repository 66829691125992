import api from '@api';
import { Button, DialogContent } from '@mui/material';
import { getItemByKey, useForm } from '@tsp-ui/core';
import {
    Autocomplete, DialogActions, RoutedDialog, usePageMessage
} from '@tsp-ui/core/components';
import { useGetCurrentAccount } from '@utils';
import { useContext, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import styles from './LoanStatusDialog.module.scss';
import { LoanStatusContext } from './LoanStatusPage';


export function RequiredDocumentsDialog() {
    const [ loading, setLoading ] = useState(false);
    const {
        availableDocs, requiredDocCodes, setRequiredDocCodes
    } = useContext(LoanStatusContext);

    const { id: clientId } = useGetCurrentAccount();

    const pageMessage = usePageMessage();
    const navigate = useNavigate();

    const formMethods = useForm<{requiredDocCodes: string[]}>({
        defaultValues: { requiredDocCodes }
    });

    const handleSubmit = formMethods.handleSubmit(async ({ requiredDocCodes }) => {
        setLoading(true);

        try {
            setRequiredDocCodes(await api.documentType.updateRequiredDocs(clientId, requiredDocCodes));

            pageMessage.success('Required documents updated');

            navigate('..');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while updating required documents', error);
        }

        setLoading(false);
    });

    return (
        <RoutedDialog
            title="Update required documents"
            maxWidth="md"
            closeTo=".."
        >
            <DialogContent>
                <FormProvider {...formMethods}>
                    <form
                        id="required-documents-form"
                        className={styles.root}
                        onSubmit={handleSubmit}
                    >
                        <Autocomplete<{requiredDocCodes: string[]}>
                            name="requiredDocCodes"
                            label="Required documents"
                            disableCloseOnSelect
                            options={availableDocs
                                .sort((a, b) => a.containerLabel.localeCompare(b.containerLabel))
                                .map(({ code }) => code)}
                            getOptionLabel={code => getItemByKey(availableDocs, 'code', code).name}
                            groupBy={(code) => {
                                const documentType = getItemByKey(availableDocs, 'code', code);

                                return !documentType ? '--' : documentType.containerLabel;
                            }}
                            multiple
                        />
                    </form>
                </FormProvider>
            </DialogContent>

            <DialogActions loading={loading}>
                <Button
                    type="submit"
                    form="required-documents-form"
                    variant="contained"
                    disabled={loading}
                >
                    Save
                </Button>
            </DialogActions>
        </RoutedDialog>
    );
}
