import api, { EligibilityGuideline } from '@api';
import { Button, DialogContent, Paper } from '@mui/material';
import {
    DialogActions, RoutedDialog, RoutedDialogImplProps, usePageMessage, useParams
} from '@tsp-ui/core';
import { AdminRouteParams, AdminSubRouteParams } from '@views/admin/components/AdminPageTemplate';
import { ExclusionsContext } from '@views/admin/investors/InvestorDetailPage/InternalInvestorDetailPage';
import {
    InternalLoanProgramDetailPageContext
} from '@views/admin/investors/InvestorDetailPage/LoanProgramDetailPage/InternalLoanProgramDetailPage';
import {
    EligibilityExclusionsFormValues,
    ExclusionGroupRow, exclusionsToFormValues, formValuesToExclusions
} from '@views/admin/investors/InvestorDetailPage/components/ExclusionGroupRow';
import { useEligibilityVersionIdQueryParam } from '@views/admin/investors/components/EligibilityVersionButton';
import {
    Dispatch, SetStateAction, useContext, useState
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import styles from './InternalInvestorEligibilityExclusionsDialog.module.scss';


interface InternalEligibilityExclusionsFormValues {
    exclusions: EligibilityExclusionsFormValues;
}

export default function InternalInvestorEligibilityExclusionsDialog(props: RoutedDialogImplProps) {
    const { exclusions, setExclusions } = useContext(ExclusionsContext);

    const eligibilityVersionId = useEligibilityVersionIdQueryParam();
    const { investorID } = useParams<AdminRouteParams<'investor'>>();

    async function handleSubmit(formValues: InternalEligibilityExclusionsFormValues) {
        const exclusionsToSubmit = formValuesToExclusions(formValues.exclusions, eligibilityVersionId!)
            .map((exclusion) => ({
                ...exclusion,
                investorId: investorID
            }));

        const newExclusions = await api.investors.updateExclusions(investorID, exclusionsToSubmit);

        setExclusions(newExclusions);
    }

    return (
        <EligibilityExclusionsDialogBase
            title="Edit investor eligibility exclusions"
            exclusions={exclusions}
            handleSubmit={handleSubmit}
            {...props}
        />
    );
}

export function InternalLoanProgramEligibilityExclusionsDialog(props: RoutedDialogImplProps) {
    const { matrix, setMatrix } = useContext(InternalLoanProgramDetailPageContext);

    const eligibilityVersionId = useEligibilityVersionIdQueryParam();
    const { investorID, loanProgramID } = useParams<AdminSubRouteParams<'loanProgram'>>();

    async function handleSubmit(formValues: InternalEligibilityExclusionsFormValues) {
        const updatedMatrix = await api.investors.updateEligibilityMatrix(investorID, loanProgramID, {
            ...matrix!,
            exclusions: formValuesToExclusions(formValues.exclusions, eligibilityVersionId!).map((exclusion) => ({
                ...exclusion,
                loanProgramId: loanProgramID
            }))
        });

        setMatrix(updatedMatrix);
    }

    return (
        <EligibilityExclusionsDialogBase
            title="Edit eligibility exclusions"
            exclusions={matrix?.exclusions}
            handleSubmit={handleSubmit}
            {...props}
        />
    );
}

interface EligibilityExclusionsDialogBaseProps extends RoutedDialogImplProps {
    title: string;
    exclusions: EligibilityGuideline[] | undefined;
    handleSubmit: (formValues: InternalEligibilityExclusionsFormValues) => Promise<void>;
}

function EligibilityExclusionsDialogBase({ exclusions, handleSubmit, ...props }: EligibilityExclusionsDialogBaseProps) {
    const [ saveLoading, setSaveLoading ] = useState(false);

    return (
        <RoutedDialog
            {...props}
            loading={!exclusions}
            keepLocationSearch
        >
            <DialogContent>
                {exclusions && (
                    <InternalEligibilityExclusionsForm
                        setLoading={setSaveLoading}
                        handleSubmit={handleSubmit}
                        defaultValues={{
                            exclusions: exclusionsToFormValues(exclusions)
                        }}
                    />
                )}
            </DialogContent>

            <DialogActions loading={saveLoading}>
                <Button
                    variant="contained"
                    type="submit"
                    form={formId}
                >
                    Save
                </Button>
            </DialogActions>
        </RoutedDialog>
    );
}

interface InternalEligibilityExclusionsFormProps {
    defaultValues: InternalEligibilityExclusionsFormValues;
    handleSubmit: (formValues: InternalEligibilityExclusionsFormValues) => Promise<void>;
    setLoading: Dispatch<SetStateAction<boolean>>;
}

function InternalEligibilityExclusionsForm({
    defaultValues, handleSubmit: handleSubmitProp, setLoading
}: InternalEligibilityExclusionsFormProps) {
    const pageMessage = usePageMessage();

    const { search } = useLocation();
    const navigate = useNavigate();

    const formMethods = useForm<InternalEligibilityExclusionsFormValues>({ defaultValues });

    const handleSubmit = formMethods.handleSubmit(async (formValues) => {
        try {
            setLoading(true);

            await handleSubmitProp(formValues);

            navigate(`..${search}`);
            pageMessage.success('Eligibility exclusions saved');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving the eligibility exclusions', error);
        }

        setLoading(false);
    });

    return (
        <Paper
            variant="outlined"
            className={styles.filterContainer}
        >
            <form
                id={formId}
                noValidate
                onSubmit={handleSubmit}
            >
                <FormProvider {...formMethods}>
                    <ExclusionGroupRow nameBase="exclusions" />
                </FormProvider>
            </form>
        </Paper>
    );
}

const formId = 'edit-eligibility-exclusions-form';
