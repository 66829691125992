/* eslint-disable @typescript-eslint/no-unused-vars */
import api from '@api';

import { apiUtils } from '../api/api-utils';

import { configureAmplify } from './configureAmplify';


export interface AppConfig {
    apiUrl: string;
    webSocketUrl: string;
    awsAuth: {
        region: string;
        userPoolId: string;
        userPoolWebClientId: string;
    };
}

const awsAuth: AppConfig['awsAuth'] = {
    region: 'us-east-1',
    userPoolId: 'us-east-1_obMWx2WVR',
    userPoolWebClientId: '44i9pv1ejq2icm67msdiuvk4fd'
};

// Api url for mocks with local Mock Service Worker server
const mswApiUrl = 'https://localhost:5000';

// Api url for dev api
const devApiUrl = 'https://dev-api.premicorr.net/api';

// Api url for backend running locally
const localBackendApiUrl = 'http://localhost:5190/api';

/**
 * Loads config variables for the current environment and uses them to configure services that depend on them.
 * If the app is running in production mode, fetches the config json from the server, else falls back to the
 * local config.
 */
export default async function configureApp() {
    const apiUrl = mswApiUrl;

    let config: AppConfig = {
        apiUrl,
        webSocketUrl: apiUrl.replace('/api', '/notification-hub'),
        awsAuth
    };

    if (process.env.NODE_ENV === 'production') {
        const configResponse = await fetch('/config.json');
        config = await configResponse.json();
    }

    configureAmplify(config);
    apiUtils.configure(config);

    if (!api.webSocket.url) {
        api.webSocket.url = config.webSocketUrl;
    }
}
