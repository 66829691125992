import {
    ConditionCategory,
    ConditionCategoryAssociation,
    LOS,
    LOSConfiguration,
    LoanFolder,
    PremicorrConditionCategory
} from '@api';
import { rest } from 'msw';

import { getMockUrl } from '../../../mocks/getMockUrl';


const isEmpty = false;

export const mocks = [
    rest.get(getMockUrl('/client/:clientId/los'), (_, res, ctx) => res(
        ctx.status(isEmpty ? 204 : 200),
        ctx.json(isEmpty ? undefined : losConfiguration)
    )),
    rest.post(getMockUrl('/client/:clientId/los'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.json(req.body)
    )),
    rest.put(getMockUrl('/client/:clientId/los'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.json(req.body),
        ctx.delay(500)
    )),
    rest.get(getMockUrl('/client/:clientId/los/loan-folders'), (_, res, ctx) => res(
        ctx.status(isEmpty ? 204 : 200),
        ctx.json(isEmpty ? undefined : loanFolders)
    )),
    rest.get(getMockUrl('/client/:clientId/los/condition-categories'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.json(req.url.searchParams.get('los')
            ? conditionCategoryAssociations : undefined)
    )),
    rest.put(getMockUrl('/client/:clientId/los/condition-categories'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.json(req.body),
        ctx.delay(500)
    ))
];

const losConfiguration: LOSConfiguration = {
    los: LOS.ENCOMPASS,
    apiUrl: 'https://api.elliemae.com/',
    clientId: '123',
    clientSecret: 'XYZ',
    instanceId: 'instance-123'
};

const loanFolders: LoanFolder[] = [
    {
        folderName: 'Pipeline',
        folderType: 'Regular',
        actionsAllowed: [
            'DuplicateFrom', 'DuplicateInto', 'Import', 'Originate'
        ],
        isArchiveFolder: false,
        includeDuplicateLoanCheck: true
    },
    {
        folderName: 'Reference',
        folderType: 'Regular',
        actionsAllowed: [
            'DuplicateFrom', 'DuplicateInto', 'Import', 'Originate'
        ],
        isArchiveFolder: false,
        includeDuplicateLoanCheck: true
    }
];

let associationID = 0;

const conditionCategoryAssociations: ConditionCategoryAssociation[] = [
    {
        associationID: `${associationID++}`,
        los: LOS.ENCOMPASS,
        losConditionCategory: ConditionCategory.CREDIT,
        premicorrConditionCategory: PremicorrConditionCategory.CREDIT_AND_LIABILITIES
    },
    {
        associationID: `${associationID++}`,
        los: LOS.ENCOMPASS,
        losConditionCategory: ConditionCategory.ASSETS,
        premicorrConditionCategory: PremicorrConditionCategory.ASSETS_AND_INCOME
    },
    {
        associationID: `${associationID++}`,
        los: LOS.ENCOMPASS,
        losConditionCategory: ConditionCategory.MISC,
        premicorrConditionCategory: PremicorrConditionCategory.SPECIALTY_ISSUES
    },
    {
        associationID: `${associationID++}`,
        los: LOS.ENCOMPASS,
        losConditionCategory: ConditionCategory.LEGAL,
        premicorrConditionCategory: PremicorrConditionCategory.FLOOD_AND_HAZARD
    },
    {
        associationID: `${associationID++}`,
        los: LOS.ENCOMPASS,
        losConditionCategory: ConditionCategory.PROPERTY,
        premicorrConditionCategory: PremicorrConditionCategory.REO_ANALYSIS
    },
    {
        associationID: `${associationID++}`,
        los: LOS.ENCOMPASS,
        losConditionCategory: ConditionCategory.MISC,
        premicorrConditionCategory: PremicorrConditionCategory.AUS
    },
    {
        associationID: `${associationID++}`,
        los: LOS.ENCOMPASS,
        losConditionCategory: ConditionCategory.MISC,
        premicorrConditionCategory: PremicorrConditionCategory.SALES_CONTRACT
    },
    {
        associationID: `${associationID++}`,
        los: LOS.ENCOMPASS,
        losConditionCategory: ConditionCategory.MISC,
        premicorrConditionCategory: PremicorrConditionCategory.SECONDARY_FINANCING
    }
];

