import { ActiveTokenContext } from '@views/components/ActiveTokenContext';
import { useContext, useMemo } from 'react';

import { decodeAuthToken } from '../../api/api-utils';


export function useDecodedToken() {
    const { activeAuthToken } = useContext(ActiveTokenContext);
    return useMemo(() => decodeAuthToken(activeAuthToken), [ activeAuthToken ]);
}
