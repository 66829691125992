import {
    CustomerCompensationPercentageOf, CustomerCompensationType, PurchaseAdviceFee
} from '../..';
import { apiUtils } from '../../api-utils';


export function getFeesComp(clientID: string, customerID: string): Promise<CustomerFeesComp> {
    return apiUtils.get(`/client/${clientID}/customer/${customerID}/feeCompensation`);
}

export function updateFeesComp(
    clientID: string, customerID: string, feesComp: CustomerFeesComp
): Promise<CustomerFeesComp> {
    return apiUtils.put(`/client/${clientID}/customer/${customerID}/feeCompensation`, feesComp);
}

export interface CustomerFeesComp {
    compensation?: CustomerCompensation;
    fees?: CustomerFee[];
}

export interface CustomerFee extends Omit<PurchaseAdviceFee, 'id'> {
    id?: string;
    customerId: string;
    isActive: boolean;
    purchaseAdviceFeeId?: string;
}

export interface CustomerCompensation {
    id: string;
    isActive: boolean;
    firstLienOnly: boolean;
    type: CustomerCompensationType;
    amount?: number;
    percentage?: number;
    percentageOf?: CustomerCompensationPercentageOf;
    minAmount?: number;
    maxAmount?: number;
    notes: string;
}
