import api from '@api';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { useGetCurrentAccount } from '@utils';
import React, {
    Dispatch, SetStateAction, useCallback, useState
} from 'react';
import { useDebounce } from 'use-debounce';


interface LoanNumberAutocompleteProps {
    className?: string;
    loanNumberSearch: string | null;
    setLoanNumberSearch: Dispatch<SetStateAction<string | null>>;
}

export default function LoanNumberAutoComplete({
    className, loanNumberSearch, setLoanNumberSearch
}: LoanNumberAutocompleteProps) {
    const { id: clientId, customerId } = useGetCurrentAccount();

    const [ loanNumberOptions, setLoanNumberOptions ] = useState<string[]>([]);
    const [ errorText, setErrorText ] = useState('');
    const [ loading, setLoading ] = useState(false);

    const fetchFilteredLoanNumbers = useCallback(async (query: string) => {
        if (query.length > 2) {
            setLoading(true);
            try {
                const options = await api.loans.getLoanNumbers(clientId, {
                    loanNumber: query,
                    pageNumber: 1
                }, customerId);
                setLoanNumberOptions(options);
                setErrorText('');
            } catch (error) {
                setErrorText('Error fetching loans');
                setLoanNumberOptions([]);
            } finally {
                setLoading(false);
            }
        } else {
            setLoanNumberOptions([]);
            setErrorText('');
        }
    }, [ clientId, customerId ]);

    const [ debouncedFetchFilteredLoanNumbers ] = useDebounce(fetchFilteredLoanNumbers, 300);

    return (
        <Autocomplete
            className={className}
            options={loanNumberOptions}
            value={loanNumberSearch}
            inputValue={loanNumberSearch || ''}
            onInputChange={(_, newInputValue) => {
                setLoanNumberSearch(newInputValue);
                debouncedFetchFilteredLoanNumbers(newInputValue);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Filter by loan number"
                    variant="filled"
                    helperText={errorText}
                    error={!!errorText}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                    />
                                ) : null}

                                {params.InputProps.endAdornment}
                            </>
                        )
                    }}
                />
            )}
            freeSolo
            size="small"
            noOptionsText="Start typing to search"
            loading={loading}
        />
    );
}
