import { EligibilityGuideline, LoanProperty, loanPropertyEligibilityGuidelineFieldNames } from '@api';
import {
    formatLoanProgramFieldFormValue,
    parseLoanProgramFieldFormValue
} from '@views/admin/investors/InvestorDetailPage/LoanProgramDetailPage/components/LoanProgramForm';
import {
    EligibilityExclusionFormValues, EligibilityExclusionGroupFormValues, EligibilityExclusionsFormValues
} from '@views/admin/investors/InvestorDetailPage/components/ExclusionGroupRow/ExclusionGroupRow';

/**
 * Sequential form ID for exclusion groups and rules
 */
let exclusionFormID = -1;

/**
 * Generates a sequential ID for use in the exclusion form. This is needed because we need unique
 * keys for react-hook-form's useFieldArray that is used in the exclusion form.
 */
export const getNextExclusionFormID = () => String(exclusionFormID--);

/**
 * Creates an empty rule with an id attached
 */
export function getEmptyExclusionRule(): EligibilityExclusionFormValues {
    return {
        id: `${getNextExclusionFormID()}`,
        loanProperty: '',
        value: ''
    };
}

/**
 * Creates an empty exclusion group with an id and one blank rule attached
 */
export function getEmptyEligibilityExclusions(omitGroups?: boolean): EligibilityExclusionsFormValues {
    return {
        operator: 'or',
        exclusions: [ getEmptyExclusionRule() ],
        ...(!omitGroups && { groups: [] })
    };
}

export function formValuesToExclusions(
    formValues: EligibilityExclusionsFormValues, eligibilityVersionId: string
): EligibilityGuideline[] {
    const { exclusions, groups, operator } = formValues;

    if (!groups?.length && exclusions?.length === 1 && exclusions?.[0].loanProperty === '') {
        return [];
    }

    return [
        ...(!exclusions?.length ? [] : operator === 'and'
            ? [ getGuidelineForExclusionFormValues(exclusions[0].id, eligibilityVersionId, exclusions) ]
            : exclusions.map((exclusion) => (
                getGuidelineForExclusionFormValues(exclusion.id, eligibilityVersionId, [ exclusion ])))),
        ...(!groups?.length ? [] : groups.flatMap(({ id, exclusions: groupExclusions }) => (
            [ getGuidelineForExclusionFormValues(id, eligibilityVersionId, groupExclusions!) ])))
    ];
}

function getGuidelineForExclusionFormValues(
    id: string, eligibilityVersionId: string, exclusions: EligibilityExclusionFormValues[]
): EligibilityGuideline {
    return {
        id: id.includes('_') ? getNextExclusionFormID() : id,
        eligibilityVersionId,
        isExclusion: true,
        ...Object.fromEntries(exclusions.map((formExclusion) => (
            [
                loanPropertyEligibilityGuidelineFieldNames[formExclusion.loanProperty as LoanProperty],
                parseLoanProgramFieldFormValue(formExclusion.loanProperty as LoanProperty, formExclusion.value)
            ]
        )))
    };
}

export function exclusionsToFormValues(exclusions: EligibilityGuideline[]): EligibilityExclusionsFormValues {
    if (!exclusions.length) {
        return getEmptyEligibilityExclusions();
    }

    const formExclusions: EligibilityExclusionFormValues[] = [];
    const formGroups: EligibilityExclusionGroupFormValues[] = [];

    exclusions.forEach((exclusion) => {
        const filledPropertyEntries = Object.entries(loanPropertyEligibilityGuidelineFieldNames)
            .filter(([ _, fieldName ]) => (
                exclusion[fieldName] !== null && exclusion[fieldName] !== undefined
            ));

        function mapFilledPropertyToFormValues(
            [ loanProperty, fieldName ]: [ string, keyof EligibilityGuideline ]
        ): EligibilityExclusionFormValues {
            const value = exclusion[fieldName]!;
            return {
                id: `${exclusion.id}_${loanProperty}`,
                loanProperty: loanProperty as LoanProperty,
                value: typeof value === 'string'
                    ? value
                    : formatLoanProgramFieldFormValue(value) as string
            };
        }

        if (filledPropertyEntries.length === 1) {
            formExclusions.push(mapFilledPropertyToFormValues(filledPropertyEntries[0]));
        } else {
            formGroups.push({
                id: exclusion.id,
                exclusions: filledPropertyEntries.map(mapFilledPropertyToFormValues)
            });
        }
    });

    if (!formExclusions.length && formGroups.length === 1) {
        formExclusions.push(...(formGroups[0]?.exclusions || []));
        formGroups.pop();
    }

    return {
        operator: (exclusions.length > 1 || formGroups.length) ? 'or' : 'and',
        exclusions: formExclusions,
        groups: formGroups
    };
}
