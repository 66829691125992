import api, {
    FeeAmountType, PermissionType, PurchaseAdviceFee, feeAmountPercentageOfDisplay, feeAmountTypeDisplay, feeTypeDisplay
} from '@api';
import { Edit, RemoveCircleOutline } from '@mui/icons-material';
import { Paper, Tooltip, Typography } from '@mui/material';
import { HorizontalLabeledValue, IconButton, ProgressIndicator } from '@tsp-ui/core';
import {
    formatCurrency, removeItemById, useConfirm, usePageMessage
} from '@tsp-ui/core/utils';
import { useGetCurrentAccount, useHasPermission } from '@utils/hooks';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import styles from './PurchaseAdviceFeeCard.module.scss';
import { PurchaseAdviceContext } from './PurchaseAdviceManagementPage';


interface PurchaseAdviceFeeCardProps {
    fee: PurchaseAdviceFee;
}

export function PurchaseAdviceFeeCard({ fee }: PurchaseAdviceFeeCardProps) {
    const [ isDeleting, setIsDeleting ] = useState(false);
    const { purchaseAdviceFees, setPurchaseAdviceFees } = useContext(PurchaseAdviceContext);

    const confirm = useConfirm();
    const pageMessage = usePageMessage();
    const { id: clientId } = useGetCurrentAccount();

    const [ canManageFees ] = useHasPermission([ PermissionType.MANAGE_PURCHASE_ADVICE_FEE ]);

    return (
        <Paper
            elevation={0}
            className={styles.feeCard}
        >
            <div>
                <div className={styles.chips}>
                    <Typography>
                        {feeTypeDisplay[fee.type]}
                    </Typography>

                    <Tooltip title="Fee Amount Type">
                        <Typography
                            variant="body2"
                            className={styles.chip}
                        >
                            {feeAmountTypeDisplay[fee.amountType]}
                        </Typography>
                    </Tooltip>
                </div>

                {fee.amountType === FeeAmountType.FIXED ?  (
                    <Typography
                        variant="body2"
                        color="textSecondary"
                    >
                        {formatCurrency(fee.amount)}
                    </Typography>
                ) : (
                    <>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                        >
                            {(fee.percentage! * 100).toFixed(3)}

                            % of the{' '}

                            {feeAmountPercentageOfDisplay[fee.percentageOf!].toLowerCase()}
                        </Typography>

                        <div className={styles.labels}>
                            <HorizontalLabeledValue
                                label="Min:"
                                value={typeof fee.minAmount === 'number' ? formatCurrency(fee.minAmount) : '--'}
                            />

                            <HorizontalLabeledValue
                                label="Max:"
                                value={typeof fee.maxAmount === 'number' ? formatCurrency(fee.maxAmount) : '--'}
                                classNames={{
                                    label: styles.maxLabel
                                }}
                            />
                        </div>
                    </>
                )}
            </div>

            <div className={styles.buttons}>
                <IconButton
                    tooltip={canManageFees ? 'Edit fee' : 'You do not have permission to edit fees'}
                    disabled={!canManageFees}
                    component={Link}
                    to={`fees/${fee.id}`}
                >
                    <Edit color="secondary" />
                </IconButton>

                {isDeleting ? <ProgressIndicator className={styles.progress} /> : (
                    <IconButton
                        tooltip={canManageFees ? 'Delete fee' : 'You do not have permission to delete fees'}
                        disabled={!canManageFees}
                        edge="end"
                        onClick={async () => {
                            setIsDeleting(true);

                            if (await confirm('Are you sure you want to delete this fee?')) {
                                try {
                                    await api.purchaseAdvice.deletePurchaseAdviceFee(clientId, fee.id);

                                    setPurchaseAdviceFees(removeItemById(purchaseAdviceFees, fee.id));

                                    pageMessage.success('Fee deleted');
                                } catch (error) {
                                    pageMessage.handleApiError('An error occurred while deleting the fee', error);
                                }
                            }

                            setIsDeleting(false);
                        }}
                    >
                        <RemoveCircleOutline color="error" />
                    </IconButton>
                )}
            </div>
        </Paper>
    );
}
