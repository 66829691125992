import { DialogContent, DialogContentProps } from '@mui/material';
import clsx from 'clsx';

import styles from './CardDialogContent.module.scss';


export default function CardDialogContent(props: DialogContentProps) {
    return (
        <DialogContent
            {...props}
            className={clsx(props.className, styles.root)}
        />
    );
}
