import { NumericFieldOperator, NumericMatrixEntryType } from '@api';
import { createDate, randomBoolean, randomNum } from '@tsp-ui/core';
import { rest } from 'msw';

import { getMockUrl } from '../../mocks/getMockUrl';
import { mockedClientPrivateInvestors } from '../client/investor/clientInvestor-mocks';
import { mockedLoanProgramBase } from '../investor/investor-mocks';

import { ExclusionOverlay, ExclusionOverlayType } from './exclusion-overlay-api';


let overlayID = 1;
let guidelineID = 1;

export const mocks = [
    rest.get(getMockUrl('/client/:clientId/exclusion-overlay'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.json(req.url.searchParams.get('loanProgramId')
            ? [
                ...clientOverlays, ...investorOverlays, ...loanProgramOverlays
            ] : req.url.searchParams.get('investorId') ? [ ...clientOverlays, ...investorOverlays ] : clientOverlays)
    )),
    rest.put(getMockUrl('/client/:clientId/exclusion-overlay/:exclusionOverlayID'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.json(req.body)
    )),
    rest.post(getMockUrl('/client/:clientId/exclusion-overlay'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.json({
            ...req.body as ExclusionOverlay,
            id: String(overlayID++)
        })
    ))
];

const clientOverlays: ExclusionOverlay[] = [
    {
        id: String(overlayID++),
        type: ExclusionOverlayType.CLIENT,
        name: 'Exclude high DTI',
        description: 'Don\'t allow DTI over 90%',
        investorIds: randomBoolean() ? [ 'ALL' ] : mockedClientPrivateInvestors.map(({ id }) => id).slice(0, randomNum(1, mockedClientPrivateInvestors.length - 1)),
        effectiveDate: createDate(-10).toISOString(),
        exclusions: [
            {
                id: `${guidelineID++}`,
                isExclusion: true,
                eligibilityVersionId: '1',
                investorId: '1',
                dti: {
                    type: NumericMatrixEntryType.COMPARISON,
                    operator: NumericFieldOperator.GREATER_THAN,
                    value: 90
                }
            }
        ]
    }
];

const investorOverlays: ExclusionOverlay[] = [
    {
        id: String(overlayID++),
        type: ExclusionOverlayType.INVESTOR,
        name: 'Credit score adjustment',
        description: 'Don\'t allow credit scores below 700',
        loanProgramIds: randomBoolean() ? [ 'ALL' ] : mockedLoanProgramBase.map(({ id }) => id).slice(0, randomNum(1, mockedLoanProgramBase.length - 1)),
        effectiveDate: createDate(-10).toISOString(),
        exclusions: [
            {
                id: `${guidelineID++}`,
                isExclusion: true,
                eligibilityVersionId: '1',
                investorId: '1',
                fico: {
                    type: NumericMatrixEntryType.COMPARISON,
                    operator: NumericFieldOperator.LESS_THAN,
                    value: 700
                }
            }
        ]
    }
];

const loanProgramOverlays: ExclusionOverlay[] = [
    {
        id: String(overlayID++),
        type: ExclusionOverlayType.LOAN_PROGRAM,
        name: 'Credit score adjustment',
        description: 'Don\'t allow credit scores below 660',
        effectiveDate: createDate(-10).toISOString(),
        exclusions: [
            {
                id: `${guidelineID++}`,
                isExclusion: true,
                eligibilityVersionId: '1',
                investorId: '1',
                fico: {
                    type: NumericMatrixEntryType.COMPARISON,
                    operator: NumericFieldOperator.LESS_THAN,
                    value: 660
                }
            }
        ]
    }
];
