import api from '@api';
import {
    Button, Paper, Step, StepLabel, Stepper
} from '@mui/material';
import { Loader, usePageMessage } from '@tsp-ui/core';
import { useActingClientID } from '@utils/hooks';
import { LLPARoutesContext } from '@views/admin/llpas/LLPARoutes';
import {
    DetailsStepFormValues,
    LLPADetailsStepForm, MatrixStepForm,
    llpaFormID,
    matrixFormValuesToApi
} from '@views/admin/llpas/components/LLPADialog';
import Page from '@views/components/Page';
import { useContext, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import styles from './AddLLPAPage.module.scss';


export default function AddLLPAPage() {
    const pageMessage = usePageMessage();
    const clientId = useActingClientID();
    const navigate = useNavigate();

    const [ activeStep, setActiveStep ] = useState(0);
    const [ detailsFormValues, setDetailsFormValues ] = useState<DetailsStepFormValues>();
    const [ saveLoading, setSaveLoading ] = useState(false);
    const { llpas, setLlpas } = useContext(LLPARoutesContext);

    const isCap = useIsCapQueryParam();

    const stepContent = [
        <Paper
            variant="outlined"
            className={styles.detailsPaper}
        >
            <LLPADetailsStepForm
                isCap={isCap}
                handleSubmit={(formValues) => {
                    setActiveStep(activeStep + 1);
                    setDetailsFormValues(formValues);
                }}
            />
        </Paper>,
        <div className={styles.matrixContainer}>
            <MatrixStepForm
                handleSubmit={async (formValues) => {
                    try {
                        setSaveLoading(true);

                        const newLlpa = await api.llpa.createLLPA(clientId, {
                            ...detailsFormValues!,
                            version: {
                                ...detailsFormValues!.version,
                                ...matrixFormValuesToApi(formValues, isCap)
                            }
                        });

                        setLlpas((llpas || []).concat(newLlpa));

                        navigate(`../${newLlpa.id}`);
                    } catch (error) {
                        pageMessage.handleApiError('An error occurred while saving the LLPA', error);
                    }

                    setSaveLoading(false);
                }}
            />
        </div>
    ];

    return (
        <Page
            header={`Add LLPA${isCap ? ' Cap' : ''}`}
            variant="centered"
        >
            <Stepper
                activeStep={activeStep}
                className={styles.stepper}
            >
                <Step>
                    <StepLabel>
                        Details
                    </StepLabel>
                </Step>

                <Step>
                    <StepLabel>
                        Matrix
                    </StepLabel>
                </Step>
            </Stepper>

            {stepContent[activeStep]}

            <div className={styles.buttons}>
                {activeStep === 0 ? (
                    <Button
                        component={Link}
                        to=".."
                    >
                        Cancel
                    </Button>
                ) : (
                    <Button onClick={() => setActiveStep(activeStep - 1)}>
                        Back
                    </Button>
                )}

                <Button
                    variant="contained"
                    type="submit"
                    form={llpaFormID}
                >
                    {activeStep === stepContent.length - 1 ? 'Save' : 'Next'}
                </Button>
            </div>

            <Loader
                loading={saveLoading}
                className={styles.loader}
            />
        </Page>
    );
}

export function useIsCapQueryParam() {
    const { search } = useLocation();

    return useMemo(() => {
        const isCapParam = new URLSearchParams(search).get('isCap');

        return !!isCapParam && isCapParam === 'true';
    }, [ search ]);
}
