import { LoanAllocationLimitType, UnderwritingType } from '@api';
import { createDate } from '@tsp-ui/core/utils';
import { rest } from 'msw';

import { getMockUrl } from '../../../mocks/getMockUrl';
import { mockedProducts } from '../../products/products-mocks';
import { mockedProductTypeConfigs } from '../eligibleProducts/eligibleProducts-mocks';

import { LoanAllocationLimit } from './limit-api';


const urlPrefix = '/client/:clientId/customer/:customerID';

export const mocks = [
    rest.get(getMockUrl(`${urlPrefix}/loan-allocation-limit`), (req, res, ctx) => res(
        ctx.status(200),
        ctx.json(mockedLimits)
    )),
    rest.post(getMockUrl(`${urlPrefix}/loan-allocation-limit`), (req, res, ctx) => res(
        ctx.status(200),
        ctx.json({
            ...req.body as LoanAllocationLimit,
            id: `${limitId++}`
        })
    )),
    rest.put(getMockUrl(`${urlPrefix}/loan-allocation-limit/:limitId`), (req, res, ctx) => res(
        ctx.status(200),
        ctx.json({ ...req.body as LoanAllocationLimit })
    )),
    rest.delete(getMockUrl(`${urlPrefix}/loan-allocation-limit/:limitId`), (req, res, ctx) => res(
        ctx.status(204)
    ))
];

let limitId = 1;

export const mockedLimits: LoanAllocationLimit[] = [
    {
        id: `${limitId++}`,
        limitAmount: 310000,
        customerId: '1',
        expirationDate: createDate(90).toISOString(),
        type: LoanAllocationLimitType.ALL
    },
    {
        id: `${limitId++}`,
        limitAmount: 32000000,
        customerId: '1',
        expirationDate: createDate(30).toISOString(),
        type: LoanAllocationLimitType.UNDERWRITING_TYPE,
        underwritingType: UnderwritingType.DELEGATED
    },
    {
        id: `${limitId++}`,
        limitAmount: 1500000,
        customerId: '1',
        expirationDate: createDate(30).toISOString(),
        type: LoanAllocationLimitType.PRODUCT_TYPE,
        productTypeConfigId: mockedProductTypeConfigs[0].id
    },
    {
        id: `${limitId++}`,
        limitAmount: 200000,
        customerId: '1',
        expirationDate: createDate(30).toISOString(),
        type: LoanAllocationLimitType.PRODUCT,
        productId: mockedProducts[0].id
    },
    {
        id: `${limitId++}`,
        limitAmount: 100000,
        customerId: '1',
        expirationDate: createDate(60).toISOString(),
        type: LoanAllocationLimitType.PRODUCT,
        productId: mockedProducts[1].id
    }
];
