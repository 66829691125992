import { Edit } from '@mui/icons-material';
import {
    IconButton, Tooltip
} from '@mui/material';
import { TextField } from '@tsp-ui/core';
import { LLPAMatrixFormCell, MatrixStepFormValues } from '@views/admin/llpas/components/LLPADialog';
import ActionsCell from '@views/admin/llpas/components/LLPAMatrix/components/ActionsCell';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';


interface AdjustmentCellProps {
    cell: LLPAMatrixFormCell;
    cellIndex: number;
    readOnly?: boolean;
}

export default function AdjustmentCell({ cell, cellIndex, readOnly }: AdjustmentCellProps) {
    const { trigger } = useFormContext<MatrixStepFormValues>();

    const { adjustment } = cell;
    const [ isEditing, setIsEditing ] = useState(false);

    const displayValue = adjustment ? `${parseFloat(adjustment).toFixed(3)}%` : '--';

    return (
        <ActionsCell
            component="td"
            align="center"
            isEditing={isEditing}
            actions={!readOnly && (
                <Tooltip title="Edit cell">
                    <IconButton
                        size="small"
                        onClick={() => setIsEditing(true)}
                    >
                        <Edit
                            color="secondary"
                            fontSize="small"
                        />
                    </IconButton>
                </Tooltip>
            )}
        >
            {!isEditing ? displayValue : (
                <TextField<MatrixStepFormValues>
                    name={`llpaCells.${cellIndex}.adjustment`}
                    size="small"
                    variant="standard"
                    autoFocus
                    required
                    onFocus={(event) => {
                        event.target.select();
                    }}
                    onBlur={async () => {
                        if (await trigger(`llpaCells.${cellIndex}.adjustment`, {
                            shouldFocus: true
                        })) {
                            setIsEditing(false);
                        }
                    }}
                />
            )}
        </ActionsCell>
    );
}
