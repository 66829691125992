import { LoanLimitType } from '@api';

import { apiUtils } from '../api-utils';
import { AmortizationType } from '../enums/amortizationTypeEnum';
import { AutomatedUwSystem } from '../enums/automatedUwSystemEnum';
import { LLPAMatrixLoanProperty, LLPAOnlyLoanProperty, LoanProperty } from '../enums/loanPropertyEnum';
import { LoanPurpose } from '../enums/loanPurposeEnum';
import { LoanType } from '../enums/loanTypeEnum';
import { NumericFieldOperator } from '../enums/numericFieldOperatorEnum';
import { OccupancyType } from '../enums/occupancyTypeEnum';
import { PropertyType } from '../enums/propertyTypeEnum';
import { SpecialtyProgram } from '../enums/specialtyProgramEnum';
import { State } from '../enums/stateEnum';
import { MatrixColumnMetadata } from '../investor/investor-api';


export function getLLPAs(clientId: string): Promise<LLPA[]> {
    return apiUtils.get(`/client/${clientId}/llpa`);
}

export type CreateLLPAReqBody = Omit<LLPA, 'versions' | 'id'> & {
    version: Omit<LLPAVersionSummary & LLPAVersionDetails, 'id'>;
};
export function createLLPA(clientId: string, newLLPA: CreateLLPAReqBody): Promise<LLPA> {
    return apiUtils.post(`/client/${clientId}/llpa`, newLLPA);
}

export type UpdateLLPAReqBody = Omit<LLPA, 'isCap' | 'versions'>;
export function updateLLPADetails(clientId: string, updates: UpdateLLPAReqBody): Promise<LLPA> {
    return apiUtils.put(`/client/${clientId}/llpa/${updates.id}`, updates);
}

type CreateLLPAVersionReqBody = Omit<LLPAVersionSummary, 'id'> & LLPAVersionDetails;
export function createLLPAVersion(
    clientId: string, llpaId: string, newVersion: CreateLLPAVersionReqBody
): Promise<LLPAVersionSummary> {
    return apiUtils.post(`/client/${clientId}/llpa/${llpaId}/version`, newVersion);
}

type UpdateLLPAVersionReqBody = CreateLLPAVersionReqBody & Pick<LLPAVersionSummary, 'id'>;
export function updateLLPAVersion(
    clientId: string, llpaId: string, updatedVersion: UpdateLLPAVersionReqBody
): Promise<LLPAVersionSummary> {
    return apiUtils.put(`/client/${clientId}/llpa/${llpaId}/version/${updatedVersion.id}`, updatedVersion);
}

export function getLLPAVersionDetails(
    clientId: string, llpaId: string, versionId: string
): Promise<LLPAVersionDetails> {
    return apiUtils.get(`/client/${clientId}/llpa/${llpaId}/version/${versionId}`);
}

export enum NumericMatrixEntryType {
    RANGE = 'RANGE',
    COMPARISON = 'COMPARISON'
}

export type NumericMatrixEntry = {
    type: NumericMatrixEntryType.RANGE;
    min: number;
    max: number;
} | {
    type: NumericMatrixEntryType.COMPARISON;
    operator: NumericFieldOperator;
    value: number;
};

export interface LLPA {
    id: string;
    name: string;
    description: string;
    isCap: boolean;
    versions: LLPAVersionSummary[];
}

export interface LLPAVersionSummary {
    id: string;
    countsTowardCap: boolean;
    effectiveDate: string;
    expirationDate?: string;
    isGainOnSale: boolean;
    isSRP: boolean;
    comments?: string;
    versionNumber: string;
}

export interface LLPAVersionDetails {
    llpaCells: LLPACell[];
    columnMetadata: MatrixColumnMetadata<LLPAMatrixLoanProperty>[];
}

export interface LLPACell extends Partial<{
    [LoanProperty.LOAN_TYPE]: LoanType[];
    [LoanProperty.OCCUPANCY]: OccupancyType[];
    [LoanProperty.PURPOSE]: LoanPurpose[];
    [LoanProperty.AMORT_TYPE]: AmortizationType[];
    [LoanProperty.LOAN_LIMIT_TYPE]: LoanLimitType[];
    [LoanProperty.PROPERTY_TYPE]: PropertyType[];
    [LoanProperty.AUS]: AutomatedUwSystem[];
    [LoanProperty.SPECIALTY_PROGRAM]: SpecialtyProgram[];
    [LoanProperty.STATE]: State[];
    [LoanProperty.HIGH_BALANCE]: boolean;
    [LoanProperty.ESCROWS]: boolean;
    [LoanProperty.SUBORDINATE_FINANCING]: boolean;
    [LoanProperty.IRRL]: boolean;
    [LoanProperty.STREAMLINE]: boolean;
    [LoanProperty.FIRST_TIME_HOMEBUYER]: boolean;
    [LoanProperty.NON_QM]: boolean;
    [LLPAOnlyLoanProperty.INVESTOR]: string[]; // investorId
    [LoanProperty.UNITS]: NumericMatrixEntry;
    [LoanProperty.FICO]: NumericMatrixEntry;
    [LoanProperty.LTV]: NumericMatrixEntry;
    [LoanProperty.CLTV]: NumericMatrixEntry;
    [LoanProperty.DTI]: NumericMatrixEntry;
    [LoanProperty.TERM]: NumericMatrixEntry;
    [LoanProperty.RESERVES_MONTHS]: NumericMatrixEntry;
    [LoanProperty.LOAN_AMOUNT]: NumericMatrixEntry;
}> {
    adjustment?: string;
    adjustmentCap?: string;
}
