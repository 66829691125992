export enum NotificationFrequency {
    IMMEDIATELY = 'IMMEDIATELY',
    DAILY = 'DAILY',
    WEEKLY = 'WEEKLY'
}

export const notificationFrequencyDisplay = {
    [NotificationFrequency.IMMEDIATELY]: 'Immediately',
    [NotificationFrequency.DAILY]: 'Daily Digest',
    [NotificationFrequency.WEEKLY]: 'Weekly'
};

export const { IMMEDIATELY, ...reminderFrequencyDisplay } = notificationFrequencyDisplay;
