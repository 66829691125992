import api, { LockAvailability } from '@api';
import { TimeField } from '@tsp-ui/core/components';
import { usePageMessage, useParams } from '@tsp-ui/core/utils';
import { fromLocalToUtcTime, parseUtcTime } from '@utils';
import { useGetCurrentAccount } from '@utils/hooks';
import { format } from 'date-fns';
import { Dispatch, SetStateAction, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import styles from './LockAvailabilityForm.module.scss';
import { Day, LockAvailabilityContext } from './LockAvailabilityPage';


interface LockAvailabilityFormProps {
    setLoading: Dispatch<SetStateAction<boolean>>;
}

export default function LockAvailabilityForm({ setLoading }: LockAvailabilityFormProps) {
    const { day, customerID } = useParams<{ day: Day }>();
    const { id: clientID } = useGetCurrentAccount();

    const { lockAvailability, setLockAvailability } = useContext(LockAvailabilityContext);
    const { overriddenCustomerNames } = useContext(LockAvailabilityContext);

    const location = useLocation();
    const navigate = useNavigate();
    const pageMessage = usePageMessage();

    const formMethods = useForm<LockAvailability>({
        defaultValues: createFormValues(day, lockAvailability)
    });

    const handleSubmit = formMethods.handleSubmit(async formData => {
        setLoading(true);

        try {
            setLockAvailability(
                await api.availability.updateLockAvailability(clientID, createSubmitValues(day, formData), customerID)
            );

            navigate(location.pathname.replace(/lock-availability.*/, 'lock-availability'));

            overriddenCustomerNames?.length !== undefined
                ? pageMessage.info('Lock availability saved. The following customers have overridden lock availabilities that were not affected', overriddenCustomerNames)
                : pageMessage.success('Lock availability saved');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving lock availability', error);
        }

        setLoading(false);
    });

    return (
        <form
            noValidate
            id={LockAvailabilityForm.formID}
            onSubmit={handleSubmit}
            className={styles.root}
        >
            <FormProvider {...formMethods}>
                <TimeField<LockAvailability>
                    name={`${day}Start`}
                    label="Open"
                    required
                    rules={{
                        validate: (value) => validateTime(value as string, formMethods.getValues()[`${day}End`]!, 'start')
                    }}
                />

                <TimeField<LockAvailability>
                    name={`${day}End`}
                    label="Close"
                    required
                    rules={{
                        validate: (value) => validateTime(formMethods.getValues()[`${day}Start`]!, value as string, 'end')
                    }}
                />
            </FormProvider>
        </form>
    );
}

// takes values from utc time to the user's local time zone
function createFormValues(day: Day, lockAvailability?: LockAvailability): LockAvailability | undefined {
    if (!lockAvailability) {
        return undefined;
    }

    const utcStart = parseUtcTime(lockAvailability[`${day}Start`]);
    const utcEnd = parseUtcTime(lockAvailability[`${day}End`]);
    return {
        ...lockAvailability,
        [`${day}Start`]: format(utcStart, 'HH:mm'),
        [`${day}End`]: format(utcEnd, 'HH:mm')
    };
}

// takes time values from the user's local time zone to utc time
function createSubmitValues(day: Day, data: LockAvailability): LockAvailability {
    return {
        ...data,
        [`${day}Start`]: fromLocalToUtcTime(data[`${day}Start`]!),
        [`${day}End`]: fromLocalToUtcTime(data[`${day}End`]!)
    };
}

export const validateTime = (start: string, end: string, field: 'start' | 'end') => {
    if (!start || !end) {
        return start ? 'End time is required' : 'Start time is required';
    }

    const [ startHours, startMinutes ] = start.split(':').map(Number);
    const [ endHours, endMinutes ] = end.split(':').map(Number);

    if (startHours > endHours || (startHours === endHours && startMinutes >= endMinutes)) {
        return field === 'start' ? 'Start time must be before end time' : 'End time must be after start time';
    }
};

LockAvailabilityForm.formID = 'lock-availability-form';
