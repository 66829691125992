import {
    LoanProperty, booleanEnumDisplay, loanLimitTypeDisplay, loanPropertyDisplay
} from '@api';
import { MenuItem, Popover } from '@mui/material';
import {
    Grid, TextFieldProps, renderEnumOptions, useConfirm
} from '@tsp-ui/core/components';
import { AllowPartialArrayItems } from '@tsp-ui/core/utils';
import { useClientTogglableValuesDisplay } from '@utils';
import { numericMatrixEntryValidationRules } from '@utils/numeric-range-utils';
import { ColDef, ColGroupDef, GetRowIdParams } from 'ag-grid-community';
import { ValueFormatterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import {
    ComponentProps, useCallback, useMemo, useState
} from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import {
    CreateLoanProgramFormValues,
    EligibilityColumnMetadataFormValues,
    EligibilityGuidelineFormValues,
    EligibilityMatrixFormValues
} from '../LoanProgramDetailPage/components/LoanProgramForm';

import { EligibilityCellEditor } from './EligibilityCellEditor';


type GridProps = ComponentProps<typeof Grid<EligibilityGuidelineFormValues>>;

export function EligibilityGuidelineGrid() {
    const confirm = useConfirm();
    const { setValue } = useFormContext<CreateLoanProgramFormValues>();
    const { inclusions, columnMetadata } = useWatch<CreateLoanProgramFormValues, 'matrix'>({
        name: 'matrix'
    });

    const { remove, insert } = useFieldArray<
        AllowPartialArrayItems<CreateLoanProgramFormValues, true>,
        'matrix.inclusions'
    >({
        name: 'matrix.inclusions'
    });

    const {
        remove: removeColumnMetadata,
        append: appendColumnMetadata
    } = useFieldArray<
        AllowPartialArrayItems<CreateLoanProgramFormValues, true>,
        'matrix.columnMetadata'
    >({
        name: 'matrix.columnMetadata'
    });

    const handleDrop = useCallback<NonNullable<GridProps['onDrop']>>(async () => {
        await new Promise<void>((resolve) => setTimeout(resolve, 2000)); // TODO
    }, []);

    const onRemoveRow = useCallback<NonNullable<GridProps['onRemoveRow']>>((rowIndex: number) => {
        remove(rowIndex);
    }, [ remove ]);

    const onCreateRow = useCallback<NonNullable<GridProps['onCreateRow']>>((rowIndex, newItem) => {
        insert(rowIndex, newItem || {}, { shouldFocus: false });
    }, [ insert ]);

    const filteredColumnDefs = useGetFilteredColumnDefs(columnMetadata);
    const memoizedColumnDefs = useMemo(() => filteredColumnDefs, [ filteredColumnDefs ]);

    const onMoreClick = useCallback<NonNullable<GridProps['onMoreClick']>>((e) => (
        setAnchorEl(e.currentTarget)
    ), []);

    const onDragStopped = useCallback<NonNullable<GridProps['onDragStopped']>>(async (e) => {
        const state = e.columnApi.getColumnState().map((state) => ({
            ...state,
            loanProperty: memoizedColumnDefs.find(({ field }) => (
                field === state.colId
            ))?.loanProperty
        }));

        const indicesToRemove = state.map(({ hide, loanProperty }) => (!hide ? null : (
            columnMetadata.findIndex((meta) => meta.loanProperty === loanProperty)
        ))).filter(Boolean) as number[];

        if (indicesToRemove.length) {
            if (await confirm('Are you sure you want to remove this column?')) {
                removeColumnMetadata(indicesToRemove);
            } else {
                e.columnApi.setColumnsVisible(state.map(({ hide, colId }) => (!hide ? null : (
                    colId
                ))).filter(Boolean) as string[], true);
            }
        } else {
            const highLevelGuidelines = columnMetadata.filter(({ isHighLevel }) => isHighLevel);
            const updatedGridColMetadata = state.map(({ loanProperty }) => (
                columnMetadata.find((meta) => meta.loanProperty === loanProperty)
            )).filter(Boolean) as EligibilityColumnMetadataFormValues[];

            setValue('matrix.columnMetadata', [
                ...highLevelGuidelines,
                ...updatedGridColMetadata
            ]);
        }
    }, [
        memoizedColumnDefs, columnMetadata, confirm, removeColumnMetadata, setValue
    ]);

    const [ anchorEl, setAnchorEl ] = useState<HTMLButtonElement>();

    return (
        <>
            <Grid
                createNewItem={createNewItem}
                columnDefs={memoizedColumnDefs}
                rowData={inclusions}
                getRowId={getRowID}
                onDrop={handleDrop}
                showActions
                showRowNumbers
                autoSizeColumns
                onRemoveRow={onRemoveRow}
                onCreateRow={onCreateRow}
                onMoreClick={onMoreClick}
                moreButtonTooltip="Add columns"
                onDragStopped={onDragStopped}
            />

            <Popover
                open={!!anchorEl}
                onClose={() => setAnchorEl(undefined)}
                anchorEl={anchorEl}
                anchorOrigin={{
                    horizontal: 'right',
                    vertical: 'bottom'
                }}
                transformOrigin={{
                    horizontal: 'right',
                    vertical: 'top'
                }}
            >
                {Object.entries(loanPropertyDisplay).filter(([ loanProperty ]) => (
                    !columnMetadata.some((meta) => meta.loanProperty === loanProperty)
                )).map(([ loanProperty, label ]) => (
                    <MenuItem
                        key={loanProperty}
                        onClick={() => {
                            appendColumnMetadata({
                                loanProperty: loanProperty as LoanProperty,
                                isHighLevel: false
                            });

                            setAnchorEl(undefined);
                        }}
                    >
                        {label}
                    </MenuItem>
                ))}
            </Popover>
        </>
    );
}

interface ViewEligibilityGuidelineGridProps {
    matrix: EligibilityMatrixFormValues | undefined;
}

export function ViewEligibilityGuidelineGrid({ matrix }: ViewEligibilityGuidelineGridProps) {
    const { inclusions, columnMetadata } = matrix || {};
    const columnDefs = useGetFilteredColumnDefs(columnMetadata || []);

    return (
        <Grid
            columnDefs={columnDefs}
            rowData={inclusions}
            getRowId={getRowID}
            showRowNumbers
            suppressClickEdit
        />
    );
}

let newInvestorGuidelineID = -1;
function createNewItem() {
    return { id: `${newInvestorGuidelineID--}` };
}

function getRowID(params: GetRowIdParams<EligibilityGuidelineFormValues>) {
    return params.data.id;
}

type EligibilityColumnData = {
    field?: keyof EligibilityGuidelineFormValues;
    display?: Record<string, string>;
    loanProperty?: LoanProperty;
    isNumber?: boolean;
} & (ColDef<EligibilityGuidelineFormValues> | ColGroupDef<EligibilityGuidelineFormValues>);

function createColumnDefs(
    eligibilityFields: EligibilityColumnData[]
): EligibilityColumnData[] {
    return eligibilityFields.map(({ display, isNumber, ...props }) => (!props.field ? props : {
        ...props,
        valueFormatter: ({ value }: ValueFormatterParams<EligibilityGuidelineFormValues>) => (
            display?.[value] || value
        ),
        cellEditor: EligibilityCellEditor,
        cellEditorParams: {
            fieldName: props.field,
            textFieldProps: {
                required: true,
                ...(isNumber ? {
                    rules: numericMatrixEntryValidationRules
                } : display ? {
                    select: true,
                    children: renderEnumOptions(display)
                } : null)
            } as TextFieldProps<CreateLoanProgramFormValues, any>
        }
    }));
}

function useGetFilteredColumnDefs(columnMetadata: EligibilityColumnMetadataFormValues[]) {
    const [
        loanTypeDisplay,
        occupancyTypeDisplay,
        loanPurposeDisplay,
        propertyTypeDisplay,
        numUnitsDisplay,
        amortizationTypeDisplay,
        automatedUnderwritingSystemDisplay,
        specialtyProgramDisplay
    ] = useClientTogglableValuesDisplay([
        'loanType', 'occupancyType', 'loanPurpose', 'propertyType', 'numUnits', 'amortizationType', 'automatedUnderwritingSystem', 'specialtyProgram'
    ]);

    const columnDefsBase = createColumnDefs([
        {
            loanProperty: LoanProperty.LOAN_TYPE,
            field: 'loanType',
            display: loanTypeDisplay
        },
        {
            loanProperty: LoanProperty.OCCUPANCY,
            field: 'occupancy',
            display: occupancyTypeDisplay
        },
        {
            loanProperty: LoanProperty.PURPOSE,
            field: 'purpose',
            display: loanPurposeDisplay
        },
        {
            loanProperty: LoanProperty.PROPERTY_TYPE,
            field: 'propertyType',
            display: propertyTypeDisplay
        },
        {
            loanProperty: LoanProperty.UNITS,
            field: 'units',
            display: numUnitsDisplay
        },
        {
            loanProperty: LoanProperty.FICO,
            headerName: 'Credit Score',
            field: 'fico',
            isNumber: true
        },
        {
            loanProperty: LoanProperty.LTV,
            headerName: 'LTV',
            field: 'ltv',
            isNumber: true
        },
        {
            loanProperty: LoanProperty.CLTV,
            headerName: 'CLTV',
            field: 'cltv',
            isNumber: true
        },
        {
            loanProperty: LoanProperty.DTI,
            headerName: 'DTI',
            field: 'dti',
            isNumber: true
        },
        {
            loanProperty: LoanProperty.TERM,
            headerName: 'Term',
            field: 'term',
            isNumber: true
        },
        {
            loanProperty: LoanProperty.AMORT_TYPE,
            field: 'amortType',
            headerName: 'Amortization Type',
            display: amortizationTypeDisplay
        },
        {
            loanProperty: LoanProperty.LOAN_LIMIT_TYPE,
            field: 'loanLimitType',
            headerName: 'Loan Limit Type',
            display: loanLimitTypeDisplay
        },
        {
            loanProperty: LoanProperty.AUS,
            field: 'aus',
            display: automatedUnderwritingSystemDisplay
        },
        {
            loanProperty: LoanProperty.RESERVES_MONTHS,
            field: 'reservesMonths',
            isNumber: true
        },
        {
            loanProperty: LoanProperty.HIGH_BALANCE,
            field: 'highBalance',
            display: booleanEnumDisplay
        },
        {
            loanProperty: LoanProperty.ESCROWS,
            field: 'escrows',
            display: booleanEnumDisplay
        },
        {
            loanProperty: LoanProperty.SUBORDINATE_FINANCING,
            field: 'subordinateFinancing',
            display: booleanEnumDisplay
        },
        {
            loanProperty: LoanProperty.IRRL,
            field: 'irrl',
            display: booleanEnumDisplay
        },
        {
            loanProperty: LoanProperty.STREAMLINE,
            field: 'streamline',
            display: booleanEnumDisplay
        },
        {
            loanProperty: LoanProperty.FIRST_TIME_HOMEBUYER,
            field: 'firstTimeHomebuyer',
            display: booleanEnumDisplay
        },
        {
            loanProperty: LoanProperty.NON_QM,
            field: 'nonQm',
            display: booleanEnumDisplay
        },
        {
            loanProperty: LoanProperty.SPECIALTY_PROGRAM,
            field: 'specialtyProgram',
            display: specialtyProgramDisplay
        }
    ]);

    const colDefs = columnDefsBase.filter(columnDef => {
        const meta = columnMetadata.find(({ loanProperty }) => loanProperty === columnDef.loanProperty);

        if (!meta) {
            return false;
        }

        return !meta.isHighLevel;
    });

    colDefs.sort((a, b) => (
        columnMetadata.findIndex(({ loanProperty }) => loanProperty === a.loanProperty)
        - columnMetadata.findIndex(({ loanProperty }) => loanProperty === b.loanProperty)
    ));

    return colDefs;
}
