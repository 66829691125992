import api, {
    AutomatedUwRecommendation, ManualLoanEntry, WebSocketEventType
} from '@api';
import {
    Button, Link as MuiLink, Step, StepLabel, Stepper, Typography
} from '@mui/material';
import { PaperSaveLoader } from '@tsp-ui/core/components';
import { usePageMessage } from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

import Page from '../components/Page';

import styles from './ManualLoanEntryPage.module.scss';
import AdditionalDetailsStep from './components/AdditionalDetailsStep';
import BorrowersStep from './components/BorrowersStep';
import LoanStep from './components/LoanStep';
import PropertyStep from './components/PropertyStep';


export interface ManualLoanEntryFormValues extends Omit<ManualLoanEntry, 'automatedUwRecommendation'> {
    automatedUwRecommendation?: AutomatedUwRecommendation | '';
}

const defaultValues: Partial<ManualLoanEntry> = {
    borrowers: [ {} ]
};

/**
 * Renders the manual loan entry page
 *
 * @constructor
 */
export default function ManualLoanEntryPage() {
    const navigate = useNavigate();
    const pageMessage = usePageMessage();

    const formMethods = useForm<ManualLoanEntry>({ defaultValues });
    const [ activeStep, setActiveStep ] = useState(0);
    const [ loading, setLoading ] = useState(false);
    const [ customerIdSearch, setCustomerIdSearch ] = useState('');

    const { id: clientId } = useGetCurrentAccount();

    const goToPreviousStep = () => setActiveStep(activeStep - 1);

    const handleSubmit = formMethods.handleSubmit(async formData => {
        if (activeStep === 3) {
            try {
                setLoading(true);
                await api.pricing.priceLoan(clientId, formData, customerIdSearch);
            } catch (error) {
                pageMessage.handleApiError('An error occurred while creating the loan', error);
            }
        } else {
            setActiveStep(activeStep + 1);
        }
    });

    useEffect(() => api.webSocket.subscribe(
        WebSocketEventType.PRICING_COMPLETE,
        () => navigate('./../..')
    ), [ navigate ]);

    return (
        <Page
            header="Price new loan manually"
            variant="centered"
        >
            <Stepper
                activeStep={activeStep}
                className={styles.stepper}
            >
                <Step>
                    <StepLabel>
                        Borrower(s)
                    </StepLabel>
                </Step>

                <Step>
                    <StepLabel>
                        Property
                    </StepLabel>
                </Step>

                <Step>
                    <StepLabel>
                        Loan
                    </StepLabel>
                </Step>

                <Step>
                    <StepLabel>
                        Additional details

                        <Typography
                            variant="caption"
                            className={styles.optionalCaption}
                        >
                            (optional)
                        </Typography>
                    </StepLabel>
                </Step>
            </Stepper>

            <form
                noValidate
                onSubmit={handleSubmit}
            >
                <FormProvider {...formMethods}>
                    {activeStep === 0 ? (
                        <BorrowersStep />
                    ) : activeStep === 1 ? (
                        <PropertyStep onPreviousButtonClick={goToPreviousStep} />
                    ) : activeStep === 2 ? (
                        <LoanStep onPreviousButtonClick={goToPreviousStep} />
                    ) : (
                        <AdditionalDetailsStep
                            setCustomerIdSearch={setCustomerIdSearch}
                            onPreviousButtonClick={goToPreviousStep}
                            submitButton={(
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={loading}
                                >
                                    Submit
                                </Button>
                            )}
                        />
                    )}
                </FormProvider>
            </form>

            <Typography>
                Have a loan file?
                {' '}

                <MuiLink
                    component={Link}
                    to="./../.."
                >
                    Upload loans
                </MuiLink>

                {' '}
                instead
            </Typography>

            <PaperSaveLoader loading={loading} />
        </Page>
    );
}
