import api, { LoanDetail } from '@api';
import {
    Button, DialogContent, Divider, FormControl, FormLabel, MenuItem, Typography
} from '@mui/material';
import {
    AddressFieldset,
    CurrencyField,
    DateField,
    DialogActions,
    PercentField,
    RoutedDialog,
    Switch,
    TextField,
    isAtLeast,
    usePageMessage
} from '@tsp-ui/core/components';
import utilStyles from '@tsp-ui/core/sass/style-utils.module.scss';
import { toSentenceCase, useGetCurrentAccount, useRenderTogglableEnumOptions } from '@utils';
import { LoanDetailContext } from '@views/loans/LoanDetailPage';
import clsx from 'clsx';
import { useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import styles from './EditLoanDialog.module.scss';

// Defining all of the acceptable Encompass values here until we implement mapping to internal enums

enum EncompassAmortizationType {
    Fixed = 'Fixed',
    GraduatedPaymentMortgage = 'GraduatedPaymentMortgage',
    AdjustableRate = 'AdjustableRate',
    OtherAmortizationType = 'OtherAmortizationType'
}

/**
 * Encompass requires the full amortization type to be passed, but only returns the first 6 characters of the value
 * when the field is fetched. This function returns the full encompass value given the first 6 characters.
 */
function getEncompassAmortizationType(partialEncompassAmorType?: string) {
    return {
        Fixed: EncompassAmortizationType.Fixed,
        Gradua: EncompassAmortizationType.GraduatedPaymentMortgage,
        Adjust: EncompassAmortizationType.AdjustableRate,
        OtherA: EncompassAmortizationType.OtherAmortizationType
    }[partialEncompassAmorType || ''] || partialEncompassAmorType;
}

enum EncompassLoanType {
    Conventional = 'Conventional',
    VA = 'VA',
    FHA = 'FHA',
    Other = 'Other',
    FarmersHomeAdministration = 'FarmersHomeAdministration',
    HELOC = 'HELOC'
}

enum EncompassPropertyType {
    Attached = 'Attached',
    Condominium = 'Condominium',
    Cooperative = 'Cooperative',
    Detached = 'Detached',
    HighRiseCondominium = 'HighRiseCondominium',
    ManufacturedHousing = 'ManufacturedHousing',
    PUD = 'PUD',
    DetachedCondo = 'DetachedCondo',
    ManufacturedHomeCondoPUDCoOp = 'ManufacturedHomeCondoPUDCoOp',
    'MH HomeChoice' = 'MH HomeChoice',
    MHSelect = 'MHSelect',
    MHAdvantage = 'MHAdvantage',
}

enum EncompassOccupancyType {
    OwnerOccupied = 'OwnerOccupied',
    NonOwnerOccupied = 'NonOwnerOccupied'
}

enum EncompassLoanPurpose {
    'Home Purchase' = 'Home Purchase',
    'Home Improvement' = 'Home Improvement',
    'Refinancing' = 'Refinancing',
    'Cash-out refinancing' = 'Cash-out refinancing',
    'Other purpose' = 'Other purpose',
    'Not applicable' = 'Not applicable'
}

enum EncompassDocumentationType {
    Full = 'Full',
    Alt = 'Alt'
}

enum EncompassHMDAFederalAgency {
    OCC = 'OCC',
    FRS = 'FRS',
    FDIC = 'FDIC',
    NCUA = 'NCUA',
    HUD = 'HUD',
    CFPB = 'CFPB'
}

enum EncompassAutomatedUWSystem {
    DU = 'DU',
    LP = 'LP',
    TOTAL = 'TOTAL',
    GUS = 'GUS',
    Other = 'Other',
    'Not applicable' = 'Not applicable',
    'Internal Proprietary System' = 'Internal Proprietary System',
    Exempt = 'Exempt',
}

enum EncompassAutomatedUWRecommendation {
    'Approve/Eligible' = 'Approve/Eligible',
    'Approve/Ineligible' = 'Approve/Ineligible',
    'Refer/Eligible' = 'Refer/Eligible',
    'Refer/Ineligible' = 'Refer/Ineligible',
    'Refer with Caution' = 'Refer with Caution',
    'Out of Scope' = 'Out of Scope',
    'Error' = 'Error',
    'Accept' = 'Accept',
    'Caution' = 'Caution',
    'Ineligible' = 'Ineligible',
    'Incomplete' = 'Incomplete',
    'Invalid' = 'Invalid',
    'Refer' = 'Refer',
    'Eligible' = 'Eligible',
    'Unable to Determine' = 'Unable to Determine',
    'Other' = 'Other',
    'Not applicable' = 'Not applicable',
    'Accept/Eligible' = 'Accept/Eligible',
    'Accept/Ineligible' = 'Accept/Ineligible',
    'Accept/Unable to Determine' = 'Accept/Unable to Determine',
    'Refer with Caution/Eligible' = 'Refer with Caution/Eligible',
    'Refer with Caution/Ineligible' = 'Refer with Caution/Ineligible',
    'Refer/Unable to Determine' = 'Refer/Unable to Determine',
    'Refer with Caution/Unable to Determine' = 'Refer with Caution/Unable to Determine',
    'Exempt' = 'Exempt'
}

export function EditLoanDialog() {
    const [ loading, setLoading ] = useState(false);
    const { setLoanDetail, loanDetail } = useContext(LoanDetailContext);
    const { id: clientId, customerId } = useGetCurrentAccount();
    const pageMessage = usePageMessage();
    const navigate = useNavigate();
    const renderTogglableEnumOptions = useRenderTogglableEnumOptions();
    const formMethods = useForm<LoanDetail>({
        defaultValues: {
            ...loanDetail,
            // casting as any until enum mappings are delt with
            amortizationType: getEncompassAmortizationType(loanDetail?.amortizationType) as any
        }
    });

    const handleSubmit = formMethods.handleSubmit(async (data) => {
        setLoading(true);

        try {
            setLoanDetail(await api.loans.updateLoan(clientId, loanDetail!.id, data, customerId));

            pageMessage.success('Loan updated');

            navigate('..');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while updating loan data', error);
        }

        setLoading(false);
    });

    const amortizationType = formMethods.watch('amortizationType');
    const coborrowerFlag = formMethods.watch('coborrowerFlag');

    return (
        <RoutedDialog
            closeTo=".."
            title="Edit loan data"
            maxWidth="md"
        >
            <DialogContent>
                <FormProvider {...formMethods}>
                    <form
                        id="loan-data-form"
                        onSubmit={handleSubmit}
                        className={styles.root}
                        noValidate
                    >
                        <Typography
                            className={utilStyles.fullWidth}
                            color="textSecondary"
                        >
                            Loan information
                        </Typography>

                        <TextField<LoanDetail>
                            name="loanNumber"
                            label="Loan number"
                            required
                        />

                        <CurrencyField<LoanDetail>
                            name="loanAmount"
                            label="Loan amount"
                            required
                        />

                        <DateField<LoanDetail>
                            name="expirationDate"
                            label="Expiration date"
                        />

                        <PercentField<LoanDetail>
                            name="interestRate"
                            label="Interest rate"
                            type="number"
                            required
                        />

                        <TextField<LoanDetail>
                            name="loanType"
                            label="Loan type"
                            required
                            select
                        >
                            {autoRenderOptions(EncompassLoanType)}
                        </TextField>

                        <TextField<LoanDetail>
                            name="loanTerm"
                            label="Loan term"
                            type="number"
                        />

                        <TextField<LoanDetail>
                            name="lockPeriod"
                            label="Lock period"
                            type="number"
                        />

                        <TextField<LoanDetail>
                            name="amortizationType"
                            label="Amortization type"
                            required
                            select
                        >
                            {autoRenderOptions(EncompassAmortizationType)}
                        </TextField>

                        {/* // casting as string until enum mappings are delt with */}
                        {amortizationType as string === EncompassAmortizationType.AdjustableRate && (
                            <FormControl
                                className={utilStyles.fullWidth}
                                component="fieldset"
                            >
                                <FormLabel
                                    component="legend"
                                    required
                                >
                                    Amortization details
                                </FormLabel>

                                <div className={styles.root}>
                                    <CurrencyField<LoanDetail>
                                        name="armMargin"
                                        label="Margin"
                                        size="small"
                                        required
                                        hideRequiredIndicator
                                        rules={isAtLeast(0)}
                                    />

                                    <CurrencyField<LoanDetail>
                                        name="armLifeCap"
                                        label="Life cap"
                                        size="small"
                                        required
                                        hideRequiredIndicator
                                        rules={isAtLeast(0)}
                                    />
                                </div>
                            </FormControl>
                        )}

                        <Switch<LoanDetail>
                            name="interestOnlyFlag"
                            label="Interest only flag"
                        />

                        <Divider className={utilStyles.fullWidth} />

                        <Typography
                            className={utilStyles.fullWidth}
                            color="textSecondary"
                        >
                            Borrowers
                        </Typography>

                        <BorrowerRow />

                        <Switch<LoanDetail>
                            name="coborrowerFlag"
                            label="Coborrower flag"
                            className={utilStyles.fullWidth}
                        />

                        {coborrowerFlag && (
                            <BorrowerRow isCoborrower />
                        )}

                        <Divider className={utilStyles.fullWidth} />

                        <Typography
                            className={utilStyles.fullWidth}
                            color="textSecondary"
                        >
                            Property details
                        </Typography>

                        <TextField<LoanDetail>
                            name="propertyType"
                            label="Property type"
                            select
                        >
                            {autoRenderOptions(EncompassPropertyType)}
                        </TextField>

                        <TextField<LoanDetail>
                            name="units"
                            label="Units"
                            select
                        >
                            {renderTogglableEnumOptions('numUnits')}
                        </TextField>

                        <TextField<LoanDetail>
                            name="occupancy"
                            label="Occupancy"
                            select
                        >
                            {autoRenderOptions(EncompassOccupancyType)}
                        </TextField>

                        <TextField<LoanDetail>
                            name="purpose"
                            label="Purpose"
                            select
                        >
                            {autoRenderOptions(EncompassLoanPurpose)}
                        </TextField>

                        <CurrencyField<LoanDetail>
                            name="appraisedValue"
                            label="Appraised value"
                        />

                        <AddressFieldset<LoanDetail>
                            className={clsx(utilStyles.fullWidth, styles.address)}
                            fieldNames={{
                                street: 'address.street',
                                city: 'address.city',
                                state: 'address.state',
                                zip: 'address.zip'
                            }}
                        />

                        <Divider className={utilStyles.fullWidth} />

                        <Typography
                            className={utilStyles.fullWidth}
                            color="textSecondary"
                        >
                            Additional data
                        </Typography>

                        <TextField<LoanDetail>
                            name="specialtyProgram"
                            label="Specialty program"
                            select
                        >
                            {renderTogglableEnumOptions('specialtyProgram')}
                        </TextField>

                        <TextField<LoanDetail>
                            name="documentationType"
                            label="Documentation type"
                            select
                        >
                            {autoRenderOptions(EncompassDocumentationType)}
                        </TextField>

                        <TextField<LoanDetail>
                            name="limitedLiabilityCorp"
                            label="Limited liability corp" // TODO is it correct to be using the EncompassHMDAFederalAgency?
                            select
                        >
                            {autoRenderOptions(EncompassHMDAFederalAgency)}
                        </TextField>

                        <TextField<LoanDetail>
                            name="mortgageInsCompany"
                            label="Mortgage ins company"
                        />

                        <TextField<LoanDetail>
                            name="automatedUwSystem"
                            label="Automated UW system"
                            select
                        >
                            {autoRenderOptions(EncompassAutomatedUWSystem)}
                        </TextField>

                        <TextField<LoanDetail>
                            name="automatedUwRecommendation"
                            label="Automated UW recommendation"
                            select
                        >
                            {autoRenderOptions(EncompassAutomatedUWRecommendation)}
                        </TextField>

                        <Switch<LoanDetail>
                            name="mortgageInsFlag"
                            label="Mortgage insurance flag"
                            type="checkbox"
                        />
                    </form>
                </FormProvider>
            </DialogContent>

            <DialogActions loading={loading}>
                <Button
                    type="submit"
                    form="loan-data-form"
                    color="primary"
                    variant="contained"
                >
                    Save
                </Button>
            </DialogActions>
        </RoutedDialog>
    );
}

function BorrowerRow({ isCoborrower = false }: {isCoborrower?: boolean}) {
    const borrowerIndex = isCoborrower ? 1 : 0;
    const borrowerText = isCoborrower ? 'Coborrower' : 'Borrower';

    return (
        <span className={clsx(utilStyles.fullWidth, styles.borrowerRow)}>
            <TextField<LoanDetail>
                name={`borrowers.${borrowerIndex}.firstName`}
                label={`${borrowerText} first name`}
                required
            />

            <TextField<LoanDetail>
                name={`borrowers.${borrowerIndex}.middleName`}
                label={`${borrowerText} middle name`}
            />

            <TextField<LoanDetail>
                name={`borrowers.${borrowerIndex}.lastName`}
                label={`${borrowerText} last name`}
                required
            />
        </span>
    );
}

// Temporary solution until we implement mapping from Encompass values to internal enums
function autoRenderOptions(enumObj: Record<string, string>) {
    return Object.entries(enumObj).map(([ value, _ ]) => (
        <MenuItem
            value={value}
            key={value}
        >
            {toSentenceCase(value)}
        </MenuItem>
    )).filter(Boolean);
}
