import {
    ActiveInactiveEnum,
    DueDiligenceStep,
    dueDiligenceStepTypeDisplay,
    dueDiligenceStepTypeDisplayMultipleLowercase,
    dueDiligenceStepTypeDisplaySingularLowercase
} from '@api';
import { Typography } from '@mui/material';
import { Button, Loader } from '@tsp-ui/core';
import { tooltipTitle } from '@utils';
import { DueDiligenceEntityCard, useDueDiligenceEntities } from '@views/admin/customers/CustomerDetailPage/components/dialogs/components/DueDiligenceStepCard';
import { useState } from 'react';

import styles from './SpecialEntitySection.module.scss';


interface SpecialEntitySectionProps {
    type: DueDiligenceStep['type'];
    readonly?: boolean;
    readonlyTooltip?: string;
}

export function SpecialEntitySection({ type, readonly }: SpecialEntitySectionProps) {
    const [ adding, setAdding ] = useState(false);
    const {
        dueDiligenceEntities,
        setDueDiligenceEntities,
        entitiesLoading,
        canManageDueDiligenceEntities,
        canViewDueDiligenceEntities
    } = useDueDiligenceEntities(type);

    const activeEntities = dueDiligenceEntities?.filter(
        dueDiligenceEntity => dueDiligenceEntity.status === ActiveInactiveEnum.ACTIVE
    );

    return (
        <>
            <Typography
                variant="h6"
                className={styles.sectionHeader}
            >
                {dueDiligenceStepTypeDisplay[type]}

                <Button
                    onClick={() => setAdding(true)}
                    disabled={adding || !canManageDueDiligenceEntities || readonly}
                    tooltip={tooltipTitle({
                        'You must unlock this data request to make changes': readonly,
                        [`You are already adding a ${dueDiligenceStepTypeDisplaySingularLowercase[type]}`]: adding,
                        [`You do not have permission to add ${dueDiligenceStepTypeDisplayMultipleLowercase[type]}`]: !canManageDueDiligenceEntities
                    })}
                >
                    Add {dueDiligenceStepTypeDisplaySingularLowercase[type]}
                </Button>
            </Typography>

            {!canViewDueDiligenceEntities ? (
                <Typography>
                    You do not have permission to view {dueDiligenceStepTypeDisplayMultipleLowercase[type]}
                </Typography>
            ) : entitiesLoading ? <Loader loading /> : (
                <div className={styles.dueDiligenceEntityCardSection}>
                    {!activeEntities?.length ? (
                        <Typography>
                            No {dueDiligenceStepTypeDisplayMultipleLowercase[type]} have been added yet.
                        </Typography>
                    ) : (
                        <>
                            {activeEntities.map(dueDiligenceEntity => (
                                <DueDiligenceEntityCard
                                    type={type}
                                    key={dueDiligenceEntity.id}
                                    dueDiligenceEntity={dueDiligenceEntity}
                                    setDueDiligenceEntities={setDueDiligenceEntities}
                                    setAdding={setAdding}
                                    locked={readonly}
                                />
                            ))}

                            {adding && (
                                <DueDiligenceEntityCard
                                    type={type}
                                    dueDiligenceEntity={undefined}
                                    setDueDiligenceEntities={setDueDiligenceEntities}
                                    setAdding={setAdding}
                                />
                            )}
                        </>
                    )}
                </div>
            )}
        </>
    );
}
