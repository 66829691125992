import api, {
    BulkCommitmentDetails, LOSLoanStatus, LoanPricingResult, LoanStatusConfig,
    LockAvailability, LockAvailabilityOverride, LockSuspension, PermissionType,
    PricingUploadBatch, RateSheet, RegisteredLoan, TimeString
} from '@api';
import { Download } from '@mui/icons-material';
import {
    Alert, AlertTitle, Button, MenuItem, Link as MuiLink, Pagination, Tab, Tabs, TextField, Typography
} from '@mui/material';
import { PaginatedResponse, usePaginatedQueryParams } from '@tsp-ui/core';
import {
    ExpandableHeader, FileInput, IconButton, RoutedDialogManager
} from '@tsp-ui/core/components';
import { capitalize, useAsyncEffect, usePageMessage } from '@tsp-ui/core/utils';
import { handleDownload, parseUtcTime, withAuth } from '@utils';
import { useGetCurrentAccount, useHandlePromiseSettledResult, useHasPermission } from '@utils/hooks';
import { ClientContext } from '@views/AuthenticatedRouteSwitch';
import { LockAvailabilityContext, useLockAvailabilityContextValue } from '@views/admin/lockAvailability/LockAvailabilityPage';
import ViewportFill from '@views/components/ViewportFill';
import { LoansContext } from '@views/loans/LoansPage';
import LoanDataDialog from '@views/loans/components/LoanDataDialog';
import LoanDocumentsDialog from '@views/loans/components/LoanDocumentsDialog';
import RegisteredLoanCard from '@views/loans/components/RegisteredLoanCard';
import LoanUploadResultsDialog from '@views/product-pricing/components/LoanUploadResultsDialog';
import { CommitmentTrackingCard } from '@views/tracking/bulk-commitment/BulkCommitmentTrackingPage';
import {
    addDays, format, isAfter, isBefore, isSameDay, isWithinInterval, parseISO
} from 'date-fns';
import {
    Children,
    ReactNode, useCallback, useContext, useEffect, useRef, useState
} from 'react';
import { Link } from 'react-router-dom';
import { TransitionGroup } from 'react-transition-group';

import Page from '../components/Page';

import styles from './ProductAndPricing.module.scss';
import LoanPricingResultCard from './components/LoanPricingResultCard';
import PendingUploadsCard from './components/PendingUploadsCard';
import RateSheetDialog from './components/RateSheetDialog';


export const productAndPricingAcceptedFileTypes = [
    'xls', 'xlsx', 'xml'
];

const loansPerPage = 20;

export default function ProductAndPricingPage() {
    const pageMessage = usePageMessage();
    const handlePromiseSettledResult = useHandlePromiseSettledResult();
    const { id: clientId, customerId: accountCustomerId } = useGetCurrentAccount();
    const [ canCreateLoans ] = useHasPermission([ PermissionType.CREATE_LOANS ]);
    const { customers } = useContext(ClientContext);

    const [ tab, setTab ] = useState(0);
    const [ commitmentId, setCommitmentId ] = useState('10003');
    const [ customerId, setCustomerId ] = useState(accountCustomerId || customers?.[0].id);

    const [ parentBatches, setParentBatches ] = useState<PricingUploadBatch[]>();
    const [ pendingBatches, setPendingBatches ] = useState<PricingUploadBatch[]>();
    const [ loanPricingResults, setLoanPricingResults ] = useState<PaginatedResponse<LoanPricingResult>>({
        data: [],
        totalPages: 0,
        totalRecords: 0,
        pageNumber: 1,
        pageSize: loansPerPage
    });
    const [ floatedLoans, setFloatedLoans ] = useState<PaginatedResponse<RegisteredLoan>>({
        data: [],
        totalPages: 0,
        totalRecords: 0,
        pageNumber: 1,
        pageSize: loansPerPage
    });
    const [ commitments, setCommitments ] = useState<BulkCommitmentDetails[]>();
    const [ loanStatusConfigs, setLoanStatusConfigs ] = useState<LoanStatusConfig[]>([]);
    const [ losLoanStatuses, setLosLoanStatuses ] = useState<LOSLoanStatus[]>([]);
    const [ currentRateSheet, setCurrentRateSheet ] = useState<RateSheet>();

    const [ loading, setLoading ] = useState(true);

    const [ floatedLoansParams, setFloatedLoansPage ] = usePaginatedQueryParams({ pageSize: loansPerPage });
    const [ pricingResultsParams, setPricingResultsPage ] = usePaginatedQueryParams({ pageSize: loansPerPage });

    async function fetchFloatedLoans() {
        try {
            setFloatedLoans(await api.loans.getFloatedLoans(clientId, customerId, floatedLoansParams));
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching floated loans', error);
        }
    }

    useAsyncEffect(useCallback(async () => {
        const [
            pricingResultsResult, commitmentsResult, floatedLoansResult, loanStatusConfigsResult,
            losLoanStatusesResult, currentRateSheetResult
        ] = await Promise.allSettled(
            [
                api.pricing.getPricingResults(clientId, accountCustomerId, pricingResultsParams),
                (await api.bulkCommitment.getCommitments(clientId)) as BulkCommitmentDetails[],
                api.loans.getFloatedLoans(clientId),
                api.loanStatus.getLoanStatusConfigs(clientId),
                api.loanStatus.getLOSLoanStatuses(clientId),
                api.pricing.getCurrentRateSheet(clientId, customerId)
            ]
        );

        handlePromiseSettledResult(pricingResultsResult, setLoanPricingResults, 'An error occurred while fetching loan pricing results');
        handlePromiseSettledResult(commitmentsResult, setCommitments, 'An error occurred while fetching commitments');
        handlePromiseSettledResult(floatedLoansResult, setFloatedLoans, 'An error occurred while fetching floated loans');
        handlePromiseSettledResult(loanStatusConfigsResult, setLoanStatusConfigs, 'An error occurred while fetching loan status configs');
        handlePromiseSettledResult(losLoanStatusesResult, setLosLoanStatuses, 'An error occurred while fetching LOS loan statuses');
        handlePromiseSettledResult(currentRateSheetResult, setCurrentRateSheet, 'An error occurred while fetching the current rate sheet');

        setLoading(false);
    }, [
        clientId, accountCustomerId, pricingResultsParams, customerId, handlePromiseSettledResult
    ]));

    async function handleLoanUpload(files: FileList | File[]) {
        if (!customerId) {
            pageMessage.error('Select a customer to upload a loan');
            return;
        }

        try {
            if (!currentRateSheet) {
                pageMessage.error('No current rate sheet found', [ 'Upload a new rate sheet and try again' ]);
                return;
            }

            setPendingBatches([
                await api.loans.uploadLoans(clientId, customerId, files, {
                    lockPeriod: 0,
                    rateSheetId: currentRateSheet.id, // TODO allow user to select rate sheet
                    submittedBy: 'No one' // TODO refactor back end to not require this - we should pull from user's token
                })
            ]);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while uploading loan files', error);
        }
    }

    const [ loadingCommitmentIds, setLoadingCommitmentIds ] = useState<string[]>([]);
    async function handleBulkUpload(files: FileList | File[], commitmentId: string) {
        const formData = new FormData();
        [ ...files ].forEach(file => formData.append('files', file));

        try {
            setLoadingCommitmentIds(loadingCommitmentIds.concat(commitmentId));
            setPendingBatches([ await api.bulkCommitment.uploadToCommitment(clientId, commitmentId, formData) ]);
        } catch (error) {
            setLoadingCommitmentIds(loadingCommitmentIds.filter((id) => id !== commitmentId));
            pageMessage.handleApiError('An error occurred while uploading loan files', error);
        }
    }

    const filteredCommitments = commitments?.filter(({ deliveryExpiration, customerId }) => (
        (!accountCustomerId || accountCustomerId === customerId) && isAfter(parseISO(deliveryExpiration), new Date())
    ));

    async function handleTemplateDownload() {
        try {
            const templateFile = await api.loans.downloadTemplate(clientId, accountCustomerId);
            const { fileName } = templateFile;
            handleDownload(templateFile, fileName);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while downloading the file', error);
        }
    }

    // TODO PREDEV-2455
    // useEffect(() => api.webSocket.subscribe(
    //     WebSocketEventType.UPLOAD_COMPLETE,
    //     async ({ commitmentId }) => {
    //         setLoadingCommitmentIds((loadingCommitmentIds) => (
    //             loadingCommitmentIds.filter((id) => id !== commitmentId)
    //         ));
    //
    //         const commitments = (await api.bulkCommitment.getCommitments(clientId)) as BulkCommitmentDetails[];
    //         setTimeout(() => setCommitments(commitments), 1000);
    //     }
    // ), [ commitments, clientId ]);

    const [ lockAvailabilityContextValue ] = useLockAvailabilityContextValue(false);
    const { lockSuspension } = lockAvailabilityContextValue;

    const {
        isLockDeskOpen, lockAlertTitle, lockAlertMessage, lockDetailsLoading
    } = useGetLockDetails(lockSuspension);


    return  (
        <LockAvailabilityContext.Provider value={lockAvailabilityContextValue}>
            <LoansContext.Provider value={{
                loanStatusConfigs,
                losLoanStatuses,
                isLockDeskOpen
            }}
            >
                <Page
                    header={(
                        <div className={styles.header}>
                            <span>Product & Pricing</span>

                            <Tabs value={tab}>
                                <Tab
                                    label="Loans"
                                    onClick={() => setTab(0)}
                                />

                                <Tab
                                    label="Commitments"
                                    onClick={() => setTab(1)}
                                />
                            </Tabs>
                        </div>
                    )}
                    loading={loading || lockDetailsLoading}
                    headerActions={(
                        <Button
                            component={Link}
                            to="rate-sheet"
                        >
                            View rate sheet
                        </Button>
                    )}
                >
                    {!isLockDeskOpen && (
                        <Alert
                            severity="warning"
                            className={styles.lockDeskAlert}
                            action={!accountCustomerId ? (
                                <Button
                                    color="inherit"
                                    component={Link}
                                    to="../admin/lock-availability"
                                >
                                    Manage lock availability
                                </Button>
                            ) : null}
                        >
                            <AlertTitle>
                                {lockAlertTitle}
                            </AlertTitle>

                            {lockAlertMessage}
                        </Alert>
                    )}

                    <ViewportFill
                        className={styles.root}
                    >
                        <div className={styles.sections}>
                            {tab === 0 ? (
                                <>
                                    <ProductAndPricingSection
                                        type="floated"
                                        itemsLength={floatedLoans?.totalRecords || 0}
                                    >
                                        {floatedLoans?.data
                                            .map((floatedLoan) => (
                                                <RegisteredLoanCard
                                                    key={floatedLoan.id}
                                                    loan={floatedLoan}
                                                    loanPricingResults={loanPricingResults}
                                                    updateLoanPricingResults={setLoanPricingResults}
                                                    updateFloatedLoans={fetchFloatedLoans}
                                                    showPricingResults
                                                />
                                            )) || (
                                            <Typography>
                                                There are no floated loans to display at this time.
                                            </Typography>
                                        )}

                                        {floatedLoans?.totalRecords! > loansPerPage && (
                                            <Pagination
                                                count={floatedLoans?.totalPages}
                                                page={floatedLoans?.pageNumber}
                                                onChange={(_, page) => setFloatedLoansPage(page)}
                                                className={styles.pagination}
                                            />
                                        )}
                                    </ProductAndPricingSection>

                                    <ProductAndPricingSection
                                        type="pending"
                                        itemsLength={loanPricingResults?.totalRecords || 0}
                                    >
                                        {loanPricingResults?.data.map((loanPricingResult, index) => (
                                            <LoanPricingResultCard
                                                key={loanPricingResult.loanId}
                                                loanPricingResult={loanPricingResult}
                                                updateLoanPricingResults={setLoanPricingResults}
                                                updateFloatedLoans={fetchFloatedLoans}
                                                index={index}
                                            />
                                        )) || (
                                            <Typography>
                                                There are no loan pricing results to display at this time.
                                            </Typography>
                                        )}

                                        {(loanPricingResults?.totalRecords || 0) > loansPerPage && (
                                            <Pagination
                                                count={loanPricingResults?.totalPages}
                                                page={loanPricingResults?.pageNumber}
                                                onChange={(event, page) => setPricingResultsPage(page)}
                                                className={styles.pagination}
                                            />
                                        )}
                                    </ProductAndPricingSection>
                                </>
                            ) : filteredCommitments && (
                                <>
                                    <Typography
                                        fontWeight={500}
                                        className={styles.sectionHeader}
                                    >
                                        Active commitments

                                        <Typography
                                            color="textSecondary"
                                            fontWeight={400}
                                        >
                                            {filteredCommitments.length} commitment

                                            {filteredCommitments.length === 1 ? '' : 's'}
                                        </Typography>
                                    </Typography>

                                    <div className={styles.commitmentContainer}>
                                        {filteredCommitments.map((commitment) => (
                                            <CommitmentTrackingCard
                                                key={commitment.id}
                                                toPathPrefix="../tracking/bulk-commitments"
                                                commitment={commitment}
                                                onAddFiles={handleBulkUpload}
                                                loading={loadingCommitmentIds.includes(commitment.id)}
                                            />
                                        ))}
                                    </div>
                                </>
                            )}
                        </div>

                        {canCreateLoans && (
                            <div className={styles.uploadLoansContainer}>
                                <div className={styles.sectionHeader}>
                                    <Typography
                                        fontWeight={500}
                                    >
                                        Upload new loans
                                    </Typography>

                                    <IconButton
                                        tooltip="Download template"
                                        size="small"
                                        onClick={handleTemplateDownload}
                                    >
                                        <Download
                                            fontSize="small"
                                            color="secondary"
                                        />
                                    </IconButton>
                                </div>

                                <div className={styles.fileInputContainer}>
                                    {!accountCustomerId && (
                                        <TextField
                                            select
                                            label="Customer"
                                            value={customerId}
                                            sx={{ mb: tab === 1 ? 1 : 2 }}
                                            onChange={(event) => setCustomerId(event.target.value)}
                                        >
                                            {customers?.map(({ id, name }) => (
                                                <MenuItem
                                                    key={id}
                                                    value={id}
                                                >
                                                    {name}
                                                </MenuItem>
                                            )) || (
                                                <MenuItem value={accountCustomerId}>
                                                    Loading...
                                                </MenuItem>
                                            )}
                                        </TextField>
                                    )}

                                    {tab === 1 && (
                                        <TextField
                                            select
                                            label="Deliver to"
                                            value={commitmentId}
                                            sx={{ mb: 2 }}
                                            onChange={(event) => setCommitmentId(event.target.value)}
                                        >
                                            <MenuItem value="10003">
                                                Commitment #10003
                                            </MenuItem>

                                            <MenuItem value="10004">
                                                Commitment #10004
                                            </MenuItem>

                                            <MenuItem value="10005">
                                                Commitment #10005
                                            </MenuItem>

                                            <MenuItem value="10006">
                                                Commitment #10006
                                            </MenuItem>
                                        </TextField>
                                    )}

                                    <FileInput
                                        compact
                                        title="file"
                                        acceptedFileTypes={productAndPricingAcceptedFileTypes}
                                        onAddFiles={tab === 1 ? (files) => (
                                            handleBulkUpload(files, commitmentId)
                                        ) : handleLoanUpload}
                                    />

                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        align="center"
                                    >
                                        Don't have a file?
                                        {' '}

                                        <MuiLink
                                            component={Link}
                                            to="new/manual"
                                        >
                                            Add a loan manually
                                        </MuiLink>

                                        {' '}
                                        instead
                                    </Typography>
                                </div>

                                <div className={styles.sectionHeader}>
                                    <Typography
                                        fontWeight={500}
                                    >
                                        Upload batches
                                    </Typography>

                                    <Typography>
                                        {parentBatches?.length || 'No'}{' '}

                                        {parentBatches?.length === 1 ? 'batch' : 'batches'}
                                    </Typography>
                                </div>

                                <PendingUploadsCard
                                    pendingBatches={pendingBatches}
                                    setLoanPricingResults={setLoanPricingResults}
                                    setParentBatches={setParentBatches}
                                />
                            </div>
                        )}
                    </ViewportFill>

                    <RoutedDialogManager routes={routes} />
                </Page>
            </LoansContext.Provider>
        </LockAvailabilityContext.Provider>
    );
}

const routes = {
    'uploads/:batchId': withAuth(LoanUploadResultsDialog, [ PermissionType.CREATE_LOANS ], true),
    ':loanID/loan-data': withAuth(LoanDataDialog, [ PermissionType.VIEW_LOANS ], true),
    ':loanID/loan-documents': withAuth(LoanDocumentsDialog, [ PermissionType.VIEW_LOAN_DOCS ], true),
    'rate-sheet': withAuth(RateSheetDialog, [ PermissionType.VIEW_RATE_SHEETS ], true)
};

interface ProductAndPricingSectionProps {
    type: 'floated' | 'pending';
    children: ReactNode;
    itemsLength: number;
}

function ProductAndPricingSection({
    type, children, itemsLength
}: ProductAndPricingSectionProps) {
    const [ isExpanded, setIsExpanded ] = useState(false);
    const [ sectionHeight, setSectionHeight ] = useState('auto');
    const loanCardsRef = useRef<(HTMLDivElement | null)[]>([]);

    useEffect(() => {
        if (isExpanded && loanCardsRef.current.length > 0) {
            const cardHeights = loanCardsRef.current.slice(0, 3).map(card => (card!.offsetHeight + 16));
            let totalHeight = 0;

            cardHeights.forEach((cardHeight) => {
                totalHeight += cardHeight;
            });

            setSectionHeight(`${totalHeight + 42}px`); // Apply total height + padding/margin
        } else {
            setSectionHeight('42px');
        }
    }, [ isExpanded ]);

    return (
        <div
            className={styles.section}
            style={{ minHeight: sectionHeight }}
        >
            <ExpandableHeader
                defaultExpand={type === 'pending'}
                disableExpand={!itemsLength}
                onExpandToggle={setIsExpanded}
                title={`${capitalize(type)} loans`}
                secondaryText={`${itemsLength} loan${itemsLength === 1 ? '' : 's'}`}
                expandedContent={(
                    <div className={styles.expandedContent}>
                        <TransitionGroup className={styles.pricingResults}>
                            {Children?.map(children, (child, index) => (
                                <div ref={el => loanCardsRef.current[index] = el}>
                                    {child}
                                </div>
                            ))}
                        </TransitionGroup>
                    </div>
                )}
            />
        </div>
    );
}

export function useGetLockDetails(lockSuspension?: LockSuspension | undefined): {
    isLockDeskOpen: boolean;
    lockAlertTitle: string;
    lockAlertMessage?: string | null;
    lockDetailsLoading: boolean;
    } {
    const [ lockAvailability, setLockAvailability ] = useState<LockAvailability>();
    const [ lockAvailabilityOverrides, setLockAvailabilityOverrides ] = useState<LockAvailabilityOverride[]>([]);
    const [ loading, setLoading ] = useState(true);

    const handlePromiseSettledResult = useHandlePromiseSettledResult();
    const { id: clientId, customerId: accountCustomerId } = useGetCurrentAccount();

    useAsyncEffect(useCallback(async () => {
        const [ lockAvailabilityResult, lockAvailabilityOverridesResult ] = await Promise.allSettled(
            [
                (await api.availability.getLockAvailabilities(clientId, accountCustomerId))[0],
                api.availability.getLockAvailabilityOverrides(clientId, accountCustomerId)
            ]
        );

        handlePromiseSettledResult(lockAvailabilityResult, setLockAvailability, 'An error occurred while fetching lock availabilities');
        handlePromiseSettledResult(lockAvailabilityOverridesResult, setLockAvailabilityOverrides, 'An error occurred while fetching lock availability overrides');

        setLoading(false);
    }, [
        clientId, accountCustomerId, handlePromiseSettledResult
    ]));

    if (lockSuspension?.isSuspended || lockSuspension?.isSuspendedUntilNewRateSheet) {
        return {
            isLockDeskOpen: false,
            lockAlertTitle: lockSuspension.isSuspended ? 'All locks suspended' : 'Locks suspended until new ratesheet',
            lockAlertMessage: lockSuspension.suspendedComments,
            lockDetailsLoading: loading
        };
    }

    if (!lockAvailability) {
        return {
            isLockDeskOpen: false,
            lockAlertTitle: 'Lock availability not found',
            lockDetailsLoading: loading
        };
    }

    const now = new Date();
    const dayOfWeek = now.toLocaleString('en-US', { weekday: 'long' }).toLowerCase();
    const isAvailableKey = `is${capitalize(dayOfWeek)}Available` as keyof LockAvailability;
    const startKey = `${dayOfWeek}Start` as keyof LockAvailability;
    const endKey = `${dayOfWeek}End` as keyof LockAvailability;

    let isAvailable = lockAvailability[isAvailableKey];
    let start = parseUtcTime(lockAvailability[startKey] as TimeString);
    let end = parseUtcTime(lockAvailability[endKey] as TimeString);

    const override = lockAvailabilityOverrides.find(
        override => isSameDay(now, parseISO(override.lockAvailabilityDate))
    );

    if (override) {
        isAvailable = override.isAvailable; // eslint-disable-line prefer-destructuring
        start = parseUtcTime(override.lockStart);
        end = parseUtcTime(override.lockEnd);
    }

    let nextTimeLockDeskIsOpen: Date | null = null;
    for (let i = 1; i <= 7; i++) {
        const nextDay = addDays(now, i);
        const nextDayOfWeek = nextDay.toLocaleString('en-US', { weekday: 'long' }).toLowerCase();
        const nextIsAvailableKey = `is${capitalize(nextDayOfWeek)}Available` as keyof LockAvailability;
        const nextStartKey = `${nextDayOfWeek}Start` as keyof LockAvailability;

        let isNextDayAvailable = lockAvailability[nextIsAvailableKey];
        let nextStartTime = parseUtcTime(lockAvailability[nextStartKey] as TimeString);
        const nextDayOverride = lockAvailabilityOverrides.find(
            override => isSameDay(nextDay, parseISO(override.lockAvailabilityDate))
        );

        if (nextDayOverride) {
            isNextDayAvailable = nextDayOverride.isAvailable;
            nextStartTime = parseUtcTime(nextDayOverride.lockStart);
        }

        if (isNextDayAvailable) {
            nextTimeLockDeskIsOpen = new Date(
                nextDay.getFullYear(),
                nextDay.getMonth(),
                nextDay.getDate(),
                nextStartTime.getHours(),
                nextStartTime.getMinutes()
            );

            break;
        }
    }

    if (!nextTimeLockDeskIsOpen) {
        // if no next time lock desk is open, then lock desk is closed for the week and we need to check
        // if there is an override to open locks
        const nextAvailableOverride = lockAvailabilityOverrides.find(
            override => isAfter(parseISO(override.lockAvailabilityDate), now) && override.isAvailable
        );

        if (nextAvailableOverride) {
            const nextAvailableDate = parseISO(nextAvailableOverride.lockAvailabilityDate);
            const nextStartTime = parseUtcTime(nextAvailableOverride.lockStart);

            nextTimeLockDeskIsOpen = new Date(
                nextAvailableDate.getFullYear(),
                nextAvailableDate.getMonth(),
                nextAvailableDate.getDate(),
                nextStartTime.getHours(),
                nextStartTime.getMinutes()
            );
        }
    }

    const reopenMessage = nextTimeLockDeskIsOpen ? `It will reopen on ${format(nextTimeLockDeskIsOpen, 'EEEE, MMMM do')} at ${format(nextTimeLockDeskIsOpen, 'h:mm a')}.` : undefined;
    const openMessage = nextTimeLockDeskIsOpen ? `It will open at ${format(nextTimeLockDeskIsOpen, 'h:mm a')}.` : undefined;

    if (!isAvailable || !start || !end) {
        return {
            isLockDeskOpen: false,
            lockAlertTitle: 'Lock desk is closed today',
            lockAlertMessage: reopenMessage,
            lockDetailsLoading: loading
        };
    }

    const isBeforeOpen = isAvailable && isBefore(now, start);

    return {
        isLockDeskOpen: isWithinInterval(now, {
            start,
            end
        }),
        lockAlertTitle: isBeforeOpen ? 'Lock desk is not open yet' : 'Lock desk is closed for the day',
        lockAlertMessage: isBeforeOpen ? openMessage : reopenMessage,
        lockDetailsLoading: loading
    };
};
