import {
    Address,
    ClientInvestor,
    ClientLoanProgram,
    CreateLoanProgramReqBody,
    CreateLoanProgramResBody,
    EligibilityMatrix,
    InvestorStatus,
    InvestorStatusUpdateBody,
    ManagedEligibilityStatus,
    State
} from '@api';
import { getRandomItemFromArray } from '@tsp-ui/core';
import { rest } from 'msw';

import { getMockUrl } from '../../../mocks/getMockUrl';
import {
    getNextMockedLoanProgramID,
    matrixMocks,
    mockedLoanProgramBase
} from '../../investor/investor-mocks';


const { ACTIVE, PENDING, INACTIVE } = InvestorStatus;
const { MANAGED, UNMANAGED } = ManagedEligibilityStatus;

export const mocks = [
    /* Investor */
    rest.get(getMockUrl('/client/:clientId/investor'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(req.url.searchParams.get('managedEligibilityStatus') === ManagedEligibilityStatus.MANAGED
            ? mockedAgencyInvestors : mockedClientInvestors)
    ))),
    rest.put(getMockUrl('/client/:clientId/investor/:investorID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(req.body as ClientInvestor)
    ))),
    rest.post(getMockUrl('/client/:clientId/investor'), (req, res, ctx) => {
        const newInvestor: ClientInvestor = {
            ...req.body as ClientInvestor,
            id: `${clientInvestorID++}`
        };

        mockedClientInvestors.push(newInvestor);

        return res(
            ctx.status(200),
            ctx.json(newInvestor)
        );
    }),
    rest.patch(getMockUrl('/client/:clientId/investor/:investorID/status'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json({
            ...(mockedClientInvestors).find(investor => investor.id === req.params.investorID),
            ...req.body as InvestorStatusUpdateBody
        })
    ))),
    rest.post(getMockUrl('/client/:clientId/investor/managed'), (req, res, ctx) => {
        const newInvestor: ClientInvestor = {
            ...req.body as ClientInvestor,
            id: `${clientInvestorID++}`
        };

        mockedClientInvestors.push(newInvestor);

        return res(
            ctx.status(200),
            ctx.json(newInvestor)
        );
    }),

    /* Loan program */
    rest.get(getMockUrl('/client/:clientId/investor/:investorID/loan-program'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(mockedLoanProgramBase.map(loanProgram => ({
            ...loanProgram,
            investorId: req.params.investorID as string
        })))
    ))),
    rest.post(getMockUrl('/client/:clientId/investor/:investorID/loan-program'), (req, res, ctx) => {
        const { loanProgram, matrix } = req.body as CreateLoanProgramReqBody;

        return res(
            ctx.status(200),
            ctx.json({
                loanProgram: {
                    ...loanProgram,
                    id: getNextMockedLoanProgramID()
                },
                matrix
            } as CreateLoanProgramResBody)
        );
    }),
    rest.put(getMockUrl('/client/:clientId/investor/:investorID/loan-program/:loanProgramID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(req.body as ClientLoanProgram)
    ))),
    rest.patch(getMockUrl('/client/:clientId/investor/:investorID/loan-program/:loanProgramID/status'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json({
            ...mockedLoanProgramBase.find(loanProgram => loanProgram.id === req.params.loanProgramID),
            ...req.body as ClientLoanProgram,
            investorId: req.params.investorID
        })
    ))),

    /* Eligibility */
    rest.get(getMockUrl('/client/:clientId/investor/:investorID/loan-program/:loanProgramID/matrix'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(matrixMocks)
    ))),
    rest.put(getMockUrl('/client/:clientId/investor/:investorID/loan-program/:loanProgramID/matrix'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(req.body as EligibilityMatrix)
    )))
];

const addresses: Address[] = [
    {
        street: '1234 Mystery Lane',
        city: 'Somewhere',
        state: State.SC,
        zip: '54321'
    }
];

export let clientInvestorID = 30;

export const mockedAgencyInvestors: ClientInvestor[] = [
    {
        id: `${clientInvestorID++}`,
        code: 'FNMA',
        name: 'Fannie Mae',
        legalName: 'Federal National Mortgage Association',
        isAgency: true,
        managedEligibilityStatus: MANAGED,
        activeClientIDs: [],
        status: ACTIVE,
        addresses,
        sellerServicerNumber: '12345',
        eligibilityVersionId: '1'
    },
    {
        id: `${clientInvestorID++}`,
        code: 'FHLMC',
        name: 'Freddie Mac',
        legalName: 'Federal Home Loan Mortgage Corporation',
        isAgency: true,
        managedEligibilityStatus: MANAGED,
        activeClientIDs: [],
        status: INACTIVE,
        addresses,
        sellerServicerNumber: '98765',
        eligibilityVersionId: '1'
    },
    {
        id: `${clientInvestorID++}`,
        code: 'GNMA',
        name: 'Ginnie Mae',
        legalName: 'Government National Mortgage Association',
        isAgency: true,
        managedEligibilityStatus: MANAGED,
        activeClientIDs: [ '1' ],
        status: INACTIVE,
        addresses,
        sellerServicerNumber: '01010101',
        eligibilityVersionId: '1'
    }
];

export const mockedClientPrivateInvestors: ClientInvestor[] = [
    {
        id: `${clientInvestorID++}`,
        code: 'QQQ',
        name: 'Private Fund',
        legalName: 'Private Fund Inc',
        isAgency: false,
        managedEligibilityStatus: UNMANAGED,
        activeClientIDs: [],
        status: ACTIVE,
        addresses,
        eligibilityBasedOnInvestorId: getRandomItemFromArray(mockedAgencyInvestors).id,
        eligibilityVersionId: '1'
    },
    {
        id: `${clientInvestorID++}`,
        code: 'XFH',
        name: 'Private Bank',
        legalName: 'Private Bank Corp',
        isAgency: false,
        managedEligibilityStatus: UNMANAGED,
        activeClientIDs: [],
        status: ACTIVE,
        addresses,
        eligibilityVersionId: '1'
    },
    {
        id: `${clientInvestorID++}`,
        code: 'FFR',
        name: 'Private Investor',
        legalName: 'Private Investment Co',
        isAgency: false,
        managedEligibilityStatus: UNMANAGED,
        activeClientIDs: [],
        status: INACTIVE,
        addresses,
        eligibilityBasedOnInvestorId: getRandomItemFromArray(mockedAgencyInvestors).id,
        eligibilityVersionId: '1'
    },
    {
        id: `${clientInvestorID++}`,
        code: 'KXQ',
        name: 'Private Institution',
        legalName: 'Private Inst Corp',
        isAgency: false,
        managedEligibilityStatus: UNMANAGED,
        activeClientIDs: [],
        status: INACTIVE,
        addresses,
        eligibilityVersionId: '1'
    },
    {
        id: `${clientInvestorID++}`,
        code: 'NEWI',
        name: 'New Institution',
        legalName: 'New Inst Corp',
        isAgency: false,
        managedEligibilityStatus: UNMANAGED,
        activeClientIDs: [],
        status: PENDING,
        addresses,
        eligibilityVersionId: '1'
    }
];

export const mockedClientInvestors = mockedAgencyInvestors.concat(mockedClientPrivateInvestors);
