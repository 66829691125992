import { apiUtils, getAccountBaseUrl } from '../api-utils';


export function getLockAvailabilities(clientID: string, customerID: string | undefined): Promise<LockAvailability[]> {
    return apiUtils.get(getAvailabilityURL(clientID, customerID));
}

export function updateLockAvailability(
    clientID: string, lockAvailability: LockAvailability, customerID: string | undefined
): Promise<LockAvailability> {
    return apiUtils.put(`${getAvailabilityURL(clientID, customerID)}/${lockAvailability.lockAvailabilityId}`, lockAvailability);
}

export function getLockAvailabilityOverrides(
    clientID: string, customerID: string | undefined
): Promise<LockAvailabilityOverride[]> {
    return apiUtils.get(getAvailabilityURL(clientID, customerID, true));
}

export function createLockAvailabilityOverride(
    clientID: string, lockAvailabilityOverride: Omit<LockAvailabilityOverride, 'lockAvailabilityOverrideId'>, customerID: string | undefined
): Promise<LockAvailabilityOverride> {
    return apiUtils.post(getAvailabilityURL(clientID, customerID, true), lockAvailabilityOverride);
}

export function updateLockAvailabilityOverride(
    clientID: string, lockAvailabilityOverride: LockAvailabilityOverride, customerID: string | undefined
): Promise<LockAvailabilityOverride> {
    return apiUtils.put(`${getAvailabilityURL(clientID, customerID, true)}/${lockAvailabilityOverride.lockAvailabilityOverrideId}`, lockAvailabilityOverride);
}

export function deleteLockAvailabilityOverride(
    clientID: string, lockAvailabilityOverrideID: string, customerID: string | undefined
): Promise<void> {
    return apiUtils.delete(`${getAvailabilityURL(clientID, customerID, true)}/${lockAvailabilityOverrideID}`);
}

export function toggleCustomerLockAvailabilityConfig(
    clientID: string,
    toggleCustomerLockAvailabilityConfig: ToggleCustomerLockAvailabilityConfig,
    customerID: string | undefined
): Promise<void> {
    return apiUtils.post(`${getAvailabilityURL(clientID, customerID)}/toggle-customer-config`, toggleCustomerLockAvailabilityConfig, { emptyResponse: true });
}

export function getActiveCustomerIds(
    clientID: string
): Promise<string[]> {
    return apiUtils.get(`${getAvailabilityURL(clientID)}/active-customer-config`);
}

export function getCurrentLockSuspension(clientID: string, customerID: string | undefined): Promise<LockSuspension> {
    return apiUtils.get(`${getAccountBaseUrl(clientID, customerID)}/lock-suspension/current`);
}

export function createLockSuspension(
    clientID: string, lockSuspension: Omit<LockSuspension, 'lockSuspensionId'>, customerID: string | undefined
): Promise<LockSuspension> {
    return apiUtils.post(`${getAccountBaseUrl(clientID, customerID)}/lock-suspension/`, lockSuspension);
}

export function deleteLockSuspension(clientID: string, customerID: string | undefined): Promise<void> {
    return apiUtils.delete(`${getAccountBaseUrl(clientID, customerID)}/lock-suspension/`);
}

function getAvailabilityURL(clientID: string, customerID?: string | undefined, override?: true) {
    return `${getAccountBaseUrl(clientID, customerID)}/lockAvailability${override ? '/override' : ''}`;
}

export type Range<TMax extends number> = _Range<TMax>;

type _Range<TMax extends number, TMinArray extends unknown[] = []> = TMinArray[ 'length' ] extends TMax
    ? TMinArray[ number ]
    : _Range<TMax, [ TMinArray[ 'length' ], ...TMinArray ]>;

export type TimeString = `${Range<24> | `0${Range<10>}`}:${Range<60> | `0${Range<10>}`}`;

export interface LockAvailability {
    lockAvailabilityId: string;
    customerId: string | null;
    isMondayAvailable: boolean;
    mondayStart: TimeString | null;
    mondayEnd: TimeString | null;
    isTuesdayAvailable: boolean;
    tuesdayStart: TimeString | null;
    tuesdayEnd: TimeString | null;
    isWednesdayAvailable: boolean;
    wednesdayStart: TimeString | null;
    wednesdayEnd: TimeString | null;
    isThursdayAvailable: boolean;
    thursdayStart: TimeString | null;
    thursdayEnd: TimeString | null;
    isFridayAvailable: boolean;
    fridayStart: TimeString | null;
    fridayEnd: TimeString | null;
    isSaturdayAvailable: boolean;
    saturdayStart: TimeString | null;
    saturdayEnd: TimeString | null;
    isSundayAvailable: boolean;
    sundayStart: TimeString | null;
    sundayEnd: TimeString | null;
}

export interface LockAvailabilityOverride {
    lockAvailabilityOverrideId: string;
    customerId: string | null;
    lockAvailabilityDate: string;
    isAvailable: boolean;
    lockStart: TimeString | null;
    lockEnd: TimeString | null;
    description: string;
}

export interface ToggleCustomerLockAvailabilityConfig {
    useCustomerConfig: boolean;
}

export interface LockSuspension {
    lockSuspensionId: string;
    customerId: string | null;
    isSuspended: boolean;
    isSuspendedUntilNewRateSheet: boolean;
    suspendedComments: string | null;
}
