import { EligibilityVersion } from '@api';
import { CheckBox, Visibility } from '@mui/icons-material';
import {
    IconButton, Paper, Tooltip, Typography
} from '@mui/material';
import {
    formatDate, isFutureDate, isPastDate, isToday
} from '@tsp-ui/core';
import clsx from 'clsx';
import { Link } from 'react-router-dom';


import styles from './EligibilityVersionCard.module.scss';


interface EligibilityVersionCardProps {
    version: EligibilityVersion;
    selectedVersionId: string | undefined;
    onClick: () => void;
}

export default function EligibilityVersionCard({
    selectedVersionId, onClick, version: {
        expirationDate, effectiveDate, id, displayVersionId, comments
    }
}: EligibilityVersionCardProps) {
    const isActive = (isPastDate(effectiveDate) || isToday(effectiveDate)) && (
        expirationDate ? isFutureDate(expirationDate) : true
    );
    const isPending = isFutureDate(effectiveDate);
    const isExpired = isPastDate(expirationDate);

    const isSelected = id === selectedVersionId;

    return (
        <Paper
            elevation={0}
            className={clsx(styles.root, {
                [styles.active]: isActive,
                [styles.pending]: isPending,
                [styles.expired]: isExpired,
                [styles.selected]: isSelected
            })}
        >
            <div>
                <div className={styles.header}>
                    <Typography>
                        Version {displayVersionId}
                    </Typography>

                    <Typography variant="caption">
                        {formatDate(effectiveDate)}

                        {` - ${expirationDate ? formatDate(expirationDate) : 'No expiration'}`}
                    </Typography>
                </div>

                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                    {comments}
                </Typography>
            </div>

            <div>
                <Tooltip
                    title="View version"
                    key={isSelected ? 'disabled' : 'enabled'}
                >
                    <IconButton
                        component={Link}
                        size="small"
                        to={isActive ? '.' : `?eligibilityVersionId=${id}`}
                        replace
                        disabled={isSelected}
                        onClick={onClick}
                    >
                        {isSelected ? (
                            <CheckBox
                                color={isActive ? 'success' : isPending ? 'warning' : 'error'}
                                fontSize="small"
                            />
                        ) : (
                            <Visibility
                                color="action"
                                fontSize="small"
                            />
                        )}
                    </IconButton>
                </Tooltip>
            </div>
        </Paper>
    );
}
