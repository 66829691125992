import { rest } from 'msw';

import {
    Address,
    CreateLoanProgramReqBody,
    CreateLoanProgramResBody,
    EligibilityGuideline,
    EligibilityMatrix,
    InternalLoanProgram,
    Investor,
    InvestorStatus,
    InvestorStatusUpdateBody,
    LoanLimitType,
    LoanProgramStatus,
    LoanProperty,
    LoanType,
    ManagedEligibilityStatus,
    NumericFieldOperator,
    NumericMatrixEntryType,
    PropertyType,
    State
} from '..';
import { getMockUrl } from '../../mocks/getMockUrl';


const { MANAGED, PENDING } = ManagedEligibilityStatus;

let mockInvestorID = 1;
let guidelineID = 0;
let mockedLoanProgramID = 0;
export function getNextMockedLoanProgramID() {
    return `${mockedLoanProgramID++}`;
}

export const mocks = [
    /* Investor */
    rest.get(getMockUrl('/investor'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(mockedInvestors)
    ))),
    rest.put(getMockUrl('/investor/:investorID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(req.body as Investor)
    ))),
    rest.post(getMockUrl('/investor'), (req, res, ctx) => {
        const newInvestor: Investor = {
            ...req.body as Investor,
            id: `${mockInvestorID++}`
        };

        mockedInvestors.push(newInvestor);

        return res(
            ctx.status(200),
            ctx.json(newInvestor)
        );
    }),
    rest.patch(getMockUrl('/investor/:investorID/status'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json({
            ...(mockedInvestors).find(investor => investor.id === req.params.investorID),
            ...req.body as InvestorStatusUpdateBody
        })
    ))),

    /* Loan program */
    rest.get(getMockUrl('/investor/:investorID/loan-program'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(mockedLoanProgramBase.map(loanProgram => ({
            ...loanProgram,
            investorId: req.params.investorID as string
        })))
    ))),
    rest.post(getMockUrl('/investor/:investorID/loan-program'), (req, res, ctx) => {
        const { loanProgram, matrix } = req.body as CreateLoanProgramReqBody;

        return res(
            ctx.status(200),
            ctx.json({
                loanProgram: {
                    ...loanProgram,
                    id: `${mockedLoanProgramID++}`
                },
                matrix
            } as CreateLoanProgramResBody)
        );
    }),
    rest.put(getMockUrl('/investor/:investorID/loan-program/:loanProgramID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(req.body as InternalLoanProgram)
    ))),
    rest.patch(getMockUrl('/investor/:investorID/loan-program/:loanProgramID/status'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json({
            ...mockedLoanProgramBase.find(loanProgram => loanProgram.id === req.params.loanProgramID),
            ...req.body as InternalLoanProgram,
            investorId: req.params.investorID
        })
    ))),

    /* Eligibility */
    rest.get(getMockUrl('/investor/:investorID/eligibility-exclusions'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(exclusionMocks)
    ))),
    rest.put(getMockUrl('/investor/:investorID/eligibility-exclusions'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(req.body as EligibilityGuideline[])
    ))),
    rest.get(getMockUrl('/investor/:investorID/loan-program/:loanProgramID/matrix'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(matrixMocks)
    ))),
    rest.put(getMockUrl('/investor/:investorID/loan-program/:loanProgramID/matrix'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(req.body as EligibilityMatrix)
    )))
];

const { ACTIVE } = InvestorStatus;
const mockedAddresses: Address[] = [
    {
        street: '1234 Mystery Lane',
        city: 'Somewhere',
        state: State.SC,
        zip: '54321'
    },
    {
        street: '234 Orthodox Languin road',
        city: 'Somewhere',
        state: State.SC,
        zip: '54321'
    },
    {
        street: '1 2nd pl',
        city: 'Somewhere',
        state: State.SC,
        zip: '54321'
    },
    {
        street: '361 Elements Place Apt 551D - Unit xyz',
        city: 'Somewhere',
        state: State.SC,
        zip: '54321'
    }
];

const mockedInvestors: Investor[] = [
    {
        id: `${mockInvestorID++}`,
        code: 'FNMA',
        name: 'Fannie Mae',
        legalName: 'Federal National Mortgage Association',
        isAgency: true,
        managedEligibilityStatus: PENDING,
        activeClientIDs: [
            '1', '2', '3'
        ],
        status: ACTIVE,
        addresses: mockedAddresses,
        eligibilityVersionId: '1'
    },
    {
        id: `${mockInvestorID++}`,
        code: 'FHLMC',
        name: 'Freddie Mac',
        legalName: 'Federal Home Loan Mortgage Corporation',
        isAgency: true,
        managedEligibilityStatus: MANAGED,
        activeClientIDs: [],
        status: ACTIVE,
        addresses: mockedAddresses,
        eligibilityVersionId: '1'
    },
    {
        id: `${mockInvestorID++}`,
        code: 'GNMA',
        name: 'Ginnie Mae',
        legalName: 'Government National Mortgage Association',
        isAgency: true,
        managedEligibilityStatus: MANAGED,
        activeClientIDs: [ '1' ],
        status: ACTIVE,
        addresses: mockedAddresses,
        eligibilityVersionId: '1'
    }
];

export const mockedLoanProgramBase: Omit<InternalLoanProgram, 'investorId'>[] = [
    {
        id: getNextMockedLoanProgramID(),
        name: 'Standard Eligibility Requirements DU',
        description: 'Eligibility for loans using Desktop Underwriter excluding High LTV Refinance, HomeReady, HomeStyle Renovation, and Manufactured Housing',
        status: LoanProgramStatus.ACTIVE,
        eligibilityVersionId: '1'
    },
    {
        id: getNextMockedLoanProgramID(),
        name: 'HomeStyle Renovation DU',
        description: 'Eligibility for HomeStyle Renovation loans using Desktop Underwriter',
        status: LoanProgramStatus.ACTIVE,
        eligibilityVersionId: '1'
    },
    {
        id: getNextMockedLoanProgramID(),
        name: 'Manufactured Housing DU',
        description: 'Eligibility for Manufactured Housing loans using Desktop Underwriter',
        status: LoanProgramStatus.ACTIVE,
        eligibilityVersionId: '1'
    },
    {
        id: getNextMockedLoanProgramID(),
        name: 'HomeReady DU',
        description: 'Eligibility for HomeReady loans using Desktop Underwriter',
        status: LoanProgramStatus.ACTIVE,
        eligibilityVersionId: '1'
    },
    {
        id: getNextMockedLoanProgramID(),
        name: 'High LTV Refinance DU',
        description: 'Eligibility for High LTV Refinance loans using Desktop Underwriter',
        status: LoanProgramStatus.ACTIVE,
        eligibilityVersionId: '1'
    },
    {
        id: getNextMockedLoanProgramID(),
        name: 'A Custom Loan Program',
        description: 'Eligibility for a custom Loan Program',
        status: LoanProgramStatus.ACTIVE,
        eligibilityVersionId: '1'
    },
    {
        id: getNextMockedLoanProgramID(),
        name: 'Standard Eligibility Requirements Manual',
        description: 'Eligibility for loans that are manually underwritten excluding High LTV Refinance, HomeReady, HomeStyle Renovation, and Manufactured Housing',
        status: LoanProgramStatus.ACTIVE,
        eligibilityVersionId: '1'
    },
    {
        id: getNextMockedLoanProgramID(),
        name: 'HomeStyle Renovation Manual',
        description: 'Eligibility for HomeStyle Renovation loans that are manually underwritten',
        status: LoanProgramStatus.ACTIVE,
        eligibilityVersionId: '1'
    },
    {
        id: getNextMockedLoanProgramID(),
        name: 'Manufactured Housing Manual',
        description: 'Eligibility for Manufactured Housing loans that are manually underwritten',
        status: LoanProgramStatus.ACTIVE,
        eligibilityVersionId: '1'
    },
    {
        id: getNextMockedLoanProgramID(),
        name: 'HomeReady Manual',
        description: 'Eligibility for HomeReady loans using that are manually underwritten',
        status: LoanProgramStatus.ACTIVE,
        eligibilityVersionId: '1'
    },
    {
        id: getNextMockedLoanProgramID(),
        name: 'High LTV Refinance Manual',
        description: 'Eligibility for High LTV Refinance loans that are manually underwritten',
        status: LoanProgramStatus.ACTIVE,
        eligibilityVersionId: '1'
    }
];

const exclusionMocks: EligibilityGuideline[] = [
    {
        id: `${guidelineID++}`,
        isExclusion: true,
        eligibilityVersionId: '1',
        investorId: '1',
        ltv: {
            type: NumericMatrixEntryType.COMPARISON,
            operator: NumericFieldOperator.LESS_THAN,
            value: 70
        }
    }
];

export const matrixMocks: EligibilityMatrix = {
    columnMetadata: [
        {
            loanProperty: LoanProperty.TERM,
            eligibilityVersionId: '1',
            value: {
                type: NumericMatrixEntryType.COMPARISON,
                operator: NumericFieldOperator.GREATER_THAN,
                value: 14.999
            },
            isHighLevel: true,
            displayOrder: 0,
            loanProgramId: '1'
        },
        {
            loanProperty: LoanProperty.LOAN_TYPE,
            eligibilityVersionId: '1',
            value: [ LoanType.CONVENTIONAL, LoanType.FHA ],
            isHighLevel: true,
            displayOrder: 1,
            loanProgramId: '1'
        },
        {
            loanProperty: LoanProperty.PROPERTY_TYPE,
            eligibilityVersionId: '1',
            value: [ PropertyType.PUD ],
            isHighLevel: false,
            displayOrder: 3,
            loanProgramId: '1'
        },
        {
            loanProperty: LoanProperty.LOAN_LIMIT_TYPE,
            eligibilityVersionId: '1',
            value: [ LoanLimitType.HIGH_BALANCE ],
            isHighLevel: false,
            displayOrder: 4,
            loanProgramId: '1'
        },
        {
            loanProperty: LoanProperty.FICO,
            eligibilityVersionId: '1',
            isHighLevel: false,
            displayOrder: 5,
            loanProgramId: '1'
        }
    ],
    inclusions: [
        {
            id: `${guidelineID++}`,
            isExclusion: false,
            eligibilityVersionId: '1',
            investorId: '1',
            propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
            fico: {
                type: NumericMatrixEntryType.COMPARISON,
                operator: NumericFieldOperator.GREATER_THAN,
                value: 700
            }
        },
        {
            id: `${guidelineID++}`,
            isExclusion: false,
            eligibilityVersionId: '1',
            investorId: '1',
            propertyType: PropertyType.PUD,
            fico: {
                type: NumericMatrixEntryType.COMPARISON,
                operator: NumericFieldOperator.GREATER_THAN,
                value: 670
            }
        }
    ],
    exclusions: exclusionMocks
};
