import api, {
    Investor, InvestorStatus, ManagedEligibilityStatus, State
} from '@api';
import { RemoveCircleOutline } from '@mui/icons-material';
import { Button, Tooltip } from '@mui/material';
import {
    AddressFieldset, IconButton, Switch, TextField
} from '@tsp-ui/core/components';
import { usePageMessage } from '@tsp-ui/core/utils';
import { useEligibilityVersionIdQueryParam } from '@views/admin/investors/components/EligibilityVersionButton';
import clsx from 'clsx';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import { AdminAddEditFormProps } from '../../../components/AdminAddPageTemplate';

import styles from './InternalInvestorForm.module.scss';


const { ACTIVE, PENDING } = InvestorStatus;

export default function InternalInvestorForm({
    entityToEdit: investorToEdit,
    onSubmit,
    setLoading
} : AdminAddEditFormProps<Investor>) {
    const pageMessage = usePageMessage();
    const navigate = useNavigate();
    const { search } = useLocation();

    const eligibilityVersionId = useEligibilityVersionIdQueryParam();
    const formMethods = useForm<Investor>({
        defaultValues: investorToEdit || {
            eligibilityVersionId,
            addresses: [ {} ],
            status: PENDING,
            managedEligibilityStatus: ManagedEligibilityStatus.MANAGED
        }
    });

    const handleSubmit = formMethods.handleSubmit(async (formValues: Investor) => {
        setLoading(true);

        const investorToSubmit = {
            ...formValues,
            status: ACTIVE
        };

        try {
            const newInvestor = investorToEdit
                ? await api.investors.updateInvestor(investorToSubmit)
                : await api.investors.createInvestor(investorToSubmit);

            onSubmit(newInvestor);

            const isAdd = !formValues.id;
            navigate(isAdd ? `../${newInvestor.id}${search}` : `../${search}`);

            pageMessage.success(`Investor ${isAdd ? 'added' : 'details saved'}`);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving investor details', error);
        }

        setLoading(false);
    });

    return (
        <form
            id={InternalInvestorForm.formID}
            className={styles.root}
            onSubmit={handleSubmit}
            noValidate
        >
            <FormProvider {...formMethods}>
                <InvestorFormFields />
            </FormProvider>
        </form>
    );
}

interface InvestorFormFieldsProps {
    hideAgencySwitch?: boolean;
    disableCode?: boolean;
}

export function InvestorFormFields({ hideAgencySwitch, disableCode }: InvestorFormFieldsProps) {
    const { fields: addresses, append, remove } = useFieldArray<Investor, 'addresses'>({
        name: 'addresses'
    });

    const appendAddress = () => append({
        street: '',
        city: '',
        state: '' as State,
        zip: ''
    });

    return (
        <>
            <TextField<Investor>
                name="name"
                label="Investor name"
                required
                autoFocus
            />

            <TextField<Investor>
                name="code"
                label="Code"
                disabled={disableCode}
                required
            />

            <TextField<Investor>
                className={styles.fullWidth}
                name="legalName"
                label="Legal name"
                required
            />

            {addresses.map((address, index) => (
                <AddressFieldset<Investor>
                    key={JSON.stringify(address)}
                    className={styles.fullWidth}
                    classes={{ legend: styles.addresses }}
                    label={(
                        <>
                            Address {index + 1}

                            {addresses.length > 1 && (
                                <Tooltip title="Remove address">
                                    <IconButton
                                        onClick={() => remove(index)}
                                    >
                                        <RemoveCircleOutline color="error" />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </>
                    )}
                    fieldNames={{
                        street: `addresses.${index}.street`,
                        line2: `addresses.${index}.line2`,
                        city: `addresses.${index}.city`,
                        state: `addresses.${index}.state`,
                        zip: `addresses.${index}.zip`
                    }}
                />
            ))}

            <div className={clsx(styles.fullWidth, styles.bottomRow)}>
                {!hideAgencySwitch && (
                    <Switch<Investor>
                        label="Is agency"
                        name="isAgency"
                    />
                )}

                <Button onClick={appendAddress}>
                    Add additional address
                </Button>
            </div>
        </>
    );
}

InternalInvestorForm.formID = 'internal-investor-form';
