import api, {
    CommitmentType,
    CustomerDetails,
    CustomerEligibleProducts,
    LockPeriod,
    Product,
    ProductType,
    ProductTypeConfig,
    UnderwritingType
} from '@api';
import { Button, DialogContent, Typography } from '@mui/material';
import { DialogActions, RoutedDialog, RoutedDialogProps } from '@tsp-ui/core/components';
import { usePageMessage } from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils/hooks';
import { useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { CustomerDetailContext, CustomerDetailContextValue } from '../../CustomerDetailPage';

import styles from './EligibleProductsDialog.module.scss';
import ProductTypeCard from './components/ProductTypeCard';
import ProductTypeSection from './components/ProductTypeSection';


type ProductTypeFormConfigValues = Omit<ProductTypeConfig, 'id'> & Partial<Pick<ProductTypeConfig, 'id'>>;

export interface CustomerEligibleProductsFormValues {
    productTypeConfigs: ProductTypeFormConfigValues[];
    eligibleProducts: {
        [productID: string]: boolean;
    };
}

export default function EligibleProductsDialog(props: Omit<RoutedDialogProps, 'title' | 'children'>) {
    const [ saveLoading, setSaveLoading ] = useState(false);

    const navigate = useNavigate();
    const pageMessage = usePageMessage();
    const { id: clientID } = useGetCurrentAccount();

    const {
        customer, refreshCustomer, products
    } = useContext(CustomerDetailContext) as Required<CustomerDetailContextValue>;

    const formMethods = useForm<CustomerEligibleProductsFormValues>({
        defaultValues: getDefaultValues(customer, products)
    });

    const handleSubmit = formMethods.handleSubmit(async (formData) => {
        setSaveLoading(true);

        try {
            await api.customer.eligibleProducts.updateEligibleProducts(
                clientID, customer.id, getRequestBodyFromFormValues(formData)
            );
            await refreshCustomer();

            navigate(props.closeTo);

            pageMessage.success('Eligible products updated');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while updating the eligible products', error);
        }

        setSaveLoading(false);
    });

    const productTypes = Object.values(ProductType);

    return (
        <RoutedDialog
            {...props}
            title="Eligible products"
            maxWidth={false}
        >
            <DialogContent className={styles.mainContent}>
                <form
                    noValidate
                    id="eligible-products-form"
                    onSubmit={handleSubmit}
                    className={styles.form}
                    autoComplete="off"
                >
                    <FormProvider {...formMethods}>
                        <div>
                            <Typography
                                fontWeight={500}
                                color="textSecondary"
                            >
                                Product types
                            </Typography>

                            {productTypes.map((productType) => (
                                <ProductTypeCard
                                    key={productType}
                                    productType={productType}
                                />
                            ))}
                        </div>

                        <div>
                            <Typography
                                fontWeight={500}
                                color="textSecondary"
                            >
                                Products
                            </Typography>

                            {productTypes.map((productType) => (
                                <ProductTypeSection
                                    key={productType}
                                    productType={productType}
                                    products={products}
                                />
                            ))}

                            <Typography
                                className={styles.noResults}
                                variant="body2"
                                color="textSecondary"
                            >
                                Activate a product type to activate products
                            </Typography>
                        </div>
                    </FormProvider>
                </form>
            </DialogContent>

            <DialogActions loading={saveLoading}>
                <Button
                    variant="contained"
                    form="eligible-products-form"
                    type="submit"
                    disabled={saveLoading}
                >
                    Save
                </Button>
            </DialogActions>
        </RoutedDialog>
    );
}

function getDefaultValues(customer: CustomerDetails | undefined, products: Product[] | undefined)
    : CustomerEligibleProductsFormValues {
    return {
        productTypeConfigs: Object.values(ProductType).map((productType) => (
            customer?.productTypeConfigs.find((config) => config.productType === productType)
            || {
                customerId: customer?.id!,
                isEnabled: false,
                productType,
                underwritingType: UnderwritingType.DELEGATED,
                allowedCommitmentTypes: [] as CommitmentType[],
                allowedLockPeriods: [] as LockPeriod[]
            } as ProductTypeFormConfigValues
        )),
        eligibleProducts: Object.fromEntries(
            (products || []).map(({ id, isActive }) => (
                [ id, isActive && !!customer?.eligibleProductIds.includes(id) ]
            ))
        )
    };
}

function getRequestBodyFromFormValues({ eligibleProducts, productTypeConfigs }: CustomerEligibleProductsFormValues)
    : CustomerEligibleProducts {
    return {
        productTypeConfigs: productTypeConfigs.filter(({ isEnabled }) => isEnabled) as ProductTypeConfig[],
        eligibleProductIds: Object.entries(eligibleProducts).map(([ productID, enabled ]) => (
            enabled ? productID : undefined
        )).filter(Boolean) as string[]
    };
}
