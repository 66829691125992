import {
    DialogContent, MenuItem, Paper, TextField, Typography
} from '@mui/material';
import { Dialog } from '@tsp-ui/core/components';
import clsx from 'clsx';
import { useState } from 'react';

import StatusChecklistItem from '../../../admin/customers/components/StatusChecklistItem';

import styles from './UnderwritingDocumentDialog.module.scss';

// TODO
export default function UnderwritingDocumentDialog({
    open, onClose
}: any) {
    const [ step ] = useState(0);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            title={(
                <div className={styles.title}>
                    View document

                    <TextField
                        variant="outlined"
                        size="small"
                        value={1}
                        select
                    >
                        <MenuItem
                            value={1}
                        >
                            Credit report.pdf
                        </MenuItem>
                    </TextField>
                </div>
            )}
            maxWidth={false}
            classes={{
                paper: styles.paper
            }}
        >
            <DialogContent className={styles.content}>
                <div className={styles.sidebar}>
                    {/* <StepContent*/}

                    {/*    step={step}*/}

                    {/*    setStep={setStep}*/}

                    {/* />*/}

                    <ChecklistCard step={step} />
                </div>

                <iframe
                    title="document"
                    src="/URLA.pdf#navpanes=0&view=FitH"
                    className={styles.frame}
                />
            </DialogContent>
        </Dialog>
    );
}

function ChecklistCard({ step }: { step: number }) {
    return (
        <Paper
            variant="outlined"
            className={styles.card}
        >
            <Typography color="textSecondary">
                Credit checklist
            </Typography>

            <StatusChecklistItem
                selected={step === 0}
                done={step > 0}
                label="Credit report details"
            />

            {step > 0 && (
                <Typography
                    className={styles.checklistItemDetails}
                    variant="body2"
                    component="p"
                    color="textSecondary"
                >
                    Qualifying score: 705
                </Typography>
            )}

            <StatusChecklistItem
                selected={step === 1 || step === 2}
                done={step > 2}
                label="Credit inquiries"
            />

            {step > 1 && (
                <Typography
                    className={clsx(styles.checklistItemDetails, {
                        [styles.noBottomMargin]: step > 2
                    })}
                    variant="body2"
                    component="p"
                    color="textSecondary"
                >
                    Has other inquiries
                </Typography>
            )}

            {step > 2 && (
                <Typography
                    className={styles.checklistItemDetails}
                    variant="body2"
                    component="p"
                    color="textSecondary"
                >
                    Has letters for inquiries
                </Typography>
            )}

            <StatusChecklistItem
                selected={step === 3}
                done={step > 3}
                label="Mortgage history"
            />

            {step > 3 && (
                <Typography
                    className={styles.checklistItemDetails}
                    variant="body2"
                    component="p"
                    color="textSecondary"
                >
                    No late payments
                </Typography>
            )}

            <StatusChecklistItem
                selected={step === 4}
                done={step > 4}
                label="Fraud check"
            />

            {step > 4 && (
                <Typography
                    className={styles.checklistItemDetails}
                    variant="body2"
                    component="p"
                    color="textSecondary"
                >
                    No fraud alerts
                </Typography>
            )}

            <StatusChecklistItem
                selected={step === 5 || step === 6 || step === 7}
                done={step > 7}
                label="Derogatories"
            />

            {step > 5 && (
                <Typography
                    className={styles.checklistItemDetails}
                    variant="body2"
                    component="p"
                    color="textSecondary"
                >
                    No bankruptcies, foreclosures, or short salse
                </Typography>
            )}

            {step > 6 && (
                <Typography
                    className={styles.checklistItemDetails}
                    variant="body2"
                    component="p"
                    color="textSecondary"
                >
                    No outstanding judgements
                </Typography>
            )}

            {step > 7 && (
                <Typography
                    className={styles.checklistItemDetails}
                    variant="body2"
                    component="p"
                    color="textSecondary"
                >
                    No open collections/charge offs
                </Typography>
            )}
        </Paper>
    );
}
