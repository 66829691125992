import { License } from '@api';
import { Typography } from '@mui/material';


export default function LicensesTableRow({ values }: { values: License }) {
    return (
        <tr>
            <Typography
                component="td"
                padding="4px"
                variant="body2"
                width="100%"
                noWrap
            >
                {values.state}
            </Typography>

            <Typography
                component="td"
                variant="body2"
                padding="4px"
                noWrap
            >
                {values.licenseNumber}
            </Typography>

            <Typography
                component="td"
                variant="body2"
                padding="4px"
                noWrap
            >
                {values.renewedThroughYear}
            </Typography>

            <Typography
                component="td"
                variant="body2"
                padding="4px"
                noWrap
            >
                {values.status}
            </Typography>

            <Typography
                component="td"
                variant="body2"
                padding="4px"
                noWrap
            >
                {values.type}
            </Typography>
        </tr>
    );
}
