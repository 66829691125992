import { FormLabel } from '@mui/material';
import {
    AddressFieldset,
    CurrencyField, TextField, isAtLeast
} from '@tsp-ui/core/components';
import { useRenderTogglableEnumOptions } from '@utils';

import { ManualLoanEntryFormValues } from '../ManualLoanEntryPage';

import ManualLoanEntryStep, { ManualLoanEntryStepProps } from './ManualLoanEntryStep';
import styles from './PropertyStep.module.scss';


export default function PropertyStep(props: Required<Pick<ManualLoanEntryStepProps, 'onPreviousButtonClick'>>) {
    const renderTogglableEnumOptions = useRenderTogglableEnumOptions();
    return (
        <ManualLoanEntryStep {...props}>
            <div>
                <FormLabel required>
                    Property details
                </FormLabel>

                <div className={styles.loanInfoContainer}>
                    <TextField<ManualLoanEntryFormValues>
                        name="propertyType"
                        label="Property type"
                        select
                        required
                        hideRequiredIndicator
                    >
                        {renderTogglableEnumOptions('propertyType')}
                    </TextField>

                    <TextField<ManualLoanEntryFormValues>
                        name="propertyUnits"
                        label="Number of units"
                        select
                        required
                        hideRequiredIndicator
                    >
                        {renderTogglableEnumOptions('numUnits')}
                    </TextField>

                    <TextField<ManualLoanEntryFormValues>
                        name="loanPurpose"
                        label="Purpose"
                        select
                        required
                        hideRequiredIndicator
                    >
                        {renderTogglableEnumOptions('loanPurpose')}
                    </TextField>

                    <TextField<ManualLoanEntryFormValues>
                        name="occupancyType"
                        label="Occupancy"
                        select
                        required
                        hideRequiredIndicator
                    >
                        {renderTogglableEnumOptions('occupancyType')}
                    </TextField>

                    <CurrencyField<ManualLoanEntryFormValues>
                        name="appraisedValue"
                        label="Appraised value"
                        required
                        hideRequiredIndicator
                        rules={isAtLeast(0)}
                    />

                    <CurrencyField<ManualLoanEntryFormValues>
                        name="salesPrice"
                        label="Sales price (optional)"
                        rules={isAtLeast(0)}
                    />
                </div>
            </div>

            <AddressFieldset<ManualLoanEntryFormValues>
                required
                fieldNames={{
                    city: 'propertyCity',
                    state: 'propertyState',
                    zip: 'propertyZipCode',
                    county: 'propertyCounty'
                }}
            />
        </ManualLoanEntryStep>
    );
}
