
import { createDate } from '@tsp-ui/core/utils';
import { rest } from 'msw';

import { getMockUrl } from '../../mocks/getMockUrl';
import { SAMPLE_PDF_URL } from '../customer/due-diligence-step/due-diligence-step-mocks';


export const mocks = [
    rest.get(getMockUrl('/client/:clientID/document/:documentID'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.delay(1000),
        ctx.json({
            id: req.params.documentID,
            preSignedUrl: SAMPLE_PDF_URL,
            url: SAMPLE_PDF_URL,
            expiration: createDate(.001).toISOString() // 1.44 minutes from now
        })
    ))
];
