export enum ConditionEvent {
    STATUS_ADDED = 'STATUS_ADDED',
    STATUS_FULFILLED = 'STATUS_FULFILLED',
    STATUS_REQUESTED = 'STATUS_REQUESTED',
    STATUS_REREQUESTED = 'STATUS_REREQUESTED',
    STATUS_RECEIVED = 'STATUS_RECEIVED',
    STATUS_REVIEWED = 'STATUS_REVIEWED',
    STATUS_REJECTED = 'STATUS_REJECTED',
    STATUS_CLEARED = 'STATUS_CLEARED',
    STATUS_WAIVED = 'STATUS_WAIVED',
    RESPONSIBLE_PARTY_CHANGES = 'RESPONSIBLE_PARTY_CHANGES'
}

export const conditionEventDisplay = {
    [ConditionEvent.STATUS_ADDED]: 'Added',
    [ConditionEvent.STATUS_FULFILLED]: 'Fulfilled',
    [ConditionEvent.STATUS_REQUESTED]: 'Requested',
    [ConditionEvent.STATUS_REREQUESTED]: 'Rerequested',
    [ConditionEvent.STATUS_RECEIVED]: 'Received',
    [ConditionEvent.STATUS_REVIEWED]: 'Reviewed',
    [ConditionEvent.STATUS_REJECTED]: 'Rejected',
    [ConditionEvent.STATUS_CLEARED]: 'Cleared',
    [ConditionEvent.STATUS_WAIVED]: 'Waived',
    [ConditionEvent.RESPONSIBLE_PARTY_CHANGES]: 'Responsible party changes'
};
