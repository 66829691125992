import { PermissionType, PricingResultProduct, RegistrationType } from '@api';
import { AddTask, Lock } from '@mui/icons-material';
import { Link as MuiLink, Typography } from '@mui/material';
import { ExpandableCardTableRow, IconButton } from '@tsp-ui/core/components';
import { tooltipTitle, useHasPermission } from '@utils';
import { LoansContext } from '@views/loans/LoansPage';
import { useContext } from 'react';

import styles from './PricingResultProductRow.module.scss';
import ProductDetailsTable from './ProductDetailsTable';


interface PricingResultProductRowProps {
    isExpired: boolean;
    onRegister(productID: string, registrationType: RegistrationType): Promise<void>;
    product: PricingResultProduct;
}

export default function PricingResultProductRow({
    onRegister, isExpired, product: {
        productId, adjustments, basePrice, description, docUrl, finalPrice, investor, llpas, margin
    }
}: PricingResultProductRowProps) {
    const [ canRegister ] = useHasPermission([ PermissionType.CREATE_LOANS ]);
    const { isLockDeskOpen } = useContext(LoansContext);

    return (
        <ExpandableCardTableRow
            expandedContent={(
                <ProductDetailsTable adjustments={adjustments} />
            )}
        >
            <td width="100%">
                <Typography
                    component={MuiLink}
                    href={docUrl}
                    rel="noreferrer noopener"
                    target="_blank"
                    variant="body2"
                    className={styles.productLink}
                >
                    {description}
                </Typography>
            </td>

            <Typography
                component="td"
                variant="body2"
            >
                {investor}
            </Typography>

            <Typography
                component="td"
                variant="body2"
            >
                {margin?.toFixed(3)}
            </Typography>

            <Typography
                component="td"
                variant="body2"
            >
                {basePrice?.toFixed(3)}
            </Typography>

            <Typography
                component="td"
                variant="body2"
                color={
                    llpas === 0
                        ? 'textPrimary' // TODO make a component for this
                        : llpas! > 0
                            ? 'green'
                            : 'error'
                }
            >
                {llpas?.toFixed(3)}
            </Typography>

            <Typography
                component="td"
                variant="body2"
            >
                {finalPrice?.toFixed(3)}
            </Typography>

            <td className={styles.buttonsCell}>
                <IconButton
                    tooltip={tooltipTitle({
                        [expiredMessage('lock')]: isExpired,
                        [noPermissionMessage('lock')]: !canRegister,
                        'Cannot lock loan while the lock desk is closed': !isLockDeskOpen,
                        'Lock loan': canRegister
                    })}
                    size="small"
                    disabled={!isLockDeskOpen || isExpired || !canRegister}
                    onClick={() => onRegister(productId, RegistrationType.LOCK)}
                >
                    <Lock
                        color="secondary"
                        fontSize="small"
                    />
                </IconButton>

                <IconButton
                    tooltip={tooltipTitle({
                        [expiredMessage('float')]: isExpired,
                        [noPermissionMessage('float')]: !canRegister,
                        'Float loan': canRegister
                    })}
                    size="small"
                    disabled={isExpired || !canRegister}
                    onClick={() => onRegister(productId, RegistrationType.FLOAT)}
                >
                    <AddTask
                        color={isExpired ? 'disabled' : 'secondary'}
                        fontSize="small"
                    />
                </IconButton>
            </td>
        </ExpandableCardTableRow>
    );
}

const expiredMessage = (action: string) => `Pricing expired. Reprice the loan to ${action}`;
const noPermissionMessage = (action: string) => `You do not have permissions to ${action} loans`;
