export enum LoanPurpose {
    PURCHASE = 'PURCHASE',
    REFINANCE = 'REFINANCE',
    REFINANCE_RATE_TERM = 'REFINANCE_RATE_TERM',
    REFINANCE_CASH_OUT = 'REFINANCE_CASH_OUT',
    CONSTRUCTION_TO_PERMANENT = 'CONSTRUCTION_TO_PERMANENT'
}

export const loanPurposeDisplay = {
    [LoanPurpose.PURCHASE]: 'Purchase',
    [LoanPurpose.REFINANCE]: 'Refinance',
    [LoanPurpose.REFINANCE_RATE_TERM]: 'Rate-term Refi',
    [LoanPurpose.REFINANCE_CASH_OUT]: 'Cash-out Refi',
    [LoanPurpose.CONSTRUCTION_TO_PERMANENT]: 'Construction-to-Permanent'
};
