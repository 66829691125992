import api, {
    ConditionConfig, EncompassUnderwritingCondition, LoanCondition, conditionPriorToDisplay,
    conditionSourceDisplay, premicorrConditionCategoryDisplay
} from '@api';
import { PropsOf } from '@emotion/react';
import { Button, DialogContent, MenuItem } from '@mui/material';
import {
    Switch, replaceItemById, tryGetItemById, useForm, useParams
} from '@tsp-ui/core';
import {
    DateField, DialogActions, RoutedDialog, RoutedDialogProps, TextField, renderEnumOptions, usePageMessage
} from '@tsp-ui/core/components';
import utilStyles from '@tsp-ui/core/sass/style-utils.module.scss';
import { useGetCurrentAccount } from '@utils';
import { useContext, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import styles from './ConditionConfigDialog.module.scss';
import { ConditionConfigContext } from './ConditionConfigPage';


export function ConditionConfigDialog(props: Omit<RoutedDialogProps, 'title'>) {
    const [ loading, setLoading ] = useState(false);
    const { conditionId, conditionConfigGroupId } = useParams<{ conditionConfigGroupId: string }>();
    const { conditionConfigs, setConditionConfigs } = useContext(ConditionConfigContext);
    const configToEdit = tryGetItemById(conditionConfigs, conditionId || '');

    const { id: clientId } = useGetCurrentAccount();

    const pageMessage = usePageMessage();
    const navigate = useNavigate();

    const formMethods = useForm<ConditionConfig>({
        defaultValues: configToEdit
    });

    const handleSubmit = formMethods.handleSubmit(async (formData) => {
        setLoading(true);
        formData.conditionConfigGroupId = conditionConfigGroupId;
        try {
            setConditionConfigs(configToEdit
                ? replaceItemById(conditionConfigs, await api.conditionConfig.updateConditionConfig(clientId, formData))
                : [
                    ...conditionConfigs,
                    await api.conditionConfig.createConditionConfig(clientId, formData)
                ]);

            pageMessage.success('Loan condition configuration saved');

            navigate('..');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving loan condition configuration', error);
        }

        setLoading(false);
    });

    return (
        <RoutedDialog
            {...props}
            title={`${configToEdit ? 'Edit' : 'Add'} loan condition configuration`}
            maxWidth="md"
        >
            <DialogContent>
                <FormProvider {...formMethods}>
                    <ConditionForm
                        id="loan-condition-form"
                        className={styles.root}
                        conditionToEdit={configToEdit}
                        onSubmit={handleSubmit}
                        noValidate
                    />
                </FormProvider>
            </DialogContent>

            <DialogActions loading={loading}>
                <Button
                    type="submit"
                    form="loan-condition-form"
                    variant="contained"
                    disabled={loading}
                >
                    Save
                </Button>
            </DialogActions>
        </RoutedDialog>
    );
}

function isEncompassUnderwritingCondition(
    condition?: LoanCondition
): condition is EncompassUnderwritingCondition {
    return !!condition && 'isAddedToConditionSet' in condition;
}

export function ConditionForm<T extends LoanCondition = LoanCondition>({ conditionToEdit, ...props }: PropsOf<'form'> & { conditionToEdit: T | undefined }) {
    return (
        <form {...props}>
            <TextField<LoanCondition>
                name="title"
                label="Title"
                className={utilStyles.fullWidth}
                required
            />

            <TextField<LoanCondition>
                name="description"
                label="Description"
                multiline
                required
                rows={3}
                className={utilStyles.fullWidth}
            />

            <TextField<LoanCondition>
                name="category"
                label="Condition Category"
                select
                required
            >
                {renderEnumOptions(premicorrConditionCategoryDisplay)}
            </TextField>

            <TextField<LoanCondition>
                name="priorTo"
                label="Prior to"
                select
                required
            >
                {Object.entries(conditionPriorToDisplay).map(([ value, display ]) => (
                    <MenuItem
                        value={display}
                        key={value}
                    >
                        {display}
                    </MenuItem>
                ))}
            </TextField>

            <TextField<LoanCondition>
                name="source"
                label="Source"
                select
                disabled={!!conditionToEdit}
            >
                {Object.entries(conditionSourceDisplay).map(([ value, display ]) => (
                    <MenuItem
                        value={display}
                        key={value}
                    >
                        {display}
                    </MenuItem>
                ))}
            </TextField>

            <TextField<LoanCondition>
                name="requestedFrom"
                label="Requested from"
            />

            <TextField<LoanCondition>
                name="daysToReceive"
                label="Days to receive"
                required
            />

            <DateField<LoanCondition>
                name="expectedDate"
                label="Expected date"
                disabled
            />

            <div className={styles.switches}>
                <Switch<LoanCondition>
                    name="allowToClear"
                    label="Allow to clear"
                />

                <Switch<LoanCondition>
                    name="printExternally"
                    label="Print externally"
                />

                <Switch<LoanCondition>
                    name="printInternally"
                    label="Print internally"
                />

                {isEncompassUnderwritingCondition(conditionToEdit) && (
                    <>
                        <Switch<EncompassUnderwritingCondition>
                            name="isFulfilled"
                            label="Fulfilled"
                        />

                        <Switch<EncompassUnderwritingCondition>
                            name="isRequested"
                            label="Requested"
                        />

                        <Switch<EncompassUnderwritingCondition>
                            name="isRerequested"
                            label="Re-requested"
                        />

                        <Switch<EncompassUnderwritingCondition>
                            name="isReceived"
                            label="Received"
                        />

                        <Switch<EncompassUnderwritingCondition>
                            name="isReviewed"
                            label="Reviewed"
                        />

                        <Switch<EncompassUnderwritingCondition>
                            name="isRejected"
                            label="Rejected"
                        />

                        <Switch<EncompassUnderwritingCondition>
                            name="isCleared"
                            label="Cleared"
                        />

                        <Switch<EncompassUnderwritingCondition>
                            name="isWaived"
                            label="Waived"
                        />

                        <Switch<EncompassUnderwritingCondition>
                            name="isRemoved"
                            label="Removed"
                        />

                        <DateField<EncompassUnderwritingCondition>
                            name="expirationDate"
                            label="Expiration date"
                        />
                    </>
                )}
            </div>
        </form>
    );
}
