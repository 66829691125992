import {
    LLPAMatrixLoanProperty,
    LLPAOnlyLoanProperty,
    LoanProperty,
    loanPropertyDisplay
} from '@api';
import {
    AddCircleOutline, Check, Edit, MoreVert, RemoveCircleOutline, WarningAmber
} from '@mui/icons-material';
import {
    Autocomplete, Menu, MenuItem, TextField, Tooltip, Typography
} from '@mui/material';
import { useConfirm } from '@tsp-ui/core';
import { IconButton, IconTypography } from '@tsp-ui/core/components';
import { formatCurrency, replaceItemByKey } from '@tsp-ui/core/utils';
import { tooltipTitle, useLlpaLoanPropertyEnumDisplays } from '@utils';
import {
    formatNumericMatrixEntry,
    numericMatrixEntryValidationRules,
    parseNumericMatrixEntry
} from '@utils/numeric-range-utils';
import { LLPARoutesContext } from '@views/admin/llpas/LLPARoutes';
import {
    LLPAMatrixFormCell,
    MatrixStepFormContext,
    MatrixStepFormValues,
    getDefaultLlpaMatrixFormCells
} from '@views/admin/llpas/components/LLPADialog';
import matrixStyles from '@views/admin/llpas/components/LLPAMatrix/LLPAMatrix.module.scss';
import clsx from 'clsx';
import deepEqual from 'fast-deep-equal';
import {
    useCallback, useContext, useEffect, useMemo, useRef, useState
} from 'react';
import { FieldPath, useFormContext, useWatch } from 'react-hook-form';

import ActionsCell from './ActionsCell';
import styles from './LoanPropertyValueCell.module.scss';


export interface LoanPropertyValueCellProps {
    cell: LLPAMatrixFormCell;
    variant: 'row' | 'column';
    property: LLPAMatrixLoanProperty;
    rowProperty: LLPAMatrixLoanProperty | null;
    colProperties: LLPAMatrixLoanProperty[];
    numRows: number;
    cellIndex: number;
    disableInsert: boolean;
    rowSpan?: number;
    hideBottomBorder?: boolean;
    readOnly?: boolean;
    isOnlyRow?: boolean;
}

export default function LoanPropertyValueCell({
    cell, rowSpan, hideBottomBorder, variant, property, rowProperty,
    colProperties, numRows, cellIndex, readOnly, isOnlyRow, disableInsert
}: LoanPropertyValueCellProps) {
    const confirm = useConfirm();
    const value = cell[property];
    const { getValues, setValue } = useFormContext<MatrixStepFormValues>();

    const cellRef = useRef<HTMLTableCellElement>(null);
    const [ moreActionsAnchorEl, setMoreActionsAnchorEl ] = useState<HTMLElement>();
    const [ isEditing, setIsEditing ] = useState(!value && !readOnly);
    const [ editingManuallyTriggered, setEditingManuallyTriggered ] = useState(!isEditing);

    const { setValidationErrors } = useContext(MatrixStepFormContext);

    const updateValue = useCallback(async (newValue: typeof value) => {
        if (!rowProperty) {
            setValue(`llpaCells.${cellIndex}.${property}`, newValue as never);
        } else {
            const cells = getValues('llpaCells').map(cellToTest => {
                const shouldUpdate = variant === 'row'
                    ? deepEqual(cellToTest[rowProperty], cell[rowProperty])
                    : colProperties.slice(0, colProperties.indexOf(property) + 1).every(colProperty => (
                        deepEqual(cellToTest[colProperty], cell[colProperty])
                    ));

                return !shouldUpdate ? cellToTest : {
                    ...cellToTest,
                    [property]: newValue
                };
            });

            const finalCells: LLPAMatrixFormCell[] = [];
            const cellsWithAdjustmentDifference: LLPAMatrixFormCell[] = [];

            cells.forEach((fullCell) => {
                const { adjustment, ...cell } = fullCell;

                if (!finalCells.some(({ adjustment: _, ...finalCell }) => (
                    deepEqual(finalCell, cell)
                ))) {
                    finalCells.push(fullCell);
                } else {
                    const finalCell = finalCells.find(({ adjustment: _, ...finalCell }) => deepEqual(cell, finalCell));

                    if (finalCell && adjustment !== finalCell?.adjustment) {
                        cellsWithAdjustmentDifference.push(cell);
                    }
                }
            });

            if (cells.length !== finalCells.length && !await confirm(
                <div className={styles.continueMergeProp}>
                    <Typography align="center">
                        Duplicate value entered. Continuing with this value will cause cells to be merged.
                    </Typography>

                    {!cellsWithAdjustmentDifference.length ? (
                        <IconTypography
                            fontWeight={400}
                            className={styles.iconTypography}
                            icon={(
                                <Check
                                    color="success"
                                    fontSize="large"
                                />
                            )}
                        >
                            <span>This merge will <b>not</b> result in data loss</span>
                        </IconTypography>
                    ) : (
                        <IconTypography
                            fontWeight={400}
                            className={styles.iconTypography}
                            icon={(
                                <WarningAmber
                                    color="warning"
                                    fontSize="large"
                                />
                            )}
                        >
                            <span>This merge <b>will</b> result in data loss</span>
                        </IconTypography>
                    )}

                    <Typography align="center">
                        Would you like to continue with the cell merge?
                    </Typography>
                </div>
            )) {
                // Timeout required to allow the dialog to close before attempting focus of the input
                setTimeout(() => (
                    cellRef.current?.querySelector('input')?.focus()
                ), 0);

                return;
            }

            setValue('llpaCells', finalCells);
        }

        setIsEditing(false);
        setEditingManuallyTriggered(false);
    }, [
        rowProperty, setValue, cellIndex, property, getValues, variant, cell, colProperties, confirm
    ]);

    function insertCell(direction: 'before' | 'after') {
        const cells = getValues('llpaCells');

        if (variant === 'row') {
            const startIndex = direction === 'before' ? cellIndex : cellIndex + numRows;

            cells.splice(startIndex, 0, ...cells.slice(0, numRows).map(cell => ({
                ...cell,
                [property]: undefined,
                adjustment: '0.000'
            })));

            setValue('llpaCells', [ ...cells ]);
        } else {
            const insertionIndex = direction === 'before' ? cellIndex : cellIndex + (rowSpan || 1);

            const matrixEntries: LLPAMatrixFormCell[][] = [];
            for (let i = 0; i < cells.length; i += numRows) {
                matrixEntries.push(cells.slice(i, i + numRows));
            }

            const emptyProperties = colProperties.slice(colProperties.indexOf(property));
            const emptyOverrides = Object.fromEntries(emptyProperties.map((colProperty) => (
                [ colProperty, undefined ]
            ))) as Partial<LLPAMatrixFormCell>;

            for (const entriesForRowValue of matrixEntries) {
                const baseEntry = entriesForRowValue[direction === 'before'
                    ? insertionIndex
                    : insertionIndex - 1
                ];

                entriesForRowValue.splice(insertionIndex, 0, {
                    ...baseEntry,
                    ...emptyOverrides,
                    adjustment: '0.000'
                });
            }

            setValue('llpaCells', matrixEntries.flat());
        }
    }

    function removeCell() {
        const previousCols = colProperties.slice(0, colProperties.indexOf(property));

        const newCells = getValues('llpaCells').filter(cellToFilter => {
            const cellNotEqual = !deepEqual(cellToFilter[property], cell[property]);

            return variant === 'row' ? cellNotEqual : (
                cellNotEqual || previousCols.some((col) => !deepEqual(cellToFilter[col], cell[col]))
            );
        });

        setValue('llpaCells', newCells.length
            ? newCells
            : getDefaultLlpaMatrixFormCells(colProperties.concat(rowProperty || [])));

        setValidationErrors(validationErrors => validationErrors.filter(
            ({ name }) => name !== `llpaCells.${cellIndex}.${property}`
        ));
    }

    const investorOptions = useInvestorOptions();
    const llpaLoanPropertyEnumDisplays = useLlpaLoanPropertyEnumDisplays();
    const displayEnum = llpaLoanPropertyEnumDisplays[property];
    const formatter = isFormattedProperty(property) ? loanPropertyEnumNumericFormatters[property] : undefined;
    const formattedValue = isEditing ? null : !value ? '--' : typeof value === 'string'
        ? formatNumericMatrixEntry(parseNumericMatrixEntry(value)!, formatter)
        : !value.length
            ? '--'
            : value.map((val) => (
                <div key={val}>
                    {property === LLPAOnlyLoanProperty.INVESTOR
                        ? investorOptions?.[val]
                        : displayEnum?.[val as keyof typeof displayEnum]}
                </div>
            ));

    const CellEditor = loanPropertyCellEditors[property];

    return (
        <ActionsCell
            component="th"
            ref={cellRef}
            rowSpan={rowSpan}
            isEditing={isEditing}
            showActions={!!moreActionsAnchorEl || isEditing}
            className={clsx(matrixStyles.loanPropertyValueCell, {
                [matrixStyles.hideBottomBorder]: hideBottomBorder
            })}
            actions={!readOnly && (
                isEditing ? (
                    <IconButton
                        size="small"
                        tooltip="Remove cell"
                        onMouseDown={removeCell}
                    >
                        <RemoveCircleOutline
                            color="error"
                            fontSize="small"
                        />
                    </IconButton>
                ) : (
                    <>
                        <IconButton
                            size="small"
                            tooltip="Edit cell"
                            onClick={() => {
                                setEditingManuallyTriggered(true);
                                setIsEditing(true);
                            }}
                        >
                            <Edit
                                color="secondary"
                                fontSize="small"
                            />
                        </IconButton>

                        <IconButton
                            size="small"
                            onClick={() => insertCell('after')}
                            disabled={disableInsert}
                            tooltip={tooltipTitle({
                                'Please fill all empty values in the matrix to add more cells': disableInsert,
                                [`Insert cell ${variant === 'column' ? 'below' : 'after'}`]: true
                            })}
                        >
                            <AddCircleOutline
                                color="success"
                                fontSize="small"
                            />
                        </IconButton>

                        <IconButton
                            size="small"
                            tooltip="More actions"
                            onClick={(event) => setMoreActionsAnchorEl(event.currentTarget)}
                        >
                            <MoreVert
                                color="action"
                                fontSize="small"
                            />
                        </IconButton>
                    </>
                )
            )}
        >
            {!isEditing ? formattedValue : (
                <CellEditor
                    property={property}
                    cellIndex={cellIndex}
                    updateValue={updateValue}
                    colProperties={colProperties}
                    autoFocus={editingManuallyTriggered || (variant === 'row' && !isOnlyRow)}
                />
            )}

            <Menu
                open={!!moreActionsAnchorEl}
                onClose={() => setMoreActionsAnchorEl(undefined)}
                anchorEl={moreActionsAnchorEl}
                anchorOrigin={{
                    horizontal: 'right',
                    vertical: 'bottom'
                }}
                transformOrigin={{
                    horizontal: 'right',
                    vertical: 'top'
                }}
            >
                <MenuItem
                    onClick={() => {
                        removeCell();
                        setMoreActionsAnchorEl(undefined);
                    }}
                >
                    Remove cell
                </MenuItem>

                <Tooltip
                    title={tooltipTitle({
                        'Please fill all empty values in the matrix to add more cells': disableInsert
                    })}
                >
                    <span>
                        <MenuItem
                            disabled={disableInsert}
                            onClick={() => {
                                insertCell('before');
                                setMoreActionsAnchorEl(undefined);
                            }}
                        >
                            Insert cell {variant === 'column' ? 'above' : 'before'}
                        </MenuItem>
                    </span>
                </Tooltip>
            </Menu>
        </ActionsCell>
    );
}

function formatPercent(value: number | undefined) {
    return !value ? '' : `${value.toFixed(2)}%`;
}

const loanPropertyEnumNumericFormatters = {
    [LoanProperty.LTV]: formatPercent,
    [LoanProperty.CLTV]: formatPercent,
    [LoanProperty.DTI]: formatPercent,
    [LoanProperty.LOAN_AMOUNT]: formatCurrency
} as const;

type FormattedLoanProperty = keyof typeof loanPropertyEnumNumericFormatters;

function isFormattedProperty(loanProperty: LLPAMatrixLoanProperty): loanProperty is FormattedLoanProperty {
    return Object.keys(loanPropertyEnumNumericFormatters).includes(loanProperty);
}

interface MatrixCellEditorProps {
    property: LLPAMatrixLoanProperty;
    cellIndex: number;
    updateValue: (newValue: string | string[] | undefined) => void;
    colProperties: LLPAMatrixLoanProperty[];
    autoFocus: boolean;
}

const { value: numericMatrixRegex, message: numericMatrixPatternError } = numericMatrixEntryValidationRules.pattern;

function NumericMatrixCellEditor({
    property, cellIndex, updateValue, colProperties, autoFocus
}: MatrixCellEditorProps) {
    const formCell = useWatch<MatrixStepFormValues, `llpaCells.${number}`>({
        name: `llpaCells.${cellIndex}`
    });

    const [ value, setValue ] = useState(formCell[property] as string | undefined);
    const [ error, setError ] = useState<string>();

    const updateValidationErrors = useUpdateValidationErrors();

    const fieldName: FieldPath<MatrixStepFormValues> = `llpaCells.${cellIndex}.${property}`;

    useEffect(() => {
        validate(value, true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Empty array ensures this runs only on component mount

    function validate(valueToValidate: typeof value, suppressError?: boolean) {
        const isPopulated = valueToValidate?.length;
        const isValid = isPopulated && !!valueToValidate?.match(numericMatrixRegex);

        if (!suppressError) {
            setError(!isPopulated
                ? 'This field is required'
                : !isValid ? numericMatrixPatternError : undefined);
        }

        updateValidationErrors(!!isValid, fieldName, !isPopulated
            ? `Please enter a valid value for ${loanPropertyDisplay[property]}`
            : numericMatrixPatternError);

        return isValid;
    }

    const firstEmptyColProperty = colProperties.find(colProperty => !formCell[colProperty]?.length);

    return (
        <TextField
            value={value}
            size="small"
            variant="standard"
            autoFocus={autoFocus || firstEmptyColProperty === property}
            required
            error={!!error}
            helperText={error}
            onChange={(event) => {
                const { value } = event.target;
                setValue(value);

                if (error) {
                    validate(value);
                }
            }}
            onFocus={(event) => {
                event.target.select();
            }}
            onBlur={() => {
                if (validate(value)) {
                    updateValue(value);
                }
            }}
        />
    );
}

function EnumMatrixCellEditor(props: MatrixCellEditorProps) {
    const llpaLoanPropertyEnumDisplays = useLlpaLoanPropertyEnumDisplays();

    return (
        <AutocompleteMatrixCellEditor
            options={llpaLoanPropertyEnumDisplays[props.property]}
            {...props}
        />
    );
}

function InvestorMatrixCellEditor(props: MatrixCellEditorProps) {
    const options = useInvestorOptions();

    return (
        <AutocompleteMatrixCellEditor
            options={options}
            {...props}
        />
    );
}

export function useInvestorOptions() {
    const { investors } = useContext(LLPARoutesContext);

    return useMemo(() => (
        !investors ? null : Object.fromEntries(
            investors.map((investor) => (
                [ investor.id, `${investor.name} (${investor.code})` ]
            ))
        )
    ), [ investors ]);
}

interface AutocompleteMatrixCellEditorProps extends MatrixCellEditorProps {
    options: null | {
        [key: string]: string;
    };
}

function AutocompleteMatrixCellEditor({
    property, cellIndex, updateValue, options, colProperties, autoFocus
}: AutocompleteMatrixCellEditorProps) {
    const cells = useWatch<MatrixStepFormValues, 'llpaCells'>({
        name: 'llpaCells'
    });

    const formCell = useWatch<MatrixStepFormValues, `llpaCells.${number}`>({
        name: `llpaCells.${cellIndex}`
    });

    const fieldName: FieldPath<MatrixStepFormValues> = `llpaCells.${cellIndex}.${property}`;
    const formValue = formCell[property] as string[] | undefined;

    const [ value, setValue ] = useState(formValue || []);
    const [ error, setError ] = useState<string>();

    const updateValidationErrors = useUpdateValidationErrors();

    function validate(valueToValidate: typeof value, suppressError?: boolean) {
        const isValid = valueToValidate.length;

        if (!suppressError) {
            setError(isValid ? undefined : 'This field is required');
        }

        updateValidationErrors(!!isValid, fieldName, `Please enter a value for ${loanPropertyDisplay[property]}`);

        return isValid;
    }

    // if the value is empty on the first render, add a validation error for the field,
    // so it cannot be submitted, but do not show the error unless the user submits
    useEffect(() => {
        validate(value, true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Empty array ensures this runs only on component mount

    const firstEmptyColProperty = colProperties.find(colProperty => !formCell[colProperty]?.length);

    return !options ? null : (
        <Autocomplete<string, true>
            value={value}
            options={Object.keys(options)}
            getOptionLabel={(value) => options[value as keyof typeof options]}
            size="small"
            multiple
            fullWidth={false}
            filterSelectedOptions
            noOptionsText="All options have been selected"
            openOnFocus
            disableCloseOnSelect
            classes={{ inputRoot: matrixStyles.autocompleteInputRoot }}
            onChange={(_, value) => {
                setValue(value);

                if (error) {
                    validate(value);
                }
            }}
            onBlur={() => {
                if (validate(value)) {
                    updateValue(value);
                }
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    fullWidth={false}
                    variant="standard"
                    autoFocus={autoFocus || (cells.length > 1 && firstEmptyColProperty === property)}
                    error={!!error}
                    helperText={error}
                    required
                />
            )}
        />
    );
}

function useUpdateValidationErrors() {
    const { setValidationErrors } = useContext(MatrixStepFormContext);

    return (isValid: boolean, fieldName: FieldPath<MatrixStepFormValues>, message: string) => {
        if (!isValid) {
            setValidationErrors(validationErrors => {
                const existingError = validationErrors.find(({ name }) => name === fieldName);

                const validationError = {
                    name: fieldName,
                    message
                };

                return existingError
                    ? replaceItemByKey(validationErrors, validationError, 'name')
                    : [ ...validationErrors, validationError ];
            });
        } else {
            setValidationErrors(validationErrors => validationErrors.filter(({ name }) => name !== fieldName));
        }
    };
}

const loanPropertyCellEditors = {
    [LoanProperty.LOAN_TYPE]: EnumMatrixCellEditor,
    [LoanProperty.OCCUPANCY]: EnumMatrixCellEditor,
    [LoanProperty.PURPOSE]: EnumMatrixCellEditor,
    [LoanProperty.AMORT_TYPE]: EnumMatrixCellEditor,
    [LoanProperty.PROPERTY_TYPE]: EnumMatrixCellEditor,
    [LoanProperty.LOAN_LIMIT_TYPE]: EnumMatrixCellEditor,
    [LoanProperty.UNITS]: NumericMatrixCellEditor,
    [LoanProperty.FICO]: NumericMatrixCellEditor,
    [LoanProperty.LTV]: NumericMatrixCellEditor,
    [LoanProperty.CLTV]: NumericMatrixCellEditor,
    [LoanProperty.DTI]: NumericMatrixCellEditor,
    [LoanProperty.TERM]: NumericMatrixCellEditor,
    [LoanProperty.AUS]: EnumMatrixCellEditor,
    [LoanProperty.RESERVES_MONTHS]: NumericMatrixCellEditor,
    [LoanProperty.HIGH_BALANCE]: EnumMatrixCellEditor,
    [LoanProperty.ESCROWS]: EnumMatrixCellEditor,
    [LoanProperty.SUBORDINATE_FINANCING]: EnumMatrixCellEditor,
    [LoanProperty.IRRL]: EnumMatrixCellEditor,
    [LoanProperty.STREAMLINE]: EnumMatrixCellEditor,
    [LoanProperty.FIRST_TIME_HOMEBUYER]: EnumMatrixCellEditor,
    [LoanProperty.NON_QM]: EnumMatrixCellEditor,
    [LoanProperty.SPECIALTY_PROGRAM]: EnumMatrixCellEditor,
    [LoanProperty.STATE]: EnumMatrixCellEditor,
    [LoanProperty.LOAN_AMOUNT]: NumericMatrixCellEditor,
    [LLPAOnlyLoanProperty.INVESTOR]: InvestorMatrixCellEditor,
    [LoanProperty.IRRL]: EnumMatrixCellEditor,
    [LoanProperty.STREAMLINE]: EnumMatrixCellEditor,
    [LoanProperty.FIRST_TIME_HOMEBUYER]: EnumMatrixCellEditor,
    [LoanProperty.NON_QM]: EnumMatrixCellEditor
};
