import { CustomerPendingSteps, customerPendingStepsDisplay } from '@api';
import { Tooltip, TooltipProps } from '@mui/material';
import clsx from 'clsx';

import StatusChecklistItem from './StatusChecklistItem';
import styles from './StatusTooltip.module.scss';


interface StatusTooltipProps {
    pendingSteps: CustomerPendingSteps[];
    children: TooltipProps['children'];
}

export default function StatusTooltip({ pendingSteps, children }: StatusTooltipProps) {
    return (
        <Tooltip
            classes={{ tooltip: clsx(styles.paperTooltip, styles.checklistTooltip) }}
            enterDelay={0}
            title={pendingSteps.map((step) => (
                <StatusChecklistItem
                    label={customerPendingStepsDisplay[step]}
                    key={step}
                />
            ))}
        >
            {children}
        </Tooltip>
    );
}
