import { rest } from 'msw';

import {
    FeeAmountPercentageOf,
    FeeAmountType,
    FeeType,
    PurchaseAdviceConfig,
    PurchaseAdviceFee,
    RoundingMethod
} from '..';
import { getMockUrl } from '../../mocks/getMockUrl';


let purchaseAdviceConfigID = 2;
let purchaseAdviceFeeID = 4;

export const mocks = [
    rest.get(getMockUrl('/client/:clientId/purchaseAdvice'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(purchaseAdviceConfig)
    ))),
    rest.post(getMockUrl('/client/:clientId/purchaseAdvice'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json({
            ...req.body as PurchaseAdviceConfig,
            id: purchaseAdviceConfigID++
        })
    ))),
    rest.put(getMockUrl('/client/:clientId/purchaseAdvice'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(req.body)
    ))),
    rest.get(getMockUrl('/client/:clientId/purchaseAdvice/purchaseAdviceFee'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(purchaseAdviceFees)
    ))),
    rest.post(getMockUrl('/client/:clientId/purchaseAdvice/purchaseAdviceFee'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json({
            ...req.body as PurchaseAdviceFee,
            id: purchaseAdviceFeeID++
        })
    ))),
    rest.put(getMockUrl('/client/:clientId/purchaseAdvice/purchaseAdviceFee/:feeID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(req.body)
    ))),
    rest.delete(getMockUrl('/client/:clientId/purchaseAdvice/purchaseAdviceFee/:feeID'), (req, res, ctx) => (res(
        ctx.status(200)
    )))
];

const purchaseAdviceConfig: PurchaseAdviceConfig = {
    id: 1,
    interestRatePrecision: 3,
    roundingMethod: RoundingMethod.ROUND_DOWN,
    cutoffDay: 20
};

const purchaseAdviceFees: PurchaseAdviceFee[] = [
    {
        id: '1',
        type: FeeType.ADMINISTRATION_FEE,
        amountType: FeeAmountType.FIXED,
        amount: 300
    },
    {
        id: '2',
        type: FeeType.COMPLIANCE_FEE,
        amountType: FeeAmountType.FIXED,
        amount: 304
    },
    {
        id: '3',
        type: FeeType.LENDER_FEE,
        amountType: FeeAmountType.VARIABLE,
        minAmount: 200,
        maxAmount: 1000,
        percentage: 0.02184,
        percentageOf: FeeAmountPercentageOf.BASE_LOAN_AMOUNT
    },
    {
        id: '4',
        type: FeeType.LOCK_PURCHASE_EXTENSION_FEE,
        amountType: FeeAmountType.FIXED,
        amount: 301
    }
];
