import api, {
    BulkCommitment, CustomerSummary, PermissionType, Product
} from '@api';
import { Edit, Lock, Schedule } from '@mui/icons-material';
import {
    Checkbox, FormControlLabel, MenuItem, Button as MuiButton,
    Link as MuiLink, Paper, Popover, TextField, Tooltip, Typography
} from '@mui/material';
import { getItemById } from '@tsp-ui/core';
import {
    Button, CardTable, ExpandableHeader, FilterTextField, IconButton, IconTypography, RoutedDialogManager
} from '@tsp-ui/core/components';
import {
    formatCurrency, formatCurrencyAbbreviation, useAsyncEffect, usePageMessage
} from '@tsp-ui/core/utils';
import { useGetCurrentAccount, useHasPermission } from '@utils/hooks';
import { withAuth } from '@utils/withAuth';
import BulkCommitmentDialog from '@views/admin/bulk-commitment/components/BulkCommitmentDialog';
import Page from '@views/components/Page';
import clsx from 'clsx';
import {
    formatDistanceToNowStrict, isAfter, isBefore, parseISO
} from 'date-fns';
import {
    Dispatch, SetStateAction, createContext, useCallback, useContext, useMemo, useState
} from 'react';
import { Link } from 'react-router-dom';

import styles from './BulkCommitmentManagementPage.module.scss';


export interface BulkCommitmentsContextValue {
    bulkCommitments: BulkCommitment[];
    setBulkCommitments: Dispatch<SetStateAction<BulkCommitment[]>>;
    customers: CustomerSummary[];
    products: Product[];
    loading: boolean;
}

export const BulkCommitmentsContext = createContext<BulkCommitmentsContextValue>({
    bulkCommitments: [],
    setBulkCommitments: () => {},
    loading: false,
    customers: [],
    products: []
});

export default function BulkCommitmentManagementPage() {
    const pageMessage = usePageMessage();
    const { id: clientId } = useGetCurrentAccount();

    const [ loading, setLoading ] = useState(true);
    const [ bulkCommitments, setBulkCommitments ] = useState<BulkCommitment[]>([]);
    const [ customers, setCustomers ] = useState<CustomerSummary[]>([]);
    const [ products, setProducts ] = useState<Product[]>([]);

    const [ canManage ] = useHasPermission([ PermissionType.MANAGE_BULK_COMMITMENT ]);

    useAsyncEffect(useCallback(async () => {
        setLoading(true);

        try {
            const [
                bulkCommitments, customers, products
            ] = await Promise.all([
                api.bulkCommitment.getCommitments(clientId),
                api.customer.getCustomers(clientId),
                api.products.getAllProducts(clientId)
            ]);

            setBulkCommitments(bulkCommitments);
            setCustomers(customers);
            setProducts(products);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching bulk commitments', error);
        }

        setLoading(false);
    }, [ pageMessage, clientId ]));

    const [ selectedCustomerId, setSelectedCustomerId ] = useState('ALL');
    const [ filterText, setFilterText ] = useState('');
    const [ showPastDeliveries, setShowPastDeliveries ] = useState(false);

    const commitmentsByCustomerId = bulkCommitments
        ?.filter(({ id, deliveryExpiration }) => (
            id.includes(filterText) && (
                showPastDeliveries || isAfter(new Date(deliveryExpiration), new Date())
            )
        ))
        .reduce((value, commitment) => {
            if (selectedCustomerId === 'ALL' || commitment.customerId === selectedCustomerId) {
                value[commitment.customerId] = [
                    ...(value[commitment.customerId] || []),
                    commitment
                ];
            }

            return value;
        }, {} as { [customerId: string]: BulkCommitment[]});

    const contextValue = useMemo<BulkCommitmentsContextValue>(() => ({
        loading,
        bulkCommitments,
        setBulkCommitments,
        customers,
        products
    }), [
        loading, bulkCommitments, customers, products
    ]);

    return (
        <BulkCommitmentsContext.Provider value={contextValue}>
            <Page
                header="Bulk Commitment Management"
                loading={loading}
                headerActions={(
                    <>
                        <MuiButton
                            component={Link}
                            to="../../tracking/bulk-commitments"
                        >
                            View bulk commitment tracking
                        </MuiButton>

                        <Button
                            component={Link}
                            to="add"
                            variant="contained"
                            tooltip={canManage ? '' : 'You do not have permission to add bulk commitments'}
                            disabled={!canManage}
                        >
                            Add Commitment
                        </Button>
                    </>
                )}
            >
                <div className={styles.filters}>
                    <TextField
                        select
                        variant="standard"
                        label="Customer"
                        value={selectedCustomerId}
                        onChange={(event) => setSelectedCustomerId(event.target.value)}
                    >
                        <MenuItem value="ALL">
                            -- All --
                        </MenuItem>

                        {customers?.map(({ id, name }) => (
                            <MenuItem
                                key={id}
                                value={id}
                            >
                                {name}
                            </MenuItem>
                        ))}
                    </TextField>

                    <FilterTextField
                        autoFocus
                        placeholder="Filter commitments"
                        helperText="Filter by commitment id"
                        value={filterText}
                        onChange={(event) => setFilterText(event.target.value)}
                    />

                    <FormControlLabel
                        className={styles.showInactive}
                        control={<Checkbox onChange={() => setShowPastDeliveries(!showPastDeliveries)} />}
                        label="Show past deliveries"
                    />
                </div>

                {Object.entries(commitmentsByCustomerId).length ? (
                    <div className={styles.sections}>
                        {Object.entries(commitmentsByCustomerId).map(([ customerId, commitments ]) => (
                            <ExpandableHeader
                                key={selectedCustomerId + customerId}
                                defaultExpand={selectedCustomerId === customerId}
                                disableExpand={false}
                                title={getItemById(customers, customerId)?.name}
                                secondaryText={`${commitments.length} commitment${commitments.length === 1 ? '' : 's'}`}
                                expandedContent={(
                                    <div className={styles.commitmentContainer}>
                                        {commitments.map((commitment) => (
                                            <BulkCommitmentCard
                                                key={commitment.id}
                                                commitment={commitment}
                                            />
                                        ))}
                                    </div>
                                )}
                            />
                        ))}
                    </div>
                ) : (
                    <Typography variant="body2">
                        No bulk commitments exist{selectedCustomerId === 'ALL' ? '' : ' for this customer'}.

                        {' '}

                        {canManage && (
                            <>
                                <MuiLink
                                    component={Link}
                                    to={`add${selectedCustomerId !== 'ALL' ? `?customerId=${selectedCustomerId}` : ''}`}
                                >
                                    Click here to add a new one
                                </MuiLink>.
                            </>
                        )}
                    </Typography>
                )}

                <RoutedDialogManager routes={dialogRoutes} />
            </Page>
        </BulkCommitmentsContext.Provider>
    );
}

const dialogRoutes = {
    add: withAuth(BulkCommitmentDialog, [ PermissionType.MANAGE_BULK_COMMITMENT ], true),
    ':commitmentID': withAuth(BulkCommitmentDialog, [ PermissionType.MANAGE_BULK_COMMITMENT ], true)
};

interface BulkCommitmentCardProps {
    commitment: BulkCommitment;
}

function BulkCommitmentCard({ commitment }: BulkCommitmentCardProps) {
    const [ anchorEl, setAnchorEl ] = useState<HTMLElement>();
    const { products } = useContext(BulkCommitmentsContext);
    const [ canManage ] = useHasPermission([ PermissionType.MANAGE_BULK_COMMITMENT ]);

    return (
        <Paper
            variant="outlined"
            className={clsx(styles.card, {
                [styles.expired]: isBefore(parseISO(commitment.deliveryExpiration), new Date())
            })}
        >
            <div className={styles.header}>
                <MuiLink
                    component={Link}
                    to={`../../tracking/bulk-commitments/${commitment.id}`}
                >
                    {commitment.id}
                </MuiLink>

                <div className={styles.chips}>
                    <Tooltip
                        title={(
                            <>
                                <div>Trade amount</div>

                                <div>{formatCurrency(commitment.tradeAmount)}</div>
                            </>
                        )}
                    >
                        <Typography
                            variant="body2"
                            className={styles.chip}
                        >
                            {formatCurrencyAbbreviation(commitment.tradeAmount)}
                        </Typography>
                    </Tooltip>

                    <Tooltip title="Trade incentive">
                        <Typography
                            variant="body2"
                            className={styles.chip}
                        >
                            {commitment.tradeIncentive.toFixed(5)}
                        </Typography>
                    </Tooltip>

                    <Tooltip title="Trade variance">
                        <Typography
                            variant="body2"
                            className={styles.chip}
                        >
                            {(commitment.tradeVariance * 100).toFixed(2)}%
                        </Typography>
                    </Tooltip>
                </div>

                <IconButton
                    tooltip={canManage ? 'Edit commitment' : 'You do not have permission to edit bulk commitments'}
                    component={Link}
                    to={`${commitment.id}`}
                    className={styles.iconButton}
                    disabled={!canManage}
                >
                    <Edit color="secondary" />
                </IconButton>
            </div>

            <div className={styles.datesRow}>
                <IconTypography
                    fontWeight={400}
                    variant="body2"
                    icon={(
                        <Tooltip title="Trade lock date">
                            <Lock
                                color="primary"
                                fontSize="small"
                            />
                        </Tooltip>
                    )}
                >
                    {formatDistanceToNowStrict(parseISO(commitment.lockDate), {
                        addSuffix: true
                    })}
                </IconTypography>

                <IconTypography
                    fontWeight={400}
                    variant="body2"
                    icon={(
                        <Tooltip title="Delivery expiration">
                            <Schedule
                                color="primary"
                                fontSize="small"
                            />
                        </Tooltip>
                    )}
                >
                    {formatDistanceToNowStrict(parseISO(commitment.deliveryExpiration), {
                        addSuffix: true
                    })}
                </IconTypography>

                <MuiButton
                    size="small"
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                >
                    View tiers ({commitment.pricingTiers.length})
                </MuiButton>
            </div>

            <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(undefined)}
                anchorOrigin={{
                    horizontal: 'left',
                    vertical: 'bottom'
                }}
                transformOrigin={{
                    horizontal: 'left',
                    vertical: 'top'
                }}
            >
                <CardTable headers={tableHeaders}>
                    {commitment.pricingTiers.map((tier) => (
                        <tr key={`${tier.productId}${tier.noteRate}`}>
                            <Typography
                                component="td"
                                variant="body2"
                            >
                                {tier.productId ? getItemById(products, tier.productId)?.productDescription || 'Product not found' : 'All products'}
                            </Typography>

                            <Typography
                                component="td"
                                variant="body2"
                            >
                                {tier.noteRate ? `${(tier.noteRate * 100).toFixed(3)}%` : 'All note rates'}
                            </Typography>

                            <Typography
                                component="td"
                                variant="body2"
                            >
                                {tier.price.toFixed(5)}
                            </Typography>

                            <Typography
                                component="td"
                                variant="body2"
                            >
                                {tier.subLimit === undefined ? '--' : formatCurrencyAbbreviation(tier.subLimit)}
                            </Typography>
                        </tr>
                    ))}
                </CardTable>
            </Popover>
        </Paper>
    );
}

const tableHeaders = [
    'Product',
    'Note Rate',
    'Price',
    'Limit'
];
