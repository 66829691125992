import { rest } from 'msw';

import { getMockUrl } from '../../mocks/getMockUrl';

import { LOSLoanStatus, LoanStatusConfig } from './loan-status-config-api';


let loanStatusConfigId = 0;

export const mocks = [
    rest.get(getMockUrl('/client/:clientId/loan-status/config'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.delay(100),
        ctx.json(loanStatusConfigs)
    )),
    rest.post(getMockUrl('/client/:clientId/loan-status/config'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.delay(100),
        ctx.json({
            ...req.body as LoanStatusConfig,
            id: `${loanStatusConfigId++}`
        })
    )),
    rest.put(getMockUrl('/client/:clientId/loan-status/config/:configId'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.delay(100),
        ctx.json(req.body)
    )),
    rest.get(getMockUrl('/client/:clientId/loan-status'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.delay(100),
        ctx.json(losLoanStatuses)
    )),
    rest.delete(getMockUrl('/client/:clientId/loan-status/config/:configId'), (req, res, ctx) => res(
        ctx.status(204),
        ctx.delay(100)
    ))
];

export const loanStatusConfigs: LoanStatusConfig[] = [
    {
        id: loanStatusConfigId++,
        title: 'Started',
        losLoanStatusIds: [ '1' ],
        displayOrder: 1
    },
    {
        id: loanStatusConfigId++,
        title: 'Processing',
        losLoanStatusIds: [ '2' ],
        displayOrder: 2
    },
    {
        id: loanStatusConfigId++,
        title: 'Submittal',
        losLoanStatusIds: [ '3' ],
        displayOrder: 3
    },
    {
        id: loanStatusConfigId++,
        title: 'Approval',
        losLoanStatusIds: [ '4' ],
        displayOrder: 4
    }
];

export const losLoanStatuses: LOSLoanStatus[] = [
    {
        id: '1',
        name: 'Started'
    },
    {
        id: '2',
        name: 'Processing'
    },
    {
        id: '3',
        name: 'Submittal'
    },
    {
        id: '4',
        name: 'Approval'
    },
    {
        id: '5',
        name: 'LOS Loan Status 5'
    },
    {
        id: '6',
        name: 'LOS Loan Status 6'
    }
];
