import api, { PermissionType } from '@api';
import {
    Button, DialogContent, Tooltip, Typography
} from '@mui/material';
import {
    DialogActions, DragTarget, RoutedDialog, RoutedDialogProps, usePageMessage
} from '@tsp-ui/core';
import { useHasPermission } from '@utils/hooks/useHasPermission';
import { shouldIgnoreEvent } from '@views/admin/reference-guides/ClientReferenceGuideManagementPage';
import { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import tinycolor from 'tinycolor2';

import { ClientDetailsContext } from '../../ClientDetailPage';

import { ColorPicker } from './ColorPicker';
import styles from './EditClientThemeDialog.module.scss';

// Brightness threshholds are on a scale of 0-255
// We limit the maximum brightness to 200 to ensure sufficient contrast with light text / background
const MAX_BRIGHTNESS = 200;
// We limit minimum brightness to 80 to ensure sufficient contrast with primary-dark and primary-very-dark variations
const MIN_BRIGHTNESS = 80;

export function EditClientThemeDialog(props: Omit<RoutedDialogProps, 'title' | 'children' | 'onSubmit'>) {
    const { client, updateClient } = useContext(ClientDetailsContext);

    const [ primaryColor, setPrimaryColor ] = useState(client?.primaryColor || '');
    const [ secondaryColor, setSecondaryColor ] = useState(client?.secondaryColor || '');
    const [ newLogo, setNewLogo ] = useState<File>();
    const navigate = useNavigate();

    const pageMessage = usePageMessage();
    const [ canEditClient ] = useHasPermission([ PermissionType.EDIT_CLIENTS ]);

    async function handleSubmit() {
        if (tinycolor(primaryColor).getBrightness() > MAX_BRIGHTNESS) {
            pageMessage.error('Please choose a darker primary color');
            return;
        }

        if (tinycolor(primaryColor).getBrightness() < MIN_BRIGHTNESS) {
            pageMessage.error('Please choose a lighter primary color');
            return;
        }

        if (tinycolor(secondaryColor).getBrightness() > MAX_BRIGHTNESS) {
            pageMessage.error('Please choose a darker secondary color');
            return;
        }

        if (client) {
            try {
                const updatedClient = await api.client.updateClient({
                    ...client,
                    primaryColor,
                    secondaryColor
                });

                updateClient(updatedClient);
            } catch (error)  {
                pageMessage.handleApiError('An error occurred while updating the client theme', error);
            }

            if (newLogo) {
                try {
                    const updatedClient = await api.client.updateClientLogo(client.id, newLogo);

                    updateClient(updatedClient);
                } catch (error) {
                    pageMessage.handleApiError('An error occurred while updating the client logo', error);
                }
            }

            navigate(props.closeTo);
        }
    }

    return (
        <RoutedDialog
            {...props}
            title="Edit Client Theme"
        >
            <DialogContent>
                <LogoInput
                    onChange={setNewLogo}
                    logoUrl={newLogo ? URL.createObjectURL(newLogo) : client?.logoUrl || ''}
                    isPreview={!!newLogo}
                />

                <ColorPicker
                    color={primaryColor}
                    label="Primary color"
                    setColor={setPrimaryColor}
                    hasPermission={canEditClient}
                    noPermissionMessage="You do not have permission to edit this client's primary color"
                />

                <ColorPicker
                    color={secondaryColor}
                    label="Secondary color"
                    setColor={setSecondaryColor}
                    hasPermission={canEditClient}
                    noPermissionMessage="You do not have permission to edit this client's secondary color"
                />
            </DialogContent>

            <DialogActions>
                <Button
                    component={Link}
                    to={props.closeTo}
                >
                    Cancel
                </Button>

                <Button
                    onClick={handleSubmit}
                    variant="contained"
                >
                    Save
                </Button>
            </DialogActions>
        </RoutedDialog>
    );
}

interface LogoInputProps {
    onChange: (file: File) => void;
    logoUrl: string;
    isPreview?: boolean;
}

function LogoInput({ onChange, logoUrl, isPreview }: LogoInputProps) {
    const [ canEditLogo ] = useHasPermission(
        [ PermissionType.EDIT_CLIENT_LOGO ]
    );

    return (
        <DragTarget
            onDrop={event => onChange(event.dataTransfer.files[0])}
            hasPermission={canEditLogo}
            validateDragEvent={(event) => !shouldIgnoreEvent(event)}
        >
            <div className={styles.logoPreviewContainer}>
                <Typography variant="subtitle2">
                    Logo {isPreview && '(Preview)'}
                </Typography>

                <Tooltip title={!canEditLogo ? "You do not have permission to edit this client's logo" : ''}>
                    <img
                        src={logoUrl}
                        alt="Logo"
                        className={styles.logoPreview}
                    />
                </Tooltip>

                {canEditLogo && (
                    <label
                        htmlFor="logo-input"
                        className={styles.label}
                    >
                        <input
                            id="logo-input"
                            className={styles.hidden}
                            type="file"
                            accept=".jpg,.jpeg,.png,.svg"
                            onChange={event => {
                                const file = event.target.files?.[0];
                                if (file) {
                                    onChange(file);
                                }
                            }}
                        />

                        <Typography
                            variant="caption"
                            color="textSecondary"
                            whiteSpace="nowrap"
                        >
                            Drag files here or
                        </Typography>

                        <Button
                            component="span"
                            size="small"
                        >
                            browse files
                        </Button>
                    </label>
                )}
            </div>
        </DragTarget>
    );
}
