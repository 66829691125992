import { EligibilityVersion } from '@api';
import { KeyboardArrowDown } from '@mui/icons-material';
import {
    Button, Popover, Skeleton, Tooltip, Typography
} from '@mui/material';
import clsx from 'clsx';
import {
    isFuture, isPast, isToday, parseISO
} from 'date-fns';
import React, { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import styles from './EligibilityVersionButton.module.scss';
import EligibilityVersionsCard
    from './EligibilityVersionsCard';


interface EligibilityVersionButtonProps {
    eligibilityVersions: EligibilityVersion[] | undefined;
}

export function EligibilityVersionButton({ eligibilityVersions }: EligibilityVersionButtonProps) {
    const selectedVersionId = useEligibilityVersionIdQueryParam();
    const [ anchorEl, setAnchorEl ] = useState<HTMLElement>();

    function isCurrentVersion({ effectiveDate, expirationDate }: EligibilityVersion) {
        const effective = parseISO(effectiveDate);
        return (isPast(effective) || isToday(effective)) && (!expirationDate || isFuture(parseISO(expirationDate)));
    }

    const selectedVersion = eligibilityVersions?.find((version) => (
        !selectedVersionId ? isCurrentVersion(version) : version.id === selectedVersionId
    ));

    const isSelectionCurrent = !selectedVersion || isCurrentVersion(selectedVersion);
    const isSelectionExpired = selectedVersion?.expirationDate && isPast(parseISO(selectedVersion.expirationDate));
    const isSelectionFuture = selectedVersion && isFuture(parseISO(selectedVersion.effectiveDate));

    const selectedVersionDisplay = `Version ${(eligibilityVersions?.indexOf(selectedVersion!) || 0) + 1}`;

    return (
        <>
            {!eligibilityVersions ? (
                <Skeleton
                    height={34}
                    width={200}
                />
            ) : (
                <>
                    <div
                        className={clsx(styles.versionChip, {
                            [styles.active]: isSelectionCurrent,
                            [styles.pending]: isSelectionFuture,
                            [styles.expired]: isSelectionExpired
                        })}
                    >
                        {isSelectionFuture ? 'Future' : isSelectionCurrent ? 'Current' : 'Expired'}

                        {' '}version
                    </div>

                    <Tooltip title="Click to view other eligibility versions">
                        <Button
                            onClick={(event) => setAnchorEl(event.currentTarget)}
                            endIcon={(
                                <KeyboardArrowDown color="primary" />
                            )}
                        >
                            {selectedVersionDisplay}
                        </Button>
                    </Tooltip>
                </>
            )}

            <Popover
                open={!!anchorEl}
                onClose={() => setAnchorEl(undefined)}
                anchorEl={anchorEl}
                anchorOrigin={{
                    horizontal: 'right',
                    vertical: 'bottom'
                }}
                transformOrigin={{
                    horizontal: 'right',
                    vertical: 'top'
                }}
                classes={{
                    paper: styles.popoverPaper
                }}
            >
                <Typography variant="h6">
                    Eligibility versions
                </Typography>

                <EligibilityVersionsCard
                    versions={eligibilityVersions}
                    selectedVersionId={selectedVersion?.id || selectedVersionId}
                    onVersionClick={() => setAnchorEl(undefined)}
                />

                <Button className={styles.button}>
                    Manage eligibility versions
                </Button>
            </Popover>
        </>
    );
}

export function useEligibilityVersionIdQueryParam() {
    const { search } = useLocation();

    return useMemo(() => {
        const params = new URLSearchParams(search);
        return params.get('eligibilityVersionId') || undefined;
    }, [ search ]);
}
