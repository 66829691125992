export enum LoanLimitType {
    CONFORMING = 'CONFORMING',
    HIGH_BALANCE = 'HIGH_BALANCE',
    JUMBO = 'JUMBO',
}

export const loanLimitTypeDisplay = {
    [LoanLimitType.CONFORMING]: 'Conforming',
    [LoanLimitType.HIGH_BALANCE]: 'High Balance',
    [LoanLimitType.JUMBO]: 'Jumbo'
};
