import api, { CustomerAlertConfig, alertTypeConfigDisplay } from '@api';
import {
    Button, DialogContent
} from '@mui/material';
import {
    DialogActions, RoutedDialog, RoutedDialogProps, TextField, renderEnumOptions, replaceItemById, usePageMessage,
    useParams
} from '@tsp-ui/core';
import { useGetCurrentAccount } from '@utils';
import { CustomerAlertConfigContext } from '@views/admin/customer-alert-config/CustomerAlertConfigPage';
import {
    Dispatch, SetStateAction, useContext, useState
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import styles from './CustomerAlertConfigDialog.module.scss';


interface CustomerAlertConfigDialogParams {
    alertConfigID: string;
}

const formID = 'customer-alert-config-form';

export default function CustomerAlertConfigDialog(props: Omit<RoutedDialogProps, 'title' | 'children'>) {
    const { alertConfigID } = useParams<CustomerAlertConfigDialogParams>();
    const { customerAlertConfigs, loading } = useContext(CustomerAlertConfigContext);
    const [ saveLoading, setSaveLoading ] = useState(false);

    const alertConfig = customerAlertConfigs?.find(({ id }) => id === alertConfigID);

    return (
        <RoutedDialog
            {...props}
            title={`${!alertConfigID ? 'Add' : 'Edit'} alert`}
            maxWidth="xs"
            saveLoading={saveLoading}
        >
            <DialogContent>
                {(alertConfig || !loading) && (
                    <CustomerAlertConfigForm
                        alertConfigToEdit={alertConfig}
                        setLoading={setSaveLoading}
                    />
                )}
            </DialogContent>

            <DialogActions loading={loading}>
                <Button
                    disabled={saveLoading}
                    type="submit"
                    variant="contained"
                    form={formID}
                >
                    Save
                </Button>
            </DialogActions>
        </RoutedDialog>
    );
}

interface CustomerAlertConfigFormProps {
    alertConfigToEdit: CustomerAlertConfig | undefined;
    setLoading: Dispatch<SetStateAction<boolean>>;
}

function CustomerAlertConfigForm({ alertConfigToEdit, setLoading }: CustomerAlertConfigFormProps) {
    const pageMessage = usePageMessage();
    const navigate = useNavigate();
    const { id: clientId } = useGetCurrentAccount();

    const { setCustomerAlertConfigs } = useContext(CustomerAlertConfigContext);

    const formMethods = useForm<CustomerAlertConfig>({
        defaultValues: alertConfigToEdit
    });

    const handleSubmit = formMethods.handleSubmit(async (formValues) => {
        setLoading(true);

        try {
            const updatedAlertConfig = alertConfigToEdit
                ? await api.client.customerAlertConfigs.editCustomerAlertConfig(clientId, formValues)
                : await api.client.customerAlertConfigs.addCustomerAlertConfig(clientId, formValues);

            setCustomerAlertConfigs(customerAlertConfigs => (alertConfigToEdit
                ? replaceItemById(customerAlertConfigs, updatedAlertConfig)
                : [ ...customerAlertConfigs, updatedAlertConfig ]));

            navigate('..');
            pageMessage.success(`Customer alert configuration ${alertConfigToEdit ? 'updated' : 'saved'}`);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving the customer alert configuration', error);
        }

        setLoading(false);
    });

    return (
        <form
            noValidate
            id={formID}
            onSubmit={handleSubmit}
            className={styles.form}
        >
            <FormProvider {...formMethods}>
                <TextField<CustomerAlertConfig>
                    name="type"
                    label="Alert type"
                    select
                    required
                >
                    {renderEnumOptions(alertTypeConfigDisplay)}
                </TextField>

                <TextField<CustomerAlertConfig>
                    name="duration"
                    label="Duration (days)"
                    type="number"
                    maxLength={20}
                    required
                />
            </FormProvider>
        </form>
    );
}
