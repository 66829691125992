import { LLPA } from '@api';
import { Paper, Typography } from '@mui/material';
import { SubtleLink } from '@tsp-ui/core/components';
import { useLocation } from 'react-router-dom';

import styles from './LLPACard.module.scss';


interface LLPACardProps {
    llpa: LLPA;
}

export function LLPACard({
    llpa: {
        id, name, description, versions
    }
}: LLPACardProps) {
    const { search } = useLocation();

    return (
        <Paper
            elevation={0}
            className={styles.root}
        >
            <div>
                <SubtleLink to={id + search}>
                    {name}
                </SubtleLink>

                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                    {description}
                </Typography>
            </div>

            <Typography
                variant="button"
                color="primary"
                className={styles.numVersions}
            >
                {`${versions.length} ${versions.length === 1 ? 'version' : 'versions'}`}
            </Typography>
        </Paper>
    );
}
