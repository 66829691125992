import { PermissionType } from '@api';
import { Button, Paper } from '@mui/material';
import { PaperSaveLoader } from '@tsp-ui/core/components';
import { useHasPermission } from '@utils/hooks';
import { useEligibilityVersionIdQueryParam } from '@views/admin/investors/components/EligibilityVersionButton';
import Page from '@views/components/Page';
import { useState } from 'react';
import { Link, Navigate, useLocation } from 'react-router-dom';

import styles from './ClientAddInvestorPage.module.scss';
import ClientInvestorForm from './components/ClientInvestorForm';


export function ClientAddInvestorPage() {
    const { search } = useLocation();
    const eligibilityVersionId = useEligibilityVersionIdQueryParam();

    const [ loading, setLoading ] = useState(false);
    const [ disableSubmit, setDisableSubmit ] = useState(true);

    const [ canManage ] = useHasPermission([ PermissionType.MANAGE_CLIENT_INVESTORS ]);

    return (!eligibilityVersionId || !canManage) ? (
        <Navigate to={`..${search}`} />
    ) : (
        <Page
            variant="centered"
            header="Add Investor"
        >
            <Paper className={styles.root}>
                <ClientInvestorForm
                    setLoading={setLoading}
                    setDisableSubmit={setDisableSubmit}
                />

                <div className={styles.buttons}>
                    <Button
                        component={Link}
                        replace
                        to={`..${search}`}
                    >
                        Cancel
                    </Button>

                    <Button
                        key="save"
                        form={ClientInvestorForm.formID}
                        variant="contained"
                        type="submit"
                        disabled={disableSubmit}
                    >
                        Next
                    </Button>
                </div>

                <PaperSaveLoader loading={loading} />
            </Paper>
        </Page>
    );
}
