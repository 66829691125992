import {
    Link as MuiLink, Tooltip, Typography, TypographyProps
} from '@mui/material';
import { useCreateAccountUrl } from '@utils/hooks';
import { MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';


interface LoanNumberLinkProps extends TypographyProps {
    loanNumber: string;
    onClick?: MouseEventHandler<HTMLAnchorElement>;
}

export default function LoanNumberLink({ loanNumber, onClick, ...props }: LoanNumberLinkProps) {
    const createAccountUrl = useCreateAccountUrl('accounts/:accountID/*');

    return (
        <Typography
            variant="caption"
            color="textSecondary"
            component="span"
            {...props}
        >
            Loan #

            <Tooltip title="Click to view loan details">
                <MuiLink
                    component={Link}
                    to={createAccountUrl(`loans/${loanNumber}/detail/all`)}
                    variant="inherit"
                    onClick={onClick}
                >
                    {loanNumber}
                </MuiLink>
            </Tooltip>
        </Typography>
    );
}
