import {
    Address, Contact, CustomerEligibleProducts, CustomerPendingSteps, CustomerStatus
} from '..';
import { apiUtils } from '../api-utils';

import { Branch } from './branches/branches-api';


export * as branches from './branches/branches-api';
export * as dueDiligenceStep from './due-diligence-step/due-diligence-step-api';
export * as agencyApproval from './agency-approval/agency-approval-api';
export * as miCompanyRelationship from './mi-company-relationship/mi-company-relationship-api';
export * as investorRelationship from './investor-relationship/investor-relationship-api';
export * as warehouseRelationship from './warehouse-relationship/warehouse-relationship-api';
export * as compensation from './compensation/compensation-api';
export * as contacts from './contacts/contacts-api';
export * as eventHistory from './eventHistory/eventHistory-api';
export * as licenses from './licenses/licenses-api';
export * as limits from './limits/limit-api';
export * as eligibleProducts from './eligibleProducts/eligibleProducts-api';
export * as wireInfo from './wireInfo/wireInfo-api';

export function getCustomers(clientID: string): Promise<CustomerSummary[]> {
    return apiUtils.get(`/client/${clientID}/customer`);
}

export function addCustomer(clientID: string, customer: CustomerBase): Promise<CustomerSummary> {
    return apiUtils.post(`/client/${clientID}/customer`, customer);
}

export function getCustomer(clientID: string, customerID: string): Promise<CustomerDetails> {
    return apiUtils.get(`/client/${clientID}/customer/${customerID}`);
}

export function updateCustomer(clientID: string, customer: CustomerBase): Promise<CustomerSummary> {
    return apiUtils.put(`/client/${clientID}/customer/${customer.id}`, customer);
}

export interface CustomerBase {
    id: string;
    name: string;
    status: CustomerStatus;
    dbaNames?: string[];
    nmlsId: string;
    taxId: string;
    address: Address;
}

export interface CustomerSummary extends CustomerBase {
    branches?: Branch[];
    pendingSteps?: CustomerPendingSteps[];
}

export interface CustomerDetails extends CustomerSummary, CustomerEligibleProducts {
    primaryContact: Contact;
}
