import api, { ReferenceGuideFile, ReferenceGuideUpload, isReferenceGuide } from '@api';
import { Button, DialogContent } from '@mui/material';
import { DialogActions, RoutedDialog, TextField } from '@tsp-ui/core/components';
import {
    replaceItemById, usePageMessage, useParams, useRouteState
} from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils/hooks';
import { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { ReferenceGuideManagementPageContext } from '../ClientReferenceGuideManagementPage';

import styles from './ReferenceGuideDialog.module.scss';


export interface ReferenceGuideDialogRouteState {
    referenceGuideUpload?: ReferenceGuideUpload;
    file: File;
}

export function ReferenceGuideDialog() {
    const [ loading, setLoading ] = useState(false);
    const {
        entities: referenceGuideSections, setEntities: setReferenceGuideSections
    } = useContext(ReferenceGuideManagementPageContext);
    const { referenceGuideID, referenceGuideSectionID } = useParams<{ referenceGuideSectionID: string }>();
    const { referenceGuideUpload, file } = useRouteState<ReferenceGuideDialogRouteState>();

    const defaultValues = referenceGuideUpload || referenceGuideSections
        .flatMap(({ files }) => files)
        .find(({ id }) => referenceGuideID === id);

    const { id: clientID } = useGetCurrentAccount();
    const navigate = useNavigate();
    const pageMessage = usePageMessage();
    const formMethods = useForm<ReferenceGuideFile | ReferenceGuideUpload>({ defaultValues });

    const handleSubmit = formMethods.handleSubmit(async (formValues: ReferenceGuideFile | ReferenceGuideUpload) => {
        setLoading(true);

        try {
            const isEdit = isReferenceGuide(formValues);

            const newReferenceGuide = isEdit
                ? await api.referenceGuides.updateReferenceGuide(clientID, formValues)
                : await api.referenceGuides.uploadReferenceGuide(clientID, formValues, referenceGuideSectionID, file);

            setReferenceGuideSections(referenceGuideSections.map(section => ({
                ...section,
                files: !isEdit && section.id === newReferenceGuide.sectionId
                    ? [ ...section.files, newReferenceGuide ]
                    : replaceItemById(section.files, newReferenceGuide)
            })));

            navigate('..');

            pageMessage.success('Reference guide section saved');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving the reference guide section', error);
        }

        setLoading(false);
    });

    useEffect(() => blur(document.activeElement), []); // blur element to allow autoFocus

    return (
        <RoutedDialog
            closeTo=".."
            title={`${isReferenceGuide(defaultValues) ? 'Edit' : 'Upload'} reference guide`}
        >
            <form onSubmit={handleSubmit}>
                <FormProvider {...formMethods}>
                    <DialogContent className={styles.root}>
                        <TextField<ReferenceGuideFile>
                            name="name"
                            label="File title"
                            required
                            autoFocus
                            onFocus={event => !isReferenceGuide(defaultValues) && event.target.select()}
                        />

                        <TextField<ReferenceGuideFile>
                            name="description"
                            label="File description"
                            required
                        />
                    </DialogContent>

                    <DialogActions loading={loading}>
                        <Button type="submit">
                            Submit
                        </Button>
                    </DialogActions>
                </FormProvider>
            </form>
        </RoutedDialog>
    );
}

function blur(element: Element | HTMLInputElement | null) {
    if (element && 'blur' in element) {
        element.name !== 'title' && element.blur();
    }
}
