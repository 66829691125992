import api, { ActiveInactiveEnum, AgencyApproval, PermissionType } from '@api';
import { Edit, RemoveCircleOutline, Save } from '@mui/icons-material';
import { Paper, Typography } from '@mui/material';
import { formatDate, replaceItemById, useParams } from '@tsp-ui/core';
import {
    IconButton, LabelGroup, LabeledValue, usePageMessage
} from '@tsp-ui/core/components';
import { tooltipTitle } from '@utils';
import { useGetCurrentAccount } from '@utils/hooks/useGetCurrentAccount';
import { useHasPermission } from '@utils/hooks/useHasPermission';
import { AdminRouteParams } from '@views/admin/components/AdminPageTemplate';
import { Dispatch, SetStateAction, useState } from 'react';

import styles from './AgencyApprovalCard.module.scss';
import { AgencyApprovalForm } from './AgencyApprovalForm';


interface AgencyApprovalCardProps {
    agencyApproval: AgencyApproval | undefined;
    setAdding?: Dispatch<SetStateAction<boolean>>;
    setAgencyApprovals: Dispatch<SetStateAction<AgencyApproval[]>>;
    locked?: boolean;
}

export function AgencyApprovalCard({
    agencyApproval, setAdding, setAgencyApprovals, locked
}: AgencyApprovalCardProps) {
    const [ editing, setEditing ] = useState(!agencyApproval);
    const [ deleting, setDeleting ] = useState(false);
    const [ saving, setSaving ] = useState(false);
    const pageMessage = usePageMessage();

    const { id: clientID, customerId } = useGetCurrentAccount();
    const { customerID } = useParams<AdminRouteParams<'customer'>>();

    const [ canManage ] = useHasPermission([ PermissionType.MANAGE_AGENCY_APPROVAL ]);

    async function handleDelete() {
        setDeleting(true);

        if (agencyApproval) {
            try {
                const deactivatedAgencyApproval = await api.customer.agencyApproval.softDeleteAgencyApproval(
                    clientID, customerId || customerID, agencyApproval
                );

                setAgencyApprovals(agencyApprovals => replaceItemById(agencyApprovals, deactivatedAgencyApproval));

                pageMessage.success('Agency approval deleted');
            } catch (error) {
                pageMessage.handleApiError('An error occurred while deleting the agency approval', error);
            }
        } else {
            setAdding?.(false);
        }

        setDeleting(false);
    }

    async function handleSubmit(formValues: AgencyApproval) {
        setSaving(true);

        try {
            const newAgencyApproval = agencyApproval
                ? await api.customer.agencyApproval.updateAgencyApproval(
                    clientID, customerId || customerID, formValues
                ) : await api.customer.agencyApproval.createAgencyApproval(
                    clientID, customerId || customerID, {
                        ...formValues,
                        status: ActiveInactiveEnum.ACTIVE
                    }
                );

            if (agencyApproval) {
                setAgencyApprovals(agencyApprovals => replaceItemById(agencyApprovals, newAgencyApproval));
                setEditing(false);
            } else {
                setAgencyApprovals(agencyApprovals => [ ...agencyApprovals, newAgencyApproval ]);
                setAdding?.(false);
            }

            pageMessage.success('Agency approval saved');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving the agency approval', error);
        }

        setSaving(false);
    }

    return (
        <Paper
            className={styles.root}
            variant="outlined"
        >
            <div className={styles.displayHeader}>
                <Typography>
                    {agencyApproval?.agency || 'New Agency Approval'}
                </Typography>

                <div>
                    {editing ? (
                        <IconButton
                            tooltip="Save agency approval"
                            form={`agency-approval-${agencyApproval?.id || 'new'}`}
                            type="submit"
                            loading={saving}
                        >
                            <Save color="success" />
                        </IconButton>
                    ) : (
                        <IconButton
                            tooltip={tooltipTitle({
                                'You must unlock this data request to make changes': locked,
                                'Edit agency approval': canManage,
                                'You do not have permission to edit agency approvals': !canManage
                            })}
                            onClick={() => setEditing(true)}
                            disabled={!canManage || locked}
                            key="edit"
                        >
                            <Edit color="primary" />
                        </IconButton>
                    )}

                    <IconButton
                        tooltip={tooltipTitle({
                            'You must unlock this data request to make changes': locked,
                            'Delete agency approval': canManage,
                            'You do not have permission to delete agency approvals': !canManage
                        })}
                        disabled={!canManage || locked}
                        loading={deleting}
                        onClick={handleDelete}
                        edge="end"
                    >
                        <RemoveCircleOutline color="error" />
                    </IconButton>
                </div>
            </div>

            {editing ? (
                <AgencyApprovalForm
                    defaultValues={agencyApproval}
                    handleSubmit={handleSubmit}
                />
            ) : (
                <LabelGroup>
                    <LabeledValue
                        label="First approval:"
                        value={agencyApproval?.firstApprovalDate ? formatDate(agencyApproval.firstApprovalDate) : 'N/A'}
                    />

                    <LabeledValue
                        label="Latest approval:"
                        value={agencyApproval?.latestApprovalDate ? formatDate(agencyApproval.latestApprovalDate) : 'N/A'}
                    />
                </LabelGroup>
            )}
        </Paper>
    );
}
