import api, { User, UserType } from '@api';
import { MenuItem, Tooltip } from '@mui/material';
import {
    PhoneField, Switch, TextField, isEmail
} from '@tsp-ui/core/components';
import { usePageMessage } from '@tsp-ui/core/utils';
import { useActingClientID, useGetCurrentAccount } from '@utils/hooks';
import { useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { AdminAddEditFormProps } from '../components/AdminAddPageTemplate';

import styles from './UserForm.module.scss';
import { RolesContext } from './UserManagementPage';


interface UserFormProps extends AdminAddEditFormProps<User> {
    customerId?: string;
    userType?: UserType;
}

export function UserForm({
    entityToEdit: defaultValues,
    setLoading,
    onSubmit,
    customerId,
    userType
}: UserFormProps) {
    const navigate = useNavigate();
    const pageMessage = usePageMessage();
    const clientID = useActingClientID();
    const { accountUserType } = useGetCurrentAccount();
    const { entities: roles } = useContext(RolesContext);

    const isEdit = !!defaultValues;
    const formMethods = useForm<User>({
        defaultValues: defaultValues || {
            type: userType || accountUserType,
            isActive: true
        }
    });

    const handleSubmit = formMethods.handleSubmit(async formData => {
        setLoading(true);

        try {
            onSubmit(defaultValues
                ? await api.users.editUser(formData, clientID, customerId)
                : await api.users.addUser(formData, clientID, customerId));

            navigate('..');

            pageMessage.success('User saved');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving the user', error);
        }

        setLoading(false);
    });

    return (
        <form
            noValidate
            id={UserForm.formID}
            onSubmit={handleSubmit}
            className={styles.root}
        >
            <FormProvider {...formMethods}>
                <TextField<User>
                    name="firstName"
                    label="First name"
                    required
                />

                <TextField<User>
                    name="lastName"
                    label="Last name"
                    required
                />

                <Tooltip title={isEdit ? 'Email can not be changed' : ''}>
                    <span>
                        <TextField<User>
                            name="email"
                            label="Email"
                            disabled={isEdit}
                            required={!isEdit}
                            rules={isEmail}
                        />
                    </span>
                </Tooltip>

                <PhoneField<User>
                    name="phone"
                    label="Phone"
                />

                <TextField<User>
                    name="roleIds"
                    label="User roles"
                    className={styles.fullWidth}
                    select
                    required
                    SelectProps={{
                        multiple: true
                    }}
                >
                    {roles.map(role => (
                        <MenuItem
                            value={role.id}
                            key={role.id}
                        >
                            {role.name}
                        </MenuItem>
                    ))}
                </TextField>

                <Switch<User>
                    className={styles.fullWidth}
                    name="isActive"
                    label="Enabled"
                />
            </FormProvider>
        </form>
    );
}

UserForm.formID = 'add-edit-user-form';
