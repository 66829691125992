import { apiUtils } from '../../api-utils';
import { ActiveInactiveEnum, Agency } from '../../index';


export function getAgencyApprovals(clientID: string, customerID: string): Promise<AgencyApproval[]> {
    return apiUtils.get(`/client/${clientID}/customer/${customerID}/agency-approval`);
}

export function createAgencyApproval(
    clientID: string, customerID: string, agencyApproval: AgencyApproval
): Promise<AgencyApproval> {
    return apiUtils.post(`/client/${clientID}/customer/${customerID}/agency-approval`, agencyApproval);
}

export function updateAgencyApproval(
    clientID: string, customerID: string, agencyApproval: AgencyApproval
): Promise<AgencyApproval> {
    return apiUtils.put(`/client/${clientID}/customer/${customerID}/agency-approval/${agencyApproval.id}`, agencyApproval);
}

export function softDeleteAgencyApproval(
    clientID: string, customerID: string, agencyApproval: AgencyApproval
): Promise<AgencyApproval> {
    return updateAgencyApproval(clientID, customerID, {
        ...agencyApproval,
        status: ActiveInactiveEnum.INACTIVE
    });
}

export interface AgencyApproval {
    id: string;
    agency: Agency;
    firstApprovalDate?: string;
    latestApprovalDate: string;
    status: ActiveInactiveEnum;
}
