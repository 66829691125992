import api, { PermissionType, ReferenceGuideSection } from '@api';
import { RemoveCircleOutline } from '@mui/icons-material';
import {
    DialogContent, Button as MuiButton, Typography
} from '@mui/material';
import {
    Button, DragTarget, IconButton, ProgressIndicator, RoutedDialog, RoutedDialogProps
} from '@tsp-ui/core/components';
import { removeItemById, useConfirm, usePageMessage } from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils/hooks';
import { useHasPermission } from '@utils/hooks/useHasPermission';
import {
    DragEvent, createContext, useCallback, useContext, useState
} from 'react';
import { Link, useNavigate } from 'react-router-dom';

import AdminPageTemplate, {
    AdminEntityGroup, AdminEntityGroupProps, AdminPageContextValues, defaultAdminPageContextValues
} from '../components/AdminPageTemplate';

import styles from './ClientReferenceGuideManagementPage.module.scss';
import CustomerReferenceGuideManagementPage from './CustomerReferenceGuideManagementPage';
import { ClientReferenceGuideCard } from './components/ClientReferenceGuideCard';
import { ReferenceGuideDialog, ReferenceGuideDialogRouteState } from './components/ReferenceGuideDialog';
import { ReferenceGuideSectionDialog } from './components/ReferenceGuideSectionDialog';


export const ReferenceGuideManagementPageContext = createContext<AdminPageContextValues<ReferenceGuideSection>>(
    defaultAdminPageContextValues
);

export default function ClientReferenceGuideManagementPage() {
    const [ canManageReferenceGuides ] = useHasPermission([ PermissionType.MANAGE_REFERENCE_GUIDES ]);
    const { id: clientID } = useGetCurrentAccount();
    const fetchReferenceGuideSections = useCallback(
        () => api.referenceGuides.getReferenceGuideSections(clientID), [ clientID ]
    );

    return (
        <AdminPageTemplate
            entityName="reference guide"
            Context={ReferenceGuideManagementPageContext}
            labelOverrides={{
                addButton: 'Add section',
                filterPlaceholder: 'Filter files',
                header: 'Reference guide management'
            }}
            disableAddEntity={!canManageReferenceGuides}
            disabledAddTooltip="You do not have permission to add reference guide sections"
            headerActions={(
                <MuiButton
                    component={Link}
                    to="customer-preview"
                >
                    Show customer preview
                </MuiButton>
            )}
            EntityGroupComponent={ReferenceGuideGroup}
            fetchEntities={fetchReferenceGuideSections}
            filterByLabel="file name"
            filterEntities={(entities, filterInputValue) => (
                entities.map(section => ({
                    ...section,
                    files: section.files.filter(({ name }) => (
                        name.toLocaleLowerCase().includes(filterInputValue)
                    ))
                }))
            )}
            dialogRoutes={routes}
            autoFocusFilter={false}
            getVisibleGroups={(referenceGuides) => referenceGuides.map(referenceGuide => referenceGuide.id)}
        />
    );
}

const routes = {
    new: ReferenceGuideSectionDialog,
    ':referenceGuideSectionID/:referenceGuideID/edit': ReferenceGuideDialog,
    ':referenceGuideSectionID/new': ReferenceGuideDialog,
    'customer-preview': (props: Omit<RoutedDialogProps, 'title'>) => (
        <RoutedDialog
            {...props}
            maxWidth="lg"
            title="Reference guides - customer view"
        >
            <DialogContent>
                <CustomerReferenceGuideManagementPage className={styles.dialogPage} />
            </DialogContent>
        </RoutedDialog>
    )
};

function ReferenceGuideGroup({
    group: referenceGuideSectionID,
    entities: referenceGuideSections,
    filterText
}: AdminEntityGroupProps<ReferenceGuideSection, string>) {
    const [ isDeleting, setIsDeleting ] = useState(false);

    const { files, title } = referenceGuideSections.find(({ id }) => id === referenceGuideSectionID) || {};

    const { id: clientID } = useGetCurrentAccount();
    const pageMessage = usePageMessage();
    const navigate = useNavigate();
    const confirm = useConfirm();

    const { setEntities: setReferenceGuideSections } = useContext(ReferenceGuideManagementPageContext);

    const [ canManageReferenceGuides ] = useHasPermission([ PermissionType.MANAGE_REFERENCE_GUIDES ]);

    function addFile(fileList: FileList | null) {
        if (!fileList || fileList.length === 0) {
            return;
        } else if (fileList.length > 1) {
            pageMessage.error('Please add only one file at a time');
            return;
        }

        navigate(`${referenceGuideSectionID}/new`, {
            state: {
                referenceGuideUpload: {
                    sectionId: referenceGuideSectionID,
                    name: fileList[0].name
                },
                file: fileList[0]
            } as ReferenceGuideDialogRouteState
        });
    }

    async function onDelete() {
        try {
            if (await confirm(
                'This action will delete all reference guides that belong to this section.'
                + ' Are you sure you want to continue?'
            )) {
                setIsDeleting(true);

                await api.referenceGuides.deleteReferenceGuideSection(
                    clientID, referenceGuideSectionID
                );

                setReferenceGuideSections(referenceGuideSections => removeItemById(
                    referenceGuideSections, referenceGuideSectionID
                ));

                pageMessage.success('Section deleted');
            }
        } catch (error) {
            pageMessage.handleApiError('An error occurred while deleting the reference guide section', error);
        } finally {
            setIsDeleting(false);
        }
    }

    return (
        <DragTarget
            hasPermission={canManageReferenceGuides}
            onDrop={(event) => addFile(event.dataTransfer.files)}
            validateDragEvent={(event) => !shouldIgnoreEvent(event)}
        >
            <AdminEntityGroup
                classes={{ header: styles.groupHeader }}
                noResultsMessage={filterText ? 'No reference guides match your filter text' : (
                    <Typography component="span">
                        No reference guides uploaded yet.

                        <Button
                            size="small"
                            disabled={!canManageReferenceGuides}
                            tooltip={!canManageReferenceGuides ? 'You do not have permission to upload files' : ''}
                        >
                            browse files
                        </Button>
                        to get started.
                    </Typography>
                )}
                header={(
                    <>
                        {title}

                        {isDeleting ? <ProgressIndicator className={styles.progress} /> : (
                            <IconButton
                                tooltip={canManageReferenceGuides ? 'Delete section' : 'You do not have permission to delete sections'}
                                edge="start"
                                disabled={!canManageReferenceGuides}
                                className={styles.removeGroupButton}
                                onClick={onDelete}
                            >
                                <RemoveCircleOutline color="error" />
                            </IconButton>
                        )}
                    </>
                )}
            >
                {!!files?.length && files.map(file => (
                    <ClientReferenceGuideCard
                        key={file.id}
                        referenceGuideFile={file}
                    />
                ))}

                {canManageReferenceGuides ? (
                    <label
                        htmlFor={`file-upload-${referenceGuideSectionID}`}
                        className={styles.label}
                    >
                        <input
                            id={`file-upload-${referenceGuideSectionID}`}
                            className={styles.hidden}
                            type="file"
                            onChange={e => addFile(e.target.files)}
                        />

                        <Typography
                            variant="caption"
                            color="textSecondary"
                            whiteSpace="nowrap"
                            alignSelf="center"
                        >
                            Drag files here or
                        </Typography>

                        <MuiButton
                            component="span"
                            size="small"
                        >
                            browse files
                        </MuiButton>
                    </label>
                ) : (
                    <Typography
                        variant="caption"
                        color="textSecondary"
                        whiteSpace="nowrap"
                        alignSelf="center"
                    >
                        You do not have permission to upload files
                    </Typography>
                )}
            </AdminEntityGroup>
        </DragTarget>
    );
}

function isElement(target: EventTarget | null): target is Element {
    return !!target && 'className' in target && 'contains' in target;
}

function isParent(parent: Element, child: Element) {
    return parent.className.includes?.('DragTarget_dropZone') && parent.contains(child);
}

export function shouldIgnoreEvent({ target, relatedTarget, type }: DragEvent<HTMLDivElement>) {
    if (isElement(target) && isElement(relatedTarget)) {
        const eventIsFromChild = isParent(target, relatedTarget) || isParent(relatedTarget, target);
        return type === 'dragleave' && eventIsFromChild;
    }
}
