import api, {
    ConditionCategoryAssociation,
    LOS,
    LOSConfiguration,
    LoanFolder,
    conditionCategoryDisplay,
    losDisplay,
    premicorrConditionCategoryDisplay
} from '@api';
import { MenuItem, Typography } from '@mui/material';
import {
    TextField, renderEnumOptions, useForm, usePageMessage, useParams
} from '@tsp-ui/core';
import clsx from 'clsx';
import {
    Dispatch, SetStateAction, useContext, useState
} from 'react';
import { FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { ClientDetailsContext } from '../../ClientDetailPage';

import styles from './LOSConfigForm.module.scss';


interface LOSConfigFormProps {
    activeStep: number;
    lastStep: number;
    setActiveStep: Dispatch<SetStateAction<number>>;
    setSaveLoading: Dispatch<SetStateAction<boolean>>;
}

interface LOSConfigFormValues {
    losConfig: LOSConfiguration;
    conditionCategories: ConditionCategoryAssociation[] | undefined;
}

export function LOSConfigForm({
    activeStep, lastStep, setActiveStep, setSaveLoading
}: LOSConfigFormProps) {
    const pageMessage = usePageMessage();
    const navigate = useNavigate();
    const { clientID } = useParams<{ clientID: string }>();
    const { losConfig, setLOSConfig } = useContext(ClientDetailsContext);
    const [ loanFolders, setLoanFolders ] = useState<LoanFolder[] | undefined>();
    const { conditionCategories, setConditionCategories } = useContext(ClientDetailsContext);

    const formMethods = useForm<LOSConfigFormValues>({
        defaultValues: {
            losConfig,
            conditionCategories
        }
    });
    const { setValue } = formMethods;

    const los = formMethods.watch('losConfig.los');

    const handleSubmit = formMethods.handleSubmit(async (formValues) => {
        setSaveLoading(true);

        try {
            setLOSConfig(await api.client.losConfig.setLOSConfiguration(clientID, formValues.losConfig));

            if (activeStep < lastStep) {
                setActiveStep(activeStep + 1);

                if (los === LOS.ENCOMPASS) {
                    if (process.env.NODE_ENV === 'development') {
                        await new Promise(resolve => setTimeout(resolve, 300));
                    }
                    setLoanFolders(await api.client.losConfig.getLoanFolders(clientID));
                }
            } else if (activeStep === lastStep) {
                const { conditionCategories } = formValues;

                if (conditionCategories) {
                    setConditionCategories(await api.client.losConfig.updateMappedConditionCategories(
                        clientID,
                        conditionCategories
                    ));
                }
            }

            if (activeStep === lastStep) {
                navigate('..');
                pageMessage.success('LOS configuration saved');
            }
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving the LOS configuration', error);
        }

        setSaveLoading(false);
    });

    return (
        <form
            noValidate
            id={LOSConfigForm.formID}
            onSubmit={handleSubmit}
            className={clsx(
                {
                    [styles.categoryForm]: activeStep === 2,
                    [styles.noSupport]: los === LOS.MCP && activeStep > 0
                },
                activeStep !== lastStep && styles.root
            )}
        >
            <FormProvider {...formMethods}>
                {activeStep === 0 && (
                    <>
                        <TextField<LOSConfigFormValues>
                            name="losConfig.los"
                            label="LOS"
                            onChange={(event) => {
                                const newLosValue = event.target.value as LOS;

                                if (newLosValue === LOS.MCP) {
                                    setValue('losConfig.instanceId', undefined);
                                    setValue('losConfig.defaultLoanFolder', undefined);
                                } else if (newLosValue === LOS.ENCOMPASS) {
                                    setValue('losConfig.userName', undefined);
                                    setValue('losConfig.password', undefined);
                                    setValue('losConfig.instanceId', losConfig?.instanceId);
                                    setValue('losConfig.defaultLoanFolder', losConfig?.defaultLoanFolder);
                                }
                            }}
                            required
                            select
                        >
                            {renderEnumOptions(losDisplay)}
                        </TextField>

                        <TextField<LOSConfigFormValues>
                            name="losConfig.apiUrl"
                            label="Api URL"
                            required
                        />

                        <TextField<LOSConfigFormValues>
                            name="losConfig.clientId"
                            label="Client ID"
                            required
                        />

                        <TextField<LOSConfigFormValues>
                            name="losConfig.clientSecret"
                            label="Client Secret"
                            required
                        />

                        {los === LOS.ENCOMPASS && (
                            <TextField<LOSConfigFormValues>
                                name="losConfig.instanceId"
                                label="Instance ID"
                                required
                            />
                        )}

                        {los === LOS.MCP && (
                            <>
                                <TextField<LOSConfigFormValues>
                                    name="losConfig.userName"
                                    label="Username"
                                    required
                                />

                                <TextField<LOSConfigFormValues>
                                    name="losConfig.password"
                                    label="Password"
                                    required
                                />
                            </>
                        )}
                    </>
                )}

                {activeStep === 1 && (
                    los === LOS.ENCOMPASS
                        ? (
                            <TextField<LOSConfigFormValues>
                                name="losConfig.defaultLoanFolder"
                                label="Loan Folder"
                                select
                            >
                                {loanFolders && loanFolders.map((folder) => (
                                    <MenuItem
                                        key={folder.folderName}
                                        value={folder.folderName}
                                    >
                                        {folder.folderName}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )
                        : (
                            <Typography>
                                This LOS does not support selectable loan folders
                            </Typography>
                        )
                )}

                {activeStep === lastStep && (
                    los === LOS.ENCOMPASS
                        ? (
                            <div className={styles.gridContainer}>
                                {conditionCategories && conditionCategories.map((category, index) => (
                                    <div className={styles.categoryContainer}>
                                        <Typography variant="body1">
                                            {premicorrConditionCategoryDisplay[category.premicorrConditionCategory]}:
                                        </Typography>

                                        <TextField<LOSConfigFormValues>
                                            className={styles.categoryDropdown}
                                            name={`conditionCategories.${index}.losConditionCategory`}
                                            label={`${losDisplay[losConfig!.los]} category`}
                                            select
                                            required
                                            hideRequiredIndicator
                                        >
                                            {renderEnumOptions(conditionCategoryDisplay)}
                                        </TextField>
                                    </div>
                                ))}
                            </div>
                        )
                        : (
                            <Typography>
                                This LOS does not support condition category mapping
                            </Typography>
                        )
                )}
            </FormProvider>
        </form>
    );
}

LOSConfigForm.formID = 'los-config-form';
