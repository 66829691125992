import { CheckCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import { IconButtonProps } from '@mui/material';
import { IconButton, ProgressIndicator } from '@tsp-ui/core/components';
import { ComponentProps } from 'react';

import styles from './ActivateDeactivateButton.module.scss';


interface ActivateDeactivateButtonProps {
    loading: boolean;
    isActive: boolean;
    toggleActive: IconButtonProps['onClick'];
    disabled?: boolean;
    tooltip?: ComponentProps<typeof IconButton>['tooltip'];
}

export function ActivateDeactivateButton({
    loading, isActive, toggleActive, disabled, tooltip
}: ActivateDeactivateButtonProps) {
    return (
        <IconButton
            onClick={toggleActive}
            tooltip={loading
                ? 'Loading...'
                : tooltip || (isActive ? 'Deactivate' : 'Activate')}
            disabled={loading || disabled}
        >
            {loading ? (
                <div>
                    <ProgressIndicator className={styles.progress} />
                </div>
            ) : isActive ? (
                <RemoveCircleOutline color="error" />
            ) : (
                <CheckCircleOutline color="success" />
            )}
        </IconButton>
    );
}
