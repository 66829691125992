import { apiUtils } from '../api-utils';
import { PricingFieldName } from '../enums/pricingFieldName';


export function getFieldConfigs(clientID: string): Promise<FieldConfig[]>  {
    return apiUtils.get(`/client/${clientID}/field-config`);
}

export function updateFieldConfig(
    clientID: string, fieldConfig: FieldConfig[]
): Promise<FieldConfig[]>  {
    return apiUtils.put(`/client/${clientID}/field-config`, fieldConfig);
}

export interface FieldConfig {
    id: string;
    fieldName: PricingFieldName;
    enabled: boolean;
}
