import { AmortizationType, amortizationTypeDisplay } from '@api';
import { FormControl, FormLabel, Typography } from '@mui/material';
import {
    CurrencyField,
    PercentField,
    Radio,
    RadioGroup,
    Switch,
    TextField,
    isAWholeNumber,
    isAtLeast,
    isAtMost
} from '@tsp-ui/core/components';
import { useRenderTogglableEnumOptions } from '@utils';
import { ClientContext } from '@views/AuthenticatedRouteSwitch';
import clsx from 'clsx';
import { useContext } from 'react';
import { useWatch } from 'react-hook-form';

import { ManualLoanEntryFormValues } from '../ManualLoanEntryPage';

import styles from './LoanStep.module.scss';
import ManualLoanEntryStep, { ManualLoanEntryStepProps } from './ManualLoanEntryStep';


export default function LoanStep(props: Required<Pick<ManualLoanEntryStepProps, 'onPreviousButtonClick'>>) {
    const amorType = useWatch<ManualLoanEntryFormValues>({ name: 'amorType' });

    const renderTogglableEnumOptions = useRenderTogglableEnumOptions();
    const { configurableValues } = useContext(ClientContext);
    const allowFixed = configurableValues?.togglableValues.amortizationType.includes(AmortizationType.FIXED);
    const allowAdjustable = configurableValues?.togglableValues.amortizationType.includes(AmortizationType.ADJUSTABLE);

    return (
        <ManualLoanEntryStep {...props}>
            <div>
                <FormLabel required>
                    Loan details
                </FormLabel>

                <div className={styles.loanDetailsContainer}>
                    <TextField<ManualLoanEntryFormValues>
                        name="customerLoanNumber"
                        label="Loan number"
                        required
                        hideRequiredIndicator
                        maxLength={100}
                    />

                    <TextField<ManualLoanEntryFormValues>
                        name="universalLoanIdentifier"
                        label="Universal loan ID (optional)"
                        maxLength={50}
                    />

                    <CurrencyField<ManualLoanEntryFormValues>
                        name="loanAmount"
                        label="Total loan amount"
                        required
                        hideRequiredIndicator
                        rules={isAtLeast(1)}
                    />

                    <CurrencyField<ManualLoanEntryFormValues>
                        name="baseLoanAmount"
                        label="Base loan amount (optional)"
                        rules={isAtLeast(1)}
                    />

                    <TextField<ManualLoanEntryFormValues>
                        name="loanType"
                        label="Loan type"
                        className={styles.sectionStart}
                        required
                        hideRequiredIndicator
                        select
                        maxLength={100}
                    >
                        {renderTogglableEnumOptions('loanType')}
                    </TextField>

                    <TextField<ManualLoanEntryFormValues>
                        name="loanLimitType"
                        label="Loan limit type"
                        className={styles.sectionStart}
                        required
                        hideRequiredIndicator
                        select
                        maxLength={100}
                    >
                        {renderTogglableEnumOptions('loanLimitType')}
                    </TextField>

                    <TextField<ManualLoanEntryFormValues>
                        name="loanTerm"
                        label="Term (months)"
                        type="number"
                        required
                        hideRequiredIndicator
                        rules={{
                            ...isAtLeast(1),
                            ...isAWholeNumber
                        }}
                    />

                    <PercentField<ManualLoanEntryFormValues>
                        name="interestRate"
                        label="Interest rate"
                        required
                        hideRequiredIndicator
                        rules={isAtLeast(0)}
                    />

                    <TextField<ManualLoanEntryFormValues>
                        name="lockPeriod"
                        label="Lock period (days)"
                        select
                        required
                        hideRequiredIndicator
                    >
                        {renderTogglableEnumOptions('lockPeriod')}
                    </TextField>

                    <TextField<ManualLoanEntryFormValues>
                        name="loanFICO"
                        label="Loan FICO"
                        type="number"
                        required
                        hideRequiredIndicator
                        rules={{
                            ...isAtLeast(300),
                            ...isAtMost(850)
                        }}
                    />

                    <TextField<ManualLoanEntryFormValues>
                        name="dti"
                        label="Debt to income ratio"
                        type="number"
                        required
                        hideRequiredIndicator
                        rules={isAtLeast(0)}
                    />

                    <br />

                    <FormControl
                        component="fieldset"
                        className={styles.sectionStart}
                    >
                        <FormLabel
                            component="legend"
                            required
                        >
                            Amortization type
                        </FormLabel>

                        <RadioGroup<ManualLoanEntryFormValues>
                            name="amorType"
                            defaultValue={AmortizationType.FIXED}
                        >
                            {allowFixed && (
                                <Radio
                                    value={AmortizationType.FIXED}
                                    label={amortizationTypeDisplay[AmortizationType.FIXED]}
                                />
                            )}

                            {allowAdjustable && (
                                <Radio
                                    value={AmortizationType.ADJUSTABLE}
                                    label={amortizationTypeDisplay[AmortizationType.ADJUSTABLE]}
                                />
                            )}

                            {!allowFixed && !allowAdjustable && (
                                <Typography>
                                    No amortization types are enabled for this client.
                                </Typography>
                            )}
                        </RadioGroup>
                    </FormControl>

                    {amorType === AmortizationType.ADJUSTABLE ? (
                        <FormControl
                            className={styles.sectionStart}
                            component="fieldset"
                        >
                            <FormLabel
                                component="legend"
                                required
                            >
                                Amortization details
                            </FormLabel>

                            <div className={styles.amortizationDetailsFields}>
                                <CurrencyField<ManualLoanEntryFormValues>
                                    name="armMargin"
                                    label="Margin"
                                    size="small"
                                    required
                                    hideRequiredIndicator
                                    rules={isAtLeast(0)}
                                />

                                <CurrencyField<ManualLoanEntryFormValues>
                                    name="armInitialCap"
                                    label="Initial cap"
                                    size="small"
                                    required
                                    hideRequiredIndicator
                                    rules={isAtLeast(0)}
                                />

                                <CurrencyField<ManualLoanEntryFormValues>
                                    name="armSubsequentCaps"
                                    label="Subsequent cap"
                                    size="small"
                                    required
                                    hideRequiredIndicator
                                    rules={isAtLeast(0)}
                                />

                                <CurrencyField<ManualLoanEntryFormValues>
                                    name="armLifeCap"
                                    label="Life cap"
                                    size="small"
                                    required
                                    hideRequiredIndicator
                                    rules={isAtLeast(0)}
                                />
                            </div>
                        </FormControl>
                    ) : <div />}

                    <Switch<ManualLoanEntryFormValues>
                        name="escrowsFlag"
                        label="Taxes & insurance escrowed"
                        className={styles.fullWidth}
                    />

                    <Switch<ManualLoanEntryFormValues>
                        name="interestOnlyFlag"
                        label="Interest only"
                        className={clsx(styles.fullWidth, styles.interestSwitch)}
                    />
                </div>
            </div>
        </ManualLoanEntryStep>
    );
}
