import { ClientStatus } from 'api/enums/clientStatusEnum';

import {
    Address, Contact, ProductType
} from '..';
import { apiUtils } from '../api-utils';


export type { ClientInvestor, ClientLoanProgram } from './investor/clientInvestor-api';

export * as customerAlertConfigs from './customerAlertConfig/customerAlertConfig-api';
export * as contacts from './contacts/contacts-api';
export * as investors from './investor/clientInvestor-api';
export * as eligibilityVersions from './eligibility-version/client-eligibility-version-api';
export * as losConfig from './los-config/los-config-api';

export function getClients(): Promise<ClientDetail[]> {
    return apiUtils.get('/client');
}

export function getClient(clientID: string): Promise<ClientDetail> {
    return apiUtils.get(`/client/${clientID}`);
}

export function createClient(client: ClientDetail): Promise<ClientDetail> {
    return apiUtils.post('/client', client);
}

export function updateClient(client: ClientDetail): Promise<ClientDetail> {
    return apiUtils.put(`/client/${client.id}`, client);
}

export function updateClientLogo(clientID: string, file: File): Promise<ClientDetail> {
    const formData = new FormData();
    formData.append('file', file);

    return apiUtils.put(`/client/${clientID}/logo`, formData, { useAutoContentType: true });
}

export interface ClientDetail {
    id: string;
    nmlsId: string | null;
    legalName: string;
    displayName: string;
    code: string;
    isInternal: boolean;
    status: ClientStatus;
    address: Address;
    primaryContact?: Contact;
    allowInternalEdits?: boolean;
    primaryColor: string;
    secondaryColor: string;
    logoUrl: string;
}

export interface ClientProductConfig {
    availableLockPeriods: number[];
    availableProducts: AvailableProduct[];
}

export interface AvailableProduct {
    id: string;
    name: string;
    productType: ProductType;
}
