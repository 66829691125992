
import { apiUtils } from '../api-utils';
import { EligibilityGuideline } from '../investor/investor-api';


export function getExclusionOverlays(
    clientId: string, queryParams?: ExclusionOverlayQueryParams
): Promise<ExclusionOverlay[]> {
    return apiUtils.get(`/client/${clientId}/exclusion-overlay`, queryParams);
}

export function createExclusionOverlay(
    clientId: string, exclusionOverlay: Omit<ExclusionOverlay, 'id'>
): Promise<ExclusionOverlay> {
    return apiUtils.post(`/client/${clientId}/exclusion-overlay`, exclusionOverlay);
}

export function updateExclusionOverlay(
    clientId: string, exclusionOverlay: ExclusionOverlay
): Promise<ExclusionOverlay> {
    return apiUtils.put(`/client/${clientId}/exclusion-overlay/${exclusionOverlay.id}`, exclusionOverlay);
}

export enum ExclusionOverlayType {
    CLIENT = 'CLIENT',
    INVESTOR = 'INVESTOR',
    LOAN_PROGRAM = 'LOAN_PROGRAM'
}

interface ExclusionOverlayQueryParams {
    loanProgramId?: string;
    investorId?: string;
    effectiveVersionId?: string;
}

export interface ExclusionOverlay {
    id: string;
    type: ExclusionOverlayType;
    investorIds?: ['ALL'] | string[];
    loanProgramIds?: ['ALL'] | string[];
    name: string;
    description: string;
    effectiveDate: string;
    expirationDate?: string;
    exclusions: EligibilityGuideline[];
}
