import { ClientDetail } from '@api';
import { PhoneInTalk } from '@mui/icons-material';
import {
    IconButton, Paper, Popover, Tooltip, Typography
} from '@mui/material';
import { LabelGroup, LabeledValue, SubtleLink } from '@tsp-ui/core/components';
import { useCreateAccountUrl } from '@utils/hooks';
import { useState } from 'react';

import ContactDisplay from '../../customers/CustomerDetailPage/components/dialogs/ContactDisplay';

import styles from './ClientCard.module.scss';


interface ClientCardProps {
    client: ClientDetail;
}

export function ClientCard({
    client: {
        id, legalName, displayName, code, nmlsId, primaryContact
    }
}: ClientCardProps) {
    const [ anchorEl, setAnchorEl ] = useState<HTMLElement>();
    const createAccountUrl = useCreateAccountUrl();

    return (
        <Paper
            className={styles.root}
            elevation={0}
        >
            <div>
                <SubtleLink to={createAccountUrl(`admin/clients/${id}`)}>
                    {legalName}
                </SubtleLink>

                <Typography
                    variant="body2"
                    color="textSecondary"
                    component="span"
                    className={styles.code}
                >
                    ({code})
                </Typography>

                <Tooltip title="Short name">
                    <Typography
                        variant="body2"
                        color="textSecondary"
                    >
                        {displayName}
                    </Typography>
                </Tooltip>
            </div>

            <div className={styles.rightContainer}>
                <Tooltip
                    title="View contact info"
                    enterDelay={0}
                >
                    <IconButton
                        className={styles.contactButton}
                        onClick={(event) => {
                            event.preventDefault();
                            setAnchorEl(event.currentTarget);
                        }}
                    >
                        <PhoneInTalk color="secondary" />
                    </IconButton>
                </Tooltip>

                <LabelGroup className={styles.nmlsID}>
                    <LabeledValue
                        label="NMLS ID:"
                        value={nmlsId}
                    />
                </LabelGroup>
            </div>

            <Popover
                open={!!anchorEl}
                onClose={() => setAnchorEl(undefined)}
                anchorEl={anchorEl}
                anchorOrigin={{
                    horizontal: 'left',
                    vertical: 'bottom'
                }}
                transformOrigin={{
                    horizontal: 'left',
                    vertical: 'top'
                }}
                PaperProps={{
                    className: styles.contactMenu
                }}
            >
                <ContactDisplay contact={primaryContact} />
            </Popover>
        </Paper>
    );
}
