import api, { PermissionType, User } from '@api';
import { Edit, RemoveCircleOutline } from '@mui/icons-material';
import {
    Link as MuiLink, Paper, PaperProps, Typography
} from '@mui/material';
import {
    IconButton, LabelGroup, LabeledValue, PhoneTypography
} from '@tsp-ui/core/components';
import { getFullName, useConfirm, usePageMessage } from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils/hooks';
import { useDecodedToken } from '@utils/hooks/useDecodedToken';
import { useHasPermission } from '@utils/hooks/useHasPermission';
import {
    Dispatch, SetStateAction, useContext, useState
} from 'react';
import { Link } from 'react-router-dom';

import { isClientTokenPayload } from '../../../api/api-utils';

import styles from './UserCard.module.scss';
import { RolesContext } from './UserManagementPage';


interface UserCardProps {
    user: User;
    setUsers: Dispatch<SetStateAction<User[]>>;
    onEditClick?: (user: User) => void;
    readOnly?: boolean;
    variant?: PaperProps['variant'];
}

export function UserCard({
    user, setUsers, onEditClick, readOnly, variant
}: UserCardProps) {
    const {
        email, id: userID, phone, roleIds
    } = user;

    const { customerId, id: clientID } = useGetCurrentAccount();

    const confirm = useConfirm();
    const pageMessage = usePageMessage();

    const { entities: roles } = useContext(RolesContext);

    const tokenPayload = useDecodedToken();
    const currentUserId = isClientTokenPayload(tokenPayload) ? tokenPayload.user_id : tokenPayload.sub;
    const isMyUser = currentUserId === userID;
    const [ canEditUser, canDeleteUser ] = useHasPermission([ PermissionType.EDIT_USERS, PermissionType.DELETE_USERS ]);

    const [ deleteLoading, setDeleteLoading ] = useState(false);
    async function handleDelete() {
        if (await confirm('Are you sure you want to delete this user?')) {
            try {
                setDeleteLoading(true);
                await api.users.deleteUser(userID, clientID, customerId);

                setUsers((users) => users.filter(user => user.id !== userID));

                pageMessage.success('User deleted');
            } catch (error) {
                pageMessage.handleApiError('An error occurred while deleting the user', error);
            }

            setDeleteLoading(false);
        }
    }

    const editTooltip = canEditUser ? 'Edit user' : 'You do not have permission to edit users';

    return (
        <Paper
            className={styles.card}
            elevation={0}
            variant={variant}
        >
            <div>
                <Typography>
                    {getFullName(user)}
                </Typography>

                <MuiLink
                    variant="body2"
                    href={`mailto:${email}`}
                >
                    {email}
                </MuiLink>

                <PhoneTypography variant="body2">
                    {phone || ''}
                </PhoneTypography>

                <LabelGroup className={styles.roles}>
                    <LabeledValue
                        label="Roles:"
                        value={roleIds.map(roleID => (
                            roles.find(role => role.id === roleID)?.name
                        )).join(', ')}
                    />
                </LabelGroup>
            </div>

            {!readOnly && (
                <div className={styles.buttons}>
                    {onEditClick ? (
                        <IconButton
                            tooltip={editTooltip}
                            disabled={!canEditUser}
                            onClick={() => onEditClick(user)}
                        >
                            <Edit color="secondary" />
                        </IconButton>
                    ) : (
                        <IconButton
                            tooltip={editTooltip}
                            disabled={!canEditUser}
                            component={Link}
                            to={`${userID}/edit`}
                        >
                            <Edit color="secondary" />
                        </IconButton>
                    )}

                    <IconButton
                        tooltip={canDeleteUser ? (isMyUser ? 'Users cannot delete their account' : 'Delete user') : 'You do not have permission to delete users'}
                        disabled={!canDeleteUser || isMyUser}
                        edge="end"
                        onClick={handleDelete}
                        loading={deleteLoading}
                    >
                        <RemoveCircleOutline color="error" />
                    </IconButton>
                </div>
            )}
        </Paper>
    );
}
