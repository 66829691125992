import { apiUtils } from '../api-utils';


export function getAvailableDocTypes(clientId: string): Promise<DocumentType[]> {
    return apiUtils.get(`/client/${clientId}/document-type/available`);
}

export function getRequiredDocCodes(clientId: string): Promise<string[]> {
    return apiUtils.get(`/client/${clientId}/document-type/required`);
}

export function updateRequiredDocs(clientId: string, docCodes: string[]): Promise<string[]> {
    return apiUtils.put(`/client/${clientId}/document-type/required`, docCodes);
}

export interface DocumentType {
    name: string;
    code: string;
    containerLabel: string;
}
