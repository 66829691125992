import api, {
    CommitmentType, LoanPricingResult, LoanPricingResultDetail, LockPeriod,
    RegistrationType, commitmentTypeDisplay, registrationTypeDisplay
} from '@api';
import { Alert, Button, Typography } from '@mui/material';
import { PaginatedResponse } from '@tsp-ui/core';
import {
    ButtonBar, DateTypography, LabelGroup, LabeledValue, Loader
} from '@tsp-ui/core/components';
import { replaceItemByKey, useAsyncEffect, usePageMessage } from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils';
import { LoanCard } from '@views/loans/components/LoanCard';
import { addDays, parseISO } from 'date-fns';
import {
    Dispatch, SetStateAction, useCallback, useState
} from 'react';

import ExpiredButton from './ExpiredButton';
import styles from './LoanPricingResultCard.module.scss';
import PricingResultIneligibleProductsTable from './PricingResultIneligibleProductsTable';
import PricingResultProductsTable from './PricingResultProductsTable';
import RateSheetLink from './RateSheetLink';


interface LoanPricingResultCardProps {
    className?: string;
    loanPricingResult: LoanPricingResult;
    updateLoanPricingResults: Dispatch<SetStateAction<PaginatedResponse<LoanPricingResult>>>;
    updateFloatedLoans(): void;
    index: number;
}

// TODO post-demo we need a LoanResultCard as a base component for
// LoanPricingResultCard, LoanPipelineResultCard, and LoanDashboardResultCard
export default function LoanPricingResultCard(props: LoanPricingResultCardProps) {
    const {
        loanPricingResult, updateLoanPricingResults, updateFloatedLoans
    } = props;
    const pageMessage = usePageMessage();
    const { id: clientId, customerId } = useGetCurrentAccount();

    const [ loading, setLoading ] = useState(false);

    async function repriceLoan() {
        setLoading(true);

        try {
            const newPricingResult = await api.pricing.repriceLoan(clientId, loanId, customerId);

            updateLoanPricingResults(
                (loanPricingResults) => ({
                    ...loanPricingResults,
                    data: replaceItemByKey(loanPricingResults.data, newPricingResult, 'loanId')
                })
            );

            updateFloatedLoans();

            setLoading(false);

            pageMessage.success('Loan repriced');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while repricing the loan', error);

            setLoading(false);
        }
    }

    const {
        expirationDate, loanId, rateSheetId, pricedDate, id
    } = loanPricingResult;
    const isExpired = parseISO(expirationDate) < new Date();

    return (
        <LoanCard
            {...props}
            isPendingLoan
            expandedContent={(
                <LoanPricingResultDetails
                    loanId={loanId}
                    pricingResultId={id}
                    updateLoanPricingResults={updateLoanPricingResults}
                    updateFloatedLoans={updateFloatedLoans}
                    isExpired={isExpired}
                    loading={loading}
                    setLoading={setLoading}
                />
            )}
            additionalDetails={(
                <div className={styles.pricingDetails}>
                    {isExpired && (
                        <ExpiredButton
                            onReprice={repriceLoan}
                            expirationDate={expirationDate}
                        />
                    )}

                    <LabelGroup>
                        <LabeledValue
                            label="Rate sheet ID:"
                            value={<RateSheetLink rateSheetId={rateSheetId} />}
                        />

                        <LabeledValue
                            label="Priced at:"
                            value={(
                                <DateTypography
                                    time
                                    date={pricedDate}
                                    component="span"
                                    variant="body2"
                                />
                            )}
                        />
                    </LabelGroup>
                </div>
            )}
        />
    );
}

interface LoanPricingResultDetailsProps {
    loading: boolean;
    setLoading: Dispatch<SetStateAction<boolean>>;
    loanId: string;
    pricingResultId: string;
    updateLoanPricingResults: Dispatch<SetStateAction<PaginatedResponse<LoanPricingResult>>> | undefined;
    updateFloatedLoans: (() => void) | undefined;
    isExpired: boolean;
}

export function LoanPricingResultDetails({
    loading, setLoading, loanId, pricingResultId, updateLoanPricingResults, updateFloatedLoans, isExpired
}: LoanPricingResultDetailsProps) {
    const pageMessage = usePageMessage();
    const { id: clientId, customerId } = useGetCurrentAccount();

    const [ showIneligibleProducts, setShowIneligibleProducts ] = useState(false);
    const [ loanPricingResultDetail, setLoanPricingResultDetail ] = useState<LoanPricingResultDetail>();
    const [ commitmentType, setCommitmentType ] = useState(CommitmentType.BEST_EFFORT);
    const [ lockPeriod, setLockPeriod ] = useState<LockPeriod>();
    const [ showError, setShowError ] = useState(false);

    const lockExpirationDate = addDays(new Date(), lockPeriod || 0).toISOString();

    useAsyncEffect(useCallback(async () => {
        setLoading(true);

        try {
            const pricingDetail = await api.pricing.getPricingResultDetail(clientId, loanId, customerId);

            setLoanPricingResultDetail(pricingDetail);
            setLockPeriod(pricingDetail.defaultLockPeriod);
        } catch (error) {
            setShowError(true);
        }

        setLoading(false);
    }, [
        clientId, customerId, loanId, setLoading
    ]));

    const handleButtonBarChange = useCallback((newValue: string) => {
        if (newValue) {
            setLockPeriod(parseInt(newValue));
        }
    }, [ ]);

    const products = loanPricingResultDetail?.products.filter(
        product => product.commitmentType === commitmentType && product.lockPeriod === lockPeriod
    ) || [];

    const lockPeriodOptions = loanPricingResultDetail?.products.reduce((previousValue, currentValue) => {
        if (currentValue.commitmentType === commitmentType) {
            previousValue[`${currentValue.lockPeriod}`] = currentValue.lockPeriod;
        }

        return previousValue;
    }, {} as {[key: string]: LockPeriod}) || {};

    async function registerLoan(productID: string, registrationType: RegistrationType) {
        if (!lockPeriod) {
            return;
        }

        setLoading(true);

        try {
            // TODO make this return the Loan
            await api.loans.registerLoan(clientId, loanId, {
                pricingResultId,
                registrationType,
                productId: productID
            }, customerId);

            setLoading(false); // set loading before component unmounts

            updateLoanPricingResults?.((loanPricingResults) => ({
                ...loanPricingResults,
                data: loanPricingResults.data.filter(pricingResult => pricingResult.loanId !== loanId)
            }));

            if (registrationType === RegistrationType.FLOAT) {
                updateFloatedLoans?.();
            }

            pageMessage.success(`Loan ${registrationTypeDisplay[registrationType]}`);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while registering the loan', error);

            setLoading(false);
        }
    }

    // Can't use the loading prop to ExpandableCard because it unmounts LoanPricingResultDetails,
    // resulting in an infinite fetch loop
    return loading ? (
        <Loader
            className={styles.initialLoader}
            loading={loading}
        />
    ) : showError ? (
        <Alert
            severity="warning"
            className={styles.alert}
        >
            An error occurred while fetching product details
        </Alert>
    ) : (!lockPeriod || !loanPricingResultDetail) ? null : (
        <>
            <div className={styles.pricingControls}>
                <ButtonBar
                    label="Lock period"
                    options={lockPeriodOptions}
                    onValueChange={handleButtonBarChange}
                    defaultValue={lockPeriod.toString()}
                />

                <LabeledValue
                    label="Lock expiration"
                    value={(
                        <DateTypography
                            date={lockExpirationDate}
                            component="span"
                            variant="body2"
                        />
                    )}
                    classNames={{ value: styles.expirationDate }}
                    variant="vertical"
                />

                <ButtonBar
                    label="Commitment type"
                    options={commitmentTypeDisplay}
                    onValueChange={newValue => setCommitmentType(newValue as CommitmentType)}
                    defaultValue={commitmentType}
                    className={styles.commitmentType}
                />
            </div>

            {products.length ? (
                <PricingResultProductsTable
                    isExpired={isExpired}
                    products={products}
                    onRegister={registerLoan}
                />
            ) : (
                <Typography
                    className={styles.noProductsFound}
                    variant="body2"
                >
                    No products found for the given lock period and commitment type.
                </Typography>
            )}

            {loanPricingResultDetail.ineligibleProducts?.length && (
                <Button
                    size="small"
                    className={styles.ineligibleProductsButton}
                    onClick={() => setShowIneligibleProducts(!showIneligibleProducts)}
                >
                    {`${showIneligibleProducts ? 'Hide' : 'View'}
                     ineligible products (${loanPricingResultDetail.ineligibleProducts.length})`}
                </Button>
            )}

            {showIneligibleProducts && (
                <PricingResultIneligibleProductsTable
                    ineligibleProducts={loanPricingResultDetail.ineligibleProducts}
                />
            )}
        </>
    );
}
