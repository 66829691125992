import { UserType } from '@api';
import { useParams } from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils/hooks';

/**
 * Returns the clientID for the current user. If the user is an internal Prem admin on a client's config page,
 * the clientID will be pulled from the route params. Otherwise, the current account ID is returned.
 */
export function useActingClientID() {
    const params = useParams();
    const { accountUserType, id } = useGetCurrentAccount();

    if (accountUserType === UserType.INTERNAL) {
        return params.clientID || 'internal';
    }

    return params.clientID || id.toString();
}
