import { ActiveInactiveEnum, LicenseType, State } from '../..';
import { apiUtils } from '../../api-utils';


export function getLicenses(clientID: string, customerID: string): Promise<License[]> {
    return apiUtils.get(`/client/${clientID}/customer/${customerID}/license`);
}

export function updateLicenseStatus(
    clientID: string, customerID: string, licenseID: string, status: ActiveInactiveEnum
): Promise<License> {
    return apiUtils.patch(`/client/${clientID}/customer/${customerID}/license/${licenseID}`, { status });
}

export function renewLicense(
    clientID: string, customerID: string, licenseID: string, renewedThroughYear: number
): Promise<License> {
    return apiUtils.patch(`/client/${clientID}/customer/${customerID}/license/${licenseID}`, { renewedThroughYear });
}

export function addLicense(clientID: string, customerID: string, license: Omit<License, 'id'>): Promise<License> {
    return apiUtils.post(`/client/${clientID}/customer/${customerID}/license`, license);
}

export function editLicense(clientID: string, customerID: string, license: License): Promise<License> {
    return apiUtils.put(`/client/${clientID}/customer/${customerID}/license/${license.id}`, license);
}

export interface License {
    id: string;
    status: ActiveInactiveEnum;
    customerId: number;
    state: State;
    type: LicenseType;
    licenseNumber: string;
    renewedThroughYear: number;
}
