import { LoanProperty, loanPropertyEnumFieldTypes } from '@api';
import { Edit, RemoveCircleOutline, Save } from '@mui/icons-material';
import { Link, Paper, Typography } from '@mui/material';
import {
    IconButton, TextField, renderEnumOptions
} from '@tsp-ui/core/components';
import { ValueOf } from '@tsp-ui/core/utils';
import { loanPropertyEnumDisplaysBase } from '@utils';
import { numericMatrixEntryValidationRules } from '@utils/numeric-range-utils';
import {
    MatrixColumnMetadataFormValues
} from '@views/admin/investors/InvestorDetailPage/LoanProgramDetailPage/components/HighLevelGuidelines';
import clsx from 'clsx';
import { ReactNode, useState } from 'react';
import { UseFieldArrayRemove, useFormContext, useWatch } from 'react-hook-form';

import styles from './HighLevelGuidelineCard.module.scss';


export interface ViewHighLevelGuidelineCardProps<T extends string> {
    columnMetadata: MatrixColumnMetadataFormValues<T>;
    buttons?: ReactNode;
    title: ReactNode;
    formatValue: (value: MatrixColumnMetadataFormValues<T>) => ReactNode;
}

export function ViewHighLevelGuidelineCard<T extends string>({
    columnMetadata, buttons, title, formatValue
}: ViewHighLevelGuidelineCardProps<T>) {
    return (
        <Paper
            variant="outlined"
            className={styles.highLevelCard}
        >
            <Typography
                variant="body2"
                component="div"
                fontWeight={500}
                className={clsx(styles.highLevelProperty, {
                    [styles.readOnly]: !buttons
                })}
            >
                {title}

                {buttons}
            </Typography>

            <Typography
                variant="body2"
                component="div"
            >
                {formatValue?.(columnMetadata)}
            </Typography>
        </Paper>
    );
}

type LoanPropertyEnumValue = keyof ValueOf<typeof loanPropertyEnumDisplaysBase>;
export function formatHighLevelGuidelineValue(columnValues: MatrixColumnMetadataFormValues<LoanProperty>) {
    const { loanProperty, value } = columnValues;

    return (!loanProperty || !value) ? null : loanPropertyEnumFieldTypes[loanProperty] === 'enum'
        ? (
            <HighLevelEnumDisplay
                values={(typeof value === 'string' ? [ value ] : value).map((val) => (
                    loanPropertyEnumDisplaysBase[loanProperty]?.[val as LoanPropertyEnumValue]!
                ))}
            />
        )
        : value as string;
}

interface HighLevelEnumDisplayProps {
    values: string[];
}

export function HighLevelEnumDisplay({ values }: HighLevelEnumDisplayProps) {
    const [ showMore, setShowMore ] = useState(false);

    const valuesToShow = showMore ? values : values.slice(0, 3);

    return (
        <>
            {valuesToShow.map((val) => (
                <div key={val}>
                    {val}
                </div>
            ))}

            {values.length > 3 && (
                <Link
                    component="button"
                    onClick={() => setShowMore(!showMore)}
                    textAlign="center"
                    variant="body2"
                    className={styles.showMore}
                >
                    {showMore ? 'Show Less' : `Show ${values.length - 3} more`}
                </Link>
            )}
        </>
    );
}

export interface EditHighLevelGuidelineCardProps<
    T extends string
> extends Pick<ViewHighLevelGuidelineCardProps<T>, 'formatValue' | 'title'> {
    index: number;
    remove: UseFieldArrayRemove;
    fieldName: string;
    selectedLoanProperties: T[];
    getValueType: (loanProperty: T) => ValueOf<typeof loanPropertyEnumFieldTypes>;
    loanPropertyOptions: {
        [key: string]: string;
    };
    selectOptions: null | {
        [key: string]: string;
    };
}

export function EditHighLevelGuidelineCard<T extends string>({
    index, remove, fieldName, selectedLoanProperties, formatValue, title, selectOptions, getValueType,
    loanPropertyOptions
}: EditHighLevelGuidelineCardProps<T>) {
    const { setValue } = useFormContext();
    const columnMetadata = useWatch({ name: fieldName }) as MatrixColumnMetadataFormValues<T> | undefined;
    const { loanProperty, value } = columnMetadata || {};

    const saveDisabled = !loanProperty || !value?.length;
    const [ isEditing, setIsEditing ] = useState(!value?.length);

    /**
     * If columnMetadata is undefined, that means the index originally used to render this card was removed.
     * We can return null here to prevent the card from rendering - it will be rendered correctly with the new index.
     */
    if (!columnMetadata) {
        return null;
    }

    const buttons = (
        <div>
            {isEditing ? (
                <IconButton
                    key="save"
                    tooltip="Save"
                    size="small"
                    onClick={() => setIsEditing(false)}
                    disabled={saveDisabled}
                >
                    <Save
                        color={saveDisabled ? 'disabled' : 'secondary'}
                        fontSize="small"
                    />
                </IconButton>
            ) : (
                <IconButton
                    tooltip="Edit"
                    size="small"
                    onClick={() => setIsEditing(true)}
                >
                    <Edit
                        color="secondary"
                        fontSize="small"
                    />
                </IconButton>
            )}

            <IconButton
                tooltip="Remove"
                size="small"
                onClick={() => remove?.(index)}
            >
                <RemoveCircleOutline
                    color="error"
                    fontSize="small"
                />
            </IconButton>
        </div>
    );

    const valueType = getValueType(loanProperty!);

    return !isEditing ? (
        <ViewHighLevelGuidelineCard
            columnMetadata={columnMetadata}
            buttons={buttons}
            formatValue={formatValue}
            title={title}
        />
    ) : (
        <Paper
            variant="outlined"
            className={styles.highLevelCard}
        >
            <Typography
                variant="body2"
                component="div"
                className={clsx(styles.highLevelProperty, styles.isEditing)}
                fontWeight={500}
            >
                <TextField
                    name={`${fieldName}.loanProperty`}
                    className={styles.loanPropertySelect}
                    SelectProps={{
                        classes: { select: styles.dropdown },
                        defaultOpen: !loanProperty
                    }}
                    required
                    hideRequiredIndicator
                    select
                    onChange={(e) => {
                        const newValueType = getValueType(e.target.value as T);

                        setValue(`${fieldName}.value`, newValueType === 'enum' ? [] : '');
                    }}
                >
                    {renderEnumOptions(Object.fromEntries(
                        Object.entries(loanPropertyOptions).filter(([ loanPropertyToFilter ]) => (
                            !selectedLoanProperties.includes(loanPropertyToFilter as T)
                                || loanProperty === loanPropertyToFilter
                        ))
                    ))}
                </TextField>

                {buttons}
            </Typography>

            <TextField
                name={`${fieldName}.value`}
                label="Value"
                required
                hideRequiredIndicator
                size="small"
                {...(selectOptions && {
                    select: true,
                    SelectProps: {
                        multiple: valueType === 'enum'
                    },
                    children: renderEnumOptions(selectOptions)
                })}
                {...(valueType === 'numeric' && {
                    rules: numericMatrixEntryValidationRules
                })}
            />
        </Paper>
    );
}
