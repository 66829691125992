import { Branch, PermissionType } from '@api';
import {
    DialogActions, DialogContent, Button as MuiButton, Paper, Typography
} from '@mui/material';
import { Button } from '@tsp-ui/core/components';
import { useHasPermission } from '@utils/hooks/useHasPermission';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import BranchCard from './BranchCard';
import styles from './BranchesDialogContent.module.scss';


interface BranchesDialogContentProps {
    onEditClick: (branch: Branch) => void;
    onToggleActive: (branch: Branch) => void;
    branches: Branch[];
}

export default function BranchesDialogContent({ onEditClick, onToggleActive, branches }: BranchesDialogContentProps) {
    const { pathname } = useLocation();

    const [ showInactive, setShowInactive ] = useState(false);
    const hasInactiveBranches = branches.some(branch => !branch.isActive);

    const activeBranches = branches.filter(branch => branch.isActive);
    const inactiveBranches = branches.filter(branch => !branch.isActive);

    const [ canViewBranches, canAddBranches ] = useHasPermission(
        [ PermissionType.VIEW_CUSTOMER_BRANCHES, PermissionType.CREATE_CUSTOMER_BRANCHES ]
    );


    return (
        <>
            <DialogContent className={styles.mainContent}>
                {!canViewBranches ? (
                    <Paper
                        variant="outlined"
                        className={styles.noBranchesCard}
                    >
                        <Typography variant="body2">
                            You don't have permission to view branches
                        </Typography>
                    </Paper>
                ) : !activeBranches.length ? (
                    <Paper
                        variant="outlined"
                        className={styles.noBranchesCard}
                    >
                        <Typography variant="body2">
                            This customer has no branches yet.
                        </Typography>
                    </Paper>
                ) : (
                    <>

                        {activeBranches.length > 0 && (
                            <div className={styles.branches}>
                                <Typography
                                    variant="h6"
                                    className={styles.header}
                                >
                                    Active
                                </Typography>

                                {activeBranches.map(branch => (
                                    <div
                                        className={styles.branchContent}
                                        key={branch.id}
                                    >
                                        <BranchCard
                                            key={branch.id}
                                            branch={branch}
                                            onEditClick={onEditClick}
                                            onToggleActive={onToggleActive}
                                        />
                                    </div>
                                ))}
                            </div>
                        )}

                        {showInactive && inactiveBranches.length > 0 && (
                            <div className={styles.branches}>
                                <Typography
                                    variant="h6"
                                    className={styles.header}
                                >
                                    Inactive
                                </Typography>

                                {inactiveBranches.map(branch => (
                                    <div
                                        className={styles.branchContent}
                                        key={branch.id}
                                    >
                                        <BranchCard
                                            branch={branch}
                                            onEditClick={onEditClick}
                                            onToggleActive={onToggleActive}
                                        />
                                    </div>
                                ))}
                            </div>
                        )}
                    </>
                )}
            </DialogContent>

            <DialogActions>
                <MuiButton
                    onClick={() => setShowInactive(!showInactive)}
                    disabled={!hasInactiveBranches}
                >
                    {showInactive ? 'Hide' : 'Show'} Inactive
                </MuiButton>

                <Button
                    variant="contained"
                    component={Link}
                    to={`${pathname}/add`}
                    disabled={!canAddBranches}
                    tooltip={!canAddBranches ? 'You don\'t have permission to add branches' : ''}
                >
                    Add branch
                </Button>
            </DialogActions>
        </>
    );
}
