export enum PricingFieldName {
    // Selectable fields when viewing pricing results
    INTEREST_RATE = 'INTEREST_RATE',
    LOCK_PERIOD = 'LOCK_PERIOD',

    // Always required
    BASE_LOAN_AMOUNT = 'BASE_LOAN_AMOUNT',
    CUSTOMER_LOAN_NUMBER = 'CUSTOMER_LOAN_NUMBER',
    PROP_STATE = 'PROP_STATE',

    // Other fields
    PROP_COUNTY = 'PROP_COUNTY',
    PROP_ZIP = 'PROP_ZIP',
    APPRAISED_VALUE = 'APPRAISED_VALUE',
    SALES_PRICE = 'SALES_PRICE',
    PROPERTY_TYPE = 'PROPERTY_TYPE',
    LOAN_TYPE = 'LOAN_TYPE',
    AMORT_TYPE = 'AMORT_TYPE',
    LOAN_TERM = 'LOAN_TERM',
    LOAN_PURPOSE = 'LOAN_PURPOSE',
    OCCUPANCY_TYPE = 'OCCUPANCY_TYPE',
    FICO_CREDIT_SCORE = 'FICO_CREDIT_SCORE',
    DTI = 'DTI',
    QUALIFYING_TOTAL_INCOME = 'QUALIFYING_TOTAL_INCOME',
    PROPOSED_HOUSING_PAYMENT = 'PROPOSED_HOUSING_PAYMENT',
    OTHER_PAYMENTS = 'OTHER_PAYMENTS',
    UNITS = 'UNITS',
    DOC_TYPE = 'DOC_TYPE',
    SUBORDINATED_LOAN_AMOUNT = 'SUBORDINATED_LOAN_AMOUNT',
    FIRST_TIME_HOME_BUYER = 'FIRST_TIME_HOME_BUYER',
    ESCROWS_FLAG = 'ESCROWS_FLAG',
    ESCROW_RESERVES = 'ESCROW_RESERVES',
    INTEREST_ONLY_FLAG = 'INTEREST_ONLY_FLAG',
    AUTOMATED_UW_SYSTEM = 'AUTOMATED_UW_SYSTEM'
}

export const pricingFieldNameDisplay = {
    [PricingFieldName.INTEREST_RATE]: 'Interest Rate',
    [PricingFieldName.LOCK_PERIOD]: 'Lock Period',
    [PricingFieldName.BASE_LOAN_AMOUNT]: 'Base Loan Amount',
    [PricingFieldName.CUSTOMER_LOAN_NUMBER]: 'Customer Loan Number',
    [PricingFieldName.PROP_STATE]: 'Property State',
    [PricingFieldName.PROP_COUNTY]: 'Property County',
    [PricingFieldName.PROP_ZIP]: 'Property ZIP',
    [PricingFieldName.APPRAISED_VALUE]: 'Appraised Value',
    [PricingFieldName.SALES_PRICE]: 'Sales Price',
    [PricingFieldName.PROPERTY_TYPE]: 'Property Type',
    [PricingFieldName.LOAN_TYPE]: 'Loan Type',
    [PricingFieldName.AMORT_TYPE]: 'Amortization Type',
    [PricingFieldName.LOAN_TERM]: 'Loan Term',
    [PricingFieldName.LOAN_PURPOSE]: 'Loan Purpose',
    [PricingFieldName.OCCUPANCY_TYPE]: 'Occupancy Type',
    [PricingFieldName.FICO_CREDIT_SCORE]: 'FICO Credit Score',
    [PricingFieldName.DTI]: 'Debt-to-Income Ratio',
    [PricingFieldName.QUALIFYING_TOTAL_INCOME]: 'Qualifying Total Income',
    [PricingFieldName.PROPOSED_HOUSING_PAYMENT]: 'Proposed Housing Payment',
    [PricingFieldName.OTHER_PAYMENTS]: 'Other Payments',
    [PricingFieldName.UNITS]: 'Units',
    [PricingFieldName.DOC_TYPE]: 'Documentation Type',
    [PricingFieldName.SUBORDINATED_LOAN_AMOUNT]: 'Subordinated Loan Amount',
    [PricingFieldName.FIRST_TIME_HOME_BUYER]: 'First Time Home Buyer',
    [PricingFieldName.ESCROWS_FLAG]: 'Escrows Flag',
    [PricingFieldName.ESCROW_RESERVES]: 'Escrow Reserves',
    [PricingFieldName.INTEREST_ONLY_FLAG]: 'Interest Only Flag',
    [PricingFieldName.AUTOMATED_UW_SYSTEM]: 'Automated Underwriting System'
};
