import { createDate } from '@tsp-ui/core';
import { rest } from 'msw';


import { getMockUrl } from '../../mocks/getMockUrl';

import { EligibilityVersion } from './eligibility-version-api';


let eligibilityVersionId = 1;

export const mocks = [
    rest.get(getMockUrl('/eligibility-version'), (_, res, ctx) => {
        mockedEligibilityVersions.sort((a, b) => (
            parseInt(a.id) - parseInt(b.id)
        ));

        return res(
            ctx.status(200),
            ctx.json(mockedEligibilityVersions)
        );
    }),
    rest.post(getMockUrl('/eligibility-version'), (req, res, ctx) => {
        const newVersion = {
            ...req.body as EligibilityVersion,
            displayOrder: `${eligibilityVersionId}`,
            id: `${eligibilityVersionId++}`
        };

        mockedEligibilityVersions.push(newVersion);

        return res(
            ctx.status(200),
            ctx.json(newVersion)
        );
    }),
    rest.put(getMockUrl('eligibility-version/:eligibilityVersionId'), (req, res, ctx) => {
        const updatedVersion = req.body as EligibilityVersion;

        const indexToUpdate = mockedEligibilityVersions.findIndex(({ id }) => id === updatedVersion.id);
        mockedEligibilityVersions.splice(indexToUpdate, 1, updatedVersion);

        return res(
            ctx.status(200),
            ctx.json(updatedVersion),
            ctx.delay(2000)
        );
    }),
    rest.delete(getMockUrl('eligibility-version/:eligibilityVersionId'), (req, res, ctx) => {
        const { eligibilityVersionId } = req.params;

        const itemIndex = mockedEligibilityVersions.findIndex(({ id }) => id === eligibilityVersionId);
        mockedEligibilityVersions.splice(itemIndex, 1);

        return res(
            ctx.status(204)
        );
    })
];

export const mockedEligibilityVersions: EligibilityVersion[] = [
    {
        displayVersionId: `${eligibilityVersionId}`,
        id: `${eligibilityVersionId++}`,
        comments: 'Initial version',
        effectiveDate: createDate(-395).toISOString(),
        expirationDate: createDate(-365).toISOString()
    },
    {
        displayVersionId: `${eligibilityVersionId}`,
        id: `${eligibilityVersionId++}`,
        comments: 'Active version',
        effectiveDate: createDate(-365).toISOString(),
        expirationDate: createDate(335).toISOString()
    },
    {
        displayVersionId: `${eligibilityVersionId}`,
        id: `${eligibilityVersionId++}`,
        comments: 'Future version',
        effectiveDate: createDate(335).toISOString()
    }
];
