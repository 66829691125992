import {
    AutomatedUwRecommendation,
    AutomatedUwSystem,
    automatedUwRecommendationDisplay
} from '@api';
import { FormControl, FormLabel } from '@mui/material';
import {
    CurrencyField, Switch, TextField, isAtLeast, renderEnumOptions
} from '@tsp-ui/core/components';
import { ConfigurableValues, useRenderTogglableEnumOptions } from '@utils';
import { ClientContext } from '@views/AuthenticatedRouteSwitch';
import CustomerAutocomplete from '@views/loans/components/CustomerAutoComplete';
import {
    Dispatch,
    SetStateAction,
    useContext, useEffect
} from 'react';
import { useFormContext } from 'react-hook-form';

import { ManualLoanEntryFormValues } from '../ManualLoanEntryPage';

import styles from './AdditionalDetailsStep.module.scss';
import ManualLoanEntryStep, { ManualLoanEntryStepProps } from './ManualLoanEntryStep';


const {
    APPROVE_ELIGIBLE, APPROVE_INELIGIBLE, REFER_WITH_CAUTION, OUT_OF_SCOPE, ACCEPT, CAUTION
} = AutomatedUwRecommendation;

const { DU, LPA } = AutomatedUwSystem;

interface AdditionalDetailsStepProps extends Partial<ManualLoanEntryStepProps> {
    setCustomerIdSearch: Dispatch<SetStateAction<string>>;
}

export default function AdditionalDetailsStep({ setCustomerIdSearch, ...props }: AdditionalDetailsStepProps) {
    const { watch, setValue } = useFormContext<ManualLoanEntryFormValues>();
    const { configurableValues } = useContext(ClientContext);

    const mortgageVisible = watch('mortgageInsFlag');
    const underwriteVisible = watch('underwriteFlag');
    const automatedUwSystem = watch('automatedUwSystem');
    const loanPurpose = watch('loanPurpose');

    const isPurchase = loanPurpose === 'PURCHASE';
    const duAutomatedUwSystem =  automatedUwSystem === DU;
    const showAutomatedUwRecommendation = automatedUwSystem === DU || automatedUwSystem === LPA;

    const renderTogglableEnumOptions = useRenderTogglableEnumOptions();

    const automatedUwRecommendationDisplay = getAURDisplay(configurableValues, duAutomatedUwSystem);

    useEffect(() => {
        setValue('automatedUwRecommendation', '');
    }, [ automatedUwSystem, setValue ]);

    return (
        <ManualLoanEntryStep {...props}>
            <div>
                <FormLabel>
                    Additional details
                </FormLabel>

                <div className={styles.detailsContainer}>
                    <TextField<ManualLoanEntryFormValues>
                        name="productCode"
                        label="Product code"
                        maxLength={100}
                    />

                    <TextField<ManualLoanEntryFormValues>
                        name="specialtyProgram"
                        label="Specialty program"
                        select
                    >
                        {renderTogglableEnumOptions('specialtyProgram')}
                    </TextField>

                    <TextField<ManualLoanEntryFormValues>
                        name="documentationType"
                        label="Documentation"
                        select
                    >
                        {renderTogglableEnumOptions('documentationType')}
                    </TextField>

                    <CurrencyField<ManualLoanEntryFormValues>
                        name="subordinatedBalance"
                        label="Subordinated balance"
                        rules={isAtLeast(0)}
                    />

                    <CurrencyField<ManualLoanEntryFormValues>
                        name="cashOutAmount"
                        label="Cash out amount"
                        rules={isAtLeast(0)}
                        disabled={isPurchase}
                    />

                    <TextField<ManualLoanEntryFormValues>
                        name="limitedLiabilityCorp"
                        label="Limited liability corp"
                        maxLength={100}
                    />

                    <TextField<ManualLoanEntryFormValues>
                        name="commitmentType"
                        label="Commitment type"
                        select
                    >
                        {renderTogglableEnumOptions('commitmentType')}
                    </TextField>

                    <TextField<ManualLoanEntryFormValues>
                        name="commitmentIdentifier"
                        label="Commitment ID"
                        maxLength={50}
                    />

                    <CustomerAutocomplete
                        setCustomerIdSearch={setCustomerIdSearch}
                        isRequired
                    />

                    <Switch<ManualLoanEntryFormValues>
                        className={styles.switch}
                        name="mortgageInsFlag"
                        label="Mortgage insurance"
                    />

                    {mortgageVisible && (
                        <FormControl
                            component="fieldset"
                            className={styles.switchFields}
                        >
                            <TextField<ManualLoanEntryFormValues>
                                name="mortgageInsCompany"
                                label="Insurance Company"
                                maxLength={100}
                            />
                        </FormControl>
                    )}

                    <Switch<ManualLoanEntryFormValues>
                        className={styles.switch}
                        name="underwriteFlag"
                        label="Underwrite"
                    />

                    {underwriteVisible && (
                        <FormControl
                            component="fieldset"
                            className={styles.switchFields}
                        >
                            <TextField<ManualLoanEntryFormValues>
                                name="automatedUwSystem"
                                label="UW System"
                                select
                            >
                                {renderTogglableEnumOptions('automatedUnderwritingSystem')}
                            </TextField>

                            {showAutomatedUwRecommendation ? (
                                <TextField<ManualLoanEntryFormValues>
                                    name="automatedUwRecommendation"
                                    label="UW Recommendation"
                                    select
                                >
                                    {renderEnumOptions(automatedUwRecommendationDisplay)}
                                </TextField>
                            ) : null}
                        </FormControl>
                    )}
                </div>
            </div>
        </ManualLoanEntryStep>
    );
}

function getAURDisplay(configurableValues: ConfigurableValues | undefined, duAutomatedUwSystem: boolean) {
    const duAURKeys = [
        APPROVE_ELIGIBLE, APPROVE_INELIGIBLE, REFER_WITH_CAUTION, OUT_OF_SCOPE
    ];
    const lpaAURKeys = [ ACCEPT, CAUTION ];
    const enabledKeys = Object.keys(automatedUwRecommendationDisplay)
        .filter(key => configurableValues?.togglableValues.automatedUnderwritingSystem.includes(
        key as AutomatedUwRecommendation
        ));

    return duAutomatedUwSystem
        ? Object.fromEntries(
            duAURKeys.filter(
                key => enabledKeys.includes(key)
            ).map(key => [ key, automatedUwRecommendationDisplay[key] ])
        ) : Object.fromEntries(
            lpaAURKeys.filter(
                key => enabledKeys.includes(key)
            ).map(key => [ key, automatedUwRecommendationDisplay[key] ])
        );
}
