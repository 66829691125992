import api, { CustomerFeesComp, PermissionType, PurchaseAdviceFee } from '@api';
import { Button, DialogContent, Tooltip } from '@mui/material';
import { DialogActions, RoutedDialog, RoutedDialogProps } from '@tsp-ui/core/components';
import { useAsyncEffect, usePageMessage, useParams } from '@tsp-ui/core/utils';
import { useGetCurrentAccount, useHasPermission } from '@utils/hooks';
import { AdminRouteParams } from '@views/admin/components/AdminPageTemplate';
import { useCallback, useState } from 'react';

import CompensationDialogForm, { COMPENSATION_FORM_ID } from './CompensationDialogForm';


export type CompensationDialogProps = Omit<RoutedDialogProps, 'title' | 'children'>;

export default function CompensationDialog(props: CompensationDialogProps) {
    const [ loading, setLoading ] = useState(false);
    const [ saveLoading, setSaveLoading ] = useState(false);

    const [ compensationDetails, setCompensationDetails ] = useState<CustomerFeesComp>();
    const [ purchaseAdviceFees, setPurchaseAdviceFees ] = useState<PurchaseAdviceFee[]>();
    const { id: clientID } = useGetCurrentAccount();
    const { customerID } = useParams<AdminRouteParams<'customer'>>();

    const pageMessage = usePageMessage();

    useAsyncEffect(useCallback(async () => {
        setLoading(true);

        try {
            const [ compensationDetails, purchaseAdviceFees ] = await Promise.all([
                api.customer.compensation.getFeesComp(clientID, customerID),
                api.purchaseAdvice.getPurchaseAdviceFees(clientID)
            ]);
            setCompensationDetails(compensationDetails);
            setPurchaseAdviceFees(purchaseAdviceFees);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching the compensation details', error);
        }

        setLoading(false);
    }, [
        clientID, customerID, pageMessage
    ]));

    const [ canManageFeesComp ] = useHasPermission([ PermissionType.MANAGE_FEE_COMPENSATION ]);

    return (
        <RoutedDialog
            maxWidth="lg"
            title="Compensation & fees"
            loading={loading}
            {...props}
        >
            <DialogContent>
                {compensationDetails && (
                    <CompensationDialogForm
                        purchaseAdviceFees={purchaseAdviceFees || []}
                        compensationDetails={compensationDetails}
                        setLoading={setSaveLoading}
                        {...props}
                    />
                )}
            </DialogContent>

            <DialogActions loading={saveLoading}>
                <Tooltip
                    title={!canManageFeesComp ? 'You do not have permission to manage fees' : ''}
                >
                    <Button
                        form={COMPENSATION_FORM_ID}
                        variant="contained"
                        type="submit"
                        disabled={!canManageFeesComp || saveLoading}
                    >
                        Save
                    </Button>
                </Tooltip>
            </DialogActions>
        </RoutedDialog>
    );
}
