import api, { PermissionType } from '@api';
import { Typography } from '@mui/material';
import { Button, FilledSection, RoutedDialogManager } from '@tsp-ui/core/components';
import { useAsyncEffect, usePageMessage } from '@tsp-ui/core/utils';
import { withAuth } from '@utils';
import { useGetCurrentAccount, useHasPermission } from '@utils/hooks';
import Page from '@views/components/Page';
import { CustomerAlertConfig } from 'api/client/customerAlertConfig/customerAlertConfig-api';
import {
    Dispatch, SetStateAction, createContext, useCallback, useMemo, useState
} from 'react';
import { Link } from 'react-router-dom';

import CustomerAlertConfigCard from './CustomerAlertConfigCard';
import styles from './CustomerAlertConfigPage.module.scss';
import CustomerAlertConfigDialog from './components/CustomerAlertConfigDialog';


export interface CustomerAlertConfigContextValue {
    customerAlertConfigs: CustomerAlertConfig[];
    setCustomerAlertConfigs: Dispatch<SetStateAction<CustomerAlertConfig[]>>;
    loading: boolean;
}

export const CustomerAlertConfigContext = createContext<CustomerAlertConfigContextValue>({
    customerAlertConfigs: [],
    setCustomerAlertConfigs: () => {},
    loading: false
});

export default function CustomerAlertConfigPage() {
    const [ customerAlertConfigs, setCustomerAlertConfigs ] = useState<CustomerAlertConfig[]>([]);
    const [ loading, setLoading ] = useState(true);

    const { id: clientID } = useGetCurrentAccount();
    const pageMessage = usePageMessage();

    useAsyncEffect(useCallback(async () => {
        setLoading(true);

        try {
            setCustomerAlertConfigs(await api.client.customerAlertConfigs.getCustomerAlertConfigs(clientID));
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching customer alert configurations', error);
        }

        setLoading(false);
    }, [
        clientID, setCustomerAlertConfigs, pageMessage
    ]));

    const contextValue = useMemo(() => ({
        loading,
        customerAlertConfigs,
        setCustomerAlertConfigs
    }), [ loading, customerAlertConfigs ]);

    const [ canManageCustomerAlertConfigs ] = useHasPermission([ PermissionType.MANAGE_CUSTOMER_ALERT_CONFIGS ]);

    return (
        <div className={styles.root}>
            <CustomerAlertConfigContext.Provider value={contextValue}>
                <Page
                    header="Customer Alert Management"
                    loading={loading}
                    headerActions={(
                        <Button
                            component={Link}
                            to="add"
                            variant="contained"
                            disabled={!canManageCustomerAlertConfigs}
                            tooltip={!canManageCustomerAlertConfigs ? 'You do not have permission to add an alert configuration' : ''}
                        >
                            Add customer alert
                        </Button>
                    )}
                >
                    <div className={styles.configDescriptionContainer}>
                        <FilledSection
                            className={styles.configs}
                            header="Alert configuration"
                        >
                            {customerAlertConfigs.map((config) => (
                                <CustomerAlertConfigCard
                                    key={config.id}
                                    alertConfig={config}
                                />
                            ))}
                        </FilledSection>

                        <Typography
                            variant="caption"
                            color="textSecondary"
                            align="center"
                            className={styles.description}
                        >
                            When changes are made in the system to any of the items configured here,
                            the affected customers will automatically receive an alert to notify them of the change.
                            The alerts will persist for the customer based on the configuration within each item.
                        </Typography>

                        <RoutedDialogManager routes={dialogRoutes} />
                    </div>
                </Page>
            </CustomerAlertConfigContext.Provider>
        </div>
    );
}

const dialogRoutes = {
    add: withAuth(CustomerAlertConfigDialog, [ PermissionType.MANAGE_CUSTOMER_ALERT_CONFIGS ], true),
    ':alertConfigID': withAuth(CustomerAlertConfigDialog, [ PermissionType.MANAGE_CUSTOMER_ALERT_CONFIGS ], true)
};
