import { TextField } from '@tsp-ui/core/components';

import styles from './LoanProgramDetailsFields.module.scss';
import { CreateLoanProgramFormValues } from './LoanProgramForm';


export function LoanProgramDetailsFields() {
    return (
        <>
            <TextField<CreateLoanProgramFormValues>
                name="loanProgram.name"
                label="Program name"
                className={styles.fullWidth}
                required
            />

            <TextField<CreateLoanProgramFormValues>
                name="loanProgram.description"
                label="Description"
                className={styles.fullWidth}
                required
                multiline
                maxRows={6}
            />
        </>
    );
}
