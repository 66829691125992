export enum ConditionCategory {
    ASSETS = 'ASSETS',
    CREDIT = 'CREDIT',
    INCOME = 'INCOME',
    LEGAL = 'LEGAL',
    MISC = 'MISC',
    PROPERTY = 'PROPERTY'
}

export const conditionCategoryDisplay = {
    [ConditionCategory.ASSETS]: 'Assets',
    [ConditionCategory.CREDIT]: 'Credit',
    [ConditionCategory.INCOME]: 'Income',
    [ConditionCategory.LEGAL]: 'Legal',
    [ConditionCategory.MISC]: 'Misc',
    [ConditionCategory.PROPERTY]: 'Property'
};
