import { PermissionType } from '@api';

import { apiUtils } from '../api-utils';
import { PermissionCategory } from '../enums/permissionCategoryEnum';
import { PermissionLevel } from '../enums/permissionLevelEnum';
import { UserType } from '../enums/userTypeEnum';


export * as notifications from './notifications/notifications-api';

export async function getRoles(clientID: string, filterByUserType?: UserType): Promise<Role[]> {
    return (
        await apiUtils.get(getRoleURL(clientID)) as Role[]
    ).filter(role => !filterByUserType || role.type === filterByUserType);
}

export function deleteRole(clientID: string, roleID: string): Promise<void> {
    return apiUtils.delete(getRoleURL(clientID, roleID));
}

export function addRole(clientID: string, role: CreateRoleBody): Promise<Role> {
    return apiUtils.post(getRoleURL(clientID), role);
}

export function editRole(clientID: string, role: Role): Promise<Role> {
    return apiUtils.put(getRoleURL(clientID, role.id), role);
}

export function getRole(clientID: string, roleID: string): Promise<Role> {
    return apiUtils.get(getRoleURL(clientID, roleID));
}

export function getPermissions(): Promise<Permission[]> {
    return apiUtils.get('/auth/permission');
}

export function getPermissionLevels(
    clientID: string, roleID: string
): Promise<RolePermissionLevel[]> {
    return apiUtils.get(`${getRoleURL(clientID, roleID)}/permission`);
}

export function editPermissionLevels(
    clientID: string,
    roleID: string,
    permissionLevels: RolePermissionLevel[]
): Promise<RolePermissionLevel[]> {
    return apiUtils.put(`${getRoleURL(clientID, roleID)}/permission`, permissionLevels);
}

export function getRoleURL(clientID: string, roleID?: string) {
    return `${clientID !== 'internal' ? `/client/${clientID}` : ''}/role${roleID ? `/${roleID}` : ''}`;
}

export interface Role {
    id: string;
    type: UserType;
    name: string;
    description: string;
    isInUse?: boolean;
}

type CreateRoleBody = Omit<Role, 'id'> & {
    roleIdToCloneFrom?: string;
}

export interface RolePermissionLevel {
    roleId: string;
    permissionType: PermissionType;
    permissionLevel: PermissionLevel;
}

export interface Permission {
    id: string;
    permissionType: PermissionType;
    category: PermissionCategory;
    description: string;
    allowAssigned: boolean;
    allowableRoleTypes: UserType[];
}
