import api, {
    ActiveInactiveEnum, License, PermissionType, licenseTypeDisplay, stateDisplay
} from '@api';
import {
    Cached, Edit, RemoveCircleOutline, Save
} from '@mui/icons-material';
import { Paper, Typography } from '@mui/material';
import { replaceItemById, useParams } from '@tsp-ui/core';
import {
    IconButton, LabelGroup, LabeledValue, usePageMessage
} from '@tsp-ui/core/components';
import { tooltipTitle } from '@utils';
import { useGetCurrentAccount } from '@utils/hooks/useGetCurrentAccount';
import { useHasPermission } from '@utils/hooks/useHasPermission';
import { Dispatch, SetStateAction, useState } from 'react';

import styles from './LicenseCard.module.scss';
import { LicenseForm } from './LicenseForm';


interface LicenseCardProps {
    license: License | undefined;
    setAdding?: Dispatch<SetStateAction<boolean>>;
    setLicenses: Dispatch<SetStateAction<License[]>>;
    locked?: boolean;
}

export function LicenseCard({
    license, setAdding, setLicenses, locked
}: LicenseCardProps) {
    const [ editing, setEditing ] = useState(!license);
    const [ renewing, setRenewing ] = useState(false);
    const [ deactivating, setDeactivating ] = useState(false);
    const [ saving, setSaving ] = useState(false);
    const pageMessage = usePageMessage();

    const currentDate = new Date();
    const expired = license?.renewedThroughYear! < currentDate.getFullYear();
    // Date.getMonth() is zero-indexed: November = 10
    const canRenew = expired || (
        currentDate.getMonth() >= 10 && license?.renewedThroughYear! === currentDate.getFullYear()
    );

    const { customerID: customerIdFromRoute } = useParams();
    const { id: clientID, customerId: customerIdFromToken } = useGetCurrentAccount();
    const customerId = (customerIdFromRoute || customerIdFromToken)!;

    const [ canManage ] = useHasPermission([ PermissionType.MANAGE_LICENSES ]);

    function getNewRenewedThroughYear() {
        return currentDate.getMonth() < 10 ? currentDate.getFullYear() : currentDate.getFullYear() + 1;
    }

    async function handleRenew(licenseID: string, renewedThroughYear: number) {
        setRenewing(true);

        try {
            const renewedLicense = await api.customer.licenses.renewLicense(
                clientID, customerId, licenseID, renewedThroughYear
            );

            setLicenses(licenses => licenses.map(
                license => (license.id === renewedLicense.id ? { ...renewedLicense } : license)
            ));

            pageMessage.success('License renewed');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while renewing the license', error);
        }

        setRenewing(false);
    }

    async function handleDeactivation() {
        setDeactivating(true);

        if (license) {
            try {
                const deactivatedLicense = await api.customer.licenses.updateLicenseStatus(
                    clientID, customerId, license.id, ActiveInactiveEnum.INACTIVE
                );

                setLicenses(licenses => replaceItemById(licenses, deactivatedLicense));

                pageMessage.success('License deleted');
            } catch (error) {
                pageMessage.handleApiError('An error occurred while deleting the license', error);
            }
        } else {
            setAdding?.(false);
        }

        setDeactivating(false);
    }

    async function handleSubmit(formValues: License) {
        setSaving(true);

        try {
            const newLicense = license
                ? await api.customer.licenses.editLicense(
                    clientID, customerId, formValues
                ) : await api.customer.licenses.addLicense(
                    clientID, customerId, {
                        ...formValues,
                        status: ActiveInactiveEnum.ACTIVE
                    }
                );

            if (license) {
                setLicenses(licenses => replaceItemById(licenses, newLicense));
                setEditing(false);
            } else {
                setLicenses(licenses => [ ...licenses, newLicense ]);
                setAdding?.(false);
            }

            pageMessage.success('License saved');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving the license', error);
        }

        setSaving(false);
    }

    return (
        <Paper
            className={styles.root}
            variant="outlined"
        >
            <div className={styles.displayHeader}>
                <Typography>
                    {license?.type ? licenseTypeDisplay[license.type] : 'New License'}
                </Typography>

                <div>
                    <span>
                        {editing && (
                            <IconButton
                                tooltip="Save license"
                                form={`licenses-${license?.id || 'new'}`}
                                type="submit"
                                loading={saving}
                            >
                                <Save color="success" />
                            </IconButton>
                        )}

                        {!editing && (
                            <>
                                <IconButton
                                    tooltip={tooltipTitle({
                                        'You must unlock this data request to make changes': locked,
                                        'Renew license': canManage,
                                        'You do not have permission to renew licenses': !canManage
                                    })}
                                    disabled={locked}
                                    loading={renewing}
                                    onClick={() => handleRenew(license?.id!, getNewRenewedThroughYear())}
                                    edge="end"
                                >
                                    <Cached color={canRenew ? 'warning' : 'disabled'} />
                                </IconButton>

                                <IconButton
                                    tooltip={tooltipTitle({
                                        'You must unlock this data request to make changes': locked,
                                        'Edit license': canManage,
                                        'You do not have permission to edit licenses': !canManage
                                    })}
                                    onClick={() => setEditing(true)}
                                    disabled={locked}
                                    key="edit"
                                    edge="end"
                                >
                                    <Edit color="primary" />
                                </IconButton>
                            </>
                        )}

                        {license && (
                            <IconButton
                                tooltip={tooltipTitle({
                                    'You must unlock this data request to make changes': locked,
                                    'Delete license': canManage,
                                    'You do not have permission to delete licenses': !canManage
                                })}
                                disabled={locked}
                                loading={deactivating}
                                onClick={handleDeactivation}
                                edge="end"
                            >
                                <RemoveCircleOutline color="error" />
                            </IconButton>
                        )}
                    </span>
                </div>
            </div>

            {editing ? (
                <LicenseForm
                    defaultValues={license}
                    handleSubmit={handleSubmit}
                />
            ) : (
                <LabelGroup>
                    <LabeledValue
                        label="State:"
                        value={license?.state ? stateDisplay[license.state] : 'N/A'}
                    />

                    <LabeledValue
                        label="License #:"
                        value={license?.licenseNumber ? license.licenseNumber : 'N/A'}
                    />

                    <LabeledValue
                        label="Valid through:"
                        value={license?.renewedThroughYear ? license.renewedThroughYear : 'N/A'}
                    />
                </LabelGroup>
            )}
        </Paper>
    );
}
