import { Link as MuiLink } from '@mui/material';
import { LabelGroup, LabeledValue } from '@tsp-ui/core/components';
import EditableSectionCard from '@views/admin/components/EditableSectionCard';
import {
    useTryGetLoanProgram
} from '@views/admin/investors/InvestorDetailPage/LoanProgramDetailPage/InternalLoanProgramDetailPage';
import { Link, useLocation } from 'react-router-dom';

import { useTryGetInvestor } from '../../InternalInvestorDetailPage';


interface LoanProgramDetailsCardProps {
    disableEdit: boolean;
}

export default function LoanProgramDetailsCard({ disableEdit }: LoanProgramDetailsCardProps) {
    const { search } = useLocation();

    const [ investor ] = useTryGetInvestor();
    const [ loanProgram ] = useTryGetLoanProgram();

    return (
        <EditableSectionCard
            header={loanProgram?.name}
            editTo={`edit${search}`}
            disableEdit={disableEdit}
            disabledTooltip="This loan program is managed by Premicorr and cannot be edited"
        >
            <LabelGroup>
                <LabeledValue
                    label="Investor:"
                    value={(
                        <MuiLink
                            component={Link}
                            to={`..${search}`}
                        >
                            {investor?.name}
                        </MuiLink>
                    )}
                    variants={{ value: 'body1' }}
                />

                <LabeledValue
                    label="Description:"
                    value={loanProgram?.description}
                    variants={{ value: 'body1' }}
                />
            </LabelGroup>
        </EditableSectionCard>
    );
}
