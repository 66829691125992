export enum PremicorrConditionCategory {
    ASSETS_AND_INCOME = 'ASSETS_AND_INCOME',
    AUS = 'AUS',
    CREDIT_AND_LIABILITIES = 'CREDIT_AND_LIABILITIES',
    TITLE_AND_MI = 'TITLE_AND_MI',
    REO_ANALYSIS = 'REO_ANALYSIS',
    APPRAISAL = 'APPRAISAL',
    FRAUD_REPORT = 'FRAUD_REPORT',
    FLOOD_AND_HAZARD = 'FLOOD_AND_HAZARD',
    SALES_CONTRACT = 'SALES_CONTRACT',
    SECONDARY_FINANCING = 'SECONDARY_FINANCING',
    SPECIALTY_ISSUES = 'SPECIALTY_ISSUES'
}

export const premicorrConditionCategoryDisplay = {
    [PremicorrConditionCategory.ASSETS_AND_INCOME]: 'Assets & Income',
    [PremicorrConditionCategory.AUS]: 'AUS',
    [PremicorrConditionCategory.CREDIT_AND_LIABILITIES]: 'Credit & Liabilities',
    [PremicorrConditionCategory.TITLE_AND_MI]: 'Title & MI',
    [PremicorrConditionCategory.REO_ANALYSIS]: 'Reo analysis',
    [PremicorrConditionCategory.APPRAISAL]: 'Appraisal',
    [PremicorrConditionCategory.FRAUD_REPORT]: 'Fraud report',
    [PremicorrConditionCategory.FLOOD_AND_HAZARD]: 'Flood & Hazard',
    [PremicorrConditionCategory.SALES_CONTRACT]: 'Sales contract',
    [PremicorrConditionCategory.SECONDARY_FINANCING]: 'Secondary financing',
    [PremicorrConditionCategory.SPECIALTY_ISSUES]: 'Specialty issues'

};
