import api, { ActiveInactiveEnum, PermissionType, WarehouseRelationship } from '@api';
import { Edit, RemoveCircleOutline, Save } from '@mui/icons-material';
import { Paper, Typography } from '@mui/material';
import { formatCurrency, replaceItemById, useParams } from '@tsp-ui/core';
import {
    IconButton, LabelGroup, LabeledValue, PhoneTypography, usePageMessage
} from '@tsp-ui/core/components';
import { tooltipTitle } from '@utils';
import { useGetCurrentAccount } from '@utils/hooks/useGetCurrentAccount';
import { useHasPermission } from '@utils/hooks/useHasPermission';
import { AdminRouteParams } from '@views/admin/components/AdminPageTemplate';
import { Dispatch, SetStateAction, useState } from 'react';

import styles from './WarehouseRelationshipCard.module.scss';
import { WarehouseRelationshipForm } from './WarehouseRelationshipForm';


interface WarehouseRelationshipCardProps {
    warehouseRelationship: WarehouseRelationship | undefined;
    setAdding?: Dispatch<SetStateAction<boolean>>;
    setWarehouseRelationships: Dispatch<SetStateAction<WarehouseRelationship[]>>;
    locked?: boolean;
}

export function WarehouseRelationshipCard(
    {
        warehouseRelationship, setAdding, setWarehouseRelationships, locked
    }: WarehouseRelationshipCardProps
) {
    const [ editing, setEditing ] = useState(!warehouseRelationship);
    const [ deleting, setDeleting ] = useState(false);
    const [ saving, setSaving ] = useState(false);
    const pageMessage = usePageMessage();

    const { id: clientID, customerId } = useGetCurrentAccount();
    const { customerID } = useParams<AdminRouteParams<'customer'>>();

    const [ canManage ] = useHasPermission([ PermissionType.MANAGE_WAREHOUSE_RELATIONSHIP ]);

    async function handleDelete() {
        setDeleting(true);

        if (warehouseRelationship) {
            try {
                const deactivatedWarehouseRelationship = await api.customer.warehouseRelationship
                    .softDeleteWarehouseRelationship(clientID, customerId || customerID, warehouseRelationship);

                setWarehouseRelationships(warehouseRelationships => replaceItemById(
                    warehouseRelationships, deactivatedWarehouseRelationship
                ));

                pageMessage.success('Warehouse relationship deleted');
            } catch (error) {
                pageMessage.handleApiError('An error occurred while deleting the warehouse relationship', error);
            }
        } else {
            setAdding?.(false);
        }

        setDeleting(false);
    }

    async function handleSubmit(formValues: WarehouseRelationship) {
        setSaving(true);

        try {
            const newWarehouseRelationship = warehouseRelationship
                ? await api.customer.warehouseRelationship.updateWarehouseRelationship(
                    clientID, customerId || customerID, formValues
                )
                : await api.customer.warehouseRelationship.createWarehouseRelationship(
                    clientID, customerId || customerID, {
                        ...formValues,
                        status: ActiveInactiveEnum.ACTIVE
                    }
                );

            if (warehouseRelationship) {
                setWarehouseRelationships(
                    warehouseRelationships => replaceItemById(warehouseRelationships, newWarehouseRelationship)
                );
                setEditing(false);
            } else {
                setWarehouseRelationships(
                    warehouseRelationships => [ ...warehouseRelationships, newWarehouseRelationship ]
                );
                setAdding?.(false);
            }

            pageMessage.success('Warehouse relationship saved');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving the warehouse relationship', error);
        }

        setSaving(false);
    }

    return (
        <Paper
            className={styles.root}
            variant="outlined"
        >
            <div className={styles.displayHeader}>
                <Typography>
                    {warehouseRelationship?.lenderName || 'New Warehouse Relationship'}
                </Typography>

                <div>
                    {editing ? (
                        <IconButton
                            tooltip="Save warehouse relationship"
                            form={`warehouse-relationship-${warehouseRelationship?.id || 'new'}`}
                            type="submit"
                            loading={saving}
                        >
                            <Save color="success" />
                        </IconButton>
                    ) : (
                        <IconButton
                            tooltip={tooltipTitle({
                                'You must unlock this data request to make changes': locked,
                                'Edit warehouse relationship': canManage,
                                'You do not have permission to edit warehouse relationships': !canManage
                            })}
                            disabled={!canManage || locked}
                            onClick={() => setEditing(true)}
                            key="edit"
                        >
                            <Edit color="primary" />
                        </IconButton>
                    )}

                    <IconButton
                        tooltip={tooltipTitle({
                            'You must unlock this data request to make changes': locked,
                            'Delete warehouse relationship': canManage,
                            'You do not have permission to delete warehouse relationships': !canManage
                        })}
                        disabled={!canManage || locked}
                        loading={deleting}
                        onClick={handleDelete}
                        edge="end"
                    >
                        <RemoveCircleOutline color="error" />
                    </IconButton>
                </div>
            </div>

            {editing ? (
                <WarehouseRelationshipForm
                    defaultValues={warehouseRelationship}
                    handleSubmit={handleSubmit}
                />
            ) : (
                <LabelGroup>
                    <LabeledValue
                        label="Line amount:"
                        value={formatCurrency(warehouseRelationship?.lineAmount)}
                    />

                    <LabeledValue
                        label="Contact name:"
                        value={warehouseRelationship?.contact}
                    />

                    <LabeledValue
                        label="Phone #:"
                        value={(
                            <PhoneTypography variant="body2">
                                {warehouseRelationship?.phone || ''}
                            </PhoneTypography>
                        )}
                    />

                    <LabeledValue
                        label="Email:"
                        value={warehouseRelationship?.emailAddress}
                    />
                </LabelGroup>
            )}
        </Paper>
    );
}
