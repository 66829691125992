export enum ConditionEventType {
    DOCUMENTS_ADDED = 'DOCUMENTS_ADDED',
    READY_FOR_REVIEW = 'READY_FOR_REVIEW',
    NOTE_ADDED = 'NOTE_ADDED',
    NOTE_DELETED = 'NOTE_DELETED',
    CONDITION_CREATED = 'CONDITION_CREATED',
    CONDITION_UPDATED = 'UW_CONDITION_UPDATED',
    CONDITIONAL_APPROVAL_SUBMITTED = 'CONDITIONAL_APPROVAL_SUBMITTED'
}

export const conditionEventTypeDisplay = {
    [ConditionEventType.DOCUMENTS_ADDED]: 'Documents added',
    [ConditionEventType.READY_FOR_REVIEW]: 'Condition ready for review',
    [ConditionEventType.NOTE_ADDED]: 'Note added',
    [ConditionEventType.NOTE_DELETED]: 'Note deleted',
    [ConditionEventType.CONDITION_CREATED]: 'Condition created',
    [ConditionEventType.CONDITION_UPDATED]: 'Condition updated',
    [ConditionEventType.CONDITIONAL_APPROVAL_SUBMITTED]: 'Conditional approval submitted'
};
