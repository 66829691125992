import { ActiveInactiveEnum } from '@api';
import { rest } from 'msw';

import { getMockUrl } from '../../../mocks/getMockUrl';

import { InvestorRelationship } from './investor-relationship-api';


export const mocks = [
    rest.get(getMockUrl('/client/:clientID/customer/:customerID/investor-relationship'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.delay(500),
        ctx.json(investorRelationships)
    ))),
    rest.post(getMockUrl('/client/:clientID/customer/:customerID/investor-relationship'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json({
            ...req.body as InvestorRelationship,
            id: String(investorRelationshipID++)
        })
    ))),
    rest.put(getMockUrl('/client/:clientID/customer/:customerID/investor-relationship/:investorRelationshipID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(req.body)
    )))
];

let investorRelationshipID = 0;
const investorRelationships: InvestorRelationship[] = [
    {
        id: String(investorRelationshipID++),
        companyName: 'Fannie Mae',
        contact: 'John Doe',
        phone: '123-456-7890',
        emailAddress: 'jdoe@company.net',
        status: ActiveInactiveEnum.ACTIVE
    }
];
