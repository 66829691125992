import { Button, DialogContent } from '@mui/material';
import { DialogActions, RoutedDialog } from '@tsp-ui/core/components';
import { getItemById, useParams } from '@tsp-ui/core/utils';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import { InvestorEligibilityContext } from '../InvestorEligibilityRoutes';

import ClientInvestorForm from './ClientInvestorForm';


export function EditClientInvestorDialog() {
    const { investorID } = useParams<{ investorID: string }>();

    const { investors } = useContext(InvestorEligibilityContext);
    const investor = getItemById(investors || [], investorID);

    const [ loading, setLoading ] = useState(false);

    return (
        <RoutedDialog
            closeTo=".."
            title="Edit investor"
            debounceTitle
            saveLoading={loading}
            keepLocationSearch
        >
            <DialogContent>
                <ClientInvestorForm
                    investorToEdit={investor}
                    setLoading={setLoading}
                />
            </DialogContent>

            <DialogActions loading={loading}>
                <Button
                    component={Link}
                    to=".."
                >
                    Cancel
                </Button>

                <Button
                    variant="contained"
                    form={ClientInvestorForm.formID}
                    type="submit"
                >
                    Save
                </Button>
            </DialogActions>
        </RoutedDialog>
    );
}
