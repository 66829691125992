import api, { LoanPricingResult } from '@api';
import {
    Description, OpenInNew, PriorityHigh, Settings
} from '@mui/icons-material';
import {
    Avatar, Button, Divider, IconButton, Paper, Tooltip, Typography
} from '@mui/material';
import { FilledSection, FilterTextField, IconTypography } from '@tsp-ui/core/components';
import { formatCurrency, formatCurrencyAbbreviation } from '@tsp-ui/core/utils';
import LoanNumberLink from '@views/components/MainNav/components/NotificationsButton/components/LoanNumberLink';
import { LoanCard } from '@views/loans/components/LoanCard';
import { ReactNode, useState } from 'react';
import { PieChart, PieChartProps } from 'react-minimal-pie-chart';

import { getMockedPricingResult } from '../../api/pricing/pricing-mocks';
import Page from '../components/Page';

import styles from './AccountDashboardPage.module.scss';


const results: LoanPricingResult[] = [
    getMockedPricingResult('Jim Burrows'),
    getMockedPricingResult('John Burrows'),
    getMockedPricingResult('Jeff Burrows', true)
].reverse();

/**
 * Renders the account dashboard page
 *
 * @constructor
 */
export default function AccountDashboardPage() {
    return (
        <Page
            className={styles.main}
            header={(
                <div className={styles.pageHeader}>
                    Dashboard

                    <FilterTextField
                        placeholder="Find a loan"
                        helperText="Search by loan number"
                    />
                </div>
            )}
            headerActions={(
                <IconButton onClick={() => api.webSocket.simulateNotification()}>
                    <Settings color="secondary" />
                </IconButton>
            )}
        >
            <div className={styles.root}>
                <div className={styles.mainContent}>
                    <div className={styles.charts}>
                        <ChartSection
                            title="Loans by pipeline stage"
                            data={pipelineStatusData}
                            renderValue={(value) => value.toString()}
                            totalLabel="loans in pipeline"
                        />

                        <ChartSection
                            title="Loans by conditions outstanding"
                            data={otherData}
                            renderValue={(value) => value.toString()}
                            totalLabel="loans with conditions outstanding"
                        />

                        <ChartSection
                            title="Current volume by product type"
                            data={volumeData}
                            renderValue={(value) => (
                                <AbbreviatedCurrencyDisplay value={value} />
                            )}
                            totalLabel="total volume"
                        />
                    </div>

                    <FilledSection
                        header="Watched loans"
                        variant="light"
                        className={styles.loans}
                    >
                        {results?.map((result) => (
                            <LoanCard
                                isPendingLoan
                                loanPricingResult={result}
                                className={styles.loanRow}
                                additionalDetails={(
                                    <IconTypography
                                        compact
                                        className={styles.iconTypography}
                                        icon={(
                                            <Tooltip title="3 indexed documents">
                                                <Description color="primary" />
                                            </Tooltip>
                                        )}
                                    >
                                        3
                                    </IconTypography>
                                )}
                            />
                        ))}
                    </FilledSection>

                    <FilledSection
                        header="Recent loans"
                        variant="light"
                        className={styles.loans}
                    >
                        {results?.map((result) => (
                            <LoanCard
                                isPendingLoan
                                loanPricingResult={result}
                                className={styles.loanRow}
                                additionalDetails={(
                                    <IconTypography
                                        compact
                                        className={styles.iconTypography}
                                        icon={(
                                            <Tooltip title="3 indexed documents">
                                                <Description color="primary" />
                                            </Tooltip>
                                        )}
                                    >
                                        3
                                    </IconTypography>
                                )}
                            />
                        ))}
                    </FilledSection>
                </div>

                <div className={styles.sidebar}>
                    <div>
                        <Typography className={styles.header}>
                            Alerts
                        </Typography>

                        <div className={styles.alerts}>
                            <Paper
                                variant="outlined"
                                className={styles.alertCard}
                            >
                                <Typography>
                                    Seller guide changed
                                </Typography>

                                <div className={styles.alertActions}>
                                    <div className={styles.alertIcons}>
                                        <Tooltip
                                            title="This alert was sent with high importance"
                                            enterDelay={0}
                                        >
                                            <PriorityHigh
                                                color="warning"
                                                fontSize="small"
                                            />
                                        </Tooltip>

                                        <Tooltip title="View alert details">
                                            <IconButton size="small">
                                                <OpenInNew
                                                    color="secondary"
                                                    fontSize="small"
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    </div>

                                    <Typography
                                        variant="caption"
                                        color="textSecondary"
                                        align="right"
                                        component="div"
                                        className={styles.date}
                                    >
                                        3 days ago
                                    </Typography>
                                </div>
                            </Paper>

                            <Paper
                                variant="outlined"
                                className={styles.alertCard}
                            >
                                <Typography>
                                    Lending limits changed
                                </Typography>

                                <div className={styles.alertActions}>
                                    <div className={styles.alertIcons}>
                                        <Tooltip title="View alert details">
                                            <IconButton size="small">
                                                <OpenInNew
                                                    color="secondary"
                                                    fontSize="small"
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    </div>

                                    <Typography
                                        variant="caption"
                                        color="textSecondary"
                                        align="right"
                                        component="div"
                                        className={styles.date}
                                    >
                                        4 days ago
                                    </Typography>
                                </div>
                            </Paper>
                        </div>
                    </div>

                    <div className={styles.sidebar}>
                        <div>
                            <Typography className={styles.header}>
                                Activity stream
                            </Typography>

                            <div className={styles.activityStream}>
                                <ActivityStreamItem
                                    avatarColor="#7092BF"
                                    initials="MO"
                                    loanNumber="7543672"
                                    dateText="5 minutes ago"
                                    message={(
                                        <>
                                            <b>Loan status</b>

                                            {' '}changed from

                                            {' '}<b>Awaiting Docs & Setup</b>

                                            {' '}to

                                            {' '}<b>Underwriting</b>
                                        </>
                                    )}
                                />

                                <Divider className={styles.divider} />

                                <ActivityStreamItem
                                    avatarColor="#846ca5"
                                    initials="JK"
                                    loanNumber="7543671"
                                    dateText="15 minutes ago"
                                    message={(
                                        <>
                                            <b>Loan status</b>

                                            {' '}changed from

                                            {' '}<b>Underwriting</b>

                                            {' '}to

                                            {' '}<b>Clear to close</b>
                                        </>
                                    )}
                                />

                                <Divider className={styles.divider} />

                                <ActivityStreamItem
                                    avatarColor="#954271"
                                    initials="NP"
                                    loanNumber="7543672"
                                    dateText="45 minutes ago"
                                    message="Loan document uploaded"
                                />

                                <Divider className={styles.divider} />

                                <ActivityStreamItem
                                    avatarColor="#846ca5"
                                    initials="JK"
                                    loanNumber="7543670"
                                    dateText="1 hour ago"
                                    message={(
                                        <>
                                            <b>Loan status</b>

                                            {' '}changed from

                                            {' '}<b>Underwriting</b>

                                            {' '}to

                                            {' '}<b>Clear to close</b>
                                        </>
                                    )}
                                />

                                <Divider className={styles.divider} />

                                <ActivityStreamItem
                                    avatarColor="#7092BF"
                                    initials="MO"
                                    loanNumber="7543670"
                                    dateText="2 hours ago"
                                    message="Loan locked"
                                />

                                <Divider className={styles.divider} />

                                <Button size="small">Load more activity</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Page>
    );
}

interface ActivityStreamItemProps {
    avatarColor: string;
    initials: string;
    message: ReactNode;
    loanNumber: string;
    dateText: string;
}

function ActivityStreamItem({
    avatarColor, initials, message, loanNumber, dateText
}:ActivityStreamItemProps) {
    return (
        <div className={styles.activityStreamItem}>
            <Avatar sx={{ bgcolor: avatarColor }}>
                {initials}
            </Avatar>

            <div>
                <Typography
                    variant="body2"
                    className={styles.activityStreamMessage}
                >
                    {message}
                </Typography>

                <div className={styles.activityStreamBottomRow}>
                    <LoanNumberLink
                        color="textPrimary"
                        loanNumber={loanNumber}
                    />

                    <Typography
                        variant="caption"
                        color="textSecondary"
                    >
                        {dateText}
                    </Typography>
                </div>
            </div>
        </div>
    );
}

interface ChartSectionProps {
    title: ReactNode;
    data: PieChartProps['data'];
    renderValue: (value: number) => ReactNode;
    totalLabel: ReactNode;
}

function ChartSection({
    title, data, renderValue, totalLabel
}: ChartSectionProps) {
    const [ selected, setSelected ] = useState<number | undefined>(undefined);
    const [ hovered, setHovered ] = useState<number | undefined>(undefined);

    data = data.map((entry, i) => (hovered === i ? {
        ...entry,
        color: 'var(--app-color_secondary)'
    } : entry));

    const total = data.reduce((sum, v) => sum + v.value, 0);

    return (
        <div>
            <Typography
                fontWeight={500}
                align="center"
            >
                {title}
            </Typography>

            <div className={styles.chartContainer}>
                <div className={styles.chartLabel}>
                    {selected === undefined ? (
                        <>
                            <Typography
                                color="textSecondary"
                                variant="h4"
                            >
                                {renderValue(total)}
                            </Typography>

                            <Typography
                                variant="body2"
                                color="textSecondary"
                                align="center"
                            >
                                {totalLabel}
                            </Typography>
                        </>
                    ) : (
                        <>
                            <Typography
                                color="textSecondary"
                                variant="h4"
                                align="center"
                            >
                                {renderValue(data[selected].value)}
                            </Typography>

                            <Typography
                                variant="body2"
                                color="textSecondary"
                                align="center"
                            >
                                {data[selected].title}
                            </Typography>
                        </>
                    )}
                </div>

                <PieChart
                    data={data}
                    startAngle={-90}
                    lineWidth={24}
                    paddingAngle={2}
                    radius={45}
                    animate
                    segmentsStyle={{
                        transition: 'stroke .3s ease',
                        cursor: 'pointer'
                    }}
                    segmentsShift={(index) => (
                        index === selected ? 3 : 0
                    )}
                    onClick={(event, index) => {
                        setSelected(index === selected ? undefined : index);
                    }}
                    onMouseOver={(_, index) => {
                        setHovered(index);
                    }}
                    onMouseOut={() => {
                        setHovered(undefined);
                    }}
                />
            </div>
        </div>
    );
}

interface AbbreviatedCurrencyDisplayProps {
    value: number;
}

function AbbreviatedCurrencyDisplay({ value }: AbbreviatedCurrencyDisplayProps) {
    return (
        <Tooltip title={formatCurrency(value)}>
            <span>
                {formatCurrencyAbbreviation(value)}
            </span>
        </Tooltip>
    );
}

const pipelineStatusData = [
    {
        title: 'Awaiting Docs & Setup',
        value: 10,
        color: '#7092BF'
    },
    {
        title: 'Underwriting',
        value: 15,
        color: '#7684B8'
    },
    {
        title: 'Conditioned',
        value: 10,
        color: '#7F74AD'
    },
    {
        title: 'Clear to Close',
        value: 10,
        color: '#89649D'
    },
    {
        title: 'Closed',
        value: 20,
        color: '#915389'
    },
    {
        title: 'Denied/Withdrawn',
        value: 20,
        color: '#954271'
    }
];

const volumeData = [
    {
        title: 'Conventional',
        value: 3435636,
        color: '#7092BF'
    },
    {
        title: 'FHA',
        value: 1231515,
        color: '#7880b6'
    },
    {
        title: 'VA',
        value: 5005213,
        color: '#846ca5'
    },
    {
        title: 'USDA',
        value: 2500566,
        color: '#8f588e'
    },
    {
        title: 'Non-agency',
        value: 3453671,
        color: '#954271'
    }
];

const otherData = [
    {
        title: '1 condition outstanding',
        value: 5,
        color: '#7092BF'
    },
    {
        title: '2 conditions outstanding',
        value: 15,
        color: '#846ca5'
    },
    {
        title: '3 conditions outstanding',
        value: 10,
        color: '#954271'
    }
];
