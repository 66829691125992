export enum ConditionStatus {
    ADDED = 'Added',
    FULFILLED = 'Fulfilled',
    REQUESTED = 'Requested',
    REREQUESTED = 'Rerequested',
    RECEIVED = 'Received',
    REVIEWED = 'Reviewed',
    REJECTED = 'Rejected',
    CLEARED = 'Cleared',
    WAIVED = 'Waived'
}

export const conditionStatusDisplay = {
    [ConditionStatus.ADDED]: 'Added',
    [ConditionStatus.REQUESTED]: 'Requested',
    [ConditionStatus.RECEIVED]: 'Received',
    [ConditionStatus.REREQUESTED]: 'Rerequested',
    [ConditionStatus.FULFILLED]: 'Fulfilled',
    [ConditionStatus.REVIEWED]: 'Reviewed',
    [ConditionStatus.CLEARED]: 'Cleared',
    [ConditionStatus.WAIVED]: 'Waived',
    [ConditionStatus.REJECTED]: 'Rejected'
};
