import api, { EncompassUnderwritingCondition, UnderwritingCondition } from '@api';
import { Button, DialogActions, DialogContent } from '@mui/material';
import { replaceItemById } from '@tsp-ui/core';
import { Dialog, usePageMessage } from '@tsp-ui/core/components';
import { useGetCurrentAccount } from '@utils';
import { ConditionForm } from '@views/admin/condition-config/ConditionConfigDialog';
import { LoanDetailContext } from '@views/loans/LoanDetailPage';
import { useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import styles from './AddEditConditionDialog.module.scss';


interface AddEditConditionDialogProps {
    open: boolean;
    onClose: () => void;
    conditionToEdit: UnderwritingCondition | undefined;
}

export default function AddEditConditionDialog({
    open, onClose, conditionToEdit
}: AddEditConditionDialogProps) {
    const losLoanId = useContext(LoanDetailContext).loanDetail!.losLoanId!;
    const { id: clientId, customerId } = useGetCurrentAccount();

    const { setConditions } = useContext(LoanDetailContext);

    const formMethods = useForm<EncompassUnderwritingCondition>({ defaultValues: conditionToEdit });

    const [ loading, setLoading ] = useState(false);
    const pageMessage = usePageMessage();

    const handleSubmit = formMethods.handleSubmit(async (formValues) => {
        setLoading(true);

        try {
            const newCondition = conditionToEdit
                ? await api.loans.updateUnderwritingCondition(
                    clientId, losLoanId, formValues, customerId
                )
                : await api.loans.createUnderwritingCondition(
                    clientId, losLoanId, formValues
                );

            setConditions(conditions => (
                conditionToEdit
                    ? replaceItemById(conditions, newCondition)
                    : [ ...conditions, newCondition ]));

            onClose();
            pageMessage.success('Condition saved');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving the condition', error);
        }

        setLoading(false);
    });

    const formID = 'add-edit-loan-condition-form';

    return (
        <Dialog
            title={`${conditionToEdit ? 'Edit' : 'Add'} condition`}
            open={open}
            onClose={onClose}
            maxWidth={false}
        >
            <DialogContent>
                <FormProvider {...formMethods}>
                    <ConditionForm
                        conditionToEdit={conditionToEdit}
                        noValidate
                        onSubmit={handleSubmit}
                        className={styles.form}
                        id={formID}
                    />
                </FormProvider>
            </DialogContent>

            <DialogActions>
                <Button
                    onClick={onClose}
                    disabled={loading}
                >
                    Cancel
                </Button>

                <Button
                    variant="contained"
                    onClick={handleSubmit}
                    id={formID}
                    type="submit"
                    disabled={loading}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
