import { useMemo, useState } from 'react';


export function usePagination<T>(items: T[], itemsPerPage: number) {
    const [ page, setPage ] = useState(1);
    const pageCount = Math.ceil(items.length / itemsPerPage);

    const paginatedItems = useMemo(() => {
        const startIndex = (page - 1) * itemsPerPage;
        const endIndex = Math.min(startIndex + itemsPerPage, items.length);
        return items.slice(startIndex, endIndex);
    }, [
        items, page, itemsPerPage
    ]);

    return {
        page,
        pageCount,
        paginatedItems,
        setPage
    };
}
