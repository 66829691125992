import api, {
    LoanAllocationLimit,
    LoanAllocationLimitType,
    loanAllocationLimitTypeDisplay,
    productTypeDisplay,
    underwritingTypeDisplay
} from '@api';
import {
    Category, Inventory, Notes, Public
} from '@mui/icons-material';
import {
    Button, DialogContent, Divider, MenuItem, Typography
} from '@mui/material';
import {
    Autocomplete, CurrencyField, DateField, DialogActions, IconTypography, TextField, renderEnumOptions, usePageMessage
} from '@tsp-ui/core/components';
import { replaceItemById, useForm } from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils/hooks';
import {
    CustomerDetailContext,
    CustomerDetailContextValue
} from '@views/admin/customers/CustomerDetailPage/CustomerDetailPage';
import { SelectableButton } from '@views/admin/investor-eligibility/components/ClientInvestorForm';
import clsx from 'clsx';
import { isToday } from 'date-fns';
import {
    Dispatch, SetStateAction, useContext, useState
} from 'react';
import { FormProvider, useFormContext } from 'react-hook-form';

import styles from './LimitForm.module.scss';


const {
    ALL, UNDERWRITING_TYPE, PRODUCT_TYPE, PRODUCT
} = LoanAllocationLimitType;

interface LimitFormProps {
    closeForm: () => void;
    onEditClick: (limitId: string) => void;
    limit: LoanAllocationLimit | undefined;
    limits: LoanAllocationLimit[] | undefined;
    setLimits: Dispatch<SetStateAction<LoanAllocationLimit[] | undefined>>;
    customerId?: string;
}

export default function LimitForm({
    closeForm, limit, customerId, limits, onEditClick, setLimits
}: LimitFormProps) {
    const { customer, products } = useContext(CustomerDetailContext) as Required<CustomerDetailContextValue>;

    const pageMessage = usePageMessage();
    const { id: clientId } = useGetCurrentAccount();

    const [ loading, setLoading ] = useState(false);
    const formMethods = useForm<LoanAllocationLimit>({
        defaultValues: limit || {
            customerId
        }
    });

    const handleSubmit = formMethods.handleSubmit(async (formValues) => {
        setLoading(true);

        try {
            if (limit) {
                const updatedLimit = await api.customer.limits.updateLoanAllocationLimit(
                    clientId, customerId!, formValues
                );

                setLimits(limits => limits && replaceItemById(limits, updatedLimit));
            } else {
                const newLimit = await api.customer.limits.createLoanAllocationLimit(clientId, customerId!, formValues);

                setLimits(limits => limits?.concat(newLimit));
            }

            closeForm();
            pageMessage.success(`Limit ${limit ? 'updated' : 'added'}`);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving the loan allocation limit', error);
        }

        setLoading(false);
    });

    const [
        type, underwritingType, productTypeConfigId, productId, limitAmount, expirationDate
    ] = formMethods.watch([
        'type', 'underwritingType', 'productTypeConfigId', 'productId', 'limitAmount', 'expirationDate'
    ]);

    const LimitTypeIcon = limitTypeIcons[type];

    const limitFields = (
        <div className={styles.limitFields}>
            <CurrencyField<LoanAllocationLimit>
                name="limitAmount"
                label="Limit amount"
                autoFocus={!limit}
                required
            />

            <DateField<LoanAllocationLimit>
                name="expirationDate"
                label="Expiration date"
                required
                pickerProps={{
                    disablePast: true,
                    disableHighlightToday: true,
                    shouldDisableDate: (date) => isToday(date)
                }}
            />
        </div>
    );

    const { productType } = customer?.productTypeConfigs?.find(({ id }) => id === limit?.productTypeConfigId) || {};

    const existingProductLimit = limits?.find((limit) => limit.productId === productId);
    const existingUwLimit = limits?.find((limit) => limit.underwritingType === underwritingType);
    const existingProductTypeLimit = limits?.find((limit) => limit.productTypeConfigId === productTypeConfigId);
    const existingGlobalLimit = limits?.find((limit) => limit.type === ALL);

    return (
        <>
            <DialogContent>
                <form
                    noValidate
                    id={LimitForm.formID}
                    onSubmit={handleSubmit}
                    className={clsx(styles.form, {
                        [styles.isAdd]: !limit
                    })}
                >
                    <FormProvider {...formMethods}>
                        {limit ? (
                            <>
                                <IconTypography icon={<LimitTypeIcon color="secondary" />}>
                                    {loanAllocationLimitTypeDisplay[type]}

                                    {type === UNDERWRITING_TYPE ? (
                                        ` - ${underwritingTypeDisplay[limit.underwritingType!]}`
                                    ) : type === PRODUCT_TYPE ? (
                                        ` - ${productType ? productTypeDisplay[productType] : '--'}`
                                    ) : type === PRODUCT ? (
                                        ` - ${products?.find(({ id }) => id === limit.productId)?.productDescription
                                        || '--'}`
                                    ) : null}
                                </IconTypography>

                                {limitFields}
                            </>
                        ) : (
                            <>
                                <Typography align="center">
                                    Which type of limit would you like to add?
                                </Typography>

                                <div className={styles.limitTypeButtons}>
                                    <LimitTypeSelectionButton
                                        type={ALL}
                                        selectedType={type}
                                    />

                                    <LimitTypeSelectionButton
                                        type={UNDERWRITING_TYPE}
                                        selectedType={type}
                                    />

                                    <LimitTypeSelectionButton
                                        type={PRODUCT_TYPE}
                                        selectedType={type}
                                    />

                                    <LimitTypeSelectionButton
                                        type={PRODUCT}
                                        selectedType={type}
                                    />
                                </div>

                                {type === UNDERWRITING_TYPE ? (
                                    <>
                                        <Divider />

                                        <Typography align="center">
                                            Which underwriting type would you like to limit?
                                        </Typography>

                                        <TextField<LoanAllocationLimit>
                                            name="underwritingType"
                                            label="Underwriting type"
                                            select
                                        >
                                            {renderEnumOptions(underwritingTypeDisplay)}
                                        </TextField>

                                        {underwritingType && (
                                            existingUwLimit ? (
                                                <ExistingLimitDisplay
                                                    limit={existingUwLimit}
                                                    onClick={onEditClick}
                                                />
                                            ) : limitFields
                                        )}
                                    </>
                                ) : type === PRODUCT_TYPE ? (
                                    <>
                                        <Divider />

                                        <Typography align="center">
                                            Which product type would you like to limit?
                                        </Typography>

                                        <TextField<LoanAllocationLimit>
                                            name="productTypeConfigId"
                                            label="Product type"
                                            select
                                        >
                                            {customer?.productTypeConfigs?.map((productTypeConfig) => (
                                                <MenuItem
                                                    key={productTypeConfig.id}
                                                    value={productTypeConfig.id}
                                                >
                                                    {productTypeDisplay[productTypeConfig.productType]}
                                                </MenuItem>
                                            ))}
                                        </TextField>

                                        {productTypeConfigId && (
                                            existingProductTypeLimit ? (
                                                <ExistingLimitDisplay
                                                    limit={existingProductTypeLimit}
                                                    onClick={onEditClick}
                                                />
                                            ) : limitFields
                                        )}
                                    </>
                                ) : type === PRODUCT ? (
                                    <>
                                        <Divider />

                                        <Typography align="center">
                                            Which product would you like to limit?
                                        </Typography>

                                        <Autocomplete<LoanAllocationLimit>
                                            name="productId"
                                            label="Product"
                                            defaultValue=""
                                            options={products?.map(({ id }) => id) || []}
                                            groupBy={(value) => {
                                                const product = products?.find(({ id }) => id === value);

                                                return !product ? '--' : productTypeDisplay[product.productType];
                                            }}
                                            getOptionLabel={(value) => (
                                                products?.find(({ id }) => id === value)?.productDescription || ''
                                            )}
                                        />

                                        {productId && (
                                            existingProductLimit ? (
                                                <ExistingLimitDisplay
                                                    limit={existingProductLimit}
                                                    onClick={onEditClick}
                                                />
                                            ) : limitFields
                                        )}
                                    </>
                                ) : type && (
                                    existingGlobalLimit ? (
                                        <ExistingLimitDisplay
                                            limit={existingGlobalLimit}
                                            onClick={onEditClick}
                                        />
                                    ) : (
                                        <>
                                            <Divider />

                                            {limitFields}
                                        </>
                                    )
                                )}
                            </>
                        )}
                    </FormProvider>
                </form>
            </DialogContent>

            <DialogActions loading={loading}>
                <Button onClick={closeForm}>
                    Cancel
                </Button>

                <Button
                    variant="contained"
                    type="submit"
                    form={LimitForm.formID}
                    disabled={!limitAmount || !expirationDate}
                >
                    Save
                </Button>
            </DialogActions>
        </>
    );
}

LimitForm.formID = 'limit-form';

const limitTypeIcons = {
    [ALL]: Public,
    [UNDERWRITING_TYPE]: Notes,
    [PRODUCT_TYPE]: Category,
    [PRODUCT]: Inventory
};

interface LimitTypeSelectionButtonProps {
    type: LoanAllocationLimitType;
    selectedType: LoanAllocationLimitType;
}

function LimitTypeSelectionButton({ type, selectedType }: LimitTypeSelectionButtonProps) {
    const { setValue } = useFormContext<LoanAllocationLimit>();
    const Icon = limitTypeIcons[type];

    return (
        <SelectableButton
            selected={type === selectedType}
            onClick={() => {
                if (selectedType === PRODUCT) {
                    setValue('productId', undefined);
                }

                if (selectedType === PRODUCT_TYPE) {
                    setValue('productTypeConfigId', undefined);
                }

                if (selectedType === UNDERWRITING_TYPE) {
                    setValue('underwritingType', undefined);
                }

                setValue('type', type);
            }}
            className={styles.limitTypeButton}
        >
            <Icon
                color="secondary"
                className={styles.icon}
            />

            {loanAllocationLimitTypeDisplay[type]}
        </SelectableButton>
    );
}

interface ExistingLimitDisplayProps {
    limit: LoanAllocationLimit;
    onClick: (limitId: string) => void;
}

function ExistingLimitDisplay({ limit, onClick }: ExistingLimitDisplayProps) {
    const label = limit.type === ALL
        ? 'A global limit already exists'
        : limit.type === UNDERWRITING_TYPE
            ? 'A limit already exists for this underwriting type'
            : limit.type === PRODUCT_TYPE
                ? 'A limit already exists for this product type' : 'A limit already exists for this product';

    return (
        <>
            <Divider />

            <Typography align="center">
                {label}
            </Typography>

            <Button
                variant="contained"
                className={styles.existingLimitButton}
                onClick={() => onClick(limit.id)}
            >
                Edit existing limit
            </Button>
        </>
    );
}
