import api, {
    NotificationConfig,
    Permission,
    PermissionCategory,
    PermissionType,
    Role,
    RolePermissionLevel,
    UserType,
    permissionCategoryDisplay,
    permissionLevelDisplay,
    userTypeDisplay
} from '@api';
import { Typography } from '@mui/material';
import { LabelGroup, LabeledValue, RoutedDialogManager } from '@tsp-ui/core/components';
import {
    replaceItemById, tryGetItemByKey, useAsyncEffect, usePageMessage, useParams
} from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils/hooks';
import { useHasPermission } from '@utils/hooks/useHasPermission';
import {
    Dispatch, SetStateAction, createContext, useCallback, useContext, useMemo, useState
} from 'react';

import Page from '../../components/Page';
import { AdminRouteParams } from '../components/AdminPageTemplate';
import EditableSectionCard from '../components/EditableSectionCard';

import styles from './RoleDetailPage.module.scss';
import { RoleManagementPageContext } from './RoleManagementPage';
import EditPermissionsDialog from './components/EditPermissionsDialog';
import EditRoleDetailsDialog from './components/EditRoleDetailsDialog';
import NotificationDialog from './notifications/NotificationDialog';
import NotificationsSection from './notifications/NotificationsSection';


interface RoleDetailContextValues {
    role?: Role;
    setRole: (role: Role) => void;
    permissions: Permission[];
    permissionLevels: RolePermissionLevel[];
    updatePermissionLevels: Dispatch<SetStateAction<RolePermissionLevel[]>>;
    notifications: NotificationConfig[];
    updateNotifications: Dispatch<SetStateAction<NotificationConfig[]>>;
}

export const RoleDetailPageContext = createContext<RoleDetailContextValues>({
    role: undefined,
    setRole: () => {},
    permissions: [],
    permissionLevels: [],
    updatePermissionLevels: () => {},
    notifications: [],
    updateNotifications: () => {}
});

export default function RoleDetailPage() {
    const [ permissionLevels, setPermissionLevels ] = useState<RolePermissionLevel[]>([]);
    const [ permissions, setPermissions ] = useState<Permission[]>([]);
    const [ notifications, setNotifications ] = useState<NotificationConfig[]>([]);
    const [ loading, setLoading ] = useState(true);

    const { roleID } = useParams<AdminRouteParams<'role'>>();
    const { entities: roles, setEntities: setRoles } = useContext(RoleManagementPageContext);

    const role = roles.find(({ id }) => roleID === `${id}`);
    const setRole = useCallback(
        (role: Role) => setRoles(replaceItemById(roles, role)),
        [ roles, setRoles ]
    );

    const roleDetailContextValues = useMemo(() => ({
        role,
        setRole,
        permissions,
        permissionLevels,
        updatePermissionLevels: setPermissionLevels,
        notifications,
        updateNotifications: setNotifications
    }), [
        role, setRole, permissions, permissionLevels, notifications
    ]);

    const { id: clientID } = useGetCurrentAccount();
    const pageMessage = usePageMessage();

    const [ canEditRoles, canEditPermissions ] = useHasPermission(
        [ PermissionType.EDIT_ROLES, PermissionType.EDIT_ROLE_PERMISSION ]
    );

    useAsyncEffect(useCallback(async () => {
        try {
            const [
                notifications, permissionLevels, permissions
            ] = await Promise.all([
                api.roles.notifications.getNotificationConfigs(clientID, roleID),
                api.roles.getPermissionLevels(clientID, roleID),
                api.roles.getPermissions()
            ]);

            setNotifications(notifications);
            setPermissionLevels(permissionLevels);
            setPermissions(permissions);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching role details', error);
        }

        setLoading(false);
    }, [
        pageMessage, roleID, clientID
    ]));

    return (
        <Page
            header="Role Details"
            loading={loading}
            breadcrumbs={[
                'Roles',
                role?.name
            ]}
        >
            <RoleDetailPageContext.Provider value={roleDetailContextValues}>
                <div className={styles.root}>
                    <div>
                        <EditableSectionCard
                            header={role?.name}
                            editTo="edit"
                            readOnly={!canEditRoles}
                        >
                            <LabelGroup>
                                <LabeledValue
                                    label="Description:"
                                    value={role?.description}
                                />

                                <LabeledValue
                                    label="Role type:"
                                    value={role?.type ? userTypeDisplay[role.type] : ''}
                                />
                            </LabelGroup>
                        </EditableSectionCard>

                        <EditableSectionCard
                            header="Permissions"
                            editTo="permissions"
                            readOnly={!canEditPermissions}
                        >
                            <div className={styles.viewPermissions}>
                                {Object.keys(PermissionCategory).map(category => (
                                    <CategoryPermissionsView
                                        key={category}
                                        category={category as PermissionCategory}
                                        permissions={permissions}
                                        permissionLevels={permissionLevels}
                                        userType={role?.type}
                                    />
                                ))}
                            </div>
                        </EditableSectionCard>
                    </div>

                    <div>
                        <NotificationsSection notifications={notifications} />
                    </div>
                </div>

                <RoutedDialogManager routes={dialogRoutes} />
            </RoleDetailPageContext.Provider>
        </Page>
    );
}

const dialogRoutes = {
    permissions: EditPermissionsDialog,
    edit: EditRoleDetailsDialog,
    'notifications/new': NotificationDialog,
    'notifications/:notificationID/edit': NotificationDialog
};

interface CategoryPermissionsViewProps {
    category: PermissionCategory;
    permissions: Permission[];
    permissionLevels: RolePermissionLevel[];
    userType?: UserType;
}

function CategoryPermissionsView({
    category, permissions, permissionLevels, userType
}: CategoryPermissionsViewProps) {
    const categoryPermissions = permissions.filter(perm => (
        perm.category === category && userType && perm.allowableRoleTypes.includes(userType)
    ));

    return categoryPermissions.length ? (
        <div key={category}>
            <Typography className={styles.sectionViewHeader}>
                {permissionCategoryDisplay[category]}
            </Typography>

            <LabelGroup className={styles.rolesView}>
                {categoryPermissions.map(({
                    id, permissionType, description
                }) => {
                    const permissionLevel = tryGetItemByKey(permissionLevels, 'permissionType', permissionType);

                    return (
                        <LabeledValue
                            key={id}
                            variants={{ value: 'body2' }}
                            classNames={{
                                label: styles.viewPermission,
                                value: styles.viewPermissionLevel
                            }}
                            value={permissionLevel ? permissionLevelDisplay[permissionLevel.permissionLevel] : 'Permission not found'}
                            label={`${description}:`}
                        />
                    );
                })}
            </LabelGroup>
        </div>
    ) : null;
}
