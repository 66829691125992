import { ApprovalStatusEnum, approvalStatusDisplay } from '@api';
import { Error, PublishedWithChanges, Verified } from '@mui/icons-material';
import { ListItemButtonProps, Tooltip } from '@mui/material';
import { ListItemLink } from '@tsp-ui/core';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';

import styles from './DataRequestsMenuListItem.module.scss';


interface DataRequestsMenuListItemProps extends ListItemButtonProps {
    title: string;
    tab?: string;
    selected?: boolean;
    disableExtraWidth?: boolean;
    status?: ApprovalStatusEnum;
}

export function DataRequestsMenuListItem({
    tab, selected, disableExtraWidth, status, title, ...otherProps
}: DataRequestsMenuListItemProps) {
    const { dataRequestID } = useParams();
    selected = selected !== undefined ? selected : !!dataRequestID && dataRequestID === tab;

    return (
        <ListItemLink
            to={`${dataRequestID ? './../' : ''}${tab || ''}`}
            selected={selected}
            className={styles.listItem}
            classes={{
                selected: clsx(styles.selected, {
                    [styles.extraWidth]: !disableExtraWidth
                })
            }}
            {...otherProps}
        >
            <span data-title={title}>
                {title}
            </span>

            <Tooltip title={status ? approvalStatusDisplay[status] : ''}>
                {status === 'SUBMITTED' ? (
                    <PublishedWithChanges fontSize="small" />
                ) : status === 'APPROVED' ? (
                    <Verified
                        fontSize="small"
                        color="success"
                    />
                ) : status === 'REJECTED' ? (
                    <Error
                        color="error"
                        fontSize="small"
                    />
                ) : <span />}
            </Tooltip>
        </ListItemLink>
    );
}
