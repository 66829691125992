import { PremicorrConditionCategory } from '@api';
import { useContext, useState } from 'react';

import { LoanDetailContext } from '../LoanDetailPage';

import ConditionsSummary from './components/ConditionsSummary';
import UnderwritingPageTemplate from './components/UnderwritingPageTemplate';
import UnderwritingStepCard from './components/UnderwritingStepCard';
import FraudPartiesSection from './components/fraud/FraudPartiesSection';
import FraudReportSection from './components/fraud/FraudReportSection';

// TODO use react-router for this
export type FraudReportUnderwritingTabs = 'summary' | 'report' | 'parties';

export default function FraudReportUnderwritingPage() {
    const { conditions } = useContext(LoanDetailContext);
    const [ tab, setTab ] = useState<FraudReportUnderwritingTabs>('summary');
    const [ finishedTabs, setFinishedTabs ] = useState<FraudReportUnderwritingTabs[]>([]);

    const fraudReportConditions = conditions?.filter(
        condition => condition.category === PremicorrConditionCategory.FRAUD_REPORT
    );

    function tabComplete(tab: FraudReportUnderwritingTabs, nextTab: FraudReportUnderwritingTabs) {
        setFinishedTabs(finishedTabs.concat(tab));
        setTab(nextTab);
    }

    return (
        <UnderwritingPageTemplate
            conditionsShown={tab === 'summary'}
            onConditionsClick={() => setTab('summary')}
            relevantDocs={[ 'Fraud report.pdf' ]}
            stepCards={(
                <>
                    <UnderwritingStepCard
                        active={tab === 'report'}
                        conditionIDs={[]}
                        done={finishedTabs.includes('report')}
                        label="Fraud report"
                        onClick={() => setTab('report')}
                    />

                    <UnderwritingStepCard
                        active={tab === 'parties'}
                        conditionIDs={[]}
                        done={finishedTabs.includes('parties')}
                        label="Parties review"
                        onClick={() => setTab('parties')}
                    />
                </>
            )}
        >
            {tab === 'summary' ? (
                <ConditionsSummary
                    header="Fraud report conditions"
                    conditions={fraudReportConditions}
                />
            ) : tab === 'report' ? (
                <FraudReportSection
                    onTabComplete={tabComplete}
                    setTab={setTab}
                />
            ) : tab === 'parties' ? (
                <FraudPartiesSection
                    onTabComplete={tabComplete}
                    setTab={setTab}
                />
            ) : null}
        </UnderwritingPageTemplate>
    );
}
