import { PremicorrConditionCategory } from '@api';
import { ErrorOutline } from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';
import { useContext, useState } from 'react';

import { LoanDetailContext } from '../LoanDetailPage';

import styles from './AppraisalUnderwritingPage.module.scss';
import ConditionsSummary from './components/ConditionsSummary';
import ExpandableUnderwritingStepCard from './components/ExpandableUnderwritingStepCard';
import UnderwritingPageTemplate from './components/UnderwritingPageTemplate';
import UnderwritingStepCard from './components/UnderwritingStepCard';
import AppraisalDetailsSection from './components/appraisal/AppraisalDetailsSection';
import AppraisalPropertySection from './components/appraisal/AppraisalPropertySection';
import AppraisalReviewSection from './components/appraisal/AppraisalReviewSection';
import AppraisalUCDPSection from './components/appraisal/AppraisalUCDPSection';
import AppraisalWaiverSection from './components/appraisal/AppraisalWaiverSection';
import AppraiserDetailsSection from './components/appraisal/AppraiserDetailsSection';
import CondoInfoSection from './components/appraisal/CondoInfoSection';
import QualifyingAppraisalSection from './components/appraisal/QualifyingAppraisalSection';

// TODO use react-router for this
export type AppraisalUnderwritingTabs = 'summary' | 'waiver' | 'property' | 'detail' | 'review' | 'appraiser' | 'ucdp' |
    'qualifying' | 'condo-info';

export default function AppraisalUnderwritingPage() {
    const { conditions } = useContext(LoanDetailContext);
    const [ tab, setTab ] = useState<AppraisalUnderwritingTabs>('summary');
    const [ finishedTabs, setFinishedTabs ] = useState<AppraisalUnderwritingTabs[]>([]);

    const appraisalConditions = conditions?.filter(
        condition => condition.category === PremicorrConditionCategory.APPRAISAL
    );

    function tabComplete(tab: AppraisalUnderwritingTabs, nextTab: AppraisalUnderwritingTabs) {
        setFinishedTabs(finishedTabs.concat(tab));
        setTab(nextTab);
    }

    const appraisalStepCards = (
        <>
            <UnderwritingStepCard
                label="Appraisal property"
                active={tab === 'property'}
                conditionIDs={[ ]}
                done={finishedTabs.includes('property')}
                onClick={() => setTab('property')}
            />

            <UnderwritingStepCard
                label="Appraisal details"
                active={tab === 'detail'}
                conditionIDs={[ ]}
                done={finishedTabs.includes('detail')}
                onClick={() => setTab('detail')}
            />

            <UnderwritingStepCard
                label="Appraisal review"
                active={tab === 'review'}
                conditionIDs={[ '' ]}
                done={finishedTabs.includes('review')}
                onClick={() => setTab('review')}
            />

            <UnderwritingStepCard
                label="Appraiser details"
                active={tab === 'appraiser'}
                conditionIDs={[]}
                done={finishedTabs.includes('appraiser')}
                onClick={() => setTab('appraiser')}
            />

            <UnderwritingStepCard
                label="SSR/UCDP"
                active={tab === 'ucdp'}
                conditionIDs={[]}
                done={finishedTabs.includes('ucdp')}
                onClick={() => setTab('ucdp')}
            />
        </>
    );

    const isJumbo = false;

    return (
        <UnderwritingPageTemplate
            relevantDocs={[ 'Appraisal.pdf', ...(isJumbo ? [ 'Appraisal 2.pdf' ] : []) ]}
            conditionsShown={tab === 'summary'}
            onConditionsClick={() => setTab('summary')}
            stepCards={(
                <>
                    {isJumbo && (
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            className={styles.appraisalsRequired}
                        >
                            <Tooltip title="Jumbo loans require two appraisals">
                                <ErrorOutline
                                    color="primary"
                                    fontSize="small"
                                />
                            </Tooltip>

                            2 appraisals required
                        </Typography>
                    )}

                    <UnderwritingStepCard
                        label="Appraisal waiver"
                        active={tab === 'waiver'}
                        conditionIDs={[ ]}
                        done={finishedTabs.includes('waiver')}
                        onClick={() => setTab('waiver')}
                    />

                    {isJumbo ? (
                        <>
                            <ExpandableUnderwritingStepCard
                                label="Appraisal 1"
                                expandedCards={appraisalStepCards}
                            />

                            <ExpandableUnderwritingStepCard
                                label="Appraisal 2"
                                expandedCards={appraisalStepCards}
                            />

                            <UnderwritingStepCard
                                label="Qualifying appraisal"
                                active={tab === 'qualifying'}
                                conditionIDs={[ ]}
                                done={finishedTabs.includes('qualifying')}
                                onClick={() => setTab('qualifying')}
                            />
                        </>
                    ) : appraisalStepCards}

                    <UnderwritingStepCard // TODO only show if property is condo
                        label="Condo info"
                        active={tab === 'condo-info'}
                        conditionIDs={[]}
                        done={finishedTabs.includes('condo-info')}
                        onClick={() => setTab('condo-info')}
                    />
                </>
            )}
        >
            {tab === 'summary' ? (
                <ConditionsSummary
                    header="Appraisal conditions"
                    conditions={appraisalConditions}
                />
            ) : tab === 'waiver' ? (
                <AppraisalWaiverSection
                    setTab={setTab}
                    onTabComplete={tabComplete}
                />
            ) : tab === 'property' ? (
                <AppraisalPropertySection
                    setTab={setTab}
                    onTabComplete={tabComplete}
                />
            ) : tab === 'detail' ? (
                <AppraisalDetailsSection
                    setTab={setTab}
                    onTabComplete={tabComplete}
                />
            ) : tab === 'review' ? (
                <AppraisalReviewSection
                    setTab={setTab}
                    onTabComplete={tabComplete}
                />
            ) : tab === 'appraiser' ? (
                <AppraiserDetailsSection
                    setTab={setTab}
                    onTabComplete={tabComplete}
                />
            ) : tab === 'ucdp' ? (
                <AppraisalUCDPSection
                    setTab={setTab}
                    onTabComplete={tabComplete}
                />
            ) : tab === 'qualifying' ? (
                <QualifyingAppraisalSection
                    setTab={setTab}
                    onTabComplete={tabComplete}
                />
            ) : tab === 'condo-info' ? (
                <CondoInfoSection
                    setTab={setTab}
                    onTabComplete={tabComplete}
                />
            ) : null}
        </UnderwritingPageTemplate>
    );
}
